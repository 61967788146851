import React, { useContext, useEffect } from "react";

import { BookingSearchContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import LatestBookingsTable from "@pages/dashboard/latest-bookings/latest-bookings-table";
import { SpinnerIcon } from "@widgets/spinnerIcon/spinnerIcon";

import LatestBookingsCards from "./latest-bookings-cards";
import styles from "./styles.css";

const LatestBookings = () => {
  const { latestBookings, fetchLatestBookings } = assertNotUndefined(
    useContext(BookingSearchContext)
  );

  useEffect(() => {
    fetchLatestBookings();
  }, []);

  return (
    <div className={styles.latestBookings} data-testid="latest-bookings">
      <h3 className={styles.title}>Latest Bookings</h3>

      {latestBookings.kind === "value" ? (
        <>
          <LatestBookingsTable latestBookings={latestBookings.value} />
          <LatestBookingsCards latestBookings={latestBookings.value} />
        </>
      ) : (
        <SpinnerIcon size="small" />
      )}
    </div>
  );
};

export default LatestBookings;
