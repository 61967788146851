import { AppUserType } from "@adl-gen/hotel/db";
import { AgentModalForm } from "@pages/settings/manage-agents/agent-modal/types";

export const EMPTY_NEW_AGENT_FORM: AgentModalForm = {
  fullname: "",
  email: "",
  phoneNumber: null,
  userType: AppUserType.agent,
  agencyId: null,
};
