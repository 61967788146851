import clsx from "clsx";
import _ from "lodash";
import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { LatLng } from "@adl-gen/common";
import {
  LocationInfoResp,
  LocationType_Bounds,
} from "@adl-gen/ids/externalapi";
import { HotelSearchContext } from "@app/app";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import { usePagination } from "@controllers/customHooks/usePagination";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import { MemoizedMapState } from "@controllers/hotel-search/types";
import { assertNotUndefined } from "@hx/util/types";
import HotelGoogleMap from "@widgets/hotel-google-map";
import { SpinnerOverlay } from "@widgets/loader/loader";
import { SpinnerIcon } from "@widgets/spinnerIcon/spinnerIcon";

import resultsStyles from "../styles.css";
import HotelMapCard from "./hotel-map-card";
import styles from "./styles.css";

const INFINITE_SCROLL_ID = "scrollableDiv";

interface PropTypes {
  hotels: HotelListingResultWithId[];
  onHotelClick(hotelId: string, opts: { bounds: LatLng[]; page: number }): void;
  isLoadingExistingSearchRef: React.MutableRefObject<boolean>;
}

export const MapView: FC<PropTypes> = ({ hotels, onHotelClick }) => {
  const {
    totalHotelsAvailable,
    locationInfo,
    searchHotelsFromCache,
    defaultMapState,
    startNewHotelSearch,
    mapHotelsToResultWithId,
    allCachedHotels,
    pollPricesForCachedHotels,
  } = assertNotUndefined(useContext(HotelSearchContext));
  const [isLoading, setIsLoading] = useState(false);
  const infiniteScrollRef = useRef<InfiniteScroll | null>(null);

  const [hotelsToShow, setHotelsToShow] = useState<HotelListingResultWithId[]>(
    hotels
  );
  const [totalHotels, setTotalHotels] = useState(0);

  const [highlightedHotelId, setHighlightedHotelId] = useState<string | null>(
    null
  );

  /* @TODO: remove this useRef solution once application will be transferred
   * to another state management library
   */
  const globalStoreHotelsCopy = useRef<HotelListingResultWithId[]>(hotels);

  const lastSearchBounds = useRef<google.maps.LatLngBounds>();
  /* Got updated every time user zooms out. Used for performance optimization:
   * if new bounds after the map got moved are within these, it's enough to do search from cache
   */
  const lastLargestBounds = useRef<google.maps.LatLngBounds>();

  const { currentPage, resetPaginationValues, setPageNumber } = usePagination({
    ignoreUrlUpdate: true,
  });

  // Because React.Context recreates links to data on each rerender,
  // we need to save a copy of hotels stored in storage and do a deep comparison.
  // TODO: get rid of this effect once app has better state management solution
  useEffect(() => {
    // Check intermediate state of the data that happens during a new search
    // when no prices are present/loading
    if (
      hotels.length > 0 &&
      hotels.every(({ details }) => details.priceDetails.kind === "error")
    ) {
      return;
    }

    const globalStorageHotelsChanged =
      hotels.length !== globalStoreHotelsCopy.current.length ||
      !_(hotels)
        .differenceWith(globalStoreHotelsCopy.current, _.isEqual)
        .isEmpty();

    if (globalStorageHotelsChanged) {
      setHotelsToShow(hotels);
      globalStoreHotelsCopy.current = hotels;
    }
  }, [hotels]);

  useEffect(() => {
    setTotalHotels(totalHotelsAvailable);
  }, [totalHotelsAvailable]);

  async function fetchMoreData() {
    const nextPageNumber = currentPage + 1;

    // If this condition is true, it means the bounds search has already been made at least once,
    // Otherwise we do an existing search instead
    if (lastSearchBounds.current) {
      const result = await searchHotelsFromCache(
        {
          kind: "bounds",
          value: [
            getSquareFromBounds(
              lastSearchBounds.current as google.maps.LatLngBounds
            ),
          ],
        },
        {
          offset: 0,
          count: ITEMS_PER_PAGE * nextPageNumber,
        }
      );

      const hotelIdsToPollPrices = result.items.map(({ hotelId }) => hotelId);

      const pricesMap = await pollPricesForCachedHotels(hotelIdsToPollPrices);

      const scrollOffset =
        infiniteScrollRef.current!.getScrollableTarget()?.scrollTop || 0;

      setHotelsToShow(mapHotelsToResultWithId(result.items, pricesMap));

      infiniteScrollRef.current!.getScrollableTarget()?.scroll(0, scrollOffset);
    }

    setPageNumber(nextPageNumber, { scrollToTop: false });
  }

  async function fetchNewDataByMap(bounds: google.maps.LatLngBounds) {
    setIsLoading(true);
    infiniteScrollRef.current?.getScrollableTarget()?.scroll(0, 0);

    const loc = getSquareFromBounds(bounds);

    const query: LocationType_Bounds = {
      kind: "bounds",
      value: [loc],
    };

    const result = await searchHotelsFromCache(query, {
      offset: 0,
      count: ITEMS_PER_PAGE,
    });
    const hotelIdsToPollPrices = result.items.map(({ hotelId }) => hotelId);

    const pricesMap = await pollPricesForCachedHotels(hotelIdsToPollPrices);

    if (
      lastLargestBounds.current &&
      within(bounds, lastLargestBounds.current)
    ) {
      setHotelsToShow(mapHotelsToResultWithId(result.items, pricesMap));
      setTotalHotels(result.total_size);
    } else {
      const newHotelsFound = result.items.some(
        ({ hotelId }) => !allCachedHotels.hotels.has(hotelId)
      );

      if (newHotelsFound) {
        await handleStartNewSearch(loc);
      } else {
        setHotelsToShow(mapHotelsToResultWithId(result.items, pricesMap));
        setTotalHotels(result.total_size);
      }

      lastLargestBounds.current = bounds;
    }

    lastSearchBounds.current = bounds;
    resetPaginationValues();
    setIsLoading(false);
  }

  function getSquareFromBounds(bounds: google.maps.LatLngBounds) {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const nw: LatLng = {
      lat: southWest.lat(),
      lng: northEast.lng(),
    };
    const se: LatLng = {
      lat: northEast.lat(),
      lng: southWest.lng(),
    };

    const ne = { lat: northEast.lat(), lng: northEast.lng() };
    const sw = { lat: southWest.lat(), lng: southWest.lng() };

    return [ne, se, sw, nw, ne];
  }

  function within(
    boundsA: google.maps.LatLngBounds,
    boundsB: google.maps.LatLngBounds
  ) {
    const points = getSquareFromBounds(boundsA);

    return points.every((point) => boundsB.contains(point));
  }

  function handleStartNewSearch(loc: LatLng[]) {
    return startNewHotelSearch({
      mLocation: loc,
      updateUrl: false,
    });
  }

  function saveMapSate(args: MemoizedMapState) {
    defaultMapState.current = args;
  }

  function onSelect(hotelId: string) {
    const bounds = getSquareFromBounds(
      (lastSearchBounds.current ||
        lastLargestBounds.current) as google.maps.LatLngBounds
    );

    const hotelIndex = hotelsToShow.findIndex(({ id }) => id === hotelId);
    const page = Math.floor(hotelIndex / ITEMS_PER_PAGE) + 1;

    onHotelClick(hotelId, {
      bounds,
      page,
    });
  }

  const location = useMemo<LocationInfoResp | null>(() => {
    return locationInfo.kind === "value" ? locationInfo.value : null;
  }, [locationInfo]);

  function onInitMap(bounds: google.maps.LatLngBounds) {
    lastLargestBounds.current = bounds;
    lastSearchBounds.current = bounds;
  }

  function onHotelCardEnter(id: string) {
    return () => {
      setHighlightedHotelId(id);
    };
  }

  function onHotelCardLeave() {
    setHighlightedHotelId(null);
  }

  const hasMore = totalHotels > hotelsToShow.length;

  return (
    <div className={styles.mapViewContainer}>
      <div
        id={INFINITE_SCROLL_ID}
        className={clsx(styles.hotelList, isLoading && styles.loading)}
      >
        {isLoading && <SpinnerOverlay type="local" />}
        {hotelsToShow.length ? (
          <InfiniteScroll
            className={styles.infiniteScroll}
            ref={infiniteScrollRef}
            scrollableTarget={INFINITE_SCROLL_ID}
            dataLength={hotelsToShow.length}
            hasMore={hasMore}
            loader={hasMore ? <SpinnerIcon /> : null}
            next={fetchMoreData}
            scrollThreshold={0.9}
          >
            {hotelsToShow.map((hotel: HotelListingResultWithId) => (
              <HotelMapCard
                key={hotel.id}
                hotel={hotel}
                onHotelClick={onSelect}
                onMouseEnter={onHotelCardEnter(hotel.id)}
                onMouseLeave={onHotelCardLeave}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <p className={resultsStyles.noData}>No hotels available</p>
        )}
      </div>
      <HotelGoogleMap
        defaultState={defaultMapState.current}
        location={location}
        onSaveMapState={saveMapSate}
        onInitBounds={onInitMap}
        hotels={hotelsToShow}
        onMarkerClick={onSelect}
        fetchNewDataByMap={fetchNewDataByMap}
        highlightedHotelId={highlightedHotelId}
      />
    </div>
  );
};

export default memo(MapView);
