import clsx from "clsx";
import React from "react";

import layout from "@common/layout-lib.css";
import { InputText, InputTextProps } from "@widgets/input-text/input-text";

import styles from "./styles.css";

export interface InputProps extends Omit<InputTextProps, "label"> {
  label?: string;
  name?: string;
  labelClassname?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { label, className, labelClassname, required, name, ...props },
    inputRef: React.RefObject<HTMLInputElement>
  ) => {
    return (
      <label className={clsx(layout.vertical, styles.inputBox, className)}>
        {label && (
          <span className={clsx(styles.label, labelClassname)}>
            {`${label}${required ? "*" : ""}`}
          </span>
        )}
        <InputText ref={inputRef} name={name} {...props} />
      </label>
    );
  }
);

export default Input;
