import { StandardSpecialRequest } from "@adl-gen/hotel/booking";

export const SpecialRequestKeyByLabel: {
  [key: string]: StandardSpecialRequest;
} = {
  ["Early Arrival (before 3pm)"]: StandardSpecialRequest.earlyArrival,
  ["Late Arrival (after 7pm)"]: StandardSpecialRequest.lateArrival,
  ["Late Check Out (after 10am)"]: StandardSpecialRequest.lateCheckOut,
  ["Honeymooners"]: StandardSpecialRequest.honeyMooners,
  ["Interconnecting Rooms"]: StandardSpecialRequest.interconnectingRooms,
  ["Non Smoking Room"]: StandardSpecialRequest.nonSmokingRoom,
  ["Smoking Room"]: StandardSpecialRequest.smokingRoom,
  ["King Bed in Room"]: StandardSpecialRequest.kingBed,
  ["Room On A High Floor"]: StandardSpecialRequest.roomOnHighFloor,
  ["Room On A Low Floor"]: StandardSpecialRequest.roomOnLowFloor,
  ["Baby Cot"]: StandardSpecialRequest.babyCot,
  ["Two Beds in Room"]: StandardSpecialRequest.twoBedsInRoom,
};
