export const markWidth = 80;
export const markHeight = 40;
export const infoWindowWidth = 308;
export const infoWindowHeight = 100;

export const positionTop = 0;
export const positionRight = (infoWindowWidth + markWidth) / 2;
export const positionBottom = (markHeight + infoWindowHeight);
export const positionLeft = -positionRight;
export const positionHorizontalMiddle = 0;
export const positionVerticalMiddle = positionBottom / 2;

export const verticalOffset = infoWindowHeight + markHeight + 5; // Y
export const horizontalOffset = infoWindowWidth / 2; // X