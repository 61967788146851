import Rollbar from "rollbar";

import { UiConfig } from "@adl-gen/hotel/uiconfig";

let rollbar;

export function initRollbar(uiConfig: UiConfig) {
  // Load Rollbar
  // NOTE: We are following the NodeJS style configuration as opposed to the
  // browser JS style because we do not want to load it via index.html and also
  // have a handle on the created Rollbar object.
  // See: https://docs.rollbar.com/docs/nodejs
  rollbar = (function (): Rollbar | undefined {
    if (uiConfig.rollbar) {
      // tslint:disable-next-line:no-console
      console.log("Loading Rollbar");
      return new Rollbar({
        accessToken: uiConfig.rollbar.accessToken,
        payload: {
          environment: uiConfig.environment,
        },

        // We register our own error handlers and send to Rollbar manually
        captureUncaught: false,
        captureUnhandledRejections: false,
      });
    } else {
      return undefined;
    }
  })();
}

export function sendErrorToRollbar(error: Error, payload: unknown) {
  if (rollbar) {
    rollbar.error(
      error.name, // message
      error, // exception object
      payload // custom payload
    );
  }
}

export function trackAndLogError(errorMsg: string, additionalInfo: Record<string, unknown>) {
  sendErrorToRollbar(new Error(errorMsg), {
    additionalInfo
  });
  console.error(`${errorMsg}, related state: ${JSON.stringify(additionalInfo)}`);
}