import { LiveStatus } from "@adl-gen/hotel/booking";
import { OptionType } from "@widgets/input-multi-select/types";

export const LIVE_STATUS_OPTIONS: OptionType[] = [
  {
    key: LiveStatus.draft,
    label: "Draft",
  },
  {
    key: LiveStatus.confirmed,
    label: "Confirmed",
  },
  {
    key: LiveStatus.pendingConfirmation,
    label: "Pending Confirmation",
  },
  {
    key: LiveStatus.unavailable,
    label: "Unavailable",
  },
  {
    key: LiveStatus.cancelled,
    label: "Cancelled",
  },
  {
    key: LiveStatus.travelled,
    label: "Travelled",
  },
];

export const PAYMENT_STATUS_OPTIONS: OptionType[] = [
  {
    key: 1,
    label: "Pending",
  },
  {
    key: 0,
    label: "Paid",
  },
];
