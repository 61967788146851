/* @generated from adl module common */

import * as ADL from './runtime/adl';
import * as common_strings from './common/strings';

/**
 * A instant in time, represented as milliseconds from
 * the epoch of "1970-01-01T00:00:00Z
 */
export type Instant = number;

const Instant_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A instant in time, represented as milliseconds from\nthe epoch of \"1970-01-01T00:00:00Z\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"Int64"},"parameters":[]}}},"name":"Instant","version":{"kind":"nothing"}}};

export const snInstant: ADL.ScopedName = {moduleName:"common", name:"Instant"};

export function texprInstant(): ADL.ATypeExpr<Instant> {
  return {value : {typeRef : {kind: "reference", value : snInstant}, parameters : []}};
}

/**
 * A date in ISO8601 format
 */
export type LocalDate = string;

const LocalDate_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A date in ISO8601 format\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"just","value":"1970-01-01"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"LocalDate","version":{"kind":"nothing"}}};

export const snLocalDate: ADL.ScopedName = {moduleName:"common", name:"LocalDate"};

export function texprLocalDate(): ADL.ATypeExpr<LocalDate> {
  return {value : {typeRef : {kind: "reference", value : snLocalDate}, parameters : []}};
}

/**
 * A time in ISO8601 format
 */
export type LocalTime = string;

const LocalTime_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A time in ISO8601 format\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"just","value":"00:00:00"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"LocalTime","version":{"kind":"nothing"}}};

export const snLocalTime: ADL.ScopedName = {moduleName:"common", name:"LocalTime"};

export function texprLocalTime(): ADL.ATypeExpr<LocalTime> {
  return {value : {typeRef : {kind: "reference", value : snLocalTime}, parameters : []}};
}

/**
 * A datetime in ISO8601 format
 */
export type LocalDateTime = string;

const LocalDateTime_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A datetime in ISO8601 format\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"just","value":"1970-01-01T00:00:00"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"LocalDateTime","version":{"kind":"nothing"}}};

export const snLocalDateTime: ADL.ScopedName = {moduleName:"common", name:"LocalDateTime"};

export function texprLocalDateTime(): ADL.ATypeExpr<LocalDateTime> {
  return {value : {typeRef : {kind: "reference", value : snLocalDateTime}, parameters : []}};
}

/**
 * A duration in ISO8601 format
 */
export type Duration = string;

const Duration_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"interval"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A duration in ISO8601 format\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"just","value":"P1D"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Duration","version":{"kind":"nothing"}}};

export const snDuration: ADL.ScopedName = {moduleName:"common", name:"Duration"};

export function texprDuration(): ADL.ATypeExpr<Duration> {
  return {value : {typeRef : {kind: "reference", value : snDuration}, parameters : []}};
}

/**
 * An IANA timezone
 */
export type Timezone = common_strings.StringNE;

const Timezone_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An IANA timezone\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}},"name":"Timezone","version":{"kind":"nothing"}}};

export const snTimezone: ADL.ScopedName = {moduleName:"common", name:"Timezone"};

export function texprTimezone(): ADL.ATypeExpr<Timezone> {
  return {value : {typeRef : {kind: "reference", value : snTimezone}, parameters : []}};
}

/**
 * A holder for paginated results
 */
export interface Paginated<T> {
  /**
   * The paginated items
   */
  items: T[];
  /**
   * The offset used for this query
   */
  current_offset: number;
  /**
   * The size of the entire date set
   */
  total_size: number;
}

export function makePaginated<T>(
  input: {
    items: T[],
    current_offset: number,
    total_size: number,
  }
): Paginated<T> {
  return {
    items: input.items,
    current_offset: input.current_offset,
    total_size: input.total_size,
  };
}

const Paginated_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A holder for paginated results\n"}],"type_":{"kind":"struct_","value":{"typeParams":["T"],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"The paginated items\n"}],"serializedName":"items","default":{"kind":"nothing"},"name":"items","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"typeParam","value":"T"},"parameters":[]}]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"The offset used for this query\n"}],"serializedName":"current_offset","default":{"kind":"nothing"},"name":"current_offset","typeExpr":{"typeRef":{"kind":"primitive","value":"Int64"},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"The size of the entire date set\n"}],"serializedName":"total_size","default":{"kind":"nothing"},"name":"total_size","typeExpr":{"typeRef":{"kind":"primitive","value":"Int64"},"parameters":[]}}]}},"name":"Paginated","version":{"kind":"nothing"}}};

export const snPaginated: ADL.ScopedName = {moduleName:"common", name:"Paginated"};

export function texprPaginated<T>(texprT : ADL.ATypeExpr<T>): ADL.ATypeExpr<Paginated<T>> {
  return {value : {typeRef : {kind: "reference", value : {moduleName : "common",name : "Paginated"}}, parameters : [texprT.value]}};
}

/**
 * Empty Struct (Used mostly for Void RPC responses)
 */
export interface Unit {
}

export function makeUnit(
  _input: {
  }
): Unit {
  return {
  };
}

const Unit_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Empty Struct (Used mostly for Void RPC responses)\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[]}},"name":"Unit","version":{"kind":"nothing"}}};

export const snUnit: ADL.ScopedName = {moduleName:"common", name:"Unit"};

export function texprUnit(): ADL.ATypeExpr<Unit> {
  return {value : {typeRef : {kind: "reference", value : snUnit}, parameters : []}};
}

/**
 * Phantom type to capture a StringMap with a named string key type:
 */
export type StringKeyMap<_K, V> = {[key: string]: V};

const StringKeyMap_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Phantom type to capture a StringMap with a named string key type:\n"}],"type_":{"kind":"type_","value":{"typeParams":["K","V"],"typeExpr":{"typeRef":{"kind":"primitive","value":"StringMap"},"parameters":[{"typeRef":{"kind":"typeParam","value":"V"},"parameters":[]}]}}},"name":"StringKeyMap","version":{"kind":"nothing"}}};

export const snStringKeyMap: ADL.ScopedName = {moduleName:"common", name:"StringKeyMap"};

export function texprStringKeyMap<K, V>(texprK : ADL.ATypeExpr<K>, texprV : ADL.ATypeExpr<V>): ADL.ATypeExpr<StringKeyMap<K, V>> {
  return {value : {typeRef : {kind: "reference", value : {moduleName : "common",name : "StringKeyMap"}}, parameters : [texprK.value, texprV.value]}};
}

/**
 * Naming aid for strings used as keys
 */
export type Key<_T> = string;

const Key_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Naming aid for strings used as keys\n"}],"type_":{"kind":"type_","value":{"typeParams":["T"],"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Key","version":{"kind":"nothing"}}};

export const snKey: ADL.ScopedName = {moduleName:"common", name:"Key"};

export function texprKey<T>(texprT : ADL.ATypeExpr<T>): ADL.ATypeExpr<Key<T>> {
  return {value : {typeRef : {kind: "reference", value : {moduleName : "common",name : "Key"}}, parameters : [texprT.value]}};
}

/**
 * A value of type T along with the Key<T>
 */
export interface WithKey<T> {
  key: Key<T>;
  value: T;
}

export function makeWithKey<T>(
  input: {
    key: Key<T>,
    value: T,
  }
): WithKey<T> {
  return {
    key: input.key,
    value: input.value,
  };
}

const WithKey_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A value of type T along with the Key<T>\n"}],"type_":{"kind":"struct_","value":{"typeParams":["T"],"fields":[{"annotations":[],"serializedName":"key","default":{"kind":"nothing"},"name":"key","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Key"}},"parameters":[{"typeRef":{"kind":"typeParam","value":"T"},"parameters":[]}]}},{"annotations":[],"serializedName":"value","default":{"kind":"nothing"},"name":"value","typeExpr":{"typeRef":{"kind":"typeParam","value":"T"},"parameters":[]}}]}},"name":"WithKey","version":{"kind":"nothing"}}};

export const snWithKey: ADL.ScopedName = {moduleName:"common", name:"WithKey"};

export function texprWithKey<T>(texprT : ADL.ATypeExpr<T>): ADL.ATypeExpr<WithKey<T>> {
  return {value : {typeRef : {kind: "reference", value : {moduleName : "common",name : "WithKey"}}, parameters : [texprT.value]}};
}

/**
 * Postgres array of strings type that is serialized in to a list of Strings
 */
export type StringList = string[];

const StringList_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"text[]"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Postgres array of strings type that is serialized in to a list of Strings\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}]}}},"name":"StringList","version":{"kind":"nothing"}}};

export const snStringList: ADL.ScopedName = {moduleName:"common", name:"StringList"};

export function texprStringList(): ADL.ATypeExpr<StringList> {
  return {value : {typeRef : {kind: "reference", value : snStringList}, parameters : []}};
}

/**
 * Postgres Geography type that is serialized using GeoJson
 */
export type GeographyGeoJson = string;

const GeographyGeoJson_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"geography"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Postgres Geography type that is serialized using GeoJson\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"GeographyGeoJson","version":{"kind":"nothing"}}};

export const snGeographyGeoJson: ADL.ScopedName = {moduleName:"common", name:"GeographyGeoJson"};

export function texprGeographyGeoJson(): ADL.ATypeExpr<GeographyGeoJson> {
  return {value : {typeRef : {kind: "reference", value : snGeographyGeoJson}, parameters : []}};
}

/**
 * Postgres Geometry type
 */
export type GeometryWKT = string;

const GeometryWKT_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"geometry"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Postgres Geometry type\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"GeometryWKT","version":{"kind":"nothing"}}};

export const snGeometryWKT: ADL.ScopedName = {moduleName:"common", name:"GeometryWKT"};

export function texprGeometryWKT(): ADL.ATypeExpr<GeometryWKT> {
  return {value : {typeRef : {kind: "reference", value : snGeometryWKT}, parameters : []}};
}

/**
 * A floating point decimal value
 */
export type BigDecimal = string;

const BigDecimal_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"numeric"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A floating point decimal value\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"BigDecimal","version":{"kind":"nothing"}}};

export const snBigDecimal: ADL.ScopedName = {moduleName:"common", name:"BigDecimal"};

export function texprBigDecimal(): ADL.ATypeExpr<BigDecimal> {
  return {value : {typeRef : {kind: "reference", value : snBigDecimal}, parameters : []}};
}

/**
 * Represents a (latitude, longitude) coordinate pair
 */
export interface LatLng {
  lat: number;
  lng: number;
}

export function makeLatLng(
  input: {
    lat: number,
    lng: number,
  }
): LatLng {
  return {
    lat: input.lat,
    lng: input.lng,
  };
}

const LatLng_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"geography"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Represents a (latitude, longitude) coordinate pair\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"lat","default":{"kind":"nothing"},"name":"lat","typeExpr":{"typeRef":{"kind":"primitive","value":"Double"},"parameters":[]}},{"annotations":[],"serializedName":"lng","default":{"kind":"nothing"},"name":"lng","typeExpr":{"typeRef":{"kind":"primitive","value":"Double"},"parameters":[]}}]}},"name":"LatLng","version":{"kind":"nothing"}}};

export const snLatLng: ADL.ScopedName = {moduleName:"common", name:"LatLng"};

export function texprLatLng(): ADL.ATypeExpr<LatLng> {
  return {value : {typeRef : {kind: "reference", value : snLatLng}, parameters : []}};
}

/**
 * Represents a geographic polygon defined by a set of polygons.
 * Each polygon is defined by a set of coordinates/points.
 * Stored as a geometry in the DB to enable a gist index to be added for
 * faster lookup
 */
export type GeoPolygon = LatLng[][];

const GeoPolygon_AST : ADL.ScopedDecl =
  {"moduleName":"common","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbColumnType"},"v2":"geometry"},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Represents a geographic polygon defined by a set of polygons.\nEach polygon is defined by a set of coordinates/points.\nStored as a geometry in the DB to enable a gist index to be added for\nfaster lookup\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LatLng"}},"parameters":[]}]}]}}},"name":"GeoPolygon","version":{"kind":"nothing"}}};

export const snGeoPolygon: ADL.ScopedName = {moduleName:"common", name:"GeoPolygon"};

export function texprGeoPolygon(): ADL.ATypeExpr<GeoPolygon> {
  return {value : {typeRef : {kind: "reference", value : snGeoPolygon}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "common.Instant" : Instant_AST,
  "common.LocalDate" : LocalDate_AST,
  "common.LocalTime" : LocalTime_AST,
  "common.LocalDateTime" : LocalDateTime_AST,
  "common.Duration" : Duration_AST,
  "common.Timezone" : Timezone_AST,
  "common.Paginated" : Paginated_AST,
  "common.Unit" : Unit_AST,
  "common.StringKeyMap" : StringKeyMap_AST,
  "common.Key" : Key_AST,
  "common.WithKey" : WithKey_AST,
  "common.StringList" : StringList_AST,
  "common.GeographyGeoJson" : GeographyGeoJson_AST,
  "common.GeometryWKT" : GeometryWKT_AST,
  "common.BigDecimal" : BigDecimal_AST,
  "common.LatLng" : LatLng_AST,
  "common.GeoPolygon" : GeoPolygon_AST
};
