import classnames from "classnames";
import React, { FC } from "react";

import styles from "./styles.css";

interface Props {
  items: string[];
  className?: string;
}

const Amenities: FC<Props> = ({ items, className }) => {
  // TODO(jess): Currently everything has just been grouped into one section, need to split it up when api is ready
  const half = Math.ceil(items.length / 2);

  const AmenityBlock = (props: {
    title: string;
    blockItems: string[];
  }): JSX.Element => {
    const { title, blockItems } = props;

    return (
      <div className={styles.amenityBlock}>
        <div className={styles.blockTitle}>{title}</div>
        {blockItems.map((amenityVal) => (
          <span key={amenityVal} className={styles.blockValue}>
            {amenityVal}
          </span>
        ))}
      </div>
    );
  };
  return (
    <div className={classnames(styles.root, className)}>
      <AmenityBlock title={""} blockItems={items.slice(0, half)} />
      <AmenityBlock title={""} blockItems={items.slice(-half)} />
    </div>
  );
};

export default Amenities;
