import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";

import { PaymentMode } from "@adl-gen/hotel/booking";
import { LoggedInContext } from "@app/app";
import { BaseProps } from "@common/base";
import { PaymentType } from "@constants/common";
import { assertNotUndefined } from "@hx/util/types";
import { throws } from "@util/validation";

import BankTransferPayment from "./bank-transfer-payment";
import BookAndHold from "./book-and-hold";
import { PaymentFormMode, PAYMENT_LIST } from "./constants";
import CreditCardPayment from "./credit-card-payment";
import styles from "./payment-form.css";
import { CommonPriceConfigProps } from "./price-config";

export type StripeCallback = (clientSecret: string) => Promise<void>;

/** Component Props */
export interface PaymentFormProps extends BaseProps, CommonPriceConfigProps {
  classes?: { paymentForm?: string };
  mode: PaymentFormMode;
  // Used for validation additional data during payment process (i.e. booking form)
  isExternalDataValid?(): boolean;
  confirmBooking?(): Promise<void>;
  onCancel?(): void;
  onSubmitCreditCard(stripeCallback: StripeCallback): Promise<void>;
}

const PaymentForm: FC<PaymentFormProps> = ({
  classes,
  mode,
  isExternalDataValid,
  confirmBooking,
  onSubmitCreditCard,
  onCancel,
  ...priceProps
}) => {
  const itineraryController = assertNotUndefined(
    useContext(LoggedInContext).itineraryController
  );

  const paymentList = PAYMENT_LIST.filter((value) =>
    value.supportedBy.includes(mode)
  );

  const defaultPaymentType =
    paymentList[0]?.key ||
    throws(new Error(`${mode} does not support any payment types`));

  const [paymentType, setPaymentType] = useState<PaymentType>(
    defaultPaymentType
  );

  useEffect(() => {
    priceProps.onChangePaymentMode(PaymentMode.gross);
    itineraryController.selectPaymentType(paymentType);
  }, [paymentType]);

  useEffect(() => {
    setPaymentType(defaultPaymentType);
  }, [mode]);

  function handleBankTransferPay() {
    const externalDataValid = isExternalDataValid
      ? isExternalDataValid()
      : true;

    if (externalDataValid && confirmBooking) {
      confirmBooking();
    }
  }

  return (
    <div className={clsx(styles.payment, classes?.paymentForm)}>
      {paymentList.length > 1 && (
        <div className={styles.headerTabs}>
          {paymentList.map(({ key, title }) => (
            <div
              key={key}
              className={clsx(
                styles.tab,
                paymentType === key && styles.activeTab
              )}
              onClick={() => setPaymentType(key)}
            >
              {title}
            </div>
          ))}
        </div>
      )}

      <div className={clsx(styles.paymentForm)}>
        {paymentType === PaymentType.CreditCardPayment && (
          <CreditCardPayment
            mode={mode}
            isExternalDataValid={isExternalDataValid}
            onSubmit={onSubmitCreditCard}
            onCancel={onCancel}
            {...priceProps}
          />
        )}

        {paymentType === PaymentType.BankTransfer && (
          <BankTransferPayment
            {...priceProps}
            onBankTransferPay={handleBankTransferPay}
          />
        )}

        {paymentType === PaymentType.BookAndHold && (
          <BookAndHold
            {...priceProps}
            isExternalDataValid={isExternalDataValid}
            onSubmit={
              confirmBooking ||
              throws(
                new Error(
                  "invalid state: PaymentForm needs to have a confirmBooking when using BookAndHold payment type"
                )
              )
            }
          />
        )}
      </div>
    </div>
  );
};

export default PaymentForm;
