import React, { FC, useCallback, useEffect, useRef, useState } from "react";

import ModalWindow from "@widgets/modal-window";

import Carousel from "../carousel";
import ImagesList from "../images-list";
import styles from "./styles.css";

interface Props {
  open: boolean;
  onClose(): void;
  images: string[];
  currentImage: number;
}

const CarouselModal: FC<Props> = ({ open, onClose, images, currentImage }) => {
  const [heroImage, setHeroImage] = useState(currentImage);

  const keyUpHandler = useCallback(
    (e: KeyboardEvent) => {
      // A workaround to get the current state value in the closure
      let current = 0;
      setHeroImage((currentValue) => {
        current = currentValue;
        return currentValue;
      });
      if (e.code === "ArrowRight") {
        onChangeImage(current + 1);
      }

      if (e.code === "ArrowLeft") {
        onChangeImage(current - 1);
      }
    },
    [heroImage]
  );

  useEffect(() => {
    if (open) {
      setHeroImage(currentImage);
      document.addEventListener("keyup", keyUpHandler);

      return () => {
        document.removeEventListener("keyup", keyUpHandler);
      };
    }
  }, [open]);

  const listRef = useRef<HTMLUListElement | null>(null);

  function onChangeImage(index: number) {
    const targetIndex =
      index === images.length ? 0 : index === -1 ? images.length - 1 : index;
    setHeroImage(targetIndex);

    adjustListScroll(targetIndex);
  }

  function onClickHeroImage() {
    onChangeImage(heroImage + 1);
  }

  function adjustListScroll(targetIndex: number) {
    const ref = listRef.current;
    if (ref) {
      const bottomBorder = 134 * (targetIndex + 1);
      const topBorder = 134 * targetIndex;
      const currentScroll = ref.scrollTop;
      const height = ref.getBoundingClientRect().height;

      if (bottomBorder > currentScroll + height) {
        ref.scroll({ left: 0, top: bottomBorder - height });
      }

      if (topBorder < currentScroll) {
        ref.scroll({ left: 0, top: topBorder });
      }
    }
  }
  return (
    <ModalWindow
      className={styles.content}
      open={open}
      onClose={onClose}
      closeButtonVariant="viewportCorner"
    >
      <ImagesList
        images={images}
        currentImage={heroImage}
        onSelectImage={onChangeImage}
        vertical
        ref={listRef}
      />
      <Carousel
        images={images}
        currentImage={heroImage}
        onChange={onChangeImage}
        fullscreen
        onClick={onClickHeroImage}
      />
    </ModalWindow>
  );
};

export default CarouselModal;
