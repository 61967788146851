import React, { FC, memo, useContext, useEffect } from "react";

import { LoggedInContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import ModalWindow from "@widgets/modal-window";

import styles from "./styles.css";

interface Props {
  open: boolean;
  onClose(): void;
}

const TermsAndConditionsModal: FC<Props> = ({ open, onClose }) => {
  const { termsAndConditions, getTermsAndConditions } = assertNotUndefined(
    useContext(LoggedInContext).itineraryController
  );

  useEffect(() => {
    if (!termsAndConditions) {
      getTermsAndConditions();
    }
  }, []);

  return (
    <ModalWindow
      className={styles.termsAndConditionsModal}
      open={open}
      onClose={onClose}
    >
      <div className={styles.termsAndConditions}>
        <div className={styles.title}>Full Booking Terms and Conditions</div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              termsAndConditions?.content ||
              "No Booking Terms and Conditions data",
          }}
        />
      </div>
    </ModalWindow>
  );
};

export default memo(TermsAndConditionsModal);
