import clsx from "clsx";
import type { Moment } from "moment";
import moment from "moment";
import type { FC } from "react";
import React, { useMemo } from "react";

import type { ClientPaymentStatus } from "@adl-gen/hotel/booking";
import type { PackageCancellationPolicy } from "@adl-gen/hotel/common";
import type { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";
import { PAYABLE_CLIENT_STATUSES } from "@constants/booking";
import { COMMON_DATE_FORMAT } from "@constants/common";
import { calculatePaymentDue } from "@util/agentus-utis";

import styles from "./cancellation.css";

/** Component Props */
export interface CancellationProps extends BaseProps {
  classes?: { cancellation?: string };
  policy: PackageCancellationPolicy;
  checkIn: Moment;
  bookingDate: Moment;
  clientPaymentStatus: ClientPaymentStatus;
}

const Cancellation: FC<CancellationProps> = ({
  policy,
  className,
  classes,
  checkIn,
  bookingDate,
  clientPaymentStatus,
}) => {
  const cancelByDate = moment(
    calculatePaymentDue(policy.policies[0].cancelFromDate)
  );

  const todayPosition = useMemo(() => {
    const wholeRange = cancelByDate.diff(bookingDate, "days");

    const differenceFromToday = moment().diff(bookingDate, "days");

    if (differenceFromToday === 0) {
      return null;
    }

    const percentage = Math.ceil((differenceFromToday / wholeRange) * 100);
    // Divide by 2 because we place it relatively to the first half of the timeline
    const result = Math.ceil(percentage / 2);

    if (result > 100 || result < 0) {
      return null;
    }

    return result;
  }, [bookingDate, checkIn]);

  const isCancellationPast = cancelByDate.isBefore(moment(), "day");

  return (
    <div
      className={clsx(
        styles.cancellation,
        classes?.cancellation,
        className,
        layout.vertical,
        layout.spaced
      )}
    >
      <div className={styles.title}>Cancellation Policy</div>

      <div className={styles.content}>
        <div className={clsx(layout.vertical, styles.timelineContainer)}>
          <div className={styles.timelinePoints}>
            <div className={styles.timelinePoint}>
              <span>Booked</span>
              <span>{bookingDate.format(COMMON_DATE_FORMAT)}</span>
            </div>
            {!isCancellationPast && (
              <div className={clsx(styles.timelinePoint, styles.getByDate)}>
                <span>AEST</span>
                <span>{cancelByDate.format(COMMON_DATE_FORMAT)} 23:59</span>
              </div>
            )}
            <div className={styles.timelinePoint}>
              <span>Check-in</span>
              <span>{checkIn.format(COMMON_DATE_FORMAT)}</span>
            </div>
          </div>

          {isCancellationPast ? (
            <div className={clsx(styles.nonRefundable, styles.text)}>
              Non-Refundable
            </div>
          ) : (
            <section className={clsx(layout.horizontal, styles.timeline)}>
              <div className={clsx(styles.freeCancellation, styles.text)}>
                Free Cancellation
              </div>
              <div className={clsx(styles.partiallyRefundable, styles.text)}>
                Non-Refundable
              </div>
            </section>
          )}

          {!isCancellationPast
            ? PAYABLE_CLIENT_STATUSES.includes(clientPaymentStatus) && (
                <div
                  className={clsx(styles.today, layout.vertical, layout.middle)}
                  style={{
                    left: `${todayPosition}%`,
                    display: todayPosition === null ? "none" : "flex",
                  }}
                >
                  <span>Today</span>
                </div>
              )
            : null}
        </div>

        <div className={clsx(styles.notice)}>
          <span>Booking Conditions</span>
          <p dangerouslySetInnerHTML={{ __html: policy.description || "" }} />
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
