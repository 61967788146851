import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ROUTE_PATH } from "@constants/common";
import { DEFAULT_MAX_PAGE, ITEMS_PER_PAGE } from "@constants/pagination";
import { usePrevious } from "@hooks/usePrevious";
import { scrollToTop } from "@util/native-dom";

interface PaginationHookProps {
  ignoreUrlUpdate: boolean;
}

interface SetPageNumberOptions {
  // true by default
  scrollToTop: boolean;
}

interface PaginationDataType {
  currentPage: number;
  setPageNumber(page: number, options?: SetPageNumberOptions): void;
  paginationOffset: number;
  maxPage: number;
  resetPaginationValues(): void;
  setTotalCount(count: number): void;
}

export const usePagination = (
  props?: PaginationHookProps
): PaginationDataType => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const filterParams = params.getAll("filter").toString();
  const pageParams = params.get("page");
  const prevFilterParams = usePrevious(filterParams);

  const [totalCount, setTotalCount] = useState<number | null>(null);

  const maxPage = useMemo(
    () =>
      totalCount ? Math.ceil(totalCount / ITEMS_PER_PAGE) : DEFAULT_MAX_PAGE,
    [totalCount]
  );

  const defaultPage = useMemo(() => {
    const page = Number(params.get("page")) || 1;
    const newPage = page > maxPage ? maxPage : page < 1 ? 1 : page;

    params.set("page", `${newPage}`);

    return newPage;
  }, [params]);

  const [currentPage, setCurrentPage] = useState<number>(defaultPage);

  const paginationOffset = useMemo(() => (currentPage - 1) * ITEMS_PER_PAGE, [
    currentPage,
  ]);

  useEffect(() => {
    if (location.pathname.includes(ROUTE_PATH.Search)) {
      setCurrentPage(defaultPage);
    }
  }, [totalCount, defaultPage]);

  const updateURL = () => {
    location.search = params.toString();
    history.replace(location);
  };

  const setPageNumber = (page: number, options?: SetPageNumberOptions) => {
    const newPage = page > maxPage ? maxPage : page < 1 ? 1 : page;

    setCurrentPage(newPage);

    params.set("page", `${newPage}`);

    if (!props?.ignoreUrlUpdate) {
      updateURL();
    }

    if (options?.scrollToTop === false) {
      return;
    }

    scrollToTop();
  };

  const resetPaginationValues = useCallback(() => {
    setCurrentPage(1);

    params.set("page", "1");
    if (!props?.ignoreUrlUpdate) {
      updateURL();
    }
  }, [params, updateURL]);

  /** When changing any of the filters, the pagination parameters must be reset to default */
  useEffect(() => {
    // the condition shouldn't work on the first render so as not to reset the default page saved in the url
    /** ---- FIRST RENDER ---- */
    // pageParams = number
    // prevPageParams = undefined
    // filterParams = string
    // prevFilterParams = undefined

    /** ---- ADDED FIRST FILTER ---- */
    // pageParams = number
    // prevPageParams = number
    // filterParams = string
    // prevFilterParams = ""

    /** ---- ADDED ONE MORE FILTER ---- */
    // pageParams = number
    // prevPageParams = number
    // filterParams = string
    // prevFilterParams = string

    /** ---- DELETED LAST FILTER ----- */
    // pageParams = number
    // prevPageParams = number
    // filterParams = ""
    // prevFilterParams = string

    if (
      pageParams &&
      pageParams !== "1" &&
      (filterParams || (!filterParams && prevFilterParams))
    ) {
      resetPaginationValues();
    }
  }, [filterParams]);

  return {
    currentPage,
    setPageNumber,
    paginationOffset,
    maxPage,
    resetPaginationValues,
    setTotalCount,
  };
};
