import moment from "moment";
import React, { FC, useContext, useMemo, useState } from "react";

import { PaymentMode } from "@adl-gen/hotel/booking";
import {
  CancellationPolicy,
  PackageCancellationPolicy,
} from "@adl-gen/hotel/common";
import { LoggedInContext } from "@app/app";
import { COMMON_DATE_FORMAT } from "@constants/common";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";
import { assertNotUndefined } from "@hx/util/types";
import {
  calculatePaymentDue,
  getSupplierPaymentDue,
  renderPriceString,
} from "@util/agentus-utis";
import CancellationModal from "@pages/complete-booking-page/complete-booking/cancellation-policy-listing/cancellation-modal";

import styles from "./styles.css";

interface Props {
  policy: PackageCancellationPolicy;
  paymentMode: PaymentMode;
  canBookAndHold: boolean;
}

const CancellationPolicyListing: FC<Props> = ({
  policy,
  paymentMode,
  canBookAndHold,
}) => {
  const [modalOpened, setModalOpened] = useState(false);

  const { sensitiveDataHidden } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  const payByDate = useMemo(() => {
    const supplierPayByDate = getSupplierPaymentDue(policy).format(
      LOCALDATE_FORMAT
    );
    return calculatePaymentDue(supplierPayByDate);
  }, [policy]);


  return (
    <div className={styles.cancellationPolicy}>
      <div className={styles.header}>Payment Due &amp; Cancellation Policy</div>
      <hr className={styles.divider} />
      <div className={styles.row}>
        <div>
          <span className={styles.dueBy}>Payment due by</span>{" "}
          {canBookAndHold
            ? `${moment(payByDate).format(COMMON_DATE_FORMAT)} 23:59`
            : "Now"}
        </div>
      </div>
      {policy.policies.map((p: CancellationPolicy, idx: number) => (
        <div key={`p${idx}`} className={styles.row}>
          <div>
            <span className={styles.cancellation}>Cancellation</span> From 12:00
            AM on{" "}
            {moment(p.cancelFromDate, LOCALDATE_FORMAT).format(
              COMMON_DATE_FORMAT
            )}
          </div>
          <div>
            {renderPriceString(
              paymentMode === PaymentMode.gross || sensitiveDataHidden
                ? p.grossCancelFee
                : p.netCancelFee
            )}
          </div>
        </div>
      ))}
      <p className={styles.hint}>
        When booking with Agentus you agree to the following{" "}
        <span className={styles.cancellationCTA} onClick={() => setModalOpened(true)}>
          Cancelation Policy
        </span>
        .
      </p>

      <CancellationModal
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        policy={policy}
        payByDate={moment(payByDate)}
        paymentMode={paymentMode}
      />
    </div>
  );
};

export default CancellationPolicyListing;
