import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { HotelSearchSortOrder } from "@adl-gen/hotel/api";

import {
  DEFAULT_ORDER_VALUES,
  LocationTypeEnum,
  ORDER_PARAM,
} from "../hotel-search/constant";

export const useSortOrder = (): {
  sortOrder: HotelSearchSortOrder;
  changeSortOrder(order: HotelSearchSortOrder): void;
  getDefaultOrder(locationType: LocationTypeEnum): HotelSearchSortOrder | null;
} => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const orderUrl = params.get(ORDER_PARAM);

  const defaultOrderValueFromUrl: HotelSearchSortOrder = useMemo(() => {
    if (orderUrl === HotelSearchSortOrder.distance.toString()) {
      return HotelSearchSortOrder.distance;
    } else {
      return HotelSearchSortOrder.popularity;
    }
  }, [params]);

  const [sortOrder, setSortOrder] = useState<HotelSearchSortOrder>(
    defaultOrderValueFromUrl
  );

  const changeSortOrder = useCallback(
    (order: HotelSearchSortOrder) => {
      setSortOrder(order);

      params.set(ORDER_PARAM, order.toString());
      location.search = params.toString();
      history.replace(location);
    },
    [setSortOrder, params, location]
  );

  // function is called before new search in startNewHotelSearch function
  function getDefaultOrder(
    locationType: LocationTypeEnum
  ): HotelSearchSortOrder | null {
    const defaultOrder = DEFAULT_ORDER_VALUES[locationType];

    if (defaultOrder !== undefined && defaultOrder !== null) {
      setSortOrder(defaultOrder);
    }

    return defaultOrder;
  }

  return { sortOrder, changeSortOrder, getDefaultOrder };
};
