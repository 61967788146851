import React from "react";

import { LiveStatus } from "@adl-gen/hotel/booking";
import {
  ConfirmedIcon,
  ErrorOutlinedIcon,
  PendingConfirmationIcon,
  UnavailableIcon,
  WarningOutlinedIcon,
} from "@assets/svg-components";

export const BOOKING_STATUS_ICON = {
  [LiveStatus.confirmed]: <ConfirmedIcon />,
  [LiveStatus.draft]: <WarningOutlinedIcon />,
  [LiveStatus.quote]: <WarningOutlinedIcon />,
  [LiveStatus.pendingConfirmation]: <PendingConfirmationIcon />,
  [LiveStatus.cancelled]: <ErrorOutlinedIcon />,
  [LiveStatus.unavailable]: <UnavailableIcon />,
  [LiveStatus.travelled]: <WarningOutlinedIcon />,
};
