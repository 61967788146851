import { LineItemType } from "@adl-gen/hotel/booking";

/**
 * Converts the line item type to a client friendly string, values taken from
 * https://xllabs.atlassian.net/wiki/spaces/AT/pages/41418976/Live+Status+Payment+Status+Item+Attributes+and+Line+Items#Line-items
 */
export const FINANCIAL_ITEM_LABEL: Record<LineItemType, string> = {
  [LineItemType.base]: "Item Price",
  [LineItemType.confirmationAdjustment]: "Item price changed on confirmation",
  [LineItemType.systemAdjustment]: "System adjustment",
  [LineItemType.adjustment]: "Re-pricing",
  [LineItemType.amendment]: "Item amendment",
  [LineItemType.cancellation]: "Item cancellation",
  [LineItemType.clientPayment]: "Client payment",
  [LineItemType.discount]: "Discount",
  [LineItemType.payables]: "Supplier payables",
  [LineItemType.refund]: "Refund",
  [LineItemType.commission]: "Commission paid",
};
