import React, { useEffect, useState } from "react";

import { DeclResolver } from "../adl-gen/runtime/adl";

import { AdminUiMainPage } from "./page/main-page";
import { AdminUiTablePage } from "./page/table-page";
import { AdminUiValuePage } from "./page/value-page";
import { AdminRoute } from "./routes";
import { AdminService } from "./service";
import { renderLoading } from "./ui-utils";
import { ActionFactory, AdminHrefFactory, getActions, Loading, loadMetadata, Metadata} from "./utils";


/** The component that contains and switches between the admin ui pages */

export interface AdminUiContainerProps {
  route: AdminRoute;
  navigateTo(adminRoute: AdminRoute): void;
  onDone(): void;
  resolver: DeclResolver;
  service: AdminService;
  hrefFactory: AdminHrefFactory;
  actionfns: ActionFactory[];
}


export const AdminUiContainer = (props:AdminUiContainerProps) => {

  // We load the admin metadata in this component as it's used
  // in all the subcomponents
  const [loadedMetadata, setLoadedMetadata] = useState<Loading<Metadata>>({kind:"loading"});

  async function load() {
      const metadata = await loadMetadata(props.service, props.resolver);
      setLoadedMetadata({kind:'ready', value:metadata});
  }

  useEffect(() => {
    void load();
  }, []);

  function renderPage(metadata: Metadata) {
    switch (props.route.route) {
    case "main":
      return (
        <AdminUiMainPage
          metadata={metadata}
          onClickTable={(table: string) => props.navigateTo({route:'table', table})}
          actions={getActions(props.actionfns, metadata, props.resolver, props.hrefFactory)}
        />
      );
    case "table":
      return (
        <AdminUiTablePage
          service={props.service}
          tableName={props.route.table}
          metadata={metadata}
          hrefFactory={props.hrefFactory}
          onGotoValue={(table:string, id:string) => props.navigateTo({route:"value", table, id})}
          onDone={() => props.navigateTo({route:"main"})}
          key={props.route.table}
        />
      );
    case "value":
      return (
        <AdminUiValuePage
          service={props.service}
          appDeclResolver={props.resolver}
          hrefFactory={props.hrefFactory}
          table={props.route.table}
          id={props.route.id}
          onDone={() => props.navigateTo({route:"main"})}
          metadata={metadata}
        />
      );
    }
  }

  return renderLoading(loadedMetadata, renderPage);
}
