/* @generated from adl module hotel.common */

import * as ADL from './../runtime/adl';
import * as common from './../common';
import * as common_strings from './../common/strings';

/**
 * Primary key of IDS hotel table.
 */
export type HotelId = common_strings.StringNE;

const HotelId_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Primary key of IDS hotel table.\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}},"name":"HotelId","version":{"kind":"nothing"}}};

export const snHotelId: ADL.ScopedName = {moduleName:"hotel.common", name:"HotelId"};

export function texprHotelId(): ADL.ATypeExpr<HotelId> {
  return {value : {typeRef : {kind: "reference", value : snHotelId}, parameters : []}};
}

/**
 * Primary key of the IDS supplier hotel table.
 */
export type SupplierHotelId = common_strings.StringNE;

const SupplierHotelId_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Primary key of the IDS supplier hotel table.\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}},"name":"SupplierHotelId","version":{"kind":"nothing"}}};

export const snSupplierHotelId: ADL.ScopedName = {moduleName:"hotel.common", name:"SupplierHotelId"};

export function texprSupplierHotelId(): ADL.ATypeExpr<SupplierHotelId> {
  return {value : {typeRef : {kind: "reference", value : snSupplierHotelId}, parameters : []}};
}

/**
 * Primary key of the IDS supplier table.
 */
export type SupplierId = common_strings.StringNE;

const SupplierId_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Primary key of the IDS supplier table.\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}},"name":"SupplierId","version":{"kind":"nothing"}}};

export const snSupplierId: ADL.ScopedName = {moduleName:"hotel.common", name:"SupplierId"};

export function texprSupplierId(): ADL.ATypeExpr<SupplierId> {
  return {value : {typeRef : {kind: "reference", value : snSupplierId}, parameters : []}};
}

/**
 * Primary key of the IDS hotel room table.
 */
export type HotelRoomId = common_strings.StringNE;

const HotelRoomId_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Primary key of the IDS hotel room table.\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}},"name":"HotelRoomId","version":{"kind":"nothing"}}};

export const snHotelRoomId: ADL.ScopedName = {moduleName:"hotel.common", name:"HotelRoomId"};

export function texprHotelRoomId(): ADL.ATypeExpr<HotelRoomId> {
  return {value : {typeRef : {kind: "reference", value : snHotelRoomId}, parameters : []}};
}

/**
 * IP address inferred from various HTTP request
 * header attributes.
 */
export type IpAddress = string;

const IpAddress_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"IP address inferred from various HTTP request\nheader attributes.\n"}],"type_":{"kind":"newtype_","value":{"typeParams":[],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"IpAddress","version":{"kind":"nothing"}}};

export const snIpAddress: ADL.ScopedName = {moduleName:"hotel.common", name:"IpAddress"};

export function texprIpAddress(): ADL.ATypeExpr<IpAddress> {
  return {value : {typeRef : {kind: "reference", value : snIpAddress}, parameters : []}};
}

export type Bsb = string;

const Bsb_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"common.ui","name":"ValidRegex"},"v2":{"regex":"^[0-9]{6}$","description":"A 6 digit number used to identify the individual branch of an Australian bank or financial institution."}}],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Bsb","version":{"kind":"nothing"}}};

export const snBsb: ADL.ScopedName = {moduleName:"hotel.common", name:"Bsb"};

export function texprBsb(): ADL.ATypeExpr<Bsb> {
  return {value : {typeRef : {kind: "reference", value : snBsb}, parameters : []}};
}

/**
 * Information relating to cancellation policy details.
 */
export interface PackageCancellationPolicy {
  /**
   * Free string describing cancellation policy which will be displayed on the booking.
   */
  description: (string|null);
  /**
   * A list of cancellation policies for this package
   */
  policies: CancellationPolicy[];
}

export function makePackageCancellationPolicy(
  input: {
    description?: (string|null),
    policies: CancellationPolicy[],
  }
): PackageCancellationPolicy {
  return {
    description: input.description === undefined ? null : input.description,
    policies: input.policies,
  };
}

const PackageCancellationPolicy_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Information relating to cancellation policy details.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Free string describing cancellation policy which will be displayed on the booking.\n"}],"serializedName":"description","default":{"kind":"just","value":null},"name":"description","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A list of cancellation policies for this package\n"}],"serializedName":"policies","default":{"kind":"nothing"},"name":"policies","typeExpr":{"typeRef":{"kind":"primitive","value":"Vector"},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"CancellationPolicy"}},"parameters":[]}]}}]}},"name":"PackageCancellationPolicy","version":{"kind":"nothing"}}};

export const snPackageCancellationPolicy: ADL.ScopedName = {moduleName:"hotel.common", name:"PackageCancellationPolicy"};

export function texprPackageCancellationPolicy(): ADL.ATypeExpr<PackageCancellationPolicy> {
  return {value : {typeRef : {kind: "reference", value : snPackageCancellationPolicy}, parameters : []}};
}

/**
 * Cancellation policy for a package.
 */
export interface CancellationPolicy {
  /**
   * Starting date from which the relevant cancellation fees apply.
   */
  cancelFromDate: common.LocalDate;
  /**
   * Fixed net cancellation fee for cancellation after or on the cancelFromDate.
   */
  netCancelFee: PriceValue;
  /**
   * Fixed gross cancellation fee for cancellation after or on the cancelFromDate.
   */
  grossCancelFee: PriceValue;
  /**
   * An additional description for this particular period of cancellation, e.g. "No show"
   */
  description: (string|null);
}

export function makeCancellationPolicy(
  input: {
    cancelFromDate: common.LocalDate,
    netCancelFee: PriceValue,
    grossCancelFee: PriceValue,
    description?: (string|null),
  }
): CancellationPolicy {
  return {
    cancelFromDate: input.cancelFromDate,
    netCancelFee: input.netCancelFee,
    grossCancelFee: input.grossCancelFee,
    description: input.description === undefined ? null : input.description,
  };
}

const CancellationPolicy_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Cancellation policy for a package.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Starting date from which the relevant cancellation fees apply.\n"}],"serializedName":"cancelFromDate","default":{"kind":"nothing"},"name":"cancelFromDate","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LocalDate"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Fixed net cancellation fee for cancellation after or on the cancelFromDate.\n"}],"serializedName":"netCancelFee","default":{"kind":"nothing"},"name":"netCancelFee","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"PriceValue"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Fixed gross cancellation fee for cancellation after or on the cancelFromDate.\n"}],"serializedName":"grossCancelFee","default":{"kind":"nothing"},"name":"grossCancelFee","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"PriceValue"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An additional description for this particular period of cancellation, e.g. \"No show\"\n"}],"serializedName":"description","default":{"kind":"just","value":null},"name":"description","typeExpr":{"typeRef":{"kind":"primitive","value":"Nullable"},"parameters":[{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}]}}]}},"name":"CancellationPolicy","version":{"kind":"nothing"}}};

export const snCancellationPolicy: ADL.ScopedName = {moduleName:"hotel.common", name:"CancellationPolicy"};

export function texprCancellationPolicy(): ADL.ATypeExpr<CancellationPolicy> {
  return {value : {typeRef : {kind: "reference", value : snCancellationPolicy}, parameters : []}};
}

export type AusAccountNumber = string;

const AusAccountNumber_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"common.ui","name":"ValidRegex"},"v2":{"regex":"^[0-9]{8,9}$","description":"An 8-9 digit Australian bank account number."}}],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"AusAccountNumber","version":{"kind":"nothing"}}};

export const snAusAccountNumber: ADL.ScopedName = {moduleName:"hotel.common", name:"AusAccountNumber"};

export function texprAusAccountNumber(): ADL.ATypeExpr<AusAccountNumber> {
  return {value : {typeRef : {kind: "reference", value : snAusAccountNumber}, parameters : []}};
}

/**
 * Represents an Australian bank account.
 */
export interface AusBankAccountDetails {
  /**
   * Name of account holder.
   */
  accountName: common_strings.StringNE;
  /**
   * BSB number.
   */
  bsb: Bsb;
  /**
   * Account number.
   */
  accountNumber: AusAccountNumber;
}

export function makeAusBankAccountDetails(
  input: {
    accountName: common_strings.StringNE,
    bsb: Bsb,
    accountNumber: AusAccountNumber,
  }
): AusBankAccountDetails {
  return {
    accountName: input.accountName,
    bsb: input.bsb,
    accountNumber: input.accountNumber,
  };
}

const AusBankAccountDetails_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Represents an Australian bank account.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Name of account holder.\n"}],"serializedName":"accountName","default":{"kind":"nothing"},"name":"accountName","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"BSB number.\n"}],"serializedName":"bsb","default":{"kind":"nothing"},"name":"bsb","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"Bsb"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Account number.\n"}],"serializedName":"accountNumber","default":{"kind":"nothing"},"name":"accountNumber","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"AusAccountNumber"}},"parameters":[]}}]}},"name":"AusBankAccountDetails","version":{"kind":"nothing"}}};

export const snAusBankAccountDetails: ADL.ScopedName = {moduleName:"hotel.common", name:"AusBankAccountDetails"};

export function texprAusBankAccountDetails(): ADL.ATypeExpr<AusBankAccountDetails> {
  return {value : {typeRef : {kind: "reference", value : snAusBankAccountDetails}, parameters : []}};
}

export interface BankAccountDetails_Aus {
  kind: 'aus';
  value: AusBankAccountDetails;
}

/**
 * Types of bank account details accepted for refunds.
 * (Only Australian bank accounts supported for now)
 */
export type BankAccountDetails = BankAccountDetails_Aus;

export interface BankAccountDetailsOpts {
  /**
   * Australian bank account.
   */
  aus: AusBankAccountDetails;
}

export function makeBankAccountDetails<K extends keyof BankAccountDetailsOpts>(kind: K, value: BankAccountDetailsOpts[K]) { return {kind, value}; }

const BankAccountDetails_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Types of bank account details accepted for refunds.\n(Only Australian bank accounts supported for now)\n"}],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Australian bank account.\n"}],"serializedName":"aus","default":{"kind":"nothing"},"name":"aus","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"AusBankAccountDetails"}},"parameters":[]}}]}},"name":"BankAccountDetails","version":{"kind":"nothing"}}};

export const snBankAccountDetails: ADL.ScopedName = {moduleName:"hotel.common", name:"BankAccountDetails"};

export function texprBankAccountDetails(): ADL.ATypeExpr<BankAccountDetails> {
  return {value : {typeRef : {kind: "reference", value : snBankAccountDetails}, parameters : []}};
}

export type Currency = string;

const Currency_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"common.ui","name":"ValidRegex"},"v2":{"regex":"^[A-Z]{3}$","description":"A three letter code for a currency (ISO 4217 standard)"}}],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Currency","version":{"kind":"nothing"}}};

export const snCurrency: ADL.ScopedName = {moduleName:"hotel.common", name:"Currency"};

export function texprCurrency(): ADL.ATypeExpr<Currency> {
  return {value : {typeRef : {kind: "reference", value : snCurrency}, parameters : []}};
}

/**
 * Represents a price value.
 */
export interface PriceValue {
  /**
   * Value amount.
   */
  value: common.BigDecimal;
  /**
   * Currency value amount is in.
   */
  currency: Currency;
}

export function makePriceValue(
  input: {
    value: common.BigDecimal,
    currency: Currency,
  }
): PriceValue {
  return {
    value: input.value,
    currency: input.currency,
  };
}

const PriceValue_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Represents a price value.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Value amount.\n"}],"serializedName":"value","default":{"kind":"nothing"},"name":"value","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Currency value amount is in.\n"}],"serializedName":"currency","default":{"kind":"nothing"},"name":"currency","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"Currency"}},"parameters":[]}}]}},"name":"PriceValue","version":{"kind":"nothing"}}};

export const snPriceValue: ADL.ScopedName = {moduleName:"hotel.common", name:"PriceValue"};

export function texprPriceValue(): ADL.ATypeExpr<PriceValue> {
  return {value : {typeRef : {kind: "reference", value : snPriceValue}, parameters : []}};
}

/**
 * Represents the percentage with the corresponding absolute price.
 * For instance, an object { percentage: "10", amount: { value: "15", currency: "AUD" } }
 * means 15 AUD is 10 percent value for some other price equal to { value: "150", currency: "AUD" }
 */
export interface PercentageAmountValue {
  /**
   * Percentage number that represents human-readable percent value (e.g. 10 for 10%).
   */
  percentage: common.BigDecimal;
  /**
   * Absolute amount value that represents this corresponding percent value.
   */
  amount: PriceValue;
}

export function makePercentageAmountValue(
  input: {
    percentage: common.BigDecimal,
    amount: PriceValue,
  }
): PercentageAmountValue {
  return {
    percentage: input.percentage,
    amount: input.amount,
  };
}

const PercentageAmountValue_AST : ADL.ScopedDecl =
  {"moduleName":"hotel.common","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Represents the percentage with the corresponding absolute price.\nFor instance, an object { percentage: \"10\", amount: { value: \"15\", currency: \"AUD\" } }\nmeans 15 AUD is 10 percent value for some other price equal to { value: \"150\", currency: \"AUD\" }\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Percentage number that represents human-readable percent value (e.g. 10 for 10%).\n"}],"serializedName":"percentage","default":{"kind":"nothing"},"name":"percentage","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Absolute amount value that represents this corresponding percent value.\n"}],"serializedName":"amount","default":{"kind":"nothing"},"name":"amount","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"hotel.common","name":"PriceValue"}},"parameters":[]}}]}},"name":"PercentageAmountValue","version":{"kind":"nothing"}}};

export const snPercentageAmountValue: ADL.ScopedName = {moduleName:"hotel.common", name:"PercentageAmountValue"};

export function texprPercentageAmountValue(): ADL.ATypeExpr<PercentageAmountValue> {
  return {value : {typeRef : {kind: "reference", value : snPercentageAmountValue}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "hotel.common.HotelId" : HotelId_AST,
  "hotel.common.SupplierHotelId" : SupplierHotelId_AST,
  "hotel.common.SupplierId" : SupplierId_AST,
  "hotel.common.HotelRoomId" : HotelRoomId_AST,
  "hotel.common.IpAddress" : IpAddress_AST,
  "hotel.common.Bsb" : Bsb_AST,
  "hotel.common.PackageCancellationPolicy" : PackageCancellationPolicy_AST,
  "hotel.common.CancellationPolicy" : CancellationPolicy_AST,
  "hotel.common.AusAccountNumber" : AusAccountNumber_AST,
  "hotel.common.AusBankAccountDetails" : AusBankAccountDetails_AST,
  "hotel.common.BankAccountDetails" : BankAccountDetails_AST,
  "hotel.common.Currency" : Currency_AST,
  "hotel.common.PriceValue" : PriceValue_AST,
  "hotel.common.PercentageAmountValue" : PercentageAmountValue_AST
};
