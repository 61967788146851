import type { DebounceCallback } from "@hx/util/timers";
import { debounce } from "@hx/util/timers";
import { assertNotUndefined } from "@hx/util/types";
import React, { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { HotelSearchContext } from "@app/app";
import { URLFilterParams } from "@common/url-filter-params";
import { HotelSearchTypeEnum } from "@controllers/hotel-search/hotel-search-controller";
import Clearable from "@src/ui/widgets/clearable";
import { IconSizeable } from "@widgets/icon/icon-sizeable";
import { Icons } from "@widgets/icon/icons";
import { InputText } from "@widgets/input-text/input-text";

import styles from "./filter-hotel-name.css";

const ClearableInputText = Clearable(InputText);

export const FilterHotelName = (): React.JSX.Element => {
  const location = useLocation();
  const history = useHistory();

  const [inputValue, setInputValue] = useState("");

  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const handleLocationChange = (value: string) => {
    const params = new URLSearchParams(location.search);
    const filterParams = new URLFilterParams(params);

    const previouslyFiltered = filterParams.get(HotelSearchTypeEnum.HotelName);
    if (!value && !previouslyFiltered) {
      return;
    }
    if (previouslyFiltered) {
      previouslyFiltered.forEach((hotelName) =>
        filterParams.remove(HotelSearchTypeEnum.HotelName, hotelName)
      );
    }
    if (value) {
      filterParams.append(HotelSearchTypeEnum.HotelName, value);
    }

    hotelSearchController.processHotelSearchFilter(
      {
        kind: HotelSearchTypeEnum.HotelName,
        value,
      },
      value ? "add" : "delete"
    );

    // Update the URL
    location.search = params.toString();
    history.push(location);
  };

  const debouncedHandleLocationChange = useRef(
    debounce<string>(500, (callbackData: DebounceCallback<string>) =>
      handleLocationChange(callbackData.value)
    )
  );

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value.toLowerCase().trim();
    setInputValue(searchText);
    debouncedHandleLocationChange.current(searchText);
  };

  return (
    <div className={styles.filterHotelName}>
      <ClearableInputText
        className={styles.filterHotelNameInput}
        placeholder="Filter by Hotel Name"
        value={inputValue}
        label
        iconBefore={<IconSizeable size={17} icon={Icons.search} />}
        onChange={onChangeHandler}
        onClear={
          inputValue
            ? () => {
                handleLocationChange("");
                setInputValue("");
              }
            : undefined
        }
      />
    </div>
  );
};
