import { StripeElementStyle } from "@stripe/stripe-js/types/stripe-js/elements/base";

export const STRIPE_ELEMENT_STYLE: StripeElementStyle = {
  base: {
    fontSize: "12px",
    "::placeholder": {
      color: "#C5D0D8",
    },
  },
};

export enum CardPaymentField {
  CardNumber = "CardNumber",
  Expiry = "Expiry",
  CVC = "CVC",
  Name = "Name",
  TermsAndConditions = "T&C",
}
