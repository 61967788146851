import React, { ChangeEvent, FC, FormEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { GeneralContext } from "@app/app";
import { LeftArrowSvg } from "@common/icon/icons-svg";
import { ROUTE_PATH } from "@constants/common";
import { UserController } from "@controllers/user/user-controller";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { ERROR_TEXT, ValidationRule, VALIDATION_REGEX } from "@util/validation";
import { Button } from "@widgets/button/button";
import Input from "@widgets/input";

import styles from "./styles.css";

interface Props {
  userController: UserController;
}

const PasswordRecovery: FC<Props> = ({ userController }) => {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { inviteUser } = userController;

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const valid = email.match(VALIDATION_REGEX[ValidationRule.Email]);

    if (valid) {
      setIsLoading(true);
      try {
        await inviteUser(email);

        setValidationError("");
        setEmail("");

        addNotification({
          text:
            "The recovery link has been successfully sent, please check your email.",
          type: NotificationTypeEnum.Success,
        });
      } catch (e) {
        addNotification({
          text: e.publicMessage,
          type: NotificationTypeEnum.Error,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setValidationError(ERROR_TEXT[ValidationRule.Email]);
    }
  }
  return (
    <div className={styles.passwordRecovery}>
      <form className={styles.content} onSubmit={handleSubmit}>
        <div className={styles.head}>
          <Link className={styles.backButton} to={ROUTE_PATH.Login}>
            {LeftArrowSvg}
          </Link>

          <span>Back to Log in</span>
        </div>

        <Input
          label="Email Address"
          placeholder="steve.jobs@apple.com"
          value={email}
          onChange={handleChange}
          error={validationError}
        />

        <Button
          className={styles.submitButton}
          isLoading={isLoading}
          type="submit"
        >
          Reset Password
        </Button>
      </form>
    </div>
  );
};

export default PasswordRecovery;
