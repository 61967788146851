import { assertNever, assertNotUndefined } from "@hx/util/types";
import clsx from "clsx";
import { LRUCache } from "lru-cache";
import type { FC, MouseEvent } from "react";
import React, { useContext, useRef, useState } from "react";

import { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import { AppUserType } from "@adl-gen/hotel/db";
import { GeneralContext, LoggedInContext } from "@app/app";
import layout from "@common/layout-lib.css";
import { PAYABLE_LIVE_STATUSES } from "@constants/booking";
import { NotificationTypeEnum } from "@src/models/common";
import type { PdfCacheType } from "@src/util/voucher";
import { DefaultCacheOptions, actOnVoucherPdf } from "@src/util/voucher";
import { checkUserAccessLevel, downloadFile } from "@util/agentus-utis";
import type { ButtonProps } from "@widgets/button/button";
import { Button } from "@widgets/button/button";
import { ToggleButton } from "@widgets/button/toggle-button";
import DialogWindow from "@widgets/dialog-window";
import { isBookingCancellable } from "@widgets/itinerary/utils";

import styles from "./styles.css";
import type { BookingControl } from "../booking";
import { PdfCacheContext } from "../itinerary";

type ButtonNames = "cancel" | "amend" | "edit" | "pay" | "download";

interface ActionButtonProps extends ButtonProps {
  name: ButtonNames;
}

const REFUNDABLE_CANCELLATION_TEXT =
  "You are about to cancel a refundable booking. Would you like to proceed?";
const NONREFUNDABLE_CANCELLATION_TEXT =
  "You are about to cancel a non-refundable booking. When you proceed, no refund is available. Would you like to proceed?";
const QUOTE_CANCELLATION_TEXT =
  "You are about to cancel a quote. Would you like to proceed?";

export const ItineraryAction: FC<BookingControl> = ({
  bookingDetailsShown,
  financialDetailsShown,
  toggleFinancialDetailsVisible,
  toggleBookingDetailsVisible,
  booking,
}) => {
  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const pdfCache =
    useContext(PdfCacheContext) ||
    useRef<PdfCacheType>(new LRUCache(DefaultCacheOptions));
  const { identityController, itineraryController } = useContext(
    LoggedInContext
  );
  const {
    cancelBooking,
    searchResult,
    checkIfBookingsRefundable,
    setQuoteToPay,
  } = assertNotUndefined(itineraryController);
  const { userProfile } = assertNotUndefined(identityController);

  const [cancellationModalText, setCancellationModalText] = useState("");

  const { bookingId, liveStatus, clientPaymentStatus } = booking;

  const handleButtonClick = async (ev: MouseEvent<HTMLButtonElement>) => {
    const buttonName = ev.currentTarget.name as ButtonNames;
    if (buttonName === "cancel") {
      if (searchResult.kind !== "value") {
        return;
      }

      if (liveStatus === LiveStatus.cancelled) {
        await cancelBooking(bookingId);
        return;
      }

      if (liveStatus === LiveStatus.quote) {
        setCancellationModalText(QUOTE_CANCELLATION_TEXT);
        return;
      }

      const refundabilityResponse = await checkIfBookingsRefundable([
        bookingId,
      ]);

      if (!refundabilityResponse) {
        return;
      }

      const refundable = refundabilityResponse.statuses[0].isRefundable;

      setCancellationModalText(
        refundable
          ? REFUNDABLE_CANCELLATION_TEXT
          : NONREFUNDABLE_CANCELLATION_TEXT
      );
    } else if (buttonName === "amend") {
      return;
    } else if (buttonName === "pay") {
      setQuoteToPay(booking);
    } else if (buttonName === "edit") {
      return;
    } else if (buttonName === "download") {
      await actOnVoucherPdf([booking], downloadFile, pdfCache.current, () => {
        addNotification({
          text: "Failed to download voucher",
          type: NotificationTypeEnum.Error,
        });
      });
    } else {
      assertNever(buttonName);
    }
  };

  function onSubmitCancelBooking() {
    setCancellationModalText("");
    cancelBooking(bookingId);
  }

  // TODO post MVP
  // const [isAmendModalOpen, setIsAmendModalOpen] = useState(false);
  // function toggleAmendModal() {
  //   setIsAmendModalOpen((open) => !open);
  // }

  const ActionButton = (p: ActionButtonProps) => {
    const [isLoading, setIsLoading] = useState(false);
    return (
      <Button
        isLoading={isLoading}
        onClick={(ev) => {
          setIsLoading(true);
          return handleButtonClick(ev).finally(() => setIsLoading(false));
        }}
        {...p}
      />
    );
  };

  return (
    <div className={styles.itineraryAction}>
      <div className={clsx(layout.horizontal, layout.spaced)}>
        <ToggleButton
          className={clsx(
            styles.expandButton,
            bookingDetailsShown && styles.toggled
          )}
          emphasis={bookingDetailsShown ? "medium" : "low"}
          onClick={toggleBookingDetailsVisible}
          toggle={bookingDetailsShown}
        >
          Booking Details
        </ToggleButton>
        <ToggleButton
          className={clsx(
            styles.expandButton,
            financialDetailsShown && styles.toggled
          )}
          emphasis={financialDetailsShown ? "medium" : "low"}
          toggle={financialDetailsShown}
          onClick={toggleFinancialDetailsVisible}
        >
          Financial Details
        </ToggleButton>
      </div>
      <div className={clsx(layout.horizontal, layout.spaced)}>
        {isBookingCancellable(booking) && (
          <ActionButton
            classes={{ container: styles.expandButtonInner }}
            name="cancel"
            emphasis="medium"
          >
            Cancel
          </ActionButton>
        )}
        {liveStatus === LiveStatus.confirmed &&
          clientPaymentStatus === ClientPaymentStatus.paid && (
            <ActionButton
              classes={{
                container: styles.expandButtonInner,
                button: styles.downloadButton,
              }}
              name="download"
              emphasis="medium"
            >
              Download
            </ActionButton>
          )}
        {checkUserAccessLevel(userProfile, AppUserType.backOffice) && (
          <ActionButton
            classes={{ container: styles.expandButtonInner }}
            name="edit"
            emphasis="medium"
            hidden={true}
          >
            Edit
          </ActionButton>
        )}
        {(PAYABLE_LIVE_STATUSES.includes(liveStatus) ||
          (liveStatus === LiveStatus.confirmed &&
            clientPaymentStatus === ClientPaymentStatus.unpaid)) && (
          <ActionButton
            classes={{ container: styles.expandButtonInner }}
            name="pay"
          >
            Pay Now
          </ActionButton>
        )}
      </div>

      <DialogWindow
        open={!!cancellationModalText}
        onCancel={() => setCancellationModalText("")}
        onOk={onSubmitCancelBooking}
        text={cancellationModalText}
      />

      {/* TODO post MVP */}
      {/* The "isAmendModalOpen" parameter is not used in the "open"
       in order not to create a Modal node inside each booking card */}
      {/*{isAmendModalOpen && <AmendmentModal open onClose={toggleAmendModal} />}*/}
    </div>
  );
};
