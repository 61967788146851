/** A typesafe route */
import {
  AdminHrefFactory,
  AdminRoute,
  adminRouteFromParts,
  pathFromAdminRoute,
} from "@adltools/adminui";
import { ROUTE_PATH } from "@constants/common";

export type Route =
  | { route: "login" }
  | { route: "logout" }
  | { route: "admin"; admin: AdminRoute };

export function routeFromPath(path: string): Route | undefined {
  if (path.startsWith("/")) {
    const parts = path.substr(1).split("/").map(decodeURIComponent);
    if (parts.length === 1 && parts[0] === "login") {
      return { route: "login" };
    } else if (parts.length === 1 && parts[0] === "logout") {
      return { route: "logout" };
    } else if (parts.length >= 1 && parts[0] === "admin") {
      const adminRoute = adminRouteFromParts(parts.slice(1));
      return adminRoute === undefined
        ? undefined
        : { route: "admin", admin: adminRoute };
    }
  }
  return undefined;
}

export function pathFromRoute(route: Route): string {
  switch (route.route) {
    case "login":
      return ROUTE_PATH.Login;
    case "logout":
      return ROUTE_PATH.Logout;
    case "admin":
      return ROUTE_PATH.Admin + pathFromAdminRoute(route.admin);
  }
}

function adminHrefFactory(): AdminHrefFactory {
  function fromAdminRoute(admin: AdminRoute): string {
    return pathFromRoute({ route: "admin", admin });
  }

  return { fromAdminRoute };
}

export const ADMIN_HREF_FACTORY = adminHrefFactory();
