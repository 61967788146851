import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { useLocation } from "react-router-dom";
import { compose, withProps } from "recompose";

import { GoogleMapKey } from "@constants/common";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import HotelMapMark from "@widgets/hotel-google-map/map-mark";

const DEFAULT_ZOOM = 16;

interface PropTypes {
  hotel: HotelListingResultWithId;
  nearbyHotels: HotelListingResultWithId[];
  onSelectHotel(id: string): void;
}

const HotelDetailsMap = compose<PropTypes, PropTypes>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%`, width: `100%` }} />,
    containerElement: <div style={{ height: `100%`, width: `100%` }} />,
    mapElement: <div style={{ height: `100%`, width: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ hotel, nearbyHotels, onSelectHotel }) => {
  const mapRef = useRef<GoogleMap | null>(null);

  const location = useLocation();

  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [center, setCenter] = useState(hotel.details.coordinates);

  useEffect(() => {
    if (mapRef.current) {
      fitHotelsToViewPort();
    }
  }, [nearbyHotels.length]);

  useEffect(() => {
    setZoom(DEFAULT_ZOOM);
    setCenter(hotel.details.coordinates);
  }, [location, hotel]);

  function onChangeZoom() {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  }

  function onChangeCenter() {
    if (mapRef.current) {
      setCenter(mapRef.current.getBounds().getCenter().toJSON());
    }
  }

  function fitHotelsToViewPort() {
    const mapBounds = mapRef.current!.getBounds();

    if (mapBounds) {
      const anyHotelOutOfView = nearbyHotels.some(
        ({ details }) => !mapBounds.contains(details.coordinates)
      );

      if (anyHotelOutOfView) {
        nearbyHotels.forEach((value) => {
          mapBounds.extend(value.details.coordinates);
        });

        mapRef.current!.fitBounds(mapBounds);
      }
    }
  }

  return (
    <GoogleMap
      defaultZoom={DEFAULT_ZOOM}
      defaultCenter={hotel.details.coordinates}
      ref={mapRef}
      onZoomChanged={onChangeZoom}
      onCenterChanged={onChangeCenter}
      zoom={zoom}
      center={center}
    >
      <HotelMapMark
        key={hotel.id}
        hotel={hotel}
        onMarkerClick={() => {
          return;
        }}
        mapRect={mapRef.current?.getDiv()?.getBoundingClientRect()}
        iconSize={40}
      />
      {nearbyHotels.map((value) => (
        <HotelMapMark
          key={value.id}
          hotel={value}
          onMarkerClick={onSelectHotel}
          mapRect={mapRef.current?.getDiv()?.getBoundingClientRect()}
        />
      ))}
    </GoogleMap>
  );
});

export default HotelDetailsMap;
