import React from "react";

import { Header } from "./header";
import { PageSection, PageSectionProps } from "./page-section";
import styles from "./page.css";

export interface PageProps {
  // Content to be inserted into the PageSection
  children?:
    | React.ReactElement<PageSectionProps>
    | React.ReactElement<PageSectionProps>[];
}

export const Page = (props: PageProps): JSX.Element => {
  return (
    <>
      <Header />
      {props.children}
      <PageSection className={styles.footer} tag={"footer"} />
    </>
  );
};
