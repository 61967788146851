import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";

import { RoomOccupancyDetails } from "@adl-gen/hotel/booking";
import { SpecialRequestsMap } from "@constants/booking";
import RoomDetails from "@pages/complete-booking-page/complete-booking/booking-form/room-details";
import { Checkbox } from "@widgets/checkbox/checkbox";
import Input from "@widgets/input";
import Textarea from "@widgets/textarea";

import styles from "./booking-form.css";

interface CheckboxState {
  val: string;
  checked: boolean;
}

export interface BookingFormProps {
  bookingDetails: BookingDetails;
  onChange(details: BookingDetails): void;
}

export interface BookingDetails {
  agentRef: string;
  comments: string;
  specialRequests: string[];
  occupancy: RoomOccupancyDetails[];
}

const BookingForm: FC<BookingFormProps> = ({ onChange, bookingDetails }) => {
  const mkCheckbox = (val: string) => ({
    val,
    checked: bookingDetails.specialRequests.some((request) => request === val),
  });
  const [checkboxes, setCheckboxes] = useState<CheckboxState[]>(
    Object.values(SpecialRequestsMap).map(mkCheckbox)
  );

  const fieldState = useRef<
    Pick<BookingDetails, "agentRef" | "comments" | "specialRequests">
  >({
    ...bookingDetails,
  });

  // TODO Booking of multiple rooms at the same time is scheduled for post MVP (#BookingMultipleRooms)
  // const [currentRoom, setCurrentRoom] = useState<number>(0);
  const currentRoom = 0;

  useEffect(() => {
    fieldState.current.specialRequests = checkboxes
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.val);
    const updatedDetails = {
      ...bookingDetails,
      ...fieldState.current,
    };
    onChange(updatedDetails);
  }, [checkboxes]);

  const renderCheckbox = (state: CheckboxState) => {
    const onCheckboxChange = (checked: boolean) => {
      setCheckboxes((prevVal) => {
        return prevVal.map((checkbox) => {
          return checkbox.val === state.val
            ? { ...checkbox, checked }
            : checkbox;
        });
      });
    };
    return (
      <div className={styles.checkbox} key={state.val}>
        <Checkbox
          text={state.val}
          checked={state.checked}
          onChange={onCheckboxChange}
        />
      </div>
    );
  };

  const handleFieldChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    fieldState.current[ev.currentTarget.name] = ev.currentTarget.value;
    const updatedDetails = {
      ...bookingDetails,
      ...fieldState.current,
    };
    onChange(updatedDetails);
  };

  const updateRoom = (roomIdx: number, updated: RoomOccupancyDetails) => {
    const roomOccupancyDetails = [...bookingDetails.occupancy];
    roomOccupancyDetails[roomIdx] = updated;
    const updatedDetails = {
      ...bookingDetails,
      ...fieldState.current,
      occupancy: roomOccupancyDetails,
    };
    onChange(updatedDetails);
  };

  // TODO for post MVP (#BookingMultipleRooms)
  // const handleChangeRoom = (ev: React.MouseEvent<HTMLDivElement>) => {
  //   const roomIdx = ev.currentTarget.dataset.idx;
  //   if (roomIdx) {
  //     setCurrentRoom(parseInt(roomIdx, 10));
  //   }
  // };

  return (
    <div className={styles.bookingForm}>
      <div className={styles.bookingHeader}>
        <div className={styles.bookingPageTitle}>Your Booking Details</div>
        {/* TODO for post MVP (#BookingMultipleRooms) */}
        {/*{bookingDetails.occupancy.map((_, idx) => (*/}
        {/*  <div*/}
        {/*    key={`room-${idx}`}*/}
        {/*    className={clsx(*/}
        {/*      styles.roomButton,*/}
        {/*      idx === currentRoom && styles.selectedRoom*/}
        {/*    )}*/}
        {/*    data-idx={idx}*/}
        {/*    onClick={handleChangeRoom}*/}
        {/*  >*/}
        {/*    {`Room ${idx + 1}`}*/}
        {/*  </div>*/}
        {/*))}*/}
      </div>
      {bookingDetails.occupancy.map((room, idx) => (
        <RoomDetails
          key={idx}
          roomIdx={idx}
          onChange={updateRoom}
          occupants={room}
          visible={idx === currentRoom}
        />
      ))}
      <Input
        label="Agent Reference"
        name="agentRef"
        placeholder="Optional"
        value={fieldState.current.agentRef}
        onChange={handleFieldChange}
        className={styles.fieldInput}
        labelClassname={styles.inputTitle}
      />
      <Textarea
        label="Comments"
        name="comments"
        placeholder="Enter any special information here"
        value={fieldState.current.comments}
        onChange={handleFieldChange}
        className={clsx(styles.commentInput, styles.fieldInput)}
        labelClassname={styles.inputTitle}
      />

      <div className={styles.bookingPageTitle}>Standard Special Requests*</div>
      <div className={styles.specialRequests}>
        {[0, 4, 8].map((start) => (
          <div key={start} className={styles.specialRequestColumn}>
            {checkboxes.slice(start, start + 4).map(renderCheckbox)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingForm;
