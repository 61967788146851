import clsx from "clsx";
import React, { FC } from "react";

import styles from "./radio.css";

/**
 * Radio Component
 */

/** Props to create a Radio */
export interface RadioProps {
  /** Optional additional className to apply */
  className?: string;
  /** Optional additional classes to apply */
  classes?: { root?: string; text?: string };
  /** Optional group name of the radio */
  name?: string;
  /** Optional initial check state of the radio */
  checked?: boolean;
  /** Optional text label displayed next to the radio  */
  text?: string | React.ReactElement;
  /** Determines if the label will be displayed as a clamped single line, or as multiline text */
  multiline?: boolean;
  /** Determines of radio is enabled, if disable tthe button will not handle events */
  disabled?: boolean;
  /** Radio size */
  size?: "small" | "medium" | "large";
  /** Callback on change event */
  onChange?(checked: boolean): void;
}

export const Radio: FC<RadioProps> = ({
  onChange,
  checked,
  size,
  name,
  className,
  disabled,
  text,
  multiline,
  classes,
}) => {
  const changeHandler = (event) => {
    if (onChange !== undefined) {
      onChange(event.target.checked);
    }
  };

  const rootClasses = clsx(
    className,
    styles.radio,
    classes?.root,
    size && styles[size],
    text !== undefined && styles.shim,
    multiline === true && styles.multiline
  );

  const textClasses = clsx(styles.text, classes?.text);

  return (
    <label className={rootClasses} tabIndex={0}>
      <input
        type="radio"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={changeHandler}
      />
      <div className={styles.radioProxy} />
      <span className={textClasses}>{text}</span>
    </label>
  );
};
