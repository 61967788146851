import moment from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";

import { BookingSearchQueryResp } from "@adl-gen/hotel/api";
import { ROUTE_PATH } from "@constants/common";
import currencies from "@constants/currencies.json";
import { LATEST_BOOKING_DATE_FORMAT } from "@pages/dashboard/latest-bookings/constant";
import {
  renderLatestBookingDuration,
  renderLatestBookingLeadGuest,
} from "@pages/dashboard/latest-bookings/utils";
import { Price } from "@widgets/price/price";

import styles from "./styles.css";

interface LatestBookingsCardsProps {
  latestBookings: BookingSearchQueryResp;
}

const LatestBookingsCards: FC<LatestBookingsCardsProps> = ({
  latestBookings,
}) => {
  return (
    <div className={styles.cardsContainer}>
      {latestBookings.value.items.map(({ bookingData, bookingPrice }) => {
        const {
          rooms,
          bookingNumber,
          created,
          checkInDate,
          checkOutDate,
          itineraryNumber,
        } = bookingData;

        const { grossPriceWithTax } = bookingPrice;

        return (
          <Link
            key={bookingNumber}
            className={styles.latestBookingCard}
            to={`${ROUTE_PATH.Itinerary}/${itineraryNumber}`}
          >
            <div>
              <p>
                <strong>{bookingNumber}</strong>
              </p>
              <p>{renderLatestBookingLeadGuest(rooms)}</p>
              <p>
                {moment(created).format(LATEST_BOOKING_DATE_FORMAT)},{" "}
                {renderLatestBookingDuration(checkInDate, checkOutDate)}
              </p>
            </div>

            <div>
              <Price
                value={grossPriceWithTax.value}
                currency={grossPriceWithTax.currency}
                size="medium"
                sign={currencies[grossPriceWithTax.currency].symbol_native}
                fractionDigits={0}
              />
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default LatestBookingsCards;
