import { assertNotUndefined } from "@hx/util/types";
import clsx from "clsx";
import type { FC } from "react";
import React, { useContext, useMemo, useRef } from "react";

import type { HotelRoom } from "@adl-gen/hotel/api";
import type { PriceValue } from "@adl-gen/hotel/common";
import { HotelSearchContext } from "@app/app";
import { MarkerIcon, TripAdvisorIcon } from "@common/icon/icons";
import type { ViewHotelProps } from "@controllers/hotel-search/types";
import type { HotelListingResultDetails } from "@models/hotel";
import { isElementInViewport } from "@util/native-dom";
import { Badge, BadgeColor } from "@widgets/badge/badge";
import { Button } from "@widgets/button/button";
import { ToggleButton } from "@widgets/button/toggle-button";
import type { PriceProps } from "@widgets/price/price";
import StarRating from "@widgets/star-rating";

import AverageHotelPrice from "./average-hotel-price";
import { VIEW_RATES_HOTEL_CARD_POSITIONING_DELAY } from "./constants";
import HotelCardImage from "./hotel-card-image";
import styles from "./styles.css";
import TotalHotelPrice from "./total-hotel-price";

/** Props to display a hotel listing card */
export interface PropTypes {
  /** Additional classes to apply */
  className?: string;
  /** Hotel listing result details */
  details: HotelListingResultDetails;
  /** Number of nights */
  nights: number;
  /** Room details (adults and children number) */
  pax: HotelRoom;
  /** Hotel id */
  id?: string;
  /** Click handler for view details button */
  // TODO(jess): Remove this, maybe we don't need this to be passed down through the parent since
  // it just changes the route. We need to pass the id down if we go down this route.
  onClickViewDetails?(options?: ViewHotelProps): void;
  /** Click handler for the view rates button */
  onClickViewRates?(): void;
  /**  If true, the total price will not be displayed */
  hideTotalPrice?: boolean;
  /**  Optional property to pass average price by selected package */
  averageRate?: PriceValue;
}

/** A hotel listing result card represents and displays a single hotel search result on the hotel search results screen */
const HotelListCard: FC<PropTypes> = ({
  details,
  hideTotalPrice,
  onClickViewRates,
  onClickViewDetails,
  className,
  id,
  averageRate,
  nights,
  pax,
}) => {
  const {
    hotelName,
    hotelCategory,
    isPromotion,
    hotelAmenities,
    nearestAirports,
    hotelAddress,
    hotelStarRating,
    priceDetails,
    tripAdvisorRating,
  } = details;

  const cardRef = useRef<HTMLDivElement>(null);

  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  // Determine if the price block should be shown, if price info is loading,
  // or has loaded and is available.
  const showPrice =
    priceDetails.kind === "loading" ||
    (priceDetails.kind === "value" && priceDetails.value.kind === "available");

  // Determine if the total should be shown, if not hidden
  const showTotal = showPrice && !hideTotalPrice;

  // Determine if Promotion Badge to be shown
  const showPromotion = isPromotion;

  // Determine if Sold Out Badge to be shown, if an error, or the pricing is unavaliable
  const showSoldOut =
    priceDetails.kind === "error" ||
    (priceDetails.kind === "value" &&
      priceDetails.value.kind === "unavailable");

  // Determine if buttons shouuld be disabled, if loading or is sold out
  // or missing handlers
  const hideButtons =
    priceDetails.kind === "loading" ||
    showSoldOut ||
    !onClickViewRates ||
    !onClickViewDetails;

  // Determine if Trip Advisor block shouuld be show, if trip advisor info exists
  const showTripAdvisor = tripAdvisorRating !== undefined;

  // Extract the pricing info

  const ratePricing: PriceProps =
    averageRate ||
    (priceDetails.kind === "value" && priceDetails.value.kind === "available"
      ? {
          // @todo (james): Remove toString when types from API updated
          // Currently the props passed in have a 'number' as the value however this should be a string
          value: priceDetails.value.lowestAverageNightlyRate.toString(),
          currency: priceDetails.value.currency,
        }
      : {});

  const totalPricing: PriceProps =
    priceDetails.kind === "value" && priceDetails.value.kind === "available"
      ? {
          // @todo (james): Remove toString when types from API updated
          // Currently the props passed in have a 'number' as the value however this should be a string
          value: priceDetails.value.totalPrice.toString(),
          currency: priceDetails.value.currency,
        }
      : {};

  function metersToKm(meters: number) {
    return Math.ceil(meters / 1000);
  }

  function handleClickViewRates() {
    if (onClickViewRates) {
      onClickViewRates();
    }

    const ref = cardRef.current;

    if (!ref) {
      return;
    }

    setTimeout(() => {
      if (isElementInViewport(ref)) {
        return;
      }

      ref.scrollIntoView({
        block: "start",
      });
    }, VIEW_RATES_HOTEL_CARD_POSITIONING_DELAY);
  }

  const hotelAmenitiesStr = useMemo(() => {
    const maxCount = hotelAmenities.length > 3 ? 3 : hotelAmenities.length;
    let amenities = "";
    for (let i = 0; i < maxCount; i++) {
      amenities += hotelAmenities[i];
      if (i < maxCount - 1) {
        amenities += ", ";
      }
    }
    return amenities;
  }, [hotelAmenities]);

  const airportData = nearestAirports.length ? nearestAirports[0] : null;
  const coverImages = details.images.map(({ url }) => url);

  function showOnMap() {
    if (onClickViewDetails) {
      onClickViewDetails({ showOnMap: true, inNewTab: true });
    }
  }

  function viewDetails() {
    if (onClickViewDetails) {
      onClickViewDetails({ inNewTab: true });
    }
  }

  return (
    <div
      className={clsx(styles.hotelListingCard, className)}
      key={id}
      ref={cardRef}
    >
      <div className={styles.hotelPropertyTypeLabel}>{hotelCategory}</div>
      <div className={styles.cardBody}>
        <HotelCardImage images={coverImages} onClick={viewDetails} />
        <div className={styles.title}>
          <span className={styles.hotelName} onClick={viewDetails}>
            {hotelName}
          </span>

          <div className={styles.addressSection}>
            <MarkerIcon className={styles.marker} />
            <div>
              <span
                className={clsx(
                  !!onClickViewDetails && styles.clickable,
                  styles.hotelAddress
                )}
                onClick={showOnMap}
              >
                {hotelAddress}
              </span>{" "}
              {!!onClickViewDetails && (
                <>
                  <span> ● </span>
                  <span
                    className={clsx(!!onClickViewDetails && styles.clickable)}
                    onClick={showOnMap}
                  >
                    See this property on the map
                  </span>
                </>
              )}
            </div>
          </div>

          <StarRating starRating={hotelStarRating} />
          <div className={styles.addInfo}>
            {airportData
              ? `${metersToKm(airportData.distanceFromHotel)} km from ${
                  airportData.poi.value.name
                }`
              : null}
            <br />
            {hotelAmenitiesStr}
            {showTripAdvisor && (
              <div className={styles.tripAdvisor}>
                <TripAdvisorIcon className={styles.tripAdvisorIcon} size="2x" />
                <span className={styles.tripAdvisorRating}>
                  {tripAdvisorRating}
                </span>
                <span>Trip Advisor Rating</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.description}>
          {showPromotion || showSoldOut ? (
            <div className={styles.promotionSection}>
              {showPromotion && (
                <Badge text="Promotion" variant={BadgeColor.Red} />
              )}
              {showSoldOut && (
                <Badge text="Sold Out" variant={BadgeColor.Red} />
              )}
            </div>
          ) : null}
          {showPrice && <AverageHotelPrice ratePricing={ratePricing} />}
          {showTotal && (
            <TotalHotelPrice
              totalPricing={totalPricing}
              nights={nights}
              pax={pax}
            />
          )}

          {hideButtons ? null : (
            <>
              <div className={styles.buttons}>
                <ToggleButton
                  emphasis="medium"
                  onClick={handleClickViewRates}
                  toggle={hotelSearchController.selectedHotel === id}
                >
                  View Rates
                </ToggleButton>

                <Button onClick={viewDetails}>View Details</Button>
              </div>
              <div className={styles.buttonsMobile}>
                <Button onClick={viewDetails}>View Details</Button>

                <ToggleButton
                  emphasis="medium"
                  onClick={handleClickViewRates}
                  toggle={hotelSearchController.selectedHotel === id}
                >
                  View Rates
                </ToggleButton>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelListCard;
