import classnames from "classnames";
import React, { ReactNode } from "react";

import styles from "./skeleton.css";

/**
 * Cmoponent to show a skeleton loader when either its children are
 * undefined/falsy or when the trigger property is false.
 *
 * The component accepts a className which is useful for passing a class to set
 * the width to an appropriate placeholder size for the intended content.
 *
 * @author James Millar
 */

export const Skeleton = ({
  className,
  children,
  trigger = true,
}: {
  className?: string;
  children?: ReactNode;
  trigger?: boolean;
}) => {
  return (
    <>
      {children === undefined || !trigger ? (
        <div className={classnames(styles.skeleton, styles.pulse, className)}>
          &nbsp;
        </div>
      ) : (
        children
      )}
    </>
  );
};
