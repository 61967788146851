import moment from "moment";

import { BookingSearchQuery, BookingSearchShortcut } from "@adl-gen/hotel/api";
import { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";

export const EMPTY_BOOKING_SEARCH_FILTERS: BookingSearchQuery = {
  countryCode: null,
  destinationName: null,
  bookingNumber: null,
  itineraryNumber: null,
  firstName: null,
  lastName: null,
  offset: 0,
  count: ITEMS_PER_PAGE,
  isPaymentPending: null,
  createdFrom: null,
  createdTo: null,
  serviceFrom: null,
  serviceTo: null,
  isCancellationFailed: null,
  agentRef: null,
  liveStatuses: [],
  isInstantPurchase: null,
  isCancellationPolicyActive: null,
  supplierPaymentStatuses: [],
  clientPaymentStatuses: [],
  paymentDueDate: null,
  userName: null,
};

export const SHORTCUT_FILTERS: Record<
  BookingSearchShortcut,
  BookingSearchQuery
> = {
  [BookingSearchShortcut.quoteBookAndHold]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.quote],
    isInstantPurchase: false,
    isCancellationPolicyActive: false,
  },
  [BookingSearchShortcut.quoteInstantPurchase]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.quote],
    isInstantPurchase: true,
  },
  [BookingSearchShortcut.confirmedUnpaid]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.confirmed],
    isPaymentPending: true,
    isInstantPurchase: false,
  },
  [BookingSearchShortcut.paymentDueIn2Days]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.quote, LiveStatus.confirmed],
    isPaymentPending: true,
    isInstantPurchase: false,
    paymentDueDate: {
      from: moment(new Date()).format(LOCALDATE_FORMAT),
      to: moment(new Date()).add(2, "days").format(LOCALDATE_FORMAT),
    },
  },
  [BookingSearchShortcut.confirmedAndPaid]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.confirmed],
    clientPaymentStatuses: [ClientPaymentStatus.paid],
  },
  [BookingSearchShortcut.refundPending]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.confirmed, LiveStatus.cancelled],
    clientPaymentStatuses: [ClientPaymentStatus.refundDue],
  },
  [BookingSearchShortcut.paymentDueIn30Days]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.confirmed, LiveStatus.quote],
    isPaymentPending: true,
    paymentDueDate: {
      from: moment(new Date()).format(LOCALDATE_FORMAT),
      to: moment(new Date()).add(30, "days").format(LOCALDATE_FORMAT),
    },
  },
  [BookingSearchShortcut.departureWithin7Days]: {
    ...EMPTY_BOOKING_SEARCH_FILTERS,
    liveStatuses: [LiveStatus.confirmed],
    serviceFrom: {
      from: moment(new Date()).format(LOCALDATE_FORMAT),
      to: moment(new Date()).add(7, "days").format(LOCALDATE_FORMAT),
    },
  },
};

export const BOOKING_SEARCH_ARRAY_PARAMS: string[] = [
  "liveStatuses",
  "clientPaymentStatuses",
  "supplierPaymentStatuses",
];

export const BOOKING_SEARCH_NUMBER_PARAMS: string[] = [
  "createdFrom",
  "createdTo",
];

// The key in this object defines the name of a URL param, value defines the access property to get the value
export const BOOKING_SEARCH_OBJECT_PARAMS: string[] = [
  "serviceFrom",
  "serviceTo",
];
