import React, { FC, memo, useEffect, useRef } from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";

import { LocationInfoResp } from "@adl-gen/ids/externalapi";
import { GoogleMapKey } from "@constants/common";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import { MemoizedMapState } from "@controllers/hotel-search/types";

import HotelMapMark from "./map-mark";

const DEFAULT_MAP_ZOOM = 13;
// Delay after latest user interaction with the map after which hotels will be fetched
const HOTELS_REQUEST_TIMEOUT = 1500;

interface PropTypes {
  hotels: HotelListingResultWithId[];
  location: LocationInfoResp | null;
  defaultState?: MemoizedMapState;
  highlightedHotelId: string | null;
  onSaveMapState(args: MemoizedMapState): void;
  onInitBounds(bounds: google.maps.LatLngBounds): void;
  onMarkerClick(hotelId: string): void;
  fetchNewDataByMap(bounds: google.maps.LatLngBounds): void;
}

const googleMapEnvironment = compose<PropTypes, PropTypes>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ flex: `1 1 100%`, minHeight: "300px" }} />,
    containerElement: <div style={{ flex: `1 1 100%`, minHeight: "300px" }} />,
    mapElement: <div style={{ height: `100%`, borderRadius: "8px" }} />,
  }),
  withScriptjs,
  withGoogleMap
);

const HotelGoogleMap: FC<PropTypes> = ({
  onMarkerClick,
  location,
  hotels,
  fetchNewDataByMap,
  defaultState,
  onSaveMapState,
  onInitBounds,
  highlightedHotelId,
}) => {
  const mapRef = useRef<GoogleMap | null>(null);

  // Ref for hotel search request timeout
  const getHotelsLazyCallback = useRef<number>();

  const mapBoundsAdjusted = useRef(false);

  useEffect(() => {
    return () => {
      if (getHotelsLazyCallback.current) {
        clearTimeout(getHotelsLazyCallback.current);
      }
    };
  }, []);

  const handleMapBoundsChanged = () => {
    if (mapRef.current) {
      // Save the up-to-date map zoom and center
      updateMemoizedState();

      // Process the initial zoom adjustment
      if (!mapBoundsAdjusted.current) {
        mapBoundsAdjusted.current = fitHotelsToViewPort();
        onInitBounds(mapRef.current.getBounds());

        return;
      }

      const currentBounds = mapRef.current.getBounds();

      // Replace timeout callback with a new one
      if (getHotelsLazyCallback.current) {
        clearTimeout(getHotelsLazyCallback.current);
      }
      getHotelsLazyCallback.current = window.setTimeout(
        () => fetchNewDataByMap(currentBounds),
        HOTELS_REQUEST_TIMEOUT
      );
    }
  };

  function fitHotelsToViewPort() {
    const mapBounds = mapRef.current!.getBounds();

    // If saved map state provided, skip initial adjustment
    if (defaultState) {
      return true;
    }

    if (mapBounds) {
      const anyHotelOutOfView = hotels.some(
        ({ details }) => !mapBounds.contains(details.coordinates)
      );

      if (anyHotelOutOfView) {
        hotels.forEach((hotel) => {
          mapBounds.extend(hotel.details.coordinates);
        });

        mapRef.current!.fitBounds(mapBounds);

        // Return false because initial adjustment not completed,
        // After changing bounds, the onIdle event will file again
        return false;
      }
    }

    return true;
  }

  function updateMemoizedState() {
    if (mapRef.current) {
      onSaveMapState({
        center: mapRef.current?.getCenter().toJSON(),
        zoom: mapRef.current?.getZoom(),
      });
    }
  }

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={defaultState?.zoom || DEFAULT_MAP_ZOOM}
      defaultCenter={defaultState?.center || location?.marker}
      onIdle={handleMapBoundsChanged}
    >
      {hotels.map((hotel) => (
        <HotelMapMark
          key={hotel.id}
          hotel={hotel}
          onMarkerClick={onMarkerClick}
          mapRect={mapRef.current?.getDiv()?.getBoundingClientRect()}
          highlighted={highlightedHotelId === hotel.id}
        />
      ))}
    </GoogleMap>
  );
};

export default memo(googleMapEnvironment(HotelGoogleMap));
