import clsx from "clsx";
import React, { createContext, FC, useContext, useMemo, useState } from "react";

import { DbKey } from "@adl-gen/common/db";
import * as db from "@adl-gen/hotel/db";
import { MapMarker } from "@assets/svg-components";
import { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";
import { Badge } from "@widgets/badge/badge";
import { Checkbox } from "@widgets/checkbox/checkbox";
import { AgentSelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";
import AgentPaymentInfo from "@widgets/itinerary/booking/agent-payment-info";
import BackofficePaymentInfo from "@widgets/itinerary/booking/backoffice-payment-info";
import {
  extractAgentBookingInfo,
  extractBackofficeBookingInfo,
} from "@widgets/itinerary/booking/utils";
import { BookingSearchResultState } from "@widgets/itinerary/types";
import { getBookingFromStoreById } from "@widgets/itinerary/utils";

import {
  extractAgentSelectedBookingInfo,
  extractSelectedBackofficeBookingInfo,
} from "../booking-selected/utils";
import itineraryStyles from "../itinerary.css";
import { BookingDetails } from "./booking-details";
import { FinancialDetails } from "./financial-details";
import styles from "./styles.css";
import { AgentBookingInfo, BackofficeBookingInfo } from "./types";

export interface BookingControl {
  bookingDetailsShown: boolean;
  financialDetailsShown: boolean;
  toggleBookingDetailsVisible(): void;
  toggleFinancialDetailsVisible(): void;
  booking: AgentSelectedBookingInfo;
}

/** Component Props */
export interface BookingProps extends BaseProps {
  classes?: { itinerary?: string };
  bookingId: DbKey<db.Booking>;
  selected?: boolean;
  toggleSelected?(bookingId: DbKey<db.Booking>): void;
  actionComponent: React.FunctionComponent<BookingControl>;
  collapsed?: boolean;
}

export const BookingSearchResultContext =
  createContext<BookingSearchResultState>({
    searchResult: { kind: "error", error: "No results specified" },
  });

const Booking: FC<BookingProps> = ({
  actionComponent,
  bookingId,
  className,
  selected,
  toggleSelected,
  collapsed,
}) => {
  const [showBookingDetails, setShowBookingDetails] = useState(false);
  const [showFinancialDetails, setShowFinancialDetails] = useState(false);

  const searchResultState = useContext(BookingSearchResultContext);
  const ActionComponent = actionComponent;

  const [booking, mode] = useMemo(() => {
    return getBookingFromStoreById<AgentBookingInfo, BackofficeBookingInfo>(
      bookingId,
      searchResultState.searchResult,
      extractAgentBookingInfo,
      extractBackofficeBookingInfo
    );
  }, [searchResultState.searchResult]);

  function toggleBookingDetails() {
    setShowBookingDetails((prevValue) => !prevValue);
  }

  function toggleFinancialDetails() {
    setShowFinancialDetails((prevValue) => !prevValue);
  }

  function renderGuestsAndDates() {
    return (
      <>
        <div
          className={clsx(
            styles.guests,
            layout.vertical,
            collapsed && styles.collapsed
          )}
        >
          <div className={clsx(itineraryStyles.label)}>Guests</div>
          <div className={styles.text}>{booking.guests}</div>
        </div>
        <div className={layout.vertical}>
          <div className={itineraryStyles.label}>Check-In</div>
          <div className={styles.text}>{booking.checkIn}</div>
        </div>
        <div className={clsx(layout.vertical)}>
          <div className={itineraryStyles.label}>Check-Out</div>
          <div className={styles.text}>{booking.checkOut}</div>
        </div>
      </>
    );
  }

  return (
    <div
      aria-label={"booking-card"}
      className={clsx(styles.bookingContainer, layout.horizontal, className)}
    >
      <Badge
        className={styles.status}
        text={booking.liveStatusLabel.label}
        variant={booking.liveStatusLabel.color}
      />
      <div className={clsx(styles.booking, layout.vertical)}>
        <div className={clsx(styles.summary, collapsed && styles.collapsed)}>
          <div
            className={clsx(
              styles.summaryTopRow,
              collapsed ? layout.vertical : layout.horizontal,
              layout.justified
            )}
          >
            <div
              className={clsx(
                styles.shortInfo,
                layout.vertical,
                collapsed && styles.collapsed
              )}
            >
              <div
                className={clsx(
                  layout.horizontal,
                  layout.spaced,
                  layout.middle
                )}
              >
                {toggleSelected && (
                  <Checkbox
                    checked={selected}
                    classes={{ proxy: styles.selectionCheckboxProxy }}
                    onChange={() => toggleSelected(bookingId)}
                  />
                )}

                <Badge
                  className={styles.typeBadge}
                  text={booking.bookingLabel.label}
                  variant={booking.bookingLabel.color}
                  testId="booking-label"
                />
                <span className={styles.text} data-testid="booking-card-number">
                  #{booking.bookingNumber}
                </span>
              </div>
              <div className={layout.vertical}>
                <div className={clsx(styles.hotelName, styles.text)}>
                  {booking.hotelName}
                </div>
                <div
                  className={clsx(
                    styles.hotelLocation,
                    itineraryStyles.label,
                    layout.horizontal,
                    layout.middle
                  )}
                >
                  <MapMarker />

                  <span>{booking.hotelAddress}</span>
                </div>
              </div>
            </div>
            <div
              className={clsx(
                styles.guestsArea,
                layout.vertical,
                layout.justified,
                collapsed && styles.collapsed
              )}
            >
              <div className={clsx(styles.leadGuest, layout.horizontal)}>
                <div className={itineraryStyles.label}>Lead Guest</div>
                <span className={styles.text}>{booking.leadGuest}</span>
              </div>

              {renderGuestsAndDates()}
            </div>
          </div>

          {mode === "agent" ? (
            <AgentPaymentInfo booking={booking} collapsed={collapsed} />
          ) : (
            <BackofficePaymentInfo
              booking={booking as BackofficeBookingInfo}
              collapsed={collapsed}
            />
          )}
        </div>
        <ActionComponent
          toggleBookingDetailsVisible={toggleBookingDetails}
          toggleFinancialDetailsVisible={toggleFinancialDetails}
          bookingDetailsShown={showBookingDetails}
          financialDetailsShown={showFinancialDetails}
          booking={
            getBookingFromStoreById(
              bookingId,
              searchResultState.searchResult,
              extractAgentSelectedBookingInfo,
              extractSelectedBackofficeBookingInfo
            )[0]
          }
        />
        <div
          className={clsx(
            itineraryStyles.openableContainer,
            showBookingDetails && styles.detailsOpened
          )}
        >
          <BookingDetails bookingId={bookingId} />
        </div>
        <div
          className={clsx(
            itineraryStyles.openableContainer,
            showFinancialDetails && styles.detailsOpened
          )}
        >
          <FinancialDetails bookingId={bookingId} />
        </div>
      </div>
    </div>
  );
};

export default Booking;
