import {
  PayForBookingResp,
  RefreshBookingResp,
} from "@adl-gen/hotel/api";

export const QUOTE_REFRESH_RESPONSE_WARNING: Partial<Record<
  RefreshBookingResp["kind"],
  string
>> = {
  invalidBookingId: "Quote ID is no longer valid",
  packageNotAvailable: "The package is no longer available",
  priceChanged: "The price has changed",
  supplierError:
    "Unable to refresh the quote: an unexpected supplier error occurred",
};

export const QUOTE_REFRESH_ERROR_FALLBACK =
  "Unable to refresh the quote, please try again later";

export const PAYMENT_ERROR_TEXT: Partial<Record<
  PayForBookingResp["kind"],
  string
>> = {
  invalidBookingId: "Quote ID is no longer valid",
  packageNotAvailable: "The package is no longer available",
  supplierError:
    "Unable to refresh the quote: an unexpected supplier error occurred",
  cardError: "The provided card details are invalid",
  priceChanged: "The price has changed, please submit the payment again",
  cannotConfirm: "Unable to confirm the booking, please try again later",
};

export const PAYMENT_ERROR_FALLBACK =
  "Unable to submit the payment, please try again later";

/**
 * Query parameter for the itinerary number
 */
export const ITINERARY_NUMBER_PARAM = "itinerary";
