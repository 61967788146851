import clsx from "clsx";
import React, { FC, useState } from "react";

import { DropdownArrow } from "@assets/svg-components";
import { generateGuestNamesFromOccupancy } from "@util/agentus-utis";
import { Badge } from "@widgets/badge/badge";
import { Button } from "@widgets/button/button";
import { Price } from "@widgets/price/price";

import layout from "../../../common/layout-lib.css";
import itineraryStyles from "../itinerary.css";
import styles from "./styles.css";
import {
  AgentSelectedBookingInfo,
  BackofficeSelectedBookingInfo,
} from "./types";

interface Props {
  booking: AgentSelectedBookingInfo | BackofficeSelectedBookingInfo;
  mode: "agent" | "backoffice";
}

const BookingSelected: FC<Props> = ({ booking, mode }) => {
  const [detailsShown, setDetailsShown] = useState(false);

  const {
    bookingNumber,
    bookingType,
    hotelName,
    bookingPrice,
    rooms,
  } = booking;

  const guests = generateGuestNamesFromOccupancy(rooms);

  function toggleDetails() {
    setDetailsShown((prevValue) => !prevValue);
  }

  return (
    <div className={styles.bookingSelected}>
      <section className={styles.summary}>
        <span>{bookingNumber}</span>

        <Badge text={bookingType.label} variant={bookingType.color} />
      </section>

      <section className={styles.hotelData}>{hotelName}</section>

      <section
        className={clsx(
          itineraryStyles.openableContainer,
          styles.hiddenSection,
          detailsShown && styles.opened
        )}
      >
        <div className={styles.guestsAndDates}>
          <div>
            <label className={itineraryStyles.label}>GUESTS</label>
            {guests.map((guest) => (
              <span key={guest}>{guest}</span>
            ))}
          </div>

          <div className={styles.dates}>
            <label className={itineraryStyles.label}>CHECK-IN</label>
            <span>{booking.checkIn}</span>

            <label className={clsx(itineraryStyles.label, styles.checkOut)}>
              CHECK-OUT
            </label>
            <span>{booking.checkOut}</span>
          </div>
        </div>
      </section>

      <section className={styles.ctaAndPrice}>
        <Button
          className={clsx(styles.expandButton, detailsShown && styles.toggled)}
          emphasis="empty"
          onClick={toggleDetails}
          classes={{ container: styles.expandButtonInner }}
        >
          View Details
          <DropdownArrow />
        </Button>

        {mode === "agent" ? (
          <>
            <div className={layout.horizontal}>
              <span className={clsx(itineraryStyles.label, styles.priceLabel)}>
                NET
              </span>
              <Price
                value={bookingPrice.netPriceWithTax.value}
                currency={bookingPrice.netPriceWithTax.currency}
                size="small"
              />
            </div>

            <div className={layout.horizontal}>
              <span className={clsx(itineraryStyles.label, styles.priceLabel)}>
                GROSS
              </span>
              <Price
                value={bookingPrice.grossPriceWithTax.value}
                currency={bookingPrice.grossPriceWithTax.currency}
                size="small"
              />
            </div>
          </>
        ) : (
          <div>
            <Price
              value={bookingPrice.grossPriceWithTax.value}
              currency={bookingPrice.grossPriceWithTax.currency}
              size="small"
            />
          </div>
        )}
      </section>

      <section />
    </div>
  );
};

export default BookingSelected;
