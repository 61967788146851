import { isEqual } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { WithDbId } from "@adl-gen/common/db";
import {
  AgentBookingData,
  CancellationPoliciesResp,
  CreateBookingResp,
  CreateBookingResp_Error,
  ItineraryDetails,
  PackageInfo,
  PaymentDetailsReq,
  PaymentDetailsResp,
  StayDetails,
} from "@adl-gen/hotel/api";
import { LiveStatus, PaymentMode } from "@adl-gen/hotel/booking";
import { PackageCancellationPolicy } from "@adl-gen/hotel/common";
import { Itinerary, TermsAndConditions } from "@adl-gen/hotel/db";
import {
  CHECK_DRAFT_STATUS_INTERVAL,
  CHECK_DRAFT_STATUS_LIMIT,
} from "@constants/booking";
import { OUTDATED_HOTELS_DATA_MSG, ROUTE_PATH } from "@constants/common";
import {
  BED_PARAM,
  BOARD_PARAM,
  CANCELLATION_PARAM,
  HOTEL_PARAM,
  PACKAGE_ID_PARAM,
  PRICE_TOKEN_PARAM,
  ROOM_ID_PARAM,
} from "@controllers/booking/constant";
import { isCompleteBookingUrlParams } from "@controllers/booking/utils";
import { COMMISSION_PARAM } from "@controllers/hotel-search/constant";
import {
  HotelListingResultWithId,
  LOCALDATE_FORMAT,
} from "@controllers/hotel-search/hotel-search-controller";
import {
  PAYMENT_ERROR_FALLBACK,
  PAYMENT_ERROR_TEXT,
} from "@controllers/itinerary/constant";
import { NotificationController } from "@controllers/notification-controller";
import { assertNever, assertNotNull, assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { Loading, mapLoading } from "@models/loading";
import { fromPromise } from "@models/loading-state";
import { BookingDetails } from "@pages/complete-booking-page/complete-booking/booking-form/booking-form";
import { SpecialRequestKeyByLabel } from "@pages/complete-booking-page/complete-booking/booking-form/constants";
import { CompleteBookingProps } from "@pages/complete-booking-page/complete-booking/complete-booking";
import {
  assertValueLoaded,
  getSupplierPaymentDue,
  makeEmptyAdultFullName,
  makeEmptyChildFullName,
} from "@util/agentus-utis";
import { StripeCallback } from "@widgets/payment-form/payment-form";

import { Service } from "../../service/service";
import { HotelSearchController } from "../hotel-search/hotel-search-controller";
import { ItineraryController } from "../itinerary/itinerary-controller";

export interface BookingStateProps {
  searchController: HotelSearchController;
  service: BookingService;
  itineraryController: ItineraryController;
  notificationController: NotificationController;
}

export interface BookingController {
  completeBookingProps: Loading<CompleteBookingProps>;
  termsAndConditions: TermsAndConditions | null;
  loadPaymentDetails(): void;
  /**
   * Creates a booking quote using the provided details in the currently selected itinerary, or creates a new itinerary
   * if required. Returns an error message if the operation is not successful, otherwise, will redirect to the itinerary
   * page
   */
  createBookingQuote(): void;

  /**
   * Ref {@link BookingController#createBookingQuote}. Performs the same function, but also confirms the booking with
   * the supplier. Only to be used with bookings that can be held
   */
  confirmBooking(): Promise<void>;

  /**
   * Ref {@link BookingController#createBookingQuote}. Performs the same function, but also pays for the booking using a
   * credit card token that has been obtained from a payment gateway
   */
  payForBooking(stripeCallback: (clientSecret: string) => Promise<void>);

  isBookingDetailsFormValid(): boolean;

  priceType: PaymentMode;
  setPriceType(type: PaymentMode): void;
}

export type BookingService = Pick<
  Service,
  | "queryPaymentDetails"
  | "createBookingQuote"
  | "createDraftBooking"
  | "confirmBooking"
  | "payForBooking"
  | "queryTermsAndConditions"
  | "queryPaymentClientConfig"
  | "queryReports"
>;

export const useBookingController = ({
  searchController,
  service,
  itineraryController,
  notificationController,
}: BookingStateProps): BookingController | undefined => {
  const history = useHistory();
  const [paymentDetails, setPaymentDetails] = useState<
    Loading<PaymentDetailsResp>
  >({ kind: "loading" });
  const [isLoading, setIsLoading] = useState(false);
  const [showBookingFormErrors, setShowBookingFormErrors] = useState(false);
  const [
    termsAndConditions,
    setTermsAndConditions,
  ] = useState<TermsAndConditions | null>(null);

  const [transactionToken, setTransactionToken] = useState<string | null>(null);
  const [processingItinerary, setProcessingItinerary] = useState<WithDbId<
    Itinerary
  > | null>(null);
  const [processingBookingId, setProcessingBookingId] = useState<string | null>(
    null
  );
  const [priceType, setPriceType] = useState<PaymentMode>(PaymentMode.gross);

  const [selectedPackage, setSelectedPackage] = useState<PackageInfo>();
  const { addNotification } = notificationController;

  const [supplierSessionExpired, setSupplierSessionExpired] = useState(false);

  const [
    cancellationPolicyForPackage,
    setCancellationPolicyForPackage,
  ] = useState<Loading<PackageCancellationPolicy | undefined>>({
    kind: "loading",
  });

  const [formError, setFormError] = useState<
    Loading<string | undefined> | undefined
  >(undefined);

  const {
    packages: searchPackages,
    hotelListingResultDetails,
    cancellationPoliciesResp,
    commission,
    stayDetails,
    selectedHotelDetails,
    getCurrentPriceToken,
    setCurrentPriceToken,
    setStayDetails,
    setSelectedHotel,
    updateExistingSearch,
    getStayDetailsFromParams,
    setLocationFromParams,
    fetchLocationInfo,
    setCommission,
    priceTokenErrorWasCaught,
  } = searchController;

  const {
    checkIfItineraryCanBeLoaded,
    currentItinerary,
    createItinerary,
    loadItineraryNumber,
    fetchBookings,
  } = itineraryController;

  const urlParams = new URLSearchParams(location.search);
  const roomId = urlParams.get(ROOM_ID_PARAM);
  const bed = urlParams.get(BED_PARAM);
  const board = +(urlParams.get(BOARD_PARAM) as string);
  const packageId = urlParams.get(PACKAGE_ID_PARAM) as string;
  const cancellationPolicy = JSON.parse(
    urlParams.get(CANCELLATION_PARAM) as string
  );

  // if price token error ever occurred, it means the provided price token has expired,
  // We save it to the state to use different comparison mechanism for packages
  useEffect(() => {
    if (priceTokenErrorWasCaught) {
      setSupplierSessionExpired(true);
    }
  }, [priceTokenErrorWasCaught]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (!isCompleteBookingUrlParams(params)) {
      redirectToSearch();
    }

    initFromScratch(params);

    setIsLoading(true);
    getTermsAndConditions().finally(() => setIsLoading(false));

    return () => {
      logout();
    };
  }, []);

  useEffect(() => {
    const packages: PackageInfo[] =
      (searchPackages.kind === "value" && searchPackages.value) || [];

    // If we get results from a valid supplier session, it means package id is still relevant,
    // and we can identify target package by it.
    // Otherwise - additional properties are used (bed, room, cancellation policy, board)
    const targetPackageId = supplierSessionExpired
      ? matchPackageForExpiredSession(packages)
      : packages.find((p) => p.packageId === packageId);

    if (searchPackages.kind === "value" && !targetPackageId) {
      redirectToSearch();
    } else {
      setSelectedPackage(targetPackageId);
    }
  }, [searchPackages]);

  useEffect(() => {
    if (getCurrentPriceToken() && selectedPackage) {
      // Kick off the fetching of payment details when the store is created
      loadPaymentDetails();
    }
  }, [getCurrentPriceToken(), selectedPackage]);

  // Using an effect here to map the cancellation policies to a single cancellation policy to our selected package
  useEffect(() => {
    if (!selectedPackage) {
      return undefined;
    }

    setCancellationPolicyForPackage(
      mapLoading(cancellationPoliciesResp, (resp: CancellationPoliciesResp) => {
        if (resp.kind === "packageCancellationPolicies") {
          const matchingPolicies = resp.value
            .filter(
              (p) =>
                p.packageId === assertNotUndefined(selectedPackage).packageId
            )
            .map((p) => p.policy);

          if (matchingPolicies.length === 0) {
            return;
          } else if (matchingPolicies.length === 1) {
            return matchingPolicies[0];
          } else {
            throw new Error(
              `Illegal state: ${matchingPolicies.length} policies matched`
            );
          }
        } else if (resp.kind === "invalidPriceToken") {
          addNotification({
            text: OUTDATED_HOTELS_DATA_MSG,
            type: NotificationTypeEnum.Error,
          });
          return;
        } else if (resp.kind === "invalidHotelId") {
          return;
        } else {
          return assertNever(resp);
        }
      })
    );
  }, [cancellationPoliciesResp, selectedPackage]);

  const defaultBookingDetails: BookingDetails = {
    agentRef: "",
    comments: "",
    specialRequests: [],
    occupancy: [],
  };

  const bookingDetails = useRef<BookingDetails>(defaultBookingDetails);

  if (location.pathname !== ROUTE_PATH.CompleteBooking) {
    return undefined;
  }

  function matchPackageForExpiredSession(packages: PackageInfo[]) {
    return packages.find(
      (p) =>
        (roomId ? p.roomKeys.includes(roomId) : true) &&
        p.board === board &&
        (bed ? p.bedConfigurations.includes(bed) : true) &&
        isEqual(p.cancellationPolicy, cancellationPolicy)
    );
  }

  function getOccupancyFromStayDetails(value: StayDetails) {
    return value.rooms.map((room) => ({
      childPassengers: room.children.map((child) => ({
        ...child,
        name: makeEmptyChildFullName(),
      })),
      adults: Array.from({ length: room.numAdults }, makeEmptyAdultFullName),
    }));
  }

  async function initFromScratch(params: URLSearchParams) {
    await checkIfItineraryCanBeLoaded();
    const pt = params.get(PRICE_TOKEN_PARAM);
    setCurrentPriceToken(assertNotNull(pt));

    const sd = getStayDetailsFromParams(params);
    setLocationFromParams(params);

    const commissionFromParams = params.get(COMMISSION_PARAM);

    if (commissionFromParams) {
      setCommission(commissionFromParams);
    }

    const hotelId = urlParams.get(HOTEL_PARAM);

    const loc = searchController.setLocationFromParams(params);
    if (loc) {
      await fetchLocationInfo(loc.locationType);
    }

    setStayDetails(sd);

    await updateExistingSearch();

    setSelectedHotel(assertNotNull(hotelId));

    setBookingDetails({
      ...bookingDetails.current,
      occupancy: getOccupancyFromStayDetails(sd),
    });
  }

  function getPaymentDetailsReq(): PaymentDetailsReq {
    return {
      packageId: assertNotUndefined(selectedPackage).packageId,
      priceToken: assertNotNull(getCurrentPriceToken()),
      commissionPercentage: assertNotUndefined(commission),
      hotelId: assertNotUndefined(searchController.selectedHotel),
    };
  }

  const loadPaymentDetails = () => {
    fromPromise(
      service.queryPaymentDetails(getPaymentDetailsReq()),
      setPaymentDetails,
      addNotification
    );
  };

  function redirectToSearch() {
    if (location.pathname !== ROUTE_PATH.Search) {
      history.push(ROUTE_PATH.Search);
    }
  }

  const getCurrentItineraryOrFail = (): ItineraryDetails => {
    const loadedItinerary = assertValueLoaded(
      assertNotUndefined(currentItinerary)
    );
    if (loadedItinerary.kind === "itinerary") {
      return loadedItinerary.value;
    } else if (loadedItinerary.kind === "noSuchItinerary") {
      throw Error("Cannot add booking to non-existant itinerary");
    } else {
      assertNever(loadedItinerary);
    }
  };

  const createBooking = async ({
    kind,
  }: {
    kind: "quote" | "draft";
  }): Promise<[CreateBookingResp, WithDbId<Itinerary> | undefined]> => {
    let itineraryToUse: WithDbId<Itinerary>;
    if (!currentItinerary) {
      const createdItinerary = await createItinerary();

      if (!createdItinerary) {
        return [
          { kind: "error", value: "Could not create itinerary" },
          undefined,
        ];
      }
      itineraryToUse = createdItinerary;
    } else {
      itineraryToUse = getCurrentItineraryOrFail().itinerary;
    }

    const viewedCancellationPolicy = assertValueLoaded(
      cancellationPolicyForPackage
    ) || { policies: [], description: null };

    const paymentDue =
      getSupplierPaymentDue(viewedCancellationPolicy) || moment();

    try {
      const body = {
        board: assertNotUndefined(selectedPackage).board,
        itineraryId: itineraryToUse.id,
        cancellationPolicy: viewedCancellationPolicy,
        checkInDate: stayDetails.checkIn,
        checkOutDate: stayDetails.checkOut,
        hotelDetails: {
          ...assertNotUndefined(selectedHotelDetails),
          roomAmenities: assertNotUndefined(selectedPackage).roomAmenities,
        },
        hotelRoomId: null,
        paymentDetailsReq: getPaymentDetailsReq(),
        paymentDueDate: paymentDue.format(LOCALDATE_FORMAT),
        roomName: assertNotUndefined(selectedPackage).name,
        rooms: bookingDetails.current.occupancy,
        specialRequests: bookingDetails.current.specialRequests.map(
          (requestName) => SpecialRequestKeyByLabel[requestName]
        ),
        agentReference: bookingDetails.current.agentRef,
        supplierHotelRoomId:
          assertNotUndefined(selectedPackage).roomKeys[0] || null,
        taxesAndFees: assertNotUndefined(selectedPackage).taxesAndFees,
      };

      const resp =
        kind === "quote"
          ? await service.createBookingQuote(body)
          : await service.createDraftBooking(body);

      return [resp, itineraryToUse];
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(`Error while creating a booking ${kind}: `, e.message);
      const resp: CreateBookingResp_Error = {
        kind: "error",
        value: "Creation booking error",
      };
      return [resp, undefined];
    }
  };

  const redirectToItinerary = (itinerary: WithDbId<Itinerary>) => {
    // NOTE(Barry): Using replace because once we create the quote we don't want the user to go back to the checkout
    history.push(`${ROUTE_PATH.Itinerary}/${itinerary.value.itineraryNumber}`);
  };

  const isBookingDetailsFormValid = () => {
    let isFormValid = true;

    bookingDetails.current.occupancy.forEach((occupancy) => {
      occupancy.adults.forEach((adult) => {
        isFormValid = isFormValid && !!(adult.lastName && adult.firstName);
      });
      occupancy.childPassengers.forEach((child) => {
        isFormValid =
          isFormValid && !!(child.name.lastName && child.name.firstName);
      });
    });
    setShowBookingFormErrors(true);

    return isFormValid;
  };

  /**
   * Takes a booking function, i.e. confirm / create quote / pay, and wraps the execution of the function in logic that
   * validates that the booking form has been correctly completed as well as setting the {@link formError} appropriately
   * based on the result of the booking function
   */
  const wrapBookingFunctionWithLoadingMessage = (
    bookingFunc: () => Promise<string | undefined>
  ) => {
    return fromPromise(bookingFunc(), setFormError, addNotification);
  };

  const createBookingQuote = async () => {
    setShowBookingFormErrors(true);

    if (isBookingDetailsFormValid()) {
      await wrapBookingFunctionWithLoadingMessage(async () => {
        setIsLoading(true);
        const [resp, itinerary] = await createBooking({ kind: "quote" });

        setIsLoading(false);
        if (resp.kind === "success") {
          redirectToItinerary(assertNotUndefined(itinerary));
          setShowBookingFormErrors(false);

          addNotification({
            type: NotificationTypeEnum.Success,
            text:
              "We have successfully created a quote for you. Please confirm or pay now to create a booking",
          });
          return undefined;
        } else if (
          resp.kind === "packageNotAvailable" ||
          resp.kind === "error"
        ) {
          return "Could not create booking";
        } else {
          assertNever(resp);
        }
      });
    } else {
      setShowBookingFormErrors(true);
    }
  };

  const confirmBooking = async () => {
    setShowBookingFormErrors(true);

    await wrapBookingFunctionWithLoadingMessage(async () => {
      setIsLoading(true);
      const [draftResp, itinerary] = await createBooking({ kind: "draft" });
      if (draftResp.kind === "success") {
        const resp = await service.confirmBooking({
          bookingId: draftResp.value,
          packageDetails: {
            packageId: assertNotUndefined(selectedPackage).packageId,
            priceToken: assertNotNull(getCurrentPriceToken()),
          },
        });

        setIsLoading(false);
        setShowBookingFormErrors(false);

        if (resp.kind === "success") {
          redirectToItinerary(assertNotUndefined(itinerary));
          return undefined;
        } else if (
          resp.kind === "error" ||
          resp.kind === "supplierError" ||
          resp.kind === "invalidBookingId" ||
          resp.kind === "cannotConfirm"
        ) {
          return "Could not confirm booking";
        } else if (resp.kind === "packageNotAvailable") {
          return "Package no longer available";
        } else if (resp.kind === "instantPurchaseRequired") {
          return "Cannot confirm because instant purchase is required";
        } else {
          assertNever(resp);
        }
      } else if (
        draftResp.kind === "packageNotAvailable" ||
        draftResp.kind === "error"
      ) {
        return "Could not create booking";
      } else {
        assertNever(draftResp);
      }
    });
  };

  async function payForBooking(stripeCallback: StripeCallback) {
    setShowBookingFormErrors(true);
    setIsLoading(true);

    try {
      // if transactionToken is present. it means booking was created
      // but gateway payment failed. In this case we just repeat gateway payment
      if (transactionToken && processingItinerary && processingBookingId) {
        await stripeCallback(transactionToken);

        refetchDraftStatusCheck(processingBookingId, processingItinerary);

        return;
      }

      const [resp, itinerary] = await createBooking({ kind: "draft" });

      if (resp.kind === "success") {
        const paymentResponse = await service.payForBooking({
          creditCardToken: null,
          bookingId: resp.value,
          packageDetails: {
            packageId: assertNotUndefined(selectedPackage).packageId,
            priceToken: assertNotNull(getCurrentPriceToken()),
          },
          paymentOptions: {
            paymentMode: priceType,
            partial: false,
          },
        });

        if (paymentResponse.kind !== "success") {
          throw new Error(paymentResponse.kind);
        }

        setShowBookingFormErrors(false);

        // save transaction token to avoid creating a new bookingId if gateway payment is unsuccessful
        setTransactionToken(paymentResponse.value.transactionToken);
        // save created itinerary and booking to do a redirection on success
        setProcessingItinerary(assertNotUndefined(itinerary));
        setProcessingBookingId(resp.value);

        await stripeCallback(paymentResponse.value.transactionToken as string);

        refetchDraftStatusCheck(resp.value, assertNotUndefined(itinerary));
      } else if (resp.kind === "packageNotAvailable" || resp.kind === "error") {
        addNotification({
          text: "Could not create booking",
          type: NotificationTypeEnum.Error,
        });

        setIsLoading(false);
      }
    } catch (e) {
      // Stripe provides human-readable error messages, so we display them as is
      const isErrorFromStripe = !!e.code;

      addNotification({
        text: isErrorFromStripe
          ? e.message
          : PAYMENT_ERROR_TEXT[e.message] || PAYMENT_ERROR_FALLBACK,
        type: NotificationTypeEnum.Error,
      });

      setIsLoading(false);
    }
  }

  // Fetching itinerary data every second before redirection
  // Because the backend needs some time to confirm the quote
  function refetchDraftStatusCheck(
    bookingId: string,
    itinerary: WithDbId<Itinerary>
  ) {
    let iterations = 0;
    let itineraryIsLoaded = false;

    const interval = setInterval(async () => {
      iterations++;

      if (iterations > CHECK_DRAFT_STATUS_LIMIT) {
        // clear saved transaction token and itinerary
        // after successful transaction in payment gateway
        setTransactionToken(null);
        setProcessingItinerary(null);
        setProcessingBookingId(null);
        setIsLoading(false);

        addNotification({
          type: NotificationTypeEnum.Error,
          text: "A supplier error occurred. Please try again.",
        });
        clearInterval(interval);
        setIsLoading(false);

        return;
      }

      setIsLoading(true);

      if (!itineraryIsLoaded) {
        loadItineraryNumber(itinerary.value.itineraryNumber);
        itineraryIsLoaded = true;
      } else {
        const bookings = await fetchBookings();

        if (bookings) {
          const targetBooking = (bookings.value
            .items as AgentBookingData[]).find(
            ({ bookingData }) => bookingData.bookingId === bookingId
          );

          if (
            targetBooking &&
            targetBooking.bookingData.liveStatus !== LiveStatus.draft
          ) {
            // clear saved transaction token and itinerary
            // after successful transaction in payment gateway
            setTransactionToken(null);
            setProcessingItinerary(null);
            setProcessingBookingId(null);
            setIsLoading(false);

            redirectToItinerary(itinerary);
            clearInterval(interval);
          }
        }
      }
    }, CHECK_DRAFT_STATUS_INTERVAL);
  }

  const setBookingDetails = (details: BookingDetails) => {
    bookingDetails.current = details;
  };

  async function getTermsAndConditions() {
    setTermsAndConditions(await service.queryTermsAndConditions());
  }

  const logout = () => {
    setPaymentDetails({ kind: "loading" });
    setIsLoading(false);
    setShowBookingFormErrors(false);
    setTermsAndConditions(null);
    setTransactionToken(null);
    setProcessingItinerary(null);
    setProcessingBookingId(null);
    setPriceType(PaymentMode.gross);
    setSelectedPackage(undefined);
    setCancellationPolicyForPackage({ kind: "loading" });
    setFormError(undefined);

    bookingDetails.current = defaultBookingDetails;
  };

  if (
    !searchController.selectedHotel ||
    !selectedPackage ||
    !hotelListingResultDetails ||
    hotelListingResultDetails.kind === "loading"
  ) {
    return;
  }

  const availableHotels: HotelListingResultWithId[] = assertValueLoaded(
    assertNotUndefined(hotelListingResultDetails)
  );

  const selectedHotelId = assertNotUndefined(searchController.selectedHotel);
  const selectedHotel = availableHotels.find(
    (hotel) => hotel.id === selectedHotelId
  );

  return {
    completeBookingProps: {
      kind: "value",
      value: {
        stayDetails,
        hotelDetails: assertNotUndefined(selectedHotel).details,
        selectedPackage: assertNotUndefined(selectedPackage),
        cancellationPolicyForPackage,
        paymentDetails,
        bookingDetails: bookingDetails.current,
        isLoading,
        setBookingDetails,
        formError,
        showBookingFormErrors,
      },
    },
    termsAndConditions,
    loadPaymentDetails,
    createBookingQuote,
    confirmBooking,
    payForBooking,
    isBookingDetailsFormValid,
    priceType,
    setPriceType,
  };
};
