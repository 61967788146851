import { snakeCase } from "lodash";
import type { LRUCache } from "lru-cache";

import { createMapSnapshot } from "@util/create-snapshot";
import { Voucher, HelpfulTips } from "@util/voucher/render-voucher";
import type { SelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";

import { createPdfFromJSX } from "../agentus-utis";
import { sendErrorToRollbar } from "../error-handling";

async function createVoucherPage(booking: SelectedBookingInfo) {
  const map = booking.hotelLocation
    ? await createMapSnapshot(booking.hotelLocation)
    : null;

  const page = Voucher(
    {
      booking,
      mapImageUrl: map?.toDataURL("image/png") || ""
    }
  )
  return page;
}

export async function createVoucherPdf(bookings: SelectedBookingInfo[]) {
  if (!bookings.length) {
    return;
  }

  const pages: JSX.Element[] = [];

  for (const booking of bookings) {
    const page = await createVoucherPage(booking);
    pages.push(page);
  }
  if (pages.length > 0) {
    pages.push(HelpfulTips())
  }

  return createPdfFromJSX(pages);
}

export const DefaultCacheOptions = {
  max: 15,
  dispose: (value, _) => {
    URL.revokeObjectURL(value.filename);
  },
  ttl: 1000 * 60 * 5, // 5 minutes
  allowStale: false,
  updateAgeOnGet: false,
  updateAgeOnHas: false,
};

export type PdfCacheType = LRUCache<string, { filename: string; url: URL }>;

export async function actOnVoucherPdf(
  bookingsToDownload: SelectedBookingInfo[],
  action: (url: URL, filename: string) => void,
  cache: PdfCacheType | null,
  callbackOnFailure?: () => void,
  callbackOnSuccess?: () => void
) {
  const bookingNameFormat = (booking: SelectedBookingInfo) =>
    `${snakeCase(
      [booking.hotelName, booking.checkIn, booking.checkOut].join(" ")
    )}`;
  if (bookingsToDownload.length) {
    const filename =
      bookingsToDownload.map(bookingNameFormat).join("_") + ".pdf";
    const cacheKey = bookingsToDownload
      .map((booking) => booking.bookingId)
      .join("_");
    try {
      const { url } = cache?.get(cacheKey) ?? { url: await createVoucherPdf(bookingsToDownload) }

      if (url) {
        if (callbackOnSuccess) {
          callbackOnSuccess();
        }
        action(url, filename);
        cache?.set(cacheKey, { filename, url });
      }
    } catch (e) {

      sendErrorToRollbar(new Error("Failed to create voucher pdf"), {
        additionalInfo: {
          bookingsToDownload,
        },
      });
      if (callbackOnFailure) {
        callbackOnFailure();
      }
    }
  }

}
