import clsx from "clsx";
import React, { FC, ReactElement } from "react";
import { useLocation } from "react-router-dom";

import styles from "./styles.css";

interface TabsProps {
  className?: string;
}
const Tabs: FC<TabsProps> = ({ children, className }) => {
  const location = useLocation();

  const childrenArray = React.Children.toArray(children) as ReactElement[];

  const childrenWithHandlers = childrenArray.map((child) => {
    return React.cloneElement(child, {
      selected: child.props.href
        ? child.props.href === location.pathname
        : child.props.selected,
    });
  });

  return (
    <div className={clsx(styles.tabs, className)}>{childrenWithHandlers}</div>
  );
};

export default Tabs;
