import clsx from "clsx";
import React, { ChangeEvent } from "react";

import { Child, HotelRoom } from "@adl-gen/hotel/api";

import { Icons } from "../icon/icons";
import { InputSelect, InputSelectProps } from "../input-select";
import styles from "./guests-picker.css";
import { RoomList } from "./room-list";

export const DEFAULT_ROOM: HotelRoom = {
  numAdults: 1,
  children: new Array<Child>(),
};
export const DEFAULT_CHILD: Child = { age: 0 };
export const DEFAULT_VALUE: HotelRoom[] = [DEFAULT_ROOM];

const PLACEHOLDER = "Guests?";

/** Component Props */
export interface GuestsPickerProps
  extends Omit<InputSelectProps, "value" | "onChange"> {
  value?: HotelRoom[];
  onChange(e: ChangeEvent<HTMLInputElement>): void;
}

/**
 * GuestsPicker Component is a complex input component that allows the
 * selection of guest/room information.
 *
 * @author James Millar
 */
export const GuestsPicker = ({
  className,
  classes = {},
  value = DEFAULT_VALUE,
  onChange,
  ...props
}: GuestsPickerProps): JSX.Element => {
  const _classes = { ...classes };

  // Merge styles to overwrite the default readonly style of the Index
  _classes.inputText = clsx(
    classes?.inputText,
    styles.inputText,
    styles.readOnly
  );

  // Merge styles to overwrite the default input styles
  _classes.selectList = clsx(classes?.input, styles.selectList);

  return (
    <InputSelect
      {...props}
      className={className}
      classes={_classes}
      placeholder={PLACEHOLDER}
      iconBefore={Icons.usersAlt}
      value={displayValue(value)}
      readOnly
    >
      <RoomList value={value} onChange={onChange} />
    </InputSelect>
  );
};

/**
 * Utility function to provide user friendly display of value
 */
const displayValue = (value: HotelRoom[] | undefined): string => {
  if (value === undefined) {
    return "";
  }
  const _value = [...value];

  let newValue = `${_value.length} Room${_value.length > 1 ? "s" : ""}`;

  const adults = _value.reduce((result, current) => {
    return result + current.numAdults;
  }, 0);

  if (adults > 0) {
    newValue = `${newValue}, ${adults} Adult${adults > 1 ? "s" : ""}`;
  }

  const children = _value.reduce((result, current) => {
    return result + current.children.length;
  }, 0);

  if (children > 0) {
    newValue = `${newValue}, ${children} Child${children > 1 ? "ren" : ""}`;
  }

  return newValue;
};
