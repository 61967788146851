import classnames from "classnames";
import React, { ChangeEvent, InputHTMLAttributes } from "react";

import { HotelRoom } from "@adl-gen/hotel/api";
import { BaseProps } from "@common/base";
import { ShadowInput, useShadowInput } from "@common/hooks/shadow-input";

import { IconButton } from "../button/icon-button";
import { Icons } from "../icon/icons";
import { RoomItem } from "./room-item";
import styles from "./room-list.css";

/** Component Props */
export interface RoomListProps
  extends BaseProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, "value"> {
  classes?: { roomList?: string };
  value: HotelRoom[];
}

/**
 * Room List Component provides a complex input to enter application specific
 * multiple room information. Provides an interface that extends the `input type="text"`
 * element.
 *
 * @author James Millar
 */
export const RoomList = ({
  name = "rooms",
  value,
  onChange,
  ...props
}: RoomListProps): JSX.Element => {
  const classes = props.classes || {};

  const [setValue, shadow] = useShadowInput(name, value, onChange);

  // Handle changes from the RoomItem
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const _name = event.currentTarget.name;
    const _value = event.currentTarget.value;

    const index = Number(_name.replace("room-", "")) - 1;

    const newValue = [...value];
    newValue[index] = JSON.parse(_value);

    setValue(newValue);
  };

  // TODO for post MVP
  // Handler for the add button which addes an additional room
  // const clickAddHandler = () => {
  //   const newValue = [...value, DEFAULT_ROOM];
  //   setValue(newValue);
  // };

  // Handler for remove button which removes the appropriate room
  const clickRemoveHandler = (event) => {
    const newValue = [...value];

    // Remove the room at the given index
    newValue.splice(Number(event.currentTarget.value), 1);

    setValue(newValue);
  };

  return (
    <div
      className={classnames(styles.roomList, classes.roomList, props.className)}
    >
      <ShadowInput {...shadow} />
      {value?.map((current, index) => {
        const number = index + 1;
        return (
          <React.Fragment key={index}>
            <RoomItem
              className={styles.roomItem}
              classes={{ ages: styles.ages }}
              // label={`Room ${number}`} // TODO for post MVP
              label="Room"
              name={`room-${number}`}
              value={current}
              onChange={changeHandler}
            />
            {index > 0 && (
              <IconButton
                className={styles.remove}
                emphasis="medium"
                iconBefore={Icons.minusCircleFilled}
                onClick={clickRemoveHandler}
                value={index}
              >
                Remove Room
              </IconButton>
            )}
          </React.Fragment>
        );
      })}
      {/* TODO for post MVP */}
      {/*<IconButton*/}
      {/*  key={`add`}*/}
      {/*  emphasis="medium"*/}
      {/*  iconBefore={Icons.plusCircleFilled}*/}
      {/*  onClick={clickAddHandler}*/}
      {/*>*/}
      {/*  Add another Room*/}
      {/*</IconButton>*/}
    </div>
  );
};
