import moment from "moment";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { BookingSearchQuery } from "@adl-gen/hotel/api";
import { ROUTE_PATH } from "@constants/common";
import { Button } from "@widgets/button/button";
import { InputText } from "@widgets/input-text/input-text";

import styles from "./styles.css";

const DATE_REGEX = /^(0?[1-9]|[12]\d|3[01])\/(0?[1-9]|1[012])\/\d{4}$/;

const MultiSearch = () => {
  const [value, setValue] = useState("");

  const location = useLocation();
  const history = useHistory();

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value);
  }

  function onSubmit(e: FormEvent) {
    e.preventDefault();

    if (value.length === 0) {
      return;
    }

    if (value.startsWith("IT")) {
      return redirectToSearch("itineraryNumber", value);
    }
    if (value.startsWith("BO")) {
      return redirectToSearch("bookingNumber", value);
    }
    if (value.match(DATE_REGEX)) {
      const textDate = moment(value, "DD/MM/YYYY").format("YYYY-MM-DD");
      const paramValue = {
        from: textDate,
        to: textDate,
      };
      return redirectToSearch("serviceFrom", JSON.stringify(paramValue));
    }

    return redirectToSearch("lastName", value);
  }

  function redirectToSearch<T extends keyof BookingSearchQuery>(
    searchCriteria: T,
    v: string
  ) {
    const params = new URLSearchParams(location.search);
    params.set(searchCriteria, v);

    location.search = params.toString();
    location.pathname = ROUTE_PATH.BookingsAdvanced;
    history.push(location);
  }

  return (
    <form className={styles.multiSearch} onSubmit={onSubmit}>
      <InputText
        value={value}
        onChange={onChange}
        placeholder="Find your booking"
      />

      <Button type="submit">Search</Button>
    </form>
  );
};

export default MultiSearch;
