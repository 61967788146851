import * as yup from "yup";

import { ERROR_TEXT, validatePassword, ValidationRule } from "@util/validation";

import { ChangePasswordFormValues } from "./types";

export const ChangePasswordSchema = yup
  .object({
    password: yup.string().required(ERROR_TEXT[ValidationRule.RequiredField]),
    newPassword: yup
      .string()
      .required(ERROR_TEXT[ValidationRule.RequiredField])
      .test("new-password", (value: string, ctx) => {
        const errorMessage = validatePassword(value);

        if (errorMessage) {
          return ctx.createError({ message: errorMessage });
        }

        return true;
      }),
    confirmPassword: yup
      .string()
      .equals([yup.ref("newPassword")], "Passwords don't match")
      .required(ERROR_TEXT[ValidationRule.RequiredField]),
  })
  .required();

export const ChangePasswordDefaultValues: ChangePasswordFormValues = {
  password: "",
  newPassword: "",
  confirmPassword: "",
};
