import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppUserType } from "@adl-gen/hotel/db";
import { LoggedInContext } from "@app/app";
import { SearchIconSvg } from "@common/icon/icons-svg";
import { Icons } from "@common/icon/svg";
import { WithAgencyController } from "@controllers/agency-controller";
import { assertNotUndefined } from "@hx/util/types";
import { IconButton } from "@widgets/button/icon-button";
import { InputText } from "@widgets/input-text/input-text";

import { ROLE_MAP, STATUS_MAP } from "../constant";
import parentStyles from "../settings.css";
import AgentModal from "./agent-modal";
import DetailsButton from "./details-button";
import css from "./styles.css";

const ManageAgents: FC<WithAgencyController> = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [filter, setFilter] = useState("");

  const { users, fetchUsers } = assertNotUndefined(
    useContext(LoggedInContext).userController
  );

  const { fetchAgencies, agencies } = assertNotUndefined(
    useContext(LoggedInContext).agencyController
  );

  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  useEffect(() => {
    fetchUsers();
    fetchAgencies();
  }, []);

  const isUserBackoffice = useMemo(() => {
    return userProfile?.appUser.value.userType !== AppUserType.agencyAdmin;
  }, [userProfile]);

  function onChangeFilter(e: ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value);
  }

  const filteredList = useMemo(() => {
    return filter
      ? users.filter(({ value }) =>
          value.fullname.toLowerCase().includes(filter.toLowerCase())
        )
      : users;
  }, [filter, users]);

  return (
    <div className={parentStyles.settingsTableContainer}>
      <InputText
        className={parentStyles.nameFilter}
        iconBefore={SearchIconSvg}
        placeholder="Agent name"
        value={filter}
        onChange={onChangeFilter}
      />

      <IconButton
        className={parentStyles.addButton}
        onClick={() => setModalOpened(true)}
        iconBefore={Icons.plusCircleFilled}
      >
        Add New Agent
      </IconButton>

      <table
        className={clsx(
          parentStyles.settingsTable,
          css.agentsTable,
          isUserBackoffice && css.backoffice
        )}
      >
        <thead className={parentStyles.tableHeader}>
          <tr>
            <th className={parentStyles.name}>Name</th>
            <th className={parentStyles.email}>Email</th>
            {isUserBackoffice ? (
              <th className={parentStyles.agencyName}>Agency name</th>
            ) : null}
            <th className={parentStyles.role}>User type</th>
            <th className={parentStyles.status}>Status</th>
            <th>Menu</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.map((user) => {
            const { id, value } = user;
            const { fullname, email, userStatus, userType, agencyId } = value;
            const { label, color, background } = STATUS_MAP[userStatus];
            return (
              <tr key={id}>
                <td className={clsx(parentStyles.name, parentStyles.plainText)}>
                  <div className={parentStyles.profilePicture}>
                    {fullname[0]}
                  </div>
                  <span>{fullname}</span>
                </td>
                <td
                  className={clsx(parentStyles.email, parentStyles.plainText)}
                >
                  <span>{email}</span>
                </td>
                {isUserBackoffice && (
                  <td
                    className={clsx(
                      parentStyles.plainText,
                      parentStyles.agencyName
                    )}
                  >
                    <span>
                      {
                        agencies.find((agency) => agency.id === agencyId)?.value
                          .name
                      }
                    </span>
                  </td>
                )}
                <td className={parentStyles.role}>
                  <span
                    style={{
                      color: ROLE_MAP[userType].color,
                      backgroundColor: ROLE_MAP[userType].background,
                    }}
                  >
                    {ROLE_MAP[userType].label}
                  </span>
                </td>
                <td className={parentStyles.status}>
                  <span
                    style={{
                      color,
                      backgroundColor: background,
                    }}
                  >
                    {label}
                  </span>
                </td>

                <td>
                  <DetailsButton user={user} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <AgentModal isOpen={modalOpened} onClose={() => setModalOpened(false)} />
    </div>
  );
};

export default ManageAgents;
