import React from "react";

import { AppUserType } from "@adl-gen/hotel/db";
import {
  AgenciesIcon,
  AgencyIcon,
  LockIcon,
  StructureIcon,
  UserRoundIcon,
  UsersIcon,
} from "@assets/svg-components";

import { SettingsNavLink } from "./types";

export const SETTINGS_LINK_LABEL: Record<SettingsNavLink, string> = {
  [SettingsNavLink.UserProfile]: "User Profile",
  [SettingsNavLink.AgentProfile]: "Agent Profile",
  [SettingsNavLink.AgencyDetails]: "Agency Details",
  [SettingsNavLink.Password]: "Password",
  [SettingsNavLink.ManageAgents]: "Manage Agents",
  [SettingsNavLink.ManageBackofficeUsers]: "Manage Backoffice Users",
  [SettingsNavLink.ManageAgencies]: "Manage Agencies",
};

export const SETTINGS_LINK_PERMISSION: Partial<Record<
  SettingsNavLink,
  AppUserType[]
>> = {
  [SettingsNavLink.UserProfile]: [AppUserType.admin, AppUserType.backOffice],
  [SettingsNavLink.AgentProfile]: [AppUserType.agencyAdmin, AppUserType.agent],
  [SettingsNavLink.AgencyDetails]: [AppUserType.agencyAdmin],
  [SettingsNavLink.ManageAgents]: [
    AppUserType.agencyAdmin,
    AppUserType.admin,
    AppUserType.backOffice,
  ],
  [SettingsNavLink.ManageBackofficeUsers]: [AppUserType.admin],
  [SettingsNavLink.ManageAgencies]: [AppUserType.admin, AppUserType.backOffice],
};

export const SETTINGS_NAV_ICON: Record<SettingsNavLink, JSX.Element> = {
  [SettingsNavLink.UserProfile]: <UserRoundIcon />,
  [SettingsNavLink.AgentProfile]: <UserRoundIcon />,
  [SettingsNavLink.AgencyDetails]: <AgencyIcon />,
  [SettingsNavLink.Password]: <LockIcon />,
  [SettingsNavLink.ManageAgents]: <StructureIcon />,
  [SettingsNavLink.ManageBackofficeUsers]: <UsersIcon />,
  [SettingsNavLink.ManageAgencies]: <AgenciesIcon />,
};
