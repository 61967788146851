import classnames from "classnames";
import clsx from "clsx";
import React, { useCallback, useContext } from "react";
import { NavLink } from "react-router-dom";

import { LoggedInContext } from "@app/app";
import { BaseProps } from "@common/base";
import { assertNotUndefined } from "@hx/util/types";
import {
  SETTINGS_LINK_LABEL,
  SETTINGS_LINK_PERMISSION,
  SETTINGS_NAV_ICON,
} from "@pages/settings/nav-settings/constant";
import { SettingsNavLink } from "@pages/settings/nav-settings/types";

import styles from "./nav-settings.css";

/** Component Props */
export interface NavSettingsProps extends BaseProps {
  classes?: { navSettings?: string };
}

export const NavSettings = (props: NavSettingsProps): JSX.Element => {
  const currentPath = location.pathname;

  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  const checkIfOptionAvailable = useCallback(
    (link: SettingsNavLink): boolean => {
      const allowedRoles = SETTINGS_LINK_PERMISSION[link];

      if (allowedRoles) {
        return !!(
          userProfile &&
          allowedRoles.includes(userProfile?.appUser.value.userType)
        );
      } else {
        return true;
      }
    },
    [userProfile]
  );

  return (
    <nav
      className={classnames(
        styles.navSettings,
        props.classes?.navSettings,
        props.className
      )}
    >
      {Object.values(SettingsNavLink).map((link) => {
        if (!checkIfOptionAvailable(link)) {
          return null;
        }

        const selected = currentPath === link;

        return (
          <NavLink
            key={link}
            className={clsx(styles.navLink, selected && styles.selected)}
            to={{ ...location, pathname: link }}
          >
            {SETTINGS_NAV_ICON[link]}
            <span>{SETTINGS_LINK_LABEL[link]}</span>
          </NavLink>
        );
      })}
    </nav>
  );
};
