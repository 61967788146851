import React, { FC, useState } from "react";

import layout from "@common/layout-lib.css";
import { WithAgencyController } from "@controllers/agency-controller";
import { AgencyManageProperties } from "@pages/settings/types";
import { Button } from "@widgets/button/button";
import TabOption from "@widgets/tab-option";
import Tabs from "@widgets/tabs";

import Commission from "./commission";
import { AgencyDetailsTab } from "./constants";
import ContactDetails from "./contact-details";
import FinancialDetails from "./financial-details";
import GeneralDetails from "./general-details";
import styles from "./styles.css";

const AgencyDetails: FC<WithAgencyController> = ({ agencyController }) => {
  const {
    updateCurrentAgencyDetailsNestedField,
    formInputChangeHandler,
    agencySettingsState,
    updateCurrentAgencyDetailsField,
    onSaveAgencyDetails,
  } = agencyController;

  const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState<AgencyDetailsTab>(
    AgencyDetailsTab.GeneralDetails
  );

  const agencyProps: AgencyManageProperties = {
    agencyDetails: agencySettingsState.currentAgencyDetails,
    formInputChangeHandler,
    updateCurrentAgencyDetailsField,
    updateCurrentAgencyDetailsNestedField,
  };

  async function save() {
    setLoading(true);

    await onSaveAgencyDetails();

    setLoading(false);
  }

  function renderActiveTab() {
    switch (activeTab) {
      case AgencyDetailsTab.GeneralDetails: {
        return <GeneralDetails {...agencyProps} />;
      }
      case AgencyDetailsTab.ContactDetails: {
        return <ContactDetails {...agencyProps} />;
      }
      case AgencyDetailsTab.FinancialDetails: {
        return <FinancialDetails {...agencyProps} />;
      }
      case AgencyDetailsTab.Commission: {
        return <Commission {...agencyProps} />;
      }
    }
  }

  return (
    <div className={layout.vertical}>
      <Tabs>
        {Object.values(AgencyDetailsTab).map((tab) => (
          <TabOption
            key={tab}
            onClick={() => setActiveTab(tab)}
            selected={tab === activeTab}
          >
            {tab}
          </TabOption>
        ))}
      </Tabs>

      {renderActiveTab()}

      <Button className={styles.saveButton} onClick={save} isLoading={loading}>
        Save
      </Button>
    </div>
  );
};

export default AgencyDetails;
