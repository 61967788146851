import classnames from "classnames";
import React from "react";

import { IconSizeable } from "../icon/icon-sizeable";

import { Button, ButtonProps } from "./button";
import styles from "./icon-button.css";

// Maping of icon size to button sizes as per the application visual design
const ICON_SIZE = {
  regular: 24,
  large: 32,
  small: 16,
};

/** Properties for IconButton component. Extends the ButtonProps. */
export interface IconButtonProps extends ButtonProps {
  classes?: { iconButton?: string; button?: string };
  /* Icon to be prefixed before the children */
  iconBefore?: JSX.Element;
  /* Icon to be postfixed before the children */
  iconAfter?: JSX.Element;
}

/**
 * IconButton component that provides the option to include a prefixed and/or
 * postfixed icon. The component handles icon sizing as per the
 * application visual design.
 *
 * @author James Millar
 */
export const IconButton = ({
  className,
  classes,
  iconBefore,
  iconAfter,
  children,
  ...props
}: IconButtonProps) => {
  // Aggregation of class names for root element
  const buttonClasses = classnames(
    styles.iconButton,
    styles[props.size || "regular"],
    classes?.iconButton,
    className
  );

  // Classes to be passed through to the Button component. Classes has been
  // destructured from props for use in this component and needs to be
  // handled manually for pass-through classes.
  const transferClasses = {
    button: classes?.button,
    container: styles.iconButtonContainer,
  };

  // The size in pixels of the icon container size
  const iconSize = ICON_SIZE[props.size || "regular"];

  return (
    <Button className={buttonClasses} classes={transferClasses} {...props}>
      {iconBefore && <IconSizeable size={iconSize} icon={iconBefore} />}
      {children}
      {iconAfter && <IconSizeable size={iconSize} icon={iconAfter} />}
    </Button>
  );
};
