import React, { FC } from "react";

import styles from "@pages/hotel-search/hotel-results/list-view-layout/hotel-list-card/styles.css";
import { Price, PriceProps } from "@widgets/price/price";
import { Skeleton } from "@widgets/skeleton/skeleton";

interface Props {
  ratePricing: PriceProps;
}

const AverageHotelPrice: FC<Props> = ({ ratePricing }) => {
  const price = Math.ceil(Number(ratePricing.value));

  return (
    <div className={styles.lowestAveragePriceSection}>
      <Price
        value={price ? `${price}` : undefined}
        currency={ratePricing.currency}
        fractionDigits={0}
      />
      <div className={styles.lowestAveragePriceLabel}>
        <Skeleton
          className={styles.rateNoteSkeleton}
          trigger={ratePricing.value !== undefined}
        >
          Lowest average nightly rate
        </Skeleton>
      </div>
    </div>
  );
};

export default AverageHotelPrice;
