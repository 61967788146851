import clsx from "clsx";
import React, { ChangeEvent, InputHTMLAttributes } from "react";

import { HotelRoom } from "@adl-gen/hotel/api";
import { BaseProps } from "@common/base";
import { ShadowInput, useShadowInput } from "@common/hooks/shadow-input";

import { InputStepper } from "../input-stepper/input-stepper";
import { DEFAULT_CHILD } from "./guests-picker";
import styles from "./room-item.css";

const MIN_ADULTS = 1;
const MAX_ADULTS = 15;

/** Component Props */
export interface RoomItemProps
  extends BaseProps,
    // Extends the Input element, excluding the 'value' and 'name' attributes
    Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "name">,
    // Extend the IInpuut Element picking just the 'name' attribute but making it required
    Required<Pick<InputHTMLAttributes<HTMLInputElement>, "name">> {
  classes?: { roomItem?: string; inputs?: string; ages?: string };
  value: HotelRoom;
  label: string;
}

/**
 * Room Item Component provides a compound input to enter application specific
 * room information. Provides an interface that extends the `input type="text"`
 * element.
 *
 * @author James Millar
 */
export const RoomItem = ({
  name,
  value,
  onChange,
  ...props
}: RoomItemProps): JSX.Element => {
  const classes = props.classes || {};

  const [setValue, shadow] = useShadowInput(name, value, onChange);

  // Handler for updates to internal inputs, modifies the value
  const changeHandler = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const _name = event.currentTarget.name;
    const _value = Number(event.currentTarget.value);

    // Clone the component value for modifying
    const newValue = { ...value };

    // If we are updating the number of adults
    if (_name === "numAdults") {
      newValue[_name] = _value;
    }

    // If we are updating the number of children
    if (_name === "children") {
      // Get the inputs value as a number
      const children = Number(_value);

      // If we are adding a child
      if (children > newValue.children.length) {
        newValue.children.push(DEFAULT_CHILD);
      }

      // If we are removing a child
      if (children < newValue.children.length) {
        newValue.children.pop();
      }
    }

    // If we are updating a childs age
    if (_name.indexOf("child-") === 0) {
      const index = Number(_name.replace("child-", "")) - 1;
      newValue.children[index].age = _value;
    }

    // Update the shadow state with the new value
    setValue(newValue);
  };

  return (
    <div className={clsx(styles.roomItem, classes.roomItem, props.className)}>
      <span className={styles.roomLabel}>{props.label}</span>
      <ShadowInput {...shadow} />
      <div className={clsx(styles.inputs, classes.inputs)}>
        <div className={styles.input}>
          <span className={styles.text}>Adults</span>
          <InputStepper
            {...stepperProps}
            className={styles.peopleCounter}
            classes={{ inputText: styles.counterInput }}
            name="numAdults"
            value={value.numAdults}
            min={MIN_ADULTS}
            max={MAX_ADULTS}
            onChange={changeHandler}
          />
        </div>
        <div className={styles.input}>
          <span className={styles.text}>Children</span>
          <InputStepper
            {...stepperProps}
            className={styles.peopleCounter}
            classes={{ inputText: styles.counterInput }}
            name="children"
            value={value.children.length}
            min={0}
            onChange={changeHandler}
          />
        </div>
        {value?.children.length ? (
          <div className={clsx(styles.childrenAges, classes.ages)}>
            {value.children.map((_, index) => {
              return (
                <label key={index}>
                  <span className={styles.childrenAgeTitle}>
                    Child Age {index + 1}
                  </span>
                  <select
                    name={`child-${index + 1}`}
                    value={value[`child-${index + 1}`]}
                    onChange={changeHandler}
                  >
                    {[...Array(18)].map((__, _index) => (
                      <option key={_index} value={_index}>
                        {_index === 0 ? "Under 1" : _index}
                      </option>
                    ))}
                  </select>
                </label>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const stepperProps = {
  editable: false,
  classes: {
    inputText: clsx(styles.inputText, styles.readOnly),
  },
};
