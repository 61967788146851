import clsx from "clsx";
import React, { FC } from "react";

import { PoiNearHotel } from "@adl-gen/ids/externalapi";
import { Loading } from "@models/loading";
import styles from "@pages/hotel-details/hotel-details.css";

interface Props {
  nearbyPoisToHotel?: Loading<PoiNearHotel[]>;
}

const AboutThisArea: FC<Props> = ({ nearbyPoisToHotel }) => {
  const aboutThisAreaMap: Map<string, string[]> = new Map();

  /**
   * Computes the POI display string from a @PoiNearHotel object in the format
   * '<poiName> - <distance from hotel> metres'.
   */
  function getPoiDisplayString(poiNearHotel: PoiNearHotel): string {
    return `${poiNearHotel.poi.value.name} - ${poiNearHotel.distanceFromHotel} metres`;
  }

  if (!nearbyPoisToHotel) {
    return <div>Loading..</div>;
  }

  if (nearbyPoisToHotel.kind === "loading") {
    return null;
  } else if (nearbyPoisToHotel.kind === "error") {
    return <div>Error: {nearbyPoisToHotel.error}</div>;
  } else {
    const pois: string[] = nearbyPoisToHotel.value.map(getPoiDisplayString);
    const half = Math.ceil(pois.length / 2);
    aboutThisAreaMap.set("What's nearby", pois.slice(0, half));
    aboutThisAreaMap.set("", pois.slice(-half));
    return (
      <div className={styles.contentSection}>
        <div className={styles.contentTitle}>About this Area</div>
        <div className={clsx(styles.box, styles.aboutThisArea)}>
          {/* TODO(jess): Uncomment out this area when we have curated region descriptions and map view. */}
          {/* <div className={styles.aboutThisAreaTopRow}>
                <div className={styles.aboutThisAreaTopRowTextSection}>
                  <div className={styles.blockHeading}>London</div>
                  <div className={styles.blockBodyText}>
                    Located in London's Kensington neighbourhood, 88 Studios
                    Kensington is in the business district and near a train station.
                    Kensington Palace and Royal Albert Hall are notable landmarks,
                    and some of the area's popular attractions include Stamford
                    Bridge Stadium and London Eye. Looking to enjoy an event or a
                    game? See what's going on at Wembley Stadium or SSE Arena,
                    Wembley. Our guests love the property's quiet location.
                  </div>
                </div>
                <div
                  className={clsx(styles.mapView, styles.aboutThisAreaMap)}
                >
                  Map view
                </div>
              </div> */}
          <div className={styles.aboutThisAreaColumns}>
            <div className={styles.contentColumn}>
              <AboutThisAreaContentGroups
                title={"What's nearby"}
                map={aboutThisAreaMap}
              />
            </div>
            <div className={styles.contentColumn}>
              <AboutThisAreaContentGroups title={""} map={aboutThisAreaMap} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AboutThisArea;

const AboutThisAreaContentGroups: FC<{
  title: string;
  map: Map<string, string[]>;
}> = ({ title, map }): JSX.Element => {
  return (
    <>
      <div className={styles.contentGroup}>
        <div className={styles.blockHeading}>{title}</div>
        {map.get(title)?.map((val, index) => (
          <span key={index} className={styles.blockBodyText}>
            {val}
          </span>
        ))}
      </div>
    </>
  );
};
