import React, { FC } from "react";

import { TermsAndConditions } from "@adl-gen/hotel/db";

import styles from "./styles.css";

interface Props {
  termsAndConditions: TermsAndConditions | null;
}

const Conditions: FC<Props> = ({ termsAndConditions }) => {
  if (!termsAndConditions) {
    return null;
  }

  const { content } = termsAndConditions;

  return (
    <div
      className={styles.conditions}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default Conditions;
