import clsx from "clsx";
import React from "react";

import { RefreshIcon } from "@assets/svg-components";
import { ROUTE_PATH } from "@constants/common";
import { Button } from "@widgets/button/button";
import { Logo } from "@widgets/logo/logo";

import styles from "./styles.css";

const ErrorFallback = () => {
  function refresh() {
    window.location.reload();
  }

  return (
    <div className={styles.errorFallback}>
      <Logo className={styles.logo} />

      <span className={styles.text}>
        Sorry, an unhandled error has occurred. Please try refreshing and
        performing the action again.
      </span>

      <Button className={styles.refreshButton} onClick={refresh}>
        <RefreshIcon />
      </Button>

      <span className={clsx(styles.text, styles.bottomText)}>
        Or you can go back to the <a href={ROUTE_PATH.Search}>search</a> page.
      </span>
    </div>
  );
};

export default ErrorFallback;
