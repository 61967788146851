import { useState } from "react";

import { WithDbId } from "@adl-gen/common/db";
import { SortDirection, TableQuery } from "@adl-gen/common/tabular";
import {
  AppUserDetails,
  ConfirmInviteAgentUserResp,
  CreateAgentUserReq,
  UpdateUserPasswordReq,
  UserInviteResp,
  UserReq,
  ValidateInviteAgentUserResp,
} from "@adl-gen/hotel/api";
import { AppUserType, UserStatus } from "@adl-gen/hotel/db";
import { NotificationController } from "@controllers/notification-controller";
import { NotificationTypeEnum } from "@models/common";

import { Service } from "../../service/service";

export type UserService = Pick<
  Service,
  | "queryUsers"
  | "updateUser"
  | "createUser"
  | "deleteUser"
  | "updateUserPassword"
  | "confirmInviteAgentUser"
  | "validateInviteAgentUser"
  | "inviteUser"
  | "createAgentUser"
>;

export interface UserControllerProps {
  service: UserService;
  currentUser?: AppUserDetails;
  notificationController: NotificationController;
}

export interface UserController {
  users: WithDbId<AppUserDetails>[];
  createAgentUser(newUser: CreateAgentUserReq): Promise<void>;
  updateUser(user: WithDbId<UserReq>): Promise<void>;
  deleteUser(id: string): Promise<void>;
  fetchUsers(): Promise<void>;
  backofficeUsers: WithDbId<AppUserDetails>[];
  createBackofficeUser(newUser: UserReq): Promise<void>;
  updateBackofficeUser(user: WithDbId<UserReq>): Promise<void>;
  deleteBackofficeUser(id: string): Promise<void>;
  fetchBackofficeUsers(): Promise<void>;
  updateUserPassword(passwords: UpdateUserPasswordReq): Promise<void>;
  confirmInvite(
    invitehash: string,
    password: string
  ): Promise<ConfirmInviteAgentUserResp>;
  validateInviteHash(invitehash: string): Promise<ValidateInviteAgentUserResp>;
  inviteUser(email: string): Promise<UserInviteResp>;

  /** Сlearing values after logout */
  logout(): void;
}

export const useUserController = ({
  service,
  currentUser,
  notificationController,
}: UserControllerProps): UserController => {
  const [users, setUsers] = useState<WithDbId<AppUserDetails>[]>([]);
  const [backofficeUsers, setBackofficeUsers] = useState<
    WithDbId<AppUserDetails>[]
  >([]);

  const { addNotification } = notificationController;

  async function createAgentUser(newUser: CreateAgentUserReq) {
    // no try/catch block here because we have it in the corresponding component
    await service.createAgentUser(newUser);
    await fetchUsers();
  }

  async function updateUser(user: WithDbId<UserReq>) {
    // no try/catch block here because we have it in the corresponding component
    await service.updateUser(user);
    addNotification({
      text: "Profile updated successfully",
      type: NotificationTypeEnum.Success,
    })
  }

  async function deleteUser(id: string) {
    try {
      await service.deleteUser(id);

      setUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.id === id) {
            return {
              id,
              value: {
                ...user.value,
                userStatus: UserStatus.deleted,
              },
            };
          } else {
            return user;
          }
        })
      );
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to delete user",
      });
    }
  }

  async function createBackofficeUser(newUser: UserReq) {
    // no try/catch block here because we have it in the corresponding component
    await service.createUser(newUser);
    await fetchBackofficeUsers();
  }

  async function updateBackofficeUser(user: WithDbId<UserReq>) {
    // no try/catch block here because we have it in the corresponding component
    await service.updateUser(user);
    addNotification({
      text: "Backoffice user updated successfully",
      type: NotificationTypeEnum.Success,
    })
    await fetchBackofficeUsers();
  }

  async function deleteBackofficeUser(id: string) {
    try {
      await service.deleteUser(id);

      setBackofficeUsers((prevUsers) =>
        prevUsers.map((user) => {
          if (user.id === id) {
            return {
              id,
              value: {
                ...user.value,
                userStatus: UserStatus.deleted,
              },
            };
          } else {
            return user;
          }
        })
      );
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to delete backoffice user",
      });
    }
  }

  async function updateUserPassword(passwords: UpdateUserPasswordReq) {
    try {
      const response = await service.updateUserPassword(passwords);

      if (response.kind === "success") {
        addNotification({
          type: NotificationTypeEnum.Success,
          text: "Password has been successfully changed",
        });
      } else if (response.kind === "error") {
        throw new Error(response.value);
      }
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Password change error",
      });
    }
  }

  async function fetchUsers() {
    if (!currentUser) {
      throw new Error("Cannot find current user profile");
    }

    const query: TableQuery = {
      offset: 0,
      count: 20,
      sorting: [{ field: "agencyId", direction: SortDirection.ascending }],
      filter: {
        kind: "or",
        value: [
          {
            kind: "equalTo",
            value: {
              expr1: { kind: "field", value: "userType" },
              expr2: { kind: "string", value: "agencyAdmin" },
            },
          },
          {
            kind: "equalTo",
            value: {
              expr1: { kind: "field", value: "userType" },
              expr2: { kind: "string", value: "agent" },
            },
          },
        ],
      },
    };

    if (currentUser.userType === AppUserType.agencyAdmin) {
      if (!currentUser.agencyId) {
        throw new Error(
          "User is not associated with agency, cannot load agency users"
        );
      }

      query.filter = {
        kind: "equalTo",
        value: {
          expr1: { kind: "field", value: "agencyId" },
          expr2: { kind: "string", value: currentUser.agencyId },
        },
      };

      query.sorting = [
        { field: "fullname", direction: SortDirection.ascending },
      ];
    }

    try {
      const result = await service.queryUsers(query);

      setUsers(result.items);
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to get users",
      });
    }
  }

  async function fetchBackofficeUsers() {
    try {
      const result = await service.queryUsers({
        filter: {
          kind: "equalTo",
          value: {
            expr1: { kind: "field", value: "userType" },
            expr2: { kind: "string", value: "backOffice" },
          },
        },
        offset: 0,
        count: 20,
        sorting: [],
      });

      setBackofficeUsers(result.items);
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to get users",
      });
    }
  }

  function validateInviteHash(inviteHash: string) {
    return service.validateInviteAgentUser({ inviteHash });
  }

  function inviteUser(email: string) {
    return service.inviteUser({ email });
  }

  function confirmInvite(inviteHash: string, password: string) {
    return service.confirmInviteAgentUser({ inviteHash, password });
  }

  function logout() {
    setUsers([]);
    setBackofficeUsers([]);
  }

  return {
    users,
    createAgentUser,
    updateUser,
    deleteUser,
    fetchUsers,
    backofficeUsers,
    createBackofficeUser,
    updateBackofficeUser,
    deleteBackofficeUser,
    fetchBackofficeUsers,
    updateUserPassword,
    validateInviteHash,
    inviteUser,
    confirmInvite,
    logout,
  };
};
