import clsx from "clsx";
import React, { forwardRef } from "react";

import styles from "./styles.css";

interface Props {
  images: string[];
  currentImage: number;
  onSelectImage(index: number): void;
  vertical?: boolean;
}
const ImagesList = forwardRef<HTMLUListElement, Props>(
  ({ images, currentImage, onSelectImage, vertical }, ref) => {
    return (
      <ul className={clsx(styles.list, vertical && styles.vertical)} ref={ref}>
        {images.map((image, index) => (
          <li
            className={currentImage === index ? styles.selected : ""}
            onClick={() => onSelectImage(index)}
            key={index}
          >
            <img src={image} alt="" loading="lazy" />
          </li>
        ))}
      </ul>
    );
  }
);

export default ImagesList;
