import clsx from "clsx";
import React, { FC } from "react";

import { Button } from "@widgets/button/button";

import styles from "./styles.css";

interface PropTypes {
  open: boolean;
  title?: string;
  text?: string;
  className?: string;
  cancelBtnText?: string;
  okBtnText?: string;
  onOk?(): void;
  onCancel?(): void;
}

const DialogWindow: FC<PropTypes> = ({
  children,
  open,
  onCancel,
  className,
  title,
  onOk,
  text,
  cancelBtnText,
  okBtnText,
}) => {
  return (
    <div className={clsx(styles.dialogWrapper, open && styles.open)}>
      <div className={clsx(styles.dialog, className)}>
        <div className={styles.dialogBody}>
          {title && <div className={styles.title}>{title}</div>}
          {text && <div className={styles.text}>{text}</div>}
          {children}
        </div>

        {(onCancel || onOk) && (
          <div className={styles.dialogFooter}>
            {onCancel && (
              <Button emphasis="medium" onClick={onCancel}>
                {cancelBtnText || "Cancel"}
              </Button>
            )}
            {onOk && (
              <Button className={styles.rightButton} onClick={onOk}>
                {okBtnText || "Ok"}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DialogWindow;
