export enum ValidationRule {
  RequiredField = "RequiredField",
  Email = "Email",
}

export const ERROR_TEXT: Record<ValidationRule, string> = {
  [ValidationRule.RequiredField]: "This field is required",
  [ValidationRule.Email]: "Please enter a valid email",
};

export const VALIDATION_REGEX: Record<ValidationRule, RegExp> = {
  [ValidationRule.RequiredField]: /[\S]+/,
  [ValidationRule.Email]: /^([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|\[[\t -Z^-~]*])$/,
};

export function validateString(str: string, rule: ValidationRule): string {
  const isValid = VALIDATION_REGEX[rule].test(str);

  return isValid ? "" : ERROR_TEXT[rule];
}

export function throws(error: Error): never {
  throw error;
}

const lowercaseLetterRegex = /[a-z]+/;
const uppercaseLetterRegex = /[A-Z]+/;
const numberRegex = /\d+/;
const specialCharacterRegex = /[!@#$%^&*()_+|~\-={}\[\]:;?,.\/]+/;
const forbiddenCharacterRegex = /["'<>`]+/;

export function validatePassword(value: string): string {
  if (value.length < 8) {
    return "The password must be at least 8 characters long";
  }

  if (value.length > 128) {
    return "The password must not exceed 128 characters";
  }

  if (!lowercaseLetterRegex.test(value)) {
    return "The password must contain at least one lowercase letter";
  }

  if (!uppercaseLetterRegex.test(value)) {
    return "The password must contain at least one uppercase letter";
  }

  if (!numberRegex.test(value)) {
    return "The password must contain at least one number";
  }

  if (!specialCharacterRegex.test(value)) {
    return "The password must contain at least one special symbol (!@#$%^&*()_+|~-={}[]:;?,.\\/)";
  }

  if (forbiddenCharacterRegex.test(value)) {
    return "Password cannot contain any forbidden characters ('<\">`)";
  }

  return "";
}
