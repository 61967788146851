import clsx from "clsx";
import moment from "moment";
import React, { FC } from "react";

import { SupplierSession } from "@adl-gen/hotel/db";
import { COMMON_DATETIME_FORMAT } from "@constants/common";

import styles from "./styles.css";

const SupplierSessionRow: FC<SupplierSession> = ({ sessionId, timestamp }) => {
  return (
    <div
      className={clsx(styles.row, styles.supplierSession, styles.desktop)}
      key={sessionId}
    >
      <p className={styles.text}>
        {moment(timestamp).format(COMMON_DATETIME_FORMAT)}
      </p>

      <p className={styles.text}>Gimmonix</p>

      <p className={styles.text}>{sessionId}</p>
    </div>
  );
};

export default SupplierSessionRow;
