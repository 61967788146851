import clsx from "clsx";
import React, { FC, ImgHTMLAttributes, useState } from "react";

import { PictureIcon } from "@assets/svg-components";

import styles from "./styles.css";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  /** If true, inverts backdrop icon color to black */
  invertColor?: boolean;
  /** Size of a backdrop icon, default = medium */
  size?: "medium" | "large";
}

const Image: FC<ImageProps> = ({ invertColor, size = "medium", ...props }) => {
  const [isError, setIsError] = useState(false);
  return isError ? (
    <div
      className={clsx(
        styles.image,
        styles[size],
        invertColor && styles.invertedColor
      )}
    >
      <PictureIcon />
    </div>
  ) : (
    <img {...props} onError={() => setIsError(true)} />
  );
};

export default Image;
