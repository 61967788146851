import { Font, StyleSheet } from "@react-pdf/renderer";

import TTNormsBold from "@assets/fonts/TTNorms-Bold.ttf";
import RalewayBold from "@assets/pdf-fonts/Raleway-Bold.ttf";
import RalewayExtraBold from "@assets/pdf-fonts/Raleway-ExtraBold.ttf";
import RalewayItalic from "@assets/pdf-fonts/Raleway-Italic.ttf";
import RalewayMedium from "@assets/pdf-fonts/Raleway-Medium.ttf";
import RalewayRegular from "@assets/pdf-fonts/Raleway-Regular.ttf";

Font.register({
  family: "Raleway",
  fonts: [
    {
      src: RalewayRegular,
      fontWeight: 400,
    },
    {
      src: RalewayItalic,
      fontWeight: 400,
      fontStyle: "italic",
    },
    {
      src: RalewayMedium,
      fontWeight: 500,
    },
    {
      src: RalewayBold,
      fontWeight: 700,
    },
    {
      src: RalewayExtraBold,
      fontWeight: 800,
    },
  ],
});

Font.register({
  family: "TT Norms",
  fonts: [
    {
      src: TTNormsBold,
      fontWeight: 700,
    },
  ],
});

export default StyleSheet.create({
  page: {
    padding: "100px 50px 50px",
    fontFamily: "Raleway",
  },
  head: {
    paddingBottom: 18,
    borderBottom: "2px solid #0556FE",
  },
  title: {
    fontSize: 23,
    fontWeight: 800,
  },
  subtitle: {
    fontSize: 19,
    fontWeight: 500,
  },
  content: {
    padding: "24px 0 45px",
    flexShrink: 0,
    flexGrow: 1,
  },
  leadingCard: {
    flexDirection: "column",
    backgroundColor: "#E6E6EB",
    borderRadius: 6,
    padding: "9px 12px",
    fontSize: 9,
    marginBottom: 24,
    width: 285,
  },
  label: {
    fontWeight: 700,
  },
  mainRow: {
    flexDirection: "row",
    height: 190,
    marginBottom: 22,
  },
  detailsCards: {
    marginRight: 20,
    flexDirection: "column",
    flexGrow: 1,
  },
  detailsCard: {
    borderRadius: 6,
    border: "1px solid #E6E6EB",
    flexDirection: "row",
    marginBottom: 11,
    fontSize: 6,
    minHeight: 18,
  },
  detailsCardTitle: {
    color: "#0556FE",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  detailsCardImage: {
    flexDirection: "row",
    justifyContent: "center",
    width: 38,
    borderRight: "1px solid #E6E6EB",
    padding: "8px 12px",
  },
  detailsCardContent: {
    padding: "8px 10px 7px",
    lineHeight: 7,
  },
  detailsCardHint: {
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: 5,
  },
  mapImage: {
    borderRadius: 6,
    width: 190,
    height: 190,
  },
  staticCard: {
    borderRadius: 6,
    border: "1px solid #E6E6EB",
    flexDirection: "row",
    padding: "9px 12px",
    marginBottom: 12,
    fontSize: 6,
  },
  staticCardTitle: {
    fontSize: 8,
    fontWeight: 700,
    marginBottom: 7,
  },
  importantInfoRow: {
    fontWeight: 400,
    flexDirection: "row",
    marginBottom: 4,
  },
  importantInfoText: {
    flexDirection: "column",
  },
  staticCardIcon: {
    width: 11,
    height: 11,
    marginRight: 12,
  },
  bulletPoint: {
    height: 4,
    width: 4,
    backgroundColor: "#8A9AA7",
    borderRadius: "50%",
    marginRight: 6,
    marginTop: 1,
  },
  cancellationPolicy: {
    lineHeight: "1.5px",
  },
  tips: {
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  tipsTitle: {
    fontSize: 23,
    fontWeight: 700,
    textAlign: "center",
  },
  tipsSubtitle: {
    fontSize: 6,
    fontWeight: 400,
    textAlign: "center",
  },
  stepsContainer: {
    flexGrow: 1,
    flexDirection: "column",
  },
  step: {
    borderRadius: 7,
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    border: "1px solid #0FA6ED",
    marginBottom: 28,
    paddingLeft: 52,
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 13,
    width: 210,
    height: 57,
  },
  stepNumberContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 2,
    backgroundColor: "#0FA6ED",
    position: "absolute",
    top: 10,
    left: -6,
    width: 44,
    height: 15,
  },
  stepNumberText: {
    color: "#FFFFFF",
    fontSize: 7,
    fontWeight: 700,
    fontFamily: "TT Norms",
  },
  stepText: {
    fontSize: 7,
    flexGrow: 1,
    fontWeight: 500,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 12,
    borderTop: "0.5px solid #0556FE",
    fontSize: 6,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logo: {
    width: 51,
    marginLeft: 4,
  },
  pageNumber: {
    position: "absolute",
    bottom: -30,
    right: 0,
    fontSize: 6,
    flexDirection: "row",
  },
});
