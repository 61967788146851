import React from "react";
import { Icons } from "@widgets/icon/icons";
import { IconButton, IconButtonProps } from "./icon-button";

import styles from "./new-booking-button.css";

interface NewBookingButtonProps {
  onClick: IconButtonProps["onClick"];
}

export const NewBookingButton = (props: NewBookingButtonProps) => {
  return (
    <IconButton
      className={styles.newBookingButton}
      onClick={props.onClick}
      iconBefore={Icons.plusCircleFilled}
      size="small"
    >
      New Booking
    </IconButton>
  );
};
