import moment from "moment";

import {
  AgentBookingData,
  BackOfficeUserBookingData,
} from "@adl-gen/hotel/api";
import { COMMON_DATETIME_FORMAT } from "@constants/common";
import { PaymentTotal } from "@widgets/itinerary/types";

export const extractBookingPriceData = (
  booking: AgentBookingData | BackOfficeUserBookingData
): PaymentTotal => {
  const net = Math.ceil(parseFloat(booking.bookingPrice.netPriceWithTax.value));
  const gross = Math.ceil(
    parseFloat(booking.bookingPrice.grossPriceWithTax.value)
  );

  return {
    bookingId: booking.bookingData.bookingId,
    dateTime: moment(booking.bookingData.created).format(
      COMMON_DATETIME_FORMAT
    ),
    net,
    gross,
    commission: gross - net,
    currency: booking.bookingPrice.netPriceWithTax.currency,
    // NOTE(Barry): As per previous conversations with Kosta I think we're going to get rid of the description field
    description: "",
    liveStatus: booking.bookingData.liveStatus,
    clientPaymentStatus: booking.bookingData.clientPaymentStatus,
  };
};
