import { COMMON_DATETIME_FORMAT } from "@constants/common";
import clsx from "clsx";
import moment, { Moment } from "moment";
import React, { FC, useContext, useMemo } from "react";

import { DbKey } from "@adl-gen/common/db";
import {
  AgentBookingData,
  BackOfficeUserBookingData,
} from "@adl-gen/hotel/api";
import {
  Board,
  ClientPaymentStatus,
  RoomOccupancyDetails,
} from "@adl-gen/hotel/booking";
import { PackageCancellationPolicy } from "@adl-gen/hotel/common";
import { AppUserType, Booking, SupplierSession } from "@adl-gen/hotel/db";
import { LoggedInContext } from "@app/app";
import { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { assertValueLoaded, checkUserAccessLevel } from "@util/agentus-utis";
import SupplierSessionRow from "@widgets/itinerary/booking/booking-details/supplier-session";

import itineraryStyles from "../../itinerary.css";
import { BookingSearchResultContext } from "../index";
import Cancellation from "./cancellation/cancellation";
import RoomRow from "./room-row";
import styles from "./styles.css";

export interface BookingDetailsData {
  agentName: string;
  agentEmail: string;
  agentRef: string;
  guests: RoomOccupancyDetails[];
  room: string;
  board: Board;
  inclusions: string[];
  information: string;
  cancellationPolicy: PackageCancellationPolicy;
  checkIn: Moment;
  created: Moment;
  paymentDueDate: Moment;
  clientPaymentStatus: ClientPaymentStatus;
  supplierSessions: SupplierSession[];
}

/** Component Props */
export interface BookingDetailsProps extends BaseProps {
  classes?: { bookingDetails?: string };
  bookingId: DbKey<Booking>;
}

export const BookingDetails: FC<BookingDetailsProps> = ({
  bookingId,
  classes,
  className,
}) => {
  const searchResultState = useContext(BookingSearchResultContext);
  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  const extractFromAgentData = (data: AgentBookingData): BookingDetailsData => {
    return {
      agentEmail: data.bookingData.userEmail,
      agentName: data.bookingData.userName,
      agentRef: data.bookingData.agentReference || "",
      board: data.bookingData.board,
      guests: data.bookingData.rooms,
      inclusions: data.bookingData.hotelDetails.roomAmenities,
      information: "",
      room: data.bookingData.roomName,
      cancellationPolicy: data.bookingData.cancellationPolicy,
      checkIn: moment(data.bookingData.checkInDate),
      created: moment(data.bookingData.created),
      paymentDueDate: moment(data.bookingData.paymentDueDate),
      clientPaymentStatus: data.bookingData.clientPaymentStatus,
      supplierSessions: [],
    };
  };

  const extractFromBackOfficeData = (
    data: BackOfficeUserBookingData
  ): BookingDetailsData => {
    return {
      ...extractFromAgentData((data as unknown) as AgentBookingData),
      supplierSessions: data.supplierData.sessionIds,
    };
  };

  const bookingData = useMemo(() => {
    const queryResp = assertValueLoaded(searchResultState.searchResult);
    if (queryResp.kind === "agentBookingData") {
      const selectedBooking = assertNotUndefined(
        queryResp.value.items.find(
          (data) => data.bookingData.bookingId === bookingId
        )
      );
      return extractFromAgentData(selectedBooking);
    } else if (queryResp.kind === "backOfficeUserBookingData") {
      const selectedBooking = assertNotUndefined(
        queryResp.value.items.find(
          (data) => data.bookingData.bookingId === bookingId
        )
      );
      return extractFromBackOfficeData(selectedBooking);
    } else {
      assertNever(queryResp);
    }
  }, [searchResultState.searchResult, bookingId]);

  return (
    <div
      className={clsx(
        styles.bookingDetails,
        classes?.bookingDetails,
        className
      )}
    >
      <section>
        <h3 className={itineraryStyles.title}>Agent Details</h3>

        <div className={styles.row}>
          <Vertical>
            <div className={clsx(itineraryStyles.label, styles.label)}>
              AGENT NAME
            </div>
            <div className={styles.text}>{bookingData.agentName}</div>
          </Vertical>

          <Vertical>
            <div className={clsx(itineraryStyles.label, styles.label)}>
              AGENT EMAIL
            </div>
            <div className={styles.text}>{bookingData.agentEmail}</div>
          </Vertical>

          <Vertical>
            <div className={clsx(itineraryStyles.label, styles.label)}>
              AGENT REF
            </div>
            <div className={styles.text}>{bookingData.agentRef}</div>
          </Vertical>

          <div />
        </div>
      </section>

      <section className={styles.roomDetails}>
        <h3 className={itineraryStyles.title}>Room Details</h3>

        <div className={styles.row}>
          <div className={clsx(itineraryStyles.label, styles.label)}>
            GUESTS
          </div>
          <div className={clsx(itineraryStyles.label, styles.label)}>
            ROOM DETAILS
          </div>
          <div className={clsx(itineraryStyles.label, styles.label)}>BOARD</div>
          <div className={clsx(itineraryStyles.label, styles.label)}>
            AMENITIES
          </div>
        </div>

        {bookingData.guests.map((room, index) => (
          <RoomRow
            key={`room-${index}`}
            room={room}
            bookingData={bookingData}
          />
        ))}
      </section>

      {checkUserAccessLevel(userProfile, AppUserType.backOffice) ? (
        <section>
          <h3 className={itineraryStyles.title}>Supplier Details</h3>

          {bookingData.supplierSessions.length > 0 ? (
            <>
              <div className={clsx(styles.row, styles.desktop)}>
                <div className={clsx(itineraryStyles.label, styles.label)}>
                  DATE & TIME
                </div>

                <div className={clsx(itineraryStyles.label, styles.label)}>
                  SUPPLIER
                </div>

                <div className={clsx(itineraryStyles.label, styles.label)}>
                  SESSION ID
                </div>
              </div>

              {bookingData.supplierSessions.map((item) => (
                <SupplierSessionRow {...item} />
              ))}

              {bookingData.supplierSessions.map((item) => (
                <div
                  className={clsx(styles.row, styles.mobile)}
                  key={item.sessionId}
                >
                  <Vertical>
                    <span className={clsx(itineraryStyles.label, styles.label)}>
                      DATE & TIME
                    </span>
                    <p className={styles.text}>
                      {moment(item.timestamp).format(COMMON_DATETIME_FORMAT)}
                    </p>
                  </Vertical>

                  <Vertical>
                    <span className={clsx(itineraryStyles.label, styles.label)}>
                      SUPPLIER
                    </span>
                    <p className={styles.text}>Gimmonix</p>
                  </Vertical>

                  <Vertical>
                    <span className={clsx(itineraryStyles.label, styles.label)}>
                      SESSION ID
                    </span>
                    <p className={styles.text}>{item.sessionId}</p>
                  </Vertical>
                </div>
              ))}
            </>
          ) : (
            <p>No data</p>
          )}
        </section>
      ) : null}

      <Cancellation
        policy={bookingData.cancellationPolicy}
        checkIn={bookingData.checkIn}
        bookingDate={bookingData.created}
        clientPaymentStatus={bookingData.clientPaymentStatus}
      />

      <section className={styles.importantInformation}>
        <h3 className={itineraryStyles.title}>Important Information</h3>
        <p>
          Most hotels require a minimum age of 18 years old to check into a room
          (unless accompanied by an adult) and in some cases such as casinos the
          age may be 21 years old. Please check with the particular hotel for
          individual requirements.
        </p>
        <p>
          Kindly note that while your booking has been confirmed, the rooming
          list with your name may not be updated in the hotel's reservation
          system until closer to the arrival date.
        </p>
        <p>
          Resort, amenities and facilities fees and city taxes may not be
          included in the confirmed rate. Unless stated otherwise in writing,
          resort, amenities and facilities fees and excluded city taxes are
          payable direct to the hotel in the local currency.
        </p>
        <p>
          The booking does not include room incidentals (for example, mini bar
          use, telephone calls, room service, pay-to-view movies and similar
          services in the hotel). All extra expenses at the hotel or for similar
          services are accepted as personal expenses and must be paid directly
          to the hotel or supplier.
        </p>
        <p>
          This is a prepaid booking. The reservation has been made by Agentus in
          partnership with the booking office mentioned above. Under no
          circumstances must the guest(s) be charged for the service listed on
          this voucher. The guest(s) is to be charged only for their
          incidentals.
        </p>
        <p>
          Child policies vary for each hotel. Please note children under 18
          years may share existing bedding.
        </p>
      </section>
    </div>
  );
};

const Vertical = ({ children }) => {
  return <div className={layout.vertical}>{children}</div>;
};
