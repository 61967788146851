import {
  AgentBookingData,
  BackOfficeUserBookingData,
  BookingSearchQueryResp,
} from "@adl-gen/hotel/api";
import { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { Loading } from "@models/loading";
import { trackAndLogError } from "@src/util/error-handling";
import { assertValueLoaded, getEnumValueKeyByIndex } from "@util/agentus-utis";
import { BadgeColor, BadgeLabel } from "@widgets/badge/badge";
import { SelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";

export function getBookingFromStoreById<Agent, Backoffice>(
  bookingId: string,
  bookingSearchResult: Loading<BookingSearchQueryResp>,
  agentMapFunction: (booking: AgentBookingData) => Agent,
  backofficeMapFunction: (booking: BackOfficeUserBookingData) => Backoffice
): [Agent, "agent"] | [Backoffice, "backoffice"] {
  const queryResp = assertValueLoaded(bookingSearchResult);
  if (queryResp.kind === "agentBookingData") {
    const selectedBooking = assertNotUndefined(
      queryResp.value.items.find(
        (bookingData) => bookingData.bookingData.bookingId === bookingId
      )
    );

    return [agentMapFunction(selectedBooking), "agent"];
  } else if (queryResp.kind === "backOfficeUserBookingData") {
    const selectedBooking = assertNotUndefined(
      queryResp.value.items.find(
        (bookingData) => bookingData.bookingData.bookingId === bookingId
      )
    );

    return [backofficeMapFunction(selectedBooking), "backoffice"];
  } else {
    assertNever(queryResp);
  }
}

/**
 * Translation of the given booking parameters to a booking type label. Logic taken from
 * https://xllabs.atlassian.net/wiki/spaces/AT/pages/41418976/Live+Status+Payment+Status+Item+Attributes+and+Line+Items#Payment-Status
 */
export function getBookingLabel(
  liveStatus: LiveStatus,
  clientPaymentStatus: ClientPaymentStatus,
  instantPurchase: boolean,
  bookingNumberForDebugging: string
): BadgeLabel {
  switch (liveStatus) {
    case LiveStatus.draft:
      return { label: "DRAFT", color: BadgeColor.Grey };
    case LiveStatus.quote:
      if (instantPurchase) {
        return { label: "INSTANT PURCHASE", color: BadgeColor.Purple };
      } else {
        return { label: "BOOK & HOLD", color: BadgeColor.Purple };
      }
    case LiveStatus.unavailable:
      return { label: "UNAVAILABLE", color: BadgeColor.Black };
    case LiveStatus.cancelled:
      return { label: "CANCELLED", color: BadgeColor.Red };
    case LiveStatus.pendingConfirmation:
      return { label: "CONF. PENDING", color: BadgeColor.Yellow };
    case LiveStatus.travelled: {
      switch (clientPaymentStatus) {
        case ClientPaymentStatus.paid:
          return { label: "PAID", color: BadgeColor.LightBlue };
        case ClientPaymentStatus.commissionDue:
          return { label: "COMMISSION DUE", color: BadgeColor.LightBlue };
        default:
          return { label: "TRAVELLED", color: BadgeColor.Green };
      }
    }
    case LiveStatus.confirmed: {
      switch (clientPaymentStatus) {
        case ClientPaymentStatus.unpaid:
          return { label: "BOOK & HOLD", color: BadgeColor.Purple };
        case ClientPaymentStatus.underPaid:
          return { label: "UNPAID", color: BadgeColor.Yellow };
        case ClientPaymentStatus.cancelled:
          return { label: "CANCELLED", color: BadgeColor.Red };
        case ClientPaymentStatus.paid:
        case ClientPaymentStatus.paidViaCredit:
          return { label: "PAID", color: BadgeColor.LightBlue };
        case ClientPaymentStatus.commissionDue:
          return { label: "COMMISSION DUE", color: BadgeColor.LightBlue };
        case ClientPaymentStatus.paymentSubmitted:
          return { label: "PAYMENT SUBMITTED", color: BadgeColor.Green };
        default: {
          const additionalInfo = {
            liveStatus: getEnumValueKeyByIndex(liveStatus, LiveStatus),
            clientPaymentStatus: getEnumValueKeyByIndex(clientPaymentStatus, ClientPaymentStatus),
            bookingNumber: bookingNumberForDebugging
          }
          trackAndLogError("An error occurred while calculating the booking label mapping due to unknown conditions", additionalInfo)
          return { label: "CONFIRMED", color: BadgeColor.Green };
        }
      }
    }
  }
}

export function isBookingCancellable(
  booking: SelectedBookingInfo | AgentBookingData | BackOfficeUserBookingData
) {
  if ("bookingData" in booking) {
    return (
      booking.bookingData.liveStatus !== LiveStatus.cancelled &&
      booking.bookingData.liveStatus !== LiveStatus.travelled
    );
  }
  return (
    booking.liveStatus !== LiveStatus.cancelled &&
    booking.liveStatus !== LiveStatus.travelled
  );
}
