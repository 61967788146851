import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { GeneralContext } from "@app/app";
import { ROUTE_PATH } from "@constants/common";
import { BookingService } from "@controllers/booking/booking-controller";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { sendErrorToRollbar } from "@util/error-handling";
import { AgentusLoader } from "@widgets/agentus-loader/agentus-loader";

interface Props {
  service: Pick<BookingService, "queryPaymentClientConfig">;
}

export const StripeLayout: FC<Props> = ({ children, service }) => {
  const [
    stripePromise,
    setStripePromise,
  ] = useState<Promise<Stripe | null> | null>(null);

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    fetchPublicKey();
  }, []);

  async function fetchPublicKey() {
    try {
      const { kind, value } = await service.queryPaymentClientConfig();

      if (kind === "config") {
        setStripePromise(loadStripe(value.clientKey));
      } else {
        handleLoadingError(
          new Error(`Can't load payment config, response: kind: ${kind}`)
        );
      }
    } catch (e) {
      handleLoadingError(e);
    }
  }

  function handleLoadingError(e) {
    addNotification({
      text:
        "The payment gateway is not available at the moment, please contact Agentus support",
      type: NotificationTypeEnum.Error,
    });

    location.pathname = ROUTE_PATH.Search;
    location.search = "";

    history.replace(location);
    sendErrorToRollbar(e, {
      additionalInfo: "Error while loading stripe layout payment config",
    });
  }

  if (!stripePromise) {
    return <AgentusLoader />;
  }

  return <Elements stripe={stripePromise}>{children}</Elements>;
};
