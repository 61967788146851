import { FieldFns } from "@hx/fields";
import * as React from "react";
import onClickOutside, { OnClickOutProps } from "react-onclickoutside";
import {
  Button,
  Form,
  Icon,
  Input,
  Label,
  Message,
  SemanticICONS,
  Table,
  TextArea
} from "semantic-ui-react";

import { getFormLabelFromAnnotation, getTableViewFromAnnotation } from "./adl-annotations";
import { enumField, maybeField, nullableField, primitiveFieldFns } from "./adl-field";
import { Paginated } from "./adl-gen/common";
import {
  Expr,
  FieldPredicate,
  makeExprLike,
  makeTableView,
  SortDirection,
  SortField,
  TableView
} from "./adl-gen/common/tabular";
import { ATypeExpr, DeclResolver, ScopedDecl } from "./adl-gen/runtime/adl";
import { isEnum } from "./adl-gen/runtime/utils";
import * as adlast from "./adl-gen/sys/adlast";
import styles from "./adl-table.css";
import { uniqueId } from "./adl-tools-helpers";
import * as adltree from "./adl-tree";
import { CustomContext, fieldLabel } from "./adl-veditor";

// This file contains various helper functions for dealing
// with adl table data.

// API cleanup TODOS
// - get rid of CI param
// - merge headerCell & tableCell in factory interface, & get rid of onClick

export interface CellPopup<CI> {
  loc: CellLoc<CI>;
  render(): JSX.Element;
}

type CustomFieldFn = (ctx: CustomContext) => FieldFns<unknown> | null;

export type TableSection = "header" | "body";

/** CI - type representing the column index, e.g. if you want string vs int */
export interface CellLoc<CI> {
  section: TableSection;

  /** Starts at zero */
  rowi: number;

  coli: CI;
}

export type CellContent = null | {
  value: JSX.Element | string; // TODO(dan/timd) consider removing the 'string' option
  style: { [key: string]: string } | null;
};

export function cellContent(value: string | JSX.Element): CellContent {
  return { value, style: null };
}

export interface Column<T, CI> {
  id: CI;
  header: CellContent;
  content(item: T, i: number): CellContent;
}

export interface TableFactory {
  table(headerCells: JSX.Element[], rows: JSX.Element[]): JSX.Element;
  tableRow(i: number, cells: JSX.Element[], style: string): JSX.Element;

  headerCell(i: number, content: CellContent, popupContent: JSX.Element | null): JSX.Element;
  tableCell(
    i: number,
    onClick: () => void,
    content: CellContent,
    popupContent: JSX.Element | null
  ): JSX.Element;
}

export const SEMANTIC_THEME: TableFactory = {
  table: (headerCells: JSX.Element[], rows: JSX.Element[]) => (
    <Table celled>
      <Table.Header>
        <Table.Row>{headerCells}</Table.Row>
      </Table.Header>
      <Table.Body>{rows}</Table.Body>
    </Table>
  ),
  tableRow: (i: number, cells: JSX.Element[], className: string) => (
    <Table.Row key={i} className={className}>
      {cells}
    </Table.Row>
  ),
  headerCell: (i: number, content: CellContent, popupContent: JSX.Element | null) => {
    const value = content === null ? null : content.value;
    const style = content === null ? null : content.style;
    return (
      <Table.HeaderCell key={i} style={style}>
        <div>{value}</div>
        {popupContent}
      </Table.HeaderCell>
    );
  },
  tableCell: (
    i: number,
    onClick: () => void,
    content: CellContent,
    popupContent: JSX.Element | null
  ) => {
    const value = content === null ? null : content.value;
    const style = content === null ? null : content.style;
    return (
      <Table.Cell key={i} onClick={onClick} style={style}>
        <div>{value}</div>
        {popupContent}
      </Table.Cell>
    );
  }
};

/**
 * Creates a theme that support fixed header columns, with the remaining columns scrolling.
 */
export function makeHscrollTheme(fixedColumnPxWidths: number[]): TableFactory {
  const numFixedColumns = fixedColumnPxWidths.length;
  const totalFixedPxWidth = fixedColumnPxWidths.reduce((a, b) => a + b, 0);

  function cellStyle(col: number): {} {
    const shared = {};
    if (col < numFixedColumns) {
      const isLast = col === numFixedColumns - 1;
      let left = 0;
      for (let i = 0; i < col; i++) {
        left += fixedColumnPxWidths[i];
      }
      return {
        ...shared,

        position: "absolute",
        left: left + "px",
        top: "auto",

        // isLast?1:0 to cover up left border of first scrollable column
        width: fixedColumnPxWidths[col] + (isLast ? 1 : 0) + "px",

        // colour copy pasted from chrome dev tools for other borders. should probably be factored better.
        borderRight: isLast ? "4px solid rgba(34, 36, 38, 0.1)" : ""

        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
      };
    } else {
      return {
        ...shared,

        // We can't put this in the base css class, because aphrodite thinks it's a good idea to put
        // !important on everything, and we can't override it above (we can't even put !important on the
        // style, react won't accept it, lawl.)
        position: "relative"
      };
    }
  }

  /**
   * Renders the cell content s.t. it doesn't spill out of fixed width cells,
   * while still supporting popup content that doesn't get clipped.
   */
  function renderCell(
    section: TableSection,
    i: number,
    content: CellContent,
    popupContent: JSX.Element | null,
    onClick?: () => void
  ) {
    const value = content === null || content.value === "" ? <br /> : content.value;
    const contentStyle: React.CSSProperties =
      content === null || content.style === null ? {} : content.style;
    // tslint:disable-next-line: no-inferred-empty-object-type
    const containerStyle: React.CSSProperties = cellStyle(i);

    const popupContainer = popupContent && (
      <div className={styles.cellPopupContainer}>{popupContent}</div>
    );

    const width = containerStyle.width;

    // Must hide overflow if width is fixed and content doesn't specify it.
    //
    if (width && !contentStyle.overflow) {
      contentStyle.overflow = "hidden";
    }

    const Cell = section === "header" ? Table.HeaderCell : Table.Cell;

    return (
      <Cell key={i} onClick={onClick} className={styles.cell} style={containerStyle}>
        <div style={contentStyle} className={styles.cellContent}>
          {value}
        </div>

        {popupContainer}
      </Cell>
    );
  }

  return {
    table: (headerCells: JSX.Element[], rows: JSX.Element[]) => (
      <div
        // Use table style to get nice borders etc.
        // Doesn't seem to matter that this is being done redundantly
        // on wrapper, and that this isn't a table element.
        className="ui celled table"
        style={{
          position: "relative",
          width: "100%",
          overflowY: "scroll"
        }}
      >
        <div
          style={{
            marginLeft: totalFixedPxWidth + "px",
            overflowX: "scroll",
            minHeight: "300px" //HACK (prunge) ensure filter dialog is not cropped when the table is empty
          }}
        >
          <Table
            celled
            style={{
              // Reset some styles that belong on the outer div
              border: "none",
              borderRadius: 0,
              width: "auto",
              minWidth: "1000px"
            }}
          >
            <Table.Header>
              <Table.Row>{headerCells}</Table.Row>
            </Table.Header>
            <Table.Body>{rows}</Table.Body>
          </Table>
        </div>
      </div>
    ),
    tableRow: (i: number, cells: JSX.Element[], className: string) => (
      <Table.Row key={i} className={className}>
        {cells}
      </Table.Row>
    ),
    headerCell: (i: number, content: CellContent, popupContent: JSX.Element | null) => {
      return renderCell("header", i, content, popupContent);
    },
    tableCell: (
      i: number,
      onClick: () => void,
      content: CellContent,
      popupContent: JSX.Element | null
    ) => {
      return renderCell("body", i, content, popupContent, onClick);
    }
  };
}

export interface AdlColumn<T> {
  fieldname: string;
  label: string;
  defaultVisible: boolean;
  column: Column<T, string>;
  fieldfns: FieldFns<T>; // TODO(timd) generalize this to an arbitrary editor
  adlTree: adltree.AdlTree;
}

export interface AdlTableInfo<T> {
  columns: AdlColumn<T>[];
  columnsByFieldName: { [key: string]: AdlColumn<T> };
  defaultView: TableView;
}

// Type to capture a value along with it's corresponding id.
export interface WithId<I, T> {
  id: I;
  value: T;
}

// Derive column information from an adl structure
export function getAdlTableInfo<T>(
  declResolver: DeclResolver,
  typeExpr: ATypeExpr<T>,
  customFields?: CustomFieldFn
): AdlTableInfo<T> {
  const adlStruct = adltree.createAdlTree(typeExpr.value, declResolver).details();

  if (adlStruct.kind !== "struct") {
    throw new Error("AdlTable only implemented for struct types");
  }

  const scopedDecl = {
    moduleName: adlStruct.moduleName,
    decl: adlStruct.astDecl
  };

  const columns: AdlColumn<T>[] = [];

  const view = getTableViewFromAnnotation(declResolver, adlStruct.astDecl);

  adlStruct.fields.forEach(f => {
    const fieldfns = getFieldFns(declResolver, scopedDecl, f.astField, f.adlTree, customFields);
    if (fieldfns !== null) {
      const fieldfnsT: FieldFns<T> = fieldfns as FieldFns<T>;
      const defaultVisible = view ? view.columns.indexOf(f.astField.name) !== -1 : true;
      const label =
        getFormLabelFromAnnotation(declResolver, f.astField) || fieldLabel(f.astField.name);

      // A column containing multi line strings
      const content = (item: T): CellContent => {
        const text = fieldfnsT.toText(item[f.astField.name]);
        if (fieldfnsT.rows > 1) {
          // show 40 characters of the first line
          const s = text;
          let line0 = s.split("\n")[0].substr(0, 40);
          if (line0.length < s.length) {
            line0 = line0 + "...";
          }
          return cellContent(line0);
        } else {
          return cellContent(text);
        }
      };

      columns.push({
        fieldname: f.astField.name,
        adlTree: f.adlTree,
        label,
        defaultVisible,
        fieldfns: fieldfnsT,
        column: {
          id: f.astField.name,
          header: cellContent(label),
          content
        }
      });
    }
  });

  const defaultView =
    view ||
    makeTableView({
      columns: columns.map(c => c.fieldname)
    });

  const columnsByFieldName = {};
  columns.forEach(c => {
    columnsByFieldName[c.fieldname] = c;
  });

  return { columns, columnsByFieldName, defaultView };
}

export function getFieldFns(
  declResolver: DeclResolver,
  scopedDecl: ScopedDecl | null,
  field: adlast.Field | null,
  t: adltree.AdlTree,
  customFields?: CustomFieldFn
): FieldFns<unknown> | null {
  if (customFields) {
    const typeExpr = t.typeExpr;
    const fieldfns = customFields({ declResolver, scopedDecl, field, typeExpr });
    if (fieldfns) {
      return fieldfns;
    }
  }
  const fdetails = t.details();
  if (fdetails.kind === "typedef") {
    return getFieldFns(declResolver, scopedDecl, field, fdetails.adlTree, customFields);
  } else if (fdetails.kind === "primitive") {
    return primitiveFieldFns(fdetails.ptype);
  } else if (fdetails.kind === "nullable") {
    const fieldfns = getFieldFns(declResolver, scopedDecl, field, fdetails.param, customFields);
    if (fieldfns === null) {
      return null;
    }
    return nullableField(fieldfns);
  } else if (
    fdetails.kind === "union" &&
    fdetails.moduleName === "sys.types" &&
    fdetails.astDecl.name === "Maybe"
  ) {
    const t2 = adltree.createAdlTree(t.typeExpr.parameters[0], declResolver);
    const fieldfns = getFieldFns(declResolver, scopedDecl, field, t2, customFields);
    if (fieldfns === null) {
      return null;
    }
    return maybeField(fieldfns);
  } else if (
    fdetails.kind === "union" &&
    fdetails.astDecl.type_.kind === "union_" &&
    isEnum(fdetails.astDecl.type_.value)
  ) {
    return enumField(fdetails.astDecl, fdetails.astDecl.type_.value);
  }
  return null;
}

export enum LoadState {
  LOAD_NEEDED,
  LOADING,
  IDLE
}

export interface PageButtonProps {
  tooltip: string;
  icon: string;
  disabled?: boolean;
  loading?: boolean;
  onClick(): void;
}

export class PageButton extends React.Component<PageButtonProps> {
  render() {
    return (
      <span data-tooltip={this.props.tooltip}>
        <Button
          icon={this.props.icon}
          disabled={this.props.disabled}
          loading={this.props.loading}
          onClick={this.props.onClick}
        />
      </span>
    );
  }
}

export function prevPageButton(
  page: Paginated<unknown>,
  loadState: LoadState,
  pageSize: number,
  loadPage: (offset: number) => void
) {
  const isloading = loadState !== LoadState.IDLE;
  const canPage = !isloading && page.current_offset > 0;
  function onPage() {
    const newOffset = pageSize > page.current_offset ? 0 : page.current_offset - pageSize;
    loadPage(newOffset);
  }
  return (
    <PageButton tooltip={"Previous page"} icon="left arrow" disabled={!canPage} onClick={onPage} />
  );
}

export function pageLocation(page: Paginated<unknown>) {
  const fromi = page.current_offset + 1;
  const toi = fromi + page.items.length - 1;
  const total = page.total_size;
  return (
    <span className={styles.pageLocation}>
      {fromi}-{toi}/{total}
    </span>
  );
}

export function nextPageButton(
  page: Paginated<unknown>,
  loadState: LoadState,
  pageSize: number,
  loadPage: (offset: number) => void
) {
  const isloading = loadState !== LoadState.IDLE;
  const canPage = !isloading && page.current_offset + pageSize < page.total_size;
  function onPage() {
    const newOffset = page.current_offset + pageSize;
    loadPage(newOffset);
  }
  return (
    <PageButton tooltip={"Next page"} icon="right arrow" disabled={!canPage} onClick={onPage} />
  );
}

export function refreshButton(
  page: Paginated<unknown>,
  loadState: LoadState,
  loadPage: (offset: number) => void
) {
  const isloading = loadState !== LoadState.IDLE;
  function onRefresh() {
    loadPage(page.current_offset);
  }
  return (
    <PageButton
      tooltip={"Refresh"}
      icon="refresh"
      disabled={isloading}
      loading={isloading}
      onClick={onRefresh}
    />
  );
}

export function linkifyButton(onClick: () => void) {
  return <PageButton tooltip={"Create Page Link"} icon="linkify" onClick={onClick} />;
}

export function columnConfigButton(onClick: () => void) {
  return <PageButton tooltip={"Configure Columns"} icon="columns" onClick={onClick} />;
}

export interface ColumnConfigPanelProps {
  filterValue: string;
  onSort(direction: SortDirection): void;
  onFilter(filterValue: string): void;
  onClose(): void;
  disableOnClickOutside(): void;
  enableOnClickOutside(): void;
  containerClassName?: string;
  panelClassName?: string;
}

export class ColumnConfigPanel extends React.Component<ColumnConfigPanelProps> {
  render() {
    return (
      <div
        className={
          this.props.containerClassName === undefined
            ? styles.columnPropsContainer
            : this.props.containerClassName
        }
      >
        <div
          className={
            this.props.panelClassName === undefined ? styles.columnProps : this.props.panelClassName
          }
        >
          <div>
            <Button
              basic
              icon="sort content ascending"
              onClick={this.onSort.bind(this, SortDirection.ascending)}
            />
            <Button
              basic
              icon="sort content descending"
              onClick={this.onSort.bind(this, SortDirection.descending)}
            />
          </div>
          <Input
            className={styles.columnFilterInput}
            autoFocus="true"
            placeholder="Filter..."
            value={this.props.filterValue}
            onChange={this.onFilter.bind(this)}
          />
        </div>
      </div>
    );
  }

  handleClickOutside() {
    this.props.onClose();
  }

  onSort(direction: SortDirection) {
    this.props.onSort(direction);
  }

  // tslint:disable-next-line: no-any
  onFilter(event: any) {
    // FIXME(timd): what should this type be ?
    this.props.onFilter(event.target.value);
  }
}

export const ColumnConfigPanelWithOutsideClick: React.ComponentClass<
  OnClickOutProps<ColumnConfigPanelProps>
> =
  // tslint:disable-next-line: no-any
  (onClickOutside(ColumnConfigPanel) as any) as React.ComponentClass<
    OnClickOutProps<ColumnConfigPanelProps>
  >;

export interface HeaderCellProps {
  label: string,
  sort: SortDirection | null;
  filter: string;
  showProps: boolean;
  onSort(sortDirection: SortDirection): void;
  onFilter(filter: string): void;
  onShowProps(show: boolean): void;
}

export class HeaderCell extends React.Component<HeaderCellProps> {
  render() {
    let sortIcon: JSX.Element | null = null;
    if (this.props.sort === SortDirection.ascending) {
      sortIcon = <Icon className={styles.activeColumnConfigIcon} name="sort content ascending" />;
    } else if (this.props.sort === SortDirection.descending) {
      sortIcon = <Icon className={styles.activeColumnConfigIcon} name="sort content descending" />;
    }
    const filterIcon = this.props.filter ? (
      <Icon className={styles.activeColumnConfigIcon} name="filter" />
    ) : null;

    let columnConfigPanel: JSX.Element | null = null;
    if (this.props.showProps) {
      columnConfigPanel = (
        <ColumnConfigPanelWithOutsideClick
          filterValue={this.props.filter}
          onSort={this.props.onSort}
          onFilter={this.props.onFilter}
          onClose={() => this.props.onShowProps(false)}
        />
      );
    }
    return (
      <div
        className={styles.columnHeaderContent}
        style={{ cursor: "pointer" }}
        onClick={() => this.props.onShowProps(true)}
      >
        {columnConfigPanel}
        <div className={styles.columnLabel}>{this.props.label}</div>
        <div className={styles.columnConfigIcons}>
          {sortIcon}
          {filterIcon}
        </div>
      </div>
    );
  }
}

export enum MoveDirection {
  Up,
  Down,
  Left,
  Right
}

export interface EditCellCallbacks<T> {
  onSave(value: T): Promise<void>;
  onMove(direction: MoveDirection): Promise<void>;
  onClose(): void;
}

interface EditCellProps<T> extends EditCellCallbacks<T> {
  value0: T | null;
  fieldfns: FieldFns<T>;
  disableOnClickOutside(): void;
  enableOnClickOutside(): void;
}

interface EditCellState {
  text: string;
}

class EditCell<T> extends React.Component<EditCellProps<T>, EditCellState> {
  // We need a per-field id in order to tie an (optional) datalist to the
  // input field.
  id: string;

  componentWillMount() {
    this.id = uniqueId("field_");
  }

  constructor(props: EditCellProps<T>) {
    super(props);
    this.state = {
      text: this.props.value0 === null ? "" : this.props.fieldfns.toText(this.props.value0)
    };
  }

  render() {
    const validationError = this.props.fieldfns.validate(this.state.text);
    const errlabel = validationError ? <Label color="red">{validationError}</Label> : null;

    if (this.props.fieldfns.rows > 1) {
      return this.renderTextArea(errlabel);
    } else {
      return this.renderField(errlabel);
    }
  }

  renderField(errlabel: JSX.Element | null) {
    const opts: { error?: boolean; list?: string } = {};
    if (errlabel) {
      opts.error = true;
    }
    let datalist: JSX.Element | null = null;
    if (this.props.fieldfns.datalist) {
      opts.list = this.id;
      datalist = (
        <datalist id={this.id}>
          {this.props.fieldfns.datalist.map((value, i) => (
            <option key={i} value={value} />
          ))}
        </datalist>
      );
    }
    return (
      <Form.Field className="table-input">
        <Input
          type="text"
          value={this.state.text}
          onChange={this.onFieldChange}
          onKeyDown={this.onFieldKeyDown}
          onKeyUp={this.onFieldKeyUp}
          onFocus={this.onFieldFocus}
          autoFocus={true}
          style={{ width: "100%" }}
          {...opts}
        />
        {datalist}
        {errlabel}
      </Form.Field>
    );
  }

  renderTextArea(errlabel: JSX.Element | null) {
    let rows = this.state.text.split("\n").length;
    if (rows < this.props.fieldfns.rows) {
      rows = this.props.fieldfns.rows;
    }

    return (
      <Form className="table-input">
        <TextArea
          value={this.state.text}
          rows={rows}
          onChange={this.onTextChange}
          onKeyDown={this.onTextKeyDown}
          onKeyUp={this.onTextKeyUp}
          autoFocus={true}
          style={{ width: this.props.fieldfns.width + "em" }}
        />
        {errlabel}
      </Form>
    );
  }

  // tslint:disable-next-line: no-any
  onFieldChange = (event: any) => {
    this.setState({ text: event.target.value });
  };

  // tslint:disable-next-line: no-any
  onFieldFocus = (event: any) => {
    event.target.select();
  };

  onFieldKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Tab" || event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  };

  onFieldKeyUp = async (event: KeyboardEvent): Promise<void> => {
    const shifted = event.shiftKey;
    if (event.key === "Escape") {
      this.props.onClose();
    } else if (event.key === "Tab") {
      await this.save();
      await this.props.onMove(shifted ? MoveDirection.Left : MoveDirection.Right);
    } else if (event.key === "Enter") {
      await this.save();
      await this.props.onMove(shifted ? MoveDirection.Up : MoveDirection.Down);
    } else if (event.key === "ArrowUp") {
      await this.save();
      await this.props.onMove(MoveDirection.Up);
    } else if (event.key === "ArrowDown") {
      await this.save();
      await this.props.onMove(MoveDirection.Down);
    }
  };

  // tslint:disable-next-line: no-any
  onTextChange = (event: any) => {
    this.setState({ text: event.target.value });
  };

  onTextKeyDown = (event: KeyboardEvent): void => {
    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
    }
  };

  onTextKeyUp = async (event: KeyboardEvent): Promise<void> => {
    const shifted = event.shiftKey;
    const control = event.ctrlKey;
    const alt = event.altKey;
    if (event.key === "Escape") {
      this.props.onClose();
    } else if (event.key === "Tab") {
      await this.save();
      await this.props.onMove(shifted ? MoveDirection.Left : MoveDirection.Right);
    } else if (event.key === "Enter") {
      if (control || alt) {
        // Manually insert a newline into the DOM element, and update the state accordingly
        // tslint:disable-next-line: no-any
        const textAreaElement = event.target as any; // Do we have types for this dom level stuff?
        const value: string = textAreaElement.value;
        const newvalue =
          value.substr(0, textAreaElement.selectionStart) +
          "\n" +
          value.substr(textAreaElement.selectionEnd);
        const newSelectionStart = textAreaElement.selectionStart + 1;
        this.setState({ text: newvalue }, () => {
          textAreaElement.selectionStart = newSelectionStart;
          textAreaElement.selectionEnd = newSelectionStart;
        });
      } else {
        // Move
        await this.save();
        await this.props.onMove(shifted ? MoveDirection.Up : MoveDirection.Down);
      }
    } else if (event.key === "ArrowUp" && control) {
      await this.save();
      await this.props.onMove(MoveDirection.Up);
    } else if (event.key === "ArrowDown" && control) {
      await this.save();
      await this.props.onMove(MoveDirection.Down);
    }
  };

  async save() {
    if (this.props.fieldfns.validate(this.state.text) === null) {
      const newValue = this.props.fieldfns.fromText(this.state.text);
      const needsSave =
        this.props.value0 === null
          ? this.state.text !== ""
          : !this.props.fieldfns.equals(this.props.value0, newValue);
      if (needsSave) {
        await this.props.onSave(newValue);
      }
    }
    return null;
  }

  setStateP(updates: {}): Promise<null> {
    return new Promise<null>(resolve => {
      this.setState(updates, resolve as () => void);
    });
  }

  async handleClickOutside(): Promise<void> {
    await this.save();
    this.props.onClose();
  }
}

export interface FilterViewProps {
  filter: FieldPredicate;
  onClearFilter?(): void;
}

export function renderCellEditor(
  value0: unknown | null,
  fieldname: string,
  fieldfns: FieldFns<unknown>,
  callbacks: EditCellCallbacks<unknown>
): JSX.Element {
  const fvalue0 = value0 ? value0[fieldname] : null;
  return React.createElement(
    // HACK(ray): Unsure why a type error appeared after upgrading to react@16.3.0
    // (could also be a red herring). In any case, typing as `any` to make it work.
    // tslint:disable-next-line: no-any
    EditCellWithOutsideClick as any,
    {
      value0: fvalue0,
      fieldfns,
      onSave: callbacks.onSave,
      onClose: callbacks.onClose,
      onMove: callbacks.onMove
    }
  );
}

export class FilterView extends React.Component<FilterViewProps> {
  render() {
    const fp = simplifyFieldPredicate(this.props.filter);
    if (fp.kind === "literal" && fp.value) {
      return null;
    } else {
      return (
        <Message onDismiss={this.props.onClearFilter}>
          filter: <i>{fieldPredicateToString(fp)}</i>
        </Message>
      );
    }
  }
}

interface IconButtonProps {
  name: SemanticICONS;
  onClick(): void;
}

export class IconButton extends React.Component<IconButtonProps> {
  render() {
    return (
      <Icon name={this.props.name} style={{ cursor: "pointer" }} onClick={this.props.onClick} />
    );
  }
}

function exprToString(expr: Expr): string {
  switch (expr.kind) {
    case "string":
    case "instant":
    case "date":
      return `'${expr.value}'`;
    case "int":
    case "bool":
      return `${expr.value}`;
    case "field":
      return expr.value;
    case "concat":
      return `concat(${expr.value.map(exprToString).join(", ")})`;
    case "currentDate":
      return "<current date>";
  }
}

function fieldPredicateToStringImpl(fp: FieldPredicate, prec: number): string {
  // prec is the operator precedence, and is use to decide when parentheses are required.
  let prec1: number = 4;
  switch (fp.kind) {
    case "or":
      prec1 = 1;
      break;
    case "and":
      prec1 = 2;
      break;
    case "not":
      prec1 = 3;
      break;
    default:
  }

  let expr = "?";

  switch (fp.kind) {
    case "equalTo":
      expr = `${exprToString(fp.value.expr1)} = ${exprToString(fp.value.expr2)}`
      break;
    case "greaterThan":
      expr = `${exprToString(fp.value.expr1)} > ${exprToString(fp.value.expr2)}`
      break;
    case "lessThan":
      expr = `${exprToString(fp.value.expr1)} < ${exprToString(fp.value.expr2)}`
      break;
    case "like":
      const op = fp.value.caseSensitive ? "like" : "ilike";
      expr = `${exprToString(fp.value.expr)} ${op} ${fp.value.pattern}`
      break;
    case "in":
      expr = `${exprToString(fp.value.expr)} in (${fp.value.exprs.map(exprToString).join(", ")})`;
      break;
    case "isnull":
      expr = `${exprToString(fp.value)} is null`;
      break;
    case "literal":
      expr = fp.value ? "true" : "false";
      break;
    case "not":
      expr = "not " + fieldPredicateToStringImpl(fp.value, prec1);
      break;
    case "and":
      expr = fp.value.map(v => fieldPredicateToStringImpl(v, prec1)).join(" and ");
      break;
    case "or":
      expr = fp.value.map(v => fieldPredicateToStringImpl(v, prec1)).join(" or ");
      break;
    default:
  }
  if (prec > prec1) {
    return "(" + expr + ")";
  }
  return expr;
}

export function fieldPredicateToString(fp: FieldPredicate): string {
  return fieldPredicateToStringImpl(fp, 0);
}

// Simplify a field predicate by eliminating redundant literal
// values.
export function simplifyFieldPredicate(fp: FieldPredicate): FieldPredicate {
  switch (fp.kind) {
    case "equalTo":
    case "greaterThan":
    case "lessThan":
    case "like":
    case "in":
    case "isnull":
    case "literal":
      return fp;
    case "not":
      const fp1 = simplifyFieldPredicate(fp.value);
      if (fp1.kind === "literal") {
        return { kind: "literal", value: !fp1.value };
      }
      return fp;
    case "and": {
      const clauses = fp.value
        .map(simplifyFieldPredicate)
        .filter(v => !(v.kind === "literal" && v.value));
      if (clauses.filter(v => v.kind === "literal" && v.value).length > 0) {
        return { kind: "literal", value: false };
      } else if (clauses.length === 0) {
        return { kind: "literal", value: true };
      } else {
        return { kind: "and", value: clauses };
      }
    }
    case "or": {
      const clauses = fp.value
        .map(simplifyFieldPredicate)
        .filter(v => !(v.kind === "literal" && v.value));
      if (clauses.filter(v => v.kind === "literal" && v.value).length > 0) {
        return { kind: "literal", value: true };
      } else if (clauses.length === 0) {
        return { kind: "literal", value: false };
      } else {
        return { kind: "or", value: clauses };
      }
    }
  }
}

/**
 * Translates, if possible, a filter predicate into a field choice filter whose UI is a set of checkboxes.
 * Returns the set of choices selected by the filter checkboxes, or an empty string if nothing was selected or
 * the predicate does not have a choice expression for the target field.
 */
export function getFieldChoiceFilter(filter: FieldPredicate, field: string): string[] {
  // Match an appropriate clause in a top level and expression
  if (filter.kind === "and") {
    for (const fp of filter.value) {
      if (fp.kind === "in" && fp.value.expr.kind === 'field' && fp.value.expr.value === field) {
        const result: string[] = [];
        for (const expr of fp.value.exprs) {
          if (expr.kind === 'string') {
            result.push(expr.value);
          } else {
            return [];
          }
        }
        return result;
      }
    }
  }
  return [];
}



/**
 * Augments an existing filter predicate with field choices made from a set of checkboxes in the UI.
 * If an existing choice filter exists it is replaced with the new selected values.  Existing predicate is augmented
 * by combining with the field choice filter through an AND.
 */
export function withFieldChoiceFilter(
  filter: FieldPredicate,
  field: string,
  values: string[]
): FieldPredicate {
  const inPredicate: FieldPredicate =
    values.length === 0
      ? { kind: "literal", value: true }
      : { kind: "in", value: {expr:{kind:"field", value:field}, exprs:values.map(value => ({kind:"string", value}))}}
      ;

  // By default assume that we add a wrapping and clause
  let newfilter: FieldPredicate = { kind: "and", value: [filter, inPredicate] };

  if (filter.kind === "and") {
    // unless we have an existing and predicate, where we replace the appropriate clause
    const newAnds = filter.value.filter(fp => !(fp.kind === "in" && fp.value.expr.kind === 'field' && fp.value.expr.value === field));
    newAnds.push(inPredicate);
    newfilter = { kind: "and", value: newAnds };
  } else if (filter.kind === "literal") {
    // unless we have a literal, in which case we replace it with and predicate
    newfilter = { kind: "and", value: [inPredicate] };
  }

  return simplifyFieldPredicate(newfilter);
}

export function getFieldFilter(filter: FieldPredicate, field: string): string {
  // Match an appropriate clause in a top level and expression
  if (filter.kind === "and") {
    for (const fp of filter.value) {
      if (fp.kind === "like" && fp.value.expr.kind === 'field' && fp.value.expr.value === field) {
        let pattern = fp.value.pattern;
        if (pattern.startsWith("%")) {
          pattern = pattern.substr(1);
        }
        if (pattern.endsWith("%")) {
          pattern = pattern.substr(0, pattern.length - 1);
        }
        return pattern;
      }
    }
  }
  return "";
}

export function withFieldFilter(
  filter: FieldPredicate,
  field: string,
  pattern: string
): FieldPredicate {
  const likefp: FieldPredicate =
    pattern === ""
      ? { kind: "literal", value: true }
      : {
          kind: "like",
          value: makeExprLike({
            expr: {kind:'field', value:field},
            pattern: "%" + pattern + "%",
          }),
        };

  // By default assume that we add a wrapping and clause
  let newfilter: FieldPredicate = { kind: "and", value: [filter, likefp] };

  if (filter.kind === "and") {
    // unless we have an existing and predicate, where we replace the appropriate clause
    const newands = filter.value.filter(
      fp =>
        !(fp.kind === "like" && fp.value.expr.kind === 'field' && fp.value.expr.value === field)
    );
    newands.push(likefp);
    newfilter = { kind: "and", value: newands };
  } else if (filter.kind === "literal") {
    // unless we have a literal, in which case we replace it with and predicate
    newfilter = { kind: "and", value: [likefp] };
  }

  return simplifyFieldPredicate(newfilter);
}

// View manipulation functions

export function getViewSort(view: TableView, field: string): SortDirection | null {
  const sortfield = view.sorting.find(sf => sf.field === field);
  if (!sortfield) {
    return null;
  }
  return sortfield.direction;
}

export function withViewSort(
  view: TableView,
  fieldname: string,
  direction: SortDirection
): TableView {
  const newview = { ...view };
  // Need to make a copy of the view def sort array to avoid changes being
  // made to the passed in props.
  const sortFields = Array.from(newview.sorting);
  // Do not allow any duplicate sort fields.
  const sortIndex = sortFields.findIndex(s => {
    return s.field === fieldname;
  });
  if (sortIndex !== -1) {
    const sortField: SortField = sortFields.splice(sortIndex, 1)[0];
    // Turn of sorting of the given field if the same sort direction is selected
    if (sortField.direction !== direction) {
      sortField.direction = direction;
      sortFields.push(sortField);
    }
  } else {
    sortFields.push({ field: fieldname, direction });
  }
  newview.sorting = sortFields;
  return newview;
}

type EditCellU = new () => EditCell<unknown>;
const EditCellU: EditCellU = EditCell as EditCellU;

export const EditCellWithOutsideClick = onClickOutside(EditCellU);
