import React, { ChangeEvent, FC, useContext, useEffect, useState } from "react";

import { AdultFullName, AdultTitle } from "@adl-gen/hotel/booking";
import { BookingContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import { FullName } from "@models/common";
import { getEnumKeys } from "@util/agentus-utis";
import { ERROR_TEXT, ValidationRule } from "@util/validation";
import Input from "@widgets/input";
import { InputSelect, InputSelectOption } from "@widgets/input-select";

import bookingStyles from "../booking-form.css";
import styles from "./styles.css";

interface GuestProps {
  guestIdx: number;
  label: string;
  isChild: boolean;
  fullName: FullName;
  onChange(guestIdx: number, fullName: FullName): void;
}

const Guest: FC<GuestProps> = ({
  onChange,
  fullName,
  label,
  guestIdx,
  isChild,
}) => {
  const bookingController = assertNotUndefined(useContext(BookingContext));
  const showBookingFormErrors: boolean =
    bookingController.completeBookingProps.kind === "value" &&
    bookingController.completeBookingProps.value.showBookingFormErrors;

  const [guest, setGuest] = useState<FullName>(fullName);

  const handleFieldChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newGuestData = {
      ...guest,
      [ev.currentTarget.name]: ev.currentTarget.value,
    };

    setGuest(newGuestData);
    onChange(guestIdx, newGuestData);
  };

  const handleGenderChange = (name: string, value: number) => {
    const newGuestData = {
      ...guest,
      [name]: value,
    };

    setGuest(newGuestData);
    onChange(guestIdx, newGuestData);
  };

  useEffect(() => {
    setGuest((guestValue) => ({ ...guestValue, ...fullName }));
  }, [fullName]);

  const titleList: string[] = getEnumKeys(AdultTitle);

  return (
    <div className={bookingStyles.row}>
      <div className={styles.guestTitle}>{label}</div>
      <div className={styles.guestFields}>
        {!isChild && (
          <InputSelect
            label="Title*"
            name="title"
            className={styles.genderInput}
            classes={{
              inputText: styles.genderInputText,
              displayValue: styles.genderInputDisplayValue,
            }}
            displayValue={AdultTitle[(guest as AdultFullName).title]}
            value={(guest as AdultFullName).title}
            onChange={(val: number) => handleGenderChange("title", val)}
          >
            {titleList.map((title, index) => (
              <InputSelectOption
                key={`${title}_${index}`}
                value={AdultTitle[title]}
              >
                {title}
              </InputSelectOption>
            ))}
          </InputSelect>
        )}
        <Input
          label="First name"
          name="firstName"
          required
          value={guest.firstName}
          error={
            showBookingFormErrors && !guest.firstName
              ? ERROR_TEXT[ValidationRule.RequiredField]
              : undefined
          }
          onChange={handleFieldChange}
          className={styles.nameInput}
          labelClassname={bookingStyles.inputTitle}
        />
        <Input
          label="Last name"
          name="lastName"
          required
          value={guest.lastName}
          error={
            showBookingFormErrors && !guest.lastName
              ? ERROR_TEXT[ValidationRule.RequiredField]
              : undefined
          }
          onChange={handleFieldChange}
          className={styles.nameInput}
          labelClassname={bookingStyles.inputTitle}
        />
      </div>
    </div>
  );
};

export default Guest;
