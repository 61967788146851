import React, { FC } from "react";

import { PackageInfo } from "@adl-gen/hotel/api";
import { PackageParams } from "@controllers/hotel-search/types";
import { Loading } from "@models/loading";
import styles from "@pages/hotel-details/hotel-details.css";
import { BreadCrumbType } from "@widgets/page/header-breadcrumbs/types";
import { RoomRateListing } from "@widgets/room-rate-listing/room-rate-listing";

interface Props {
  packages: Loading<PackageInfo[]>;
  onSelectPackage(params: PackageParams): void;
}

const HotelDetailsPackages: FC<Props> = ({ packages, onSelectPackage }) => {
  function onSelect(packageParams: PackageParams) {
    onSelectPackage({ ...packageParams, selectedFrom: BreadCrumbType.Details });
  }
  if (packages.kind === "loading") {
    return (
      <RoomRateListing
        kind={"listingInfo"}
        className={styles.hotelRooms}
        onSelectPackage={onSelect}
      />
    );
  } else if (packages.kind === "value") {
    return (
      <RoomRateListing
        kind={"listingInfo"}
        className={styles.hotelRooms}
        roomPackageList={packages.value}
        onSelectPackage={onSelect}
      />
    );
  } else {
    return null;
  }
};

export default HotelDetailsPackages;
