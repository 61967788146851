import clsx from "clsx";
import React, { FC, useRef, useState } from "react";

import Carousel from "./carousel";
import CarouselModal from "./carousel-modal";
import ImagesList from "./images-list";
import styles from "./styles.css";

interface Props {
  images: string[];
  coverImageUrl?: string;
}

const CoverImage: FC<Props> = ({ images, coverImageUrl }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);

  const listRef = useRef<HTMLUListElement | null>(null);

  function onChangeImage(index: number) {
    const targetIndex =
      index === images.length ? 0 : index === -1 ? images.length - 1 : index;
    setCurrentImage(targetIndex);
    adjustListScroll(targetIndex);
  }

  function adjustListScroll(targetIndex: number) {
    const ref = listRef.current;
    if (ref) {
      const rightBorder = 186 * (targetIndex + 1);
      const leftBorder = 186 * targetIndex;
      const currentScroll = ref.scrollLeft;
      const width = ref.getBoundingClientRect().width;

      if (rightBorder > currentScroll + width) {
        ref.scroll({ left: rightBorder - width, top: 0 });
      }

      if (leftBorder < currentScroll) {
        ref.scroll({ left: leftBorder, top: 0 });
      }
    }
  }

  if (!coverImageUrl) {
    return (
      <div className={clsx(styles.hotelCoverImage, styles.noHotelCoverImage)}>
        No images available
      </div>
    );
  }

  return (
    <div className={styles.hotelCoverImage}>
      <Carousel
        images={images}
        currentImage={currentImage}
        onChange={onChangeImage}
        onClick={() => setModalOpened(true)}
      />

      <ImagesList
        images={images}
        currentImage={currentImage}
        onSelectImage={onChangeImage}
        ref={listRef}
      />

      <CarouselModal
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        images={images}
        currentImage={currentImage}
      />
    </div>
  );
};

export default CoverImage;
