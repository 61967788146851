import { HOTEL_PARAM, PRICE_TOKEN_PARAM } from "@controllers/booking/constant";
import { isHotelSearchUrlParams } from "@controllers/hotel-search/utils";

export function isHotelPageUrlParams(object: URLSearchParams): boolean {
  return (
    isHotelSearchUrlParams(object) &&
    object.has(HOTEL_PARAM) &&
    object.has(PRICE_TOKEN_PARAM)
  );
}
