import clsx from "clsx";
import React, { FC, memo, useEffect, useState } from "react";

import { Button } from "@widgets/button/button";
import { Checkbox } from "@widgets/checkbox/checkbox";
import Textarea from "@widgets/textarea";

import styles from "./styles.css";

interface Props {
  open: boolean;
  bookings: string[];
  onClose(): void;
  addComment(text: string, selectedBookings: string[]): Promise<void>;
}

const NewComment: FC<Props> = ({
  open,
  onClose: closeModal,
  bookings,
  addComment,
}) => {
  const [commentText, setCommentText] = useState("");
  const [selectedBookings, setSelectedBookings] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  function handleCommentTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCommentText(e.currentTarget.value);
  }

  function handleBookingSelect(bookingId: string, checked: boolean) {
    if (checked) {
      setSelectedBookings((data) => [...data, bookingId]);
    } else {
      setSelectedBookings((data) => data.filter((item) => item !== bookingId));
    }
  }

  async function handleAddComment() {
    setIsLoading(true);

    await addComment(commentText, selectedBookings).finally(() =>
      setIsLoading(false)
    );

    closeModal();
  }

  useEffect(() => {
    if (selectedBookings.length === bookings.length && !allSelected) {
      setAllSelected(true);
    } else if (selectedBookings.length !== bookings.length && allSelected) {
      setAllSelected(false);
    }
  }, [selectedBookings, bookings]);

  function toggleSelectAll(checked: boolean) {
    if (checked && bookings.length !== selectedBookings.length) {
      setSelectedBookings(bookings);
    } else {
      setSelectedBookings([]);
    }
  }

  return (
    <div className={clsx(styles.newComment, open && styles.opened)}>
      <Textarea
        name="comment"
        placeholder="Enter comment text"
        value={commentText}
        onChange={handleCommentTextChange}
        className={styles.commentInput}
      />

      <div className={styles.bookingListWrapper}>
        <div className={styles.title}>Associated Bookings</div>
        <div className={styles.bookingList}>
          {bookings.map((bookingId) => (
            <Checkbox
              key={bookingId}
              className={styles.bookingCheckbox}
              text={bookingId}
              checked={selectedBookings.includes(bookingId)}
              onChange={(checked) => handleBookingSelect(bookingId, checked)}
            />
          ))}
        </div>

        <Checkbox
          className={clsx(styles.bookingCheckbox, styles.selectAll)}
          text="Select all bookings"
          checked={allSelected}
          onChange={toggleSelectAll}
        />

        <div className={styles.footer}>
          <Button
            onClick={handleAddComment}
            disabled={!commentText.length || !selectedBookings.length}
            isLoading={isLoading}
          >
            Add Comment
          </Button>
          <Button emphasis="medium" onClick={closeModal}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(NewComment);
