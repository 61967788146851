import clsx from "clsx";
import React, { FC } from "react";

import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";

import ContentGroup from "../content-group";
import styles from "../hotel-details.css";

interface Props {
  hotel?: HotelListingResultWithId;
}

/**
 * Static data for about this area titles mapped to value strings.
 * // TODO(jess): This is hardcoded static data. We want to replace this with dynamically loaded data from the backend when
 * // an endpoint that returns this data is implemented. We may need to restructure how this is set out depending on
 * // what data Gimmonix returns.
 */
const AboutThisProperty: FC<Props> = ({ hotel }) => {
  // TODO(jess): Handle this case better.
  if (!hotel) {
    return <div>No hotel found</div>;
  } else {
    const hotelName = hotel.details.hotelName;
    const description = hotel.details.hotelDescription;
    // If no description exists, just don't display this section.
    if (!description) {
      return <div />;
    }
    return (
      <div className={styles.contentSection}>
        <div className={styles.contentTitle}>
          Additional information and Policies
        </div>
        <div className={clsx(styles.box, styles.aboutThisPropertyBox)}>
          <ContentGroup
            blockHeading={hotelName}
            bodyTextBlocks={[description]}
          />
        </div>
      </div>
    );
  }
};

export default AboutThisProperty;
