import { useEffect, useState } from "react";

import { LoginReq, UserProfile } from "@adl-gen/hotel/api";
import { NotificationController } from "@controllers/notification-controller";
import { assertNever } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { Service } from "@src/service/service";
import { TokenManager } from "@src/service/token-manager";
import { sendErrorToRollbar } from "@util/error-handling";

export type LoginState =
  | { kind: "not-logged-in" }
  | { kind: "logged-in" }
  | { kind: "login-failed"; error: string };

/**
 * Data and actions related to user identity.
 */
export interface IdentityController {
  userProfile?: UserProfile;
  loadProfile(): Promise<void>;
  login(req: LoginReq): Promise<LoginState>;
  logout(): void;
  isLoggedIn(): boolean;
  avatarUrl: string;
  /** A boolean state tracking whether the user profile has loaded. */
  userProfileIsLoading: boolean;
  sensitiveDataHidden: boolean;
  toggleSensitiveDataVisibility(): void;
}

const CLIENT_TOGGLE_KEY = "clientToggle";

export function useIdentityController(
  service: Pick<Service, "whoami" | "login">,
  tokenManager: TokenManager,
  notificationController: NotificationController
): IdentityController {
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(
    undefined
  );
  const [userProfileIsLoading, setUserProfileIsLoading] = useState(true);
  /** Keep track of whether the user is logged out on initial mount by checking that the user token is non-empty */
  const [userIsLoggedOut, setUserIsLoggedOut] = useState(
    tokenManager.getToken().length === 0
  );

  const [sensitiveDataHidden, setSensitiveDataHidden] = useState(false);

  // On initial mount, load the logged in user profile.
  useEffect(() => {
    // If the user is logged out, then stop loading and don't attempt to load the user profile.
    if (userIsLoggedOut) {
      setUserProfileIsLoading(false);
    } else {
      void loadProfile();
    }

    try {
      const clientToggleFromStorage = localStorage.getItem(CLIENT_TOGGLE_KEY);
      if (
        clientToggleFromStorage !== null &&
        JSON.parse(clientToggleFromStorage) === true
      ) {
        setSensitiveDataHidden(true);
      }
    } catch (e) {
      sendErrorToRollbar(e, {
        additionalInfo:
          "Failed to get the client toggle value from the local storage",
      });
      // tslint:disable-next-line:no-console
      console.error(
        "Failed to get the client toggle value from the local storage: ",
        e.message
      );
    }

    const clientToggleHandler = (event) => {
      if (event.storageArea !== localStorage) {
        return;
      }
      if (event.key === CLIENT_TOGGLE_KEY) {
        const newValue = JSON.parse(event.newValue);

        if (typeof newValue === "boolean") {
          setSensitiveDataHidden(newValue);
        }
      }
    };
    window.addEventListener("storage", clientToggleHandler);

    return () => {
      window.removeEventListener("storage", clientToggleHandler);
    };
  }, []);

  // When the logged in user profile changes, check if it is defined and set
  // update the user profile loading state tracker accordingly.
  useEffect(() => {
    if (userProfile) {
      setUserProfileIsLoading(false);
    }
  }, [userProfile]);

  /**
   * Login user and saves access token and user profile.
   * Returns a login state of kind "logged-in" if successful
   * and a state of kind "login-failed" accompanied by an
   * error message if not.
   */
  const login = async (req: LoginReq): Promise<LoginState> => {
    try {
      const resp = await service.login(req);
      let state: LoginState = { kind: "not-logged-in" };
      if (resp.kind === "accessToken") {
        tokenManager.setToken(resp.value);
        await loadProfile();
        state = { kind: "logged-in" };
      } else if (resp.kind === "invalidCredentials") {
        const error = "Sorry, the email and password is incorrect";
        state = { kind: "login-failed", error };
      } else {
        assertNever(resp);
      }
      return state;
    } catch (e) {
      return {
        kind: "login-failed",
        error: "Unexpected error: unable to log in",
      };
    }
  };

  /**
   * Loads the user profile from the token manager by
   * calling whoami() on the server on the token saved
   * in local storage.
   */
  const loadProfile = async () => {
    if (tokenManager.getToken().length > 0) {
      try {
        const profile = await service.whoami();
        setUserProfile(profile);
      } catch (e) {
        notificationController.addNotification({
          type: NotificationTypeEnum.Error,
          text: "Unexpected error occurred",
        });
      }
    } else {
      setUserIsLoggedOut(true);
    }
  };

  /**
   * Logs the user out by clearing the user token
   * and the loaded user profile.
   */
  const logout = () => {
    tokenManager.setToken("");
    setUserProfile(undefined);
    setUserProfileIsLoading(true);
    setUserIsLoggedOut(true);
  };

  /**
   * Checks if a user is currently logged in by checking if
   * a user profile is loaded (which should happen upon login).
   */
  const isLoggedIn = () => {
    return userProfile !== undefined;
  };

  const toggleSensitiveDataVisibility = () => {
    setSensitiveDataHidden((prevState) => {
      try {
        localStorage.setItem(CLIENT_TOGGLE_KEY, JSON.stringify(!prevState));
      } catch (e) {
        sendErrorToRollbar(e, {
          additionalInfo:
            "Failed to update the client toggle value in the local storage",
        });
        // tslint:disable-next-line:no-console
        console.error(
          "Failed to update the client toggle value in the local storage: ",
          e.message
        );
      }

      return !prevState;
    });
  };

  return {
    userProfile,
    loadProfile,
    login,
    logout,
    isLoggedIn,
    avatarUrl: "https://picsum.photos/200",
    userProfileIsLoading,
    sensitiveDataHidden,
    toggleSensitiveDataVisibility,
  };
}
