import clsx from "clsx";
import React, { FC, useContext } from "react";

import { PaymentMode } from "@adl-gen/hotel/booking";
import { PriceValue } from "@adl-gen/hotel/common";
import { LoggedInContext } from "@app/app";
import { WarningOutlinedIcon } from "@assets/svg-components";
import { assertNotUndefined } from "@hx/util/types";
import { renderPriceString } from "@util/agentus-utis";
import { Price } from "@widgets/price/price";
import { Radio } from "@widgets/radio/radio";
import Tooltip from "@widgets/tooltip";

import styles from "./styles.css";

export interface CommonPriceConfigProps {
  paymentMode: PaymentMode;
  onChangePaymentMode(paymentMode: PaymentMode): void;
  netAmount: PriceValue;
  grossAmount: PriceValue;
}

interface PropTypes extends CommonPriceConfigProps {
  isPartialPayments?: boolean;
  onPartialPaymentsChange?(checked: boolean): void;
}

const PriceConfig: FC<PropTypes> = ({
  netAmount,
  grossAmount,
  paymentMode,
  onChangePaymentMode,
}) => {
  const { sensitiveDataHidden } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );
  function selectTotalNet(checked: boolean) {
    onChangePaymentMode(checked ? PaymentMode.net : PaymentMode.gross);
  }
  function selectTotalGross(checked: boolean) {
    onChangePaymentMode(checked ? PaymentMode.gross : PaymentMode.net);
  }

  return (
    <div
      className={clsx(
        styles.amountRow,
        sensitiveDataHidden && styles.sensitiveDataHidden
      )}
    >
      <div className={styles.priceList}>
        <Radio
          size="small"
          className={styles.priceLabel}
          classes={{ text: styles.radioText }}
          text={`${renderPriceString(netAmount)} Total Net`}
          name="paymentTypeRadio"
          checked={paymentMode === PaymentMode.net}
          onChange={selectTotalNet}
        />
        <Radio
          size="small"
          className={styles.priceLabel}
          classes={{ text: styles.radioText }}
          text={`${renderPriceString(grossAmount)} Total Gross`}
          name="paymentTypeRadio"
          checked={paymentMode === PaymentMode.gross}
          onChange={selectTotalGross}
        />
        {/*<Checkbox*/}
        {/*  size="small"*/}
        {/*  className={styles.priceLabel}*/}
        {/*  text="Partial Payments"*/}
        {/*  checked={isPartialPayments}*/}
        {/*  onChange={onPartialPaymentsChange}*/}
        {/*/>*/}
      </div>
      <Tooltip
        classes={{ wrapper: styles.moreInformationHint }}
        text="Client view is on, switch it off for more information"
      >
        <WarningOutlinedIcon />
      </Tooltip>

      <div className={styles.amountDetail}>
        <Price
          value={
            (sensitiveDataHidden
              ? grossAmount
              : paymentMode === PaymentMode.gross
              ? grossAmount
              : netAmount
            ).value
          }
          currency={
            (sensitiveDataHidden
              ? grossAmount
              : paymentMode === PaymentMode.gross
              ? grossAmount
              : netAmount
            ).currency
          }
          size="large"
        />
      </div>
    </div>
  );
};

export default PriceConfig;
