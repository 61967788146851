import { useCallback, useEffect, useRef, useState } from "react";

function useOutsideClick<T extends HTMLElement>(open?: boolean) {
  const [isOpen, setIsOpen] = useState(!!open);

  // Ref to handle the click outside
  const ref = useRef<T>(null);

  // Close the dropdown if the user clicks outside of the component
  useEffect(() => {
    const clickOutsideHandler = (event) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", clickOutsideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
    };
  }, [ref]);

  const toggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return {
    isOpen,
    ref,
    toggleIsOpen,
  };
}

export default useOutsideClick;
