import React from "react";

interface IconBaseProps {
  /** Icon to be displayed */
  icon: JSX.Element;
}

interface IconWithSizeProps extends IconBaseProps {
  /** Size in px to reszie the notional icon container to */
  size: number;
}

interface IconWithScaleProps extends IconBaseProps {
  /** Scale factor to apply to the notional icon container */
  scale: number;
}

/** Component Props */

type IconProps = IconWithSizeProps | IconWithScaleProps;

/**
 * Base size for the notional container in which icons were created within the
 * design for this application.
 */

const BASE_ICON_SIZE = 32;

/**
 * This component allows icons to be resized according to the notional container
 * in which they were created, this allowing the relative sizing of icons to be
 * maintained as per the design intention. You can supply either a size in pixels,
 * or a scale factor.
 */
export const IconSizeable = (props: IconProps) => {
  const icon = props.icon;

  let scale: number;

  /* tslint:disable:strict-type-predicates */
  if ((props as IconWithSizeProps).size !== undefined) {
    scale = (props as IconWithSizeProps).size / BASE_ICON_SIZE;
  } else {
    scale = (props as IconWithScaleProps).scale;
  }
  /* tslint:enable:strict-type-predicates */

  const scaled = React.cloneElement(icon, {
    width: icon.props.width ? icon.props.width * scale : icon.props.width,
    height: icon.props.height ? icon.props.height * scale : icon.props.height,
  });

  return scaled;
};
