import React, {
  FormEvent,
  forwardRef,
  useContext,
  useMemo,
  useState,
} from "react";

import { WithDbId } from "@adl-gen/common/db";
import { AppUserDetails, UserReq } from "@adl-gen/hotel/api";
import { AppUserType } from "@adl-gen/hotel/db";
import { PhoneNumber } from "@adl-gen/ids/common";
import { GeneralContext, LoggedInContext } from "@app/app";
import { EXISTING_EMAIL_ERROR } from "@controllers/user/constant";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { EMPTY_NEW_AGENT_FORM } from "@pages/settings/manage-agents/agent-modal/constant";
import phoneCountryCodes from "@pages/settings/phone-country-codes.json";
import { CountryCode } from "@pages/settings/types";
import { getFlagUrlByCode } from "@util/agentus-utis";
import { validateString, ValidationRule } from "@util/validation";
import { Button } from "@widgets/button/button";
import Image from "@widgets/image";
import Input from "@widgets/input";
import { InputSelect, InputSelectOption } from "@widgets/input-select";
import ModalWindow from "@widgets/modal-window";

import { ROLE_MAP } from "../../constant";
import styles from "./styles.css";
import { AgentModalForm, AgentModalFormErrors } from "./types";

const NO_AGENCY_LABEL = "No Agency";

interface Props {
  isOpen: boolean;
  onClose(): void;
  user?: WithDbId<AppUserDetails>;
}

const AgentModal = forwardRef<HTMLFormElement, Props>(
  ({ isOpen, onClose, user }, ref) => {
    if (!isOpen) {
      return null;
    }

    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState<AgentModalForm>(
      user
        ? {
            fullname: user.value.fullname,
            email: user.value.email,
            userType: user.value.userType,
            phoneNumber: user.value.phoneNumber,
            agencyId: user.value.agencyId,
          }
        : EMPTY_NEW_AGENT_FORM
    );

    const [validationErrors, setValidationErrors] = useState<
      AgentModalFormErrors
    >({});

    const { fullname, email, phoneNumber, userType, agencyId } = userData;

    const { createAgentUser, updateUser, fetchUsers } = assertNotUndefined(
      useContext(LoggedInContext).userController
    );

    const { userProfile } = assertNotUndefined(
      useContext(LoggedInContext).identityController
    );

    const { addNotification } = assertNotUndefined(
      useContext(GeneralContext).notificationController
    );

    const { agencies } = assertNotUndefined(
      useContext(LoggedInContext).agencyController
    );

    const agenciesOptions = useMemo(() => {
      const options = agencies.map(({ id, value }) => {
        const { name } = value;

        return (
          <InputSelectOption key={id} value={id}>
            {name}
          </InputSelectOption>
        );
      });

      options.unshift(
        <InputSelectOption key={NO_AGENCY_LABEL} value="">
          {NO_AGENCY_LABEL}
        </InputSelectOption>
      );

      return options;
    }, [agencies]);

    if (!userProfile) {
      return null;
    }

    const isCurrentUserBackoffice =
      userProfile.appUser.value.userType !== AppUserType.agencyAdmin;

    function changeField({ currentTarget }: FormEvent<HTMLInputElement>) {
      const { value, name } = currentTarget;

      setUserData((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    }

    function changeUserType(value: number) {
      setUserData((prevValue) => ({
        ...prevValue,
        userType: value as AppUserType,
      }));
    }

    function changeAgency(value: string) {
      setUserData((prevValue) => ({
        ...prevValue,
        agencyId: value || null,
      }));
    }

    function updatedNestedPhoneField(value: string, name: keyof PhoneNumber) {
      const updated: PhoneNumber = {
        countryCode: phoneNumber?.countryCode ? phoneNumber.countryCode : "",
        number: phoneNumber?.number ? phoneNumber.number : "",
      };

      updated[name] = value;

      setUserData((prevValue) => ({
        ...prevValue,
        phoneNumber: Object.values(updated).some((val) => !!val)
          ? updated
          : null,
      }));
    }

    function changeCountryCode(value: string) {
      updatedNestedPhoneField(value, "countryCode");
    }

    function changeNumber({ currentTarget }: FormEvent<HTMLInputElement>) {
      const { value } = currentTarget;

      updatedNestedPhoneField(value, "number");
    }

    async function create(e: FormEvent) {
      e.preventDefault();

      const formValid = validate();
      if (!formValid) {
        return;
      }

      setLoading(true);

      const {
        email: formEmail,
        userType: formUserType,
        ...restUserData
      } = userData;

      try {
        await createAgentUser({
          ...restUserData,
          emailAddress: formEmail,
          isAgencyAdmin: formUserType === AppUserType.agencyAdmin,
          agencyId: isCurrentUserBackoffice
            ? userData.agencyId
            : userProfile!.appUser.value.agencyId,
        });

        onClose();
      } catch (e) {
        addNotification({
          type:
            e.status === EXISTING_EMAIL_ERROR
              ? NotificationTypeEnum.Warning
              : NotificationTypeEnum.Error,
          text: e.publicMessage || e.message,
        });
      }

      setLoading(false);
    }

    async function edit(e: FormEvent) {
      e.preventDefault();

      if (!user) {
        return;
      }

      const formValid = validate();
      if (!formValid) {
        return;
      }

      setLoading(true);

      try {
        const body: WithDbId<UserReq> = {
          id: user.id,
          value: {
            ...userData,
            agencyId: isCurrentUserBackoffice
              ? userData.agencyId
              : user.value.agencyId,
            commissionPercentage: user.value.commissionPercentage,
          },
        };

        await updateUser(body);
        await fetchUsers();

        onClose();
      } catch (e) {
        addNotification({
          type:
            e.status === EXISTING_EMAIL_ERROR
              ? NotificationTypeEnum.Warning
              : NotificationTypeEnum.Error,
          text: e.publicMessage || e.message,
        });
      }

      setLoading(false);
    }

    function validate() {
      const nameError = validateString(fullname, ValidationRule.RequiredField);
      const emailError = validateString(email, ValidationRule.Email);
      const agencyIdError = isCurrentUserBackoffice
        ? validateString(agencyId || "", ValidationRule.RequiredField)
        : "";

      setValidationErrors({
        fullname: nameError,
        email: emailError,
        agencyId: agencyIdError,
      });

      return !nameError && !emailError && !agencyIdError;
    }

    return (
      <ModalWindow className={styles.root} open={isOpen} onClose={onClose}>
        <form
          className={styles.content}
          ref={ref}
          onSubmit={user ? edit : create}
          onReset={onClose}
        >
          <h2 className={styles.title}>
            {user ? "Edit the agent" : "Add a new agent"}
          </h2>

          <Input
            label="name"
            placeholder="Name"
            value={fullname}
            onChange={changeField}
            name="fullname"
            error={validationErrors.fullname}
            required
          />
          <Input
            label="Email"
            placeholder="Email address"
            value={email}
            onChange={changeField}
            name="email"
            error={validationErrors.email}
            required
          />

          <div className={styles.phoneRow}>
            <InputSelect
              label="Phone Number"
              placeholder="Country code"
              value={phoneNumber?.countryCode || ""}
              onChange={changeCountryCode}
            >
              {(phoneCountryCodes as CountryCode[]).map(
                ({ name, dial_code, code }) => (
                  <InputSelectOption key={name} value={dial_code}>
                    <>
                      {`${dial_code} \(${name}\)`}{" "}
                      <Image
                        className={styles.countryFlag}
                        src={getFlagUrlByCode(code)}
                        alt={`${name} flag`}
                      />
                    </>
                  </InputSelectOption>
                )
              )}
            </InputSelect>
            <Input
              placeholder="Phone number"
              variant="fullwidth"
              value={phoneNumber?.number || ""}
              onChange={changeNumber}
              type="number"
              name="number"
            />
          </div>

          <div className={styles.dropdownsRow}>
            <InputSelect
              label="Select a Role"
              placeholder="Select a role"
              value={userType}
              onChange={changeUserType}
              displayValue={ROLE_MAP[userType].label}
              required
            >
              <InputSelectOption
                key={`${AppUserType.agent}`}
                value={AppUserType.agent}
              >
                {ROLE_MAP[AppUserType.agent].label}
              </InputSelectOption>
              <InputSelectOption
                key={`${AppUserType.agencyAdmin}`}
                value={AppUserType.agencyAdmin}
              >
                {ROLE_MAP[AppUserType.agencyAdmin].label}
              </InputSelectOption>
            </InputSelect>

            {isCurrentUserBackoffice && (
              <InputSelect
                label="Select an agency"
                placeholder="Select an agency"
                value={agencyId || ""}
                onChange={changeAgency}
                displayValue={
                  agencyId
                    ? agencies.find((item) => item.id === agencyId)?.value.name
                    : NO_AGENCY_LABEL
                }
                required
                error={validationErrors.agencyId}
              >
                {agenciesOptions}
              </InputSelect>
            )}
          </div>

          <div className={styles.actions}>
            <Button type="reset" emphasis="medium">
              Close
            </Button>
            <Button type="submit" isLoading={loading}>
              {user ? "Edit User" : "Add User"}
            </Button>
          </div>
        </form>
      </ModalWindow>
    );
  }
);

export default AgentModal;
