import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { RoomOccupancyDetails } from "@adl-gen/hotel/booking";
import { LoggedInContext } from "@app/app";
import { ErrorFilledIcon } from "@assets/svg-components";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";
import { assertNotUndefined } from "@hx/util/types";
import { Button } from "@widgets/button/button";
import { AgentSelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";
import BookingItemSmall from "@widgets/itinerary/unavailable-items-modal/booking-item-small";
import ModalWindow from "@widgets/modal-window";

import styles from "./styles.css";

interface Props {
  open: boolean;
  onClose(): void;
  bookings: AgentSelectedBookingInfo[];
}

const UnavailableItemsModal: FC<Props> = ({ open, onClose, bookings }) => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { identityController } = assertNotUndefined(
    useContext(LoggedInContext)
  );
  const agencyController = assertNotUndefined(
    useContext(LoggedInContext).agencyController
  );

  const [selectedBookings, setSelectedBookings] = useState<string[]>([]);

  function selectBooking(id: string, checked: boolean) {
    if (checked) {
      setSelectedBookings((data) => [...data, id]);
    } else {
      setSelectedBookings((data) => data.filter((item) => item !== id));
    }
  }

  function getRoomSearchParam(rooms: RoomOccupancyDetails[]): string[] {
    return rooms.map((room, idx) => {
      const roomString: string[] = [`room-${idx + 1}`, `${room.adults.length}`];
      room.childPassengers.forEach((child, childIdx) =>
        roomString.push(`child-${childIdx}-${child.age}`)
      );
      return roomString.join(",");
    });
  }

  function findReplacement() {
    const selectedBooking = bookings.find(
      (item) => item.bookingId === selectedBookings[0]
    );

    if (selectedBooking) {
      const currentUser = identityController?.userProfile?.appUser.value;
      const currentAgency =
        agencyController.agencySettingsState.currentAgencyDetails?.value;
      const commission = currentUser?.commissionPercentage
        ? currentUser?.commissionPercentage
        : currentAgency?.commissionPercentage || "0";

      params.set("location", selectedBooking.hotelId);
      params.set("type", "hotel");
      params.set("locationname", selectedBooking.hotelName);
      params.set(
        "checkin",
        moment(selectedBooking.checkIn).format(LOCALDATE_FORMAT)
      );
      params.set(
        "checkout",
        moment(selectedBooking.checkOut).format(LOCALDATE_FORMAT)
      );
      params.set("commission", commission);
      params.set("order", "0");
      getRoomSearchParam(selectedBooking.rooms).forEach((roomString) =>
        params.append("room", roomString)
      );

      location.pathname = "/search";
      location.search = params.toString();
      history.push(location);
    }
  }

  return (
    <ModalWindow
      className={styles.unavailableItemsModal}
      open={open}
      onClose={onClose}
    >
      <div className={styles.unavailableItemsInner}>
        <div className={styles.modalTitle}>Unavailable Items</div>
        <div className={styles.errorMessage}>
          <div className={styles.errorIcon}>
            <ErrorFilledIcon />
          </div>
          <div>
            <div>
              Due to a change of rate, lack of availability or a change in the
              cancellation and/or payment policy, one or more of your items
              could not be confirmed. Deselect these items if you wish to
              continue with paying for your confirmed items.
            </div>
            <div className={styles.bolder}>
              Your unavailable items are now kept under the Action and itinerary
              tabs for you to find replacements in the future
            </div>
          </div>
        </div>

        <div className={styles.bookingList}>
          {bookings.map((booking) => (
            <BookingItemSmall
              key={booking.bookingId || booking.bookingNumber}
              booking={booking}
              selected={selectedBookings.includes(
                booking.bookingId || booking.bookingNumber
              )}
              toggleSelected={selectBooking}
            />
          ))}
        </div>

        <div className={styles.modalFooter}>
          <Button
            emphasis="medium"
            size="large"
            onClick={findReplacement}
            disabled={selectedBookings.length !== 1}
          >
            Find Replacement
          </Button>
          <Button onClick={onClose}>Close All</Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default UnavailableItemsModal;
