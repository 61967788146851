export enum BreadCrumbType {
  Search = "search",
  Results = "results",
  Details = "details",
  Confirmation = "confirmation",
}

export interface BreadCrumbsDataType {
  title: string;
  type: BreadCrumbType;
}
