import React, { FC, useCallback, useContext, useState } from "react";

import { WithDbId } from "@adl-gen/common/db";
import { AppUserDetails } from "@adl-gen/hotel/api";
import { UserStatus } from "@adl-gen/hotel/db";
import { GeneralContext, LoggedInContext } from "@app/app";
import useOutsideClick from "@common/hooks/outside-click";
import { Icons } from "@common/icon/svg";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";

import BackofficeUserModal from "../user-modal";
import styles from "./styles.css";

interface Props {
  user: WithDbId<AppUserDetails>;
}

// Commented until https://xllabs.atlassian.net/browse/AG-349 is completed
const SHOW_REACTIVATE_OPTION = false;

const DetailsButton: FC<Props> = ({ user }) => {
  const { ref, isOpen, toggleIsOpen } = useOutsideClick<HTMLButtonElement>();

  const [modalOpened, setModalOpened] = useState(false);

  const { value, id } = user;

  const { inviteUser, deleteBackofficeUser } = assertNotUndefined(
    useContext(LoggedInContext).userController
  );

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const toggleEditModal = useCallback(() => {
    setModalOpened((prevValue) => !prevValue);
  }, [modalOpened]);

  async function deleteAgent() {
    try {
      await deleteBackofficeUser(id);

      addNotification({
        type: NotificationTypeEnum.Success,
        text: "The user is deactivated",
      });
    } catch (e) {
      addNotification({
        type:
          e.status === 422
            ? NotificationTypeEnum.Warning
            : NotificationTypeEnum.Error,
        text: e.publicMessage || e.message,
      });
    }
  }

  async function sendActivationEmail() {
    try {
      await inviteUser(value.email);

      addNotification({
        type: NotificationTypeEnum.Success,
        text: `Activation email has been sent to ${value.email}`,
      });
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Invite sending error",
      });
    }
  }

  return (
    <>
      <button ref={ref} className={styles.detailsButton} onClick={toggleIsOpen}>
        {Icons.ellipsis}

        {isOpen && (
          <ul className={styles.list}>
            <li onClick={toggleEditModal}>Edit User</li>
            {value.userStatus !== UserStatus.activated &&
              SHOW_REACTIVATE_OPTION && (
                <li onClick={sendActivationEmail}>Send Activation Email</li>
              )}
            {value.userStatus !== UserStatus.deleted && (
              <li onClick={deleteAgent}>Deactivate User</li>
            )}
          </ul>
        )}
      </button>

      <BackofficeUserModal
        isOpen={modalOpened}
        onClose={toggleEditModal}
        user={user}
      />
    </>
  );
};

export default DetailsButton;
