import classnames from "classnames";
import React from "react";

import styles from "./page-section.css";

export interface PageSectionProps {
  // Optional additional class to apply to the wrapper element
  className?: string;
  // Optional additional classes to apply to internal elements
  classes?: { pageSection?: string; pageSectionContainer?: string };
  // Define the HTML tag to use for the PageSection, default: section
  tag?: "header" | "section" | "footer";
  // Apply standard container padding, default: true (useful to set to false when container needed to bleeds to edge)
  pad?: boolean;
  // Consider this section as the main one on the page (eg. grows to fill the space, different background colour)
  main?: boolean;
  // Content to be inserted into the PageSection
  children?: React.ReactNode;
}

export const PageSection = ({
  className,
  classes = {},
  tag = "section",
  pad = true,
  main = false,
  children,
}: PageSectionProps): JSX.Element => {
  const Element = tag;

  return (
    <Element
      className={classnames(
        styles.pageSection,
        main && styles.mainPageSection,
        className,
        classes.pageSection
      )}
    >
      <div
        className={classnames(
          styles.pageSectionContainer,
          classes.pageSectionContainer,
          pad && styles.pad
        )}
      >
        {children}
      </div>
    </Element>
  );
};
