import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";

import { PackageInfo, SimpleCancellationPolicy } from "@adl-gen/hotel/api";
import { Board } from "@adl-gen/hotel/booking";
import { PriceValue } from "@adl-gen/hotel/common";
import { WarningOutlinedIcon } from "@assets/svg-components";
import { BaseProps } from "@common/base";
import { getBoardDisplayString } from "@util/agentus-utis";
import { sendErrorToRollbar } from "@util/error-handling";
import { Badge, BadgeColor } from "@widgets/badge/badge";
import Image from "@widgets/image";
import { Radio } from "@widgets/radio/radio";
import Tooltip from "@widgets/tooltip";

import { Button } from "../../button/button";
import { Icons } from "../../icon/icons";
import { Price } from "../../price/price";
import { Skeleton } from "../../skeleton/skeleton";
import { useRoomRateOptions } from "./hooks";
import styles from "./room-rate-item.css";
import {
  CANCELLATION_DATE_DISPLAY_FORMAT,
  CANCELLATION_DATE_LOCALE,
  findDefaultRateForRoom,
  renderPriceDiffString,
} from "./utils";

interface PriceWithBookButton {
  kind: "priceWithBookButton";
  price: PriceValue;
  onClickBook(): void;
}

interface PriceDetailsOnly {
  kind: "priceDetailsOnly";
  netPrice: PriceValue;
  grossPrice: PriceValue;
}

interface ClientViewPrice {
  kind: "clientViewPrice";
  grossPrice: PriceValue;
}

export type PriceWithAction =
  | PriceWithBookButton
  | PriceDetailsOnly
  | ClientViewPrice;

export interface RoomRate {
  roomPackage: PackageInfo;
  price: PriceWithAction;
}

/** Details required to display a single hotel room package/rate for a hotel. */
export interface RoomRateItemValueProps extends BaseProps {
  kind: "value";
  /** All rates for the specific room */
  rates: RoomRate[];
  /** Date for correct  */
  checkIn?: string;
}

interface RoomRateItemSkeletonProps {
  kind: "skeleton";
}

export type RoomRateItemProps =
  | RoomRateItemValueProps
  | RoomRateItemSkeletonProps;

/**
 * Represents a single hotel room package/rate result for a hotel on the search
 * results page.
 */
export const RoomRateItem = (props: RoomRateItemProps) => {
  if (props.kind === "value") {
    const { rates } = props;

    const defaultPackage = useMemo(() => findDefaultRateForRoom(rates), [
      rates,
    ]);

    const [currentPackage, setCurrentPackage] = useState(defaultPackage);

    const {
      selectedCancellation,
      setSelectedCancellation,
      selectedBoard,
      setSelectedBoard,
      boardOptions,
      cancellationOptions,
    } = useRoomRateOptions(defaultPackage, rates);

    useEffect(() => {
      const targetPackage = rates.find(
        (rate) =>
          rate.roomPackage.cancellationPolicy.kind === selectedCancellation
      );

      if (!targetPackage) {
        throw new Error(
          "Unexpected error: target package is not found after selecting a cancellation policy"
        );
      }

      const targetBoard = targetPackage.roomPackage.board;

      setCurrentPackage(targetPackage);
      setSelectedBoard(targetBoard);
    }, [selectedCancellation]);

    useEffect(() => {
      setCurrentPackage((prevState) => {
        const targetRate = rates.find(
          (rate) =>
            rate.roomPackage.packageId === prevState.roomPackage.packageId
        );

        return targetRate ? targetRate : prevState;
      });
    }, [rates]);

    const onSelectBoard = (board: Board) => {
      const targetPackage = rates.find(
        (rate) =>
          rate.roomPackage.cancellationPolicy.kind === selectedCancellation &&
          rate.roomPackage.board === board
      );

      if (!targetPackage) {
        throw new Error(
          "Unexpected error: target package is not found after selecting a board"
        );
      }

      setCurrentPackage(targetPackage);
      setSelectedBoard(board);
    };

    const thumbnailUrl = useMemo(() => rates[0].roomPackage.thumbnailUrl, []);
    const { roomPackage, price } = currentPackage;

    const { name, bedConfigurations, area, occupancy } = roomPackage;

    return (
      <div className={styles.roomRow}>
        {thumbnailUrl && thumbnailUrl.length > 0 ? (
          <Image className={styles.thumbnail} src={thumbnailUrl} invertColor />
        ) : (
          <div className={clsx(styles.thumbnail, styles.noImage)}>No image</div>
        )}
        <div className={styles.informationColumn}>
          <span className={styles.name}>{name}</span>
          {bedConfigurations.map((current, index) => (
            <span key={index} className={styles.bedItem}>
              {Icons.doubleBed}
              {current}
            </span>
          ))}
          {area && (
            <span className={styles.bedItem}>
              {Icons.space}
              {`${area.toString()}sqm`}
            </span>
          )}
        </div>
        <div className={styles.sleepColumn}>
          {occupancy &&
            Array(occupancy)
              .fill(1)
              .map((_, index) =>
                React.cloneElement(Icons.person, { key: index })
              )}
        </div>
        <div className={styles.radioGroup}>
          {cancellationOptions.map((option) => {
            return price.kind === "priceWithBookButton" ? (
              <div key={option.policy.kind}>
                <Radio
                  checked={selectedCancellation === option.policy.kind}
                  classes={{ text: styles.cancellationRadio }}
                  size="small"
                  text={
                    <CancellationPolicy cancellationPolicy={option.policy} />
                  }
                  onChange={() => setSelectedCancellation(option.policy.kind)}
                  multiline
                />
                <span className={styles.priceDiff}>
                  {renderPriceDiffString(option.additionalPrice)}
                </span>
              </div>
            ) : (
              <CancellationPolicy cancellationPolicy={option.policy} />
            );
          })}
        </div>
        <div className={styles.radioGroup}>
          {boardOptions.map(({ value, disabled, additionalPrice }) => {
            return price.kind === "priceWithBookButton" ? (
              <div key={value}>
                <Radio
                  checked={selectedBoard === value}
                  classes={{ text: styles.cancellationRadio }}
                  size="small"
                  text={getBoardDisplayString(value)}
                  onChange={() => onSelectBoard(value)}
                  disabled={disabled}
                />
                <span className={styles.priceDiff}>
                  {renderPriceDiffString(additionalPrice)}
                </span>
              </div>
            ) : (
              getBoardDisplayString(value)
            );
          })}
        </div>
        <div className={styles.ratesColumn}>
          {price.kind === "priceDetailsOnly" ? (
            <PriceDetails {...price} />
          ) : price.kind === "clientViewPrice" ? (
            <PriceForClientView {...price} />
          ) : (
            <PriceWithButton {...price} />
          )}
        </div>
      </div>
    );
  } else {
    return <RoomRateItemSkeleton />;
  }
};

const CancellationPolicy = (cancellationPolicyProps: {
  cancellationPolicy: SimpleCancellationPolicy;
}): JSX.Element | null => {
  const { cancellationPolicy } = cancellationPolicyProps;

  if (cancellationPolicy.kind === "freeCancellation") {
    return (
      <Tooltip text="Select ‘Book room’ to view">
        <Badge
          text="Refundable"
          variant={BadgeColor.Green}
          endIcon={<WarningOutlinedIcon />}
        />
      </Tooltip>
    );
  } else if (cancellationPolicy.kind === "freeCancellationUntil") {
    const date = new Date(cancellationPolicy.value);
    const userFriendlyDate = date.toLocaleDateString(
      CANCELLATION_DATE_LOCALE,
      CANCELLATION_DATE_DISPLAY_FORMAT
    );

    const tooltipText = `Until 23:59 on ${userFriendlyDate}`;

    return (
      <Tooltip text={tooltipText}>
        <Badge
          text="Refundable"
          variant={BadgeColor.Green}
          endIcon={<WarningOutlinedIcon />}
        />
      </Tooltip>
    );
  } else if (cancellationPolicy.kind === "noCancellation") {
    return (
      <Badge
        className={styles.noCancellationBadge}
        text="Non Refundable"
        variant={BadgeColor.Yellow}
      />
    );
  } else if (cancellationPolicy.kind === "cancellationNotAvailable") {
    sendErrorToRollbar(
      new Error('Package has "cancellationNotAvailable" policy'),
      {}
    );
    return null;
  } else {
    throw new Error("Illegal cancellation type");
  }
};

const PriceDetails = (details: PriceDetailsOnly) => {
  return (
    <>
      <div className={styles.priceDetail}>
        <span className={styles.priceLabel}>NET</span>
        <Price
          size={"medium"}
          value={details.netPrice.value}
          currency={details.netPrice.currency}
          fractionDigits={0}
        />
      </div>
      <div className={styles.priceDetail}>
        <span className={styles.priceLabel}>GROSS</span>
        <Price
          size={"medium"}
          value={details.grossPrice.value}
          currency={details.grossPrice.currency}
          fractionDigits={0}
        />
      </div>
    </>
  );
};

const PriceForClientView = (details: ClientViewPrice) => {
  return (
    <div className={styles.priceDetail}>
      <Price
        size={"medium"}
        value={details.grossPrice.value}
        currency={details.grossPrice.currency}
        fractionDigits={0}
      />
    </div>
  );
};

const PriceWithButton = (details: PriceWithBookButton) => {
  return (
    <>
      <Price
        size={"medium"}
        value={details.price.value}
        currency={details.price.currency}
        fractionDigits={0}
      />
      <Button
        size={"regular"}
        onClick={() => Promise.resolve(details.onClickBook())}
      >
        Book room
      </Button>
    </>
  );
};

const RoomRateItemSkeleton = () => {
  return (
    <div className={styles.roomRow}>
      <Skeleton className={styles.skeletonThumbnail} />
      <div className={styles.informationColumn}>
        <Skeleton className={styles.skeletonFull} />
        <Skeleton className={styles.skeletonHalf} />
      </div>
      <div className={styles.sleepColumn}>
        <Skeleton className={styles.skeletonSleeps} />
        <Skeleton className={styles.skeletonSleeps} />
      </div>
      <div>
        <Skeleton className={styles.skeletonFull} />
        <Skeleton className={styles.skeletonHalf} />
      </div>
      <div>
        <Skeleton className={styles.skeletonFull} />
        <Skeleton className={styles.skeletonHalf} />
      </div>
      <div className={styles.ratesColumn}>
        <Price />
        <Skeleton className={styles.skeletonHalf} />
      </div>
    </div>
  );
};
