import { formatDistance } from "date-fns";

import {
  AgentBookingData,
  BackOfficeUserBookingData,
} from "@adl-gen/hotel/api";
import { AdultTitle, RoomOccupancyDetails } from "@adl-gen/hotel/booking";
import {
  calculatePaymentDue,
  formatLocalDate,
  generateBookingGuestsStringFromOccupancy,
  renderAddress,
} from "@util/agentus-utis";

import { getBookingLabel } from "../utils";
import { BOOKING_LIVE_STATUS_MAP } from "./constant";
import { AgentBookingInfo, BackofficeBookingInfo, BookingInfo } from "./types";

export function extractAgentBookingInfo(
  booking: AgentBookingData
): AgentBookingInfo {
  return {
    ...extractBaseBookingInfo(booking),
    bookingPrice: booking.bookingPrice,
  };
}

export function extractBackofficeBookingInfo(
  booking: BackOfficeUserBookingData
): BackofficeBookingInfo {
  return {
    ...extractBaseBookingInfo(booking),
    bookingPrice: booking.bookingPrice,
    taxesAndFees: booking.taxesAndFees,
  };
}

function extractBaseBookingInfo(
  booking: AgentBookingData | BackOfficeUserBookingData
): BookingInfo {
  return {
    bookingId: booking.bookingData.bookingId,
    bookingNumber: booking.bookingData.bookingNumber,
    bookingLabel: getBookingLabel(
      booking.bookingData.liveStatus,
      booking.bookingData.clientPaymentStatus,
      booking.bookingData.isInstantPurchase,
      booking.bookingData.bookingNumber
    ),
    checkIn: formatLocalDate(booking.bookingData.checkInDate),
    checkOut: formatLocalDate(booking.bookingData.checkOutDate),
    guests: generateBookingGuestsStringFromOccupancy(
      booking.bookingData.rooms,
      { omitNumRooms: true }
    ),
    hotelAddress: renderAddress(booking.bookingData.hotelDetails.address),
    hotelName: booking.bookingData.hotelDetails.hotelName,
    leadGuest: getLeadGuest(booking.bookingData.rooms),
    lastUpdated: getLastUpdated(booking.bookingData.lastUpdated),
    paymentDue: `${formatLocalDate(
      calculatePaymentDue(booking.bookingData.paymentDueDate)
    )} by 23:59`,
    liveStatusLabel: BOOKING_LIVE_STATUS_MAP[booking.bookingData.liveStatus],
    liveStatus: booking.bookingData.liveStatus,
    clientPaymentStatus: booking.bookingData.clientPaymentStatus,
    supplierPaymentStatus: booking.bookingData.supplierPaymentStatus,
    isCancellationFailed: booking.bookingData.isCancellationFailed,
    isRefundable: !!booking.bookingData.cancellationPolicy.policies[0]
      ?.cancelFromDate,
    paidOn: booking.bookingData.paidOn
      ? formatLocalDate(new Date(booking.bookingData.paidOn))
      : "",
    isInstantPurchase: booking.bookingData.isInstantPurchase,
    agentReference: booking.bookingData.agentReference,
    agentEmail: booking.bookingData.userEmail,
  };
}

/**
 * Gets the name of the first adult in the list of rooms. This matches the logic to find the lead passenger when
 * sending the booking to the supplier
 */
function getLeadGuest(rooms: RoomOccupancyDetails[]): string {
  for (const room of rooms) {
    if (room.adults.length > 0) {
      const { title, lastName, firstName } = room.adults[0];
      return `${AdultTitle[title]} ${firstName} ${lastName}`;
    }
  }

  return "";
}

function getLastUpdated(dateInMs: number): string {
  return formatDistance(dateInMs, new Date(), {
    addSuffix: true,
  });
}
