import clsx from "clsx";
import type { FC } from "react";
import React, { useMemo } from "react";

import layout from "@common/layout-lib.css";
import BackofficePaymentBadge from "@widgets/itinerary/booking/backoffice-payment-info/backoffice-payment-badge";
import type { BackofficeBookingInfo } from "@widgets/itinerary/booking/types";
import itineraryStyles from "@widgets/itinerary/itinerary.css";
import { Price } from "@widgets/price/price";

import styles from "./styles.css";
import {
  getBackofficeClientPaymentLabel,
  getBackofficeSupplierPaymentLabel,
} from "./utils";

interface Props {
  booking: BackofficeBookingInfo;
  collapsed?: boolean;
}

const BackofficePaymentInfo: FC<Props> = ({ booking, collapsed }) => {
  const { bookingPrice, lastUpdated } = booking;
  const { netPriceWithTax, grossPriceWithTax, supplierPrice, commission } =
    bookingPrice;

  const clientPaymentLabel = useMemo(
    () => getBackofficeClientPaymentLabel(booking),
    [booking]
  );

  const supplierPaymentLabel = useMemo(
    () => getBackofficeSupplierPaymentLabel(booking),
    [booking]
  );

  return (
    <div className={styles.backofficePaymentInfo}>
      <div
        className={clsx(styles.paymentBadges, collapsed && styles.collapsed)}
      >
        <div>
          {supplierPaymentLabel && (
            <BackofficePaymentBadge type="Supplier" {...supplierPaymentLabel} />
          )}
        </div>
        <div>
          {clientPaymentLabel && (
            <BackofficePaymentBadge type="Client" {...clientPaymentLabel} />
          )}
        </div>
      </div>

      <div
        className={clsx(
          styles.backofficePrices,
          collapsed && styles.collapsed,
          layout.vertical,
          layout.justified
        )}
      >
        <div className={clsx(layout.horizontal, layout.bottom)}>
          <div className={clsx(itineraryStyles.label, styles.priceLabel)}>
            Supplier
          </div>
          <Price
            className={styles.priceValue}
            size={"medium"}
            value={supplierPrice.value}
            currency={supplierPrice.currency}
          />
        </div>
        <div className={clsx(layout.horizontal, layout.bottom)}>
          <div className={clsx(itineraryStyles.label, styles.priceLabel)}>
            Net
          </div>
          <Price
            className={styles.priceValue}
            size={"medium"}
            value={netPriceWithTax.value}
            currency={netPriceWithTax.currency}
          />
        </div>
        <div className={clsx(layout.horizontal, layout.bottom)}>
          <div className={clsx(itineraryStyles.label, styles.priceLabel)}>
            Gross
          </div>
          <Price
            className={styles.priceValue}
            size={"medium"}
            value={grossPriceWithTax.value}
            currency={grossPriceWithTax.currency}
          />
        </div>
        <div className={clsx(layout.horizontal, layout.bottom)}>
          <div className={clsx(itineraryStyles.label, styles.priceLabel)}>
            Com ({commission.percentage}%)
          </div>
          <Price
            className={styles.priceValue}
            size={"medium"}
            value={commission.amount.value}
            currency={commission.amount.currency}
          />
        </div>
        <span className={clsx(itineraryStyles.label, styles.lastUpdated)}>
          Last updated {lastUpdated}
        </span>
      </div>
    </div>
  );
};

export default BackofficePaymentInfo;
