import clsx from "clsx";
import React, { useEffect, useRef } from "react";

import styles from "./styles.css";
import { CustomDropdownProps } from "./types";

const CustomDropdown = ({
  isOpen,
  setIsOpen,
  children,
  inner,
  className,
}: CustomDropdownProps): JSX.Element => {
  // Ref to handle the click outside
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Close the dropdown if the user clicks outside of the component
  useEffect(() => {
    const clickOutsideHandler = (event) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", clickOutsideHandler);

    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
    };
  }, [wrapperRef]);

  return (
    <div className={clsx(styles.dropdownWrapper, className)}>
      {children}
      {isOpen && (
        <div ref={wrapperRef} className={styles.dropdown}>
          {inner}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
