import React from "react";

import layout from "@common/layout-lib.css";
import { OptionKey } from "@src/ui/widgets/input-multi-select/types";
import InputMultiSelect from "@widgets/input-multi-select";

import advancedSearchStyles from "../styles.css";
import styles from "./styles.css";

export interface Props<Status> {
  title: string;
  placeholder: string;
  renderOptions: { key: Status; label: string }[];
  onSelectOption(val: Status, checked: boolean): void;
  value?: Status[];
}

const StatusDropdown = <Status extends OptionKey>({
  title,
  placeholder,
  renderOptions,
  onSelectOption,
  value,
}: Props<Status>) => {
  return (
    <div className={layout.vertical}>
      <span className={advancedSearchStyles.inputLabel}>{title}</span>
      <InputMultiSelect
        options={renderOptions}
        selectedOptions={value === undefined ? [] : value}
        onOptionSelect={onSelectOption}
        placeholder={placeholder}
        classes={{ container: styles.statusDropdown }}
      />
    </div>
  );
};

export default StatusDropdown;
