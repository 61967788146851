import classnames from "classnames";
import React from "react";

import { BaseProps } from "../../common/base";

import styles from "./input-lookup-option.css";

/**
 * Properties for InputLookupOption component.
 */
export interface InputLookupOptionProps
  extends BaseProps,
    React.InputHTMLAttributes<HTMLButtonElement> {
  /** Text to be displayed */
  value: string;
  /** Components to be placed inside the dropdown panel */
  children?: string | JSX.Element | JSX.Element[];
  /** Internal callback when an option is selected */
  onClickInternal?(event: React.MouseEvent<HTMLButtonElement>): void;
}

/**
 * A lookup option which is passed to an InputLookup component.
 *
 * @author James Millar
 */
export const InputLookupOption = (props: InputLookupOptionProps) => {
  const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    // If there is an onSelect listener
    if (props.onClick) {
      props.onClick(event);
    }

    // If there is an onClickInternal listener
    if (props.onClickInternal) {
      props.onClickInternal(event);
    }
  };

  return (
    <button
      key={props.value}
      className={classnames(styles.inputLookupOption, props.className)}
      onClick={clickHandler}
      value={props.value}
    >
      {props.children}
    </button>
  );
};
