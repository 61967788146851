import ReactDOM from "react-dom";

import { LeftArrowSvg, RightArrowSvg } from "@common/icon/icons-svg";

/**
 * Nativley set the value of an input element. This function is a variant
 * based on: https://stackoverflow.com/questions/42550341/react-trigger-onchange-if-input-value-is-changing-by-state
 * Its primary use case is to enable triggering of a native 'change' event
 * which is not triggered within react when a inputs value is programatically
 * set.
 *
 * @param element The native HTML input element on which the value is to be set.
 * @param value The value to be applied to the input element.
 *
 * @author James Millar
 */

export function setNativeValue(element: HTMLInputElement, value: string) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, "value")?.set;

  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    "value"
  )?.set;

  // tslint:disable-next-line: strict-comparisons
  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}

export function replaceDatepickerNavigationIcons(
  datepickerElement: HTMLDivElement
) {
  const prevButton = datepickerElement.querySelector(
    "[class*=navigation--previous]"
  );
  const nextButton = datepickerElement.querySelector(
    "[class*=navigation--next]"
  );

  if (prevButton) {
    ReactDOM.render(LeftArrowSvg, prevButton);
  }

  if (nextButton) {
    ReactDOM.render(RightArrowSvg, nextButton);
  }
}

export function scrollToTop() {
  window.scrollTo({ top: 0, left: 0 });
}

// Based on https://stackoverflow.com/a/7557433
export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
