import React, { FC, memo } from "react";
import Linkify from "react-linkify";

import { ItineraryCommentData } from "@adl-gen/hotel/api";
import { formatLocalDate } from "@util/agentus-utis";
import { COMMENTS_TABLE_DATE_FORMAT } from "@widgets/itinerary/comments";
import ModalWindow from "@widgets/modal-window";

import styles from "./styles.css";

interface Props {
  comment: ItineraryCommentData;
  onClose(): void;
}

const CommentInfoModal: FC<Props> = ({ comment, onClose: closeModal }) => {
  const { created, text, bookingNumbers } = comment;
  return (
    <ModalWindow className={styles.commentInfoModal} open onClose={closeModal}>
      <div className={styles.commentInfo}>
        <div className={styles.commentHeader}>
          <div className={styles.title}>Comment</div>
          <div className={styles.date}>
            {formatLocalDate(new Date(created), COMMENTS_TABLE_DATE_FORMAT)}
          </div>
        </div>

        <div className={styles.commentText}>
          <Linkify>{text}</Linkify>
        </div>

        <div className={styles.bookingList}>
          <div className={styles.title}>Booking Ids</div>
          {bookingNumbers.map((bookingId) => (
            <div key={bookingId}>#{bookingId}</div>
          ))}
        </div>
      </div>
    </ModalWindow>
  );
};

export default memo(CommentInfoModal);
