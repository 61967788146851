import clsx from "clsx";
import React, { FC } from "react";

import layout from "@common/layout-lib.css";
import { AgencyManageProperties } from "@pages/settings/types";
import Input from "@widgets/input";

import styles from "./styles.css";

const Commission: FC<AgencyManageProperties> = ({
  formInputChangeHandler,
  agencyDetails,
  getFieldError,
  fieldsWithError,
  requiredFields,
}) => {
  return (
    <div className={clsx(layout.vertical, styles.commission)}>
      <Input
        name={"commissionPercentage"}
        label={"% Default Commission"}
        value={agencyDetails?.value.commissionPercentage}
        onChange={formInputChangeHandler}
        type="number"
        required={requiredFields?.includes("commissionPercentage")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "commissionPercentage")
        }
      />
    </div>
  );
};

export default Commission;
