import clsx from "clsx";
import React, { FC, useState } from "react";

import { Button } from "@widgets/button/button";
import { Checkbox } from "@widgets/checkbox/checkbox";
import PriceConfig, {
  CommonPriceConfigProps,
} from "@widgets/payment-form/price-config";

import { BANK_TRANSFER_INFO, CONDITIONS_AGREEMENT_TEXT } from "../constants";
import paymentStyles from "../payment-form.css";
import styles from "./styles.css";

interface PropTypes extends CommonPriceConfigProps {
  onBankTransferPay(): void;
}

const BankTransferPayment: FC<PropTypes> = ({
  onBankTransferPay,
  ...priceProps
}) => {
  const [conditionsAgreed, setConditionsAgreed] = useState(false);

  return (
    <div className={styles.bankTransferLayout}>
      <div className={styles.bankTransfer}>
        <table className={styles.paymentProperties}>
          <tbody>
            <tr className={styles.row}>
              <td className={styles.title}>Bank Name:</td>
              <td className={styles.value}>NAB</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.title}>Account Name:</td>
              <td className={styles.value}>Agentus</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.title}>Account Number:</td>
              <td className={styles.value}>1234-5678</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.title}>Swift Code:</td>
              <td className={styles.value}>NATAAU3303M</td>
            </tr>
            <tr className={styles.row}>
              <td className={styles.title}>Currency:</td>
              <td className={styles.value}>
                {priceProps.grossAmount.currency}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PriceConfig {...priceProps} />
      <div className={clsx(paymentStyles.row, paymentStyles.info)}>
        *{BANK_TRANSFER_INFO}
      </div>
      <hr />
      <div className={paymentStyles.row}>
        <Checkbox
          size="small"
          className={paymentStyles.checkbox}
          text={CONDITIONS_AGREEMENT_TEXT}
          multiline={true}
          checked={conditionsAgreed}
          onChange={setConditionsAgreed}
        />
      </div>
      <div className={paymentStyles.row}>
        <Button
          className={paymentStyles.payNowButton}
          onClick={onBankTransferPay}
          disabled={!conditionsAgreed}
        >
          Book and Hold
        </Button>
      </div>
    </div>
  );
};

export default BankTransferPayment;
