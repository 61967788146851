import html2canvas from "html2canvas";
import React, { ReactElement } from "react";
import { render, unmountComponentAtNode } from "react-dom";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { compose, withProps } from "recompose";

import { markerIcon } from "@assets/svg";
import { GoogleMapKey } from "@constants/common";

import styles from "./styles.css";

interface Props {
  location: { lat: number; lng: number };
  onMapLoaded(): void;
}

const MapTemplate = compose<Props, Props>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GoogleMapKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div className={styles.mapTemplateContainer} />,
    containerElement: <div className={styles.mapTemplateContainer} />,
    mapElement: <div id="map-template" style={{ height: "100%" }} />,
  }),
  withScriptjs,
  withGoogleMap
)(({ location, onMapLoaded }) => {
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={location}
      onTilesLoaded={onMapLoaded}
      options={{
        disableDefaultUI: true,
        gestureHandling: null,
        keyboardShortcuts: false,
      }}
      defaultClickableIcons={false}
    >
      <Marker position={location} options={{ icon: markerIcon }} />
    </GoogleMap>
  );
});

function generateCanvas(resolve: (canvas) => void, container: HTMLElement) {
  return async () => {
    const targetDiv = container.firstChild;

    if (targetDiv) {
      const canvas = await html2canvas(targetDiv as HTMLElement, {
        useCORS: true,
        scale: 1.1
      });

      unmountComponentAtNode(container);

      resolve(canvas);
    }
  };
}

function createMapSnapshot(location: {
  lat: number;
  lng: number;
}): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const container = document.getElementById("offscreen-map-container");

    if (!container) {
      reject("Cannot find a container for offscreen google map");
      return;
    }

    const map = (
      <MapTemplate
        location={location}
        onMapLoaded={generateCanvas(resolve, container)}
      />
    );

    render(map, container);
  });
}

function createComponentSnapshot(
  component: ReactElement
): Promise<HTMLCanvasElement> {
  return new Promise((resolve, reject) => {
    const container = document.getElementById("offscreen-map-container");

    if (!container) {
      reject("Cannot find a container for offscreen snapshot");
      return;
    }

    render(component, container);

    return generateCanvas(resolve, container)();
  });
}

export { createMapSnapshot, createComponentSnapshot };
