import clsx from "clsx";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";

import { GeneralContext } from "@app/app";
import layout from "@common/layout-lib.css";
import { ROUTE_PATH } from "@constants/common";
import { UserController } from "@controllers/user/user-controller";
import { yupResolver } from "@hookform/resolvers/yup";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { ResendInviteForm, SetPasswordForm } from "@pages/invite-page/types";
import { AgentusLoader } from "@widgets/agentus-loader/agentus-loader";
import { Button } from "@widgets/button/button";
import Input from "@widgets/input";
import { Logo } from "@widgets/logo/logo";

import {
  ResendInviteSchema,
  SetPasswordDefaultValues,
  SetPasswordSchema,
  SetResendInviteDefaultValues,
} from "./constant";
import styles from "./styles.css";

interface Props {
  userController: UserController;
}

const InvitePage: FC<Props> = ({ userController }) => {
  const [initialCheck, setInitialCheck] = useState<
    "loading" | "success" | "error"
  >("loading");

  const { pathname } = useLocation();
  const history = useHistory();

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const inviteHash = useMemo(() => {
    return pathname.substring(pathname.lastIndexOf("/") + 1);
  }, [pathname]);

  const { validateInviteHash, inviteUser, confirmInvite } = userController;

  const passwordForm = useForm({
    resolver: yupResolver(SetPasswordSchema),
    defaultValues: SetPasswordDefaultValues,
  });

  const resendInviteForm = useForm({
    resolver: yupResolver(ResendInviteSchema),
    defaultValues: SetResendInviteDefaultValues,
  });

  useEffect(() => {
    validateHash(inviteHash);
  }, []);

  async function validateHash(hash: string) {
    try {
      await validateInviteHash(hash);

      setInitialCheck("success");
    } catch (e) {
      setInitialCheck("error");
    }
  }

  async function setPassword({ password }: SetPasswordForm) {
    try {
      await confirmInvite(inviteHash, password);

      history.push(ROUTE_PATH.Login);
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unable set new password, please contact support.",
      });
    }
  }

  async function resendInvite({ email }: ResendInviteForm) {
    try {
      await inviteUser(email);

      addNotification({
        type: NotificationTypeEnum.Success,
        text: "The invite link has been sent, please check your email.",
      });
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: e.publicMessage,
      });
    }
  }

  function renderContent() {
    switch (initialCheck) {
      case "success": {
        const { handleSubmit, formState, register } = passwordForm;

        return (
          <form
            className={clsx(layout.vertical, styles.content)}
            onSubmit={handleSubmit(setPassword)}
          >
            <Logo className={styles.logo} />
            <Input
              {...register("password")}
              label="Password"
              type="password"
              error={formState.errors?.password?.message}
            />
            <Input
              {...register("confirmPassword")}
              label="Confirm Password"
              type="password"
              error={formState.errors?.confirmPassword?.message}
            />

            <Button className={styles.submitButton} type="submit">
              Submit
            </Button>
          </form>
        );
      }
      case "error": {
        const { register, formState, handleSubmit } = resendInviteForm;
        return (
          <div className={clsx(layout.vertical, styles.content)}>
            <Logo className={styles.logo} />
            <span className={styles.expirationText}>
              Your invite link has expired. Please enter your email, we will
              send another one.
            </span>

            <form
              className={layout.vertical}
              onSubmit={handleSubmit(resendInvite)}
            >
              <Input
                {...register("email")}
                error={formState.errors?.email?.message}
                label="Email"
                type="email"
              />

              <Button className={styles.submitButton} type="submit">
                Submit
              </Button>
            </form>
          </div>
        );
      }
      default: {
        return <AgentusLoader />;
      }
    }
  }

  return <div className={styles.invitePage}>{renderContent()}</div>;
};

export default InvitePage;
