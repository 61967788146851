import React, { FC, memo, useState } from "react";

import { GetItineraryResp } from "@adl-gen/hotel/api";
import { Loading } from "@models/loading";
import { PaymentTotal } from "@widgets/itinerary/types";

import TermsAndConditionsModal from "../termsAndConditionsModal";
import ItineraryTotals from "./itinerary-totals";
import styles from "./styles.css";

interface Props {
  totals: PaymentTotal[];
  totalNet: number;
  totalGross: number;
  totalCommissions: number;
  itinerary?: Loading<GetItineraryResp>;
}

const PaymentTotals: FC<Props> = ({
  totals,
  totalNet,
  totalGross,
  totalCommissions,
  itinerary,
}) => {
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  return (
    <div className={styles.paymentsTotals}>
      <div className={styles.paymentsTotalsTitle}>Payment Totals</div>
      <table className={styles.paymentsTable}>
        <thead>
          <tr>
            <th>Date & Time</th>
            <th>Description</th>
            <th>Net</th>
            <th>Gross</th>
            <th>Commissions</th>
          </tr>
        </thead>

        {!!totals.length && (
          <tbody>
            {totals.map(
              (
                { dateTime, description, net, gross, commission, currency },
                index
              ) => (
                <tr key={`${dateTime}_${index}`}>
                  <td>{dateTime}</td>
                  <td>{description}</td>
                  <td>{`${net} ${currency}`}</td>
                  <td>{`${gross} ${currency}`}</td>
                  <td>{`${commission} ${currency}`}</td>
                </tr>
              )
            )}

            <tr className={styles.summary}>
              <td />
              <td>Total Payments</td>
              <td>{`${totalNet} ${totals[0].currency}`}</td>
              <td>{`${totalGross} ${totals[0].currency}`}</td>
              <td>{`${totalCommissions} ${totals[0].currency}`}</td>
            </tr>
          </tbody>
        )}
      </table>

      <ul className={styles.paymentsList}>
        {totals.map(
          (
            { dateTime, description, net, gross, commission, currency },
            index
          ) => (
            <li key={`${dateTime}_${index}`}>
              <div>
                <span>{dateTime}</span>
                <span>
                  <strong>Net: </strong>
                  {`${net} ${currency}`}
                </span>
              </div>

              <div>
                <span>{description}</span>
                <span>
                  <strong>Gross: </strong>
                  {`${gross} ${currency}`}
                </span>
              </div>

              <div>
                <span>{description}</span>
                <span>
                  <strong>Commission: </strong>
                  {`${commission} ${currency}`}
                </span>
              </div>
            </li>
          )
        )}

        {totals.length ? (
          <li className={styles.summary}>
            <div>
              <span>
                <strong>Total Payments</strong>
              </span>

              <span>
                <strong>Net: </strong>
                {`${totalNet} ${totals[0].currency}`}
              </span>
            </div>

            <div>
              <span></span>
              <span>
                <strong>Gross: </strong>
                {`${totalGross} ${totals[0].currency}`}
              </span>
            </div>

            <div>
              <span></span>
              <span>
                <strong>Commission: </strong>
                {`${totalCommissions} ${totals[0].currency}`}
              </span>
            </div>
          </li>
        ) : null}
      </ul>

      {!totals.length && (
        <div className={styles.emptyData}>No bookings selected</div>
      )}

      <div className={styles.totalPrices}>
        <div className={styles.termsAndConditions} onClick={() => setIsTermsModalOpen(true)}>
          Read Full Booking Terms and Conditions
        </div>
        {!!itinerary && <ItineraryTotals currentItinerary={itinerary} />}
      </div>

      <TermsAndConditionsModal
        open={isTermsModalOpen}
        onClose={() => setIsTermsModalOpen(false)}
      />
    </div>
  );
};

export default memo(PaymentTotals);
