import clsx from "clsx";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { PackageInfo, PaymentDetails } from "@adl-gen/hotel/api";
import { HotelSearchContext, LoggedInContext } from "@app/app";
import { BaseProps } from "@common/base";
import { PackageParams } from "@controllers/hotel-search/types";
import { assertNotUndefined } from "@hx/util/types";

import {
  PriceWithAction,
  RoomRate,
  RoomRateItem,
} from "@widgets/room-rate-listing/room-rate-item/room-rate-item";
import styles from "./room-rate-listing.css";

export interface RoomInfo extends BaseProps {
  kind: "roomInfo";
  room?: {
    roomPackage: PackageInfo;
    paymentDetails: PaymentDetails;
  };
}

export interface ListingInfo extends BaseProps {
  kind: "listingInfo";
  roomPackageList?: PackageInfo[];
  onSelectPackage: SelectPackageHandler;
}

type SelectPackageHandler = (
  params: Omit<PackageParams, "selectedFrom">
) => void;

type RoomRateListingProps = (RoomInfo | ListingInfo) & {
  collapsed?: boolean;
  fullWidth?: boolean;
};

const SCROLL_ITEMS_NUMBER_LIMIT = 5;
const UNRENDER_CONTENTS_TIMEOUT = 1000;
const GRID_HEAD_HEIGHT = 56;

/**
 * Displays a list of room rates for a hotel with an option to display detailed
 * pricing for a single room package, or pricing with a Book button for a list
 * of room packages.
 */
export const RoomRateListing = (props: RoomRateListingProps) => {
  const { fullWidth = true } = props;
  const collapseTimeout = useRef<number>();

  const [renderContent, setRenderContent] = useState(false);

  // Used for better performance - since page has many instances of this component
  // we increase rendering speed by setting display=none to the collapsed ones
  useEffect(() => {
    if (props.collapsed) {
      collapseTimeout.current = window.setTimeout(() => {
        setRenderContent(false);
        setHeight(0);
      }, UNRENDER_CONTENTS_TIMEOUT);
    } else {
      setRenderContent(true);
      clearTimeout(collapseTimeout.current);
    }
  }, [props.collapsed]);

  useEffect(() => {
    return () => {
      clearTimeout(collapseTimeout.current);
    };
  }, []);

  const list: Array<PackageInfo | undefined> =
    props.kind === "listingInfo"
      ? props.roomPackageList
        ? props.roomPackageList
        : Array<undefined>(3).fill(undefined)
      : [props.room?.roomPackage];

  const { stayDetails, selectedHotel } = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const { sensitiveDataHidden } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  /**
   * Used to track the height of the content grid
   */
  const [height, setHeight] = useState(0);

  const mapPriceForSinglePackage = (
    paymentDetails: PaymentDetails
  ): PriceWithAction => {
    return sensitiveDataHidden
      ? {
          kind: "clientViewPrice",
          grossPrice: paymentDetails.grossPriceWithTax,
        }
      : {
          kind: "priceDetailsOnly",
          grossPrice: paymentDetails.grossPriceWithTax,
          netPrice: paymentDetails.netPriceWithTax,
        };
  };

  const mapPriceForPackageInList = (
    room: PackageInfo,
    onSelect: SelectPackageHandler
  ): PriceWithAction => {
    return {
      kind: "priceWithBookButton",
      price: room.price,
      onClickBook: () =>
        // TODO: support multi room selection
        onSelect({
          roomId: room.roomKeys[0],
          hotelId: selectedHotel!,
          board: room.board,
          bed: room.bedConfigurations[0],
          cancellation: room.cancellationPolicy,
          packageId: room.packageId,
        }),
    };
  };

  const groupedPackagesBySameRoom = useMemo(() => {
    // Return skeleton if data is missing
    if (props.kind === "roomInfo" && !props.room) {
      return [[undefined]];
    }

    // Return skeleton if data is missing
    if (props.kind === "listingInfo" && !props.roomPackageList) {
      return [[undefined], [undefined], [undefined]];
    }

    const map = new Map<string, RoomRate[]>();

    list.forEach((item: PackageInfo) => {
      const bed = item.bedConfigurations[0];
      const name = item.name;

      const key = bed + name;

      const rate: RoomRate = {
        roomPackage: item,
        price:
          props.kind === "listingInfo"
            ? mapPriceForPackageInList(item, props.onSelectPackage)
            : mapPriceForSinglePackage(props.room!.paymentDetails),
      };
      if (map.has(key)) {
        map.set(key, map.get(key)!.concat([rate]));
      } else {
        map.set(key, [rate]);
      }
    });

    return Array.from(map.values());
  }, [list, sensitiveDataHidden]);

  const listOverflows =
    groupedPackagesBySameRoom.length > SCROLL_ITEMS_NUMBER_LIMIT;

  /**
   * Used to reference the grid body
   */
  const gridBodyRef = useRef<HTMLDivElement>(null);

  /**
   * Get the height of the body container in order to dynamically set the height
   * of the body. This cannot be achieved with CSS as the height of the body is
   * unknown
   */
  useEffect(() => {
    if (!renderContent || props.collapsed) {
      return;
    }

    if (gridBodyRef.current !== null) {
      // If number of rooms exceeds scroll limit, we
      // want to dynamically count the max height of the grid
      if (listOverflows) {
        // If the element has an even index, it's room element and we need to add margin value to height,
        // otherwise, it's an <hr> tag with the fixes margin value
        const rowsHeights: number[] = Array.from(
          gridBodyRef.current.children
        ).map((node, index) =>
          index % 2 === 0 ? node.getBoundingClientRect().height + 16 : 16
        );

        const totalMaxHeight =
          rowsHeights.slice(0, 10).reduce((acc, value) => acc + value, 0) +
          GRID_HEAD_HEIGHT;

        setHeight(totalMaxHeight);
      } else {
        const href = gridBodyRef.current.getBoundingClientRect().height;
        setHeight(href + GRID_HEAD_HEIGHT);
      }
    }
  }, [renderContent, groupedPackagesBySameRoom]);

  return (
    <div
      className={clsx(
        styles.roomRateListing,
        fullWidth && styles.fullWidth,
        props.kind === "listingInfo" && styles.collapsible,
        props.className,
        props.collapsed && styles.collapsed
      )}
      style={{ maxHeight: height }}
      id="room-rate-listing"
    >
      {props.kind === "listingInfo" ? (
        <div className={styles.gridHead}>
          <span className={clsx(styles.headerItem, styles.headerRoomInfo)}>
            Room Information
          </span>
          <span className={clsx(styles.headerItem, styles.headerItemSleeps)}>
            Sleeps
          </span>
          <span className={styles.headerItem}>Cancellation policy</span>
          <span className={styles.headerItem}>Inclusions</span>
          <span className={styles.headerItem}>
            <span className={styles.headerItemRates}>Rates</span>
          </span>
        </div>
      ) : null}
      <div
        className={styles.gridBody}
        ref={gridBodyRef}
        style={renderContent ? undefined : { display: "none" }}
      >
        {groupedPackagesBySameRoom.length > 0 ? (
          groupedPackagesBySameRoom.map((rates, index) => {
            return rates.includes(undefined) ? (
              <RoomRateItem kind="skeleton" key={index} />
            ) : (
              <React.Fragment key={rates[0].roomPackage.name}>
                <RoomRateItem
                  kind="value"
                  rates={rates}
                  checkIn={stayDetails.checkIn}
                />
                {index !== groupedPackagesBySameRoom.length - 1 ? (
                  <hr className={styles.divider} />
                ) : null}
              </React.Fragment>
            );
          })
        ) : (
          <div className={styles.noPackagesMessage}>No packages available</div>
        )}
      </div>
    </div>
  );
};
