import { useEffect, useState } from "react";

import { WithDbId } from "../../adl-gen/common/db";
import { makeTableQuery } from "../../adl-gen/common/tabular";
import { DeclResolver } from "../../adl-gen/runtime/adl";
import { AdminService } from "../service";
import { AdminHrefFactory, createTableMetadata, Loading, mapDbValue, Metadata, TableMetadata, TSRow } from "../utils";


export interface PageStateParams {
  service: AdminService,
  appDeclResolver: DeclResolver,
  hrefFactory: AdminHrefFactory,
  table: string;
  id: string;
  metadata: Metadata;
}

export interface PageState {
  tmetadata: TableMetadata;
  table: string;
  id: string;

  value: Loading<ValueState>;
  dbError: string|null;

  clearDbError(): void;
  saveValue(v: WithDbId<TSRow>): Promise<void>;
}

export interface ValueLoaded {
    value: WithDbId<TSRow>;
    veditorState: unknown;
};

export type ValueState = "notfound" | ValueLoaded;


export function usePageState(props: PageStateParams): PageState {
  // Create state
  const [value,setValue] = useState<Loading<ValueState>>({kind:"loading"});
  const [dbError, setDbError] = useState<string|null>(null);
  const tmetadata = createTableMetadata(props.metadata, props.table, props.hrefFactory, false);

  function setPageState(v: WithDbId<TSRow>) {
    const veditorState = tmetadata.veditor.stateFromValue(v.value);
    const pageState = {
      value: v,
      veditorState
    }
    setValue({kind:'ready', value:pageState})
  }

  async function load() {

    const page = await props.service.adminQuery({
        table: tmetadata.table.name,
        columns: tmetadata.table.columns.map(c => c.name),
        query: makeTableQuery({
          filter: {
            kind:'equalTo', value:{
              expr1: {kind:'field', value:'id'},
              expr2: {kind:'string', value:props.id}
            }
          }
        })
    });
    if (page.items.length !== 1) {
      setValue({kind:'ready', value:"notfound"})
    }
    const v = mapDbValue(tmetadata.tsRowFromDbRow, page.items[0]);
    setPageState(v);
  }

  useEffect(() => {
    void load();
  }, [props.table, props.id]);

  async function saveValue(v: WithDbId<TSRow>): Promise<void> {
    const dbresult = await props.service.adminUpdate({
      table: tmetadata.table.name,
      values: mapDbValue(tmetadata.dbRowFromTsRow, v),
    });
    if (dbresult.kind === "ok") {
      setPageState(v);
    } else {
      setDbError(dbresult.value);
    }
  }

  function clearDbError() {
    setDbError(null);
  }
  

  return {tmetadata, table:props.table, id:props.id, value, dbError, clearDbError, saveValue};
}