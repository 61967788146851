import React, { FC, useCallback, useContext } from "react";

import { HotelSearchContext } from "@app/app";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import { ViewHotelProps } from "@controllers/hotel-search/types";
import { assertNotUndefined } from "@hx/util/types";
import { getNightsFromRange } from "@util/agentus-utis";
import Pagination from "@widgets/pagination";

import { AvailableHotelPackages } from "../../hotel-search-page";
import resultsStyles from "../styles.css";
import HotelListCard from "./hotel-list-card";
import styles from "./styles.css";

interface PropTypes {
  hotelList: HotelListingResultWithId[];
  viewDetailsHandler(hotelId: string, options: ViewHotelProps): void;
  viewRatesHandler(hotelId: string): void;
}

export const ListView: FC<PropTypes> = ({
  hotelList,
  viewDetailsHandler,
  viewRatesHandler,
}) => {
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const {
    setPageNumber,
    stayDetails,
    currentPage,
    totalHotelsAvailable,
  } = hotelSearchController;

  const handlePageChange = useCallback(
    (page: number) => {
      setPageNumber(page);
    },
    [setPageNumber]
  );

  return (
    <div>
      {hotelList.map((hotel) => (
        <div key={hotel.id} className={styles.hotelResult}>
          <HotelListCard
            id={hotel.id}
            details={hotel.details}
            onClickViewDetails={(options: ViewHotelProps) =>
              viewDetailsHandler(hotel.id, options)
            }
            onClickViewRates={() => viewRatesHandler(hotel.id)}
            nights={getNightsFromRange(
              stayDetails.checkIn,
              stayDetails.checkOut
            )}
            pax={stayDetails.rooms[0]}
          />

          <AvailableHotelPackages
            {...hotelSearchController}
            collapsed={hotelSearchController.selectedHotel !== hotel.id}
          />
        </div>
      ))}

      {!hotelList.length && (
        <p className={resultsStyles.noData}>No hotels available</p>
      )}

      {!!hotelList.length && (
        <Pagination
          currentPage={currentPage}
          totalCount={totalHotelsAvailable}
          pageSize={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default ListView;
