import React, { FC } from "react";

import { Price } from "@widgets/price/price";
import { HotelListingResultDetails } from "@models/hotel";

interface PropTypes {
  hotelDetails: HotelListingResultDetails;
}

const PriceDetails: FC<PropTypes> = ({ hotelDetails }) => {
  if (hotelDetails.priceDetails.kind === "value") {
    if (hotelDetails.priceDetails.value.kind === "available") {
      const priceDetails = hotelDetails.priceDetails.value;
      const rate = priceDetails.lowestAverageNightlyRate;
      const currency = priceDetails.currency;
      return <Price value={`${rate}`} currency={currency} fractionDigits={0} />;
    } else {
      return <div>Sold out for selected dates</div>;
    }
  } else if (hotelDetails.priceDetails.kind === "loading") {
    return <Price />; // do display price skeleton
  } else {
    return <div>No price data found</div>;
  }
};

export default PriceDetails;
