import moment from "moment";
import React from "react";

import { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import {
  CalendarIcon,
  SuccessOutlinedIcon,
  Wallet,
  WarningOutlinedIcon,
} from "@assets/svg-components";
import { COMMON_DATE_FORMAT } from "@constants/common";
import { getEnumValueKeyByIndex } from "@src/util/agentus-utis";
import { trackAndLogError } from "@util/error-handling";
import { BadgeColor } from "@widgets/badge/badge";
import type {
  AgentPaymentLabel,
  BookingInfo,
} from "@widgets/itinerary/booking/types";

export function getAgentPaymentLabel(
  booking: BookingInfo
): AgentPaymentLabel | null {
  const {
    clientPaymentStatus,
    liveStatus,
    paymentDue,
    isCancellationFailed,
    isRefundable,
    isInstantPurchase,
    paidOn,
  } = booking;

  const paymentDueMoment = moment(paymentDue, COMMON_DATE_FORMAT);

  const isPaymentDueToday = paymentDueMoment.isSame(moment(), "day");

  switch (liveStatus) {
    case LiveStatus.quote:
      if (isInstantPurchase) {
        return {
          label: "Pay to Confirm",
          color: BadgeColor.Grey,
          icon: <CalendarIcon />,
          date: null,
        };
      }
      return {
        label: "Confirm & Pay By",
        color: BadgeColor.Grey,
        icon: <CalendarIcon />,
        date: paymentDue,
      };
    case LiveStatus.draft:
    case LiveStatus.unavailable:
      return null;
    case LiveStatus.cancelled: {
      if (isCancellationFailed) {
        return null;
      }

      switch (clientPaymentStatus) {
        case ClientPaymentStatus.unpaid:
        case ClientPaymentStatus.cancelled: {
          return null;
        }
        case ClientPaymentStatus.underPaid:
        case ClientPaymentStatus.paid: {
          if (isRefundable) {
            return {
              label: "Refund Due",
              date: null,
              color: BadgeColor.LightBlue,
              icon: <Wallet />,
            };
          } else {
            if (!isInstantPurchase) {
              return {
                label: "Refund Due",
                date: null,
                color: BadgeColor.LightBlue,
                icon: <Wallet />,
              };
            }
            return {
              label: "Paid",
              date: paidOn,
              color: BadgeColor.Green,
              icon: <SuccessOutlinedIcon />,
            };
          }
        }
        case ClientPaymentStatus.refundDue: {
          return {
            label: "Refund Due",
            date: null,
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        }
        default: {
          logError(liveStatus, clientPaymentStatus, booking.bookingNumber);
          return {
            label: "Pending",
            date: null,
            color: BadgeColor.Yellow,
            icon: <Wallet />,
          };
        }
      }
    }
    case LiveStatus.confirmed: {
      switch (clientPaymentStatus) {
        case ClientPaymentStatus.underPaid:
        case ClientPaymentStatus.unpaid:
          return {
            label: isPaymentDueToday ? "Payment due today" : "Payment due",
            date: paymentDue,
            color: isPaymentDueToday ? BadgeColor.Red : BadgeColor.Yellow,
            icon: isPaymentDueToday ? <WarningOutlinedIcon /> : <Wallet />,
          };
        case ClientPaymentStatus.paidViaCredit:
        case ClientPaymentStatus.paid:
          return {
            label: "Paid On",
            date: paidOn,
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        case ClientPaymentStatus.paymentSubmitted:
          return {
            label: "Payment Submitted On",
            // TODO: use payment submission date instead
            date: paymentDue,
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        case ClientPaymentStatus.refundDue:
          return {
            label: "Refund Due",
            date: null,
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        case ClientPaymentStatus.commissionDue:
          return {
            label: "Commission Due",
            date: null,
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        default: {
          logError(liveStatus, clientPaymentStatus, booking.bookingNumber);
          return {
            label: "Pending",
            date: null,
            color: BadgeColor.Yellow,
            icon: <Wallet />,
          };
        }
      }
    }
    case LiveStatus.pendingConfirmation:
      return {
        label: "Payment due now",
        date: "—",
        color: BadgeColor.Red,
        icon: <WarningOutlinedIcon />,
      };
    case LiveStatus.travelled: {
      switch (clientPaymentStatus) {
        case ClientPaymentStatus.settled:
          return {
            label: "Settled",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
            date: null,
          };
        case ClientPaymentStatus.commissionDue:
        case ClientPaymentStatus.paid:
          return {
            label: "Paid On",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
            date: paidOn,
          };
        default: {
          logError(liveStatus, clientPaymentStatus, booking.bookingNumber);

          return {
            label: "Paid On",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
            date: paidOn,
          };
        }
      }
    }
  }
}

function logError(
  liveStatus: LiveStatus,
  clientPaymentStatus: ClientPaymentStatus,
  bookingNumber: string
) {
  const additionalInfo = {
    liveStatus: getEnumValueKeyByIndex(liveStatus, LiveStatus),
    clientPaymentStatus: getEnumValueKeyByIndex(
      clientPaymentStatus,
      ClientPaymentStatus
    ),
    bookingNumber
  }
  const errorMsg = "An error occurred while calculating the agent payment label during the label mapping process due to unknown conditions.";
  trackAndLogError(errorMsg, additionalInfo);
}
