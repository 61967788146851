import clsx from "clsx";
import React, { FC, useMemo } from "react";

import { FilledStarIcon, HalfStarIcon, StarIcon } from "@common/icon/icons";
import { MAX_RATING_STARS } from "@constants/common";

import styles from "./styles.css";

export interface PropTypes {
  starRating: number;
  size?: "xs" | "sm";
  classname?: string;
}

const StarRating: FC<PropTypes> = ({ starRating, size = "sm", classname }) => {
  const hotelStarRating =
    starRating > MAX_RATING_STARS ? MAX_RATING_STARS : starRating;
  const halfStarPosition = Number.isInteger(starRating)
    ? null
    : Math.ceil(starRating);

  const starIcons: JSX.Element[] = useMemo(() => {
    const stars: JSX.Element[] = [];

    for (let index = 1; index <= MAX_RATING_STARS; index++) {
      if (halfStarPosition && index === halfStarPosition) {
        stars.push(
          <HalfStarIcon
            key={`star-${index}`}
            size={size}
            className={clsx(
              styles.starIcon,
              styles.halfStar,
              size === "xs" && styles.xs
            )}
          />
        );
      } else {
        if (index <= hotelStarRating) {
          stars.push(
            <FilledStarIcon
              key={`star-${index}`}
              size={size}
              className={styles.starIcon}
            />
          );
        } else {
          stars.push(
            <StarIcon
              key={`star-${index}`}
              size={size}
              className={styles.starIcon}
            />
          );
        }
      }
    }
    return stars;
  }, [hotelStarRating]);

  return (
    <div className={clsx(classname, styles.ratingSection)}>{starIcons}</div>
  );
};

export default StarRating;
