const NAME = "filter";

const APPEND = 0;
const REMOVE = 1;

type Action = 0 | 1;

/**
 * Object that accepts a URLSearchParams object and provides functions
 * to modify the the filter parameter.
 *
 * NB This will modify the URLSearchParams object.
 *
 * NB Because URLSearchParams does not provide a mechanism to handle multi
 * keyed properties, editing multi keyed properties requiures a full
 * recreation of the propertys when they are edited.
 */

export class URLFilterParams {
  private readonly params: URLSearchParams;

  constructor(params: URLSearchParams) {
    this.params = params;
  }

  /**
   * Returns an array of values for the provided key,
   * or null when the key does not exist.
   */

  get(key: string): null | string[] {
    const filter = this.params.getAll(NAME).find((current) => {
      const _split = current.split(",");
      return _split.length > 0 && _split[0] === key;
    });

    return filter ? filter.split(",").slice(1) : null;
  }

  /**
   * Returns true if the value exists within the filter property
   * identified by the key.
   */

  includes(key: string, value: string) {
    const filterValues = this.get(key);

    return (filterValues && filterValues.includes(value)) || false;
  }

  /**
   * Modifies the value inline with the action for the filter
   * identified by the key. This is an internal function and not intended
   * to be used directly.
   */

  set(key: string, value: string, action: Action) {
    const filters = this.params.getAll(NAME);

    this.params.delete(NAME);

    // Flag to check the non-existant case
    let handled = false;

    filters.forEach((current) => {
      const filterValues = current.split(",");

      // If the current filter matches the current filter component
      if (filterValues.length > 0 && filterValues[0] === key) {
        // If we are appending and the value does not exist
        if (action === APPEND && filterValues.indexOf(value) === -1) {
          // Push the value
          filterValues.push(value);
        }
        // If we are removing and the value does exist
        else if (action === REMOVE && filterValues.indexOf(value) !== -1) {
          // Remove the value
          filterValues.splice(filterValues.indexOf(value), 1);
        }

        // If there is at least one value
        if (filterValues.length > 1) {
          // Push the updated filter onto the params
          this.params.append(NAME, filterValues.join(","));
        }

        // We have handled the specific filter
        handled = true;
      } else {
        // Push the unmodified filter onto the params
        this.params.append(NAME, current);
      }
    });

    // If not handle it is the non-existant case, build the first instance
    if (action === APPEND && !handled) {
      this.params.append(NAME, [key, value].join(","));
    }
  }

  /**
   * Appends the value to the filter identified by the key
   */

  append(key: string, value: string): void {
    this.set(key, value, APPEND);
  }

  /**
   * Removes the value from the filter identified by the key
   */

  remove(key: string, value: string): void {
    this.set(key, value, REMOVE);
  }
}
