import * as React from "react";

import { Loading } from "./utils";


export function renderLoading<T>(lt: Loading<T>, renderfn: (t: T) => JSX.Element): JSX.Element {
    if (lt.kind === "loading") {
      return <div>loading...</div>;
    } else {
      return renderfn(lt.value);
    }
  }