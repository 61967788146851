import { WithDbId } from "@adl-gen/common/db";
import { Agency } from "@adl-gen/hotel/db";
import { AgencyDetailsTab } from "@pages/settings/agency-details/constants";

export const EMPTY_AGENCY_VALUES: WithDbId<Agency> = {
  id: "",
  value: {
    name: "",
    emailAddress: "",
    phoneNumber: { countryCode: "", number: "" },
    address: {
      line1: "",
      line2: null,
      city: "",
      stateProvinceCode: "",
      stateProvinceName: "",
      postalCode: "",
      countryCode: "",
    },
    timezone: "",
    commissionPercentage: "",
    currency: "",
    keyContactName: "",
    keyContactPhoneNumber: { countryCode: "", number: "" },
    bankAccountDetails: null,
    headOffice: null,
    keyContactEmailAddress: null,
  },
};

export const AGENCY_REQUIRED_FIELDS: { [key: string]: string[] } = {
  [AgencyDetailsTab.GeneralDetails]: ["name", "emailAddress", "currency"],
  [AgencyDetailsTab.ContactDetails]: [
    "keyContactName",
    "address_line1",
    "address_city",
    "address_postalCode",
    "address_stateProvinceName",
    "timezone",
    "keyContactPhoneNumber_countryCode",
    "keyContactPhoneNumber_number",
  ],
  [AgencyDetailsTab.FinancialDetails]: [],
  [AgencyDetailsTab.Commission]: ["commissionPercentage"],
};

export const AGENCY_FORM_EMPTY_ERRORS: { [key: string]: string[] } = {
  [AgencyDetailsTab.GeneralDetails]: [],
  [AgencyDetailsTab.ContactDetails]: [],
  [AgencyDetailsTab.FinancialDetails]: [],
  [AgencyDetailsTab.Commission]: [],
};
