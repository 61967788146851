import clsx from "clsx";
import React, { FC, useMemo } from "react";

import useOutsideClick from "@common/hooks/outside-click";
import { Checkbox, CheckboxProps } from "@widgets/checkbox/checkbox";
import { InputText, InputTextProps } from "@widgets/input-text/input-text";

import styles from "./styles.css";
import { OptionKey, OptionType } from "./types";

interface Props {
  options: OptionType[];
  selectedOptions: OptionKey[];
  onOptionSelect(optionKey: OptionKey, checked: boolean): void;
  placeholder?: string;
  /** Optional additional classes to apply to internal elements */
  classes?: InputTextProps["classes"] & {
    container?: string;
    selectList?: string;
  };
  checkboxClasses?: CheckboxProps["classes"];
}

const InputMultiSelect: FC<Props> = ({
  placeholder,
  options,
  classes = {},
  onOptionSelect,
  selectedOptions,
  checkboxClasses,
}) => {
  const { ref, isOpen, toggleIsOpen } = useOutsideClick<HTMLDivElement>();

  const displayValue = useMemo(() => {
    return selectedOptions
      .map((val) => options.find(({ key }) => key === val)!.label)
      .join(", ");
  }, [options, selectedOptions]);

  return (
    <div
      ref={ref}
      className={clsx(
        styles.inputMultiSelect,
        classes?.container,
        isOpen && styles.open
      )}
    >
      <InputText
        classes={{
          inputText: clsx(
            styles.inputText,
            classes?.inputText,
            isOpen && styles.open
          ),
          input: styles.input,
        }}
        placeholder={placeholder}
        value={selectedOptions.length ? displayValue : undefined}
        onClick={toggleIsOpen}
        readOnly
      />
      {isOpen && (
        <div className={clsx(styles.selectList, classes?.selectList)}>
          {options.map((option, index) => (
            <Checkbox
              className={styles.option}
              key={`${option.key}-${index}`}
              text={option.label}
              checked={selectedOptions.includes(option.key)}
              onChange={(checked) => onOptionSelect(option.key, checked)}
              classes={checkboxClasses}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default InputMultiSelect;
