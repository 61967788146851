import clsx from "clsx";
import React from "react";

import { BaseProps } from "@common/base";
import { SpinnerOverlay } from "@widgets/loader/loader";

import styles from "./button.css";

// Available button sizes as determined by the application visual design
export type ButtonSize = "regular" | "large" | "small";

// Available button emphasis as determined by the application visual design
export type ButtonEmphasis = "high" | "medium" | "low" | "empty";

/**
 * Properties for Button component. Extends the BaseProps and the intrinsic
 * properties for an HTML Button.
 */
export interface ButtonProps
  extends BaseProps,
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  classes?: { button?: string; container?: string };
  /** Indicate the visual size of the button.  */
  size?: ButtonSize;
  /**
   * Indicate the importance of the button within the visual hierarchy.
   * NB This will set the correct visual representation.
   */
  emphasis?: ButtonEmphasis;

  isLoading?: boolean;
}

/**
 * Button component that provides an HTML button that conforms to the style of
 * the application visual design.
 *
 * The component adds a absolute `div.target` that maintains a minimum 44x44px dimension
 * to ensure that the button always meets the WCAG accessibility target
 * size critera. This makes it possible to have a target area that is larger
 * than the visual area of the button.
 *
 * The component wraps the children in a div to gracefully handle overflow
 * when button width is reduced below the content width. This cannot be
 * handled on the outer `button` as the target area needs to overflow the
 * button and would be clipped by the overflow handling.
 *
 * @author James Millar
 */
export const Button = ({
  className,
  classes,
  children,
  size = "regular",
  emphasis = "high",
  isLoading,
  disabled,
  ...props
}: ButtonProps) => {
  // Aggregation of class names for root element
  const buttonClasses = clsx(
    styles.button,
    size !== "regular" && styles[size],
    emphasis !== "high" && styles[emphasis],
    classes?.button,
    className
  );

  return (
    <button
      className={buttonClasses}
      disabled={isLoading || disabled}
      {...props}
    >
      <div className={styles.target} />
      <div className={clsx(styles.container, classes?.container)}>
        {isLoading ? (
          <SpinnerOverlay type="local" withBackdrop={false} />
        ) : (
          children
        )}
      </div>
    </button>
  );
};
