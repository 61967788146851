import { assertNever, assertNotUndefined } from "@hx/util/types";
import clsx from "clsx";
import { debounce } from "lodash";
import type { FC, FormEvent } from "react";
import React, { memo, useContext, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import type { BookingSearchQuery, BookingSearchQueryResp } from "@adl-gen/hotel/api";
import type { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import layout from "@common/layout-lib.css";
import { ROUTE_PATH } from "@constants/common";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import type {
  BookingSearchController,
  BookingSearchType,
} from "@controllers/booking-search/booking-search-controller";
import { GeneralPage } from "@layouts/page-layout/general-page";
import { mapLoading } from "@models/loading";
import AdvancedSearchOptions from "@pages/booking-search/advanced-search-options";
import RefinedSearch from "@pages/booking-search/refined-search";
import { AppUserType } from "@src/adl-gen/hotel/db";
import { LoggedInContext } from "@src/app/app";
import { getOffsetFromPageNumber } from "@util/pagination";
import { Button } from "@widgets/button/button";
import { NewBookingButton } from "@widgets/button/new-booking-button";
import Clearable, { createClearHandler } from "@widgets/clearable";
import Input from "@widgets/input";
import Booking, {
  BookingSearchResultContext,
} from "@widgets/itinerary/booking";
import { SpinnerOverlay } from "@widgets/loader/loader";
import Pagination from "@widgets/pagination";
import TabOption from "@widgets/tab-option";
import Tabs from "@widgets/tabs";

import BookingSearchAction from "./booking-search-action";
import styles from "./booking-search.css";

const ClearableInput = Clearable(Input)

export interface BookingSearchProps {
  controller: BookingSearchController;
  searchType: BookingSearchType;
}

/** Booking search page */
const BookingSearch: FC<BookingSearchProps> = (props) => {
  const history = useHistory();

  return (
    <GeneralPage
      items={["Booking"]}
      sibling={
        <NewBookingButton onClick={() => history.push(ROUTE_PATH.Search)} />
      }
    >
      <BookingSearchForm
        controller={props.controller}
        searchType={props.searchType}
      />
    </GeneralPage>
  );
};

/** Renders the booking search form inputs. */
const BookingSearchForm: FC<BookingSearchProps> = ({
  controller,
  searchType,
}) => {
  const history = useHistory();
  const location = useLocation();

  const { searchParams, setQueryField, doSearch, searchResult, currentPage } =
    controller;

  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  const debouncedFieldUpdate = useRef(debounce(setQueryField, 200));

  /** Updates the appropriate field by parsing the form event for the input element. */
  const changeHandler = (event: FormEvent<HTMLInputElement>): void => {
    debouncedFieldUpdate.current(
      event.currentTarget.name as keyof BookingSearchQuery,
      event.currentTarget.value
    );
  };


  type QueryField = "clientPaymentStatuses" | "liveStatuses";

  function clearField<T extends keyof BookingSearchQuery>(name: T) {
    return () => {
      setQueryField(name, undefined);
    };
  }


  const statusesChangeHandler = <T extends QueryField>(
    option: BookingSearchQuery[T][number],
    queryField: T,
    checked: boolean
  ): void => {
    const params = new URLSearchParams(location.search);
    const currentValue = searchParams[queryField];

    let updatedValue: (typeof option)[];

    if (currentValue) {
      updatedValue = checked
        ? [...currentValue, option]
        : // @ts-ignore
          currentValue.filter(
            (val: BookingSearchQuery[T][number]) => val !== option
          );
    } else {
      updatedValue = [option];
    }

    setQueryField(queryField, updatedValue as BookingSearchQuery[T]);

    if (updatedValue.length) {
      params.set(queryField, JSON.stringify(updatedValue));
    } else {
      params.delete(queryField);
    }

    location.search = params.toString();
    history.replace(location);
  };

  const paymentStatusesChangeHandler = (
    option: ClientPaymentStatus,
    checked: boolean
  ) => {
    return statusesChangeHandler(option, "clientPaymentStatuses", checked);
  };

  const liveStatusesChangeHandler = (option: LiveStatus, checked: boolean) => {
    return statusesChangeHandler(option, "liveStatuses", checked);
  };

  const ErrorMessage = (args: { error: string }) => (
    <div>{`Error: ${args.error}`}</div>
  );

  const LoadingBookings = () => {
    const searchData = assertNotUndefined(searchResult);

    const bookingIds = mapLoading(
      searchData,
      (resp: BookingSearchQueryResp) => {
        if (resp.kind === "backOfficeUserBookingData") {
          return resp.value.items.map((data) => data.bookingData.bookingId);
        } else if (resp.kind === "agentBookingData") {
          return resp.value.items.map((data) => data.bookingData.bookingId);
        } else {
          assertNever(resp);
        }
      }
    );
    if (bookingIds.kind === "loading") {
      return <SpinnerOverlay />;
    } else if (bookingIds.kind === "error") {
      return <ErrorMessage {...bookingIds} />;
    } else if (bookingIds.kind === "value") {
      return (
        <BookingSearchResultContext.Provider
          value={{ searchResult: searchData }}
        >
          <div className={clsx(layout.vertical, layout.spaced)}>
            {bookingIds.value.map((current) => {
              return (
                <Booking
                  key={current}
                  bookingId={current}
                  actionComponent={BookingSearchAction}
                />
              );
            })}
            {!bookingIds.value.length && (
              <div className={styles.emptyData}>No data</div>
            )}

            {searchData.kind === "value" && (
              <Pagination
                currentPage={currentPage}
                totalCount={searchData.value.value.total_size}
                pageSize={ITEMS_PER_PAGE}
                onPageChange={(page) =>
                  doSearch({
                    offset: getOffsetFromPageNumber(page, ITEMS_PER_PAGE),
                  })
                }
              />
            )}
          </div>
        </BookingSearchResultContext.Provider>
      );
    } else {
      assertNever(bookingIds);
    }
  };


  return (
    <div className={clsx(layout.vertical, styles.booking)}>
      <div className={clsx(styles.searchTabs)}>
        <Tabs>
          <TabOption href={ROUTE_PATH.Bookings} className={styles.firstTab}>
            Basic Search
          </TabOption>
          <TabOption href={ROUTE_PATH.BookingsAdvanced}>
            Advanced Search
          </TabOption>
        </Tabs>
      </div>

      <div className={styles.basicSearch}>
        <div>
          <ClearableInput
            name="itineraryNumber"
            label="Itinerary ID"
            value={searchParams.itineraryNumber || ""}
            onChange={changeHandler}
            placeholder="Itinerary ID"
            type="text"
            onClear={createClearHandler("itineraryNumber", searchParams, clearField)}
          />
          <ClearableInput
            name="bookingNumber"
            label="Booking Number"
            value={searchParams.bookingNumber || ""}
            onChange={changeHandler}
            placeholder="Booking Number"
            type="text"
            onClear={createClearHandler("bookingNumber", searchParams, clearField)}
          />
          <ClearableInput
            name="agentRef"
            label="Your reference"
            value={searchParams.agentRef || ""}
            onChange={changeHandler}
            placeholder="Your Reference"
            onClear={createClearHandler("agentRef", searchParams, clearField)}
          />
        </div>
        <div>
          <ClearableInput
            name="destinationName"
            label="Accommodation Name"
            value={searchParams.destinationName || ""}
            onChange={changeHandler}
            placeholder="Accommodation Name"
            onClear={createClearHandler("destinationName", searchParams, clearField)}
          />
          <ClearableInput
            name="firstName"
            label="Traveller First Name"
            value={searchParams.firstName || ""}
            onChange={changeHandler}
            placeholder="Traveller First Name"
            onClear={createClearHandler("firstName", searchParams, clearField)}
          />
          <ClearableInput
            name="lastName"
            label="Last Name"
            value={searchParams.lastName || ""}
            onChange={changeHandler}
            placeholder="Last Name"
            onClear={createClearHandler("lastName", searchParams, clearField)}
          />
        </div>
      </div>

      {searchType === "advanced" && (
        <AdvancedSearchOptions
          changeHandler={changeHandler}
          onToggleLiveStatus={liveStatusesChangeHandler}
          onTogglePaymentStatus={paymentStatusesChangeHandler}
          restrictPaymentStatusVisibility={
            userProfile?.appUser.value.userType !== AppUserType.backOffice
          }
          setQueryField={setQueryField}
          searchParams={searchParams}
        />
      )}
      <div className={clsx(layout.horizontal, layout.right)}>
        <Button
          className={styles.searchButton}
          onClick={(_e) => doSearch({ offset: 0 })}
        >
          Search
        </Button>
      </div>

      {searchType === "advanced" && <RefinedSearch />}

      {searchResult && <LoadingBookings />}
    </div>
  );
};

export default memo(BookingSearch);
