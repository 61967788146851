import React, { FC, useMemo } from "react";

import { GetItineraryResp } from "@adl-gen/hotel/api";
import { Loading } from "@models/loading";

import { ITINERARY_TOTALS_OPTIONS } from "../../constants";
import styles from "./styles.css";

interface Props {
  currentItinerary: Loading<GetItineraryResp>;
}

const ItineraryTotals: FC<Props> = ({ currentItinerary }) => {
  if (
    currentItinerary.kind !== "value" ||
    currentItinerary.value.kind !== "itinerary"
  ) {
    return null;
  }

  const itineraryValue = useMemo(
    () =>
      (currentItinerary.value.kind === "itinerary" &&
        currentItinerary.value.value) ||
      {},
    [currentItinerary]
  );

  const totalPrices = useMemo(
    () => ITINERARY_TOTALS_OPTIONS.filter(({ key }) => itineraryValue[key]),
    [itineraryValue]
  );

  return (
    <div className={styles.priceSummary}>
      {totalPrices.map(
        ({ key, label }) =>
          itineraryValue[key] && (
            <div key={key} className={styles.priceItem}>
              <div className={styles.priceItemTitle}>{label}</div>
              <div className={styles.priceItemValue}>
                <div className={styles.priceValue}>
                  {Math.ceil(Math.abs(parseFloat(itineraryValue[key].value)))}
                </div>
                <div className={styles.currency}>
                  {itineraryValue[key].currency}
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default ItineraryTotals;
