import React, { useContext } from "react";

import { AppUserType } from "@adl-gen/hotel/db";
import { LoggedInContext } from "@app/app";
import { ROUTE_PATH } from "@constants/common";
import { assertNotUndefined } from "@hx/util/types";
import { checkUserAccessLevel } from "@util/agentus-utis";
import QuickSearch from "@widgets/page/quick-search";

import { Logo } from "../logo/logo";
import HeaderBreadCrumbs from "./header-breadcrumbs";
import styles from "./header.css";
import { NavPrimary } from "./nav-primary";
import { PageSection } from "./page-section";
import ProfileMenu from "./profile-menu";

/** Props to display a text input */

export const Header = (): JSX.Element => {
  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  return (
    <PageSection
      className={styles.header}
      classes={styles}
      tag="header"
      pad={false}
    >
      <a href={ROUTE_PATH.Root}>
        <Logo className={styles.logo} />
      </a>
      <HeaderBreadCrumbs />
      {checkUserAccessLevel(userProfile, AppUserType.backOffice) && (
        <QuickSearch />
      )}
      <NavPrimary userProfile={userProfile} classes={styles} />
      <ProfileMenu />
    </PageSection>
  );
};
