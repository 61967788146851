import clsx from "clsx";
import moment from "moment";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { keys } from "ts-transformer-keys";

import {
  isElementInViewport,
  replaceDatepickerNavigationIcons,
} from "@util/native-dom";

import { Icons } from "../icon/icons";
import { InputText, InputTextProps } from "../input-text/input-text";
import styles from "./input-date.css";

const DATE_FORMAT = "d MMM yyyy";

/**
 * Component Props
 * The props for this component extend from ReactDatePickerProps and
 * InputTextProps. Where props clash between these two Components the
 * ReactDatePickerProps are privileged to expose the features provided by the
 * react-datepicker library. An exception to this however is the onChange prop
 * which is typed against the InputTextProps which in turn extends the
 * intrinsic onChange from a primitive input. This is to better mimic how
 * an `input type="date"` functions.
 */
export interface InputDateProps
  extends Omit<ReactDatePickerProps, "onChange">,
    Omit<InputTextProps, keyof Omit<ReactDatePickerProps, "onChange">> {
  classes?: { input?: string; inputDate?: string; inputText?: string };
  disablePast?: boolean;
  disableFuture?: boolean;
}

/**
 * Text Input Component intended to be a replacement for the
 * `input type="date"` element. Utilises the react-datepicker library and the
 * Index component as the input.
 *
 * @author James Millar
 */
export const InputDate = ({
  onChange,
  disablePast,
  disableFuture,
  ...props
}: InputDateProps): JSX.Element => {
  const [date, setDate] = useState<Date | null>(props.selected || null);
  const [opened, setIsOpened] = useState(false);

  const [positionClassname, setPositionClassname] = useState("");
  const textInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  if (textInputRef.current) {
    textInputRef.current.readOnly = true;
  }

  function toggle() {
    if (!opened && containerRef.current) {
      fixPositionIfNotFullyVisible(containerRef.current);
    }
    setIsOpened((prevState) => !prevState);
  }

  function close({ target }: MouseEvent<HTMLDivElement>) {
    const clickInput = !!textInputRef.current?.contains(target as Node);

    if (clickInput) {
      return;
    }

    setIsOpened(false);
  }

  const changeHandler = (value: Date | null, _event) => {
    if (onChange) {
      /* This is a hack to put the `name` and the `value` into the event
       * because react-datepicker does not correctly create the
       * event.target.name or event.target.value property. The library provides
       * an onChangeRaw method to expose the underlying event however this also
       * is not set correctly hence this workaround.
       */
      _event.target.name = props.name;
      _event.target.value = value ? value.toLocaleDateString() : value;
      onChange(_event);
    }
    setDate(value ? new Date(value) : value);
  };

  // Effect fires when uncontrolled side effects change the value, i.e. by clicking clear icon
  useEffect(() => {
    if (!props.value) {
      setDate(null);
    }
  }, [props.value]);

  /**
   * Takes an array of prop keys and returns an props object containing only
   * the matching items.
   */
  function reduceProps<T>(acceptedKeys: string[]) {
    const result = {};
    acceptedKeys.forEach(
      (current) =>
        props[current] !== undefined && (result[current] = props[current])
    );
    return result as T;
  }

  // Extract the pass through props for the ReactDatePicker
  const reactDatePickerProps: Omit<
    ReactDatePickerProps,
    "onChange"
  > = reduceProps(keys<ReactDatePickerProps>());

  // Extract the pass through props for the Index
  const inputTextProps: Omit<InputTextProps, "onChange"> = reduceProps(
    keys<InputTextProps>()
  );

  // If placeholderText has not been passed in and placeholder has
  if (!props.placeholderText && props.placeholder) {
    // Map the placeholder prop to the ReactDatePicker placeholderText prop
    reactDatePickerProps.placeholderText = props.placeholder;
  }

  const classes = props.classes || {};

  useEffect(() => {
    if (containerRef.current) {
      replaceDatepickerNavigationIcons(containerRef.current);
    }
  }, []);

  // Adjusting datepicker position if it goes beyond the viewport borders
  function fixPositionIfNotFullyVisible(el: HTMLDivElement) {
    const isFullyVisible = isElementInViewport(el);
    if (isFullyVisible) {
      setPositionClassname("");
      return;
    }

    const viewport = window.innerWidth;

    const left = Math.abs(el.getBoundingClientRect().left);
    const right = Math.abs(viewport - el.getBoundingClientRect().right);

    if (right > left) {
      setPositionClassname(styles.alignLeft);
    } else {
      setPositionClassname(styles.alignRight);
    }
  }

  if (disablePast) {
    reactDatePickerProps.minDate = moment(new Date()).toDate();
  }

  if (disableFuture) {
    reactDatePickerProps.maxDate = moment(new Date()).toDate();
  }

  return (
    <div className={styles.inputDate}>
      <InputText
        label
        iconBefore={Icons.schedule}
        ref={textInputRef}
        {...inputTextProps}
        onClick={toggle}
      />

      <div
        className={clsx(
          styles.calendarWrapper,
          opened && styles.opened,
          positionClassname
        )}
        ref={containerRef}
      >
        <DatePicker
          className={clsx(styles.inputDate, classes.inputDate, props.className)}
          dateFormat={DATE_FORMAT}
          selected={date}
          onChange={changeHandler}
          onMonthChange={() =>
            replaceDatepickerNavigationIcons(
              containerRef.current as HTMLDivElement
            )
          }
          onCalendarOpen={() =>
            replaceDatepickerNavigationIcons(
              containerRef.current as HTMLDivElement
            )
          }
          inline
          onClickOutside={close}
          {...reactDatePickerProps}
        />
      </div>
    </div>
  );
};
