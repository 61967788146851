import clsx from "clsx";
import React, { FC } from "react";

import layout from "@common/layout-lib.css";
import { getAgentPaymentLabel } from "@widgets/itinerary/booking/agent-payment-info/utils";
import { AgentBookingInfo } from "@widgets/itinerary/booking/types";

import paymentBadgeStyles from "../../payment-badge.styles.css";
import styles from "./styles.css";

interface Props {
  booking: AgentBookingInfo;
  collapsed?: boolean;
}

const AgentPaymentBadge: FC<Props> = ({ booking, collapsed }) => {
  const displayData = getAgentPaymentLabel(booking);

  if (!displayData) {
    return null;
  }
  const { label, color, icon, date } = displayData;

  return (
    <div
      className={clsx(
        styles.root,
        paymentBadgeStyles.paymentBadge,
        paymentBadgeStyles[color],
        collapsed && styles.collapsed
      )}
    >
      <div className={paymentBadgeStyles.pictureContainer}>{icon}</div>
      <div className={clsx(layout.vertical, layout.bottom)}>
        <span className={clsx(paymentBadgeStyles.title, styles.title)}>
          {label}
        </span>
        <div className={clsx(paymentBadgeStyles.text, styles.text)}>{date}</div>
      </div>
    </div>
  );
};

export default AgentPaymentBadge;
