import React from "react";

import styles from "./agentus-loader.css";

const svg = require("./agentus-loader.svg");

/**
 * Component that displays a centered animated loader
 *
 * @author James Millar
 */
export const AgentusLoader = (): JSX.Element => {
  return (
    <img
      className={styles.agentusLoader}
      src={svg.default ? svg.default : svg}
      data-testid="agentus-loader"
    />
  );
};
