import clsx from "clsx";
import React, { FC } from "react";

import layout from "@common/layout-lib.css";
import AgentPaymentBadge from "@widgets/itinerary/booking/agent-payment-info/agent-payment-badge";
import { AgentBookingInfo } from "@widgets/itinerary/booking/types";
import itineraryStyles from "@widgets/itinerary/itinerary.css";
import { Price } from "@widgets/price/price";

import styles from "./styles.css";

interface Props {
  booking: AgentBookingInfo;
  collapsed?: boolean;
}

const AgentPaymentInfo: FC<Props> = ({ booking, collapsed }) => {
  const { bookingPrice, lastUpdated } = booking;

  const { netPriceWithTax, grossPriceWithTax } = bookingPrice;

  return (
    <div
      className={clsx(styles.agentPaymentInfo, collapsed && styles.collapsed)}
    >
      <AgentPaymentBadge booking={booking} collapsed={collapsed} />

      <div
        className={clsx(
          styles.agentPrices,
          layout.vertical,
          layout.justified,
          collapsed && styles.collapsed
        )}
      >
        <div className={clsx(layout.horizontal, layout.bottom)}>
          <div className={clsx(itineraryStyles.label, styles.priceLabel)}>
            NET
          </div>
          <Price
            size={"medium"}
            value={netPriceWithTax.value}
            currency={netPriceWithTax.currency}
          />
        </div>
        <div className={clsx(layout.horizontal, layout.bottom)}>
          <div className={clsx(itineraryStyles.label, styles.priceLabel)}>
            GROSS
          </div>
          <Price
            size={"medium"}
            value={grossPriceWithTax.value}
            currency={grossPriceWithTax.currency}
          />
        </div>
        <span className={clsx(itineraryStyles.label, styles.lastUpdated)}>
          Last updated {lastUpdated}
        </span>
      </div>
    </div>
  );
};

export default AgentPaymentInfo;
