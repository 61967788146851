import React, { FC } from "react";

import { ClientBookingData } from "@adl-gen/hotel/api";
import { formatLocalDate } from "@util/agentus-utis";
import DialogWindow from "@widgets/dialog-window";

import styles from "./styles.css";

interface Props {
  booking: ClientBookingData;
  isOpen: boolean;
  onClose(): void;
  getBookingKey(bookingId: string): string;
}

const BookingDialogWindow: FC<Props> = ({
  booking,
  isOpen,
  onClose,
  getBookingKey,
}) => (
  <DialogWindow
    title="Confirm that you read booking number"
    open={isOpen}
    onOk={onClose}
    okBtnText="Confirm"
  >
    <table className={styles.bookingDialogTable}>
      <tbody>
        <tr className={styles.row}>
          <td className={styles.label}>Booking number:</td>
          <td className={styles.value}>
            {getBookingKey(booking.bookingNumber)}
          </td>
        </tr>
        <tr className={styles.row}>
          <td className={styles.label}>Payment Due:</td>
          <td className={styles.value}>
            {`${formatLocalDate(booking.paymentDueDate)} by 23:59`}
          </td>
        </tr>
      </tbody>
    </table>
  </DialogWindow>
);

export default BookingDialogWindow;
