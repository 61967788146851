import { AdultFullName, ChildFullName } from "@adl-gen/hotel/booking";

export enum NotificationTypeEnum {
  Information = "information",
  Alert = "alert",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export interface NotificationType {
  id: string;
  text: string;
  type: NotificationTypeEnum;
}

export interface NotificationWithoutIdType {
  text: string;
  type: NotificationTypeEnum;
}

export type FullName = AdultFullName | ChildFullName;
