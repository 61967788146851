import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { LoggedInContext } from "@app/app";
import { SearchIconSvg } from "@common/icon/icons-svg";
import { Icons } from "@common/icon/svg";
import { WithAgencyController } from "@controllers/agency-controller";
import { assertNotUndefined } from "@hx/util/types";
import { IconButton } from "@widgets/button/icon-button";
import { InputText } from "@widgets/input-text/input-text";

import parentStyles from "../settings.css";
import CreateEditAgencyModal from "./agency-modal";
import AgencyDetailsButton from "./details-button";
import styles from "./styles.css";

const ManageAgencies: FC<WithAgencyController> = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [filter, setFilter] = useState("");

  const { agencies, fetchAgencies } = assertNotUndefined(
    useContext(LoggedInContext).agencyController
  );

  useEffect(() => {
    fetchAgencies();
  }, []);

  function onChangeFilter(e: ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value);
  }

  const filteredList = useMemo(() => {
    return filter
      ? agencies.filter(({ value }) =>
          value.name.toLowerCase().includes(filter.toLowerCase())
        )
      : agencies;
  }, [filter, agencies]);

  return (
    <div className={parentStyles.settingsTableContainer}>
      <InputText
        className={parentStyles.nameFilter}
        iconBefore={SearchIconSvg}
        placeholder="Agency name"
        value={filter}
        onChange={onChangeFilter}
      />

      <IconButton
        className={parentStyles.addButton}
        onClick={() => setModalOpened(true)}
        iconBefore={Icons.plusCircleFilled}
      >
        Add New Agency
      </IconButton>

      <table className={clsx(parentStyles.settingsTable, styles.agenciesTable)}>
        <thead className={parentStyles.tableHeader}>
          <tr>
            <th className={parentStyles.name}>Name</th>
            <th className={parentStyles.email}>Email</th>
            <th className={parentStyles.commission}>Commission</th>
            <th className={parentStyles.address}>Address</th>
            <th>Menu</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.map((agency, index) => (
            <tr key={index}>
              <td className={parentStyles.name}>
                <span>{agency.value.name}</span>
              </td>
              <td className={parentStyles.email}>
                <span>{agency.value.emailAddress}</span>
              </td>
              <td className={parentStyles.commission}>
                <span>{`${agency.value.commissionPercentage} %`}</span>
              </td>
              <td className={parentStyles.address}>
                {`${agency.value.address.city}, ${agency.value.address.stateProvinceName}, ${agency.value.address.line1}`}
              </td>
              <td>
                <AgencyDetailsButton agency={agency} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <CreateEditAgencyModal isOpen={modalOpened} onClose={() => setModalOpened(false)} />
    </div>
  );
};

export default ManageAgencies;
