import React, { FormEvent, forwardRef, useContext, useState } from "react";

import { WithDbId } from "@adl-gen/common/db";
import { AppUserDetails, UserReq } from "@adl-gen/hotel/api";
import { AppUserType } from "@adl-gen/hotel/db";
import { PhoneNumber } from "@adl-gen/ids/common";
import { GeneralContext, LoggedInContext } from "@app/app";
import { EXISTING_EMAIL_ERROR } from "@controllers/user/constant";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import phoneCountryCodes from "@pages/settings/phone-country-codes.json";
import { CountryCode } from "@pages/settings/types";
import { getFlagUrlByCode } from "@util/agentus-utis";
import { validateString, ValidationRule } from "@util/validation";
import { Button } from "@widgets/button/button";
import Image from "@widgets/image";
import Input from "@widgets/input";
import { InputSelect, InputSelectOption } from "@widgets/input-select";
import ModalWindow from "@widgets/modal-window";

import { ROLE_MAP } from "../../constant";
import { EMPTY_NEW_BACKOFFICE_USER_FORM } from "./constant";
import styles from "./styles.css";
import {
  BackofficeUserModalForm,
  BackofficeUserModalFormErrors,
} from "./types";

interface Props {
  isOpen: boolean;
  onClose(): void;
  user?: WithDbId<AppUserDetails>;
}

const BackofficeUserModal = forwardRef<HTMLFormElement, Props>(
  ({ isOpen, onClose, user }, ref) => {
    if (!isOpen) {
      return null;
    }

    const [loading, setLoading] = useState(false);

    const [userData, setUserData] = useState<BackofficeUserModalForm>(
      user
        ? {
            fullname: user.value.fullname,
            email: user.value.email,
            userType: user.value.userType,
            phoneNumber: user.value.phoneNumber,
          }
        : EMPTY_NEW_BACKOFFICE_USER_FORM
    );

    const [validationErrors, setValidationErrors] = useState<
      BackofficeUserModalFormErrors
    >({});

    const { fullname, email, phoneNumber, userType } = userData;

    const {
      createBackofficeUser: createUser,
      updateBackofficeUser: updateUser,
      fetchUsers,
    } = assertNotUndefined(useContext(LoggedInContext).userController);

    const { userProfile } = assertNotUndefined(
      useContext(LoggedInContext).identityController
    );

    const { addNotification } = assertNotUndefined(
      useContext(GeneralContext).notificationController
    );

    function changeField({ currentTarget }: FormEvent<HTMLInputElement>) {
      const { value, name } = currentTarget;

      setUserData((prevValue) => ({
        ...prevValue,
        [name]: value,
      }));
    }

    function changeUserType(value: number) {
      setUserData((prevValue) => ({
        ...prevValue,
        userType: value as AppUserType,
      }));
    }

    function updatedNestedPhoneField(value: string, name: keyof PhoneNumber) {
      const updated: PhoneNumber = {
        countryCode: phoneNumber?.countryCode ? phoneNumber.countryCode : "",
        number: phoneNumber?.number ? phoneNumber.number : "",
      };

      updated[name] = value;

      setUserData((prevValue) => ({
        ...prevValue,
        phoneNumber: Object.values(updated).some((val) => !!val)
          ? updated
          : null,
      }));
    }

    function changeCountryCode(value: string) {
      updatedNestedPhoneField(value, "countryCode");
    }

    function changeNumber({ currentTarget }: FormEvent<HTMLInputElement>) {
      const { value } = currentTarget;

      updatedNestedPhoneField(value, "number");
    }

    async function createBackofficeUser(e: FormEvent) {
      e.preventDefault();

      if (!userProfile) {
        return;
      }

      const formValid = validate();
      if (!formValid) {
        return;
      }

      setLoading(true);

      try {
        await createUser({
          ...userData,
          agencyId: null,
          commissionPercentage: null,
        });

        onClose();
      } catch (e) {
        addNotification({
          type:
            e.status === EXISTING_EMAIL_ERROR
              ? NotificationTypeEnum.Warning
              : NotificationTypeEnum.Error,
          text: e.publicMessage || e.message,
        });
      }

      setLoading(false);
    }

    async function editBackofficeUser(e: FormEvent) {
      e.preventDefault();

      if (!user) {
        return;
      }

      const formValid = validate();
      if (!formValid) {
        return;
      }

      setLoading(true);

      try {
        const body: WithDbId<UserReq> = {
          id: user.id,
          value: {
            ...userData,
            agencyId: null,
            commissionPercentage: null,
          },
        };

        await updateUser(body);
        await fetchUsers();

        onClose();
      } catch (e) {
        addNotification({
          type:
            e.status === EXISTING_EMAIL_ERROR
              ? NotificationTypeEnum.Warning
              : NotificationTypeEnum.Error,
          text: e.publicMessage || e.message,
        });
      }

      setLoading(false);
    }

    function validate() {
      const nameError = validateString(fullname, ValidationRule.RequiredField);
      const emailError = validateString(email, ValidationRule.Email);

      setValidationErrors({
        fullname: nameError,
        email: emailError,
      });

      return !nameError && !emailError;
    }

    return (
      <ModalWindow className={styles.root} open={isOpen} onClose={onClose}>
        <form
          className={styles.content}
          ref={ref}
          onSubmit={user ? editBackofficeUser : createBackofficeUser}
          onReset={onClose}
        >
          <h2 className={styles.title}>
            {user ? "Edit a Backoffice User" : "Add a New Backoffice User"}
          </h2>

          <Input
            label="name"
            placeholder="Name"
            value={fullname}
            onChange={changeField}
            name="fullname"
            error={validationErrors.fullname}
            required
          />
          <Input
            label="Email"
            placeholder="Email address"
            value={email}
            onChange={changeField}
            name="email"
            error={validationErrors.email}
            required
          />

          <div className={styles.phoneRow}>
            <InputSelect
              label="Phone Number"
              placeholder="Country code"
              value={phoneNumber?.countryCode || ""}
              onChange={changeCountryCode}
            >
              {(phoneCountryCodes as CountryCode[]).map(
                ({ name, dial_code, code }) => (
                  <InputSelectOption key={name} value={dial_code}>
                    <>
                      {`${dial_code} \(${name}\)`}{" "}
                      <Image
                        className={styles.countryFlag}
                        src={getFlagUrlByCode(code)}
                        alt={`${name} flag`}
                        invertColor
                      />
                    </>
                  </InputSelectOption>
                )
              )}
            </InputSelect>
            <Input
              placeholder="Phone number"
              variant="fullwidth"
              value={phoneNumber?.number || ""}
              onChange={changeNumber}
              type="number"
              name="number"
            />
          </div>

          <InputSelect
            label="Select a Role"
            placeholder="Select a role"
            value={userType}
            onChange={changeUserType}
            displayValue={ROLE_MAP[userType].label}
            required
          >
            <InputSelectOption
              key={`${AppUserType.backOffice}`}
              value={AppUserType.backOffice}
            >
              {ROLE_MAP[AppUserType.backOffice].label}
            </InputSelectOption>
            <InputSelectOption
              key={`${AppUserType.admin}`}
              value={AppUserType.admin}
            >
              {ROLE_MAP[AppUserType.admin].label}
            </InputSelectOption>
          </InputSelect>

          <div className={styles.actions}>
            <Button type="reset" emphasis="medium">
              Close
            </Button>
            <Button type="submit" isLoading={loading}>
              {user ? "Edit User" : "Add User"}
            </Button>
          </div>
        </form>
      </ModalWindow>
    );
  }
);

export default BackofficeUserModal;
