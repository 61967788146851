import { assertNever, assertNotUndefined } from "@hx/util/types";
import clsx from "clsx";
import { LRUCache } from "lru-cache";
import type {
  FC,
  RefObject
} from "react";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import type {
  AgentBookingData,
  BookingSearchQueryResp,
  ClientBookingData,
} from "@adl-gen/hotel/api";
import {
  ClientPaymentStatus,
  LiveStatus,
  PaymentMode,
} from "@adl-gen/hotel/booking";
import { AppUserType } from "@adl-gen/hotel/db";
import { GeneralContext, LoggedInContext } from "@app/app";
import type { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";
import { PaymentType } from "@constants/common";
import { NotificationTypeEnum } from "@models/common";
import { mapLoading } from "@models/loading";
import { downloadFile } from "@src/util/agentus-utis";
import type { PdfCacheType } from "@src/util/voucher";
import { DefaultCacheOptions, actOnVoucherPdf } from "@src/util/voucher";
import BookingDialogWindow from "@widgets/itinerary/booking-dialog-window";
import type {
  AgentSelectedBookingInfo,
  BackofficeSelectedBookingInfo
} from "@widgets/itinerary/booking-selected/types";
import {
  extractAgentSelectedBookingInfo,
  extractBaseSelectedBookingInfo,
  extractSelectedBackofficeBookingInfo,
} from "@widgets/itinerary/booking-selected/utils";
import Comments from "@widgets/itinerary/comments";
import FiltersBar from "@widgets/itinerary/filter-bar";
import ItineraryLogs from "@widgets/itinerary/logs";
import PaymentTotals from "@widgets/itinerary/payment-totals";
import UnavailableItemsModal from "@widgets/itinerary/unavailable-items-modal";
import { getBookingFromStoreById } from "@widgets/itinerary/utils";
import { SpinnerOverlay } from "@widgets/loader/loader";
import { Page } from "@widgets/page/page";
import { PageSection } from "@widgets/page/page-section";
import { PaymentFormMode } from "@widgets/payment-form/constants";
import type {
  StripeCallback,
} from "@widgets/payment-form/payment-form";
import PaymentForm from "@widgets/payment-form/payment-form";

import type { BookingProps } from "./booking";
import Booking, { BookingSearchResultContext } from "./booking";
import BookingSelected from "./booking-selected";
import { ItineraryAction } from "./itinerary-action";
import ItineraryBar from "./itinerary-bar";
import styles from "./itinerary.css";


export const PdfCacheContext = createContext<RefObject<PdfCacheType> | undefined>(undefined);

export const Itinerary: FC<BaseProps> = ({ className }) => {
  const params = useParams<{ id: string }>();
  const context = useContext(LoggedInContext);

  const [paymentMode, setPaymentMode] = useState(PaymentMode.gross);
  const itineraryController = assertNotUndefined(context.itineraryController);
  const { userProfile } = assertNotUndefined(context.identityController);
  const {
    isLoading,
    currentItinerary,
    searchResult,
    selectedBookings,
    paymentType,
    inFlightAmendments,
    loadItineraryNumber,
    toggleBookingSelection,
    generateBookingKey,
    paymentTotals,
    totalSelectedNet,
    totalSelectedGross,
    totalSelectedCommissions,
    payForQuote,
    quoteToPay,
    payForBookAndHold,
    setQuoteToPay,
  } = itineraryController;

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [itemModalOpened, setItemModalOpened] = useState(false);
  const [unavailableModalOpened, setUnavailableModalOpened] = useState(false);

  const itineraryNumber: string = useMemo(() => {
    if (
      currentItinerary !== undefined &&
      currentItinerary.kind === "value" &&
      currentItinerary.value.kind === "itinerary"
    ) {
      return currentItinerary.value.value.itinerary.value.itineraryNumber;
    }

    return "";
  }, [currentItinerary]);

  const lastBooking: ClientBookingData | null = useMemo(() => {
    if (searchResult.kind === "value") {
      const sortedByCreationDate = searchResult.value.value.items.sort(
        (a, b) => b.bookingData.created - a.bookingData.created
      );

      return sortedByCreationDate[0]?.bookingData || null;
    }
    return null;
  }, [searchResult]);

  const bookingIdList: string[] = useMemo(() => {
    if (
      searchResult.kind === "value" &&
      searchResult.value &&
      searchResult.value.kind === "backOfficeUserBookingData"
    ) {
      return searchResult.value.value.items.map(
        (data) => data.bookingData.bookingNumber
      );
    }
    return [];
  }, [searchResult]);

  useEffect(() => {
    if (itineraryNumber) {
      setIsDialogOpen(true);
    }
  }, [itineraryNumber]);

  // Load the itinerary details when the component is first loaded
  useEffect(() => {
    loadItineraryNumber(params.id);
  }, []);

  function toggleManualItemModal() {
    setItemModalOpened((prevState) => !prevState);
  }

  async function submitPayment(stripeCallback: StripeCallback) {
    if (quoteToPay) {
      let error: unknown;

      if (quoteToPay.liveStatus === LiveStatus.quote) {
        error = await payForQuote(
          quoteToPay.bookingId,
          paymentMode,
          stripeCallback
        );
      } else if (
        quoteToPay.liveStatus === LiveStatus.confirmed &&
        quoteToPay.clientPaymentStatus === ClientPaymentStatus.unpaid
      ) {
        error = await payForBookAndHold(
          quoteToPay.bookingId,
          paymentMode,
          stripeCallback
        );
      }

      if (error) {
        setUnavailableModalOpened(true);
      }
    }
  }


  const ErrorMessage = (args: { error: string }) => (
    <div>{`Error: ${args.error}`}</div>
  );

  const LoadingBooking = (booking: BookingProps) => {
    const amendment = inFlightAmendments.get(booking.bookingId);

    if (amendment?.kind === "error") {
      return <ErrorMessage {...amendment} />;
    } else {
      return <Booking key={booking.bookingId} {...booking} />;
    }
  };

  const LoadingBookings = () => {
    const bookingIds = mapLoading(
      searchResult,
      (resp: BookingSearchQueryResp) => {
        if (resp.kind === "backOfficeUserBookingData") {
          return resp.value.items.map((data) => data.bookingData.bookingId);
        } else if (resp.kind === "agentBookingData") {
          return resp.value.items.map((data) => data.bookingData.bookingId);
        } else {
          assertNever(resp);
        }
      }
    );

    if (bookingIds.kind === "value") {
      return (
        <>
          {bookingIds.value.map((current) => {
            return (
              <LoadingBooking
                key={current}
                bookingId={current}
                selected={selectedBookings.includes(current)}
                toggleSelected={toggleBookingSelection}
                actionComponent={ItineraryAction}
                collapsed={!!selectedBookings.length || !!quoteToPay}
              />
            );
          })}
          {!bookingIds.value.length && (
            <div className={styles.noData}>No results in this itinerary</div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const selectedBookingsData = useMemo(() => {
    if (itineraryController.searchResult.kind === "value") {
      return itineraryController.selectedBookings.map((bookingId) => {
        return getBookingFromStoreById<
          AgentSelectedBookingInfo,
          BackofficeSelectedBookingInfo
        >(
          bookingId,
          itineraryController.searchResult,
          extractAgentSelectedBookingInfo,
          extractSelectedBackofficeBookingInfo
        );
      });
    }
    return [];
  }, [selectedBookings]);

  const allDownloadableBookings = useMemo(() => {
    if (searchResult.kind !== "value") {
      return [];
    } else {
      return (searchResult.value.value.items as AgentBookingData[]).filter(
        (booking) =>
          booking.bookingData.liveStatus === LiveStatus.confirmed &&
          booking.bookingData.clientPaymentStatus === ClientPaymentStatus.paid
      );
    }
  }, [searchResult]);

  const selectedDownloadableBookings = useMemo(() => {
    return selectedBookingsData
      .map(([item]) => item)
      .filter(
        ({ liveStatus, clientPaymentStatus }) =>
          liveStatus === LiveStatus.confirmed &&
          clientPaymentStatus === ClientPaymentStatus.paid
      );
  }, [selectedBookings]);



  const pdfCache = useRef<PdfCacheType>(new LRUCache(DefaultCacheOptions));

  const downloadItineraryVoucher = async () => {
    const bookingsToDownload = selectedDownloadableBookings.length
      ? selectedDownloadableBookings
      : allDownloadableBookings.map(extractBaseSelectedBookingInfo);

    const sentNotification = () => addNotification({
      text: "We have created a voucher containing all confirmed and paid bookings",
      type: NotificationTypeEnum.Information,
    });

    const errorNotification = () => addNotification({
      text: "Failed to create voucher",
      type: NotificationTypeEnum.Error,
    })

    await actOnVoucherPdf(
      bookingsToDownload,
      downloadFile,
      pdfCache.current,
      errorNotification,
      sentNotification,
    );
  };

  const itineraryDetails = useMemo(
    () => (
      <>
        <PageSection className={styles.itineraryTopBar}>
          <ItineraryBar
            onClickDownload={downloadItineraryVoucher}
            actionsDisabled={
              !allDownloadableBookings.length ||
              (!!selectedBookings.length && !selectedDownloadableBookings.length)
            }
          />
        </PageSection>
        <PageSection main>
          <div
            className={clsx(
              styles.itinerary,
              className,
              layout.vertical,
              layout.spaced
            )}
          >
            <div className={clsx(layout.vertical, layout.spaced)}>
              <FiltersBar onAddManualItem={toggleManualItemModal} />
              {/*Disabled for MVP https://xllabs.atlassian.net/browse/AG-505*/}
              {/*<ManualItemModal*/}
              {/*  bookings={*/}
              {/*    searchResult.kind === "value"*/}
              {/*      ? (searchResult.value.value*/}
              {/*          .items as AgentBookingData[]).map(*/}
              {/*          ({ bookingData }) => bookingData*/}
              {/*        )*/}
              {/*      : []*/}
              {/*  }*/}
              {/*  open={itemModalOpened}*/}
              {/*  onClose={toggleManualItemModal}*/}
              {/*/>*/}
              <BookingSearchResultContext.Provider value={itineraryController}>
                <div
                  className={clsx(
                    styles.mainContent,
                    (selectedBookings.length || quoteToPay) && styles.split
                  )}
                >
                  <div className={styles.bookingsList}>
                    <LoadingBookings />
                  </div>

                  <UnavailableItemsModal
                    open={unavailableModalOpened}
                    onClose={() => setUnavailableModalOpened(false)}
                    bookings={quoteToPay ? [quoteToPay] : []}
                  />

                  {selectedBookings.length || quoteToPay ? (
                    <div className={styles.selectedItems}>
                      <h2 className={styles.selectedSummaryTitle}>
                        Your Selection Summary
                      </h2>

                      {selectedBookingsData.map(([booking, mode]) => (
                        <BookingSelected
                          key={booking.bookingNumber}
                          booking={booking}
                          mode={mode}
                        />
                      ))}

                      {quoteToPay && (
                        <div className={styles.paymentPanel}>
                          <PaymentForm
                            paymentMode={paymentMode}
                            onChangePaymentMode={setPaymentMode}
                            onSubmitCreditCard={submitPayment}
                            mode={PaymentFormMode.PayNow}
                            netAmount={quoteToPay.bookingPrice.netPriceWithTax}
                            grossAmount={
                              quoteToPay.bookingPrice.grossPriceWithTax
                            }
                            onCancel={() => setQuoteToPay(null)}
                          />
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                <PaymentTotals
                  totals={paymentTotals}
                  totalNet={totalSelectedNet}
                  totalGross={totalSelectedGross}
                  totalCommissions={totalSelectedCommissions}
                  itinerary={currentItinerary}
                />

                {userProfile?.appUser.value &&
                  userProfile.appUser.value.userType <=
                  AppUserType.backOffice && (
                    <>
                      <Comments bookingIds={bookingIdList} />
                      <ItineraryLogs />
                    </>
                  )}
              </BookingSearchResultContext.Provider>
            </div>
          </div>
        </PageSection>

        {!isLoading &&
          lastBooking &&
          (lastBooking.liveStatus === LiveStatus.confirmed ||
            lastBooking.liveStatus === LiveStatus.pendingConfirmation) &&
          paymentType.current === PaymentType.BankTransfer && (
            <BookingDialogWindow
              booking={lastBooking}
              isOpen={isDialogOpen}
              getBookingKey={generateBookingKey}
              onClose={() => setIsDialogOpen(false)}
            />
          )}
      </>
    ),
    [
      selectedBookings,
      lastBooking,
      searchResult,
      paymentType,
      isDialogOpen,
      setIsDialogOpen,
      isLoading,
      itemModalOpened,
      paymentMode,
      quoteToPay,
      selectedDownloadableBookings,
      allDownloadableBookings,
      unavailableModalOpened,
    ]
  );

  const loadingItinerary = useMemo(() => {
    if (currentItinerary === undefined) {
      return <ErrorMessage error="No itinerary selected" />;
    } else if (currentItinerary.kind === "error") {
      return <ErrorMessage {...currentItinerary} />;
    }

    return (
      <>
        {(currentItinerary.kind === "loading" || isLoading) && (
          <SpinnerOverlay />
        )}
        <PdfCacheContext.Provider value={pdfCache}>
          {itineraryDetails}
        </PdfCacheContext.Provider>
      </>
    );
  }, [currentItinerary?.kind, itineraryDetails, isLoading]);

  return <Page>{loadingItinerary}</Page>;
};
