import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";

import { MINIMAL_SEARCH_STRING_LENGTH } from "@controllers/location-search/constant";
import { usePrevious } from "@hooks/usePrevious";

import { Badge, BadgeColor } from "../badge/badge";
import { Icons } from "../icon/icons";
import { InputLookup, InputLookupProps } from "../input-lookup/input-lookup";
import {
  InputLookupOption,
  InputLookupOptionProps,
} from "../input-lookup/input-lookup-option";
import styles from "./location-picker.css";

const BADGE_COLOR_MAP: Record<LocationPickerBadgeType, BadgeColor> = {
  hotel: BadgeColor.Red,
  landmark: BadgeColor.Purple,
  area: BadgeColor.Green,
  popular: BadgeColor.Orange,
  badge: BadgeColor.Green,
};

/** Component Props */
export interface LocationPickerProps extends InputLookupProps {
  classes?: InputLookupProps["classes"] & {
    locationPicker?: string;
  };
  /** Optional, list of options to show as a select */
  options?: OptionProps[];
  isLoading: boolean;
}

export const LocationPicker = React.forwardRef<
  HTMLInputElement,
  LocationPickerProps
>(
  (
    { options, isLoading, ...props }: LocationPickerProps,
    ref: React.RefObject<HTMLInputElement>
  ): JSX.Element => {
    // Value of the input field
    const [value, setValue] = useState(props.value || "");

    useEffect(() => {
      setValue(props.value || "");
    }, [props.value]);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);

      // If there is a onChange listener
      if (props.onChange !== undefined) {
        props.onChange(event);
      }
    };

    // @wereOptionsLoaded indicate whether at least one option request was sent
    // This is necessary in order to correctly display the message "No results"
    const wereOptionsLoaded = useRef(false);
    const prevIsLoading = usePrevious(isLoading);

    useEffect(() => {
      if (
        prevIsLoading !== isLoading &&
        isLoading &&
        !wereOptionsLoaded.current
      ) {
        wereOptionsLoaded.current = true;
      }
    }, [isLoading, prevIsLoading, wereOptionsLoaded]);

    const renderOptionList = () => {
      const result: JSX.Element[] = [];

      let group: string;

      if (options) {
        options.map((current, index) => {
          // If the type has changed from the last one
          if (group && group !== current.type) {
            // Insert an HR as a divider
            result.push(<hr key={`hr-${index}`} className={styles.divider} />);
          }

          result.push(
            renderOption({
              value: current.text,
              type: current.type,
              text: current.text,
              onClick: current.onClick,
              match: value.toString(),
              index,
            })
          );

          group = current.type;
        });
      }

      return result;
    };

    const showOptions =
      (value as string).length > MINIMAL_SEARCH_STRING_LENGTH &&
      (wereOptionsLoaded.current || isLoading);

    return (
      <InputLookup
        ref={ref}
        className={classnames(props?.classes?.locationPicker)}
        iconBefore={Icons.mapMarkerAlt}
        onChange={changeHandler}
        value={value}
        isLoading={isLoading}
        {...props}
      >
        {showOptions ? renderOptionList() : undefined}
      </InputLookup>
    );
  }
);

export type LocationPickerBadgeType =
  | "area"
  | "badge"
  | "hotel"
  | "landmark"
  | "popular";

export interface OptionProps extends InputLookupOptionProps {
  /** Optional, text to match and highlight within the option */
  match?: string;
  /** Text to be displayed */
  text: string;
  /** Type of the option which wiill be displayed as a badge */
  type: LocationPickerBadgeType;
  /** Optional, callback when an option is selected */
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  /** Optional, index in the list */
  index?: number;
}

const renderOption = ({ text, type, match, onClick, index }: OptionProps) => {
  // Regular expression to match the text substring
  const regEx = new RegExp(`(${match})`, "gi");

  // Split the text using the regex
  const matched = text.split(regEx);

  // A container for the processed text
  const result: (JSX.Element | string)[] = [];

  // For each piece of the text
  matched.forEach((value, i) => {
    // If the text is a match
    if (match && value.toLowerCase() === match.toLowerCase()) {
      // Wrap in a strong tag
      result.push(<strong key={`strong-${i}`}>{value}</strong>);
    } else {
      // Don't wrap in a strong tag
      result.push(value);
    }
  });

  return (
    <InputLookupOption key={`${text}-${index}`} value={text} onClick={onClick}>
      <span>{result}</span>
      <Badge text={type} variant={BADGE_COLOR_MAP[type]} />
    </InputLookupOption>
  );
};
