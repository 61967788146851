import classnames from "classnames";
import React, { useEffect, useState } from "react";

import { Icons } from "../icon/icons";
import { IconButton, IconButtonProps } from "./icon-button";
import styles from "./toggle-button.css";

/** Properties for ToggleButton component. Extends the ButtonProps. */
export interface ToggleButtonProps extends IconButtonProps {
  classes?: { toggleButton?: string; iconButton?: string; button?: string };
  /** Explicitly not allowed */
  iconBefore?: never;
  /** Explicitly not allowed */
  iconAfter?: never;
  /** The state of the toggle */
  toggle?: boolean;
}

/**
 * ToggleButton component that provides a button with and icon that is toggled
 * when clicked. You can listen for the state of the toggle by adding
 * an `onClick` handler and checking `event.currentTarget.value`.
 *
 * @author James Millar
 */
export const ToggleButton = ({
  className,
  classes,
  children,
  toggle = false,
  ...props
}: ToggleButtonProps) => {
  // The toggle state of the component
  const [toggleState, setToggleState] = useState(toggle);

  useEffect(() => {
    setToggleState(toggle);
  }, [toggle]);

  const clickHandler = (event) => {
    // Invert the value of the button that will be sent to handlers
    event.currentTarget.value = !toggleState;

    // Toggle the toggleState
    setToggleState(!toggleState);

    // If there are handlers throw the event
    if (props.onClick) {
      props.onClick(event);
    }
  };

  // Aggregation of class names for root element
  const toggleButtonClasses = classnames(
    styles.toggleButton,
    classes?.toggleButton,
    className,
    toggleState && styles.on
  );

  // Remove/consume the classes used by this component
  const { toggleButton: string, ...transferClasses } = { ...classes };

  return (
    <IconButton
      className={toggleButtonClasses}
      classes={transferClasses}
      {...props}
      iconAfter={Icons.angleDown}
      onClick={clickHandler}
      value={toggleState.toString()}
    >
      {children}
    </IconButton>
  );
};
