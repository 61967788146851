import React from "react";

import {
  AlertIcon,
  ErrorFilledIcon,
  SuccessIcon,
  WarningFilledIcon,
} from "@assets/svg-components";
import { NotificationTypeEnum } from "@models/common";

export const NotificationIcons = {
  [NotificationTypeEnum.Information]: <WarningFilledIcon />,
  [NotificationTypeEnum.Alert]: <AlertIcon />,
  [NotificationTypeEnum.Success]: <SuccessIcon />,
  [NotificationTypeEnum.Warning]: <WarningFilledIcon />,
  [NotificationTypeEnum.Error]: <ErrorFilledIcon />,
};
