import React, { FC, useMemo, useRef, useState } from "react";

import { EyeIcon, HideIcon } from "@common/icon/icons";
import { IconButton } from "@widgets/button/icon-button";
import ModalWindow from "@widgets/modal-window";
import StarRating from "@widgets/star-rating";

import HotelDetailsMap from "../hotel-details-map";
import PriceDetails from "../price-details";
import styles from "./styles.css";
import { HotelModalPropTypes } from "./types";

const BUTTON_MARGIN = 12;

const HotelModal: FC<HotelModalPropTypes> = ({
  open,
  closeModal,
  hotel,
  nearbyHotels,
  onSelectHotel,
}) => {
  const [hotelsShown, setHotelsShown] = useState(false);
  const hotelCardRef = useRef<HTMLDivElement>(null);

  const { details } = hotel;

  function toggleNearbyHotels() {
    setHotelsShown((prevState) => !prevState);
  }

  function selectNearbyHotel(hotelId: string) {
    onSelectHotel(hotelId);
    setHotelsShown(false);
  }

  const showHotelsButtonTop = useMemo(
    () =>
      (hotelCardRef.current?.getBoundingClientRect().height || 0) +
      BUTTON_MARGIN,
    [details.priceDetails.kind, hotelCardRef.current?.clientHeight]
  );

  return (
    <ModalWindow className={styles.mapModal} open={open} onClose={closeModal}>
      <div className={styles.mapModalContent}>
        <HotelDetailsMap
          hotel={hotel}
          nearbyHotels={hotelsShown ? nearbyHotels : []}
          onSelectHotel={selectNearbyHotel}
        />

        <div className={styles.mapSideSection} ref={hotelCardRef}>
          <div className={styles.hotelName}>{details.hotelName}</div>
          <div className={styles.hotelAddress}>{details.hotelAddress}</div>
          <StarRating starRating={details.hotelStarRating} />
          <div className={styles.hotelPriceSection}>
            {details.priceDetails.kind === "value" &&
              details.priceDetails.value?.kind === "available" && (
                <div className={styles.lowestAverageNightlyRate}>
                  Lowest average nightly rate
                </div>
              )}
            <PriceDetails hotelDetails={details} />
          </div>
        </div>

        <IconButton
          className={styles.showMoreButton}
          onClick={toggleNearbyHotels}
          style={{ top: showHotelsButtonTop }}
          iconBefore={
            hotelsShown ? (
              <EyeIcon className={styles.eyeIcon} />
            ) : (
              <HideIcon className={styles.eyeIcon} />
            )
          }
        >
          Show nearby hotels
        </IconButton>
      </div>
    </ModalWindow>
  );
};

export default HotelModal;
