import clsx from "clsx";
import React, { MouseEvent, useEffect, useRef, useState } from "react";

import { BaseProps } from "@common/base";

import { IconSizeable } from "../icon/icon-sizeable";
import styles from "./input-text.css";

// The size as determined by the visual design
const ICON_SIZE = 24;

/**
 * Properties for Index component. Extends the BaseProps and the intrinsic
 * properties for an HTML Input.
 */
export interface InputTextProps
  extends BaseProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  /** Optional additional classes to apply to internal elements */
  classes?: { inputText?: string; input?: string };
  /** Optional, prefixed icon within input container */
  iconBefore?: JSX.Element;
  onClickIconBefore?(): void;
  /** Optional, postfixed icon within input container */
  iconAfter?: React.JSX.Element;
  onClickIconAfter?(): void;
  /** Optional, label the field with floating placeholder text */
  label?: boolean;
  /** Explicitly not allowed */
  children?: never;
  /** Error text */
  error?: string;
  /** Length variant */
  variant?: "short" | "long" | "medium" | "fullwidth";
}

/**
 * Text Input Component intended to be a replacement for the
 * `input type="text"` element. Provides the option to add a prefixed or
 * postfixed icon within the input area. Provides the option using the label
 * flag to use a placeholder value as a internal floating label.
 *
 * @author James Millar
 */

export const InputText = React.forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      className,
      iconBefore,
      onClickIconBefore,
      iconAfter,
      onClickIconAfter,
      label,
      value,
      placeholder,
      onChange,
      error,
      variant = "fullwidth",
      onClick,
      ...props
    }: InputTextProps,
    inputTextRef: React.RefObject<HTMLInputElement>
  ) => {
    /** State to manage the value of the input field */
    const [valueState, setValueState] = useState(value || "");

    /** Update the value if explicitly passed as a prop  */
    useEffect(() => {
      setValueState(value || "");
    }, [value]);

    /**
     * Reference to input element, used to effect focus when outer components
     * clicked.
     */
    const inputComponentRef = inputTextRef || useRef<HTMLInputElement>(null);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      // Handle max property for numeric inputs
      if (props.max !== undefined && Number(newValue) > props.max) {
        return;
      }
      // Handle min property for numeric inputs
      if (props.min !== undefined && Number(newValue) < props.min) {
        return;
      }

      setValueState(event.target.value);
      // If a change handler has been passed in then throw the event
      if (onChange) {
        onChange(event);
      }
    };

    // Handle min = 0 property for numeric inputs separately
    // because in this case we want to filter out minus sign as well
    function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      if (props.min === 0 && e.key === "-") {
        e.preventDefault();
        return false;
      }
    }

    // This handler sets the focus to the input within the component
    const clickHandler = (event) => {
      inputComponentRef.current?.focus();

      if (onClick) {
        onClick(event);
      }
    };

    function clickIconBefore(e: MouseEvent) {
      e.stopPropagation();
      if (onClickIconBefore) {
        onClickIconBefore();
      }
    }

    function clickIconAfter(e: MouseEvent) {
      e.stopPropagation();
      if (onClickIconAfter) {
        onClickIconAfter();
      }
    }

    // Aggregation of class names for root element
    const inputTextClasses = clsx(
      className,
      styles.inputText,
      styles[variant],
      valueState?.toString() && styles.hasValue,
      props.disabled && styles.disabled,
      props.readOnly && styles.readOnly,
      error && styles.error,
      props.classes?.inputText
    );

    return (
      <div
        className={inputTextClasses}
        style={props.style}
        onClick={clickHandler}
      >
        {iconBefore && (
          <div
            className={clsx(styles.icon, onClickIconBefore && styles.clickable)}
            onClick={clickIconBefore}
          >
            {iconBefore}
          </div>
        )}
        <div className={styles.labelContainer}>
          {label && placeholder && (
            <div className={styles.label}>{placeholder}</div>
          )}
          <input
            ref={inputComponentRef}
            className={props.classes?.input}
            type="text"
            onChange={changeHandler}
            onKeyDown={onKeyDown}
            // Only pass the placeholder through if not a label
            placeholder={(!label && placeholder) || ""}
            // if inputComponentRef === "function" then the input is used with the useForms
            // and the value will be filled automatically
            {...(typeof inputComponentRef === "function"
              ? {}
              : { value: valueState })}
            {...props}
          />
        </div>
        {error && <span className={styles.errorText}>{error}</span>}
        {iconAfter && (
          <div
            className={clsx(styles.icon, onClickIconAfter && styles.clickable)}
            onClick={clickIconAfter}
          >
            <IconSizeable size={ICON_SIZE} icon={iconAfter} />
          </div>
        )}
      </div>
    );
  }
);
