import clsx from "clsx";
import React, { FC } from "react";

import {
  AdultFullName,
  ChildFullName,
  RoomOccupancyDetails,
} from "@adl-gen/hotel/booking";
import styles from "@pages/complete-booking-page/complete-booking/booking-form/booking-form.css";
import Guest from "@pages/complete-booking-page/complete-booking/booking-form/guest";

interface PropTypes {
  roomIdx: number;
  occupants: RoomOccupancyDetails;
  onChange(roomIdx: number, occupancy: RoomOccupancyDetails): void;
  visible: boolean;
}

const RoomDetails: FC<PropTypes> = ({
  onChange,
  roomIdx,
  visible,
  occupants,
}) => {
  const onAdultUpdate = (updatedIdx: number, updated: AdultFullName) => {
    occupants.adults[updatedIdx] = updated;
    onChange(roomIdx, occupants);
  };

  const onChildUpdate = (updatedIdx: number, updatedName: ChildFullName) => {
    occupants.childPassengers[updatedIdx].name = updatedName;
    onChange(roomIdx, occupants);
  };

  return (
    <div className={clsx(styles.roomDetails, !visible && styles.hidden)}>
      {occupants.adults.map((adult, idx) => (
        <Guest
          key={idx}
          guestIdx={idx}
          label={`Adult ${idx + 1}`}
          isChild={false}
          fullName={adult}
          onChange={onAdultUpdate}
        />
      ))}
      {occupants.childPassengers.map((child, idx) => (
        <Guest
          key={idx}
          guestIdx={idx}
          label={`Child ${idx + 1} (${
            child.age ? `${child.age} years` : "Under 1 year"
          })`}
          isChild={true}
          fullName={child.name}
          onChange={onChildUpdate}
        />
      ))}
    </div>
  );
};

export default RoomDetails;
