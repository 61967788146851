import clsx from "clsx";
import { cloneDeep } from "lodash";
import React, {
  FormEvent,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";

import { WithDbId } from "@adl-gen/common/db";
import { Agency } from "@adl-gen/hotel/db";
import { GeneralContext, LoggedInContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import Commission from "@pages/settings/agency-details/commission";
import { AgencyDetailsTab } from "@pages/settings/agency-details/constants";
import ContactDetails from "@pages/settings/agency-details/contact-details";
import FinancialDetails from "@pages/settings/agency-details/financial-details";
import GeneralDetails from "@pages/settings/agency-details/general-details";
import {
  AGENCY_FORM_EMPTY_ERRORS,
  AGENCY_REQUIRED_FIELDS,
  EMPTY_AGENCY_VALUES,
} from "@pages/settings/manage-agencies/constants";
import { AgencyManageProperties } from "@pages/settings/types";
import { isObjectKey } from "@util/agentus-utis";
import { ERROR_TEXT, ValidationRule } from "@util/validation";
import { Button } from "@widgets/button/button";
import ModalWindow from "@widgets/modal-window";
import TabOption from "@widgets/tab-option";

import styles from "./styles.css";

interface Props {
  isOpen: boolean;
  onClose(): void;
  agency?: WithDbId<Agency>;
}

const CreateEditAgencyModal = forwardRef<HTMLFormElement, Props>(
  ({ isOpen, onClose, agency }, _ref) => {
    if (!isOpen) {
      return null;
    }

    const { updateAgency, createAgency } = assertNotUndefined(
      useContext(LoggedInContext).agencyController
    );

    const { addNotification } = assertNotUndefined(
      useContext(GeneralContext).notificationController
    );

    const [loading, setLoading] = useState(false);
    const [showFieldErrors, setShowFieldErrors] = useState(false);
    const [agencyDetails, setAgencyDetails] = useState<WithDbId<Agency>>(
      agency ? agency : cloneDeep(EMPTY_AGENCY_VALUES)
    );
    const [errors, setErrors] = useState<{ [key: string]: string[] }>(
      agency ? AGENCY_FORM_EMPTY_ERRORS : AGENCY_REQUIRED_FIELDS
    );
    const [currentModalTab, setCurrentModalTab] = useState<AgencyDetailsTab>(
      AgencyDetailsTab.GeneralDetails
    );

    useEffect(() => {
      if (!isOpen) {
        setAgencyDetails(cloneDeep(EMPTY_AGENCY_VALUES));
        setErrors(AGENCY_FORM_EMPTY_ERRORS);
      }
    }, [isOpen]);

    const changeTab = (tab) => {
      // if the required fields are not filled in, do not let go to a new tab
      if (errors[currentModalTab].length === 0) {
        setCurrentModalTab(tab);
        setShowFieldErrors(false);
      } else {
        setShowFieldErrors(true);
      }
    };

    async function save() {
      setLoading(true);

      if (agency) {
        await updateAgency(agencyDetails);
        addNotification({
          text: "The agency has been updated",
          type: NotificationTypeEnum.Success,
        });
      } else {
        await createAgency(agencyDetails.value);
        addNotification({
          text: "The agency has been created",
          type: NotificationTypeEnum.Success,
        });
      }

      onClose();
      setLoading(false);
    }

    async function onSubmitStep(e: FormEvent) {
      e.preventDefault();

      if (currentModalTab === AgencyDetailsTab.Commission) {
        if (errors[currentModalTab].length === 0) {
          await save();
        } else {
          setShowFieldErrors(true);
        }
        return;
      }

      const currentTabIndex = Object.values(AgencyDetailsTab).findIndex(
        (v) => v === currentModalTab
      );

      changeTab(Object.values(AgencyDetailsTab)[currentTabIndex + 1]);
    }

    function updateErrors<T extends keyof Agency>(
      fieldValue: Agency[T] | string,
      fieldName: string
    ) {
      const currentErrors = cloneDeep(errors);

      const nestedFieldName = fieldName.split("_")[1];
      const fieldValueStr: string =
        fieldValue && typeof fieldValue === "object" && nestedFieldName
          ? fieldValue[nestedFieldName]
          : fieldValue;

      if (
        !fieldValueStr &&
        !currentErrors[currentModalTab].includes(fieldName)
      ) {
        currentErrors[currentModalTab] = [
          ...currentErrors[currentModalTab],
          fieldName,
        ];
      }
      if (fieldValueStr && currentErrors[currentModalTab].includes(fieldName)) {
        currentErrors[currentModalTab] = currentErrors[currentModalTab].filter(
          (name) => name !== fieldName
        );
      }
      setErrors(currentErrors);
    }

    const formInputChangeHandler = (
      event: FormEvent<HTMLInputElement>
    ): void => {
      const fieldValue: string = event.currentTarget.value;
      const fieldName: string = event.currentTarget.name;

      setAgencyDetails((agencyValue) => ({
        ...agencyValue,
        value: {
          ...agencyValue.value,
          [fieldName]: fieldValue,
        },
      }));
      updateErrors(fieldValue, fieldName);
    };

    const updateCurrentAgencyDetailsField = <T extends keyof Agency>(
      fieldName: T,
      fieldValue: Agency[T],
      fieldNameKey?: string
    ): void => {
      const tempAgency = {
        ...agencyDetails,
      };
      tempAgency.value[fieldName] = fieldValue;

      setAgencyDetails(tempAgency);
      if (
        AGENCY_REQUIRED_FIELDS[currentModalTab].includes(
          fieldNameKey || fieldName
        )
      ) {
        updateErrors(fieldValue, fieldNameKey || fieldName);
      }
    };

    const updateCurrentAgencyDetailsNestedField = (
      fieldName: keyof Agency,
      nestedFieldName: string,
      value: string
    ): void => {
      const fieldValue = agencyDetails.value[fieldName];

      if (
        fieldValue &&
        typeof fieldValue !== "string" &&
        isObjectKey(nestedFieldName, fieldValue)
      ) {
        fieldValue[nestedFieldName] = value;

        updateCurrentAgencyDetailsField(
          fieldName,
          fieldValue,
          `${fieldName}_${nestedFieldName}`
        );
      } else {
        throw new Error(
          `${String(fieldName)}/${nestedFieldName} is an illegal agency field`
        );
      }
    };

    function getFieldError(
      requiredFields: string[],
      fieldName: string
    ): string | undefined {
      return requiredFields.includes(fieldName)
        ? ERROR_TEXT[ValidationRule.RequiredField]
        : undefined;
    }

    const agencyProps: AgencyManageProperties = {
      agencyDetails,
      formInputChangeHandler,
      updateCurrentAgencyDetailsField,
      updateCurrentAgencyDetailsNestedField,
      getFieldError,
    };

    function getCurrentModalPage() {
      switch (currentModalTab) {
        case AgencyDetailsTab.GeneralDetails:
        default:
          return (
            <GeneralDetails
              {...agencyProps}
              requiredFields={
                AGENCY_REQUIRED_FIELDS[AgencyDetailsTab.GeneralDetails]
              }
              fieldsWithError={
                showFieldErrors
                  ? errors[AgencyDetailsTab.GeneralDetails]
                  : undefined
              }
            />
          );
        case AgencyDetailsTab.ContactDetails:
          return (
            <ContactDetails
              {...agencyProps}
              requiredFields={
                AGENCY_REQUIRED_FIELDS[AgencyDetailsTab.ContactDetails]
              }
              fieldsWithError={
                showFieldErrors
                  ? errors[AgencyDetailsTab.ContactDetails]
                  : undefined
              }
            />
          );
        case AgencyDetailsTab.FinancialDetails:
          return (
            <FinancialDetails
              {...agencyProps}
              requiredFields={
                AGENCY_REQUIRED_FIELDS[AgencyDetailsTab.FinancialDetails]
              }
              fieldsWithError={
                showFieldErrors
                  ? errors[AgencyDetailsTab.FinancialDetails]
                  : undefined
              }
            />
          );
        case AgencyDetailsTab.Commission:
          return (
            <Commission
              {...agencyProps}
              requiredFields={
                AGENCY_REQUIRED_FIELDS[AgencyDetailsTab.Commission]
              }
              fieldsWithError={
                showFieldErrors
                  ? errors[AgencyDetailsTab.Commission]
                  : undefined
              }
            />
          );
      }
    }

    return (
      <ModalWindow
        className={clsx(styles.root, styles.agencyModal)}
        open={isOpen}
        onClose={onClose}
      >
        <form
          className={styles.agencyModalInner}
          onSubmit={onSubmitStep}
          onReset={onClose}
        >
          <div className={styles.agencyModalTitle}>
            {agency ? "Edit Agency" : "Create Agency"}
          </div>
          <div className={styles.tabs}>
            {Object.values(AgencyDetailsTab).map((tab) => (
              <TabOption
                key={tab}
                onClick={() => changeTab(tab)}
                selected={tab === currentModalTab}
              >
                {tab}
              </TabOption>
            ))}
          </div>

          <div className={styles.tabContent}>{getCurrentModalPage()}</div>

          <div className={styles.modalFooter}>
            <Button emphasis="medium" type="reset" size="large">
              Cancel
            </Button>
            <Button
              className={styles.saveBtn}
              isLoading={loading}
              size="large"
              type="submit"
            >
              {currentModalTab === AgencyDetailsTab.Commission
                ? "Save"
                : "Next"}
            </Button>
          </div>
        </form>
      </ModalWindow>
    );
  }
);

export default CreateEditAgencyModal;
