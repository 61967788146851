import { useState } from "react";

import {
  BasicHotelInfo,
  Location,
  LocationInfoResp,
  LocationSearchResp,
  LocationType,
} from "@adl-gen/ids/externalapi";
import { HotelSearchService } from "@controllers/hotel-search/hotel-search-controller";
import { NotificationController } from "@controllers/notification-controller";
import { DebounceCallback } from "@hx/util/timers";
import { assertNotUndefined } from "@hx/util/types";
import { Loading } from "@models/loading";
import { fromPromise } from "@models/loading-state";

import { MINIMAL_SEARCH_STRING_LENGTH } from "./constant";

/**
 * Controller of the location search
 */
export interface LocationSearchController {
  locationSearchString: string;
  setSearchString(val: string): void;
  searchForMatchingLocations(args: DebounceCallback<string>): void;
  selectedLocation?: Location;
  searchLocations: Loading<LocationSearchResp>;
  selectLocation(location: Location): void;
  locationInfo: Loading<LocationInfoResp>;
  getLocationParams(): Map<string, string>;
  setLocationFromParams(params: URLSearchParams): Location | undefined;
  fetchLocationInfo(locationType: LocationType): Promise<void>;

  /** Сlearing values after logout */
  logout(): void;
}

const EMPTY_LOCATION_SEARCH_RESP: LocationSearchResp = {
  availableLocations: [],
  availableHotels: [],
};

const EMPTY_LOCATION_INFO_RESP: LocationInfoResp = {
  amenities: [],
  hotelCategories: [],
  hotelChains: [],
  marker: { lat: 0.0, lng: 0.0 },
  region: null,
  subRegions: [],
};

export const getNameContext = (l: Location): string => {
  return l.context !== null ? `${l.name}, ${l.context}` : l.name;
};

export const getHotelName = (h: BasicHotelInfo): string => {
  return h.name;
};

export const getHotelContext = (h: BasicHotelInfo): string => {
  let hotelContext: string = h.name;
  if (h.address.city !== null) {
    hotelContext += ", " + h.address.city;
  }
  return hotelContext;
};

export const LOCATION_ID_PARAM = "location";
export const LOCATION_TYPE_PARAM = "type";
export const LOCATION_NAME_CONTEXT_PARAM = "locationname";
export const BOUNDS_PARAM = "bounds";

/**
 * Creates a location search state to use on the hotel search page
 */
export const useLocationSearchController = (
  service: HotelSearchService,
  notificationController: NotificationController
): LocationSearchController => {
  const [selectedLocation, selectLocation] = useState<Location | undefined>();

  const [locationSearchString, setLocationSearchString] = useState<string>("");
  const [searchLocations, setSearchLocations] = useState<
    Loading<LocationSearchResp>
  >({
    kind: "value",
    value: EMPTY_LOCATION_SEARCH_RESP,
  });
  const [locationInfo, setLocationInfo] = useState<Loading<LocationInfoResp>>({
    kind: "value",
    value: EMPTY_LOCATION_INFO_RESP,
  });

  const { addNotification } = notificationController;

  const searchForMatchingLocations = ({
    value,
    signal,
  }: DebounceCallback<string>) => {
    if (value.length > 0) {
      fromPromise(
        service.searchLocations({ searchText: value }, { signal }),
        setSearchLocations,
        addNotification
      );
    } else {
      setSearchLocations({
        kind: "value",
        value: EMPTY_LOCATION_SEARCH_RESP,
      });
    }
  };

  const getLocationParams = (): Map<string, string> => {
    const loc = assertNotUndefined(selectedLocation);

    if (loc.locationType.kind === "bounds") {
      throw new Error("Not allowed location type: bounds");
    }

    return new Map<string, string>([
      [LOCATION_ID_PARAM, loc.locationType.value],
      [LOCATION_TYPE_PARAM, loc.locationType.kind],
      [LOCATION_NAME_CONTEXT_PARAM, getNameContext(loc)],
    ]);
  };

  const setLocationFromParams = (
    params: URLSearchParams
  ): Location | undefined => {
    if (selectedLocation) {
      return selectedLocation;
    }

    const id = params.get(LOCATION_ID_PARAM);
    const type = params.get(LOCATION_TYPE_PARAM);
    const nameContext = params.get(LOCATION_NAME_CONTEXT_PARAM);
    const isAValidLocation: boolean =
      id !== null &&
      type !== null &&
      nameContext !== null &&
      (type === "region" || type === "poi" || type === "hotel");

    if (isAValidLocation) {
      const loc: Location = {
        // tslint:disable-next-line:no-non-null-assertion
        locationType: { kind: type as "region" | "poi" | "hotel", value: id! },
        // tslint:disable-next-line:no-non-null-assertion
        name: nameContext!,
        context: null,
      };
      setSelectedLocation(loc);
      setLocationSearchString(loc.name);
      return loc;
    } else {
      setSelectedLocation(undefined);
      return undefined;
    }
  };

  const getLocationContext = (location: Location): string => {
    let locationContext: string = location.name;
    if (location.context !== null) {
      locationContext += ", " + location.context;
    }
    return locationContext;
  };

  /**
   * Sets the selected location
   */
  const setSelectedLocation = (newLocation?: Location) => {
    selectLocation(newLocation);

    if (newLocation?.locationType) {
      setLocationSearchString(getLocationContext(newLocation));
    }
  };

  /**
   * Triggers a call to fetch the
   * location info including amenity data etc. from the backend based
   * on the location selected.
   */
  const fetchLocationInfo = (locationType: LocationType) => {
    return fromPromise(
      service.queryLocationInfo(locationType),
      setLocationInfo,
      addNotification
    );
  };

  const setSearchString = (value: string) => {
    if (value.length < MINIMAL_SEARCH_STRING_LENGTH) {
      setSearchLocations({
        kind: "value",
        value: EMPTY_LOCATION_SEARCH_RESP,
      });
    } else {
      setSearchLocations({
        kind: "loading",
      });
    }

    setLocationSearchString(value);
  };

  const reset = () => {
    selectLocation(undefined);
    setLocationSearchString("");
    setLocationInfo({ kind: "value", value: EMPTY_LOCATION_INFO_RESP });
    setSearchLocations({
      kind: "value",
      value: EMPTY_LOCATION_SEARCH_RESP,
    });
  };

  const logout = () => {
    reset();
  };

  return {
    locationSearchString,
    setSearchString,
    searchForMatchingLocations,
    selectedLocation,
    selectLocation: setSelectedLocation,
    searchLocations,
    locationInfo,
    getLocationParams,
    setLocationFromParams,
    logout,
    fetchLocationInfo,
  };
};
