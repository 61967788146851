import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export enum HotelViewEnum {
  ListView =  'list',
  MapView =  'map',
}

export const useHotelView = () => {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);

  const defaultView: HotelViewEnum = useMemo(() => {
    const newView = params.get('view');
    params.set('view', `${newView}`);

    return (newView || HotelViewEnum.ListView) as HotelViewEnum;
  }, [params]);

  const [hotelView, setHotelView] = useState<HotelViewEnum>(defaultView);

  const changeView = () => {
    const newView = hotelView === HotelViewEnum.ListView ? HotelViewEnum.MapView : HotelViewEnum.ListView;

    setHotelView(newView);

    const params = new URLSearchParams(location.search);
    params.set('view', newView);

    location.search = params.toString();
    history.replace(location);
  }

  return { hotelView, changeView };
};