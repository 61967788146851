import { observable, runInAction } from "mobx";

import { assertNever } from "@hx/util/types";

/** Represents a value that may not (yet) have loaded */
export type Loading<T> =
  | { kind: "value"; value: T }
  | { kind: "loading" }
  | { kind: "error"; error: string };

/** Create a loading observable from a promise */
export function fromPromise<T>(futureValue: Promise<T>): Loading<T> {
  // Initially we are always in the loading state
  const loader: Loading<T> = observable<Loading<T>>({
    kind: "loading",
  });

  // Resolve promise and update loader state in the background
  futureValue
    .then((value) => {
      // TODO(ray): For some reason if the loaded value is an array then it does
      // not properly become an observable array after it gets inside the
      // observable. Added simple error for now.
      if (value instanceof Array) {
        throw new Error("arrays not supported for loaded value");
      }
      runInAction(() => {
        loader.kind = "value";
        if (loader.kind === "value") {
          loader.value = value;
        }
      });
    })
    .catch((error) =>
      runInAction(() => {
        //tslint:disable-next-line:no-console
        console.error("encountered error while loading:", error);

        loader.kind = "error";
        if (loader.kind === "error") {
          loader.error = `${error}`;
        }

        // NOTE: Do not rethrow the error because it should be handled at the
        // service level, e.g. authorization errors. The service may choose to
        // throw an error to tell the loader component to show a message to the
        // user. Hence at this point it is considered "handled".
      })
    );

  // Return loading state, this will return as soon as the above promise chain is created
  // so should be '{kind: loading}' unless the promise has already been resolved
  return loader;
}

/**
 * Maps the value of a Loading type if a value is present, otherwise, retains
 * the status of the original loading value
 */
export function mapLoading<T, Q>(
  original: Loading<T>,
  map: (val: T) => Q
): Loading<Q> {
  if (original.kind === "loading" || original.kind === "error") {
    return { ...original };
  } else if (original.kind === "value") {
    return {
      kind: "value",
      value: map(original.value),
    };
  } else {
    assertNever(original);
  }
}
