import clsx from "clsx";
import React, { ChangeEvent, FC, memo, useContext, useState } from "react";

import { GeneralContext } from "@app/app";
import layout from "@common/layout-lib.css";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { Button } from "@widgets/button/button";
import { Checkbox } from "@widgets/checkbox/checkbox";
import Input from "@widgets/input";

import inputStyles from "../../input-text/input-text.css";
import {
  CONDITIONS_AGREEMENT_TEXT,
  PaymentFormMode,
  PAYMENT_INFO_TEXT,
} from "../constants";
import paymentStyles from "../payment-form.css";
import PriceConfig, { CommonPriceConfigProps } from "../price-config";
import { CardPaymentField, STRIPE_ELEMENT_STYLE } from "./constants";
import styles from "./styles.css";

const STRIPE_INTERNAL_FIELDS_NUMBER = 3;

interface PropTypes extends CommonPriceConfigProps {
  classes?: { paymentForm?: string };
  // Used for validation additional data during payment process (i.e. booking form)
  isExternalDataValid?(): boolean;
  mode: PaymentFormMode;
  onSubmit(
    stripeCallback: (clientSecret: string) => Promise<void>
  ): Promise<void>;
  onCancel?(): void;
}

const CreditCardPayment: FC<PropTypes> = ({
  netAmount,
  grossAmount,
  isExternalDataValid,
  onSubmit,
  paymentMode,
  onChangePaymentMode,
  mode,
  onCancel,
}) => {
  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const elements = useElements();
  const stripe = useStripe();

  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [emptyFields, setEmptyFields] = useState<CardPaymentField[]>(
    Object.values(CardPaymentField)
  );
  const [validationErrors, setValidationErrors] = useState<CardPaymentField[]>(
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const externalDataValid = isExternalDataValid
      ? isExternalDataValid()
      : true;
    const nameValid = validateName();
    const completedFields = document.querySelectorAll(
      "[class*=StripeElement--complete]"
    );
    const termsAndConditionsValid = validateTerms();

    if (
      !externalDataValid ||
      !nameValid ||
      completedFields.length < STRIPE_INTERNAL_FIELDS_NUMBER ||
      !termsAndConditionsValid
    ) {
      addNotification({
        type: NotificationTypeEnum.Warning,
        text: !externalDataValid
          ? "The name fields are not completed, please add the adult first and last names to continue"
          : "The card fields are not completed, please add the missing information to continue",
      });
      return;
    }

    setIsLoading(true);

    await onSubmit(confirmInStripe);

    setIsLoading(false);
  };

  async function confirmInStripe(clientSecret: string) {
    if (!elements || !stripe) {
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (!card) {
      return;
    }

    const paymentBody = {
      payment_method: {
        card,
        billing_details: {
          name,
        },
      },
    };

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      paymentBody
    );

    if (error) {
      throw error;
    } else {
      if (paymentIntent?.status === "succeeded") {
        addNotification({
          text: "We have successfully confirmed a booking for you",
          type: NotificationTypeEnum.Success,
        });
      }
    }
  }

  function changeName(e: ChangeEvent<HTMLInputElement>) {
    setName(e.target.value);
    if (!e.target.value) {
      setEmptyFields((prevState) => [...prevState, CardPaymentField.Name]);
    } else {
      setEmptyFields((prevState) =>
        prevState.filter((value) => value !== CardPaymentField.Name)
      );
    }
  }

  function validateName(): boolean {
    if (!name) {
      setValidationErrors((prevState) => [...prevState, CardPaymentField.Name]);
      return false;
    } else {
      setValidationErrors((prevState) =>
        prevState.filter((value) => value !== CardPaymentField.Name)
      );
      return true;
    }
  }

  function toggleTerms(checked: boolean) {
    setConditionsAgreed(checked);
    if (!checked) {
      setEmptyFields((prevState) => [
        ...prevState,
        CardPaymentField.TermsAndConditions,
      ]);
    } else {
      setEmptyFields((prevState) =>
        prevState.filter(
          (value) => value !== CardPaymentField.TermsAndConditions
        )
      );
    }
  }

  function validateTerms() {
    if (!conditionsAgreed) {
      setValidationErrors((prevState) => [
        ...prevState,
        CardPaymentField.TermsAndConditions,
      ]);
      return false;
    } else {
      setValidationErrors((prevState) =>
        prevState.filter(
          (value) => value !== CardPaymentField.TermsAndConditions
        )
      );
      return true;
    }
  }

  function changeStripeField(field: CardPaymentField) {
    return (e: StripeElementChangeEvent) => {
      if (e.empty) {
        setEmptyFields((prevState) => [...prevState, field]);
      } else {
        setEmptyFields((prevState) =>
          prevState.filter((value) => value !== field)
        );
      }

      if (e.error) {
        setValidationErrors((prevState) => [...prevState, field]);
      } else {
        setValidationErrors((prevState) =>
          prevState.filter((value) => value !== field)
        );
      }
    };
  }

  return (
    <form
      id="payment-form"
      className="creditCardPayment"
      onSubmit={handleSubmit}
    >
      <div className={clsx(layout.horizontal, layout.justified, styles.row)}>
        <div className={clsx(layout.vertical, styles.wideInput)}>
          <label className={styles.label} htmlFor="cardNumber">
            Card Number
          </label>
          <CardNumberElement
            data-testid="card-number-input"
            className={clsx(
              inputStyles.inputText,
              styles.stripeInput,
              validationErrors.includes(CardPaymentField.CardNumber) &&
                inputStyles.error
            )}
            onChange={changeStripeField(CardPaymentField.CardNumber)}
            id="cardNumber"
            options={{
              placeholder: "•••• •••• •••• ••••",
              style: STRIPE_ELEMENT_STYLE,
            }}
          />
        </div>

        <div className={layout.vertical}>
          <label className={styles.label} htmlFor="cardExpiry">
            Expiry
          </label>
          <CardExpiryElement
            data-testid="card-expiry-input"
            className={clsx(
              inputStyles.inputText,
              styles.shortInput,
              styles.stripeInput,
              validationErrors.includes(CardPaymentField.Expiry) &&
                inputStyles.error
            )}
            onChange={changeStripeField(CardPaymentField.Expiry)}
            id="cardExpiry"
            options={{ placeholder: "MM/YY", style: STRIPE_ELEMENT_STYLE }}
          />
        </div>
      </div>

      <div className={clsx(layout.horizontal, layout.justified, styles.row)}>
        <Input
          data-testid="card-name-input"
          placeholder="Steve Jobs"
          label="Name on Credit Card"
          value={name}
          onChange={changeName}
          className={styles.wideInput}
          classes={{
            inputText: clsx(
              styles.input,
              validationErrors.includes(CardPaymentField.Name) &&
                inputStyles.error
            ),
          }}
          labelClassname={styles.label}
        />
        <div className={layout.vertical}>
          <label className={styles.label} htmlFor="cardCvc">
            CVC
          </label>
          <CardCvcElement
            data-testid="card-cvc-input"
            className={clsx(
              inputStyles.inputText,
              styles.shortInput,
              styles.stripeInput,
              validationErrors.includes(CardPaymentField.CVC) &&
                inputStyles.error
            )}
            onChange={changeStripeField(CardPaymentField.CVC)}
            id="cardCvc"
            options={{
              placeholder: "•••",
              style: STRIPE_ELEMENT_STYLE,
            }}
          />
        </div>
      </div>

      <PriceConfig
        grossAmount={grossAmount}
        netAmount={netAmount}
        paymentMode={paymentMode}
        onChangePaymentMode={onChangePaymentMode}
      />
      <hr />
      <div className={paymentStyles.row}>
        <Checkbox
          size="small"
          checked={conditionsAgreed}
          className={clsx(
            paymentStyles.checkbox,
            validationErrors.includes(CardPaymentField.TermsAndConditions) &&
              styles.termsError
          )}
          classes={{ text: styles.noWrap }}
          text={CONDITIONS_AGREEMENT_TEXT}
          multiline={true}
          onChange={toggleTerms}
        />
      </div>
      <div className={paymentStyles.row}>
        <Button
          type="submit"
          className={paymentStyles.payNowButton}
          disabled={
            isLoading ||
            !stripe ||
            !elements ||
            !conditionsAgreed ||
            emptyFields.length !== 0
          }
        >
          {mode === PaymentFormMode.PayNow ? "Pay Now" : "Book and Hold"}
        </Button>

        {onCancel && (
          <Button
            className={paymentStyles.cancelButton}
            onClick={onCancel}
            emphasis="medium"
            type="reset"
          >
            Cancel
          </Button>
        )}
      </div>
      <div className={clsx(paymentStyles.row, paymentStyles.info)}>
        {PAYMENT_INFO_TEXT}
      </div>
    </form>
  );
};

export default memo(CreditCardPayment);
