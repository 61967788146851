import {
  BOARD_PARAM,
  CANCELLATION_PARAM,
  HOTEL_PARAM,
  PACKAGE_ID_PARAM,
  PRICE_TOKEN_PARAM,
} from "@controllers/booking/constant";
import { isHotelSearchUrlParams } from "@controllers/hotel-search/utils";
import { USER_PATH_PARAM } from "@widgets/page/header-breadcrumbs/constants";

export function isCompleteBookingUrlParams(object: URLSearchParams): boolean {
  return (
    isHotelSearchUrlParams(object) &&
    object.has(HOTEL_PARAM) &&
    object.has(PRICE_TOKEN_PARAM) &&
    object.has(BOARD_PARAM) &&
    object.has(CANCELLATION_PARAM) &&
    object.has(PACKAGE_ID_PARAM) &&
    object.has(USER_PATH_PARAM)
  );
}
