import clsx from "clsx";
import React, { FC } from "react";

import { LeftArrowIcon, RightArrowIcon } from "@common/icon/icons";
import Image from "@widgets/image";

import styles from "./styles.css";

interface Props {
  currentImage: number;
  images: string[];
  onChange(targetIndex: number): void;
  onClick?(): void;
  fullscreen?: boolean;
}

const Carousel: FC<Props> = ({
  images,
  currentImage,
  onChange,
  onClick,
  fullscreen,
}) => {
  const countLineFillPercent = Math.ceil(
    ((currentImage + 1) / images.length) * 100
  );

  const countLineBackground = `linear-gradient(to right, white ${countLineFillPercent}%, rgba(255, 255, 255, 0.25) ${countLineFillPercent}%)`;
  const currentImageSrc = images[currentImage];
  function selectNext() {
    onChange(currentImage + 1);
  }

  function selectPrevious() {
    onChange(currentImage - 1);
  }
  return (
    <div className={clsx(styles.container, fullscreen && styles.fullscreen)}>
      <button
        className={clsx(styles.navButton, styles.left)}
        onClick={selectPrevious}
      >
        <LeftArrowIcon />
      </button>
      <button
        className={clsx(styles.navButton, styles.right)}
        onClick={selectNext}
      >
        <RightArrowIcon />
      </button>
      <Image
        className={clsx(styles.image, !!onClick && styles.clickable)}
        src={currentImageSrc}
        size="large"
        onClick={onClick}
      />

      <div className={styles.count}>
        <span className={styles.countNumberBright}>{currentImage + 1}</span>
        <div
          className={styles.countLine}
          style={{
            background: countLineBackground,
          }}
        />
        <span className={clsx(styles.countNumberShaded)}>{images.length}</span>
      </div>
    </div>
  );
};

export default Carousel;
