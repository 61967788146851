import {
  ActionFactory,
  AdminUiContainer,
  makePostAction,
} from "@adltools/adminui";
import { observer } from "mobx-react";
import * as React from "react";

import { RESOLVER } from "../adl-gen/resolver";
import { AdminStore } from "../adminui/admin-store";
import { IdentityController } from "@controllers/identity/identity-controller";
import { ADMIN_HREF_FACTORY } from "../routing/app-routes";
import { Router } from "../routing/router";
import { HttpService } from "../service/http-service";
import { PageLayout } from "../ui/layouts/page-layout/page-layout";

interface AppAdminProps {
  service: HttpService;
  identityStore: IdentityController;
  reactRouterHistory;
  reactRouterLocation;
}

/**
 * This conponent is a refactored app.tsx from proto-app that isolates the
 * proto-app adminui so that it can be run with the legacy routing system
 * while new parts of the application can be built using React Router.
 * TODO(jess): Refactor adminui to use React Router.
 */
@observer
export class AppAdmin extends React.Component<AppAdminProps> {
  router = new Router(
    location.pathname,
    window.history,
    this.props.reactRouterHistory,
    window
  );

  appStore: AdminStore = new AdminStore(
    this.router,
    this.props.service,
    this.props.identityStore,
    this.props.reactRouterLocation
  );

  // Declare the actions we want to show in the adminui
  adminUiActions: ActionFactory[] = [
    makePostAction(this.props.service.postCreateUser),
  ];

  render() {
    const state = this.appStore.state;
    return (
      <PageLayout title="Admin">
        <AdminUiContainer
          route={state.adminRoute}
          navigateTo={(admin) =>
            this.appStore.navigateTo({ route: "admin", admin })
          }
          onDone={() => this.appStore.navigateTo({ route: "logout" })}
          resolver={RESOLVER}
          service={this.appStore.service}
          hrefFactory={ADMIN_HREF_FACTORY}
          actionfns={this.adminUiActions}
        />
      </PageLayout>
    );
  }
}
