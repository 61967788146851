import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { LatLng } from "@adl-gen/common";
import { HotelSearchContext } from "@app/app";
import { ROUTE_PATH } from "@constants/common";
import { HOTEL_PARAM, PRICE_TOKEN_PARAM } from "@controllers/booking/constant";
import { HotelViewEnum } from "@controllers/customHooks/useHotelView";
import { PAGE_PARAM } from "@controllers/hotel-search/constant";
import { ViewHotelProps } from "@controllers/hotel-search/types";
import { BOUNDS_PARAM } from "@controllers/location-search/location-search-controller";
import { assertNotUndefined } from "@hx/util/types";
import { Loading } from "@models/loading";
import { SpinnerOverlay } from "@widgets/loader/loader";

import { HotelListingType } from "../types";
import ListView from "./list-view-layout";
import MapView from "./map-view-layout";
import styles from "./styles.css";

interface PropTypes {
  hotelsData: Loading<HotelListingType> | undefined;
}

const HotelResultsLayout = ({ hotelsData }: PropTypes) => {
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const isLoading = hotelsData ? hotelsData.kind === "loading" : true;

  /**
   * During each loading, the list of hotels is missing from the data -> { kind: "loading" }
   * This hotels value must be stored in the component state
   * so that the previous content does not disappear during loading
   */
  const [hotelsValue, setHotelsValue] = useState<HotelListingType | undefined>(
    (hotelsData?.kind === "value" && hotelsData?.value) || undefined
  );

  const viewDetailsHandler = useCallback(
    (hotelId: string, options: ViewHotelProps) => {
      hotelSearchController.setSelectedHotel(hotelId, options);
    },
    [hotelSearchController]
  );

  function selectHotelFromMapView(
    hotelId: string,
    opts: { bounds: LatLng[]; page: number }
  ) {
    const params = new URLSearchParams(location.search);
    params.set(HOTEL_PARAM, hotelId);
    params.set(
      PRICE_TOKEN_PARAM,
      hotelSearchController.getCurrentPriceToken() as string
    );

    params.set(BOUNDS_PARAM, JSON.stringify(opts.bounds));
    params.set(PAGE_PARAM, JSON.stringify(opts.page));

    window.open(`${ROUTE_PATH.Hotel}?${params.toString()}`);
  }

  function viewRatesHandler(hotelIdValue: string) {
    const hotelId =
      hotelIdValue === hotelSearchController.selectedHotel
        ? undefined
        : hotelIdValue;
    availablePackagesState.setSelectedHotel(hotelId);
  }

  const availablePackagesState = hotelSearchController;

  useEffect(() => {
    if (hotelsData?.kind === "value" && hotelsData?.value) {
      setHotelsValue(hotelsData.value);
    } else {
      setHotelsValue(undefined);
    }
  }, [hotelsData]);

  useEffect(() => {
    // when updating the list of hotels (for example, pagination), show the page from the beginning
    if (
      hotelSearchController.hotelView === HotelViewEnum.ListView &&
      !isLoading &&
      document.documentElement.scrollTop
    ) {
      document.documentElement.scroll(0, 0);
    }
  }, [isLoading]);

  useEffect(() => {
    if (availablePackagesState.selectedHotel) {
      availablePackagesState.setSelectedHotel(undefined);
    }
  }, []);

  const isLoadingExistingSearchRef = useRef(
    hotelSearchController.lastHotelQuery.current?.searchParams.kind ===
      "existingSearch" && isLoading
  );

  return (
    <div className={styles.hotelResultRoot}>
      {isLoading && <SpinnerOverlay />}

      {hotelsValue && (
        <>
          {hotelSearchController.hotelView === HotelViewEnum.ListView ? (
            <ListView
              hotelList={hotelsValue.details}
              viewDetailsHandler={viewDetailsHandler}
              viewRatesHandler={viewRatesHandler}
            />
          ) : (
            <MapView
              hotels={hotelsValue.details}
              onHotelClick={selectHotelFromMapView}
              isLoadingExistingSearchRef={isLoadingExistingSearchRef}
            />
          )}
        </>
      )}
    </div>
  );
};

export default HotelResultsLayout;
