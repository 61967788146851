import { FormEvent, useEffect, useState } from "react";

import { BigDecimal } from "@adl-gen/common";
import { WithDbId } from "@adl-gen/common/db";
import { SortDirection } from "@adl-gen/common/tabular";
import { UserProfile } from "@adl-gen/hotel/api";
import { Agency, AppUserType } from "@adl-gen/hotel/db";
import { NotificationController } from "@controllers/notification-controller";
import { NotificationTypeEnum } from "@models/common";
import { checkUserAccessLevel, isObjectKey } from "@util/agentus-utis";

import { Service } from "../service/service";

export type AgencyService = Pick<
  Service,
  "updateAgency" | "getAgency" | "whoami" | "queryAgencies" | "createAgency"
>;

export interface AgencySettingsState {
  /** Loaded agency details from the backend */
  agencyDetails?: WithDbId<Agency>;
  /** The current agency details being edited */
  currentAgencyDetails?: WithDbId<Agency>;
}

export interface WithAgencyController {
  agencyController: AgencyController;
}

export interface AgencyController {
  /** Manages the agency settings page state */
  agencySettingsState: AgencySettingsState;
  /**
   * Updates the current agency details being edited given a field of the
   * Agency table and the corresponding value to that field.
   */
  updateCurrentAgencyDetailsField<T extends keyof Agency>(
    fieldName: T,
    value: Agency[T]
  ): void;
  /** Updates a nested field on the current agency details */
  updateCurrentAgencyDetailsNestedField(
    fieldName: keyof Agency,
    nestedFieldName: string,
    value: string
  ): void;
  /** Saves the current agency details */
  onSaveAgencyDetails(): Promise<void>;
  /** Edit agency */
  updateAgency(agency: WithDbId<Agency>): Promise<void>;
  /** Create new agency */
  createAgency(agency: Agency): Promise<void>;
  /** Discards current agency details to default state */
  discardAgencyDetailsChanges(): void;
  /** Updates a field on the current agency details using the form event's name and value fields */
  formInputChangeHandler(event: FormEvent<HTMLInputElement>): void;
  /** Refreshes the agency details */
  refreshAgencyDetails(): Promise<void>;
  /** Сlearing values after logout */
  logout(): void;

  /**
   * Returns the default value of commission to use for this agent
   */
  defaultCommission?: BigDecimal;

  fetchAgencies(): void;
  agencies: WithDbId<Agency>[];
}

export const useAgencyController = (
  service: AgencyService,
  notificationController: NotificationController,
  userProfile?: UserProfile
): AgencyController => {
  const [agencies, setAgencies] = useState<WithDbId<Agency>[]>([]);
  const [agencyDetails, setAgencyDetails] = useState<
    WithDbId<Agency> | undefined
  >(undefined);
  const [currentAgencyDetails, setCurrentAgencyDetails] = useState<
    WithDbId<Agency> | undefined
  >(undefined);
  const [defaultCommission, setDefaultCommission] = useState<
    BigDecimal | undefined
  >(undefined);

  const refreshAgencyDetails = async () => {
    if (userProfile) {
      const userProfileAgencyId: string | null =
        userProfile.appUser.value.agencyId;

      if (
        userProfileAgencyId &&
        checkUserAccessLevel(userProfile, AppUserType.agencyAdmin)
      ) {
        try {
          const fetchedAgencyDetails = await service.getAgency(
            userProfileAgencyId
          );
          const agencyWithId = {
            id: userProfileAgencyId,
            value: fetchedAgencyDetails,
          };
          setAgencyDetails(agencyWithId);
          setCurrentAgencyDetails(agencyWithId);
          updateDefaultCommission(fetchedAgencyDetails, userProfile);
        } catch (e) {
          notificationController.addNotification({
            type: NotificationTypeEnum.Error,
            text: "Unexpected error occurred: unable to get agency information",
          });
        }
      }
    }
  };

  const updateDefaultCommission = (agency: Agency, user: UserProfile) => {
    setDefaultCommission(
      user.appUser.value.commissionPercentage !== null
        ? user.appUser.value.commissionPercentage
        : agency.commissionPercentage
    );
  };

  const updateCurrentAgencyDetailsField = <T extends keyof Agency>(
    fieldName: T,
    value: Agency[T]
  ): void => {
    if (currentAgencyDetails) {
      const tempAgency = {
        ...currentAgencyDetails,
      };
      tempAgency.value[fieldName] = value;
      setCurrentAgencyDetails(tempAgency);
    }
  };

  const onSaveAgencyDetails = async (): Promise<void> => {
    if (currentAgencyDetails) {
      try {
        await service.updateAgency({
          id: currentAgencyDetails.id,
          value: {
            ...currentAgencyDetails.value,
          },
        });
      } catch (e) {
        notificationController.addNotification({
          type: NotificationTypeEnum.Error,
          text: "Unexpected error occurred: unable to update agency",
        });
      }
    }
  };

  const createAgency = async (agency: Agency): Promise<void> => {
    try {
      await service.createAgency(agency);
      await fetchAgencies();
    } catch (e) {
      notificationController.addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to create agency",
      });
    }
  };

  const updateAgency = async (agency: WithDbId<Agency>): Promise<void> => {
    try {
      await service.updateAgency(agency);
      await fetchAgencies();
    } catch (e) {
      notificationController.addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to update agency",
      });
    }
  };

  /** Updates the appropriate field by parsing the form event for the input element. */
  const formInputChangeHandler = (event: FormEvent<HTMLInputElement>): void => {
    updateCurrentAgencyDetailsField(
      event.currentTarget.name as keyof Agency,
      event.currentTarget.value
    );
  };

  /** Updates a nested field on the current agency details */
  const updateCurrentAgencyDetailsNestedField = (
    fieldName: keyof Agency,
    nestedFieldName: string,
    value: string
  ): void => {
    if (currentAgencyDetails) {
      const fieldValue = currentAgencyDetails.value[fieldName];
      if (
        fieldValue &&
        typeof fieldValue !== "string" &&
        isObjectKey(nestedFieldName, fieldValue)
      ) {
        fieldValue[nestedFieldName] = value;
        updateCurrentAgencyDetailsField(fieldName, fieldValue);
      } else {
        throw new Error(
          `${String(fieldName)}/${nestedFieldName} is an illegal agency field`
        );
      }
    }
  };

  const discardAgencyDetailsChanges = () => {
    setCurrentAgencyDetails(agencyDetails);
  };

  async function fetchAgencies() {
    try {
      const allAgencies = await service.queryAgencies({
        filter: {
          kind: "literal",
          value: true,
        },
        offset: 0,
        count: 20,
        sorting: [{ field: "name", direction: SortDirection.ascending }],
      });

      setAgencies(allAgencies.items);
    } catch (e) {
      notificationController.addNotification({
        type: NotificationTypeEnum.Error,
        text: e.message,
      });
    }
  }

  // When the component initially mounts, the user profile is undefined.
  // As a result the agency id cannot be fetched.
  // Fetch agency details when the user profile is loaded.
  useEffect(() => {
    void refreshAgencyDetails();
  }, [userProfile]);

  const logout = () => {
    setAgencies([]);
    setAgencyDetails(undefined);
    setCurrentAgencyDetails(undefined);
    setDefaultCommission(undefined);
  };

  return {
    agencySettingsState: {
      agencyDetails,
      currentAgencyDetails,
    },
    updateCurrentAgencyDetailsField,
    updateCurrentAgencyDetailsNestedField,
    onSaveAgencyDetails,
    updateAgency,
    createAgency,
    formInputChangeHandler,
    refreshAgencyDetails,
    discardAgencyDetailsChanges,
    logout,
    defaultCommission,
    fetchAgencies,
    agencies,
  };
};
