import classnames from "classnames";
import React, { FC, useCallback, useMemo } from "react";

import { DOTS, usePaginationValues } from "./customHooks";
import styles from "./styles.css";
import { PaginationPropTypes, PaginationRangeType } from "./types";

const Pagination: FC<PaginationPropTypes> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange: PaginationRangeType = usePaginationValues({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = useCallback(() => onPageChange(currentPage + 1), [
    currentPage,
    onPageChange,
  ]);
  const onPrevious = useCallback(() => onPageChange(currentPage - 1), [
    currentPage,
    onPageChange,
  ]);

  const lastPage = paginationRange[paginationRange.length - 1];

  const getLeftNavigationArrow: JSX.Element = useMemo(
    () => (
      <li
        key="prev"
        className={classnames(
          styles.paginationItem,
          currentPage === 1 && styles.disabled
        )}
        onClick={onPrevious}
      >
        <div className={classnames(styles.arrow, styles.left)} />
      </li>
    ),
    [currentPage, onPrevious]
  );

  const getRightNavigationArrow: JSX.Element = useMemo(
    () => (
      <li
        key="next"
        className={classnames(
          styles.paginationItem,
          currentPage === lastPage && styles.disabled
        )}
        onClick={onNext}
      >
        <div className={classnames(styles.arrow, styles.right)} />
      </li>
    ),
    [currentPage, onNext]
  );

  return (
    <ul className={classnames(styles.paginationContainer)}>
      {getLeftNavigationArrow}

      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS item
        if (pageNumber === DOTS) {
          // "&#8230;" - DOTS unicode character
          return (
            <li
              key={`dots-${index}`}
              className={classnames(styles.paginationItem, styles.dots)}
            >
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={classnames(
              styles.paginationItem,
              pageNumber === currentPage && styles.selected
            )}
            onClick={() => onPageChange(Number(pageNumber))}
          >
            {pageNumber}
          </li>
        );
      })}
      {getRightNavigationArrow}
    </ul>
  );
};

export default Pagination;
