export const NUMBER_OF_INCLUSIONS_TO_DISPLAY = 3;

export const AMENITIES_PRIORITY = [
  "wifi",
  "internet",
  "air conditioning",
  "toiletries",
  "tv",
  "balcony",
  "safe",
  "fridge",
  "room service",
  "laundry service",
  "telephone",
  "bathrobe",
  "wheelchair accessible",
  "shower",
  "bath",
  "refrigerator",
  "bathroom",
  "washing machine",
  "kitchen",
  "hairdryer",
];

export const AMENITIES_PRIORITY_MAP = new Map(
  AMENITIES_PRIORITY.map((v, i) => [v, i])
);
