import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { AgentBookingData } from "@adl-gen/hotel/api";
import { LiveStatus } from "@adl-gen/hotel/booking";
import { GeneralContext, LoggedInContext } from "@app/app";
import layout from "@common/layout-lib.css";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { Button } from "@widgets/button/button";
import { ToggleButton } from "@widgets/button/toggle-button";
import DialogWindow from "@widgets/dialog-window";
import { IconSizeable } from "@widgets/icon/icon-sizeable";
import { Icons } from "@widgets/icon/icons";
import InputMultiSelect from "@widgets/input-multi-select";
import { InputText } from "@widgets/input-text/input-text";
import { isBookingCancellable } from "@widgets/itinerary/utils";
import { SpinnerOverlay } from "@widgets/loader/loader";

import { LIVE_STATUS_OPTIONS, PAYMENT_STATUS_OPTIONS } from "./constants";
import styles from "./styles.css";

export interface FiltersType {
  destinationName: string | null;
  paymentDueDate: string | null;
  paymentStatuses: number[];
  liveStatuses: LiveStatus[];
}

interface Props {
  onAddManualItem(): void;
}

export const DefaultBookingFilters: FiltersType = {
  destinationName: null,
  paymentDueDate: null,
  paymentStatuses: [],
  liveStatuses: [],
  // paymentDue: null,
};

const FiltersBar: FC<Props> = () => {
  const [isFilterBarOpen, setIsFilterBarOpen] = useState(false);
  const [destinationName, setDestinationName] = useState<string>("");
  const [selectedPaymentStatusKeys, setSelectedPaymentStatusesKeys] = useState<
    number[]
  >(DefaultBookingFilters.paymentStatuses);
  const [selectedLiveStatusKeys, setSelectedLiveStatusesKeys] = useState<
    number[]
  >(DefaultBookingFilters.liveStatuses);

  const [filters, setFilters] = useState<FiltersType>(DefaultBookingFilters);
  const isFiltersChanged = useRef(false);

  const [isLoading, setIsLoading] = useState(false);
  const [cancelConfirmationOpened, setCancelConfirmationOpened] = useState(
    false
  );

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );
  const {
    fetchBookings,
    searchResult,
    selectedBookings,
    cancelBooking,
    loadItineraryNumber,
    refreshSelectedQuotes,
  } = assertNotUndefined(useContext(LoggedInContext).itineraryController);

  useEffect(() => {
    if (isFiltersChanged.current) {
      fetchBookings({
        ...filters,
        paymentDueDate: {
          from: null,
          to: filters.paymentDueDate,
        },
      });
      isFiltersChanged.current = false;
    }
  }, [filters]);

  const itemsToCancel = useMemo(() => {
    if (searchResult.kind !== "value") {
      return [];
    }
    return (searchResult.value.value.items as AgentBookingData[])
      .filter(
        ({ bookingData }) =>
          bookingData.liveStatus !== LiveStatus.cancelled &&
          selectedBookings.includes(bookingData.bookingId)
      )
      .map(({ bookingData }) => ({
        id: bookingData.bookingId,
        number: bookingData.bookingNumber,
      }));
  }, [searchResult, selectedBookings]);

  const itineraryHasCancellableBookings = useMemo(() => {
    if (searchResult.kind !== "value") {
      return false;
    }

    return searchResult.value.value.items.some(isBookingCancellable);
  }, [searchResult]);

  function handleFilterChange(
    filterName: string,
    filterValue: string | number | string[] | number[] | null | boolean
  ) {
    if (!isFiltersChanged.current) {
      isFiltersChanged.current = true;
    }

    setFilters((data) => ({
      ...data,
      [filterName]: filterValue,
    }));
  }

  function closeFilterBar() {
    setIsFilterBarOpen(!isFilterBarOpen);
  }

  function handleDestinationNameChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    setDestinationName(value);
  }

  async function refresh() {
    setIsLoading(true);

    await refreshSelectedQuotes();

    setIsLoading(false);
  }

  const enterDestinationNameInputHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.currentTarget.blur();
    }
  };

  const blurDestinationNameInputHandler = (event) => {
    const value = event.currentTarget.value.toLowerCase() || null;
    if (filters.destinationName !== value) {
      handleFilterChange("destinationName", value);
    }
  };

  function handlePaymentStatusSelect(statusKey: number, checked: boolean) {
    const newParams = checked
      ? [...selectedPaymentStatusKeys, statusKey]
      : selectedPaymentStatusKeys.filter((key) => key !== statusKey);
    const isPaymentPending: boolean | null =
      newParams.length === 2 || newParams.length === 0 ? null : !!newParams[0];

    setSelectedPaymentStatusesKeys(newParams);
    handleFilterChange("isPaymentPending", isPaymentPending);
  }

  function handleLiveStatusSelect(statusKey: number, checked: boolean) {
    const newParams = checked
      ? [...selectedLiveStatusKeys, statusKey]
      : selectedLiveStatusKeys.filter((key) => key !== statusKey);

    setSelectedLiveStatusesKeys(newParams);
    handleFilterChange("liveStatuses", newParams);
  }

  function confirmCancellation() {
    if (itemsToCancel.length) {
      setCancelConfirmationOpened(true);
    } else {
      addNotification({
        text: "Please select at least one booking to cancel",
        type: NotificationTypeEnum.Information,
      });
    }
  }

  function cancelSingle(id: string) {
    return cancelBooking(id, true).catch(() => null);
  }

  async function cancelSelectedBookings() {
    setCancelConfirmationOpened(false);
    setIsLoading(true);

    const promises = itemsToCancel.map(({ id }) => cancelSingle(id));

    const results = await Promise.all(promises);

    await loadItineraryNumber();

    const errors: string[] = [];

    results.forEach((response, index) => {
      if (!response || response.kind !== "success") {
        errors.push(itemsToCancel[index].number);
      }
    });

    if (!errors.length) {
      addNotification({
        text: "All selected items are cancelled",
        type: NotificationTypeEnum.Success,
      });
    } else {
      addNotification({
        text: `The following items were not cancelled due to unexpected error: ${errors.join(
          ", "
        )}.`,
        type: NotificationTypeEnum.Warning,
      });
    }

    setIsLoading(false);
  }

  // TODO for postMVP
  // const [isPaymentDueSelected, setIsPaymentDueSelected] = useState(false);
  // function handlePaymentDueCheckboxChanged(checked: boolean) {
  //   if (checked) {
  //     const paymentDueDateFormatted = formatLocalDate(
  //       moment(new Date()).add("days", 7).toDate()
  //     );
  //
  //     handleFilterChange("paymentDue", paymentDueDateFormatted);
  //   } else {
  //     handleFilterChange("paymentDue", null);
  //   }
  //   setIsPaymentDueSelected(checked);
  // }

  return (
    <div>
      {isLoading && <SpinnerOverlay />}
      <div
        className={clsx(
          layout.horizontal,
          layout.justified,
          styles.filtersHeader
        )}
      >
        <div>
          <ToggleButton
            emphasis="medium"
            onClick={closeFilterBar}
            toggle={isFilterBarOpen}
          >
            Filter
          </ToggleButton>
        </div>
        <div className={clsx(layout.horizontal, layout.spaced)}>
          {/*Disabled for MVP https://xllabs.atlassian.net/browse/AG-505*/}
          {/*<Button emphasis="medium" onClick={onAddManualItem}>*/}
          {/*  Add manual item*/}
          {/*</Button>*/}
          {/*Disabled for MVP*/}
          {/*<Button emphasis="medium">Confirm items</Button>*/}
          {/*<Button emphasis="medium">Pay for items</Button>*/}
          {itineraryHasCancellableBookings && (
            <Button emphasis="medium" onClick={confirmCancellation}>
              Cancel items
            </Button>
          )}
          <Button onClick={refresh}>Refresh Quotes</Button>
        </div>
      </div>

      {isFilterBarOpen && (
        <div className={styles.filtersBody}>
          <InputText
            className={clsx(styles.destinationNameInput, styles.filterItem)}
            placeholder="Hotel name, City or State"
            value={destinationName}
            label
            onChange={handleDestinationNameChange}
            iconBefore={<IconSizeable size={17} icon={Icons.search} />}
            onBlur={blurDestinationNameInputHandler}
            onKeyPress={enterDestinationNameInputHandler}
          />
          <InputMultiSelect
            classes={{ container: styles.filterItem }}
            checkboxClasses={{
              proxy: styles.checkboxProxy,
              text: styles.checkboxText,
            }}
            placeholder="Payment Status"
            options={PAYMENT_STATUS_OPTIONS}
            selectedOptions={selectedPaymentStatusKeys}
            onOptionSelect={handlePaymentStatusSelect}
          />
          <InputMultiSelect
            classes={{ container: styles.filterItem }}
            placeholder="Live Status"
            options={LIVE_STATUS_OPTIONS}
            selectedOptions={selectedLiveStatusKeys}
            onOptionSelect={handleLiveStatusSelect}
          />

          {/*<Checkbox*/}
          {/*  text="Payment due next 7 days"*/}
          {/*  checked={isPaymentDueSelected}*/}
          {/*  onChange={handlePaymentDueCheckboxChanged}*/}
          {/*/>*/}
        </div>
      )}

      <DialogWindow
        open={cancelConfirmationOpened}
        onCancel={() => setCancelConfirmationOpened(false)}
        onOk={cancelSelectedBookings}
        text={`You are about to cancel the following bookings: ${itemsToCancel
          .map(({ number }) => number)
          .join(", ")}.`}
      />
    </div>
  );
};

export default memo(FiltersBar);
