import * as yup from "yup";

import { ResendInviteForm, SetPasswordForm } from "@pages/invite-page/types";
import {
  ERROR_TEXT,
  validatePassword,
  ValidationRule,
  VALIDATION_REGEX,
} from "@util/validation";

export const SetPasswordSchema = yup
  .object({
    password: yup
      .string()
      .required(ERROR_TEXT[ValidationRule.RequiredField])
      .test("new-password", (value: string, ctx) => {
        const errorMessage = validatePassword(value);

        if (errorMessage) {
          return ctx.createError({ message: errorMessage });
        }

        return true;
      }),
    confirmPassword: yup
      .string()
      .equals(
        [yup.ref("password")],
        "The password entry fields do not match. Enter the same password in both fields and try again."
      )
      .required(ERROR_TEXT[ValidationRule.RequiredField]),
  })
  .required();

export const SetPasswordDefaultValues: SetPasswordForm = {
  password: "",
  confirmPassword: "",
};

export const ResendInviteSchema = yup
  .object({
    email: yup
      .string()
      .required(ERROR_TEXT[ValidationRule.RequiredField])
      .matches(
        VALIDATION_REGEX[ValidationRule.Email],
        ERROR_TEXT[ValidationRule.Email]
      ),
  })
  .required();

export const SetResendInviteDefaultValues: ResendInviteForm = {
  email: "",
};
