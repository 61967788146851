import clsx from "clsx";
import React, { FC } from "react";

import { ImageSlashIcon } from "@common/icon/icons";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import { PriceProps } from "@widgets/price/price";
import { Skeleton } from "@widgets/skeleton/skeleton";
import StarRating from "@widgets/star-rating";

import styles from "./styles.css";

interface PropTypes {
  hotel: HotelListingResultWithId;
  onHotelClick?(hotelId: string): void;
  className?: string;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
}

export const HotelMapCard: FC<PropTypes> = ({
  hotel,
  onHotelClick,
  className,
  onMouseEnter,
  onMouseLeave,
}) => {
  const {
    hotelName,
    priceDetails,
    hotelStarRating,
    hotelCoverImageURL,
  } = hotel.details;

  // Determine if the price block should be shown, if price info is loading,
  // or has loaded and is available.
  const showPrice =
    priceDetails.kind === "loading" ||
    (priceDetails.kind === "value" && priceDetails.value.kind === "available");

  const ratePricing: PriceProps =
    priceDetails.kind === "value" && priceDetails.value.kind === "available"
      ? {
          // Currently the props passed in have a 'number' as the value however this should be a string
          value: priceDetails.value.totalPrice.toString(),
          currency: priceDetails.value.currency,
        }
      : {};

  /** Renders the hotel cover image, if one isn't supplied renders the default centered image slash icon */
  function renderCoverImage() {
    return hotelCoverImageURL ? (
      <div
        className={styles.hotelCoverImage}
        style={{ backgroundImage: `url(${hotelCoverImageURL})` }}
      />
    ) : (
      <div className={styles.noHotelCoverImage}>
        <ImageSlashIcon className={styles.imageSlashIcon} size="2x" />
      </div>
    );
  }

  function renderPrice() {
    const price = Math.ceil(Number(ratePricing.value || 0));

    return (
      <div className={styles.priceDetails}>
        {showPrice ? (
          <>
            <span className={styles.priceValue}>
              <Skeleton className={styles.amountSkeleton}>
                {price || undefined}
              </Skeleton>
            </span>
            <span className={styles.currency}>
              <Skeleton className={styles.currencySkeleton}>
                {ratePricing.currency}
              </Skeleton>
            </span>
          </>
        ) : (
          <span className={styles.soldOut}>Sold out</span>
        )}
      </div>
    );
  }

  function handleHotelClick() {
    if (onHotelClick) {
      onHotelClick(hotel.id);
    }
  }

  return (
    <div
      className={clsx(className, styles.hotelMapResult)}
      onClick={handleHotelClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {renderCoverImage()}
      <div className={styles.infoSection}>
        <StarRating
          classname={styles.rating}
          starRating={hotelStarRating}
          size="xs"
        />
        <div className={styles.hotelName}>{hotelName}</div>
        {renderPrice()}
      </div>
    </div>
  );
};

export default HotelMapCard;
