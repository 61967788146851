import { ABORT_ERROR_MESSAGE } from "@constants/common";
import { NotificationController } from "@controllers/notification-controller";
import { NotificationTypeEnum } from "@models/common";

import { Loading } from "./loading";

/** Updates some state based on the result of a promise */
export async function fromPromise<T>(
  futureValue: Promise<T>,
  setter: (val: Loading<T>) => void,
  addNotification: NotificationController["addNotification"]
): Promise<void> {
  // Initially we are always in the loading state
  setter({ kind: "loading" });

  // Resolve promise and update loader state in the background
  await futureValue
    .then((value) => {
      setter({
        kind: "value",
        value,
      });
    })
    .catch((error) => {
      // Ignore showing the AbortError notification because it's manual and controlled
      if (error.message !== ABORT_ERROR_MESSAGE) {
        setter({
          kind: "error",
          error: `${error}`,
        });

        addNotification({
          type: NotificationTypeEnum.Error,
          text: "Unexpected error occurred",
        });
      }
    });
}
