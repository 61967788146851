import clsx from "clsx";
import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";

import layout from "@common/layout-lib.css";
import { ROUTE_PATH } from "@constants/common";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { assertValueLoaded } from "@util/agentus-utis";
import { Button } from "@widgets/button/button";
import {
  BookingControl,
  BookingSearchResultContext,
} from "@widgets/itinerary/booking";

import styles from "./styles.css";

interface BookingData {
  itineraryNumber: string;
  agentName: string;
}

const BookingSearchAction: FC<BookingControl> = ({
  booking,
  toggleBookingDetailsVisible,
}) => {
  const searchResultState = useContext(BookingSearchResultContext);
  const mapResultState = (): BookingData => {
    const queryResp = assertValueLoaded(searchResultState.searchResult);
    if (queryResp.kind === "agentBookingData") {
      const selectedBooking = assertNotUndefined(
        queryResp.value.items.find(
          (item) => item.bookingData.bookingId === booking.bookingId
        )
      );
      return {
        itineraryNumber: selectedBooking.bookingData.itineraryNumber,
        agentName: selectedBooking.bookingData.userName,
      };
    } else if (queryResp.kind === "backOfficeUserBookingData") {
      const selectedBooking = assertNotUndefined(
        queryResp.value.items.find(
          (item) => item.bookingData.bookingId === booking.bookingId
        )
      );
      return {
        itineraryNumber: selectedBooking.bookingData.itineraryNumber,
        agentName: selectedBooking.bookingData.userName,
      };
    } else {
      assertNever(queryResp);
    }
  };

  const bookingData = mapResultState();

  return (
    <div className={styles.bookingAction}>
      <div className={styles.links}>
        <div className={clsx(layout.horizontal, layout.left, layout.spaced)}>
          <label>Agent</label>
          <Link to={ROUTE_PATH.Root}>{bookingData.agentName}</Link>
        </div>
        <div className={clsx(layout.horizontal, layout.left, layout.spaced)}>
          <label>Itinerary ID</label>
          <Link to={`${ROUTE_PATH.Itinerary}/${bookingData.itineraryNumber}`}>
            {bookingData.itineraryNumber}
          </Link>
        </div>
      </div>

      <Button
        className={styles.detailsButton}
        onClick={toggleBookingDetailsVisible}
        classes={{ container: styles.detailsButtonInner }}
      >
        View Details
      </Button>
    </div>
  );
};

export default BookingSearchAction;
