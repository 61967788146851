import React, { FC } from "react";

import {
  AdultFullName,
  AdultTitle,
  RoomOccupancyDetails,
} from "@adl-gen/hotel/booking";
import layout from "@common/layout-lib.css";
import { FullName } from "@models/common";
import { getBoardDisplayString } from "@util/agentus-utis";
import styles from "@widgets/itinerary/booking/booking-details/styles.css";

import { BookingDetailsData } from ".";
import {
  AMENITIES_PRIORITY,
  AMENITIES_PRIORITY_MAP,
  NUMBER_OF_INCLUSIONS_TO_DISPLAY,
} from "./constant";

interface Props {
  bookingData: BookingDetailsData;
  room: RoomOccupancyDetails;
}

const RoomRow: FC<Props> = ({ bookingData, room }) => {
  function renderFullNameRow(fullName: FullName, index, isChild: boolean) {
    const { firstName, lastName } = fullName;
    const title = isChild ? "" : AdultTitle[(fullName as AdultFullName).title];
    return (
      <div className={styles.text} key={`${firstName}_${lastName}_${index}`}>
        {`${title} ${firstName} ${lastName}`}
      </div>
    );
  }

  function renderInclusions() {
    const categorised = bookingData.inclusions.map((inclusion) => ({
      category:
        AMENITIES_PRIORITY.find((a) => inclusion.toLowerCase().includes(a)) ??
        "unknown",
      inclusion,
    }));

    const prioritised = [...categorised].sort((i1, i2) => {
      const i1Priority =
        AMENITIES_PRIORITY_MAP.get(i1.category) ?? AMENITIES_PRIORITY.length;
      const i2Priority =
        AMENITIES_PRIORITY_MAP.get(i2.category) ?? AMENITIES_PRIORITY.length;
      if (i1Priority === i2Priority) {
        return 0;
      }
      return i1Priority < i2Priority ? -1 : 1;
    });

    return prioritised
      .slice(0, NUMBER_OF_INCLUSIONS_TO_DISPLAY)
      .map((i) => i.inclusion)
      .join(", ");
  }

  return (
    <div className={styles.row}>
      <div className={layout.vertical}>
        {room.adults.map((name, index) =>
          renderFullNameRow(name, index, false)
        )}
        {room.childPassengers.map(({ name }, index) =>
          renderFullNameRow(name, index, true)
        )}
      </div>
      <div className={styles.text}>{bookingData.room}</div>

      <div className={styles.text}>
        {getBoardDisplayString(bookingData.board)}
      </div>

      <div className={styles.text}>{renderInclusions()}</div>
    </div>
  );
};

export default RoomRow;
