import clsx from "clsx";
import React, { MouseEvent, useContext, useState } from "react";

import { BookingContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import { assertValueLoaded } from "@util/agentus-utis";
import { PaymentFormMode } from "@widgets/payment-form/constants";

import PaymentForm from "../payment-form/payment-form";
import styles from "./confirmation-input.css";

interface TabProps {
  type: PaymentFormMode;
  label: string;
}

export const ConfirmationInput = () => {
  const [formType, setFormType] = useState<PaymentFormMode>(
    PaymentFormMode.PayNow
  );

  const {
    completeBookingProps,
    payForBooking,
    confirmBooking,
    isBookingDetailsFormValid,
    setPriceType,
    priceType,
  } = assertNotUndefined(useContext(BookingContext));

  const bookingProps = assertNotUndefined(
    assertValueLoaded(completeBookingProps)
  );
  if (
    bookingProps.paymentDetails.kind !== "value" ||
    bookingProps.paymentDetails.value.kind !== "details"
  ) {
    throw Error("Trying to display payment details before they are ready");
  }

  const paymentDetails = bookingProps.paymentDetails.value.value;

  const bookAndHoldEnabled =
    bookingProps.selectedPackage.cancellationPolicy.kind !== "noCancellation" &&
    paymentDetails.canBookAndHold;

  const handleClickFormType = (ev: MouseEvent<HTMLDivElement>) => {
    setFormType(ev.currentTarget.id as PaymentFormMode);
  };

  const Tab = ({ type, label }: TabProps) => {
    return (
      <div
        className={clsx(
          styles.confirmationTab,
          type === formType && styles.selected
        )}
        onClick={handleClickFormType}
        id={type}
      >
        <span className={styles.confirmationTabText}>{label}</span>
      </div>
    );
  };

  return (
    <div className={styles.confirmationInput}>
      {bookAndHoldEnabled && (
        <div className={styles.tabList}>
          <Tab type={PaymentFormMode.PayNow} label="Pay Now" />
          <Tab type={PaymentFormMode.BookAndHold} label="Book and Hold" />
        </div>
      )}
      <PaymentForm
        netAmount={paymentDetails.netPriceWithTax}
        grossAmount={paymentDetails.grossPriceWithTax}
        mode={formType}
        onSubmitCreditCard={payForBooking}
        confirmBooking={confirmBooking}
        isExternalDataValid={isBookingDetailsFormValid}
        paymentMode={priceType}
        onChangePaymentMode={setPriceType}
      />
    </div>
  );
};
