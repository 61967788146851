import clsx from "clsx";
import React, { FC } from "react";
import { Link } from "react-router-dom";

import styles from "./styles.css";

interface Props {
  selected?: boolean;
  href?: string;
  onClick?(): void;
  className?: string;
}

// these properties are passed by Tab component
interface InnerProps extends Props {
  selected?: boolean;
}

const TabOptionInner: FC<InnerProps> = ({
  children,
  selected,
  href,
  onClick,
  className,
}) => {
  return href ? (
    <Link className={clsx(styles.root, className)} to={href}>
      <span className={clsx(styles.content, selected && styles.selected)}>
        {children}
      </span>
    </Link>
  ) : (
    <div className={clsx(styles.root, className)} onClick={onClick}>
      <span className={clsx(styles.content, selected && styles.selected)}>
        {children}
      </span>
    </div>
  );
};

const TabOption: FC<Props> = (props) => {
  return <TabOptionInner {...props} />;
};

export default TabOption;
