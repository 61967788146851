import clsx from "clsx";
import React, { FC } from "react";

import layout from "@common/layout-lib.css";
import currencies from "@constants/currencies.json";
import { AgencyManageProperties } from "@pages/settings/types";
import Input from "@widgets/input";
import { InputSelect, InputSelectOption } from "@widgets/input-select";

import styles from "./styles.css";

const GeneralDetails: FC<AgencyManageProperties> = ({
  agencyDetails,
  updateCurrentAgencyDetailsField,
  getFieldError,
  fieldsWithError,
  requiredFields,
  formInputChangeHandler,
}) => {
  if (!agencyDetails) {
    return null;
  }

  return (
    <div className={clsx(layout.vertical, styles.generalDetails)}>
      <Input
        name="name"
        label="Agency Name"
        value={agencyDetails?.value.name}
        onChange={formInputChangeHandler}
        required={requiredFields?.includes("name")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "name")
        }
      />

      <Input
        name="emailAddress"
        label="Contact Email"
        value={agencyDetails?.value.emailAddress}
        onChange={formInputChangeHandler}
        required={requiredFields?.includes("emailAddress")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "emailAddress")
        }
      />
      <InputSelect
        className={styles.currencyDropdown}
        onChange={(val: string) =>
          updateCurrentAgencyDetailsField("currency", val)
        }
        label="Currency"
        value={agencyDetails?.value.currency}
        required={requiredFields?.includes("currency")}
        searchable
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "currency")
        }
      >
        {Object.keys(currencies).map((currencyCode) => (
          <InputSelectOption key={currencyCode} value={currencyCode}>
            {`${currencyCode} \(${currencies[currencyCode].name}\)`}
          </InputSelectOption>
        ))}
      </InputSelect>
    </div>
  );
};

export default GeneralDetails;
