import clsx from "clsx";
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";

import useOutsideClick from "@common/hooks/outside-click";
import { GooglePlacesKey, GooglePlaceType } from "@constants/common";
import { usePrevious } from "@hooks/usePrevious";
import Input, { InputProps } from "@widgets/input";

import selectStyles from "../input-select/input-select.css";
import styles from "./styles.css";

const FETCH_PREDICTIONS_DELAY = 500;

const googlePlacesEnvironment = compose<
  InputPlaceAutoCompleteProps,
  InputPlaceAutoCompleteProps
>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GooglePlacesKey}&language=en&v=3.exp&libraries=places`,
    loadingElement: <div />,
    containerElement: <div />,
    mapElement: <div style={{ display: "none" }} />,
  }),
  withScriptjs,
  withGoogleMap
);

interface InputPlaceAutoCompleteProps extends Omit<InputProps, "onChange"> {
  onChange(value: { longName: string; shortName: string }): void;
  placeTypes?: GooglePlaceType[];
  restrictions?: {
    country: string | null;
    state: string | null;
  };
}

const InputPlaceAutocomplete: FC<InputPlaceAutoCompleteProps> = ({
  placeTypes,
  restrictions,
  value,
  onChange,
  ...props
}) => {
  const [tempValue, setTempValue] = useState(value);

  const { ref, isOpen, toggleIsOpen } = useOutsideClick<HTMLDivElement>();

  const {
    placePredictions,
    getPlacePredictions,
    placesService,
    isPlacePredictionsLoading,
  } = usePlacesService({
    debounce: FETCH_PREDICTIONS_DELAY,
  });

  const prevPredictions = usePrevious(placePredictions);

  const mappedPredictions = useMemo(
    () =>
      placePredictions.map(({ place_id, structured_formatting }) => ({
        id: place_id,
        value: structured_formatting.main_text,
      })),
    [placePredictions]
  );

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  function onInputChange(e: ChangeEvent<HTMLInputElement>) {
    const val = e.target.value;
    setTempValue(val);

    getPlacePredictions({
      input: restrictions?.state ? `${restrictions.state} ${val}` : val,
      types: placeTypes,
      componentRestrictions: restrictions?.country
        ? {
            country: restrictions.country,
          }
        : undefined,
    });

    if (!isOpen) {
      toggleIsOpen();
    }
  }

  function onSelect(id: string) {
    placesService?.getDetails(
      {
        placeId: id,
        fields: ["name", "address_components"],
      },
      (place) => {
        if (place) {
          const shortName = place.address_components?.find(
            ({ long_name }) => long_name === place.name
          )?.short_name;
          onChange({
            longName: place.name as string,
            shortName: shortName as string,
          });
        }
      }
    );

    toggleIsOpen();
  }

  // These two variables allows to hide old irrelevant results when typing new query
  const prevPredictionsPresent =
    prevPredictions &&
    ((prevPredictions as unknown) as google.maps.places.AutocompletePrediction[])
      .length;

  const hideOldResults = prevPredictionsPresent && isPlacePredictionsLoading;

  return (
    <div className={styles.inputPlaceAutocomplete} ref={ref}>
      <Input
        value={tempValue}
        onChange={onInputChange}
        {...props}
        className={clsx(styles.inputInner, props.className)}
      />
      {!isOpen || hideOldResults ? null : (
        <ul className={clsx(selectStyles.selectList, styles.predictionsList)}>
          {mappedPredictions.map(({ id, value: optionValue }) => (
            <li
              className={selectStyles.option}
              key={id}
              onClick={() => onSelect(id)}
            >
              {optionValue}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default googlePlacesEnvironment(InputPlaceAutocomplete);
