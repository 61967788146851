import moment from "moment";

import { RoomOccupancyDetails } from "@adl-gen/hotel/booking";

export function renderLatestBookingDuration(checkIn: string, checkOut: string) {
  const difference = moment(checkOut).diff(moment(checkIn), "days");

  return `${difference} ${difference > 1 ? "nights" : "night"}`;
}

export function renderLatestBookingLeadGuest(rooms: RoomOccupancyDetails[]) {
  const { lastName, firstName } = rooms[0].adults[0];

  return `${firstName} ${lastName}`;
}
