import clsx from "clsx";
import React, { FC } from "react";

import { HotelViewEnum } from "@controllers/customHooks/useHotelView";

import { ViewTypeLabel } from "./constants";
import styles from "./styles.css";

interface PropTypes {
  currentView: HotelViewEnum;
  changeView(): void;
}

const MapListViewButton: FC<PropTypes> = ({ currentView, changeView }) => (
  <div
    className={clsx(
      styles.view,
      currentView === HotelViewEnum.MapView ? styles.listView : styles.mapView
    )}
    onClick={changeView}
  >
    <div className={styles.viewButton}>
      {
        ViewTypeLabel[
          currentView === HotelViewEnum.MapView
            ? HotelViewEnum.ListView
            : HotelViewEnum.MapView
        ]
      }
    </div>
  </div>
);

export default MapListViewButton;
