import React from "react";

import { UnavailableIcon } from "@src/assets/svg-components";

interface PropsHandledByClearable { onClear?(): void }
export interface PropsProvidedByClearable { iconAfter?: React.JSX.Element, onClickIconAfter?(): void }

export function Clearable<Props extends PropsProvidedByClearable>(
    Component: React.ComponentType<Props>
) {
    const Wrapped = (props: Omit<Props, keyof PropsProvidedByClearable> & PropsHandledByClearable) => {
        const { onClear, ...restProps } = props
        return props.onClear ?
            <Component  {...restProps as unknown as Props} iconAfter={<UnavailableIcon />} onClickIconAfter={onClear} />
            : <Component {...restProps as unknown as Props} />
    };
    return Wrapped
};

export const createClearHandler = (fieldName, searchParams, clearField) => {
    return searchParams[fieldName] ? clearField(fieldName) : undefined;
};

export default Clearable