import { MutableRefObject, useCallback, useRef, useState } from "react";

import { HotelListingDetails } from "@adl-gen/hotel/api";
import { Loading } from "@models/loading";

export interface CachedHotels {
  hotels: Set<string>;
  priceToken: string;
}

const DEFAULT_CACHED_HOTELS: CachedHotels = {
  priceToken: "",
  hotels: new Set(),
};

export const useHotelListing = (): {
  addToHotelList: MutableRefObject<boolean>;
  hotelListing: Loading<HotelListingDetails[]> | undefined;
  allCachedHotels: MutableRefObject<CachedHotels>;
  setHotelListing(hotelsData?: Loading<HotelListingDetails[]>): void;
} => {
  /*
   * A flag that indicates whether the previous values of hotels list should be replaced with new ones
   * or whether new values should be added to the list to the existing ones
   */
  const addToHotelList = useRef<boolean>(false);

  const allCachedHotels = useRef<CachedHotels>(DEFAULT_CACHED_HOTELS);

  const [hotelListing, setHotelList] = useState<
    Loading<HotelListingDetails[]> | undefined
  >(undefined);

  const setHotelListing = useCallback(
    (hotelsData?: Loading<HotelListingDetails[]>) => {
      if (
        addToHotelList.current &&
        hotelListing?.kind === "value" &&
        hotelsData?.kind === "value"
      ) {
        setHotelList({
          kind: "value",
          value: [...hotelListing.value, ...hotelsData.value],
        });
        addToHotelList.current = false;
      } else {
        setHotelList(hotelsData);
      }
    },
    [addToHotelList, hotelListing, setHotelList]
  );

  return {
    addToHotelList,
    hotelListing,
    setHotelListing,
    allCachedHotels,
  };
};
