import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { BaseProps } from "@common/base";
import { Icons } from "@common/icon/svg";

import styles from "./checkbox.css";

/** Props to create a Checkbox */
export interface CheckboxProps extends BaseProps {
  /** Optional initial check state of the checkbox */
  checked?: boolean;
  /** Optional text label dsiplayed next to the checkbox  */
  text?: string | JSX.Element;
  /** Determines if the label will be displayed as a clamped single line, or as multiline text */
  multiline?: boolean;
  /** Determines of checkbox is enabled, if disable tthe button will not handle events */
  disabled?: boolean;
  /** Radio size */
  size?: "small" | "medium" | "large";
  /** Callback on change event */
  onChange?(checked: boolean): void;

  classes?: {
    proxy?: string;
    text?: string;
  };
}

/**
 * Checkbox Component, replaces standard input type=checkbox wrapped in a label
 */

export const Checkbox = (props: CheckboxProps): JSX.Element => {
  /**
   * Tracks the checked/unchecked state of the checkbox
   */
  const [checked, setChecked] = useState(props.checked || false);

  /**
   * References the label wrapper of the component, used to handle
   * keypress events for checking/unchecking internal input checkbox
   */
  const labelRef = useRef<HTMLLabelElement>(null);

  /**
   * If the checked state is explicitly passed in then override the internal state
   */
  useEffect(() => {
    if (props.checked !== undefined) {
      setChecked(props.checked);
    }
  }, [props.checked]);

  const changeHandler = (event) => {
    setChecked(event.target.checked);

    if (props.onChange !== undefined) {
      props.onChange(event.target.checked);
    }
  };

  const keyPressHandler = (event) => {
    if (labelRef.current !== null) {
      const labelClass = labelRef.current.getAttribute("class");
      const targetClass = event.target.getAttribute("class");

      if (
        labelClass !== null &&
        labelClass === targetClass &&
        (event.key === " " || event.key === "Enter")
      ) {
        setChecked(!checked);
      }
    }
  };

  const clickHandler = () => {
    labelRef.current?.focus();
  };

  const classes = clsx(
    props.className,
    styles.checkbox,
    props.size && styles[props.size],
    props.text !== undefined && styles.shim,
    props.multiline === true && styles.multiline
  );

  return (
    <label
      ref={labelRef}
      className={classes}
      tabIndex={0}
      onKeyPress={keyPressHandler}
      onClick={clickHandler}
    >
      <div className={styles.container}>
        <input
          type="checkbox"
          checked={checked}
          disabled={props.disabled}
          onChange={changeHandler}
          tabIndex={-1}
        />
        <div className={clsx(styles.checkboxProxy, props.classes?.proxy)}>
          <div className={styles.tickIcon}>{Icons.tick}</div>
          <div className={styles.dashIcon}>{Icons.dash}</div>
        </div>
        {props.text && (
          <span className={clsx(props.classes?.text, styles.text)}>
            {props.text}
          </span>
        )}
      </div>
    </label>
  );
};
