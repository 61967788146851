import { AppUserType, UserStatus } from "@adl-gen/hotel/db";

export const STATUS_MAP: Record<
  UserStatus,
  { label: string; color: string; background: string }
> = {
  [UserStatus.activated]: {
    label: "Activated",
    color: "#00C48C",
    background: "#E6F9F4",
  },
  [UserStatus.pending]: {
    label: "Pending",
    color: "#FDBC22",
    background: "#FFF8E9",
  },
  [UserStatus.deactivated]: {
    label: "Deactivated",
    color: "#8A9AA7",
    background: "#F1F6F9",
  },
  [UserStatus.deleted]: {
    label: "Deactivated",
    color: "#8A9AA7",
    background: "#F1F6F9",
  },
};

export const ROLE_MAP: Record<
  AppUserType,
  { label: string; color: string; background: string }
> = {
  [AppUserType.agent]: {
    label: "Agent",
    color: "#0FA6ED",
    background: "#E7F6FD",
  },
  [AppUserType.admin]: {
    label: "Admin",
    color: "#0FA6ED",
    background: "#E7F6FD",
  },
  [AppUserType.agencyAdmin]: {
    label: "Agency admin",
    color: "#0FA6ED",
    background: "#E7F6FD",
  },
  [AppUserType.backOffice]: {
    label: "Backoffice",
    color: "#0FA6ED",
    background: "#E7F6FD",
  },
};
