import clsx from "clsx";
import moment from "moment/moment";
import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { AgentBookingData, BookingSearchQueryResp } from "@adl-gen/hotel/api";
import { ArrowPlainRight } from "@assets/svg-components";
import { ROUTE_PATH } from "@constants/common";
import currencies from "@constants/currencies.json";
import {
  renderLatestBookingDuration,
  renderLatestBookingLeadGuest,
} from "@pages/dashboard/latest-bookings/utils";
import { Price } from "@widgets/price/price";

import { LATEST_BOOKING_DATE_FORMAT } from "../constant";
import styles from "./styles.css";

interface LatestBookingsTableProps {
  latestBookings: BookingSearchQueryResp;
}

const LatestBookingsTable: FC<LatestBookingsTableProps> = ({
  latestBookings,
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);

  const [bodyHasOverflow, setBodyHasOverflow] = useState(false);

  useEffect(() => {
    if (bodyRef.current) {
      setBodyHasOverflow(
        bodyRef.current.clientHeight < bodyRef.current.scrollHeight
      );
    }
  }, [latestBookings]);

  return (
    <div className={styles.list}>
      <div
        className={clsx(styles.listHead, bodyHasOverflow && styles.listHeadGap)}
      >
        <span>Lead Passenger</span>
        <span>Booking #</span>
        <span>Details</span>
        <span>Duration</span>
        <span>Gross Rate</span>
        <span />
      </div>
      <div className={styles.listBody} ref={bodyRef}>
        {(latestBookings.value.items as AgentBookingData[]).map(
          (
            {
              bookingData: {
                checkInDate,
                checkOutDate,
                created,
                roomName,
                bookingNumber,
                itineraryNumber,
                rooms,
              },
              bookingPrice: { grossPriceWithTax },
            },
            index
          ) => (
            <Link
              key={index}
              className={styles.booking}
              to={`${ROUTE_PATH.Itinerary}/${itineraryNumber}`}
            >
              <span className={styles.bookingColumn}>
                {renderLatestBookingLeadGuest(rooms)}
              </span>
              <div className={styles.bookingColumn}>{bookingNumber}</div>
              <div className={clsx(styles.bookingColumn, styles.dateAndType)}>
                <span>
                  {moment(created).format(LATEST_BOOKING_DATE_FORMAT)}
                </span>
                <span>{roomName.split(",")[0]}</span>
              </div>
              <div className={styles.bookingColumn}>
                {renderLatestBookingDuration(checkInDate, checkOutDate)}
              </div>
              <div className={styles.bookingColumn}>
                <Price
                  value={grossPriceWithTax.value}
                  currency={grossPriceWithTax.currency}
                  size="small"
                  sign={currencies[grossPriceWithTax.currency].symbol_native}
                  fractionDigits={0}
                />
              </div>

              <div className={clsx(styles.bookingColumn, styles.arrow)}>
                <ArrowPlainRight />
              </div>
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default LatestBookingsTable;
