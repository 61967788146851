import classnames from "classnames";
import React from "react";

import layout from "../../common/layout-lib.css";

import styles from "./breadcrumb.css";

export interface BreadcrumbProps {
  // Optional additional class to apply to the wrapper element
  className?: string;
  // Optional additional classes to apply to internal elements
  classes?: {
    breadcrumb?: string;
    pageSection?: string;
    pageSectionContainer?: string;
  };
  items: string[];
  // Optional sibling component in breadcrumb header
  sibling?: JSX.Element;
}

export const Breadcrumb = ({
  className,
  classes = {},
  items,
  sibling,
}: BreadcrumbProps): JSX.Element => {
  return (
    <div
      className={classnames(layout.horizontal, layout.justified, layout.middle)}
    >
      <ol
        className={classnames(styles.breadcrumb, className, classes.breadcrumb)}
      >
        {items.map((current) => {
          return <li key={current}>{current}</li>;
        })}
      </ol>
      {sibling}
    </div>
  );
};
