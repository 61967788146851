import clsx from "clsx";
import * as React from "react";

import * as SVG from "./icons-svg";
import styles from "./icons.css";

/** All icons for easy iterating */
export const ALL_ICONS: {
  name: string;
  Icon(props: IconProps): JSX.Element;
}[] = [];

export type IconSizes = "xs" | "sm" | "2x" | "3x" | "4x" | "5x" | "6x";

export interface IconProps {
  /** Additional classes */
  className?: string;
  /** Size otherwise medium */
  size?: IconSizes;
  /** Title displayed on hovering */
  title?: string;
}

function makeIconComponent(
  name: string,
  svg: JSX.Element
): (props: IconProps) => JSX.Element {
  const Icon = (props: IconProps) => {
    const { title, size, className } = props;
    const sizeStyle = size ? styles[`icon-${size}`] : "";
    return (
      <div title={title} className={clsx(styles.icon, sizeStyle, className)}>
        {svg}
      </div>
    );
  };
  ALL_ICONS.push({
    name,
    Icon,
  });
  return Icon;
}

export const RightArrowIcon = makeIconComponent(
  "right-arrow",
  SVG.RightArrowSvg
);
export const LeftArrowIcon = makeIconComponent("left-arrow", SVG.LeftArrowSvg);
export const UsersIcon = makeIconComponent("users-icon", SVG.UsersIconSvg);
export const StarIcon = makeIconComponent("star", SVG.StarIconSvg);
export const HalfStarIcon = makeIconComponent("star", SVG.HalfStarIconSvg);
export const FilledStarIcon = makeIconComponent(
  "filled-star",
  SVG.FilledStarIconSvg
);
export const MarkerIcon = makeIconComponent("marker", SVG.MarkerIconSvg);
export const TimesCircleFilledIcon = makeIconComponent(
  "times-circle-filled",
  SVG.TimesCircleFilledIconSvg
);
export const CheckCircleFilledIcon = makeIconComponent(
  "check-circle-filled",
  SVG.CheckCircleFilledIconSvg
);
export const InfoCircleIcon = makeIconComponent(
  "info-circle",
  SVG.InfoCircleIconSvg
);
export const CheckIcon = makeIconComponent("check", SVG.CheckIconSvg);
export const SlidersIcon = makeIconComponent("sliders", SVG.SlidersIconSvg);
export const MenuIcon = makeIconComponent("menu", SVG.MenuIconSvg);
export const SearchIcon = makeIconComponent("search", SVG.SearchIconSvg);
export const TripAdvisorIcon = makeIconComponent(
  "trip-advisor",
  SVG.TripAdvisorIconSvg
);
export const ImageSlashIcon = makeIconComponent(
  "image-slash",
  SVG.ImageSlashIconSvg
);
export const AngleDownIcon = makeIconComponent(
  "angle-down",
  SVG.AngleDownIconSvg
);
export const AngleUpIcon = makeIconComponent("angle-up", SVG.AngleUpIconSvg);
export const EyeIcon = makeIconComponent("angle-up", SVG.EyeIconSvg);
export const HideIcon = makeIconComponent("angle-up", SVG.HideIconSvg);
export const CloseUpIcon = makeIconComponent("close", SVG.CloseIconSvg);
export const FilterIcon = makeIconComponent("filter", SVG.FilterIconSvg);
