import { VisuallyHidden } from "@widgets/visually-hidden/visually-hidden";
import clsx from "clsx";
import React, { FC } from "react";

import styles from "./spinnerIcon.css";

/** Dynamic spinner icon to show loading progress */

type SpinnerSize = "small" | "medium" | "large";

interface Props {
  size?: SpinnerSize;
  className?: string;
}

export const SpinnerIcon: FC<Props> = ({ size, className }) => {
  return (
    <div className={clsx(styles.spinner, size && styles[size], className)}>
      <VisuallyHidden>Loading...</VisuallyHidden>
    </div>
  );
};
