import { Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";

import type { PhoneNumber } from "@adl-gen/ids/common";
import { HTML_REGEX } from "@constants/common";
import {
  generateGuestNamesFromOccupancy,
  getBoardDisplayString,
  getCountryNameByCode,
} from "@util/agentus-utis";
import type { SelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";

import detailsIcon from "./assets/details.png";
import hotelIcon from "./assets/hotel.png";
import knowBeforeGoIcon from "./assets/knowBeforeGo.png";
import logo from "./assets/logo.png";
import step1 from "./assets/step1.png";
import step2 from "./assets/step2.png";
import step3 from "./assets/step3.png";
import step5 from "./assets/step5.png";
import summaryIcon from "./assets/summary.png";
import warnIcon from "./assets/warn.png";
import styles from "./styles";

// styles for PDF are generated only once during the first call

const Footer = ({ bookingNumber }: { bookingNumber?: string }) => {

  return (
    <View fixed style={styles.footer}>
      <View style={{ flexDirection: "row", flex: 1 }} >
        {bookingNumber &&
          <>
            <Text style={styles.label}>AGENTUS Booking ID: </Text>
            <Text>{bookingNumber}</Text>
          </>
        }
      </View>
      <View style={{ flexDirection: "row", justifyContent: "center", flex: 1 }}>
        <Text style={styles.label}>AGENTUS </Text>
        <Text>Travel Platforms</Text>
      </View>

      <View style={{ flexDirection: "row", justifyContent: "flex-end", flex: 1 }}>
        <Text>In partnership with</Text>
        <Image src={logo} style={styles.logo} />
      </View>
    </View>
  )
}

const HelpfulTips = () => {

  return (
    <Page style={styles.page}>
      <View style={styles.tips}>
        <Text style={styles.tipsTitle}>5 Helpful Tips</Text>
        <Text style={{ ...styles.tipsTitle, marginBottom: 20 }}>
          When Checking In
        </Text>

        <Text style={styles.tipsSubtitle}>
          If you experience any problems when checking in,
        </Text>
        <Text style={{ ...styles.tipsSubtitle, marginBottom: 72 }}>
          please follow these 5 easy steps:
        </Text>

        <View style={styles.stepsContainer}>
          <View style={styles.step}>
            <View style={styles.stepNumberContainer}>
              <Text style={styles.stepNumberText}>STEP 1</Text>
            </View>
            <View style={styles.stepText}>
              <Text>Speak to the hotel reception</Text>
              <Text>prior to making any other</Text>
              <Text>arrangements.</Text>
            </View>

            <Image src={step1} style={{ width: 26, height: 26 }} />
          </View>

          <View style={styles.step}>
            <View style={styles.stepNumberContainer}>
              <Text style={styles.stepNumberText}>STEP 2</Text>
            </View>
            <View style={styles.stepText}>
              <Text>Contact the Booking Office on</Text>
              <Text>the emergency phone number</Text>
              <Text>listed on the voucher.</Text>
            </View>

            <Image src={step2} style={{ width: 26, height: 26 }} />
          </View>

          <View style={styles.step}>
            <View style={styles.stepNumberContainer}>
              <Text style={styles.stepNumberText}>STEP 3</Text>
            </View>
            <View style={styles.stepText}>
              <Text>Contact your travel agent</Text>
              <Text>for assistance.</Text>
            </View>

            <Image src={step3} style={{ width: 26, height: 26 }} />
          </View>

          <View style={styles.step}>
            <View style={styles.stepNumberContainer}>
              <Text style={styles.stepNumberText}>STEP 4</Text>
            </View>
            <View style={styles.stepText}>
              <Text>Contact AGENTUS on</Text>
              <Text>+612 8000 1898.</Text>
            </View>

            <Image src={logo} style={{ width: 26, height: 4 }} />
          </View>

          <View style={styles.step}>
            <View style={styles.stepNumberContainer}>
              <Text style={styles.stepNumberText}>STEP 5</Text>
            </View>
            <View style={styles.stepText}>
              <Text>Please take down the name of</Text>
              <Text>the person you spoke to and</Text>
              <Text>keep a copy of any receipts.</Text>
            </View>

            <Image src={step5} style={{ width: 26, height: 26 }} />
          </View>
        </View>
        <Footer />
      </View>
    </Page>
  )
}

const CancellationPolicy = ({ cancellationPolicy }: { cancellationPolicy: string }) => {
  return (
    <View break style={styles.staticCard}>
      <Image src={knowBeforeGoIcon} style={styles.staticCardIcon}></Image>
      <View style={{ flex: 1 }}>
        <Text style={styles.staticCardTitle}>Know before you go</Text>

        <Text style={styles.cancellationPolicy}>
          {cancellationPolicy
            .replaceAll(HTML_REGEX, "")}
        </Text>
      </View>
    </View>
  )
}

const Voucher = ({
  booking,
  mapImageUrl
}: {
  booking: SelectedBookingInfo;
  mapImageUrl: string;
}
) => {

  const {
    bookingNumber,
    rooms,
    agentRef,
    agentPhoneNumber,
    hotelName,
    hotelAddress,
    checkIn,
    checkOut,
    nights,
    roomName,
    board,
    cancellationPolicy,
    hotelPhoneNumber,
    itineraryNumber,
  } = booking;

  const Emergencies = () => {
    return (
      <View style={styles.staticCard}>
        <Image src={warnIcon} style={styles.staticCardIcon}></Image>

        <View>
          <Text style={styles.staticCardTitle}>For emergencies</Text>

          <Text>
            If you have any questions about this reservation, please contact
            the local booking office. In the event you are unable to make
            contact with the local booking office,
          </Text>

          <Text>please call Agentus on +61 2 8000 1898.</Text>
        </View>
      </View>
    )
  }

  const InfoCard = () => {
    return (
      <View style={styles.staticCard}>
        <Image src={warnIcon} style={styles.staticCardIcon}></Image>

        <View>
          <Text style={styles.staticCardTitle}>
            Important Information for guests and the front desk
          </Text>

          <View style={styles.importantInfoRow}>
            <View style={styles.bulletPoint} />

            <View style={styles.importantInfoText}>
              <Text>
                Most hotels require a minimum age of 18 years old to check
                into a room (unless accompanied by an adult) in some cases
                such as casinos the age may be
              </Text>
              <Text>
                21 years old. Please check with the particular hotel for
                individual requirements.
              </Text>
            </View>
          </View>

          <View style={styles.importantInfoRow}>
            <View style={styles.bulletPoint} />
            <View style={styles.importantInfoText}>
              <Text>
                Kindly note that while your booking has been confirmed, the
                rooming list with your name may not be updated in the hotel’s
                reservation system until closer
              </Text>

              <Text>to the arrival date.</Text>
            </View>
          </View>

          <View style={styles.importantInfoRow}>
            <View style={styles.bulletPoint} />
            <View style={styles.importantInfoText}>
              <Text>
                Resort, amenities and facilities fees and city taxes may not
                be included in the confirmed rate. Unless stated otherwise in
                writing, resort, amenities and facilities
              </Text>

              <Text>
                fees and excluded city taxes are payable direct to the hotel
                in the local currency.
              </Text>
            </View>
          </View>

          <View style={styles.importantInfoRow}>
            <View style={styles.bulletPoint} />
            <View style={styles.importantInfoText}>
              <Text>
                The booking does not include room incidentals (for example,
                mini bar use, telephone calls, room service, pay-to-view
                movies and similar services in the hotel).
              </Text>

              <Text>
                All extra expenses at the hotel or for similar services are
                accepted as personal expenses and must be paid directly to the
                hotel or supplier.
              </Text>
            </View>
          </View>

          <View style={styles.importantInfoRow}>
            <View style={styles.bulletPoint} />
            <View style={styles.importantInfoText}>
              <Text>
                This is a prepaid booking. Reservation has been made by
                Agentus in partnership with booking office mentioned above.
                Under no circumstances must the
              </Text>
              <Text>
                guest(s) be charged for the service listed on this voucher.
                The guest(s) is to be charged only for their incidentals.
              </Text>
            </View>
          </View>

          <View style={styles.importantInfoRow}>
            <View style={styles.bulletPoint} />
            <View style={styles.importantInfoText}>
              <Text>
                Child policies vary for each hotel. Please note children under
                18 years may share existing bedding.
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  const guests = generateGuestNamesFromOccupancy(rooms);

  function renderPhoneNumber({ countryCode: code, number }: PhoneNumber) {
    return `${code} ${number}`;
  }

  const DOCUMENT_SIZE = cancellationPolicy ? 2 : 1;

  const { city, line1, countryCode } = hotelAddress;
  return (
    <Page key={bookingNumber} style={styles.page}>
      <View style={styles.head}>
        <Text style={styles.title}>PREPAID VOUCHER</Text>
        <Text style={styles.subtitle}>ACCOMMODATION</Text>
      </View>

      <View style={styles.content}>
        <View style={styles.leadingCard}>
          <Text>
            <Text style={styles.label}>Lead Customer: </Text>
            <Text>{guests[0]}</Text>
          </Text>
          <Text>
            <Text style={styles.label}>Booking Office Reference No: </Text>
            <Text>{agentRef || "-"}</Text>
          </Text>
          <Text>
            <Text style={styles.label}>Agent Itinerary ID: </Text>
            <Text>{itineraryNumber}</Text>
          </Text>
          <Text>
            <Text style={styles.label}>Agent Booking ID: </Text>
            <Text>{bookingNumber}</Text>
          </Text>
        </View>

        <View style={styles.mainRow}>
          <View style={styles.detailsCards}>
            <View style={styles.detailsCard}>
              <View style={styles.detailsCardImage}>
                <Image src={hotelIcon} style={{ width: 14, height: 14 }} />
              </View>

              <View style={styles.detailsCardContent}>
                <Text style={styles.label}>
                  {hotelName}, {hotelAddress.city}
                </Text>
                <Text>
                  <Text style={styles.label}>Address: </Text>
                  <Text>
                    {line1}, {city}, {getCountryNameByCode(countryCode || "")}
                  </Text>
                </Text>
                <Text>
                  <Text style={styles.label}>Phone: </Text>
                  <Text>
                    {hotelPhoneNumber
                      ? renderPhoneNumber(hotelPhoneNumber)
                      : "-"}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.detailsCard}>
              <View style={styles.detailsCardImage}>
                <Image src={summaryIcon} style={{ width: 14, height: 14 }} />
              </View>

              <View style={styles.detailsCardContent}>
                <Text style={styles.detailsCardTitle}>Booking Summary</Text>

                <Text>
                  <Text style={styles.label}>Guest(s): </Text>
                  <Text>
                    ({guests.length}), {guests.join(", ")}
                  </Text>
                </Text>

                <Text>
                  <Text style={styles.label}>Check In: </Text>
                  <Text>{checkIn}</Text>
                </Text>

                <Text>
                  <Text style={styles.label}>Check Out: </Text>
                  <Text>{checkOut}</Text>
                </Text>

                <Text>
                  <Text style={styles.label}>Nights: </Text>
                  <Text>{nights}</Text>
                </Text>

                <Text>
                  <Text style={styles.label}>Room Type: </Text>
                  <Text>{roomName}</Text>
                </Text>

                <Text>
                  <Text style={styles.label}>Board: </Text>
                  <Text>{getBoardDisplayString(board)}</Text>
                </Text>
              </View>
            </View>
            <View style={{ ...styles.detailsCard, marginBottom: 0 }}>
              <View style={styles.detailsCardImage}>
                <Image src={detailsIcon} style={{ width: 15, height: 10 }} />
              </View>

              <View style={styles.detailsCardContent}>
                <Text style={styles.detailsCardTitle}>
                  Booking Office Details
                </Text>

                <Text>
                  <Text style={styles.label}>Local Phone No: </Text>
                  <Text>
                    {agentPhoneNumber
                      ? renderPhoneNumber(agentPhoneNumber)
                      : "-"}
                  </Text>
                </Text>

                <Text>
                  <Text style={styles.label}>Reference No: </Text>
                  <Text>{agentRef || "-"}</Text>
                </Text>

                <Text style={styles.detailsCardHint}>
                  This service is payable by the booking office only.
                </Text>
                <Text style={styles.detailsCardHint}>
                  Payment for extras to be collected from the customer.
                </Text>
              </View>
            </View>
          </View>

          {mapImageUrl ? (
            <Image src={mapImageUrl} style={styles.mapImage} />
          ) : (
            <View style={styles.mapImage} />
          )}
        </View>

        <InfoCard />
        <Emergencies />

        {cancellationPolicy &&
          <CancellationPolicy cancellationPolicy={cancellationPolicy} />
        }
        <Footer bookingNumber={bookingNumber} />
        <View fixed style={styles.pageNumber}>
          <Text>Page No </Text>
          <Text
            style={styles.label}
            render={({ pageNumber }) =>
              `${pageNumber % DOCUMENT_SIZE || DOCUMENT_SIZE
              } of ${DOCUMENT_SIZE}`
            }
          />
        </View>
      </View>
    </Page>
  );
};

export { HelpfulTips, Voucher };
