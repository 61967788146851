import clsx from "clsx";
import moment from "moment";
import React from "react";

import { ITINERARY_LOGS_MOCK } from "@widgets/itinerary/logs/__mock__";

import styles from "./styles.css";

const LOGS_TABLE_DATE_FORMAT = "MM/DD/YYYY, HH:mm";

const ItineraryLogs = () => {
  return (
    <div className={styles.itineraryLogs}>
      <h3>Logs</h3>

      <div className={clsx(styles.row, styles.head)}>
        <span>Date & Time</span>
        <span>User Email</span>
        <span>Itinerary</span>
        <span>Text</span>
      </div>

      <div className={styles.body}>
        {ITINERARY_LOGS_MOCK.map(({ id, date, user, itinerary, text }) => (
          <div key={id} className={styles.row}>
            <span>{moment(date).format(LOGS_TABLE_DATE_FORMAT)}</span>
            <span>{user}</span>
            <span>{itinerary}</span>
            <span>{text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItineraryLogs;
