import { useMemo, useState } from "react";

import { Board } from "@adl-gen/hotel/booking";
import { RoomRate } from "@widgets/room-rate-listing/room-rate-item/room-rate-item";
import {
  extractBoardOptionsFromRates,
  extractCancellationOptionsFromRates,
  getRatesPriceDifference,
} from "@widgets/room-rate-listing/room-rate-item/utils";

export const useRoomRateOptions = (
  defaultPackage: RoomRate,
  allRates: RoomRate[]
) => {
  const [selectedCancellation, setSelectedCancellation] = useState<string>(
    defaultPackage.roomPackage.cancellationPolicy.kind
  );
  const [selectedBoard, setSelectedBoard] = useState<Board>(
    defaultPackage.roomPackage.board
  );

  // A list of unique cancellation options with additional price
  const cancellationOptions = useMemo(
    () => extractCancellationOptionsFromRates(allRates, defaultPackage),
    []
  );

  // A list of unique board options
  const uniqueBoardOptions = useMemo(
    () => extractBoardOptionsFromRates(allRates),
    []
  );

  // A list of board options with additional price and availability flag
  const boardOptions = useMemo(() => {
    const baseRate = allRates.find(
      ({ roomPackage }) =>
        roomPackage.cancellationPolicy.kind === selectedCancellation
    );

    if (!baseRate) {
      return [];
    }

    return uniqueBoardOptions.map((board) => {
      const isDisabled = !allRates.some(
        (rate) =>
          rate.roomPackage.board === board &&
          rate.roomPackage.cancellationPolicy.kind === selectedCancellation
      );

      const currentRate = allRates.find(
        ({ roomPackage }) =>
          roomPackage.cancellationPolicy.kind === selectedCancellation &&
          roomPackage.board === board
      );

      // Additional price is null if there is only one board option
      const additionalPrice =
        currentRate && !isDisabled && uniqueBoardOptions.length > 1
          ? getRatesPriceDifference(baseRate, currentRate)
          : null;

      return {
        value: board,
        disabled: isDisabled,
        additionalPrice,
      };
    });
  }, [selectedCancellation]);

  return {
    selectedCancellation,
    selectedBoard,
    setSelectedCancellation,
    setSelectedBoard,
    cancellationOptions,
    boardOptions,
  };
};
