import React, { FC, useContext, useMemo } from "react";

import { LiveStatus } from "@adl-gen/hotel/booking";
import { AppUserType } from "@adl-gen/hotel/db";
import { LoggedInContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import { checkUserAccessLevel, getEnumKeys } from "@util/agentus-utis";

import StatusDropdown, {
  Props as StatusDropdownProps,
} from "../status-dropdown";

const title = "Status";

const LabeledLiveStatus = {
  [LiveStatus.draft]: "Draft",
  [LiveStatus.quote]: "Quote",
  [LiveStatus.confirmed]: "Confirmed",
  [LiveStatus.pendingConfirmation]: "Pending Confirmation",
  [LiveStatus.unavailable]: "Unavailable",
  [LiveStatus.cancelled]: "Cancelled",
  [LiveStatus.travelled]: "Travelled",
};

const useStatusOptions = (userProfile) => {
  const options = useMemo(() => {
    return (
      getEnumKeys(LiveStatus)
        // We hide the Draft live status from non backoffice users
        .filter((status) =>
          status === "draft"
            ? checkUserAccessLevel(userProfile, AppUserType.backOffice)
            : true
        )
        .map((key) => ({
          key: LiveStatus[key],
          label: LabeledLiveStatus[LiveStatus[key]],
        }))
    );
  }, [userProfile]);

  return options;
};

type Props = Pick<StatusDropdownProps<LiveStatus>, "onSelectOption" | "value">;

const LiveStatusDropdown: FC<Props> = ({ onSelectOption, value }) => {
  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );
  const options = useStatusOptions(userProfile);

  return (
    <StatusDropdown
      title={title}
      placeholder={title}
      renderOptions={options}
      onSelectOption={onSelectOption}
      value={value}
    />
  );
};

export default LiveStatusDropdown;
