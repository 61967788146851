import { LiveStatus } from "@adl-gen/hotel/booking";
import { BadgeColor } from "@widgets/badge/badge";

import { BookingStatus } from "./types";

export const BOOKING_LIVE_STATUS_MAP: Record<LiveStatus, BookingStatus> = {
  [LiveStatus.confirmed]: {
    label: "Confirmed",
    color: BadgeColor.Green,
  },
  [LiveStatus.quote]: {
    label: "Quote",
    color: BadgeColor.Grey,
  },
  [LiveStatus.draft]: {
    label: "Draft",
    color: BadgeColor.Grey,
  },
  [LiveStatus.pendingConfirmation]: {
    label: "Conf.Pending",
    color: BadgeColor.Yellow,
  },
  [LiveStatus.cancelled]: {
    label: "Cancelled",
    color: BadgeColor.Red,
  },
  [LiveStatus.unavailable]: {
    label: "Unavailable",
    color: BadgeColor.Black,
  },
  [LiveStatus.travelled]: {
    label: "Travelled",
    color: BadgeColor.LightBlue,
  },
};
