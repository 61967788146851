import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useStoreCleanUp = (cleanCallback: () => void) => {
  const { pathname } = useLocation();

  useEffect(() => {
    return cleanCallback;
  }, []);

  useEffect(() => {
    cleanCallback();
  }, [pathname]);
};

export default useStoreCleanUp;
