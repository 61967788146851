import clsx from "clsx";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { WithDbId } from "@adl-gen/common/db";
import { UserReq } from "@adl-gen/hotel/api";
import { GeneralContext, LoggedInContext } from "@app/app";
import { yupResolver } from "@hookform/resolvers/yup";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { ProfileForm } from "@pages/settings/agent-profile/types";
import { CountryCode } from "@pages/settings/types";
import { getFlagUrlByCode } from "@util/agentus-utis";
import { ERROR_TEXT, ValidationRule } from "@util/validation";
import { Button } from "@widgets/button/button";
import Image from "@widgets/image";
import Input from "@widgets/input";
import { InputSelect, InputSelectOption } from "@widgets/input-select";

import phoneCountryCodes from "../phone-country-codes.json";
import styles from "./styles.css";

const AgentProfileSchema = yup
  .object({
    fullname: yup.string().required(ERROR_TEXT[ValidationRule.RequiredField]),
    email: yup.string().required(ERROR_TEXT[ValidationRule.RequiredField]),
  })
  .required();

const AgentProfile: FC = () => {
  const { userController, identityController } = useContext(LoggedInContext);
  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );
  const { userProfile, loadProfile } = assertNotUndefined(identityController);
  const { updateUser } = assertNotUndefined(userController);

  if (!userProfile) {
    return null;
  }

  const defaultValues: ProfileForm = useMemo(
    () => ({
      fullname: userProfile.fullname,
      email: userProfile.email,
      commissionPercentage:
        userProfile.appUser.value.commissionPercentage || null,
      number: userProfile.appUser.value.phoneNumber?.number || "",
    }),
    [userProfile]
  );

  useEffect(() => {
    setCountryCode(userProfile.appUser.value.phoneNumber?.countryCode || "");
  }, [userProfile]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AgentProfileSchema),
    defaultValues,
  });
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState<string>("");

  function changeCountryCode(value: string) {
    setCountryCode(value);
  }

  async function submit({
    fullname,
    email,
    number,
    commissionPercentage,
  }: ProfileForm) {
    if (!userProfile) {
      return;
    }

    setLoading(true);

    try {
      const body: WithDbId<UserReq> = {
        id: userProfile.appUser.id,
        value: {
          fullname,
          email,
          commissionPercentage: commissionPercentage || null,
          phoneNumber: number || countryCode ? { countryCode, number } : null,
          agencyId: userProfile.appUser.value.agencyId,
          userType: userProfile.appUser.value.userType,
        },
      };

      await updateUser(body);
      await loadProfile();
    } catch (e) {
      e.publicMessage
        ? addNotification({
            text: e.publicMessage,
            type: NotificationTypeEnum.Warning,
          })
        : addNotification({
            text: "Unable to save data, please contact support",
            type: NotificationTypeEnum.Error,
          });
    }

    setLoading(false);
  }

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Input
        {...register("fullname")}
        error={errors?.fullname?.message}
        className={styles.line}
        label="Agent Name"
        placeholder="Agent Name"
      />

      <Input
        {...register("email")}
        error={errors?.email?.message}
        className={styles.line}
        label="Email"
        placeholder="example@mail.com"
        type="email"
      />

      <div className={clsx(styles.line, styles.phoneNumberLine)}>
        <InputSelect
          value={countryCode}
          label="Phone Number"
          placeholder="Country code"
          onChange={changeCountryCode}
        >
          {(phoneCountryCodes as CountryCode[]).map(
            ({ name, dial_code, code }) => (
              <InputSelectOption key={`${name}-${dial_code}`} value={dial_code}>
                <>
                  {`${dial_code} \(${name}\)`}{" "}
                  <Image
                    className={styles.countryFlag}
                    src={getFlagUrlByCode(code)}
                    alt={`${name} flag`}
                    invertColor
                  />
                </>
              </InputSelectOption>
            )
          )}
        </InputSelect>

        <Input
          {...register("number")}
          error={errors?.number?.message}
          placeholder="Phone number"
          type="number"
        />
      </div>

      <Input
        {...register("commissionPercentage")}
        error={errors?.commissionPercentage?.message}
        className={styles.line}
        label="% Agent commission Preference"
        placeholder="10"
        type="number"
      />

      <Button className={styles.saveButton} type="submit" isLoading={loading}>
        Save
      </Button>
    </form>
  );
};

export default AgentProfile;
