import moment from "moment";

import {
  AgentBookingData,
  BackOfficeUserBookingData,
} from "@adl-gen/hotel/api";
import { COMMON_DATETIME_FORMAT } from "@constants/common";
import { formatLocalDate, getNightsFromRange } from "@util/agentus-utis";

import { getBookingLabel } from "../utils";
import {
  AgentSelectedBookingInfo,
  BackofficeSelectedBookingInfo,
  SelectedBookingInfo,
} from "./types";

export function extractAgentSelectedBookingInfo(
  booking: AgentBookingData
): AgentSelectedBookingInfo {
  return {
    ...extractBaseSelectedBookingInfo(booking),
    bookingPrice: booking.bookingPrice,
  };
}

export function extractSelectedBackofficeBookingInfo(
  booking: BackOfficeUserBookingData
): BackofficeSelectedBookingInfo {
  return {
    ...extractBaseSelectedBookingInfo(booking),
    bookingPrice: booking.bookingPrice,
    created: moment(booking.bookingData.created).format(COMMON_DATETIME_FORMAT),
  };
}

export function extractBaseSelectedBookingInfo(
  booking: AgentBookingData | BackOfficeUserBookingData
): SelectedBookingInfo {
  return {
    bookingId: booking.bookingData.bookingId,
    bookingType: getBookingLabel(
      booking.bookingData.liveStatus,
      booking.bookingData.clientPaymentStatus,
      booking.bookingData.isInstantPurchase,
      booking.bookingData.bookingNumber
    ),
    bookingNumber: booking.bookingData.bookingNumber,
    hotelId: booking.bookingData.hotelId,
    hotelName: booking.bookingData.hotelDetails.hotelName,
    hotelAddress: booking.bookingData.hotelDetails.address,
    hotelLocation: booking.bookingData.hotelDetails.point,
    hotelPhoneNumber: booking.bookingData.hotelDetails.phoneNumber,
    rooms: booking.bookingData.rooms,
    checkIn: formatLocalDate(booking.bookingData.checkInDate),
    checkOut: formatLocalDate(booking.bookingData.checkOutDate),
    nights: getNightsFromRange(
      booking.bookingData.checkInDate,
      booking.bookingData.checkOutDate
    ),
    liveStatus: booking.bookingData.liveStatus,
    clientPaymentStatus: booking.bookingData.clientPaymentStatus,
    agentRef: booking.bookingData.agentReference,
    agentPhoneNumber: booking.bookingData.phoneNumber,
    roomName: booking.bookingData.roomName,
    board: booking.bookingData.board,
    cancellationPolicy: booking.bookingData.cancellationPolicy.description,
    isInstantPurchase: booking.bookingData.isInstantPurchase,
    itineraryNumber: booking.bookingData.itineraryNumber,
  };
}
