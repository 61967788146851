import { cloneDeep, keysIn } from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import type {
  BookingSearchQuery,
  BookingSearchQueryResp,
  Report
} from "@adl-gen/hotel/api";
import {
  BookingSearchShortcut,
  ReportType,
} from "@adl-gen/hotel/api";
import { LiveStatus } from "@adl-gen/hotel/booking";
import { ALL_SEARCH_PROPERTY } from "@constants/booking";
import { ROUTE_PATH } from "@constants/common";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import {
  BOOKING_SEARCH_ARRAY_PARAMS,
  BOOKING_SEARCH_NUMBER_PARAMS,
  BOOKING_SEARCH_OBJECT_PARAMS,
  EMPTY_BOOKING_SEARCH_FILTERS,
  SHORTCUT_FILTERS,
} from "@controllers/booking-search/constant";
import { usePagination } from "@controllers/customHooks/usePagination";
import useStoreCleanUp from "@hooks/useStoreCleanUp";
import { NotificationTypeEnum } from "@models/common";
import type { Loading } from "@models/loading";
import { fromPromise } from "@models/loading-state";
import { getEnumValues } from "@util/agentus-utis";
import { getPageNumberFromOffset } from "@util/pagination";

import { Service } from "../../service/service";
import { sendErrorToRollbar } from "@src/util/error-handling";

/** Possible booking search page variants which determine how many inputs are displayed */
export type BookingSearchType = "basic" | "advanced";

/**
 * Manages booking search store search params and search operations.
 */
export interface BookingSearchController {
  searchParams: BookingSearchQuery;
  setQueryField<T extends keyof BookingSearchQuery>(
    fieldName: T,
    value?: BookingSearchQuery[T]
  ): void;
  doSearch(params?: Partial<BookingSearchQuery>): void;
  discardSearchResults(): void;
  searchResult?: Loading<BookingSearchQueryResp>;
  bookingReport?: Loading<Report>;
  fetchBookingReport(): void;
  latestBookings: Loading<BookingSearchQueryResp>;
  fetchLatestBookings(): void;
  fetchShortcuts(): void;
  shortcutsCount: Map<BookingSearchShortcut, number>;
  selectedShortcut: BookingSearchShortcut | null;
  toggleShortcut(shortcut: BookingSearchShortcut): void;
  setPageNumber(page: number): void;
  currentPage: number;

  /** Сlearing values after logout */
  logout(): void;
}

export const useBookingSearchController = (
  service: Pick<
    Service,
    "queryBookings" | "queryReports" | "countBookingShortcuts"
  >,
  notificationController
): BookingSearchController => {
  const location = useLocation();
  const history = useHistory();
  const { addNotification } = notificationController;

  const [searchParams, setSearchParams] = useState<BookingSearchQuery>(
    EMPTY_BOOKING_SEARCH_FILTERS
  );
  const [searchResult, setSearchResult] = useState<
    Loading<BookingSearchQueryResp> | undefined
  >();
  const [bookingReport, setBookingReport] = useState<
    Loading<Report> | undefined
  >();

  const [shortcutsCount, setShortcutsCount] = useState<
    Map<BookingSearchShortcut, number>
  >(new Map());
  const [
    selectedShortcut,
    setSelectedShortcut,
  ] = useState<BookingSearchShortcut | null>(null);

  const {
    currentPage,
    setPageNumber,
    resetPaginationValues,
    setTotalCount,
  } = usePagination({ ignoreUrlUpdate: true });

  useStoreCleanUp(() => {
    setBookingReport(undefined);
    if (!location.pathname.includes(ROUTE_PATH.Bookings)) {
      resetPaginationValues();
      discardSearchResults();
    }
  });

  const [latestBookings, setLatestBookings] = useState<
    Loading<BookingSearchQueryResp>
  >({ kind: "loading" });

  const setQueryField = (
    fieldName: keyof BookingSearchQuery,
    value?: BookingSearchQuery[keyof BookingSearchQuery]
  ) => {
    setSearchParams((prevState) => {
      return { ...prevState, [fieldName]: value };
    });
  };

  function updateUrlParams(filters: BookingSearchQuery) {
    const urlParams = new URLSearchParams();

    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        // Additional check to avoid adding empty arrays to URL params
        if (Array.isArray(value) && !value.length) {
          return;
        }

        if (BOOKING_SEARCH_OBJECT_PARAMS.includes(key)) {
          urlParams.set(key, JSON.stringify(value));

          return;
        }

        urlParams.set(
          key,
          Array.isArray(value) ? JSON.stringify(value) : value.toString()
        );
      } else {
        urlParams.delete(key);
      }
    });

    location.search = urlParams.toString();

    history.push(location);
  }

  function discardSearchResults() {
    setSearchResult(undefined);
    setSearchParams(EMPTY_BOOKING_SEARCH_FILTERS);
  }

  useEffect(() => {
    if (searchResult?.kind === "value") {
      setTotalCount(searchResult.value.value.total_size);
    }
  }, [searchResult]);

  useEffect(() => {
    if (selectedShortcut && searchResult?.kind === "value") {
      const totalsMap = new Map(shortcutsCount);
      totalsMap.set(selectedShortcut, searchResult.value.value.total_size);
      setShortcutsCount(totalsMap);
    }
  }, [searchResult, selectedShortcut]);

  useEffect(() => {
    // Avoid parsing URL params on another pages
    if (!location.pathname.includes(ROUTE_PATH.Bookings)) {
      return;
    }

    setSearchParams(cloneDeep(EMPTY_BOOKING_SEARCH_FILTERS));

    const filters = cloneDeep(EMPTY_BOOKING_SEARCH_FILTERS);

    let paramsChanged = false;

    const urlParams = new URLSearchParams(location.search);

    keysIn(EMPTY_BOOKING_SEARCH_FILTERS).forEach((key) => {
      const valueFromUrl = urlParams.get(key);

      if (valueFromUrl !== null) {
        if (BOOKING_SEARCH_ARRAY_PARAMS.includes(key)) {
          // Parsing array values from URL params
          const parsed = JSON.parse(valueFromUrl);

          // TODO: support string arrays parsing
          filters[key] =
            parsed[0] === ALL_SEARCH_PROPERTY
              ? []
              : parsed.map((value) => +value);

          paramsChanged = true;
        } else if (BOOKING_SEARCH_NUMBER_PARAMS.includes(key)) {
          // Parsing number values from URL params
          if (Number.isInteger(+valueFromUrl)) {
            filters[key] = +valueFromUrl;
            paramsChanged = true;
          }
        } else if (BOOKING_SEARCH_OBJECT_PARAMS.includes(key)) {
          filters[key] = JSON.parse(valueFromUrl);
          paramsChanged = true;
        } else {
          filters[key] = valueFromUrl;
          paramsChanged = true;
        }
      }
    });

    if (paramsChanged) {
      doSearch(filters);
    }
  }, [location.pathname]);

  function toggleShortcut(shortcut: BookingSearchShortcut) {
    if (shortcut === selectedShortcut) {
      setSelectedShortcut(null);
      setSearchParams(EMPTY_BOOKING_SEARCH_FILTERS);

      fromPromise(
        service.queryBookings(EMPTY_BOOKING_SEARCH_FILTERS),
        setSearchResult,
        notificationController.addNotification
      );
    } else {
      setSelectedShortcut(shortcut);
      setSearchParams(EMPTY_BOOKING_SEARCH_FILTERS);

      fromPromise(
        service.queryBookings(SHORTCUT_FILTERS[shortcut]),
        setSearchResult,
        notificationController.addNotification
      );
    }
  }

  function fetchBookingReport() {
    try {
      fromPromise(
        service.queryReports({
          types: [ReportType.bookingTotal, ReportType.liveStatusTotals],
        }),
        (data) => {
          if (data.kind === "loading") {
            setBookingReport({ kind: "loading" });
          } else if (data.kind === "value" && data.value.kind === "reports") {
            setBookingReport({ kind: "value", value: data.value.value });
          } else if (data.kind === "error") {
            setBookingReport(undefined);
            sendErrorToRollbar(new Error(`Could not fetch booking report`), {
              type: "bookingReport",
              error: data.error,
            })
            throw Error();
          }
        },
        notificationController.addNotification
      );
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Booking Totals loading error",
      });
    }
  }

  const doSearch = (filters: BookingSearchQuery) => {
    const params = {
      ...searchParams,
      ...(filters || {}),
    };

    let requestFilters: BookingSearchQuery = {
      ...EMPTY_BOOKING_SEARCH_FILTERS,
      offset: params.offset,
      serviceFrom: null,
      serviceTo: null,
      createdFrom: null,
      createdTo: null,
      destinationName: params.destinationName || null,
      bookingNumber: params.bookingNumber || null,
      itineraryNumber: params.itineraryNumber || null,
      agentRef: params.agentRef || null,
      firstName: params.firstName || null,
      lastName: params.lastName || null,
    };

    //Advanced search options
    if (location.pathname === ROUTE_PATH.BookingsAdvanced) {
      // NOTE(Barry): We convert the user specified local date to an instant in the frontend because if the user asks
      // for bookings created before Tuesday, you want to find the correct timezone to use, which will be based on
      // their system's timezone, not the backend's timezone
      requestFilters = {
        ...requestFilters,
        createdFrom: params.createdFrom,
        createdTo: params.createdTo,
        serviceFrom: params.serviceFrom,
        serviceTo: params.serviceTo,
        liveStatuses: params.liveStatuses,
        userName: params.userName || null,
        isInstantPurchase: params.isInstantPurchase,
        isCancellationPolicyActive: params.isCancellationPolicyActive,
        isPaymentPending: params.isPaymentPending,
        paymentDueDate: params.paymentDueDate,
        clientPaymentStatuses: params.clientPaymentStatuses,
      };
    }

    fromPromise(
      service.queryBookings(requestFilters),
      setSearchResult,
      notificationController.addNotification
    );

    setSelectedShortcut(null);
    setPageNumber(getPageNumberFromOffset(params.offset, ITEMS_PER_PAGE));

    if (filters) {
      setSearchParams(requestFilters);
    }

    // Avoiding of URL update on another pages, i.e. Dashboard
    if (location.pathname.includes(ROUTE_PATH.Bookings)) {
      updateUrlParams(requestFilters);
    }
  };

  function fetchLatestBookings() {
    const requestFilters: BookingSearchQuery = {
      ...EMPTY_BOOKING_SEARCH_FILTERS,
      count: 6,
      liveStatuses: [LiveStatus.confirmed, LiveStatus.quote],
      serviceFrom: null,
      serviceTo: null,
      createdFrom: null,
      createdTo: null,
    };

    fromPromise(
      service.queryBookings(requestFilters),
      setLatestBookings,
      notificationController.addNotification
    );
  }

  async function fetchShortcuts() {
    try {
      const response = await service.countBookingShortcuts({
        shortcuts: (getEnumValues(
          BookingSearchShortcut
        ) as unknown) as BookingSearchShortcut[],
      });

      const map = new Map();

      response.shortcutTotals.forEach(({ shortcut, total }) =>
        map.set(shortcut, total)
      );

      setShortcutsCount(map);
    } catch (e) {
      addNotification({
        text: "Unable to get refined search information",
        type: NotificationTypeEnum.Error,
      });
    }
  }

  const logout = () => {
    setShortcutsCount(new Map());
    setSelectedShortcut(null);
    setLatestBookings({ kind: "loading" });
  };

  return {
    searchParams,
    setQueryField,
    doSearch,
    searchResult,
    discardSearchResults,
    bookingReport,
    fetchBookingReport,
    fetchLatestBookings,
    latestBookings,
    fetchShortcuts,
    shortcutsCount,
    selectedShortcut,
    toggleShortcut,
    setPageNumber,
    currentPage,
    logout,
  };
};
