/** All the side effect css need to be imported first (disabled ordered imports) */
/* tslint:disable:no-import-side-effect no-submodule-imports ordered-imports*/
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.min.css";
import "./ui/common/globals.css";
import { GoogleMapKey, GooglePlacesKey } from "@constants/common";
import { enGB } from "date-fns/locale";
import { registerLocale } from "react-datepicker";
import { BrowserRouter as Router } from "react-router-dom";

import { BrowserHttp } from "@hx/hx/service/browser-http";
import { HttpWithRetries } from "@hx/hx/service/retrying-http";
import * as React from "react";
import * as ReactDOM from "react-dom";

import { maskSensitiveData } from "@util/agentus-utis";
import { texprUiConfig, UiConfig } from "@adl-gen/hotel/uiconfig";
import { RESOLVER } from "@adl-gen/resolver";
import { createJsonBinding } from "@adl-gen/runtime/json";
import { App } from "@app/app";
import { HttpService } from "./service/http-service";
import { HttpServiceError } from "./service/http-service-error";
import { StorageTokenManager } from "./service/token-manager";

if (module.hot) {
  module.hot.accept();
}

declare const window: Window & {
  /** UI config JSON injected via index.html */
  UI_CONFIG: {};
};

// Fetch and parse the typed UiConfig record from the window
// This has been populated via a script tag in index.html
function loadUiConfig(): UiConfig {
  if (!window.UI_CONFIG) {
    return {
      title: "",
      environment: "",
      releaseName: "",
      rollbar: null,
    };
  }
  try {
    return createJsonBinding(RESOLVER, texprUiConfig()).fromJson(
      window.UI_CONFIG
    );
  } catch (e) {
    throw new Error("Could not parse UI_CONFIG: " + e);
  }
}

export function initHttp(): [HttpService, StorageTokenManager] {
  const tokenManager = new StorageTokenManager(
    "jwt-access-token",
    localStorage
  );

  // Configure http to retry up to 8 times, with an initial delay of 100ms, with
  // exponential increases. The precise delays are randomised, but this will
  // result in a typical max delay before failure of 25 seconds
  const http = new HttpWithRetries(new BrowserHttp(), 8, 100);

  const service = new HttpService(
    http,
    "/_a",
    RESOLVER,
    tokenManager,
    (error: HttpServiceError) => {
      throw error;
    }
  );

  return [service, tokenManager];
}

let UI_CONFIG: UiConfig;

async function deferRender() {
  if (
    process.env.NODE_ENV === "development" &&
    process.env.WEBPACK_SERVE &&
    process.env.ENABLE_MOCKUP_SERVICE_ON_DEV_SERVER === "true"
  ) {
    // tslint:disable-next-line:no-console
    console.log("Starting mock worker");
    const mocks = await import("./mocks/browser");
    await mocks.worker.start();
    // tslint:disable-next-line:no-console
    console.log("Started mock worker");
  }
  UI_CONFIG = loadUiConfig();

  // tslint:disable:no-console
  console.log("Loading Agentus");
  console.log(`Environment: ${UI_CONFIG.environment}`);
  console.log(`Google Maps API Key: ${maskSensitiveData(GoogleMapKey)}`);
  console.log(`Google Places API Key: ${maskSensitiveData(GooglePlacesKey)}`);
  // tslint:enable:no-console

  // Register locale for react-datepicker
  registerLocale("enGB", enGB);
}

deferRender().then(() => {
  ReactDOM.render(
    <Router>
      <App uiconfig={UI_CONFIG} />
    </Router>,
    document.getElementById("root")
  );
});
