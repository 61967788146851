import clsx from "clsx";
import React, { useContext, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { HotelSearchContext } from "@app/app";
import { BaseProps } from "@common/base";
import { URLFilterParams } from "@common/url-filter-params";
import {
  AMENITIES_FILTER_TITLE,
  HotelSearchTypeEnum,
  HOTEL_CHAIN_TITLE,
  HOTEL_STAR_RATING_FILTER_TITLE,
  PROPERTY_TYPE_TITLE,
  REGION_TITLE,
} from "@controllers/hotel-search/hotel-search-controller";
import { assertNotUndefined } from "@hx/util/types";
import { ToggleButton } from "@widgets/button/toggle-button";
import { Checkbox } from "@widgets/checkbox/checkbox";
import { IconSizeable } from "@widgets/icon/icon-sizeable";
import { Icons } from "@widgets/icon/icons";
import { InputText } from "@widgets/input-text/input-text";

import styles from "./filter.css";

const INITIAL_OPTIONS_SHOWN = 7;

/** Component Props */

export interface FilterOption {
  /** User-friendly string representation of filter to display  */
  displayString: string | JSX.Element;
  /** Filter value */
  value: string;
}

export interface FilterProps extends BaseProps {
  classes?: { filter?: string };
  title: string;
  propertyKey: HotelSearchTypeEnum;
  placeholder?: string;
  options: FilterOption[];
  searchable?: boolean;
}

/**
 * Searchable list of checkboxes wrapped in an accordian, primarily used
 * as a filter.
 */

export const Filter = ({
  searchable = true,
  ...props
}: FilterProps): JSX.Element => {
  const { title, options, propertyKey, className, placeholder } = props;
  /**
   * Used to track state of search input field value, used to filter list.
   */
  const [searchValue, setSearchValue] = useState("");

  const [showAll, setShowAll] = useState(false);

  /**
   * Reference ot the Options List Container, used to obgtain scroll info.
   */
  const optionListContainerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();
  const location = useLocation();

  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const changeHandler = (value: string) => {
    setSearchValue(value.toLowerCase());
  };

  const checkboxChangeHandler = (
    optionDisplayString: string | JSX.Element,
    optionValue: string,
    checked
  ) => {
    // TODO(jess): Move updating of URLParams from this layer to the
    // controller layer so that when a filter gets added/deleted,
    // the URLParams are updated from the controller layer.
    // Also implement parsing of the URLParams when the page is
    // refreshed so the correct filters are loaded.
    const params = new URLSearchParams(location.search);
    const filterParams = new URLFilterParams(params);
    const urlParamStr =
      typeof optionDisplayString === "string"
        ? `${optionDisplayString}_${optionValue}`
        : `${optionValue}`;

    if (checked) {
      filterParams.append(propertyKey, urlParamStr);
    } else {
      filterParams.remove(propertyKey, urlParamStr);
    }

    // TODO(jess): Move this logic into the filter panel component
    // and refactor this component to take a check box handler.
    const operationType = checked ? "add" : "delete";
    if (title === HOTEL_STAR_RATING_FILTER_TITLE) {
      hotelSearchController.processHotelSearchFilter(
        {
          kind: HotelSearchTypeEnum.StarRatings,
          value: parseInt(optionValue, 10),
        },
        operationType
      );
    } else if (title === AMENITIES_FILTER_TITLE) {
      hotelSearchController.processHotelSearchFilter(
        {
          kind: HotelSearchTypeEnum.Amenity,
          value: optionValue,
        },
        operationType
      );
    } else if (title === HOTEL_CHAIN_TITLE) {
      hotelSearchController.processHotelSearchFilter(
        {
          kind: HotelSearchTypeEnum.HotelChain,
          value: optionValue,
        },
        operationType
      );
    } else if (title === PROPERTY_TYPE_TITLE) {
      hotelSearchController.processHotelSearchFilter(
        {
          kind: HotelSearchTypeEnum.PropertyType,
          value: optionValue,
        },
        operationType
      );
    } else if (title === REGION_TITLE) {
      hotelSearchController.processHotelSearchFilter(
        {
          kind: HotelSearchTypeEnum.Regions,
          value: optionValue,
        },
        operationType
      );
    }
    // Update the URL
    location.search = params.toString();
    history.push(location);
  };

  const isChecked = (optionDisplayStr, optionValue: string): boolean => {
    const params = new URLSearchParams(location.search);
    const filterParams = new URLFilterParams(params);
    const urlParamStr =
      typeof optionDisplayStr === "string"
        ? `${optionDisplayStr}_${optionValue}`
        : `${optionValue}`;

    return filterParams.includes(propertyKey, urlParamStr);
  };

  const filteredOptions = options.filter(({ displayString }) =>
    searchValue
      ? displayString.toString().toLowerCase().indexOf(searchValue) !== -1
      : true
  );

  const classes = props.classes || {};

  return (
    <div className={clsx(styles.filter, classes.filter, className)}>
      <p className={styles.title}>{title}</p>
      <>
        {searchable && (
          <InputText
            className={styles.inputText}
            iconBefore={<IconSizeable size={24} icon={Icons.search} />}
            placeholder={placeholder}
            onChange={(ev) => changeHandler(ev.currentTarget.value)}
          />
        )}
      </>
      <div className={styles.optionList}>
        <div
          ref={optionListContainerRef}
          className={styles.optionListContainer}
        >
          {(showAll
            ? filteredOptions
            : filteredOptions.slice(0, INITIAL_OPTIONS_SHOWN)
          ).map(({ displayString, value }, index) => (
            <Checkbox
              key={`${value}-${index}`}
              text={displayString}
              checked={isChecked(displayString, value)}
              onChange={(checked) =>
                checkboxChangeHandler(displayString, value, checked)
              }
            />
          ))}
        </div>
      </div>
      {filteredOptions.length > INITIAL_OPTIONS_SHOWN ? (
        <ToggleButton
          className={styles.cta}
          emphasis="low"
          toggle={showAll}
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? "Show less" : "Show all"}
        </ToggleButton>
      ) : null}
    </div>
  );
};
