import { BreadCrumbsDataType, BreadCrumbType } from "./types";

export const BreadCrumbsData: BreadCrumbsDataType[] = [
  {
    title: "Search",
    type: BreadCrumbType.Search,
  },
  {
    title: "Results",
    type: BreadCrumbType.Results,
  },
  {
    title: "Details",
    type: BreadCrumbType.Details,
  },
  {
    title: "Confirmation",
    type: BreadCrumbType.Confirmation,
  },
];

export const USER_PATH_PARAM = "user_path";
