// Timezone values to display in timezone dropdown with their corresponding display-friendly string representations.
import moment from "moment-timezone";

import { TimezoneToDisplayString } from "./types";

export let TIME_ZONES: TimezoneToDisplayString[] = [];
// NOTE(jess): A direct call of moment.tz.names() returns some deprecated timezone values, looping through
// the list of moment.tz.countries and calling moment.tz.zonesForCountry doesn't. Please see
// https://github.com/moment/moment-timezone/issues/227#issuecomment-629098169 for more info.
const timezoneCountries = moment.tz.countries();
for (const country of timezoneCountries) {
  const timezonesForCountry = moment.tz.zonesForCountry(country);
  for (const timezone of timezonesForCountry) {
    TIME_ZONES.push({
      timezoneValue: timezone,
      displayLabel: timezone.replace(/_/g, " "),
    });
  }
}

TIME_ZONES = TIME_ZONES.sort((a, b) =>
  a.timezoneValue.localeCompare(b.timezoneValue)
);

export enum AgencyDetailsTab {
  GeneralDetails = "General Details",
  ContactDetails = "Contact Details",
  FinancialDetails = "Financial Details",
  Commission = "Commission",
}
