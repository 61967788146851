import { useState } from "react";

import { NotificationType, NotificationWithoutIdType } from "@models/common";

export interface NotificationController {
  notifications: NotificationType[];
  addNotification(notification: NotificationWithoutIdType): void;
  deleteNotification(id: string): void;
  deleteAllNotifications(): void;

  /** Сlearing values after logout */
  logout(): void;
}

export const useNotificationController = (): NotificationController => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  function addNotification(notification: NotificationWithoutIdType) {
    const newNotification: NotificationType = {
      id: `${notification.text}_${notification.type}_${new Date().getTime()}`,
      ...notification,
    };
    setNotifications((list) => [...list, newNotification]);
  }

  function deleteNotification(id: string) {
    setNotifications((list) =>
      list.filter((notification) => notification.id !== id)
    );
  }

  function deleteAllNotifications() {
    setNotifications([]);
  }

  function logout() {
    setNotifications([]);
  }

  return {
    notifications,
    addNotification,
    deleteNotification,
    deleteAllNotifications,
    logout,
  };
};
