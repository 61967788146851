import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";

import { LoggedInContext } from "@app/app";
import { Icons } from "@common/icon/svg";
import { WithAgencyController } from "@controllers/agency-controller";
import { assertNotUndefined } from "@hx/util/types";
import { Button } from "@widgets/button/button";

import { ROLE_MAP, STATUS_MAP } from "../constant";
import parentStyles from "../settings.css";
import DetailsButton from "./details-button";
import styles from "./styles.css";
import BackofficeUserModal from "./user-modal";

const ManageBackofficeUsers: FC<WithAgencyController> = () => {
  const [modalOpened, setModalOpened] = useState(false);

  const { backofficeUsers, fetchBackofficeUsers } = assertNotUndefined(
    useContext(LoggedInContext).userController
  );

  useEffect(() => {
    fetchBackofficeUsers();
  }, []);

  return (
    <div className={parentStyles.settingsTableContainer}>
      <Button
        className={parentStyles.addButton}
        classes={{ container: parentStyles.addButtonInner }}
        onClick={() => setModalOpened(true)}
      >
        {Icons.plusCircleFilled}
        Add New Backoffice User
      </Button>

      <table
        className={clsx(parentStyles.settingsTable, styles.backofficeUsers)}
      >
        <thead className={parentStyles.tableHeader}>
          <tr>
            <th className={parentStyles.name}>Name</th>
            <th className={parentStyles.email}>Email</th>
            <th className={parentStyles.role}>User type</th>
            <th className={parentStyles.status}>Status</th>
            <th>Menu</th>
          </tr>
        </thead>
        <tbody>
          {backofficeUsers.map((user) => {
            const { id, value } = user;
            const { fullname, email, userStatus, userType } = value;
            const { label, color, background } = STATUS_MAP[userStatus];
            return (
              <tr key={id}>
                <td className={clsx(parentStyles.name, parentStyles.plainText)}>
                  <div className={parentStyles.profilePicture}>
                    {fullname[0]}
                  </div>
                  <span>{fullname}</span>
                </td>
                <td
                  className={clsx(parentStyles.email, parentStyles.plainText)}
                >
                  <span>{email}</span>
                </td>
                <td className={parentStyles.role}>
                  <span
                    style={{
                      color: ROLE_MAP[userType].color,
                      backgroundColor: ROLE_MAP[userType].background,
                    }}
                  >
                    {ROLE_MAP[userType].label}
                  </span>
                </td>
                <td className={parentStyles.status}>
                  <span
                    style={{
                      color,
                      backgroundColor: background,
                    }}
                  >
                    {label}
                  </span>
                </td>

                <td>
                  <DetailsButton user={user} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <BackofficeUserModal isOpen={modalOpened} onClose={() => setModalOpened(false)} />
    </div>
  );
};

export default ManageBackofficeUsers;
