import React, { FC, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import { AppUserType } from "@adl-gen/hotel/db";
import { Protected } from "@common/protected";
import { WithAgencyController } from "@controllers/agency-controller";
import { GeneralPage } from "@layouts/page-layout/general-page";
import ManageAgencies from "@pages/settings/manage-agencies";
import ManageBackofficeUsers from "@pages/settings/manage-backoffice-users";
import {
  SETTINGS_LINK_LABEL,
  SETTINGS_LINK_PERMISSION,
} from "@pages/settings/nav-settings/constant";
import { SettingsNavLink } from "@pages/settings/nav-settings/types";
import UserProfile from "@pages/settings/user-profile";

import AgencyDetails from "./agency-details";
import AgentProfile from "./agent-profile";
import ManageAgents from "./manage-agents";
import { NavSettings } from "./nav-settings";
import PasswordSettings from "./password";
import styles from "./settings.css";

interface PropTypes extends WithAgencyController {
  userType?: AppUserType;
}

export const Settings: FC<PropTypes> = ({ agencyController, userType }) => {
  const location = useLocation();

  useEffect(() => {
    if (userType === AppUserType.agent || AppUserType.agencyAdmin) {
      agencyController.refreshAgencyDetails();
    }
  }, [location]);

  const title = SETTINGS_LINK_LABEL[location.pathname] || "";

  return (
    <GeneralPage items={["Settings", title]}>
      <div className={styles.settings}>
        <NavSettings />
        <Switch>
          <Route path="/:dir*/agent-profile">
            <Protected
              allowedRoles={
                SETTINGS_LINK_PERMISSION[SettingsNavLink.AgentProfile]
              }
            >
              <AgentProfile />
            </Protected>
          </Route>
          <Route path="/:dir*/user-profile">
            <Protected
              allowedRoles={
                SETTINGS_LINK_PERMISSION[SettingsNavLink.UserProfile]
              }
            >
              <UserProfile />
            </Protected>
          </Route>
          <Route path="/:dir*/agency">
            <Protected
              allowedRoles={
                SETTINGS_LINK_PERMISSION[SettingsNavLink.AgencyDetails]
              }
            >
              <AgencyDetails agencyController={agencyController} />
            </Protected>
          </Route>
          <Route path="/:dir*/password">
            <PasswordSettings />
          </Route>
          <Route path="/:dir*/agents">
            <Protected
              allowedRoles={
                SETTINGS_LINK_PERMISSION[SettingsNavLink.ManageAgents]
              }
            >
              <ManageAgents agencyController={agencyController} />
            </Protected>
          </Route>
          <Route path="/:dir*/backoffice-users">
            <Protected
              allowedRoles={
                SETTINGS_LINK_PERMISSION[SettingsNavLink.ManageBackofficeUsers]
              }
            >
              <ManageBackofficeUsers agencyController={agencyController} />
            </Protected>
          </Route>
          <Route path="/:dir*/agencies">
            <Protected
              allowedRoles={
                SETTINGS_LINK_PERMISSION[SettingsNavLink.ManageAgencies]
              }
            >
              <ManageAgencies agencyController={agencyController} />
            </Protected>
          </Route>
        </Switch>
      </div>
    </GeneralPage>
  );
};
