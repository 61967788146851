import { HotelSearchSortOrder } from "@adl-gen/hotel/api";

export const CHECKIN_PARAM = "checkin";
export const CHECKOUT_PARAM = "checkout";
export const ROOM_PARAM = "room";
export const COMMISSION_PARAM = "commission";
export const ORDER_PARAM = "order";
export const FILTER_PARAM = "filter";
export const PAGE_PARAM = "page";

export const ROOM_PARAM_SEPARATOR = ",";

export enum LocationTypeEnum {
  Area = "region",
  Landmark = "poi",
  Hotel = "hotel",
}

export const DEFAULT_ORDER_VALUES = {
  [LocationTypeEnum.Area]: HotelSearchSortOrder.popularity,
  [LocationTypeEnum.Landmark]: HotelSearchSortOrder.distance,
  [LocationTypeEnum.Hotel]: HotelSearchSortOrder.distance,
};

export const PRICE_FETCH_RETRIES_LIMIT = 15;
