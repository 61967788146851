import clsx from "clsx";
import moment, { Moment } from "moment";
import React, { FC, useContext } from "react";

import { PaymentMode } from "@adl-gen/hotel/booking";
import { PackageCancellationPolicy } from "@adl-gen/hotel/common";
import { HotelSearchContext } from "@app/app";
import { WarningFilledIcon } from "@assets/svg-components";
import { COMMON_DATE_FORMAT } from "@constants/common";
import { assertNotUndefined } from "@hx/util/types";
import { Button } from "@widgets/button/button";
import ModalWindow from "@widgets/modal-window";
import { Price } from "@widgets/price/price";

import layout from "@common/layout-lib.css";
import styles from "./styles.css";

interface Props {
  open: boolean;
  policy: PackageCancellationPolicy;
  payByDate: Moment;
  paymentMode: PaymentMode;
  onClose(): void;
}

const CancellationModal: FC<Props> = ({
  open,
  onClose,
  policy,
  payByDate,
  paymentMode,
}) => {
  const { stayDetails } = assertNotUndefined(useContext(HotelSearchContext));

  const { policies, description } = policy;

  const isCancellationPast = payByDate.isBefore(moment(new Date()));

  return (
    <ModalWindow open={open} onClose={onClose}>
      <div className={clsx(styles.cancellationModal, layout.vertical)}>
        <div className={styles.content}>
          <h2 className={styles.title}>Cancellation Policy</h2>

          {!!description ? (
            <div
              className={styles.descriptionContainer}
              id="policy-description"
            >
              <WarningFilledIcon />

              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          ) : null}

          <div className={styles.timelinePoints}>
            <div className={styles.timelinePoint}>
              <span>Today</span>
              <span>{moment(new Date()).format(COMMON_DATE_FORMAT)}</span>
            </div>
            {!isCancellationPast && (
              <div className={clsx(styles.timelinePoint, styles.getByDate)}>
                <span>AEST</span>
                <span>{payByDate.format(COMMON_DATE_FORMAT)} 23:59</span>
              </div>
            )}
            <div className={styles.timelinePoint}>
              <span>Check-in</span>
              <span>
                {moment(stayDetails.checkIn).format(COMMON_DATE_FORMAT)}
              </span>
            </div>
          </div>

          {isCancellationPast ? (
            <>
              <div className={clsx(styles.nonRefundable, styles.text)}>
                Non-Refundable
              </div>
            </>
          ) : (
            <section className={styles.timeline}>
              <div className={clsx(styles.freeCancellation, styles.text)}>
                Free Cancellation
              </div>
              <div className={clsx(styles.partiallyRefundable, styles.text)}>
                Non-Refundable
              </div>
            </section>
          )}

          <section className={layout.vertical}>
            <span className={clsx(styles.text, styles.sectionTitle)}>
              Cancellation policy
            </span>

            {policies.map(
              ({ cancelFromDate, grossCancelFee, netCancelFee }, index) => (
                <div key={index} className={styles.policyRow}>
                  <p>
                    From 12:00 AM{" "}
                    {moment(cancelFromDate).format(COMMON_DATE_FORMAT)}
                  </p>

                  <Price
                    value={
                      paymentMode === PaymentMode.gross
                        ? grossCancelFee.value
                        : netCancelFee.value
                    }
                    currency={
                      paymentMode === PaymentMode.gross
                        ? grossCancelFee.currency
                        : netCancelFee.currency
                    }
                    size="small"
                  />
                </div>
              )
            )}
          </section>

          <div className={layout.vertical}>
            <span className={clsx(styles.text, styles.sectionTitle)}>
              No Show
            </span>

            <p>100% of booking fee applies</p>
          </div>
        </div>

        <Button
          className={styles.closeButton}
          onClick={onClose}
          emphasis="medium"
          size="large"
        >
          Close
        </Button>
      </div>
    </ModalWindow>
  );
};

export default CancellationModal;
