import clsx from "clsx";
import React, { FC, useEffect } from "react";

import { CloseUpIcon } from "@common/icon/icons";

import styles from "./styles.css";
import { ModalWindowPropTypes } from "./types";

const ModalWindow: FC<ModalWindowPropTypes> = ({
  children,
  open,
  onClose,
  className,
  title,
  closeButtonVariant = "contentCorner",
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto";
  }, [open]);

  useEffect(() => {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.code === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keyup", escapeHandler);
    return () => {
      document.body.style.overflow = "auto";
      window.removeEventListener("keyup", escapeHandler);
    };
  }, []);

  return (
    <div className={clsx(styles.modal, open ? styles.open : styles.close)}>
      <div
        className={clsx(
          styles.modalMain,
          closeButtonVariant === "contentCorner" && styles.contentCorner,
          className
        )}
      >
        {title && <div className={styles.title}>{title}</div>}
        {children}
        <button
          className={clsx(
            styles.closeBtn,
            closeButtonVariant === "contentCorner"
              ? styles.contentCorner
              : styles.viewportCorner
          )}
          type="button"
          onClick={onClose}
        >
          <CloseUpIcon />
        </button>
      </div>
    </div>
  );
};

export default ModalWindow;
