import React, { FC, useContext } from "react";

import {
  BookingContext,
  GeneralContext,
  HotelSearchContext,
  LoggedInContext,
} from "@app/app";
import {
  BookingService,
  useBookingController,
} from "@controllers/booking/booking-controller";
import { assertNotUndefined } from "@hx/util/types";
import { AgentusLoader } from "@widgets/agentus-loader/agentus-loader";
import { SpinnerOverlay } from "@widgets/loader/loader";

import CompleteBooking from "./complete-booking/complete-booking";

interface CompleteBookingPageProps {
  service: BookingService;
}

const CompleteBookingPage: FC<CompleteBookingPageProps> = ({ service }) => {
  const { itineraryController } = useContext(LoggedInContext);
  const hotelSearchController = useContext(HotelSearchContext);
  const { notificationController } = useContext(GeneralContext);
  const bookingController = useBookingController({
    searchController: assertNotUndefined(hotelSearchController),
    service,
    itineraryController: assertNotUndefined(itineraryController),
    notificationController: assertNotUndefined(notificationController),
  });

  if (!bookingController) {
    return <AgentusLoader />;
  }

  const { completeBookingProps } = bookingController;

  const isLoading =
    completeBookingProps.kind === "loading" ||
    (completeBookingProps.kind === "value" &&
      (completeBookingProps.value.isLoading ||
        completeBookingProps.value.paymentDetails.kind === "loading" ||
        completeBookingProps.value.cancellationPolicyForPackage.kind ===
          "loading"));

  return (
    <BookingContext.Provider value={bookingController}>
      {isLoading && <SpinnerOverlay />}
      {completeBookingProps.kind === "value" && <CompleteBooking />}
      {completeBookingProps.kind === "error" && (
        <div>Error loading booking state</div>
      )}
    </BookingContext.Provider>
  );
};

export default CompleteBookingPage;
