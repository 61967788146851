import { assertNever } from "@hx/util/types";
import React, { useState } from "react";
import { Button,  Header, Icon, Modal } from "semantic-ui-react";

import { AdlEditor } from "../../adl-editor";
import { TableColumn } from "../../adl-gen/common/adminui/api";
import { WithDbId } from "../../adl-gen/common/db";
import { SortDirection, TableView } from "../../adl-gen/common/tabular";
import * as AT from "../../adl-table";
import { createAdlTree } from "../../adl-tree";
import { AdlTable, AdlTableProps } from "../../components/AdlTable";
import { AdminService } from "../service";
import { AdminHrefFactory, createTableMetadata, dbKeyScopedName, Metadata, TableMetadata, TSRow } from "../utils";

import { createTablePageState } from "./table-page-state";
import styles from "./table-page.css";

export interface AdminUiTablePageProps {
  tableName: string;
  metadata: Metadata;
  service: AdminService,
  hrefFactory: AdminHrefFactory,
  onGotoValue(tableName: string, id: string): void;
  onDone(): void;
}

type ModalState =
  | { kind: "add" }
  | { kind: "edit"; value: WithDbId<TSRow> }
  | { kind: "delete"; value: WithDbId<TSRow> }
  ;

export const AdminUiTablePage = (props: AdminUiTablePageProps) => {
  const [modalState,setModalState] = useState<ModalState | null>(null);
  const [columnPropsShown,setColumnPropsShown] = useState<string | null>(null);
  const rowmetadata: TableMetadata = createTableMetadata(props.metadata, props.tableName, props.hrefFactory, true);
  const editormetadata: TableMetadata = createTableMetadata(props.metadata, props.tableName, props.hrefFactory, false);
  const tablePageState = createTablePageState(props.service, props.metadata, rowmetadata);

  const renderPage = (): JSX.Element => {
    const columns = getColumns();
    const modal
      = modalState !== null ? renderModal(modalState)
      : tablePageState.pendingDbError ? renderDbError(tablePageState.pendingDbError, clearDbError)
      : null;
    return (
      <div className={styles.page}>
        <div className={styles.topbar1}>
          <h1 className={styles.title}>{props.tableName}</h1>
          <AT.IconButton name="window close outline" onClick={props.onDone} />
        </div>
        <div className={styles.topbar2}>
          <p>{rowmetadata.table.description}</p>
        </div>
        {renderFilter()}
        <div  className={styles.topbar3}>
          {renderPageButtons()}
        </div>
        <div className={styles.tableholder}>
          <AdminTable columns={columns} values={tablePageState.loadedRows ? tablePageState.loadedRows.items : []} />
        </div>
        {modal}
      </div>
    );
  };

  const renderPageButtons = (): JSX.Element => {
    const addButton = !rowmetadata.table.allowInsert ? (
      undefined
    ) : (
      <AT.PageButton tooltip="Add a new row" icon="plus" onClick={addRow} />
    );
    const prevPageButton = (
      <AT.PageButton
        tooltip={"Previous page"}
        icon="left arrow"
        disabled={!tablePageState.canPageBack}
        onClick={tablePageState.pageBack}
      />
    );
    let pageLocation = <span>...</span>;
    const page = tablePageState.loadedRows;
    if (page !== null) {
      const fromi = page.current_offset + 1;
      const toi = fromi + page.items.length - 1;
      const total = page.total_size;
      pageLocation = (
        <span className={styles.pagelocation}>
          {fromi}-{toi}/{total}
        </span>
      );
    }
    const nextPageButton = (
      <AT.PageButton
        tooltip={"Next page"}
        icon="right arrow"
        disabled={!tablePageState.canPageForward}
        onClick={tablePageState.pageForward}
      />
    );
    const refreshButton = (
      <AT.PageButton
        tooltip={"Refresh"}
        icon="refresh"
        disabled={tablePageState.rowsLoading}
        loading={tablePageState.rowsLoading}
        onClick={tablePageState.reload}
      />
    );
    return (
      <div className={styles.buttonpanel}>
        {addButton}
        {prevPageButton}
        {pageLocation}
        {nextPageButton}
        {refreshButton}
      </div>
    );
  };

  const renderModal = (state: ModalState): JSX.Element => {
    if (state.kind === "add") {
      const header = "Adding new " + editormetadata.table.label + " value";
      const content = (
        <AdlEditor
          value={null}
          veditor={editormetadata.veditor}
          onCancel={clearModal}
          onApply={(tsrow: TSRow) => {
            tablePageState.create(tsrow);
            clearModal()
          }}
          allowRaw={editormetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={clearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    } else if (state.kind === "edit") {
      const header = "Editing " + editormetadata.table.label + " value with id " + state.value.id;
      const content = (
        <AdlEditor
          value={state.value.value}
          veditor={editormetadata.veditor}
          onCancel={clearModal}
          onApply={(tsrow: TSRow) => {
            tablePageState.update({id:state.value.id, value:tsrow});
            clearModal()
          }}
          allowRaw={editormetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={clearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    } else if (state.kind === "delete") {
      return (
       <Modal open={true} onClose={clearModal}>
          <Header>Delete {rowmetadata.table.name}:{state.value.id}?</Header>
          <Modal.Content style={{ margin: 0 }}>
            <div>
              Please confirm you wish to delete {state.value.id} from {rowmetadata.table.name}.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={clearModal}>
              <Icon />Cancel
            </Button>
            <Button color="red" onClick={() => {
              tablePageState.deletev(state.value.id);
              clearModal()
            }}>

              <Icon name="checkmark" />Delete
            </Button>
          </Modal.Actions>
       </Modal>
      );
    } else {
       return assertNever(state);
    }

  };

  const renderFilter = (): JSX.Element | undefined => {
    const view = tablePageState.tableView;
    if(!view) {
      return undefined;
    }
    return (
      <div className={styles.filterholder}>
        <AT.FilterView filter={view.filter} onClearFilter={onClearFilter}/>
      </div>
    );
  }

  const getColumns = (): AdminTableColumn[] => {
    const dbColumns: AdminTableColumn[] = [];
    const view = tablePageState.tableView;

    // The action button column
    dbColumns.push(createActionsColumn());

    // The id column
    dbColumns.push(createIdColumn(view));

    // All of the simple data fields - ie those that can be displayed/edited
    rowmetadata.table.columns.forEach(tcol => {
      const acol = createDbColumn(tcol, view);
      if (acol !== null) {
        dbColumns.push(acol);
      }
    });

    return dbColumns;
  };

  const createIdColumn = (view: TableView | null): AdminTableColumn => {

    const label = "ID";
    const header
      = view === null
      ? AT.cellContent(label)
      : createHeaderCell(view, label, "id");

    return {
      id: "id",
      header,
      content: (tsrow: WithDbId<TSRow>) => AT.cellContent(
        <a onClick={() => gotoValue(rowmetadata.table.name, tsrow.id)} className={styles.idlink}>{tsrow.id}</a>
      )
    };
  };

  const createActionsColumn = (): AdminTableColumn => {
    return {
      id: "actions",
      header: AT.cellContent(""),
      content: (tsrow: WithDbId<TSRow>) => {
        const editButton = (
          <AT.IconButton name="edit" onClick={() => editRow(tsrow)} />
        );
        const deleteButton = !rowmetadata.table.allowDelete ? (
          undefined
        ) : (
          <AT.IconButton name="trash" onClick={() => deleteRow(tsrow)} />
        );
        return AT.cellContent(
          <span>
            {editButton}
            {deleteButton}
          </span>
        );
      }
    };
  };

  const createDbColumn = (
    tcol: TableColumn,
    view: TableView | null
  ): AdminTableColumn | null => {
    const adlTree = createAdlTree(tcol.typeExpr, rowmetadata.resolver);
    const fieldfns = AT.getFieldFns(
      rowmetadata.resolver,
      null,
      null,
      adlTree,
      rowmetadata.customize.getCustomField
    );
    if (fieldfns === null) {
      return null;
    }

    const fieldname = tcol.name;
    const dbKeyType = dbKeyScopedName(tcol.typeExpr, rowmetadata.resolver);
    const table = dbKeyType && tablePageState.getDbKeyTable(dbKeyType);

    const toCellContent = (v:unknown): AT.CellContent => {
      if (dbKeyType && table) {
        const id = v as string;
        const ilink = <a onClick={() => gotoValue(table, id)} className={styles.idlink}>{id}</a>;
        const label = tablePageState.getDbKeyLabel(dbKeyType, id);
        if (label.length > 0) {
          return AT.cellContent(<span>{label} ({ilink})</span>);
        } else {
          return AT.cellContent(ilink);
        }        
      } else {
        return AT.cellContent(fieldfns.toText(v));
      }
    }
 
    const header
      = view === null
      ? AT.cellContent(tcol.label)
      : createHeaderCell(view, tcol.label, fieldname);

    return {
      id: tcol.name,
      header,
      content: (tsrow: WithDbId<TSRow>) => toCellContent(tsrow.value[fieldname])
    };
  };

  const createHeaderCell = (view: TableView, label: string, fieldname: string): AT.CellContent => {
    return AT.cellContent(
      <AT.HeaderCell
        label={label}
        sort={AT.getViewSort(view, fieldname)}
        filter={AT.getFieldFilter(view.filter, fieldname)}
        showProps={columnPropsShown === fieldname}
        onSort={(dir) => onSort(fieldname,dir)}
        onFilter={(pattern) => onFilter(fieldname, pattern)}
        onShowProps={(show) => setColumnPropsShown(show ? fieldname: null)}
      />
    );
  }

  const editRow = (value: WithDbId<TSRow>) => {
    setModalState({ kind: "edit", value });
  };

  const deleteRow = (value: WithDbId<TSRow>) => {
    setModalState({ kind: "delete", value });
  };

  const addRow = () => {
    setModalState({ kind: "add" });
  };

  const gotoValue = (table: string, id: string) => {
    props.onGotoValue(table, id);
  };

  const onSort = (fieldname: string, direction: SortDirection) => {
    const view = tablePageState.tableView;
    if (view) {
      const view1 = AT.withViewSort(view, fieldname, direction);
      tablePageState.setTableView(view1);
      setColumnPropsShown(null);
    }
  };

  const onFilter = (fieldname: string, pattern: string) => {
    const view = tablePageState.tableView;
    if (view) {
      const view1 = { ...view };
      view1.filter = AT.withFieldFilter(view1.filter, fieldname, pattern);
      tablePageState.setTableView(view1);
    }
  };

  const onClearFilter = () => {
    const view = tablePageState.tableView;
    if (view) {
      const view1 = { ...view };
      view1.filter = {kind:'literal', value: true};
      tablePageState.setTableView(view1);
    }
  };

  const clearModal = () => {
    setModalState(null);
  };

  const clearDbError = () => {
    setModalState(null);
    tablePageState.clearDbError();
  };

  return renderPage();
}


export function renderDbError(dbError: string, onClose: () => void): JSX.Element {
  return (
  <Modal open={true} onClose={onClose}>
     <Header>Database Error</Header>
     <Modal.Content style={{ margin: 0 }}>
       <div>{dbError}</div>
     </Modal.Content>
     <Modal.Actions>
       <Button onClick={onClose}>Ok</Button>
     </Modal.Actions>
  </Modal>
  );
}

export type AdminTableColumn = AT.Column<unknown, string>;

function AdminTable(props: AdlTableProps<WithDbId<TSRow>, string>): JSX.Element {
  return React.createElement(AdlTable, props);
}
