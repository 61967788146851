import clsx from "clsx";
import moment from "moment";
import React, { useContext, useMemo } from "react";

import { DbKey } from "@adl-gen/common/db";
import { AgentPaymentLineItem } from "@adl-gen/hotel/api";
import { LineItemType, PaymentMode } from "@adl-gen/hotel/booking";
import { PriceValue } from "@adl-gen/hotel/common";
import { Booking } from "@adl-gen/hotel/db";
import { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";
import { COMMON_DATETIME_FORMAT } from "@constants/common";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { assertValueLoaded, renderPriceString } from "@util/agentus-utis";

import itineraryStyles from "../../itinerary.css";
import { BookingSearchResultContext } from "../index";
import { FINANCIAL_ITEM_LABEL } from "./constant";
import styles from "./styles.css";

/** Component Props */
export interface FinancialDetailsProps extends BaseProps {
  classes?: { financialDetails?: string };
  bookingId: DbKey<Booking>;
}

interface FinancialData {
  lineItems: AgentPaymentLineItem[];
  grossPrice: PriceValue;
  netPrice: PriceValue;
  paymentMode: PaymentMode | null;
}

export const FinancialDetails = ({
  classes,
  className,
  bookingId,
}: FinancialDetailsProps) => {
  const searchResultState = useContext(BookingSearchResultContext);

  const data: FinancialData = useMemo(() => {
    const queryResp = assertValueLoaded(searchResultState.searchResult);
    if (queryResp.kind === "agentBookingData") {
      const selectedBooking = assertNotUndefined(
        queryResp.value.items.find(
          (booking) => booking.bookingData.bookingId === bookingId
        )
      );

      return {
        lineItems: selectedBooking.lineItems as AgentPaymentLineItem[],
        grossPrice: selectedBooking.balance.grossPriceWithTax,
        netPrice: selectedBooking.balance.netPriceWithTax,
        paymentMode: selectedBooking.bookingData.clientPaymentMode,
      };
    } else if (queryResp.kind === "backOfficeUserBookingData") {
      const selectedBooking = assertNotUndefined(
        queryResp.value.items.find(
          (booking) => booking.bookingData.bookingId === bookingId
        )
      );
      return {
        lineItems: selectedBooking.lineItems.map((lineItem) => ({
          netPriceWithTax: {
            ...lineItem.netPrice,
            value: `${
              parseFloat(lineItem.netPrice.value) +
              parseFloat(lineItem.markupTax.amount.value)
            }`,
          },
          grossPriceWithTax: {
            ...lineItem.grossPrice,
            value: `${
              parseFloat(lineItem.grossPrice.value) +
              parseFloat(lineItem.markupTax.amount.value)
            }`,
          },
          created: lineItem.created,
          lineItemId: lineItem.lineItemType,
        })),
        grossPrice: selectedBooking.balance.grossPriceWithTax,
        netPrice: selectedBooking.balance.netPriceWithTax,
        paymentMode: selectedBooking.bookingData.clientPaymentMode,
      };
    } else {
      assertNever(queryResp);
    }
  }, [searchResultState.searchResult, bookingId]);

  return (
    <div
      className={clsx(
        styles.financialDetails,
        classes?.financialDetails,
        className,
        layout.vertical
      )}
    >
      <div className={itineraryStyles.title}>Financial Details</div>

      <div className={styles.gridContainer}>
        <div className={styles.gridRow}>
          <span>Date &amp; Time</span>
          <span>Description</span>
          <span>Comments</span>
          <span className={itineraryStyles.right}>Net</span>
          <span className={itineraryStyles.right}>Gross</span>
        </div>

        {data.lineItems
          .filter(
            ({ lineItemId }) =>
              lineItemId !== LineItemType.confirmationAdjustment
          )
          .map((current, index) => (
            <div
              className={clsx(
                styles.gridRow,
                index % 2 === 0 && styles.faded,
                current.lineItemId === LineItemType.clientPayment &&
                  styles.green
              )}
              key={index}
            >
              <span>
                {moment(current.created).format(COMMON_DATETIME_FORMAT)}
              </span>
              <span>{FINANCIAL_ITEM_LABEL[current.lineItemId]}</span>
              <span />
              <span className={itineraryStyles.right}>
                {current.lineItemId !== LineItemType.clientPayment
                  ? renderPriceString(current.netPriceWithTax)
                  : data.paymentMode === PaymentMode.net
                  ? renderPriceString(current.netPriceWithTax)
                  : "0"}
              </span>
              <span className={itineraryStyles.right}>
                {current.lineItemId !== LineItemType.clientPayment
                  ? renderPriceString(current.grossPriceWithTax)
                  : data.paymentMode === PaymentMode.gross
                  ? renderPriceString(current.grossPriceWithTax)
                  : "0"}
              </span>
            </div>
          ))}

        <div className={styles.balanceRow}>
          <span />
          <span />
          <span className={itineraryStyles.right}>
            {+data.netPrice.value < 0 ? "Balance" : "Balance to Pay"}
          </span>
          <span className={itineraryStyles.right}>
            {data.paymentMode === PaymentMode.net
              ? renderPriceString(data.netPrice)
              : "-"}
          </span>
          <span className={itineraryStyles.right}>
            {data.paymentMode === PaymentMode.gross
              ? renderPriceString(data.grossPrice)
              : "-"}
          </span>
        </div>
      </div>

      <ul className={styles.cardsList}>
        {data.lineItems
          .filter(
            ({ lineItemId }) =>
              lineItemId !== LineItemType.confirmationAdjustment
          )
          .map((current, index) => (
            <li
              className={clsx(
                styles.financialCard,
                index % 2 === 0 && styles.faded,
                current.lineItemId === LineItemType.clientPayment &&
                  styles.green
              )}
              key={index}
            >
              <div>
                <span>
                  {moment(current.created).format(COMMON_DATETIME_FORMAT)}
                </span>

                <span className={itineraryStyles.right}>
                  <strong>Net: </strong>
                  {current.lineItemId !== LineItemType.clientPayment
                    ? renderPriceString(current.netPriceWithTax)
                    : data.paymentMode === PaymentMode.net
                    ? renderPriceString(current.netPriceWithTax)
                    : "0"}
                </span>
              </div>

              <div>
                <span>{FINANCIAL_ITEM_LABEL[current.lineItemId]}</span>

                <span className={itineraryStyles.right}>
                  <strong>Gross: </strong>
                  {current.lineItemId !== LineItemType.clientPayment
                    ? renderPriceString(current.grossPriceWithTax)
                    : data.paymentMode === PaymentMode.gross
                    ? renderPriceString(current.grossPriceWithTax)
                    : "0"}
                </span>
              </div>
            </li>
          ))}

        <li className={styles.balanceRow}>
          <span className={itineraryStyles.right}>
            {+data.netPrice.value < 0 ? "Balance" : "Balance to Pay"}
          </span>
          <span className={itineraryStyles.right}>
            {data.paymentMode === PaymentMode.net
              ? renderPriceString(data.netPrice)
              : "-"}
          </span>
          <span className={itineraryStyles.right}>
            {data.paymentMode === PaymentMode.gross
              ? renderPriceString(data.grossPrice)
              : "-"}
          </span>
        </li>
      </ul>
    </div>
  );
};
