import clsx from "clsx";
import React, { FC, useEffect } from "react";

import styles from "./loader.css";

interface SpinnerOverlayProps {
  type?: "local" | "global";
  withBackdrop?: boolean;
}

export const SpinnerOverlay: FC<SpinnerOverlayProps> = ({
  type = "global",
  withBackdrop = true,
}) => {
  // remove the ability to scroll the page when loading
  useEffect(() => {
    if (type === "global") {
      document.body.style.overflow = "hidden";
    }

    return () => {
      if (type === "global") {
        document.body.style.overflow = "auto";
      }
    };
  }, []);

  return (
    <div
      className={clsx(
        styles.loader,
        type === "global" && styles.global,
        withBackdrop && styles.withBackdrop
      )}
      data-testid="spinner-overlay"
    >
      <svg className={styles.circular} viewBox="25 25 50 50">
        <circle
          className={styles.path}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};
