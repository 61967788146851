import React, { useState } from "react";
import { Button,  Header, Modal } from "semantic-ui-react";

import { AdlEditor } from "../../adl-editor";
import { WithDbId } from "../../adl-gen/common/db";
import * as AT from "../../adl-table";
import { renderLoading } from "../ui-utils";
import { TableMetadata, TSRow } from "../utils";

import { renderDbError } from "./table-page";
import { PageState, PageStateParams, usePageState, ValueState } from "./value-page-state";
import styles from "./value-page.css";

/** Props for the component */
export interface AdminUiValuePageProps extends PageStateParams {
  onDone(): void;
}

/**
 * Component composing the state with the (stateless view)
 */
export const AdminUiValuePage = (props:AdminUiValuePageProps) => {
  const state = usePageState(props);
  return <AdminUiValueView
    {...state}
    onDone={props.onDone}
  />;
}

type ModalState = null | EditModal;

interface EditModal {
  kind: "edit";
  value: WithDbId<TSRow>;
};

export interface AdminUiValueViewProps extends PageState {
  onDone(): void;
}

/** 
 * The stateless view component 
 */
export const AdminUiValueView = (props:AdminUiValueViewProps) => {
  const [modal,setModal] = useState<ModalState>(null);;

  function onEdit(v: WithDbId<TSRow>) {
    setModal({kind:"edit", value:v})
  };

  async function onEditDone(v: WithDbId<TSRow>): Promise<void> {
    await props.saveValue(v);
    setModal(null);
  }

  function onClearModal() {
    setModal(null);
  }

  function renderModal(tmetadata: TableMetadata): JSX.Element | undefined {
    if (modal !== null) {
      const header = "Editing " + tmetadata.table.label + " value with id " + modal.value.id;
      const content = (
        <AdlEditor
          value={modal.value.value}
          veditor={tmetadata.veditor}
          onCancel={onClearModal}
          onApply={(tsrow: TSRow) => {void onEditDone({id:props.id, value:tsrow})}}
          allowRaw={tmetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={onClearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    }
    return undefined;
  }

  function renderPageState(vstate: ValueState): JSX.Element {
      if (vstate === "notfound") {
          return <div>not found</div>;
      } else {
          return (
            <div>
              {renderModal(props.tmetadata)}
              {props.dbError === null ? undefined : renderDbError(props.dbError, props.clearDbError)}
              {props.tmetadata.veditor.render(vstate.veditorState, false, () => undefined)}
              <div className={styles.buttoncontainer}>
                <Button primary onClick={() => onEdit(vstate.value)}>Edit</Button>
              </div>
            </div>
          );
      }
  }

  return (
    <div className={styles.page}>
          <div className={styles.topbar1}>  
            <h1>{props.table}</h1>
            <AT.IconButton name="window close outline" onClick={() => props.onDone()} />
          </div>
          <div className={styles.idrow}>id: {props.id}</div>
          <div className={styles.content}>
            {renderLoading(props.value, renderPageState)}
          </div>
    </div>
    );
};
