import clsx from "clsx";
import moment from "moment";
import React, { createRef, FC, MouseEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import { replaceDatepickerNavigationIcons } from "@util/native-dom";
import { Icons } from "@widgets/icon/icons";
import { InputDateProps } from "@widgets/input-date/input-date";
import { InputText } from "@widgets/input-text/input-text";

import styles from "./styles.css";

interface InputDateRangeProps
  extends Omit<InputDateProps, "value" | "onChange"> {
  startText: string;
  endText: string;
  startDate: Date | null;
  endDate: Date | null;
  onChange(value: [Date, Date]): void;
}

const InputDateRange: FC<InputDateRangeProps> = ({
  startText,
  endText,
  startDate,
  endDate,
  className,
  onChange,
}) => {
  const [opened, setIsOpened] = useState(false);

  const startInputRef = createRef<HTMLInputElement>();
  const endInputRef = createRef<HTMLInputElement>();
  const containerRef = createRef<HTMLInputElement>();

  function toggle() {
    setIsOpened((prevState) => !prevState);
  }

  function close({ target }: MouseEvent<HTMLDivElement>) {
    const clickStartInput = !!startInputRef.current?.contains(target as Node);
    const clickEndInput = !!endInputRef.current?.contains(target as Node);

    if (clickStartInput || clickEndInput) {
      return;
    }

    setIsOpened(false);
  }

  useEffect(() => {
    if (containerRef.current) {
      replaceDatepickerNavigationIcons(containerRef.current);
    }
  }, []);

  return (
    <div className={clsx(styles.dateInputRange, className)}>
      <InputText
        className={styles.textInput}
        label
        readOnly
        iconBefore={Icons.schedule}
        placeholder={startText}
        value={moment(startDate).format("DD MMM.")}
        onClick={toggle}
        ref={startInputRef}
      />
      <InputText
        className={styles.textInput}
        label
        readOnly
        iconBefore={Icons.schedule}
        placeholder={endText}
        value={endDate ? moment(endDate).format("DD MMM.") : ""}
        onClick={toggle}
        ref={endInputRef}
      />

      <div
        className={clsx(styles.calendarWrapper, opened && styles.opened)}
        ref={containerRef}
      >
        <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          calendarClassName={styles.inlinePicker}
          inline
          monthsShown={2}
          selectsRange
          minDate={moment(new Date()).toDate()}
          onClickOutside={close}
          onMonthChange={() =>
            replaceDatepickerNavigationIcons(
              containerRef.current as HTMLDivElement
            )
          }
          locale="enGB"
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
        />
      </div>

      <div className={styles.backdrop} />
    </div>
  );
};

export default InputDateRange;
