import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { HotelSearchFilters } from "@adl-gen/hotel/api";
import {
  EMPTY_FILTERS,
  HotelSearchTypeEnum,
} from "@controllers/hotel-search/hotel-search-controller";

/*
 * Generates rating values based on the selected integer rating
 * f.e.: Rating 3 => [3, 3.1, 3.2, ... , 3.9]
 */
export const generateRatingValues = (rating: number): number[] => {
  if (rating === 5) {
    return [rating];
  } else {
    const ratings: number[] = [];

    for (let i = rating; i < rating + 1; i += 0.1) {
      const newRating = Number(i.toFixed(1));
      if (newRating < rating + 1) {
        ratings.push(newRating);
      }
    }
    return ratings;
  }
};

/*
 * Generates rating values based on the selected integer ratings (array)
 * f.e.: Rating [3, 4] => [3, 3.1, ... , 3.9, 4, 4.1, ... , 4.9]
 */
export const generateRatingValuesFromArray = (
  ratings: (string | number)[]
): number[] =>
  ratings.reduce(
    (acc, value) => [...acc, ...generateRatingValues(Number(value))],
    []
  );

export const useSearchFilters = () => {
  const location = useLocation();

  const defaultSelectedFiltersParams = useMemo(() => {
    const params = new URLSearchParams(location.search);

    const filtersParams = params.getAll("filter").reduce((acc, current) => {
      const _split = current.split(",");
      const propertyName = _split[0];
      const propertyValuesArray = _split.filter((_value, index) => index !== 0);

      const propertyValue =
        propertyName === HotelSearchTypeEnum.HotelName
          ? propertyValuesArray[0]
          : propertyName === HotelSearchTypeEnum.StarRatings
          ? generateRatingValuesFromArray(propertyValuesArray) || []
          : // f.e. --> "Hostel_hcat"
            propertyValuesArray.map((value) => value.split("_")[1]);

      return {
        ...acc,
        [propertyName]: propertyValue,
      };
    }, {});

    return {
      ...EMPTY_FILTERS,
      ...filtersParams,
    };
  }, [location]);

  const [selectedFilters, setSelectedFilters] = useState<HotelSearchFilters>(
    defaultSelectedFiltersParams
  );

  const filtersAreEmpty: boolean = useMemo(() => {
    for (const value of Object.values(selectedFilters)) {
      if (value?.length > 0) {
        return false;
      }
    }

    return true;
  }, [selectedFilters]);

  const resetFilters = useCallback(() => {
    setSelectedFilters(defaultSelectedFiltersParams);
  }, [defaultSelectedFiltersParams]);

  return { selectedFilters, setSelectedFilters, resetFilters, filtersAreEmpty };
};
