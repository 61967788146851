import {
  ClientPaymentStatus,
  LiveStatus,
  StandardSpecialRequest,
} from "@adl-gen/hotel/booking";

export const SpecialRequestsMap = {
  [StandardSpecialRequest.earlyArrival]: "Early Arrival (before 3pm)",
  [StandardSpecialRequest.lateArrival]: "Late Arrival (after 7pm)",
  [StandardSpecialRequest.lateCheckOut]: "Late Check Out (after 10am)",
  [StandardSpecialRequest.honeyMooners]: "Honeymooners",
  [StandardSpecialRequest.interconnectingRooms]: "Interconnecting Rooms",
  [StandardSpecialRequest.nonSmokingRoom]: "Non Smoking Room",
  [StandardSpecialRequest.smokingRoom]: "Smoking Room",
  [StandardSpecialRequest.kingBed]: "King Bed in Room",
  [StandardSpecialRequest.roomOnHighFloor]: "Room On A High Floor",
  [StandardSpecialRequest.roomOnLowFloor]: "Room On A Low Floor",
  [StandardSpecialRequest.babyCot]: "Baby Cot",
  [StandardSpecialRequest.twoBedsInRoom]: "Two Beds in Room",
};

export const PAYABLE_CLIENT_STATUSES = [
  ClientPaymentStatus.unpaid,
  ClientPaymentStatus.underPaid,
  ClientPaymentStatus.commissionDue,
];

export const PAYABLE_LIVE_STATUSES = [LiveStatus.quote];

export const ALL_SEARCH_PROPERTY = "all";

export const CHECK_DRAFT_STATUS_LIMIT = 30;
export const CHECK_DRAFT_STATUS_INTERVAL = 2000;

export const MAX_AGENT_COMMISSION = 25;
