import clsx from "clsx";
import React, { FC, FormEvent } from "react";

import { AusBankAccountDetails } from "@adl-gen/hotel/common";
import layout from "@common/layout-lib.css";
import { AgencyManageProperties } from "@pages/settings/types";
import Input from "@widgets/input";

import styles from "./styles.css";

const FinancialDetails: FC<AgencyManageProperties> = ({
  agencyDetails,
  updateCurrentAgencyDetailsField,
  getFieldError,
  fieldsWithError,
  requiredFields,
}) => {
  // NOTE(jess): This whole section needs to update when we determine
  // how we want to do non-Australian bank accounts.
  const updateAusBankAccountDetails = (
    ev: FormEvent<HTMLInputElement>,
    fieldName: keyof AusBankAccountDetails
  ) => {
    const fieldValue = ev.currentTarget.value;
    if (agencyDetails) {
      let bankAccountDetails = agencyDetails.value.bankAccountDetails;
      if (bankAccountDetails?.kind === "aus") {
        const ausBankAccountDetails = bankAccountDetails.value;
        ausBankAccountDetails[fieldName] = fieldValue;
        bankAccountDetails.value = ausBankAccountDetails;
      } else {
        bankAccountDetails = {
          kind: "aus",
          value: {
            accountName: "",
            bsb: "",
            accountNumber: "",
          },
        };
        bankAccountDetails[fieldName] = fieldValue;
      }
      updateCurrentAgencyDetailsField("bankAccountDetails", bankAccountDetails);
    }
  };

  return (
    <div className={clsx(layout.vertical, styles.financialDetails)}>
      <Input
        name="accountName"
        label="Account name"
        placeholder="Account Name"
        value={agencyDetails?.value.bankAccountDetails?.value.accountName}
        onChange={(ev) => updateAusBankAccountDetails(ev, "accountName")}
        required={requiredFields?.includes("bankAccountDetails_accountName")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "bankAccountDetails_accountName")
        }
      />
      <Input
        name="bsb"
        label="BSB Number"
        placeholder="BSB"
        type="number"
        value={agencyDetails?.value.bankAccountDetails?.value.bsb}
        onChange={(ev) => updateAusBankAccountDetails(ev, "bsb")}
        required={requiredFields?.includes("bankAccountDetails_bsb")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "bankAccountDetails_bsb")
        }
      />
      <Input
        name="accountNumber"
        label="Account Number"
        type="number"
        placeholder="Account number"
        value={agencyDetails?.value.bankAccountDetails?.value.accountNumber}
        onChange={(ev) => updateAusBankAccountDetails(ev, "accountNumber")}
        required={requiredFields?.includes("bankAccountDetails_accountNumber")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "bankAccountDetails_accountNumber")
        }
      />
    </div>
  );
};

export default FinancialDetails;
