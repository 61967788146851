import { assertNever } from "@hx/util/types";
import moment from "moment";
import React from "react";

import type { LocalDate } from "@adl-gen/common";
import type { HotelRoom, StayDetails, UserProfile } from "@adl-gen/hotel/api";
import type {
  AdultFullName,
  ChildFullName,
  RoomOccupancyDetails
} from "@adl-gen/hotel/booking";
import {
  AdultTitle,
  Board
} from "@adl-gen/hotel/booking";
import type { PackageCancellationPolicy, PriceValue } from "@adl-gen/hotel/common";
import type { AppUserType } from "@adl-gen/hotel/db";
import type { Address } from "@adl-gen/ids/common";
import { COMMON_DATE_FORMAT } from "@constants/common";
import currenciesDigits from "@constants/currencies-digits.json";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";
import type { Loading } from "@models/loading";

import countries from "../constants/countries.json";

export function downloadFile(url: URL, filename: string) {
  const link = document.createElement("a");
  link.href = url.toString();
  link.download = filename;
  link.click();
}

export async function createPdfFromJSX(pages: JSX.Element[]) {
  const { Document, pdf } = await import("@react-pdf/renderer");

  const doc = <Document>{pages}</Document>;

  const blob = await pdf(doc).toBlob();
  const blobURL = URL.createObjectURL(blob);
  return new URL(blobURL);
}

export function printPdf(blobURL: URL) {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = blobURL.toString();
  document.body.appendChild(iframe);

  iframe.onload = () => {
    setTimeout(() => iframe.contentWindow!.print(), 1);
  };
}

/**
 * Renders a price value as per the following example "AUD 100.00"
 */
export function renderPriceString(
  grossPrice: PriceValue,
  options?: { fractionDigits?: number }
) {
  const value = +grossPrice.value;

  if (value === 0) {
    return `${grossPrice.currency} 0`;
  }

  const fractionDigits =
    options?.fractionDigits ??
    currenciesDigits.find(({ currency }) => currency === grossPrice.currency)
      ?.fractionDigits;

  if (fractionDigits === undefined) {
    throw new Error("Render price error: unknown currency provided");
  }

  // If fractionDigits = 0, we round the number
  return `${grossPrice.currency} ${fractionDigits
    ? value.toFixed(fractionDigits)
    : value < 0
      ? Math.floor(value)
      : Math.ceil(value)
    }`;
}

/**
 * Returns the value within the provided {@link Loading} or throws an exception if the value is not loaded
 */
export function assertValueLoaded<T>(val: Loading<T>, msg?: string): T {
  if (val.kind !== "value") {
    throw Error(msg || "Value not loaded");
  }

  return val.value;
}

/**
 * Returns the first upcoming date for the given package cancellation policy,
 * which should be used as the supplier payment due date
 */
export function getSupplierPaymentDue(
  cancellationPolicy: PackageCancellationPolicy
): moment.Moment {
  const policyDates = cancellationPolicy.policies
    .map((p) => moment(p.cancelFromDate, LOCALDATE_FORMAT))
    .sort((d1, d2) => {
      return d1.valueOf() - d2.valueOf();
    });

  return policyDates[0];
}

// This function calculates payment due date
// to make it "until" rather than "from" (as it comes from supplier) for further displaying in the UI
export function calculatePaymentDue(date: string): string {
  return moment(date, LOCALDATE_FORMAT)
    .subtract(1, "day")
    .format(LOCALDATE_FORMAT);
}

/**
 * Generates a readable string that describes the guests for a particular booking
 */
export const generateBookingGuestsString = (
  rooms: HotelRoom[],
  opts?: DescriptionOpts
): string => {
  const roomStats = new RoomStats();
  rooms.forEach(roomStats.addRoom);
  return roomStats.description(opts || {});
};

export const generateBookingGuestsStringFromOccupancy = (
  rooms: RoomOccupancyDetails[],
  opts?: DescriptionOpts
): string => {
  return generateBookingGuestsString(
    rooms.map<HotelRoom>((occupancy) => ({
      numAdults: occupancy.adults.length,
      children: occupancy.childPassengers,
    })),
    opts
  );
};

export function generateGuestNamesFromOccupancy(
  rooms: RoomOccupancyDetails[]
): string[] {
  return rooms.reduce((acc: string[], room) => {
    const adults = room.adults.map(
      ({ firstName, lastName, title }) =>
        `${getEnumValueKeyByIndex(title, AdultTitle)} ${firstName} ${lastName}`
    );

    const children = room.childPassengers.map(
      ({ name: { firstName, lastName } }) => `${firstName} ${lastName}`
    );

    return [...acc, ...adults, ...children];
  }, []);
}

export interface DescriptionOpts {
  omitNumRooms?: boolean;
}

class RoomStats {
  numRooms = 0;
  numAdults = 0;
  numChildren = 0;

  addRoom = (room: HotelRoom) => {
    this.numRooms++;
    this.numAdults += room.numAdults;
    this.numChildren += room.children.length;
  };

  description = (opts: DescriptionOpts) => {
    const room = this.numRooms > 1 ? "Rooms" : "Room";
    const adult = this.numAdults > 1 ? "Adults" : "Adult";
    const child = this.numChildren > 1 ? "Children" : "Child";
    const parts: string[] = [];
    if (!opts.omitNumRooms) {
      parts.push(`${this.numRooms} ${room}`);
    }
    parts.push(`${this.numAdults} ${adult}`);
    if (this.numChildren > 0) {
      parts.push(`${this.numChildren} ${child}`);
    }
    return parts.join(", ");
  };
}

/**
 * Renders an address as a single line string
 */
export function renderAddress(address: Address) {
  const addressComponents: (string | null)[][] = [
    [address.line1, address.line2],
    [address.stateProvinceName, address.countryCode],
    [address.postalCode],
  ];

  const nullPartsRemoved = addressComponents
    .map((part) => part.filter((line) => line !== null))
    .filter((part) => part.length > 0);

  return nullPartsRemoved.map((part) => part.join(" ")).join(", ");
}

export const makeEmptyAdultFullName = (): AdultFullName => ({
  title: AdultTitle.Mr,
  firstName: "",
  lastName: "",
});
export const makeEmptyChildFullName = (): ChildFullName => ({
  firstName: "",
  lastName: "",
});

/** Converts the board type to a user-friendly display string */
export const getBoardDisplayString = (board: Board): string => {
  switch (board) {
    case Board.roomOnly:
      return "Room only";
    case Board.continentalBreakfast:
      return "Continental breakfast";
    case Board.breakfast:
      return "Breakfast";
    case Board.fullBoard:
      return "Full board";
    case Board.halfBoard:
      return "Half board";
    case Board.allInclusive:
      return "All-inclusive";
    default:
      assertNever(board, "Illegal board type");
  }
};

/**
 * Formats the given {@link LocalDate} from the API. If no format is provided, will default to using
 * {@link COMMON_DATE_FORMAT}
 */
export const formatLocalDate = (date: LocalDate | Date, format?: string) => {
  return moment(date, LOCALDATE_FORMAT).format(format || COMMON_DATE_FORMAT);
};

export const getNightsFromRange = (
  dateStart: LocalDate | Date,
  dateEnd: LocalDate | Date
) => {
  return moment(dateEnd, LOCALDATE_FORMAT).diff(
    moment(dateStart, LOCALDATE_FORMAT),
    "days"
  );
};

export const daysStaying = (stayDetails: StayDetails): number => {
  const checkIn = moment(stayDetails.checkIn, LOCALDATE_FORMAT);
  const checkOut = moment(stayDetails.checkOut, LOCALDATE_FORMAT);
  return checkOut.diff(checkIn, "days");
};

export function getFlagUrlByCode(countryCode: string) {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`;
}

export function getCountryNameByCode(countryCode: string) {
  return countries.find(({ code }) => code === countryCode)?.name || "";
}

/** Allows to get keys enum if enum keys and values are not the same  */
export function getEnumKeys(enumObj): string[] {
  return Object.keys(enumObj).filter((value) => Number.isNaN(Number(value)));
}

export function getEnumValues(enumObj): number[] {
  return Object.values(enumObj).filter(
    (value) => Number(value) || value === 0
  ) as number[];
}

/** Checks if the specified key is a key of the given object */
export const isObjectKey = <T extends object>(key: string, obj: T) => {
  return key in obj;
};

export function getEnumValueKeyByIndex(index: number, enumObj) {
  const keys = getEnumKeys(enumObj);

  return keys[index];
}

export const checkUserAccessLevel = (
  userProfile: UserProfile | undefined,
  type: AppUserType
): boolean =>
  !!(userProfile?.appUser && userProfile.appUser.value.userType <= type);

export function maskSensitiveData(
  value: string | undefined,
  visibleChars = 4
): string {
  if (!value) {
    return "Not Provided";
  }

  const maskedLength = Math.max(value.length - visibleChars, 0);
  return "*".repeat(maskedLength) + value.slice(-visibleChars);
}

export function validateRequiredEnvVar(value: string | undefined): string {
  if (!value || value.includes("PLACEHOLDER")) {
    throw new Error(
      `Environment variable ${value} is not set or contains a placeholder value. Please check your .env file or environment variables.`
    );
  }
  return value;
}