import clsx from "clsx";
import React, { FC } from "react";

import { BaseProps } from "@common/base";

import styles from "./styles.css";

const Label: FC<BaseProps> = ({ children, className }) => {
  return (
    <span className={clsx(styles.agentusLabel, className)}>{children}</span>
  );
};

export default Label;
