import { observer } from "mobx-react";
import * as React from "react";

import { MenuIcon } from "../../common/icon/icons";

const styles = require("./page-layout.css");

export interface LinkItem {
  text: string;
  onClick(): void;
}

export interface Menu {
  title: string;
  items: LinkItem[];
}

/** Props for the component */
export interface PageLayoutProps {
  /** Page title */
  title: string;

  /** Elements of the primary content area */
  children?: React.ReactNode;

  /** The link to be shown */
  link?: LinkItem;

  /** The menu */
  menu?: Menu;
}

/**
 * Layout for typical pages.
 */
@observer
export class PageLayout extends React.Component<PageLayoutProps> {
  /** Renders the header */
  renderHeader() {
    return (
      <header className={styles.header}>
        <div>agentus - {this.props.title}</div>
        {this.props.link ? (
          <div className={styles.goLink} onClick={this.props.link.onClick}>
            {this.props.link.text}
          </div>
        ) : undefined}
        {this.props.menu ? (
          <div className={styles.dropdown}>
            <div className={styles.dropbtn}>
              <span>{this.props.menu.title}</span>
              <span className={styles.menuIcon}>
                <MenuIcon size="2x" />
              </span>
            </div>
            <div className={styles.dropcontent}>
              {this.props.menu.items.map((i) => (
                <a onClick={i.onClick} key={i.text}>
                  {i.text}
                </a>
              ))}
            </div>
          </div>
        ) : undefined}
      </header>
    );
  }

  /** Renders the content body */
  renderBody() {
    return <div className={styles.body}>{this.props.children}</div>;
  }

  /** Renders the page */
  render() {
    return (
      <div className={styles.page}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}
