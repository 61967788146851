import clsx from "clsx";
import React, { useContext, useEffect } from "react";

import { BookingSearchShortcut } from "@adl-gen/hotel/api";
import { BookingSearchContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import Label from "@widgets/label";

import styles from "./styles.css";

const RefinedSearch = () => {
  const {
    fetchShortcuts,
    shortcutsCount,
    selectedShortcut,
    toggleShortcut,
  } = assertNotUndefined(useContext(BookingSearchContext));

  useEffect(() => {
    fetchShortcuts();
  }, []);

  function toggle(shortcut: BookingSearchShortcut) {
    return () => {
      toggleShortcut(shortcut);
    };
  }

  return (
    <div className={styles.root}>
      <h2>Refined Booking Search</h2>

      <div className={styles.categories}>
        <div className={styles.category}>
          <Label>Pending</Label>

          <ul>
            <li
              className={clsx(
                selectedShortcut === BookingSearchShortcut.quoteBookAndHold &&
                  styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.quoteBookAndHold)}
            >
              <span>Quote</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.quoteBookAndHold)}
              </span>
            </li>

            <li
              className={clsx(
                selectedShortcut ===
                  BookingSearchShortcut.quoteInstantPurchase && styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.quoteInstantPurchase)}
            >
              <span>Instant Purchase</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.quoteInstantPurchase)}
              </span>
            </li>
          </ul>
        </div>

        <div className={styles.category}>
          <Label>Unpaid</Label>

          <ul>
            <li
              className={clsx(
                selectedShortcut === BookingSearchShortcut.confirmedUnpaid &&
                  styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.confirmedUnpaid)}
            >
              <span>Confirmed Unpaid</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.confirmedUnpaid)}
              </span>
            </li>

            <li
              className={clsx(
                selectedShortcut === BookingSearchShortcut.paymentDueIn2Days &&
                  styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.paymentDueIn2Days)}
            >
              <span>Payments Due in 48h</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.paymentDueIn2Days)}
              </span>
            </li>
          </ul>
        </div>

        <div className={styles.category}>
          <Label>paid</Label>

          <ul>
            <li
              className={clsx(
                selectedShortcut === BookingSearchShortcut.confirmedAndPaid &&
                  styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.confirmedAndPaid)}
            >
              <span>Confirmed (Paid)</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.confirmedAndPaid)}
              </span>
            </li>

            <li
              className={clsx(
                selectedShortcut === BookingSearchShortcut.refundPending &&
                  styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.refundPending)}
            >
              <span>Refund Pending</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.refundPending)}
              </span>
            </li>
          </ul>
        </div>

        <div className={styles.category}>
          <Label>fyI</Label>

          <ul>
            <li
              className={clsx(
                selectedShortcut === BookingSearchShortcut.paymentDueIn30Days &&
                  styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.paymentDueIn30Days)}
            >
              <span>Payments Due in 30 Days</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.paymentDueIn30Days)}
              </span>
            </li>

            <li
              className={clsx(
                selectedShortcut ===
                  BookingSearchShortcut.departureWithin7Days && styles.selected
              )}
              onClick={toggle(BookingSearchShortcut.departureWithin7Days)}
            >
              <span>Departure within 7 Days</span>
              <span>
                {shortcutsCount.get(BookingSearchShortcut.departureWithin7Days)}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RefinedSearch;
