import clsx from "clsx";
import React, { FC, MouseEvent, useState } from "react";

import {
  ImageSlashIcon,
  LeftArrowIcon,
  RightArrowIcon,
} from "@common/icon/icons";

import cardStyles from "../styles.css";
import styles from "./styles.css";

interface Props {
  images: string[];
  onClick?(): void;
}

const HotelCardImage: FC<Props> = ({ images, onClick }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const firstImage = currentImage === 0;
  const lastImage = currentImage === images.length - 1;
  const imageSrc = images[currentImage];

  function selectNext(e: MouseEvent) {
    e.stopPropagation();
    setCurrentImage(currentImage + 1);
  }

  function selectPrevious(e: MouseEvent) {
    e.stopPropagation();
    setCurrentImage(currentImage - 1);
  }

  return images.length ? (
    <div className={cardStyles.hotelCoverImage} onClick={onClick}>
      {images.length > 1 && (
        <>
          <button
            className={clsx(styles.navButton, styles.left)}
            disabled={firstImage}
            style={firstImage ? { visibility: "hidden" } : {}}
            onClick={selectPrevious}
          >
            <LeftArrowIcon />
          </button>
          <button
            className={clsx(styles.navButton, styles.right)}
            style={lastImage ? { visibility: "hidden" } : {}}
            onClick={selectNext}
          >
            <RightArrowIcon />
          </button>
        </>
      )}

      <div
        className={styles.image}
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
    </div>
  ) : (
    <div className={cardStyles.noHotelCoverImage} onClick={onClick}>
      <ImageSlashIcon className={styles.imageSlashIcon} size="2x" />
    </div>
  );
};

export default HotelCardImage;
