
// AdminUi specific routes
export type AdminRoute =
  | { route: "main" }
  | { route: "table"; table: string }
  | { route: "value"; table: string, id: string }
  ;

export function adminRouteFromParts(parts: string[]): AdminRoute | undefined {
  if (parts.length === 1 && parts[0] === "main") {
    return { route: "main" };
  } else if (parts.length === 2 && parts[0] === "table") {
    return { route: "table", table: parts[1] };
  } else if (parts.length === 3 && parts[0] === "value") {
    return { route: "value", table: parts[1], id: parts[2] };
  }
  return undefined;
}

export function pathFromAdminRoute(route: AdminRoute): string {
  switch (route.route) {
    case "main":
      return "/main";
    case "table":
      return "/table/" + route.table;
    case "value":
      return "/value/" + route.table + "/" + route.id
  }
}
