import clsx from "clsx";
import React, { FC, memo } from "react";

import { DbKey } from "@adl-gen/common/db";
import * as db from "@adl-gen/hotel/db";
import { MapMarker } from "@assets/svg-components";
import { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";
import { renderAddress } from "@util/agentus-utis";
import { Badge, BadgeColor } from "@widgets/badge/badge";
import { Checkbox } from "@widgets/checkbox/checkbox";
import { AgentSelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";
import { getBookingLabel } from "@widgets/itinerary/utils";

import itineraryStyles from "../../itinerary.css";
import styles from "./styles.css";

interface Props extends BaseProps {
  classes?: { itinerary?: string };
  toggleSelected?(bookingId: DbKey<db.Booking>, selected: boolean): void;
  selected?: boolean;
  booking: AgentSelectedBookingInfo;
}

const BookingItemSmall: FC<Props> = ({
  className,
  selected,
  toggleSelected,
  booking,
}) => {
  const {
    liveStatus,
    clientPaymentStatus,
    bookingId,
    bookingNumber,
    bookingPrice,
    hotelName,
    hotelAddress,
    checkIn,
    checkOut,
    isInstantPurchase,
  } = booking;

  return (
    <div className={clsx(styles.bookingItem, layout.vertical, className)}>
      <div className={styles.summary}>
        <div className={clsx(styles.shortInfo, layout.vertical)}>
          <div
            className={clsx(layout.horizontal, layout.spaced, layout.middle)}
          >
            {toggleSelected && (
              <Checkbox
                checked={selected}
                size="small"
                classes={{ proxy: styles.selectionCheckboxProxy }}
                onChange={(checked) => toggleSelected(bookingId || "", checked)}
              />
            )}

            <span className={styles.text}>#{bookingNumber}</span>
            <Badge
              className={styles.typeBadge}
              text={
                getBookingLabel(
                  liveStatus,
                  clientPaymentStatus,
                  isInstantPurchase,
                  booking.bookingNumber
                ).label
              }
              variant={BadgeColor.Purple}
            />
          </div>
          <div className={layout.vertical}>
            <div className={clsx(styles.hotelName, styles.text)}>
              {hotelName}
            </div>
            <div
              className={clsx(
                styles.hotelLocation,
                itineraryStyles.label,
                layout.horizontal,
                layout.middle
              )}
            >
              <MapMarker />

              <span>{renderAddress(hotelAddress)}</span>
            </div>
          </div>
        </div>

        <div className={layout.horizontal}>
          <div className={clsx(styles.checkIn, layout.vertical)}>
            <div className={itineraryStyles.label}>Check-In</div>
            <div className={styles.text}>{checkIn}</div>
          </div>
          <div className={clsx(layout.vertical)}>
            <div className={itineraryStyles.label}>Check-Out</div>
            <div className={styles.text}>{checkOut}</div>
          </div>
        </div>

        <div className={styles.price}>
          <span className={styles.priceValue}>
            {Math.ceil(parseFloat(bookingPrice.grossPriceWithTax.value))}
          </span>
          <span className={styles.currency}>
            {bookingPrice.grossPriceWithTax.currency}
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(BookingItemSmall);
