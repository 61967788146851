import clsx from "clsx";
import React, { FC, RefObject, useEffect, useRef } from "react";

import styles from "./styles.css";

const OFFSET = "4px";

interface TooltipProps {
  text: string | JSX.Element;
  position?: "top" | "bottom";
  classes?: {
    tooltip?: string;
    wrapper?: string;
  };
}

const Tooltip: FC<TooltipProps> = ({
  text,
  position = "top",
  classes,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const classNames = [styles.tooltip, styles[position], classes?.tooltip];

  useEffect(() => {
    const scrollHandler = () => {
      if (tooltipRef.current && containerRef.current) {
        changePosition(containerRef, tooltipRef);
      }
    };

    document.addEventListener("scroll", scrollHandler, true);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    if (tooltipRef.current && containerRef.current) {
      changePosition(containerRef, tooltipRef);
    }
  }, [tooltipRef.current, containerRef.current]);

  // This dynamic position change is required to avoid issues with overflow:hidden containers
  function changePosition(
    container: RefObject<HTMLDivElement>,
    tooltip: RefObject<HTMLDivElement>
  ) {
    const {
      top,
      left,
      width,
      bottom,
    } = container.current!.getBoundingClientRect();
    const {
      height: tooltipHeight,
      width: tooltipWidth,
    } = tooltip.current!.getBoundingClientRect();

    const containerMidX = left + width / 2;
    const tooltipLeftPosition = containerMidX - tooltipWidth / 2;

    tooltip.current!.style.top =
      position === "top"
        ? `calc(${top}px - (${tooltipHeight}px + ${OFFSET})`
        : `calc(${bottom}) + ${OFFSET}`;
    tooltip.current!.style.left = `${tooltipLeftPosition}px`;
  }

  return (
    <div
      className={clsx(styles.tooltipWrapper, classes?.wrapper)}
      ref={containerRef}
    >
      {children}

      <div className={clsx(classNames)} ref={tooltipRef}>
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
