import classNames from "classnames";
import moment from "moment";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { StayDetails } from "@adl-gen/hotel/api";
import { Location } from "@adl-gen/ids/externalapi";
import { HotelSearchContext } from "@app/app";
import { ROUTE_PATH } from "@constants/common";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";
import { MINIMAL_SEARCH_STRING_LENGTH } from "@controllers/location-search/constant";
import {
  getHotelContext,
  getHotelName,
  getNameContext,
} from "@controllers/location-search/location-search-controller";
import { debounce } from "@hx/util/timers";
import { assertNotUndefined } from "@hx/util/types";
import InputDateRange from "@widgets/input-date-range";

import { Button } from "../button/button";
import { GuestsPicker } from "../guests-picker/guests-picker";
import {
  LocationPicker,
  LocationPickerBadgeType,
  OptionProps,
} from "../location-picker/location-picker";
import styles from "./search-bar.css";

function getLocationType(l: Location): LocationPickerBadgeType {
  const locationTypeKind = l.locationType.kind;
  if (locationTypeKind === "poi") {
    return "landmark";
  } else if (locationTypeKind === "region") {
    return "area";
  } else if (locationTypeKind === "hotel") {
    return "hotel";
  } else {
    throw new Error(`Not allowed location type: ${locationTypeKind}`);
  }
}

export const SearchBar = () => {
  const [optionProps, setOptionsProps] = useState<OptionProps[]>([]);
  const history = useHistory();
  const location = useLocation();
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const debouncedLocationSearch = useRef(
    debounce<string>(500, hotelSearchController.searchForMatchingLocations)
  );

  const setSearchString = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    hotelSearchController.setSearchString(searchText);

    if (searchText.length > MINIMAL_SEARCH_STRING_LENGTH) {
      debouncedLocationSearch.current(searchText);
    } else {
      debouncedLocationSearch.current.cancel();
    }
  };

  const normalizeInputDates = (
    checkIn: Date,
    checkOut: Date
  ): [string, string | null] => {
    const checkInNormalized = moment(checkIn).format(LOCALDATE_FORMAT);
    const checkOutNormalized = checkOut
      ? moment(checkOut).format(LOCALDATE_FORMAT)
      : null;

    return [checkInNormalized, checkOutNormalized];
  };

  const changeHandler = (event) => {
    const name = event.target.name as keyof StayDetails;
    const value = event.target.value;

    const _value = name === "rooms" ? JSON.parse(value) : value;
    hotelSearchController.setStayDetails((prevState: StayDetails) => {
      return {
        ...prevState,
        [name]: _value,
      };
    });
  };

  function changeCheckRange([checkIn, checkOut]: [Date, Date]) {
    hotelSearchController.setStayDetails((prevState: StayDetails) => {
      const [checkInNormalized, checkOutNormalized] = normalizeInputDates(
        checkIn,
        checkOut
      );

      return {
        ...prevState,
        checkIn: checkInNormalized,
        checkOut: checkOutNormalized,
      };
    });
  }

  useEffect(() => {
    if (hotelSearchController.searchLocations.kind === "error") {
      // TODO(Barry > James): Figure out a better way to display loading and error messages for this widget
      setOptionsProps([]);
    } else if (hotelSearchController.searchLocations.kind === "value") {
      // TODO(Barry): Render the available hotels
      const options: OptionProps[] = [];
      for (const loc of hotelSearchController.searchLocations.value
        .availableLocations) {
        const nameContext = getNameContext(loc);
        const type = getLocationType(loc);

        options.push({
          value: nameContext,
          text: nameContext,
          type,
          onClick: () => hotelSearchController.selectLocation(loc),
        });
      }

      for (const hotel of hotelSearchController.searchLocations.value
        .availableHotels) {
        const hotelName = getHotelName(hotel);
        const hotelContext = getHotelContext(hotel);
        const type = "hotel";

        const loc: Location = {
          locationType: {
            kind: "hotel",
            value: hotel.hotelId,
          },
          name: hotel.name,
          context: null,
        };

        options.push({
          value: hotelName,
          text: hotelContext,
          type,
          onClick: () => hotelSearchController.selectLocation(loc),
        });
      }

      setOptionsProps(options);
    }
  }, [hotelSearchController.searchLocations]);

  const locationInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!hotelSearchController.locationSearchString) {
      locationInputRef.current?.focus();
    }
  }, []);

  const searchHotels = async () => {
    hotelSearchController.resetSearchParams();

    if (hotelSearchController.selectedLocation) {
      await hotelSearchController.startNewHotelSearch(
        { updateUrl: true },
        true
      );
      if (location.pathname !== ROUTE_PATH.Search) {
        location.pathname = ROUTE_PATH.Search;
        history.push(location);
      }
    }
  };

  const classes = styles;

  const startDate = hotelSearchController.stayDetails.checkIn
    ? new Date(hotelSearchController.stayDetails.checkIn)
    : null;
  const endDate = hotelSearchController.stayDetails.checkOut
    ? new Date(hotelSearchController.stayDetails.checkOut)
    : null;

  return (
    <div className={classNames(styles.searchBar)}>
      <LocationPicker
        ref={locationInputRef}
        className={styles.locationPicker}
        classes={classes}
        onChange={setSearchString}
        label
        placeholder="Going to?"
        options={optionProps}
        value={hotelSearchController.locationSearchString}
        isLoading={hotelSearchController.searchLocations.kind === "loading"}
      />
      <InputDateRange
        className={styles.inputDateRange}
        startDate={startDate}
        endDate={endDate}
        startText="Check-in"
        endText="Check-out"
        onChange={changeCheckRange}
      />
      <GuestsPicker
        className={styles.guestsPicker}
        classes={styles}
        value={hotelSearchController.stayDetails.rooms}
        onChange={changeHandler}
      />
      <Button
        className={styles.searchButton}
        /// Don't let the users search hotels if they haven't selected a location or hotel yet
        disabled={!hotelSearchController.selectedLocation || !endDate}
        onClick={searchHotels}
      >
        Search
      </Button>
    </div>
  );
};
