import classnames from "classnames";
import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import layout from "@common/layout-lib.css";
import { Icons } from "@widgets/icon/icons";

import { InputText, InputTextProps } from "../input-text/input-text";
import styles from "./input-select.css";

/** Props to display a text input */

export interface InputSelectProps
  extends Omit<InputTextProps, "children" | "label" | "onChange"> {
  /** Optional additional classes to apply to internal elements */
  classes?: InputTextProps["classes"] & {
    inputSelect?: string;
    inputContainer?: string;
    iconAfter?: string;
    selectList?: string;
    displayValue?: string;
  };
  // Block being able to specify an iconAfter, this is used internally by this component
  iconAfter?: never;
  /** Optional, indicates that the select is initially open */
  open?: boolean;
  /** Callback on toggle event */
  onToggle?(isOpen: boolean): void;
  /** Components to be placed inside the dropdown panel */
  children?: undefined | JSX.Element | JSX.Element[];
  label?: string;
  onChange?(value: string | number): void;
  /** Property that will represent current value in the UI */
  displayValue?: string | number | JSX.Element;
  searchable?: boolean;
}

export const InputSelect = ({
  className,
  classes = {},
  open,
  children,
  label,
  onChange,
  displayValue,
  value,
  placeholder,
  required,
  searchable = false,
  ...props
}: InputSelectProps): JSX.Element => {
  // Manage open/close state of the select list
  const [isOpen, setIsOpen] = useState(open || false);
  const [searchValue, setSearchValue] = useState("");

  // If the open state is explicitly passed in then override the internal state
  useEffect(() => {
    const _open = open !== undefined ? open : isOpen;

    if (isOpen !== _open) {
      setIsOpen(_open);
    }
  }, [open, value]);

  // Ref to handle the click outside
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Close the dropdown if the user clicks outside of the component
  useEffect(() => {
    const clickOutsideHandler = (event) => {
      if (
        wrapperRef &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", clickOutsideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
    };
  }, [wrapperRef]);

  const clickHandler = () => {
    setIsOpen(!isOpen);
  };

  const containerClasses = clsx(
    layout.vertical,
    styles.inputSelect,
    classes.inputSelect,
    className,
    isOpen && styles.open
  );

  function clickOption(val: string) {
    return () => {
      if (onChange) {
        onChange(val);
      }
      setIsOpen(false);
    };
  }

  const childrenArray = useMemo(
    () => React.Children.toArray(children) as ReactElement[],
    [children]
  );

  const childrenWithHandlers = useMemo(
    () =>
      childrenArray.map((child) => {
        return React.cloneElement(child, {
          onClick: clickOption(child.props.value),
        });
      }),
    [childrenArray]
  );

  const filteredChildrenWithHandlers = useMemo(
    () =>
      childrenWithHandlers.filter((child) => {
        if (typeof child.props.children === "string") {
          return child.props.children
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        } else if (typeof child.props.value === "string") {
          return child.props.value
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        }
        return true;
      }),
    [childrenWithHandlers, searchValue]
  );

  function handleSearchValueChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchValue(event.currentTarget.value);
  }

  useEffect(() => {
    if (searchable && !isOpen) {
      setSearchValue("");
    }
  }, [isOpen]);

  const _classes = { ...classes };
  _classes.inputText = clsx(
    classes.inputText,
    styles.inputText,
    isOpen && styles.open
  );
  _classes.input = styles.input;

  return (
    <div ref={wrapperRef} className={containerClasses} tabIndex={0}>
      {label && (
        <label className={styles.label}>
          {`${label}${required ? "*" : ""}`}
        </label>
      )}
      <span className={clsx(classes?.displayValue, styles.displayValue)}>
        {displayValue}
      </span>
      <InputText
        classes={_classes}
        onClick={clickHandler}
        readOnly
        value={displayValue ? "" : value}
        placeholder={displayValue ? "" : placeholder}
        {...props}
      />
      <div className={clsx(classes.selectList, styles.selectList)}>
        {searchable && (
          <InputText
            className={styles.searchInput}
            iconBefore={Icons.search}
            onChange={handleSearchValueChange}
            value={searchValue}
            placeholder="Search text"
          />
        )}
        <div className={styles.selectListInner}>
          {searchValue ? filteredChildrenWithHandlers : childrenWithHandlers}
        </div>
      </div>
    </div>
  );
};

export interface InputSelectOptionProps {
  /** the value that would be passed to change event */
  value: string | number;
  /** option ID  */
  id?: string;
  /** Components to be placed inside the dropdown panel */
  children: string | JSX.Element | JSX.Element[];
}

interface InputSelectOptionInnerProps extends InputSelectOptionProps {
  onClick?(): void;
}

const InputSelectOptionInner: FC<InputSelectOptionInnerProps> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <div className={classnames(styles.option)} onClick={onClick} {...props}>
      {children}
    </div>
  );
};

export const InputSelectOption = (props: InputSelectOptionProps) => {
  return <InputSelectOptionInner {...props} />;
};
