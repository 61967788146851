import clsx from "clsx";
import React, { FC, useMemo } from "react";

import { GooglePlaceType } from "@constants/common";
import countries from "@constants/countries.json";
import { AgencyManageProperties } from "@pages/settings/types";
import Input from "@widgets/input";
import InputPlaceAutocomplete from "@widgets/input-place-autocomplete";
import { InputSelect, InputSelectOption } from "@widgets/input-select";
import { InputText } from "@widgets/input-text/input-text";

import phoneCountryCodes from "../../phone-country-codes.json";
import { TIME_ZONES } from "../constants";
import parentStyles from "../styles.css";
import styles from "./styles.css";

const ContactDetails: FC<AgencyManageProperties> = ({
  agencyDetails,
  formInputChangeHandler,
  updateCurrentAgencyDetailsField,
  updateCurrentAgencyDetailsNestedField,
  getFieldError,
  fieldsWithError,
  requiredFields,
}) => {
  if (!agencyDetails) {
    return <div>Loading..</div>;
  }

  const selectedCountryName = useMemo(
    () =>
      countries.find(
        ({ code }) => code === agencyDetails?.value.address.countryCode
      )?.name,
    [agencyDetails?.value.address.countryCode]
  );

  return (
    <div className={styles.contactDetails}>
      <Input
        name="keyContactName"
        label="Manager Name"
        value={agencyDetails.value.keyContactName}
        onChange={formInputChangeHandler}
        required={requiredFields?.includes("keyContactName")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "keyContactName")
        }
      />
      {/*
       * TODO(jess): Move this address down and style appropriately.
       * It doesn't make sense here, it's all bundled up with the manager
       * details.
       */}
      <Input
        name="line1"
        label="Business Street Number & Street Name"
        value={agencyDetails?.value.address.line1}
        onChange={(ev) =>
          updateCurrentAgencyDetailsNestedField(
            "address",
            "line1",
            ev.currentTarget.value
          )
        }
        required={requiredFields?.includes("address_line1")}
        error={
          getFieldError &&
          fieldsWithError &&
          getFieldError(fieldsWithError, "address_line1")
        }
      />
      <div className={clsx(styles.multipleInputs, styles.countryAndTimezone)}>
        <InputSelect
          onChange={(val: string) =>
            updateCurrentAgencyDetailsNestedField("address", "countryCode", val)
          }
          label="Country"
          value={agencyDetails?.value.address.countryCode || undefined}
          displayValue={selectedCountryName}
          required={requiredFields?.includes("address_countryCode")}
          searchable
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "address_countryCode")
          }
        >
          {countries.map((country) => (
            <InputSelectOption key={country.name} value={country.code}>
              {country.name}
            </InputSelectOption>
          ))}
        </InputSelect>

        <InputSelect
          onChange={(val: string) => {
            updateCurrentAgencyDetailsField("timezone", val);
          }}
          classes={{
            inputText: parentStyles.longInput,
          }}
          label="Timezone"
          value={agencyDetails?.value.timezone}
          required={requiredFields?.includes("timezone")}
          searchable
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "timezone")
          }
        >
          {TIME_ZONES.map((timezone, index) => (
            <InputSelectOption
              key={`${timezone.displayLabel}${index}`}
              value={timezone.timezoneValue}
            >
              {timezone.displayLabel}
            </InputSelectOption>
          ))}
        </InputSelect>
      </div>

      <div className={styles.managerContactNumber}>
        <InputSelect
          className={styles.countryCodeDropdown}
          classes={{ selectList: styles.countryCodeDropdownList }}
          label="Manager Contact Number"
          onChange={(countryCode: string) => {
            updateCurrentAgencyDetailsNestedField(
              "keyContactPhoneNumber",
              "countryCode",
              // Remove the extra plus sign prefix before sending back to server.
              countryCode.replace(/\+/g, "")
            );
          }}
          value={`+${agencyDetails?.value.keyContactPhoneNumber.countryCode}`}
          required={requiredFields?.includes(
            "keyContactPhoneNumber_countryCode"
          )}
          searchable
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "keyContactPhoneNumber_countryCode")
          }
        >
          {phoneCountryCodes.map((code) => (
            <InputSelectOption key={code.name} value={code.dial_code}>
              {`${code.dial_code} \(${code.name}\) ${code.flag}`}
            </InputSelectOption>
          ))}
        </InputSelect>
        <InputText
          name="number"
          type="number"
          className={parentStyles.mediumInput}
          onChange={(ev) =>
            updateCurrentAgencyDetailsNestedField(
              "keyContactPhoneNumber",
              "number",
              ev.currentTarget.value
            )
          }
          placeholder={"Phone number"}
          value={agencyDetails?.value.keyContactPhoneNumber.number}
          required={requiredFields?.includes("keyContactPhoneNumber_number")}
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "keyContactPhoneNumber_number")
          }
        />
      </div>
      <div className={clsx(styles.multipleInputs, styles.addressDetails)}>
        <Input
          className={parentStyles.shortInput}
          name="postalCode"
          label="Postcode"
          value={agencyDetails?.value.address.postalCode || undefined}
          onChange={(ev) =>
            updateCurrentAgencyDetailsNestedField(
              "address",
              "postalCode",
              ev.currentTarget.value
            )
          }
          required={requiredFields?.includes("address_postalCode")}
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "address_postalCode")
          }
        />

        <InputPlaceAutocomplete
          className={parentStyles.shortInput}
          name="stateProvinceName"
          value={agencyDetails?.value.address.stateProvinceName || undefined}
          label="State"
          onChange={({ longName, shortName }) => {
            updateCurrentAgencyDetailsNestedField(
              "address",
              "stateProvinceName",
              longName
            );
            updateCurrentAgencyDetailsNestedField(
              "address",
              "stateProvinceCode",
              shortName
            );
          }}
          placeTypes={[GooglePlaceType.State]}
          restrictions={{
            country: agencyDetails?.value.address.countryCode,
            state: null,
          }}
          required={requiredFields?.includes("address_stateProvinceName")}
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "address_stateProvinceName")
          }
        />

        <InputPlaceAutocomplete
          className={parentStyles.shortInput}
          name="city"
          value={agencyDetails?.value.address.city}
          label="Suburb"
          placeTypes={[GooglePlaceType.City]}
          restrictions={{
            country: agencyDetails?.value.address.countryCode,
            state: agencyDetails?.value.address.stateProvinceName,
          }}
          required={requiredFields?.includes("address_city")}
          onChange={({ longName }) =>
            updateCurrentAgencyDetailsNestedField("address", "city", longName)
          }
          error={
            getFieldError &&
            fieldsWithError &&
            getFieldError(fieldsWithError, "address_city")
          }
        />
      </div>
    </div>
  );
};

export default ContactDetails;
