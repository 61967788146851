import React, { FC, memo, useMemo, useRef, useState } from "react";
import { InfoWindow, Marker } from "react-google-maps";

import { markerIcon, markerSoldIcon } from "@assets/svg";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import HotelMapCard from "@pages/hotel-search/hotel-results/map-view-layout/hotel-map-card";

import {
  horizontalOffset,
  positionBottom,
  positionHorizontalMiddle,
  positionLeft,
  positionRight,
  positionTop,
  positionVerticalMiddle,
  verticalOffset,
} from "./constants";
import styles from "./styles.css";

interface PropTypes {
  hotel: HotelListingResultWithId;
  onMarkerClick(hotelId: string): void;
  iconSize?: number;
  highlighted?: boolean;
  mapRect?: DOMRect;
}

const HotelMapMark: FC<PropTypes> = ({
  onMarkerClick,
  hotel,
  mapRect,
  highlighted,
  iconSize,
}) => {
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  const [markPosition, setMarkPosition] = useState({
    horizontal: positionTop,
    vertical: positionTop,
  });

  const markerRef = useRef<Marker | null>(null);

  // Flags for correct marker rendering when moving the map
  // So it won't blink if the previous value matches the new one
  const firstPriceLoad = useRef(true);
  const prevPricePresent = useRef(false);

  function handleMouseOver(e) {
    if (mapRect) {
      const extremeTop = mapRect.top;
      const extremeRight = mapRect.width + mapRect.left;
      const extremeBottom = mapRect.top + mapRect.height;
      const extremeLeft = mapRect.left;

      const newMarkPosition = {
        horizontal: positionTop,
        vertical: positionTop,
      };

      /** Horizontal position */
      // if the marker is close to the left map border
      if (e.domEvent.x - extremeLeft < horizontalOffset) {
        newMarkPosition.horizontal = positionRight;
      }
      // if the marker is close to the right map border
      else if (extremeRight - e.domEvent.x < horizontalOffset) {
        newMarkPosition.horizontal = positionLeft;
      } else {
        // if the marker has enough space on the right and left
        // the tooltip's default horizontal position is centered relative to the marker
        newMarkPosition.horizontal = positionHorizontalMiddle;
      }

      /** Vertical position */
      // if the marker is close to the top of the map
      if (extremeBottom - e.domEvent.y < verticalOffset) {
        newMarkPosition.vertical = positionTop;
      }
      // if the marker is close to the bottom of the map
      else if (e.domEvent.y - extremeTop < verticalOffset) {
        newMarkPosition.vertical = positionBottom;
      } else {
        // if the marker has enough space above and below

        // if the marker is close to the left or right border of the map - vertical alignment to the center
        if (
          e.domEvent.x - extremeLeft < horizontalOffset ||
          extremeRight - e.domEvent.x < horizontalOffset
        ) {
          newMarkPosition.vertical = positionVerticalMiddle;
        } else {
          // default vertical position of the tooltip above the marker
          newMarkPosition.vertical = positionTop;
        }
      }
      setMarkPosition(newMarkPosition);
    }
    setShowInfoTooltip(true);
  }
  function handleMouseExit() {
    setShowInfoTooltip(false);
  }

  const pricePresent = useMemo(() => {
    if (hotel.details.priceDetails.kind === "loading") {
      return prevPricePresent.current;
    }

    const result =
      hotel.details.priceDetails.kind === "value" &&
      hotel.details.priceDetails.value.kind === "available";

    firstPriceLoad.current = false;
    prevPricePresent.current = result;

    return result;
  }, [hotel.details.priceDetails]);

  function handleHotelClick() {
    onMarkerClick(hotel.id);
  }

  if (firstPriceLoad.current && hotel.details.priceDetails.kind !== "value") {
    return null;
  }

  const icon = {
    url: pricePresent ? markerIcon : markerSoldIcon,
    scaledSize: iconSize ? new google.maps.Size(iconSize, iconSize) : undefined,
  };

  return (
    <Marker
      ref={markerRef}
      position={hotel.details.coordinates}
      onClick={handleHotelClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseExit}
      options={{ icon }}
      animation={highlighted ? google.maps.Animation.BOUNCE : undefined}
    >
      {showInfoTooltip && (
        <InfoWindow
          options={{
            disableAutoPan: true,
            pixelOffset: new google.maps.Size(
              markPosition.horizontal,
              markPosition.vertical
            ),
          }}
        >
          <HotelMapCard className={styles.tooltipInfo} hotel={hotel} />
        </InfoWindow>
      )}
    </Marker>
  );
};

export default memo(HotelMapMark);
