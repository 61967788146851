export const ITINERARY_TOTALS_OPTIONS: { label: string; key: string }[] = [
  {
    label: "BALANCE DUE",
    key: "balanceDue",
  },
  {
    label: "NET",
    key: "netPrice",
  },
  {
    label: "GROSS",
    key: "grossPrice",
  },
  {
    label: "COMMISSION",
    key: "commission",
  },
  {
    label: "SUPPLIER",
    key: "supplierPrice",
  },
  {
    label: "BUY",
    key: "buyPrice",
  },
];
