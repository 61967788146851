import { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";

export function isBookingPayable(
  liveStatus: LiveStatus,
  clientPaymentStatus: ClientPaymentStatus
) {
  return (
    liveStatus === LiveStatus.draft ||
    (liveStatus === LiveStatus.confirmed &&
      clientPaymentStatus === ClientPaymentStatus.unpaid) ||
    (liveStatus === LiveStatus.confirmed &&
      clientPaymentStatus === ClientPaymentStatus.unpaid)
  );
}
