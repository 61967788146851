import React, { FC, useContext } from "react";

import { GeneralContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";

import Notification from "../index";
import styles from "./styles.css";

const NotificationBar: FC = () => {
  const { notifications, deleteNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  return (
    <div className={styles.notificationBar}>
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          notification={notification}
          onClose={deleteNotification}
        />
      ))}
    </div>
  );
};

export default NotificationBar;
