import clsx from "clsx";
import React, { FC, useContext, useEffect, useRef, useState } from "react";

import { LocationInfoResp } from "@adl-gen/ids/externalapi";
import { HotelSearchContext } from "@app/app";
import { ArrowPlainRight } from "@assets/svg-components";
import { BaseProps } from "@common/base";
import { FilledStarIcon, FilterIcon } from "@common/icon/icons";
import { HotelViewEnum } from "@controllers/customHooks/useHotelView";
import {
  AMENITIES_FILTER_TITLE,
  HotelSearchTypeEnum,
  HOTEL_CHAIN_TITLE,
  HOTEL_STAR_RATING_FILTER_TITLE,
  PROPERTY_TYPE_TITLE,
  REGION_TITLE,
} from "@controllers/hotel-search/hotel-search-controller";
import { assertNotUndefined } from "@hx/util/types";
import ModalWindow from "@widgets/modal-window";

import { FilterHotelName } from "./filter-hotel-name/filter-hotel-name";
import styles from "./filter-panel.css";
import { Filter, FilterOption } from "./filter/filter";
import { IconButton } from "@widgets/button/icon-button";

const STICKY_PANEL_THRESHOLD = 150;

const stars: JSX.Element[] = [];
for (let i = 0; i < 5; i++) {
  stars.push(
    <FilledStarIcon
      key={`star-rating-${i}`}
      className={styles.starIcon}
      size="sm"
    />
  );
}

/**
 * Comparator which sorts filter options by their display string
 * value in alphabetical order (asc).
 */
const sortFilterOptionAlphabetically = (a: FilterOption, b: FilterOption) => {
  if (
    typeof a.displayString === "string" &&
    typeof b.displayString === "string"
  ) {
    return a.displayString.localeCompare(b.displayString);
  } else {
    throw new Error(
      "This comparator is only used for filter options with display string types"
    );
  }
};

/** Component Props */
export interface FilterPanelProps extends BaseProps {
  collapsed: boolean;
  onChangeCollapsed(collapsed: boolean): void;
  classes?: { filterPanel?: string };
}

export const FilterPanel: FC<FilterPanelProps> = ({
  classes: propsClasses,
  className,
  collapsed,
  onChangeCollapsed,
}) => {
  const classes = propsClasses || {};
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const collapsible = hotelSearchController.hotelView === HotelViewEnum.MapView;

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [modalOpened, setModalOpened] = useState(false);


  useEffect(() => {
    onChangeCollapsed(false);
  }, [collapsible]);

  useEffect(() => {
    const scrollCallback = () => {
      if (
        containerRef.current &&
        contentRef.current &&
        hotelSearchController.hotelView === HotelViewEnum.ListView
      ) {
        const sticky =
          containerRef.current.getBoundingClientRect()?.top <
          STICKY_PANEL_THRESHOLD;
        if (sticky) {
          contentRef.current.classList.add(styles.sticky);
        } else {
          contentRef.current.classList.remove(styles.sticky);
        }
      }
    };

    window.addEventListener("scroll", scrollCallback);

    return () => {
      window.removeEventListener("scroll", scrollCallback);
      contentRef.current?.classList.remove(styles.sticky);
    };
  }, [hotelSearchController.hotelView]);

  const { locationInfo } = hotelSearchController;
  if (locationInfo.kind === "error") {
    return <div>{locationInfo.error}</div>;
  } else {
    const locationInfoVal =
      locationInfo.kind === "value" ? locationInfo.value : null;
    return (
      <div
        className={clsx(
          styles.filterPanelContainer,
          collapsed && styles.collapsed
        )}
      >
        {!collapsed && <div className={styles.clipWrap} ref={containerRef}>
          <div
            className={clsx(styles.filterPanel, classes.filterPanel, className)}
            ref={contentRef}
          >
            <div className={styles.head}>
              <FilterHotelName />
              {/** @link https://xllabs.atlassian.net/browse/AG-545 */}
              {/*<FilterPrice />*/}
              <IconButton
                className={styles.otherFiltersButton}
                onClick={() => setModalOpened(true)}
                emphasis="low"
                iconAfter={<FilterIcon />}
              >
                Other filters
              </IconButton>
            </div>

            <div className={styles.mdContainer}>
              <FiltersContent locationValue={locationInfoVal} />
            </div>
            <ModalWindow
              className={styles.smContainer}
              open={modalOpened}
              onClose={() => setModalOpened(false)}
            >
              <>
                <h3>Search filters</h3>
                <div>
                  <FiltersContent locationValue={locationInfoVal} />
                </div>
              </>
            </ModalWindow>
          </div>
        </div>}

        {collapsible && (
          <div
            className={styles.collapseButton}
            onClick={() => onChangeCollapsed(!collapsed)}
          >
            <ArrowPlainRight />
          </div>
        )}
      </div>
    );
  }
};

const FiltersContent: FC<{ locationValue: LocationInfoResp | null }> = ({
  locationValue,
}) => {
  return (
    <>
      <Filter
        propertyKey={HotelSearchTypeEnum.StarRatings}
        title={HOTEL_STAR_RATING_FILTER_TITLE}
        options={[
          {
            displayString: <div>{stars}</div>,
            value: "5",
          },
          {
            displayString: <div>{stars.slice(0, 4)}</div>,
            value: "4",
          },
          {
            displayString: <div>{stars.slice(0, 3)}</div>,
            value: "3",
          },
          {
            displayString: <div>{stars.slice(0, 2)}</div>,
            value: "2",
          },
          {
            displayString: <div>{stars[0]}</div>,
            value: "1",
          },
        ]}
        searchable={false}
      />
      <Filter
        propertyKey={HotelSearchTypeEnum.PropertyType}
        title={PROPERTY_TYPE_TITLE}
        placeholder={"Find property types"}
        options={
          locationValue
            ? locationValue.hotelCategories
              .map((c) => {
                return { displayString: c.value.name, value: c.id };
              })
              .sort(sortFilterOptionAlphabetically)
            : []
        }
      />
      <Filter
        propertyKey={HotelSearchTypeEnum.HotelChain}
        title={HOTEL_CHAIN_TITLE}
        placeholder={"Find hotel chains"}
        options={
          locationValue
            ? locationValue.hotelChains
              .map((c) => {
                return { displayString: c.value.name, value: c.id };
              })
              .sort(sortFilterOptionAlphabetically)
            : []
        }
      />
      <Filter
        propertyKey={HotelSearchTypeEnum.Amenity}
        title={AMENITIES_FILTER_TITLE}
        placeholder={"Find amenities"}
        options={
          locationValue
            ? locationValue.amenities
              .map((a) => {
                return { displayString: a.value.name, value: a.id };
              })
              .sort(sortFilterOptionAlphabetically)
            : []
        }
      />
      <Filter
        propertyKey={HotelSearchTypeEnum.Regions}
        title={REGION_TITLE}
        placeholder={"Find a Region / Area"}
        options={
          locationValue
            ? locationValue.subRegions
              .map((r) => {
                return { displayString: r.value.name, value: r.id };
              })
              .sort(sortFilterOptionAlphabetically)
            : []
        }
      />
    </>
  );
};
