import {
  LOCATION_ID_PARAM,
  LOCATION_NAME_CONTEXT_PARAM,
  LOCATION_TYPE_PARAM,
} from "@controllers/location-search/location-search-controller";

import {
  CHECKIN_PARAM,
  CHECKOUT_PARAM,
  COMMISSION_PARAM,
  ORDER_PARAM,
  ROOM_PARAM,
} from "./constant";

export function isHotelSearchUrlParams(object: URLSearchParams): boolean {
  return (
    object.has(CHECKIN_PARAM) &&
    object.has(CHECKOUT_PARAM) &&
    object.has(ROOM_PARAM) &&
    object.has(COMMISSION_PARAM) &&
    object.has(ORDER_PARAM) &&
    object.has(LOCATION_ID_PARAM) &&
    object.has(LOCATION_TYPE_PARAM) &&
    object.has(LOCATION_NAME_CONTEXT_PARAM)
  );
}
