import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { ROUTE_PATH } from "@constants/common";
import { Button } from "@widgets/button/button";
import { InputText } from "@widgets/input-text/input-text";

import styles from "./styles.css";

const QuickSearch = () => {
  const [bookingId, setBookingId] = useState("");
  const [itineraryId, setItineraryId] = useState("");

  const location = useLocation();

  useEffect(() => {
    resetParams();
  }, [location.pathname]);

  const onChangeBookingId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setBookingId(e.target.value);
  }, []);

  const onChangeItineraryId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setItineraryId(e.target.value);
    },
    []
  );

  function resetParams() {
    setBookingId("");
    setItineraryId("");
  }

  function onSubmit(e: FormEvent) {
    e.preventDefault();

    const urlParams = new URLSearchParams();

    if (bookingId) {
      urlParams.set("bookingNumber", bookingId);
    }

    if (itineraryId) {
      urlParams.set("itineraryNumber", itineraryId);
    }

    window.open(
      `${ROUTE_PATH.BookingsAdvanced}?${urlParams.toString()}`,
      "_self"
    );
  }

  return (
    <form className={styles.quickSearchRoot} onSubmit={onSubmit}>
      <InputText
        className={styles.input}
        placeholder="Itinerary ID"
        value={itineraryId}
        onChange={onChangeItineraryId}
      />
      <InputText
        className={styles.input}
        placeholder="Booking Number"
        value={bookingId}
        onChange={onChangeBookingId}
      />
      <Button type="submit">Search</Button>
    </form>
  );
};

export default QuickSearch;
