import { AdminRoute } from "@adltools/adminui";
import { action, observable } from "mobx";

import { UserProfile } from "../adl-gen/hotel/api";
import { AppUserType } from "../adl-gen/hotel/db";
import { IdentityController } from "@controllers/identity/identity-controller";
import { pathFromRoute, Route, routeFromPath } from "../routing/app-routes";
import { Router } from "../routing/router";
import { Service } from "../service/service";

/** Admin state */
export interface AdminState {
  kind: "admin";
  userProfile: UserProfile;
  adminRoute: AdminRoute;
}

/**
 * Top level app data and actions, including navigation.
 */
export class AdminStore {
  @observable.deep state: AdminState;

  constructor(
    /** Router which is the bridge to the browser location */
    private readonly router: Router,
    /** The server backend */
    readonly service: Service,
    /** The global identity store */
    readonly identityStore: IdentityController,
    /** Pointer to react router's current location which stores where the app is now */
    private readonly reactRouterLocation
  ) {
    if (identityStore.userProfile) {
      this.state = {
        kind: "admin",
        userProfile: identityStore.userProfile,
        adminRoute: { route: "main" },
      };

      // Asynchronously register route change listener to not block construction,
      // in case the store factories rely on the app store being constructed
      setTimeout(
        () => router.registerRouteChangeListener(this.onRouteChange),
        0
      );
    }
  }

  /** Updates state based on route changes */
  @action onRouteChange = async () => {
    const userProfile = this.identityStore.userProfile;
    const isAdminUser =
      userProfile && userProfile.appUser.value.userType === AppUserType.admin;
    let route = routeFromPath(this.router.getCurrentPath());
    if (route === undefined) {
      const pathName = this.reactRouterLocation.pathname;
      // If the user is logged in, redirect to search unless the pathname
      // tracked by react router is admin.
      if (userProfile !== undefined) {
        if (pathName === "/admin") {
          this.state = {
            kind: "admin",
            userProfile,
            adminRoute: { route: "main" },
          };
          return;
        } else {
          route = { route: "login" };
        }
      } else {
        route = { route: "login" };
      }
      void (await this.navigateTo(route));
      return;
    } else if (route.route === "admin" && userProfile && isAdminUser) {
      this.state = {
        kind: "admin",
        userProfile,
        adminRoute: route.admin,
      };
      return;
    }

    await this.navigateTo({ route: "login" });
  };

  async navigateTo(route: Route) {
    return this.router.go(pathFromRoute(route));
  }
}
