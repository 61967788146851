/**
 * Wrapper around taking an async action which enforces only a single action is
 * in flight and will invoke a subsequent action if one was requested during
 * that time. Useful for fetching data.
 */
export class GuardedPromise {

  /** Promise of the action in flight */
  actionPromise: Promise<unknown> = Promise.resolve();

  /** Whether the action was requested when one was already in flight */
  hasPendingRequest: boolean = false;

  constructor(
    /** Performs the action */
    private readonly performAction: () => Promise<unknown>,
  ) {
  }

  /**
   * Requests for an action to be performed and returns when it returns.
   */
  requestAction(): Promise<unknown> {
    if (!this.hasPendingRequest) {
      // Chain the action to happen after the current one in flight
      this.hasPendingRequest = true;
      this.actionPromise = this.actionPromise
        // Simulate `finally` instead of actually using .finally because calling finally will not execute the pending
        // request if actionPromise throws an error
        .then(this.executePendingRequest, this.executePendingRequest);
    }
    return this.actionPromise;
  }

  /** Executes the pending requested action */
  executePendingRequest = () => {
    this.hasPendingRequest = false;
    return this.performAction();
  }

}
