import clsx from "clsx";
import moment from "moment";
import type { FC, FormEvent} from "react";
import React, { useMemo } from "react";

import type { LocalDate } from "@adl-gen/common";
import type { BookingSearchQuery } from "@adl-gen/hotel/api";
import type { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import { UnavailableIcon } from "@assets/svg-components";
import layout from "@common/layout-lib.css";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";
import Clearable, { createClearHandler } from "@widgets/clearable";
import Input from "@widgets/input";
import type { InputDateProps } from "@widgets/input-date/input-date";
import { InputDate } from "@widgets/input-date/input-date";

import ClientPaymentStatusDropdown from "./client-payment-status-dropwdown";
import LiveStatusDropdown from "./live-status-dropdown";
import styles from "./styles.css";

const DATE_PICKER_FORMAT = "DD.MM.YYYY";
const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";

const ClearableInput = Clearable(Input);

interface Props {
  changeHandler(ev: FormEvent<HTMLInputElement>): void;
  onToggleLiveStatus(value: LiveStatus, checked: boolean): void;
  onTogglePaymentStatus(value: ClientPaymentStatus, checked: boolean): void;
  restrictPaymentStatusVisibility: boolean;
  setQueryField<T extends keyof BookingSearchQuery>(
    fieldName: T,
    value?: BookingSearchQuery[T]
  ): void;
  searchParams: BookingSearchQuery;
}

const AdvancedSearchOptions: FC<Props> = ({
  changeHandler,
  onToggleLiveStatus,
  onTogglePaymentStatus,
  restrictPaymentStatusVisibility,
  searchParams,
  setQueryField,
}) => {
  const {
    createdFrom,
    createdTo,
    serviceFrom,
    serviceTo,
    liveStatuses,
    clientPaymentStatuses,
    userName,
  } = searchParams;

  function clearField<T extends keyof BookingSearchQuery>(name: T) {
    return () => {
      setQueryField(name, undefined);
    };
  }

  const createdToMinDate = useMemo(() => {
    return createdFrom ? moment(createdFrom).toDate() : undefined;
  }, [createdFrom]);

  const serviceToMinDate = useMemo(() => {
    return serviceFrom
      ? moment(serviceFrom.from, LOCALDATE_FORMAT).toDate()
      : undefined;
  }, [serviceFrom]);

  const convertToInstant = (localDate?: LocalDate | null) => {
    return localDate ? moment(localDate, DATE_PICKER_FORMAT).valueOf() : null;
  };

  const convertToIsoString = (localDate?: LocalDate | null) => {
    return localDate
      ? moment(localDate, DATE_PICKER_FORMAT).format(LOCALDATE_FORMAT)
      : null;
  };

  function onChangeServiceFrom(event: FormEvent<HTMLInputElement>) {
    setQueryField("serviceFrom", {
      from: convertToIsoString(event.currentTarget.value),
      to: null,
    });
  }

  function onChangeServiceTo(event: FormEvent<HTMLInputElement>) {
    setQueryField("serviceTo", {
      from: null,
      to: convertToIsoString(event.currentTarget.value),
    });
  }

  function onChangeNumberDate(event: FormEvent<HTMLInputElement>) {
    setQueryField(
      event.currentTarget.name as keyof BookingSearchQuery,
      convertToInstant(event.currentTarget.value)
    );
  }

  return (
    <div
      className={clsx(
        styles.advancedSearch,
        restrictPaymentStatusVisibility && styles.disabledPayments
      )}
    >
      <div>
        <CustomDateInput
          name="createdFrom"
          label="Created from"
          val={
            createdFrom ? moment(createdFrom).format(DISPLAY_DATE_FORMAT) : ""
          }
          onChange={onChangeNumberDate}
          placeholder="12/12/2022"
          onClear={clearField("createdFrom")}
          disableFuture
        />
        <CustomDateInput
          name="createdTo"
          label="To"
          val={createdTo ? moment(createdTo).format(DISPLAY_DATE_FORMAT) : ""}
          onChange={onChangeNumberDate}
          placeholder="12/12/2022"
          onClear={clearField("createdTo")}
          disableFuture
          minDate={createdToMinDate}
        />
        <CustomDateInput
          name="serviceFrom"
          label="Service from"
          val={
            serviceFrom
              ? moment(serviceFrom.from, LOCALDATE_FORMAT).format(
                  DISPLAY_DATE_FORMAT
                )
              : ""
          }
          onChange={onChangeServiceFrom}
          placeholder="12/12/2022"
          onClear={clearField("serviceFrom")}
        />
        <CustomDateInput
          name="serviceTo"
          label="To"
          val={
            serviceTo
              ? moment(serviceTo.to, LOCALDATE_FORMAT).format(
                  DISPLAY_DATE_FORMAT
                )
              : ""
          }
          onChange={onChangeServiceTo}
          placeholder="12/12/2022"
          onClear={clearField("serviceTo")}
          minDate={serviceToMinDate}
        />
      </div>
      <div>
        <LiveStatusDropdown
          onSelectOption={onToggleLiveStatus}
          value={liveStatuses}
        />
        {restrictPaymentStatusVisibility || (
          <ClientPaymentStatusDropdown
            onSelectOption={onTogglePaymentStatus}
            value={clientPaymentStatuses}
          />
        )}
        <ClearableInput
          name="userName"
          label="Username"
          value={userName || ""}
          onChange={changeHandler}
          placeholder="Username"
          onClear={createClearHandler("userName", searchParams, clearField)}
        />
      </div>
    </div>
  );
};

export default AdvancedSearchOptions;

interface CustomDateInputProps
  extends Pick<
    InputDateProps,
    "name" | "disablePast" | "disableFuture" | "minDate"
  > {
  label: string;
  val?: LocalDate;
  onChange(ev: FormEvent<HTMLInputElement>): void;
  onClear(): void;
  placeholder?: string;
}

const CustomDateInput = (dateInputProps: CustomDateInputProps) => {
  const { label, val, onClear, ...restProps } = dateInputProps;

  return (
    <div className={clsx(layout.vertical, styles.inputBox)}>
      <span className={styles.inputLabel}>{label}</span>
      <InputDate
        classes={{ inputText: styles.inputText }}
        value={val}
        label={false}
        iconAfter={val ? <UnavailableIcon /> : undefined}
        onClickIconAfter={onClear}
        {...restProps}
      />
    </div>
  );
};
