export function getOffsetFromPageNumber(
  pageNumber: number,
  itemsPerPage: number
): number {
  return (pageNumber - 1) * itemsPerPage;
}

export function getPageNumberFromOffset(
  offset: number,
  itemsPerPage: number
): number {
  return offset / itemsPerPage + 1;
}
