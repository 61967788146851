import clsx from "clsx";
import React, { HTMLAttributes } from "react";

import styles from "./badge.css";

export interface BadgeLabel {
  label: string;
  color: BadgeColor;
}

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  text: string;
  variant: BadgeColor;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  testId?: string;
}

/**
 * Component to display a coloured badge. Colour schemes are pre-baked depnding
 * on the badge text.
 *
 * @author: James Millar
 */

export enum BadgeColor {
  Green = "green",
  Red = "red",
  Yellow = "yellow",
  Purple = "purple",
  Orange = "orange",
  Grey = "grey",
  Black = "black",
  LightBlue = "lightBlue",
}

export const Badge = ({
  text,
  startIcon,
  endIcon,
  variant,
  className,
  testId,
  ...props
}: BadgeProps) => {
  return (
    <div
      {...props}
      className={clsx(styles.badge, styles[variant], className)}
      data-testid={testId}
    >
      {startIcon}
      <span>{text}</span>
      {endIcon}
    </div>
  );
};
