import React, { PropsWithChildren } from "react";

import styles from "./visually-hidden.css";

interface Props {
  as?: keyof JSX.IntrinsicElements;
}

export const VisuallyHidden = ({
  as: Comp = "span",
  children,
}: PropsWithChildren<Props>) => {
  return <Comp className={styles.visuallyHidden}>{children}</Comp>;
};
