import clsx from "clsx";
import React, { FC, memo, useContext, useState } from "react";

import { GeneralContext } from "@app/app";
import { assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { Button } from "@widgets/button/button";
import { Checkbox } from "@widgets/checkbox/checkbox";

import {
  BOOK_AND_HOLD_ACKNOWLEDGEMENT_TEXT,
  CONDITIONS_AGREEMENT_TEXT,
} from "../constants";
import paymentStyles from "../payment-form.css";
import styles from "./styles.css";

interface PropTypes {
  onSubmit(): Promise<void>;
  // Used for validation additional data during payment process (i.e. booking form)
  isExternalDataValid?(): boolean;
}

const BookAndHoldPayment: FC<PropTypes> = ({
  onSubmit,
  isExternalDataValid,
}) => {
  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const [conditionsAgreed, setConditionsAgreed] = useState(false);
  const [bookAndHoldAckAgreed, setBookAndHoldAckAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const externalDataValid = isExternalDataValid
      ? isExternalDataValid()
      : true;

    if (!externalDataValid) {
      addNotification({
        type: NotificationTypeEnum.Warning,
        text:
          "The name fields are not completed, please add the adult first and last names to continue",
      });
      return;
    }

    if (!conditionsAgreed || !bookAndHoldAckAgreed) {
      addNotification({
        type: NotificationTypeEnum.Warning,
        text:
          !conditionsAgreed || !bookAndHoldAckAgreed
            ? "Please accept the Booking Terms and Conditions "
            : "The name fields are not completed, please add the adult first and last names to continue",
      });
      return;
    }

    setIsLoading(true);

    await onSubmit();

    setIsLoading(false);
  };

  return (
    <form id="payment-form-book-and-hold" onSubmit={handleSubmit}>
      <div
        className={clsx([
          paymentStyles.row,
          styles.bookAndHoldAckRow,
          styles.override,
        ])}
      >
        <Checkbox
          size="small"
          checked={conditionsAgreed}
          className={paymentStyles.checkbox}
          text={CONDITIONS_AGREEMENT_TEXT}
          multiline={true}
          onChange={setConditionsAgreed}
        />
      </div>
      <div className={paymentStyles.row}>
        <Checkbox
          size="small"
          checked={bookAndHoldAckAgreed}
          className={paymentStyles.checkbox}
          text={BOOK_AND_HOLD_ACKNOWLEDGEMENT_TEXT}
          multiline={true}
          onChange={setBookAndHoldAckAgreed}
        />
      </div>
      <div className={paymentStyles.row}>
        <Button
          type="submit"
          className={paymentStyles.payNowButton}
          disabled={isLoading || !conditionsAgreed || !bookAndHoldAckAgreed}
        >
          Confirm
        </Button>
      </div>
    </form>
  );
};

export default memo(BookAndHoldPayment);
