import React from "react";

import {
  ClientPaymentStatus,
  LiveStatus,
  SupplierPaymentStatus,
} from "@adl-gen/hotel/booking";
import {
  ErrorOutlinedIcon,
  SuccessOutlinedIcon,
  Wallet,
} from "@assets/svg-components";
import { trackAndLogError } from "@util/error-handling";
import { BadgeColor } from "@widgets/badge/badge";
import type {
  BackofficeBookingInfo,
  BackofficePaymentLabel,
} from "@widgets/itinerary/booking/types";
import { getEnumValueKeyByIndex } from "@src/util/agentus-utis";

// TODO: complete mapping after final requirements
export function getBackofficeClientPaymentLabel(
  booking: BackofficeBookingInfo
): BackofficePaymentLabel | null {
  const {
    clientPaymentStatus,
    liveStatus,
    paymentDue,
    isCancellationFailed,
    isRefundable,
    isInstantPurchase,
  } = booking;

  switch (liveStatus) {
    case LiveStatus.unavailable:
    case LiveStatus.draft:
    case LiveStatus.quote:
      return null;
    case LiveStatus.confirmed: {
      switch (clientPaymentStatus) {
        case ClientPaymentStatus.underPaid:
          return {
            label: "Underpaid",
            color: BadgeColor.Yellow,
            icon: <Wallet />,
          };
        case ClientPaymentStatus.unpaid:
          return {
            label: "Unpaid",
            color: BadgeColor.Yellow,
            icon: <Wallet />,
          };
        case ClientPaymentStatus.refundDue:
          return {
            label: "Refund Due",
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        case ClientPaymentStatus.paidViaCredit:
        case ClientPaymentStatus.paid:
          return {
            label: "Paid",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        case ClientPaymentStatus.paymentSubmitted:
          return {
            // TODO: use payment submission date instead
            label: `Payment Submitted On ${paymentDue}`,
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        default:
          return null;
      }
    }
    case LiveStatus.pendingConfirmation:
      return {
        label: "Unpaid",
        color: BadgeColor.Yellow,
        icon: <Wallet />,
      };
    case LiveStatus.cancelled: {
      if (isCancellationFailed) {
        return null;
      }

      switch (clientPaymentStatus) {
        case ClientPaymentStatus.paid: {
          if (isRefundable) {
            return {
              label: "Refund Due",
              color: BadgeColor.LightBlue,
              icon: <Wallet />,
            };
          } else {
            if (!isInstantPurchase) {
              return {
                label: "Refund Due",
                color: BadgeColor.LightBlue,
                icon: <Wallet />,
              };
            }

            return {
              label: "Paid",
              color: BadgeColor.Green,
              icon: <SuccessOutlinedIcon />,
            };
          }
        }
        case ClientPaymentStatus.refundDue: {
          return {
            label: "Refund Due",
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        }
        default:
          return null;
      }
    }
    case LiveStatus.travelled: {
      switch (clientPaymentStatus) {
        case ClientPaymentStatus.settled:
          return {
            label: "Settled",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        case ClientPaymentStatus.commissionDue:
        case ClientPaymentStatus.paid:
          return {
            label: "Paid On",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        default: {
          logPaymentLabelError("client", liveStatus, clientPaymentStatus, booking.bookingNumber);

          return {
            label: "Paid On",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        }
      }
    }
  }
}

export function getBackofficeSupplierPaymentLabel(
  booking: BackofficeBookingInfo
): BackofficePaymentLabel | null {
  const { liveStatus, isCancellationFailed, supplierPaymentStatus } = booking;

  switch (liveStatus) {
    case LiveStatus.unavailable:
    case LiveStatus.draft:
    case LiveStatus.quote:
      return null;
    case LiveStatus.confirmed: {
      switch (supplierPaymentStatus) {
        case SupplierPaymentStatus.paid:
        case SupplierPaymentStatus.creditDue:
          return {
            label: "Paid",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        case SupplierPaymentStatus.unpaid:
          return {
            label: "Unpaid",
            color: BadgeColor.Yellow,
            icon: <Wallet />,
          };
        default:
          return null;
      }
    }
    case LiveStatus.pendingConfirmation:
      return {
        label: "Unpaid",
        color: BadgeColor.Yellow,
        icon: <Wallet />,
      };
    case LiveStatus.cancelled: {
      if (isCancellationFailed) {
        return null;
      }

      switch (supplierPaymentStatus) {
        case SupplierPaymentStatus.paid:
          return {
            label: "Credit Due",
            color: BadgeColor.LightBlue,
            icon: <Wallet />,
          };
        case SupplierPaymentStatus.unpaid:
          return {
            label: "Cancelled",
            color: BadgeColor.Red,
            icon: <ErrorOutlinedIcon />,
          };
        default:
          return null;
      }
    }
    case LiveStatus.travelled: {
      switch (supplierPaymentStatus) {
        case SupplierPaymentStatus.settled:
          return {
            label: "Settled",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        case SupplierPaymentStatus.paid:
          return {
            label: "Paid",
            color: BadgeColor.Green,
            icon: <SuccessOutlinedIcon />,
          };
        case SupplierPaymentStatus.unpaid:
          return {
            label: "Unpaid",
            color: BadgeColor.Grey,
            icon: <Wallet />,
          };
        default: {
          logPaymentLabelError("supplier", liveStatus, supplierPaymentStatus, booking.bookingNumber);
          return null;
        }
      }
    }
  }
}

const formatErrorMsg = (labelType: string) => `An error occurred while calculating the backoffice ${labelType} payment label mapping due to unknown conditions.`

function logPaymentLabelError(
  labelType: "client" | "supplier",
  liveStatus: LiveStatus,
  paymentStatus: ClientPaymentStatus | SupplierPaymentStatus,
  bookingNumber: string
) {
  const additionalInfo = {
    liveStatus: getEnumValueKeyByIndex(liveStatus, LiveStatus),
    clientPaymentStatus: getEnumValueKeyByIndex(
      paymentStatus,
      labelType === "client"
        ? ClientPaymentStatus
        : SupplierPaymentStatus
    ),
    bookingNumber
  }
  trackAndLogError(formatErrorMsg(labelType), additionalInfo)
}
