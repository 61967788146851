import { cloneDeep, isEqual } from "lodash";
import intersectionWith from "lodash/intersectionWith";
import moment from "moment";
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { BigDecimal, LatLng, Paginated } from "@adl-gen/common";
import { DbKey, WithDbId } from "@adl-gen/common/db";
import {
  AgentHotelSearchQuery,
  AgentHotelSearchResp,
  CancellationPoliciesResp,
  CancellationPoliciesResp_PackageCancellationPolicies,
  Child,
  HotelListingDetails,
  HotelRoom,
  HotelSearchFilters,
  HotelSearchParameters,
  HotelSearchSortOrder,
  LeadingPriceQueryResp,
  LeadingPriceType,
  PackageInfo,
  PriceToken,
  SimpleCancellationPolicy_FreeCancellationUntil,
  StayDetails,
} from "@adl-gen/hotel/api";
import { HotelDetails } from "@adl-gen/hotel/booking";
import { StarRating } from "@adl-gen/ids/common";
import { Amenity, Hotel } from "@adl-gen/ids/db";
import {
  HotelImageResp,
  Location,
  LocationType as AgentusLocationType,
  PoiNearHotel,
} from "@adl-gen/ids/externalapi";
import {
  OUTDATED_HOTELS_DATA_MSG,
  ROUTE_PATH,
  UPDATED_HOTELS_DATA_MSG,
} from "@constants/common";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import {
  BED_PARAM,
  BOARD_PARAM,
  CANCELLATION_PARAM,
  HOTEL_PARAM,
  PACKAGE_ID_PARAM,
  PRICE_TOKEN_PARAM,
  ROOM_ID_PARAM,
  SHOW_ON_MAP_PARAM,
} from "@controllers/booking/constant";
import {
  CachedHotels,
  useHotelListing,
} from "@controllers/customHooks/useHotelListing";
import {
  HotelViewEnum,
  useHotelView,
} from "@controllers/customHooks/useHotelView";
import {
  generateRatingValues,
  useSearchFilters,
} from "@controllers/customHooks/useSearchFilters";
import { ITINERARY_NUMBER_PARAM } from "@controllers/itinerary/constant";
import { NotificationController } from "@controllers/notification-controller";
import { GuardedPromise } from "@hx/util/guarded_promise";
import { sleep } from "@hx/util/timers";
import { assertNever, assertNotNull, assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { HotelListingResultDetails, HotelPriceDetails } from "@models/hotel";
import { Loading, mapLoading } from "@models/loading";
import { fromPromise } from "@models/loading-state";
import {
  calculatePaymentDue,
  daysStaying,
  getSupplierPaymentDue,
  renderAddress,
} from "@util/agentus-utis";
import { sendErrorToRollbar } from "@util/error-handling";
import { USER_PATH_PARAM } from "@widgets/page/header-breadcrumbs/constants";
import { BreadCrumbType } from "@widgets/page/header-breadcrumbs/types";

import { Service } from "../../service/service";
import { usePagination } from "../customHooks/usePagination";
import { useSortOrder } from "../customHooks/useSortOrder";
import { LocationSearchController } from "../location-search/location-search-controller";
import {
  CHECKIN_PARAM,
  CHECKOUT_PARAM,
  COMMISSION_PARAM,
  FILTER_PARAM,
  LocationTypeEnum,
  ORDER_PARAM,
  PAGE_PARAM,
  PRICE_FETCH_RETRIES_LIMIT,
  ROOM_PARAM,
  ROOM_PARAM_SEPARATOR,
} from "./constant";
import { MemoizedMapState, PackageParams, ViewHotelProps } from "./types";

export type HotelSearchService = Pick<
  Service,
  | "queryHotels"
  | "queryNearbyPoisToHotel"
  | "queryLeadingPrices"
  | "searchLocations"
  | "queryLocationInfo"
  | "queryAvailablePackages"
  | "queryCancellationPolicies"
  | "queryHotelImages"
>;

export interface StayDetailsController {
  stayDetails: StayDetails;
  setStayDetails(stayDetails): void;
  updateUrl(): URLSearchParams;
  getStayDetailsFromParams(params: URLSearchParams): StayDetails;
}

export enum HotelSearchTypeEnum {
  Amenity = "amenity",
  StarRatings = "starRatings",
  HotelChain = "hotelChain",
  Regions = "regions",
  PropertyType = "hotelCategories",
  HotelName = "hotelName",
  Prices = "prices",
}

export const HOTEL_STAR_RATING_FILTER_TITLE = "Star Rating";
export const AMENITIES_FILTER_TITLE = "Amenities";
export const HOTEL_CHAIN_TITLE = "Hotel Chain";
export const PROPERTY_TYPE_TITLE = "Property Type";
export const REGION_TITLE = "Region / Area";

export type HotelSearchFilter =
  | { kind: HotelSearchTypeEnum.Amenity; value: string }
  | { kind: HotelSearchTypeEnum.StarRatings; value: StarRating }
  | { kind: HotelSearchTypeEnum.HotelChain; value: string }
  | { kind: HotelSearchTypeEnum.Regions; value: string }
  | { kind: HotelSearchTypeEnum.PropertyType; value: string }
  | { kind: HotelSearchTypeEnum.HotelName; value: string }
  | { kind: HotelSearchTypeEnum.Prices; value: Array<number | null> };

/**
 * Controller for displaying available packages
 */
export interface PackageListingController {
  packages: Loading<PackageInfo[]>;
  resetSearchParams(): void;
  selectedHotel: DbKey<Hotel> | undefined;

  /**
   * Selects the package with the given ID and moves to the booking page
   */
  onSelectPackage(options: PackageParams): void;

  /**
   * Sets the selected hotel for displaying its packages. If the selected hotel is the same as one that's currently
   * selected, the available packages will be cleared.
   */
  setSelectedHotel(hotelId?: DbKey<Hotel>, opts?: ViewHotelProps);

  /**
   * Cancellation policies for the currently selected hotel
   */
  cancellationPoliciesResp: Loading<CancellationPoliciesResp>;
}

/**
 * Pair of the {@link HotelListingResultDetails} with the DB ID of the hotel that it is generated from
 */
export interface HotelListingResultWithId {
  details: HotelListingResultDetails;
  id: DbKey<Hotel>;
}

/**
 * Controller related to listing of hotels mathching the agent's search
 */
export interface HotelListingController {
  hotelListingResultDetails?: Loading<HotelListingResultWithId[]>;
  processHotelSearchFilter(
    filter: HotelSearchFilter,
    operationType: "add" | "delete"
  ): void;
  currentPage: number;
  paginationOffset: number;
  /** the number of all hotels that match the last query */
  totalHotelsAvailable: number;
  setPageNumber(page: number): void;
  noHotelsAreAvailable(): boolean;
  commission: BigDecimal;
  setCommission(val: BigDecimal): void;
  sortOrder: HotelSearchSortOrder;
  changeSortOrder(order: HotelSearchSortOrder): void;
  mapHotelsToResultWithId(
    data: HotelListingDetails[],
    pricesMap: Map<string, LeadingPriceType>
  ): HotelListingResultWithId[];

  /**
   * Initiates a new hotel search using the parameters that are set. Assumes that a location has been picked
   */
  startNewHotelSearch(
    opts?: {
      updateUrl?: boolean;
      mStayDetails?: StayDetails;
      mLocation?: Location | LatLng[];
    },
    resetFilterUrlParams?: boolean
  ): Promise<void>;

  /**
   * Returns the price token that is being used in the current booking workflow
   */
  getCurrentPriceToken(): PriceToken | null;
  setCurrentPriceToken(token: string): void;

  /**
   * Starts a new hotel search from the incoming URL params if possible
   */
  checkIfSearchCanBeStarted(resetFilterUrlParams?: boolean): Promise<void>;

  selectedHotelDetails?: HotelDetails;

  /** Nearby POIs to the currently selected hotel */
  nearbyPoisToHotel?: Loading<PoiNearHotel[]>;

  /** hotel display type on the search page (map / list) */
  hotelView: HotelViewEnum;
  changeView(): void;

  /** Existing search initiating function */
  updateExistingSearch(
    addToList?: boolean,
    offsetValue?: number,
    paginationCount?: number
  ): Promise<void>;
  /** payload of the last sent request for hotel search */
  lastHotelQuery: MutableRefObject<AgentHotelSearchQuery | undefined>;

  /** Default state for the map view */
  defaultMapState: React.MutableRefObject<MemoizedMapState | undefined>;

  /** Function to get additional nearby hotels */
  fetchNearbyHotels(): Promise<HotelListingResultWithId[]>;

  /** @summary Function to query hotels without calling supplier */
  /** @returns Map bounds if there are any changes in the search result and new search should be started, otherwise - undefined */
  searchHotelsFromCache(
    loc: AgentusLocationType,
    options: { offset: number; count: number; updateStore?: boolean }
  ): Promise<Paginated<HotelListingDetails>>;

  /** @summary Function to get prices for cached hotels */
  /** @returns Map object where key is hotel id and value is corresponding price object */
  pollPricesForCachedHotels(
    hotelIds: string[]
  ): Promise<Map<string, LeadingPriceType>>;
  /** Сlearing values after logout */
  logout(): void;

  /** All hotel ids available by the current price token */
  allCachedHotels: CachedHotels;

  /** Indication if the price token error occurred in the last search call */
  priceTokenErrorWasCaught: boolean;

  /**
   * Kicks off a new hotel search for the specified itinerary
   */
  startNewSearchForItinerary(itineraryId: string): void;

  /**
   * Fetches hotel images for a specific hotel
   */
  queryHotelImages(hotelId: string, count: number): Promise<HotelImageResp>;
}

/**
 * Complete controller required for the hotel search page
 */
export type HotelSearchController = LocationSearchController &
  StayDetailsController &
  PackageListingController &
  HotelListingController;

/**
 * Default definition of room config to use for a search
 */
const DEFAULT_ROOM_CONFIG: HotelRoom[] = [
  {
    numAdults: 2,
    children: [],
  },
];

/**
 * Format string for a local date
 */
export const LOCALDATE_FORMAT = "YYYY-MM-DD";

/**
 * The default number of nights to stay when first loading the search page
 */
const DEFAULT_NUMBER_OF_NIGHTS = 2;

export const TOMORROW_OFFSET = 1;

/**
 * The number of milliseconds to delay when polling the backend for leading prices
 */
const POLL_DELAY_MILLIS: number = 2000;
/**
 * Set of empty filters to use when initiating a new search
 */
export const EMPTY_FILTERS: HotelSearchFilters = {
  amenities: [],
  hotelCategories: [],
  hotelChains: [],
  hotelName: "",
  regions: [],
  starRatings: [],
};

const DEFAULT_STAY_DETAILS: StayDetails = {
  checkIn: moment().add(TOMORROW_OFFSET, "days").format(LOCALDATE_FORMAT),
  checkOut: moment()
    .add(DEFAULT_NUMBER_OF_NIGHTS, "days")
    .format(LOCALDATE_FORMAT),
  rooms: DEFAULT_ROOM_CONFIG,
};

interface LocationType {
  scroll?: number;
  existingSearchParams?: {
    addToList?: boolean;
    offset?: number;
  };
}

/**
 * Creates the HotelSearchController to use on the HotelSearchPage
 */
export const useHotelSearchController = (
  service: HotelSearchService,
  notificationController: NotificationController,
  locationSearchController: LocationSearchController,
  defaultCommission: string | null
): HotelSearchController => {
  const { addNotification } = notificationController;
  const location = useLocation<LocationType>();
  const history = useHistory();
  const hotelIdMatch = useRouteMatch<{ hotelId?: string }>(
    "/search/hotel/:hotelId"
  );

  const commission = useRef<BigDecimal>("0");

  // Default user commission is loaded asynchronously
  // Thus we need to track it
  useEffect(() => {
    if (defaultCommission !== null) {
      commission.current = defaultCommission;
    }
  }, [defaultCommission]);

  const [stayDetails, setStayDetails] = useState<StayDetails>(
    cloneDeep(DEFAULT_STAY_DETAILS)
  );

  const { hotelView, changeView } = useHotelView();

  const priceToken = useRef<PriceToken | null>(null);

  const [
    leadingPricesForCurrentPage,
    setLeadingPricesForCurrentPage,
  ] = useState<Map<DbKey<Hotel>, LeadingPriceType>>(new Map());
  const [isPricesLoading, setIsPricesLoading] = useState(false);

  // Flag indicates whether prices data needs to be loaded (used to handle an expired price token)
  const shouldLoadPrices = useRef(false);

  const [totalHotelsAvailable, setTotalHotelsAvailable] = useState<number>(0);

  const {
    currentPage,
    setPageNumber,
    paginationOffset,
    resetPaginationValues,
    setTotalCount,
  } = usePagination();

  useEffect(() => {
    setTotalCount(totalHotelsAvailable);
  }, [totalHotelsAvailable]);

  const {
    setHotelListing,
    hotelListing,
    addToHotelList,
    allCachedHotels,
  } = useHotelListing();

  const { sortOrder, changeSortOrder, getDefaultOrder } = useSortOrder();

  const {
    selectedFilters,
    setSelectedFilters,
    resetFilters,
    filtersAreEmpty,
  } = useSearchFilters();

  const [cancellationPoliciesResp, setCancellationPoliciesResp] = useState<
    Loading<CancellationPoliciesResp>
  >({ kind: "loading" });

  const [hotelListingResultDetails, setHotelListingResultDetails] = useState<
    Loading<HotelListingResultWithId[]> | undefined
  >(undefined);

  const [selectedHotel, setSelectedHotelState] = useState<string | undefined>(
    hotelIdMatch?.params.hotelId
  );

  /**
   * NOTE(Barry): Basically a duplication of the selectedHotel state. However, I need a ref version because the
   * loadPackagesAndCancellation policies function needs to know what hotel is selected in response to a commission
   * change and this doesn't work with state (i.e. you always get an old version of the state)
   */
  const selectedHotelRef = useRef<string | undefined>(undefined);

  const [selectedHotelDetails, setSelectedHotelDetails] = useState<
    HotelDetails | undefined
  >(undefined);

  const [nearbyPoisToHotel, setNearbyPoisToHotel] = useState<
    Loading<PoiNearHotel[]> | undefined
  >(undefined);

  const [packages, setPackages] = useState<Loading<PackageInfo[]>>({
    kind: "loading",
  });

  const defaultMapState = useRef<MemoizedMapState>();

  const resetSearchParams = useCallback(() => {
    setPackages({ kind: "loading" });
    setSelectedHotelState(undefined);
    setSelectedHotelDetails(undefined);
    resetPaginationValues();
  }, []);

  const currentQuery = useRef<AgentHotelSearchQuery>();
  const lastRequestedQuery = useRef<AgentHotelSearchQuery>();

  /**
   * List of hotels that we are currently polling for leading price information. Needs to be stored as a reference
   * because this value is based on the response to a hotel query, but needs to also be referenced when polling needs to
   * start again in response to a commission change
   */
  const hotelsToQueryForLeadingPrices = useRef<DbKey<Hotel>[]>([]);

  const priceTokenErrorWasCaught = useRef(false);

  /**
   * Update the current search if the agent changes the filter for the search, but not the search parameters
   */
  useEffect(() => {
    if (priceToken.current) {
      void updateExistingSearch();
    }
  }, [currentPage, selectedFilters, sortOrder]);

  const shouldClearHotelListing = (): boolean => {
    const query = currentQuery.current;

    if (locationSearchController.selectedLocation === undefined) {
      return true;
    } else if (query !== undefined) {
      if (query.searchParams.kind === "existingSearch") {
        return true;
      } else if (query.searchParams.kind === "newSearch") {
        const params = getHotelSearchParams(
          locationSearchController.selectedLocation,
          stayDetails
        );

        if (
          // Don't discard search results when search was done with bounds
          // it means user just switched to the map view
          query.searchParams.value.location.kind !== "bounds" &&
          !isEqual(params, query.searchParams.value)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Start a completely new search if the agent changes where the customer is going, or who is going
   */
  useEffect(() => {
    if (shouldClearHotelListing()) {
      lastRequestedQuery.current = undefined;
    }
  }, [
    locationSearchController.selectedLocation,
    stayDetails,
    priceToken.current,
  ]);

  /**
   * Update the selected hotel ref
   */
  useEffect(() => {
    selectedHotelRef.current = selectedHotel;
  }, [selectedHotel]);

  /**
   * Load package info if the agent updates which hotel is selected
   */
  useEffect(() => {
    if (
      hotelListing !== undefined &&
      selectedHotel !== undefined &&
      hotelListing.kind === "value" &&
      hotelListing.value.some((hotel) => hotel.hotelId === selectedHotel)
    ) {
      const selectedHotelListingDetails = assertNotUndefined(
        hotelListing.value.find((hotel) => hotel.hotelId === selectedHotel)
      );
      setSelectedHotelDetails({
        address: selectedHotelListingDetails.address,
        emailAddress: null,
        faxNum: null,
        hotelAmenities: selectedHotelListingDetails.amenities,
        hotelCategory: "TODO",
        hotelName: selectedHotelListingDetails.name,
        phoneNumber: selectedHotelListingDetails.phoneNumber,
        policies: null,
        // This value should come from a package, not a hotel
        roomAmenities: [],
        point: selectedHotelListingDetails.point,
      });
      void loadPackages();
      void loadPoisNearHotel(selectedHotel);
    }
  }, [selectedHotel, hotelListing]);

  /** Adds the filter string to the list of filter strings */
  const addFilter = (
    filters: string[],
    filterValue: string | string[]
  ): string[] => {
    const temp = JSON.parse(JSON.stringify(filters));

    if (Array.isArray(filterValue)) {
      return [...temp, ...filterValue];
    } else {
      temp.push(filterValue);
      return temp;
    }
  };

  /** Deletes the filter string from the list of filter strings */
  const deleteFilter = (filters: string[], filterValue: string): string[] => {
    const temp = JSON.parse(JSON.stringify(filters));

    if (Array.isArray(filterValue)) {
      return temp.filter((f) => !filterValue.includes(f));
    } else {
      return temp.filter((f) => f !== filterValue);
    }
  };

  /** Updates the list of hotel search filters with the specified filter and operation type. */
  const processHotelSearchFilter = (
    filter: HotelSearchFilter,
    operation: "add" | "delete"
  ) => {
    const tempFilters: HotelSearchFilters = { ...selectedFilters };
    const processFunction = operation === "add" ? addFilter : deleteFilter;

    if (filter.kind === HotelSearchTypeEnum.StarRatings) {
      const starRatings = generateRatingValues(Number(filter.value));
      // tslint:disable-next-line:ban-ts-ignore
      // @ts-ignore
      tempFilters.starRatings = processFunction(
        // tslint:disable-next-line:ban-ts-ignore
        // @ts-ignore
        tempFilters.starRatings,
        starRatings
      );
    } else if (filter.kind === HotelSearchTypeEnum.Amenity) {
      tempFilters.amenities = processFunction(
        tempFilters.amenities,
        filter.value
      );
    } else if (filter.kind === HotelSearchTypeEnum.PropertyType) {
      tempFilters.hotelCategories = processFunction(
        tempFilters.hotelCategories,
        filter.value
      );
    } else if (filter.kind === HotelSearchTypeEnum.HotelChain) {
      tempFilters.hotelChains = processFunction(
        tempFilters.hotelChains,
        filter.value
      );
    } else if (filter.kind === HotelSearchTypeEnum.Regions) {
      tempFilters.regions = processFunction(tempFilters.regions, filter.value);
    } else if (filter.kind === HotelSearchTypeEnum.HotelName) {
      processFunction([tempFilters.hotelName || ""], filter.value);
      tempFilters.hotelName = filter.value;
    }
    setSelectedFilters(tempFilters);
  };

  /**
   * Just maps the hotel listing in to a shape suitable for a hotel card if a new page of hotels is available or new
   * pricing information is available
   */
  useEffect(() => {
    setHotelListingResultDetails(
      hotelListing &&
        mapLoading(hotelListing, (details: HotelListingDetails[]) => {
          return mapHotelsToResultWithId(details, leadingPricesForCurrentPage);
        })
    );
  }, [hotelListing, leadingPricesForCurrentPage]);

  function mapHotelsToResultWithId(
    hotels: HotelListingDetails[],
    pricesMap: Map<string, LeadingPriceType>
  ): HotelListingResultWithId[] {
    const { locationInfo } = locationSearchController;
    const result: HotelListingResultWithId[] = [];

    for (const hotel of hotels) {
      const priceDetails = getPriceDetailsForHotel(hotel.hotelId, pricesMap);

      const address = hotel.address;
      let hotelCategory = "Uncategorised";
      let hotelAmenities: string[] = [];
      if (locationInfo.kind === "value") {
        if (hotel.hotelCategory !== null) {
          const targetCategory = locationInfo.value.hotelCategories.find(
            (c) => c.id === hotel.hotelCategory
          );
          if (targetCategory) {
            hotelCategory = targetCategory.value.name;
          } else {
            // tslint:disable-next-line:no-console
            console.error(
              `Error parsing hotel results: hotel category ${hotel.hotelCategory} not found in the current location`
            );
          }
        }
        const hotelAmenityIds: DbKey<Amenity>[] = hotel.amenities;
        // Map all the hotel amenity ids to the the amenity names by
        // fetching the intersection of the amenity ids that are a
        // available for this hotel with the complete list of
        // amenities for all the hotels in the location search.
        hotelAmenities = intersectionWith(
          locationInfo.value.amenities,
          hotelAmenityIds,
          (amenityWithId: WithDbId<Amenity>, id: DbKey<Amenity>) =>
            id === amenityWithId.id
        ).map((amenityWithId: WithDbId<Amenity>) => amenityWithId.value.name);
      }
      result.push({
        id: hotel.hotelId,
        details: {
          hotelName: hotel.name,
          hotelAddress: renderAddress(address),
          hotelStarRating: hotel.starRating,
          images: hotel.images,
          hotelCategory,
          hotelDescription: hotel.description || undefined,
          hotelCoverImageURL: hotel.images.find((image) => image.isHeroImage)
            ?.url,
          hotelAmenities,
          priceDetails,
          coordinates: hotel.point,
          isPromotion: false, // TODO(Barry): Get promotions from IDS or Supplier?
          tripAdvisorRating: undefined, // TODO add value
          nearestAirports: hotel.nearestAirports,
        },
      });
    }

    return result;
  }

  function setNewHotelListingValue(
    paginatedHotels: Paginated<HotelListingDetails>,
    updateTotalHotels?: boolean
  ) {
    const hotelList = paginatedHotels.items;
    hotelsToQueryForLeadingPrices.current = hotelList.map((h) => h.hotelId);
    initializeLeadingPriceMap();

    setHotelListing({ kind: "value", value: hotelList });
    if (updateTotalHotels) {
      setTotalHotelsAvailable(paginatedHotels.total_size);
    }

    void pollLeadingPrices(assertNotNull(priceToken.current));
  }

  async function handleNewSearchResp(resp: AgentHotelSearchResp) {
    if (resp.kind === "noHotelsAvailable") {
      setNoHotelsAvailable();
    } else if (resp.kind === "availableHotels") {
      priceToken.current = resp.value.priceToken;
      // Checking if any search filter is selected,
      // if so, we need to fetch exiting search api to apply them
      if (filtersAreEmpty) {
        setNewHotelListingValue(resp.value.hotels, true);
      } else {
        await updateExistingSearch();
      }
    } else if (resp.kind === "invalidPriceToken") {
      priceTokenErrorWasCaught.current = true;
      // NOTE: not sure that we can get invalidPriceToken error here,
      // as a request for a new search just returns a new price token
      checkIfSearchCanBeStarted().then(() => {
        addInvalidPriceTokenNotification();
      });
    } else {
      assertNever(resp);
    }
  }

  // Once user switches to map view, we load all cached hotels for map optimization
  useEffect(() => {
    if (hotelView === HotelViewEnum.MapView) {
      getAllHotelsFromCache();
    }
  }, [hotelView, priceToken.current]);

  function getAllHotelsFromCache() {
    const token = priceToken.current;

    if (
      !token ||
      token === allCachedHotels.current.priceToken ||
      !currentQuery.current
    ) {
      return;
    }

    const getAllHotelsQuery: AgentHotelSearchQuery = {
      searchParams: {
        kind: "existingSearch",
        value: {
          priceToken: token,
          filters: selectedFilters,
        },
      },
      count: 10000,
      offset: 0,
      order: (currentQuery.current as AgentHotelSearchQuery).order,
    };

    // Fetching all hotels by current price token
    // in optimisation purpose for the map view
    service.queryHotels(getAllHotelsQuery).then((res) => {
      if (res.kind === "availableHotels") {
        const idSet = new Set<string>(
          res.value.hotels.items.map(({ hotelId }) => hotelId)
        );

        allCachedHotels.current = {
          hotels: idSet,
          priceToken: token,
        };
      }
    });
  }

  function setNoHotelsAvailable() {
    setHotelListing({ kind: "value", value: [] });
    setHotelListingResultDetails({ kind: "value", value: [] });
    setTotalHotelsAvailable(0);
  }

  const doQueryHotels = async () => {
    // Discard map view saved state when searching something in the list view
    defaultMapState.current = undefined;

    const nextQuery = cloneDeep(assertNotUndefined(currentQuery.current));
    lastRequestedQuery.current = nextQuery;
    try {
      const [resp] = await Promise.all([
        service.queryHotels(nextQuery),
        nextQuery.searchParams.kind === "newSearch"
          ? locationSearchController.fetchLocationInfo(
              nextQuery.searchParams.value.location
            )
          : undefined,
      ]);

      priceTokenErrorWasCaught.current = false;

      if (nextQuery.searchParams.kind === "newSearch") {
        handleNewSearchResp(resp);
      } else if (nextQuery.searchParams.kind === "existingSearch") {
        handleExistingSearchResp(resp);
      }
    } catch (e) {
      setHotelListing({ kind: "error", error: e });
      notificationController.addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to get hotels data",
      });
    }
  };

  const setSelectedHotel = (hotelId: DbKey<Hotel>, opts?: ViewHotelProps) => {
    if (opts?.inNewTab) {
      const searchParams = updateUrl();
      searchParams.set(HOTEL_PARAM, hotelId);
      searchParams.set(PRICE_TOKEN_PARAM, priceToken.current as string);

      if (opts?.showOnMap) {
        searchParams.set(SHOW_ON_MAP_PARAM, "true");
      }

      window.open(`${ROUTE_PATH.Hotel}?${searchParams.toString()}`);
    } else {
      setSelectedHotelState(hotelId);
    }
  };

  function pollLeadingPrices(tokenToPoll: PriceToken) {
    let retries = 1;

    async function poll() {
      if (retries > PRICE_FETCH_RETRIES_LIMIT) {
        sendErrorToRollbar(new Error("Missing leading prices for hotel"), {
          tokenToPoll,
          selectedHotel,
        });

        setLeadingPricesForCurrentPage(
          (prevValue) =>
            new Map(
              Array.from(prevValue.entries()).map(([key, value]) =>
                value.kind === "pending"
                  ? [key, { kind: "unavailable", value: null }]
                  : [key, value]
              )
            )
        );
        setIsPricesLoading(false);
        return;
      }
      if (commission.current) {
        setIsPricesLoading(true);
        try {
          const leadingPrices = await service.queryLeadingPrices({
            hotelsToQuery: hotelsToQueryForLeadingPrices.current,
            priceToken: tokenToPoll,
            commissionPercentage: assertNotUndefined(commission.current),
          });
          retries++;

          // Make sure we're working with the same price token
          if (tokenToPoll !== priceToken.current) {
            return;
          }
          const leadingPriceMap = getLeadingPriceMap(leadingPrices);
          setLeadingPricesForCurrentPage(leadingPriceMap);

          if (leadingPricesNeedToBeQueriedAgain(leadingPriceMap)) {
            await sleep(POLL_DELAY_MILLIS);
            return await poll();
          } else {
            setIsPricesLoading(false);
          }
        } catch (e) {
          notificationController.addNotification({
            type: NotificationTypeEnum.Error,
            text:
              "Unexpected error occurred: unable to get leading prices, please start a new search",
          });
        }
      }
    }
    return poll();
  }

  async function pollPricesForCachedHotels(
    hotelIds: string[]
  ): Promise<Map<string, LeadingPriceType>> {
    try {
      const leadingPrices = await service.queryLeadingPrices({
        hotelsToQuery: hotelIds,
        priceToken: assertNotNull(priceToken.current),
        commissionPercentage: assertNotUndefined(commission.current),
      });

      const leadingPriceMap = getLeadingPriceMap(leadingPrices);

      if (leadingPricesNeedToBeQueriedAgain(leadingPriceMap)) {
        await sleep(POLL_DELAY_MILLIS);
        return pollPricesForCachedHotels(hotelIds);
      }

      return leadingPriceMap;
    } catch (e) {
      notificationController.addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to get leading prices",
      });

      return new Map();
    }
  }

  function getLeadingPriceMap(
    leadingPrices: LeadingPriceQueryResp
  ): Map<DbKey<Hotel>, LeadingPriceType> {
    const leadingPriceMap: Map<DbKey<Hotel>, LeadingPriceType> = new Map();
    for (const price of leadingPrices.prices) {
      if (price.leadingPrice === null) {
        // TODO(Barry): Why would we query a hotel ID that was not part of the original search? If this becomes a
        // problem let's look in to it then
        continue;
      }
      leadingPriceMap.set(price.hotelId, price.leadingPrice);
    }
    return leadingPriceMap;
  }
  function leadingPricesNeedToBeQueriedAgain(
    leadingPriceMap: Map<DbKey<Hotel>, LeadingPriceType>
  ): boolean {
    const prices = Array.from(leadingPriceMap.values());
    for (const price of prices) {
      if (price && (price.kind === "pending" || price.kind === "partial")) {
        return true;
      }
    }

    return false;
  }

  function addInvalidPriceTokenNotification() {
    addNotification({
      text: UPDATED_HOTELS_DATA_MSG,
      type: NotificationTypeEnum.Information,
    });
  }

  function addExpiredSearchNotification() {
    addNotification({
      text: OUTDATED_HOTELS_DATA_MSG,
      type: NotificationTypeEnum.Information,
    });
  }

  function handleExistingSearchInvalidPriceToken() {
    // the response from a newSearch request doesn't need to be added to the existing hotels list
    addToHotelList.current = false;

    // start NewSearch again to get a new price token
    checkIfSearchCanBeStarted().then(() => {
      const addToList =
        location.state?.existingSearchParams?.addToList || false;

      // Map view uses infinite scroll.
      // Therefore, in order to save the state that was before receiving InvalidPriceToken error,
      // it is necessary to recalculate the pagination values
      if (hotelView === HotelViewEnum.MapView) {
        // search from the second page for all remaining hotels
        // count: 25   -->  count: 75
        // offset: 75       offset: 25
        const currentOffset =
          location.state?.existingSearchParams?.offset || paginationOffset;
        const paginationCount =
          currentOffset > ITEMS_PER_PAGE ? currentOffset : ITEMS_PER_PAGE;
        const newOffset =
          paginationCount > ITEMS_PER_PAGE ? ITEMS_PER_PAGE : currentOffset;

        // a new search for the number of ITEMS_PER_PAGE hotels has already been completed
        // need to send ExistingSearch to the remaining hotels
        updateExistingSearch(addToList, newOffset, paginationCount).then(() => {
          addInvalidPriceTokenNotification();
        });
      } else {
        location.pathname === ROUTE_PATH.Search
          ? addInvalidPriceTokenNotification()
          : addExpiredSearchNotification();
      }
    });
  }

  function handleExistingSearchResp(nextPage: AgentHotelSearchResp) {
    if (nextPage.kind === "availableHotels") {
      setNewHotelListingValue(nextPage.value.hotels, true);

      if (location.state?.existingSearchParams) {
        location.state.existingSearchParams = {};
      }
    } else if (nextPage.kind === "noHotelsAvailable") {
      setHotelListing({
        kind: "error",
        error: "Got no hotels available when trying to load next page",
      });
    } else if (nextPage.kind === "invalidPriceToken") {
      priceTokenErrorWasCaught.current = true;
      handleExistingSearchInvalidPriceToken();
    }
  }

  const loadPoisNearHotel = (hotelId: DbKey<Hotel>) => {
    fromPromise(
      service.queryNearbyPoisToHotel(hotelId),
      setNearbyPoisToHotel,
      notificationController.addNotification
    );
  };

  function initializeLeadingPriceMap() {
    const leadingPriceMap: Map<DbKey<Hotel>, LeadingPriceType> = new Map();
    for (const hotelId of hotelsToQueryForLeadingPrices.current) {
      leadingPriceMap.set(hotelId, { kind: "pending" });
    }
    setLeadingPricesForCurrentPage(leadingPriceMap);
  }
  const guardedHotelQuery: GuardedPromise = new GuardedPromise(doQueryHotels);

  function applyDefaultScroll() {
    document.documentElement.scroll(0, location.state?.scroll || 0);
  }
  function saveDefaultScrollValue() {
    location.state = { scroll: document.documentElement.scrollTop };
  }

  const loadPackages = async (isSecondRequest: boolean = false) => {
    setPackages({ kind: "loading" });
    setCancellationPoliciesResp({ kind: "loading" });
    if (!isSecondRequest) {
      saveDefaultScrollValue();
    }

    try {
      const resp = await service.queryAvailablePackages({
        priceToken: assertNotNull(priceToken.current),
        hotelId: assertNotUndefined(selectedHotelRef.current),
        commissionPercentage: assertNotUndefined(commission.current),
      });

      if (resp.kind === "packages") {
        // After we load packages and cancellation policies,
        // some packages may not have the simple cancellation policy (policy.kind === "cancellationNotAvailable").
        // This fulfills the package policies where it's missing
        const cancellationPolicies = await service.queryCancellationPolicies({
          hotelId: assertNotUndefined(selectedHotelRef.current),
          priceToken: assertNotNull(priceToken.current),
          commissionPercentage: assertNotUndefined(commission.current),
        });

        const packagesWithoutMissingPolicies = resp.value.map((value) => {
          if (
            value.cancellationPolicy.kind === "cancellationNotAvailable" &&
            cancellationPolicies.kind === "packageCancellationPolicies"
          ) {
            const targetPolicy = getPolicyForPackage(
              value.packageId,
              cancellationPolicies
            );

            return {
              ...value,
              cancellationPolicy: targetPolicy,
            };
          } else {
            return value;
          }
        });

        setCancellationPoliciesResp({
          kind: "value",
          value: cancellationPolicies,
        });
        setPackages({
          kind: "value",
          value: packagesWithoutMissingPolicies,
        });

        if (isSecondRequest) {
          applyDefaultScroll();
        }
      } else if (resp.kind === "invalidHotelId") {
        setPackages({ kind: "error", error: "Invalid hotel" });
      } else if (resp.kind === "invalidPriceToken") {
        priceTokenErrorWasCaught.current = true;

        checkIfSearchCanBeStarted().then(() => {
          if (isPricesLoading) {
            // load Packages again with a new priceToken
            loadPackages(true).then(() => {
              addInvalidPriceTokenNotification();
            });
          } else {
            shouldLoadPrices.current = true;
          }
        });
      }
    } catch (e) {
      addNotification({
        text: "Packages loading error",
        type: NotificationTypeEnum.Error,
      });
    }
  };

  useEffect(() => {
    if (!isPricesLoading && shouldLoadPrices.current) {
      loadPackages(true).then(() => {
        shouldLoadPrices.current = false;
        addInvalidPriceTokenNotification();
      });
    }
  }, [isPricesLoading, shouldLoadPrices.current]);

  function getPolicyForPackage(
    packageId: string,
    policies: CancellationPoliciesResp_PackageCancellationPolicies
  ): SimpleCancellationPolicy_FreeCancellationUntil {
    const packagePolicies = policies.value.filter(
      (p) => p.packageId === packageId
    );

    const targetDate = calculatePaymentDue(
      getSupplierPaymentDue(packagePolicies[0].policy).format(LOCALDATE_FORMAT)
    );

    return {
      kind: "freeCancellationUntil",
      value: targetDate,
    };
  }

  function getHotelSearchParams(
    loc: Location | LatLng[],
    sd: StayDetails
  ): HotelSearchParameters {
    if (Array.isArray(loc)) {
      return {
        location: { kind: "bounds", value: [loc] },
        stayDetails: sd,
      };
    }

    return {
      location: loc.locationType,
      stayDetails: sd,
    };
  }

  const startNewHotelSearch = async (
    opts?: {
      updateUrl?: boolean;
      mStayDetails?: StayDetails;
      mLocation?: Location | LatLng[];
    },
    resetFilterUrlParams = false
  ) => {
    let sd: StayDetails = stayDetails;
    let loc: Location | LatLng[] | undefined =
      locationSearchController.selectedLocation;
    const params = new URLSearchParams(location.search);

    const defaultOrder = getDefaultOrder(
      loc?.locationType.kind as LocationTypeEnum
    );
    // defaultOrder can be "0" (HotelSearchSortOrder.popularity = 0)
    const order =
      defaultOrder !== undefined && defaultOrder !== null
        ? defaultOrder
        : sortOrder;

    if (opts) {
      if (opts.updateUrl) {
        if (resetFilterUrlParams) {
          resetUrlParams(params);
        }

        updateUrl();
      }

      sd = opts.mStayDetails || sd;
      loc = opts.mLocation || loc;
    } else if (resetFilterUrlParams) {
      resetUrlParams(params);
      location.search = params.toString();
      history.replace(location);
    }

    currentQuery.current = {
      count: ITEMS_PER_PAGE,
      offset: paginationOffset,
      order,
      searchParams: {
        kind: "newSearch",
        value: getHotelSearchParams(assertNotUndefined(loc), sd),
      },
    };

    // Showing loader only for non-boundary locations
    if (!Array.isArray(loc)) {
      setHotelListing({ kind: "loading" });
    }

    return guardedHotelQuery.requestAction() as Promise<void>;
  };

  async function searchHotelsFromCache(
    loc: AgentusLocationType,
    {
      count,
      offset,
      updateStore,
    }: { offset: number; count: number; updateStore?: boolean }
  ) {
    const query: AgentHotelSearchQuery = {
      ...currentQuery.current,
      count,
      offset,
      order: HotelSearchSortOrder.popularity,
      searchParams: {
        kind: "hotelQuery",
        value: {
          location: loc,
          filters: filtersAreEmpty ? null : selectedFilters,
        },
      },
    };

    const resp: AgentHotelSearchResp = await service.queryHotels(query);

    if (resp.kind === "availableHotels") {
      if (updateStore) {
        setNewHotelListingValue(resp.value.hotels);
      }
      return resp.value.hotels;
    } else {
      return {
        items: [],
        total_size: 0,
        current_offset: 0,
      };
    }
  }

  async function fetchNearbyHotels(): Promise<HotelListingResultWithId[]> {
    try {
      const response = await service.queryHotels({
        searchParams: {
          kind: "newSearch",
          value: getHotelSearchParams(
            assertNotUndefined(locationSearchController.selectedLocation),
            stayDetails
          ),
        },
        count: ITEMS_PER_PAGE,
        offset: 0,
        order: HotelSearchSortOrder.distance,
      });

      if (response.kind === "availableHotels") {
        return mapHotelsToResultWithId(
          response.value.hotels.items,
          leadingPricesForCurrentPage
        );
      } else {
        return [];
      }
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unable to get nearby hotels, please contact support",
      });

      return [];
    }
  }

  function updateUrl() {
    const loc: Location | undefined = locationSearchController.selectedLocation;
    const params = new URLSearchParams(location.search);

    const defaultOrder = getDefaultOrder(
      loc?.locationType.kind as LocationTypeEnum
    );
    // defaultOrder can be "0" (HotelSearchSortOrder.popularity = 0)
    const order =
      defaultOrder !== undefined && defaultOrder !== null
        ? defaultOrder
        : sortOrder;

    Array.from(
      locationSearchController.getLocationParams().entries()
    ).forEach((entry) => params.set(entry[0], entry[1]));
    params.set(CHECKIN_PARAM, stayDetails.checkIn);
    params.set(CHECKOUT_PARAM, stayDetails.checkOut);
    params.set(COMMISSION_PARAM, assertNotUndefined(commission.current));
    params.set(ORDER_PARAM, order.toString());
    params.delete(ROOM_PARAM);
    getRoomSearchParam().forEach((roomString) =>
      params.append(ROOM_PARAM, roomString)
    );
    location.search = params.toString();
    history.replace(location);

    return params;
  }

  function getRoomSearchParam(): string[] {
    return stayDetails.rooms.map((room, idx) => {
      const roomString: string[] = [`room-${idx + 1}`, `${room.numAdults}`];
      room.children.forEach((child, childIdx) =>
        roomString.push(`child-${childIdx}-${child.age}`)
      );
      return roomString.join(ROOM_PARAM_SEPARATOR);
    });
  }

  const onSelectPackage = ({
    roomId,
    hotelId,
    bed,
    board,
    cancellation,
    packageId,
    selectedFrom,
  }: PackageParams) => {
    const searchParams = updateUrl();
    if (roomId) {
      searchParams.set(ROOM_ID_PARAM, roomId);
    }

    const userPath =
      selectedFrom === BreadCrumbType.Details
        ? [
            BreadCrumbType.Search,
            BreadCrumbType.Results,
            BreadCrumbType.Details,
            BreadCrumbType.Confirmation,
          ]
        : [
            BreadCrumbType.Search,
            BreadCrumbType.Results,
            BreadCrumbType.Confirmation,
          ];

    searchParams.set(USER_PATH_PARAM, JSON.stringify(userPath));

    searchParams.set(BOARD_PARAM, board.toString());
    searchParams.set(BED_PARAM, bed);
    searchParams.set(HOTEL_PARAM, hotelId);
    searchParams.set(PRICE_TOKEN_PARAM, priceToken.current as string);
    searchParams.set(CANCELLATION_PARAM, JSON.stringify(cancellation));
    searchParams.set(PACKAGE_ID_PARAM, packageId);

    window.open(`${ROUTE_PATH.CompleteBooking}?${searchParams.toString()}`);
  };

  async function updateExistingSearch(
    addToList = false,
    offsetValue?: number,
    paginationCount = ITEMS_PER_PAGE
  ) {
    const offset = offsetValue || paginationOffset;
    addToHotelList.current = addToList;

    if (hotelView === HotelViewEnum.MapView) {
      // save ExistingSearch params to get it while handling invalidPriceToken
      location.state = {
        ...location.state,
        existingSearchParams: { addToList, offset },
      };
    }

    currentQuery.current = {
      offset,
      order: sortOrder,
      count: paginationCount,
      searchParams: {
        kind: "existingSearch",
        value: {
          priceToken: assertNotNull(priceToken.current),
          filters: selectedFilters,
        },
      },
    };
    await guardedHotelQuery.requestAction();
  }

  function getPriceDetailsForHotel(
    hotel: DbKey<Hotel>,
    map: Map<string, LeadingPriceType>
  ): Loading<HotelPriceDetails> {
    const hotelPrice = map.get(hotel);
    if (hotelPrice === undefined || hotelPrice.kind === "unavailable") {
      return { kind: "error", error: "No price found" };
    } else if (hotelPrice.kind === "pending" || hotelPrice.kind === "partial") {
      return { kind: "loading" };
    } else if (hotelPrice.kind === "complete") {
      const totalPrice = parseFloat(hotelPrice.value.price.value);
      const currency = hotelPrice.value.price.currency;

      return {
        kind: "value",
        value: {
          kind: "available",
          currency,
          totalPrice,
          lowestAverageNightlyRate:
            Math.round((totalPrice / daysStaying(stayDetails)) * 100) / 100,
        },
      };
    } else {
      assertNever(hotelPrice);
    }
  }

  const noHotelsAreAvailable = (): boolean => {
    return (
      hotelListing !== undefined &&
      hotelListing.kind === "value" &&
      hotelListing.value.length === 0 &&
      currentPage === 1 &&
      totalHotelsAvailable === 0
    );
  };

  const getCurrentPriceToken = () => priceToken.current;
  const setCurrentPriceToken = (token: string) => (priceToken.current = token);
  const resetCurrentPriceToken = () => {
    priceToken.current = null;
  };

  function getRoomDetailsFromParams(
    roomParams: string[]
  ): HotelRoom[] | undefined {
    if (roomParams.length === 0) {
      return undefined;
    }

    // Sorting should be safe because the first part of the room param is "room-<n>"
    roomParams.sort();
    return roomParams.map<HotelRoom>((roomString) => {
      const params = roomString.split(ROOM_PARAM_SEPARATOR);
      // We should have at least two params, the room identifier and the number of adults
      if (params.length < 2) {
        return {
          numAdults: 1,
          children: [],
        };
      }

      const children: Child[] = params.slice(2).map<Child>((childString) => {
        const childParams = childString.split("-");
        return childParams.length < 3
          ? { age: 10 }
          : { age: parseInt(childParams[2], 10) };
      });
      const hotelRoom: HotelRoom = {
        numAdults: parseInt(params[1], 10),
        children,
      };
      return hotelRoom;
    });
  }

  function getStayDetailsFromParams(
    incomingParams: URLSearchParams
  ): StayDetails {
    return {
      checkIn:
        incomingParams.get(CHECKIN_PARAM) ||
        moment().add(TOMORROW_OFFSET, "days").format(LOCALDATE_FORMAT),
      checkOut:
        incomingParams.get(CHECKOUT_PARAM) ||
        moment().add(DEFAULT_NUMBER_OF_NIGHTS, "days").format(LOCALDATE_FORMAT),
      rooms:
        getRoomDetailsFromParams(incomingParams.getAll(ROOM_PARAM)) ||
        DEFAULT_ROOM_CONFIG,
    };
  }

  const checkIfSearchCanBeStarted = async (resetFilterUrlParams = false) => {
    const incomingParams = new URLSearchParams(location.search);
    const stayDetailsFromUrl = getStayDetailsFromParams(incomingParams);

    setStayDetails(stayDetailsFromUrl);

    const selectedLocation = locationSearchController.setLocationFromParams(
      incomingParams
    );

    const incomingCommission = incomingParams.get(COMMISSION_PARAM);
    if (incomingCommission !== null) {
      commission.current = incomingCommission;
    }
    addToHotelList.current = false;

    // Check whether we have enough information from the URL to kick off a search
    if (selectedLocation !== undefined && incomingCommission !== null) {
      return startNewHotelSearch(
        { mLocation: selectedLocation, mStayDetails: stayDetailsFromUrl },
        resetFilterUrlParams
      );
    }

    return;
  };

  const setCommission = (val: string) => {
    if (val.length === 0) {
      commission.current = "0";
    } else {
      const floatVal = parseFloat(val);
      commission.current = floatVal.toString(10) || "0";
    }

    const params = new URLSearchParams(location.search);
    params.set(COMMISSION_PARAM, assertNotUndefined(commission.current));
    const locationUrl = params.get("location");

    if (locationUrl) {
      location.search = params.toString();
      history.replace(location);
    }

    if (hotelsToQueryForLeadingPrices.current.length > 0) {
      initializeLeadingPriceMap();
      void pollLeadingPrices(assertNotNull(priceToken.current));
      if (selectedHotelRef.current) {
        void loadPackages();
      }
    }
  };

  const queryHotelImages = async (hotelId: string, count: number) => {
    try {
      return await service.queryHotelImages({ hotelId, count });
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(
        `Unable to query hotel images for hotelId ${hotelId}, error: ${e.message}`
      );

      return { galleryImages: [] };
    }
  };

  // clean up all info from state values
  const reset = () => {
    setStayDetails(cloneDeep(DEFAULT_STAY_DETAILS));
    setLeadingPricesForCurrentPage(new Map());
    setIsPricesLoading(false);
    setTotalHotelsAvailable(0);
    setCancellationPoliciesResp({ kind: "loading" });
    setHotelListingResultDetails(undefined);
    setSelectedHotelState(hotelIdMatch?.params.hotelId);
    setSelectedHotelDetails(undefined);
    setNearbyPoisToHotel(undefined);
    setPackages({ kind: "loading" });

    setHotelListing(undefined);
    resetFilters();
    resetCurrentPriceToken();

    commission.current = "0";
    priceToken.current = null;
    shouldLoadPrices.current = false;
    selectedHotelRef.current = undefined;
    defaultMapState.current = undefined;
    currentQuery.current = undefined;
    lastRequestedQuery.current = undefined;
    hotelsToQueryForLeadingPrices.current = [];
    priceTokenErrorWasCaught.current = false;
  };

  const logout = () => {
    reset();
  };

  function resetUrlParams(params: URLSearchParams) {
    params.delete(FILTER_PARAM);
    params.delete(ORDER_PARAM);
    params.delete(PAGE_PARAM);
    resetPaginationValues();
  }

  function startNewSearchForItinerary(itineraryId: string) {
    reset();
    locationSearchController.logout();
    history.push(`/search?${ITINERARY_NUMBER_PARAM}=${itineraryId}`);
  }

  useEffect(() => {
    return () => {
      location.state = {};
    };
  }, []);

  return {
    ...locationSearchController,
    stayDetails,
    commission: commission.current,
    setCommission,
    setStayDetails,
    packages,
    resetSearchParams,
    selectedHotel,
    setSelectedHotel,
    selectedHotelDetails,
    hotelListingResultDetails,
    currentPage,
    totalHotelsAvailable,
    paginationOffset,
    setPageNumber,
    noHotelsAreAvailable,
    startNewHotelSearch,
    getCurrentPriceToken,
    setCurrentPriceToken,
    onSelectPackage,
    checkIfSearchCanBeStarted,
    cancellationPoliciesResp,
    processHotelSearchFilter,
    nearbyPoisToHotel,
    sortOrder,
    changeSortOrder,
    hotelView,
    changeView,
    updateExistingSearch,
    lastHotelQuery: lastRequestedQuery,
    updateUrl,
    getStayDetailsFromParams,
    defaultMapState,
    fetchNearbyHotels,
    searchHotelsFromCache,
    logout,
    mapHotelsToResultWithId,
    allCachedHotels: allCachedHotels.current,
    pollPricesForCachedHotels,
    priceTokenErrorWasCaught: priceTokenErrorWasCaught.current,
    startNewSearchForItinerary,
    queryHotelImages,
  };
};
