import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import { BaseProps } from "@common/base";
import layout from "@common/layout-lib.css";

import styles from "./styles.css";

export interface TextareaProps
  extends BaseProps,
    React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  required?: boolean;
  error?: string;
  label?: string;
  labelClassname?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      label,
      value,
      placeholder,
      onChange,
      required = false,
      error,
      onClick,
      labelClassname,
      ...props
    }: TextareaProps,
    ref: React.RefObject<HTMLTextAreaElement>
  ) => {
    /** State to manage the value of the input field */
    const [valueState, setValueState] = useState(value || "");
    const textareaRef =
      ref || useRef<React.RefObject<HTMLTextAreaElement> | null>(null);

    /** Update the value if explicitly passed as a prop  */
    useEffect(() => {
      setValueState(value || "");
    }, [value]);

    const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValueState(event.target.value);
      // If a change handler has been passed in then throw the event
      if (onChange) {
        onChange(event);
      }
    };

    // This handler sets the focus to the input within the component
    const clickHandler = (event) => {
      textareaRef.current?.focus();

      if (onClick) {
        onClick(event);
      }
    };

    // Aggregation of class names for root element
    const classes = clsx(
      className,
      styles.textareaInput,
      valueState?.toString() && styles.hasValue,
      props.disabled && styles.disabled,
      props.readOnly && styles.readOnly,
      error && styles.error
    );

    return (
      <label className={clsx(layout.vertical, className)}>
        {label && (
          <span className={clsx(styles.label, labelClassname)}>
            {`${label}${required ? "*" : ""}`}
          </span>
        )}
        <div className={classes} style={props.style} onClick={clickHandler}>
          <textarea
            ref={textareaRef}
            // if inputComponentRef === "function" then the input is used with the useForms and the value will be filled automatically
            {...(typeof textareaRef === "function"
              ? {}
              : { value: valueState })}
            onChange={changeHandler}
            placeholder={placeholder}
            {...props}
          />
          {error && <span className={styles.errorText}>{error}</span>}
        </div>
      </label>
    );
  }
);

export default Textarea;
