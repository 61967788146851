import clsx from "clsx";
import React, { ChangeEvent, FC, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { LoginReq } from "@adl-gen/hotel/api";
import { ROUTE_PATH } from "@constants/common";
import { IdentityController } from "@controllers/identity/identity-controller";
import { Button } from "@widgets/button/button";
import { InputText } from "@widgets/input-text/input-text";
import { Logo } from "@widgets/logo/logo";

import styles from "./login-page.css";

export interface LoginPageProps {
  identityState: IdentityController;
}

const ERROR_MSG_DISPLAY_TIME = 3000;

const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

/**
 * Login page of the app.
 */

export const LoginPage: FC<LoginPageProps> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const onClickHandler = async () => {
    const request: LoginReq = {
      email,
      password,
    };

    const response = await props.identityState.login(request);

    if (response.kind === "logged-in") {
      const params = new URLSearchParams(location.search);

      // If the login was from a redirect
      if (params.has("referrer")) {
        location.pathname = params.get("referrer") || "";
        params.delete("referrer");
        location.search = params.toString();
      } else {
        location.pathname = ROUTE_PATH.Dashboard;
      }

      history.push(location);
    } else {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, ERROR_MSG_DISPLAY_TIME);
    }
  };

  const onEmailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  const onPasswordChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const isValid = () => {
    return emailRegex.test(email) && password !== "";
  };

  return (
    <div className={styles.login}>
      <div className={styles.loginContainer}>
        <Logo />
        <InputText
          className={styles.inputText}
          value={email}
          placeholder="Email"
          onChange={onEmailChangeHandler}
        />
        <InputText
          className={styles.inputText}
          type={"password"}
          value={password}
          placeholder="Password"
          onChange={onPasswordChangeHandler}
        />
        <Link to={ROUTE_PATH.PasswordRecovery}>Forgotten password?</Link>
        <p className={clsx(styles.message, error && styles.show)}>
          Unable to login with the supplied email and password.
        </p>
        <Button
          className={styles.button}
          onClick={onClickHandler}
          disabled={!isValid()}
        >
          Login
        </Button>
      </div>
    </div>
  );
};
