import classnames from "classnames";
import React from "react";

import { ShadowInput, useShadowInput } from "../../common/hooks/shadow-input";
import { Button } from "../button/button";
import { InputText, InputTextProps } from "../input-text/input-text";

import styles from "./input-stepper.css";

/**
 * Properties for InputStepper component. Extends the BaseProps and the intrinsic
 * properties for an HTML Input.
 */
export interface InputStepperProps extends InputTextProps {
  /** Optional additional classes to apply to internal elements */
  classes?: { inputStepper?: string } & InputTextProps["classes"];
  /** Optional minimum value to allow in input */
  min?: number;
  /** Optional maximum value to allow in input */
  max?: number;
  /** Optional increment/decrement step */
  step?: number;
  /** Optional allow editing of input text directly */
  editable?: boolean;
}

/**
 * Numeric Stepper Input Component intended to be a replacement for the
 * `input type="number" step=""` element.
 *
 * @author James Millar
 */
export const InputStepper = ({
  className,
  classes = {},
  min = -Infinity,
  max = Infinity,
  step = 1,
  editable = true,
  value = 0,
  onChange,
  ...props
}: InputStepperProps): JSX.Element => {
  // State of the input value
  const [setValue, shadow] = useShadowInput(
    props.name,
    value.toString(),
    onChange
  );

  // Handle a click on the decrement button
  const minusClickHandler = () => {
    // const nextValue = Math.max(min, Number(value) - step).toString();
    setValue(Math.max(min, Number(value) - step).toString());
  };

  // Handle a click on the increment button
  const plusClickHandler = () => {
    setValue(Math.min(max, Number(value) + step).toString());
  };

  // Handle changes to the input field
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set the value state to the entered value
    setValue(event.currentTarget.value);
  };

  const blurHandler = (event) => {
    // Parse a value from the input field value
    const _value = Number.parseFloat(event.currentTarget.value);

    // If we do not have a number
    if (isNaN(_value)) {
      // Set the value state to zero
      setValue(0);
    }
    // If we do have a value
    else {
      // Set the value state to value constrained by the min/max
      setValue(Math.max(min, Math.min(max, _value)));
    }
  };

  const inputStepperClasses = classnames(
    styles.inputStepper,
    classes.inputStepper,
    className
  );

  const _classes = { ...classes } || {};

  _classes.inputText = classnames(styles.inputText, classes.inputText);
  _classes.input = classnames(styles.input, classes.input);
  // console.log("classes.input", _classes.input);

  return (
    <div className={inputStepperClasses}>
      <ShadowInput {...shadow} />
      <Button
        className={styles.button}
        emphasis={"medium"}
        tabIndex={0}
        disabled={props.disabled || props.readOnly || Number(value) === min}
        onClick={minusClickHandler}
        name={`${props.name}-decrease`}
      >
        -
      </Button>
      <InputText
        {...props}
        classes={_classes}
        value={value?.toString()}
        onChange={changeHandler}
        onBlur={blurHandler}
        readOnly={!editable}
        disabled={props.disabled}
      />
      <Button
        className={styles.button}
        emphasis={"medium"}
        tabIndex={0}
        disabled={props.disabled || props.readOnly || Number(value) === max}
        onClick={plusClickHandler}
        name={`${props.name}-increase`}
      >
        +
      </Button>
    </div>
  );
};
