import clsx from "classnames";
import React, { useContext } from "react";

import { PaymentDetails } from "@adl-gen/hotel/api";
import { PaymentMode } from "@adl-gen/hotel/booking";
import { BookingContext, LoggedInContext } from "@app/app";
import { BaseProps } from "@common/base";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { Loading } from "@models/loading";
import { renderPriceString } from "@util/agentus-utis";

import styles from "./price-summary.css";

export interface PriceSummaryProps extends BaseProps {
  packageName: string;
  paymentDetails: Loading<PaymentDetails>;
}

export const PriceSummary = ({
  className,
  packageName,
  paymentDetails,
}: PriceSummaryProps) => {
  const { priceType } = assertNotUndefined(useContext(BookingContext));
  const { sensitiveDataHidden } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  if (paymentDetails.kind === "value") {
    const data = paymentDetails.value;
    const isGrossPayment =
      sensitiveDataHidden || priceType === PaymentMode.gross;
    const price = isGrossPayment
      ? data.grossPriceWithTax
      : data.netPriceWithTax;

    return (
      <div className={clsx(styles.priceSummary, className)}>
        <div className={styles.header}>Your Price Summary</div>
        <hr className={styles.divider} />
        <div className={styles.row}>
          <div>{packageName}</div>
          <div>{renderPriceString(price)}</div>
        </div>

        <div className={clsx(styles.row, styles.price)}>
          <div>Price</div>
          <div>{renderPriceString(price)}*</div>
        </div>
        <div className={styles.caveat}>
          * This price is converted to show you the approximate cost in{" "}
          {price.currency}. The exchange rate might change before you pay.
        </div>
      </div>
    );
  } else if (paymentDetails.kind === "loading") {
    return <div>loading...</div>;
  } else if (paymentDetails.kind === "error") {
    return <div>error</div>;
  } else {
    assertNever(paymentDetails);
  }
};
