import { AppUserType } from "@adl-gen/hotel/db";

import { BackofficeUserModalForm } from "./types";

export const EMPTY_NEW_BACKOFFICE_USER_FORM: BackofficeUserModalForm = {
  fullname: "",
  email: "",
  phoneNumber: null,
  userType: AppUserType.backOffice,
};
