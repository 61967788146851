import clsx from "clsx";
import React, { FC, useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LiveStatusTotalsReport } from "@adl-gen/hotel/api";
import { LiveStatus } from "@adl-gen/hotel/booking";
import { AppUserType } from "@adl-gen/hotel/db";
import { BookingSearchContext, LoggedInContext } from "@app/app";
import { BookingIcon } from "@assets/svg-components";
import { ALL_SEARCH_PROPERTY } from "@constants/booking";
import { LIVE_STATUS_MAP, ROUTE_PATH } from "@constants/common";
import { assertNotUndefined } from "@hx/util/types";
import { GeneralPage } from "@layouts/page-layout/general-page";
import { BOOKING_STATUS_ICON } from "@pages/dashboard/constants";
import DashboardQuickSearch from "@pages/dashboard/dashboard-quick-search";
import LatestBookings from "@pages/dashboard/latest-bookings";
import { checkUserAccessLevel } from "@util/agentus-utis";
import { NewBookingButton } from "@widgets/button/new-booking-button";

import styles from "./dashboard.css";

/** Props interface */
export interface DashboardProps {}

export const Dashboard: FC<DashboardProps> = () => {
  const location = useLocation();
  const history = useHistory();

  const { bookingReport, fetchBookingReport } = assertNotUndefined(
    useContext(BookingSearchContext)
  );

  const { userProfile } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  const bookingReportByStatuses: LiveStatusTotalsReport[] = useMemo(
    () =>
      (bookingReport?.kind === "value" &&
        bookingReport.value.liveStatusTotals) ||
      [],
    [bookingReport]
  );
  const totalBookingNumber: number = useMemo(
    () =>
      (bookingReport?.kind === "value" &&
        bookingReport.value.bookingTotal?.total) ||
      0,
    [bookingReport]
  );

  useEffect(() => {
    if (!bookingReport) {
      fetchBookingReport();
    }
  }, []);

  /**
   * Demonstrates best practise for programatic navigation;
   * use the current location object modify as required and
   * then push to history.
   */
  const clickHandler = () => {
    location.pathname = ROUTE_PATH.Search;
    location.search = "";
    history.push(location);
  };

  function goToBookings(status?: LiveStatus) {
    window.open(
      `${ROUTE_PATH.BookingsAdvanced}?liveStatuses=${
        status !== undefined
          ? JSON.stringify([status])
          : JSON.stringify([ALL_SEARCH_PROPERTY])
      }`,
      "_self"
    );
  }

  return (
    <GeneralPage
      items={["Dashboard"]}
      sibling={<NewBookingButton onClick={clickHandler} />}
    >
      <div className={styles.dashboard}>
        <div className={clsx(styles.shadowedBox, styles.quickSearch)}>
          <DashboardQuickSearch />
        </div>

        <div className={styles.bookingsSummary}>
          <div
            className={clsx(styles.shadowedBox, styles.totalBookingNumberBox)}
            onClick={(_e) => goToBookings()}
          >
            <div className={styles.boxHeading}>All Bookings</div>
            <div className={styles.boxBody}>
              <BookingIcon />
              <div className={styles.totalBookingNumber}>
                {totalBookingNumber}
              </div>
            </div>
          </div>

          {bookingReportByStatuses.map((report) => {
            // We hide the draft live status for non backoffice users
            return report.status === LiveStatus.draft &&
              !checkUserAccessLevel(
                userProfile,
                AppUserType.backOffice
              ) ? null : (
              <div
                key={report.status}
                className={clsx(
                  styles.shadowedBox,
                  styles[
                    `${LIVE_STATUS_MAP[report.status].key}BookingNumberBox`
                  ],
                  styles.statusBookingNumberBox
                )}
                onClick={() => goToBookings(report.status)}
              >
                <div className={styles.boxHeadingWrapper}>
                  <div className={styles.icon}>
                    {BOOKING_STATUS_ICON[report.status]}
                  </div>
                  <div className={styles.boxHeading}>
                    {LIVE_STATUS_MAP[report.status].label}
                  </div>
                </div>
                <div>{report.total}</div>
              </div>
            );
          })}
        </div>

        <div className={clsx(styles.shadowedBox, styles.latestBookings)}>
          <LatestBookings />
        </div>
      </div>
    </GeneralPage>
  );
};
