import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import {
  PackageInfo,
  PaymentDetails,
  PaymentDetailsResp,
  StayDetails,
} from "@adl-gen/hotel/api";
import { PackageCancellationPolicy, PriceValue } from "@adl-gen/hotel/common";
import { BookingContext, GeneralContext } from "@app/app";
import { OUTDATED_HOTELS_DATA_MSG, ROUTE_PATH } from "@constants/common";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { HotelListingResultDetails } from "@models/hotel";
import { Loading, mapLoading } from "@models/loading";
import BookingForm, {
  BookingDetails,
} from "@pages/complete-booking-page/complete-booking/booking-form/booking-form";
import HotelListCard from "@pages/hotel-search/hotel-results/list-view-layout/hotel-list-card";
import { assertValueLoaded, getNightsFromRange } from "@util/agentus-utis";
import { Button } from "@widgets/button/button";
import { ConfirmationInput } from "@widgets/confirmation-input/confirmation-input";
import { Page } from "@widgets/page/page";
import { PageSection } from "@widgets/page/page-section";
import { RoomRateListing } from "@widgets/room-rate-listing/room-rate-listing";

import CancellationPolicyListing from "./cancellation-policy-listing";
import styles from "./complete-booking.css";
import Conditions from "./conditions";
import { PriceSummary } from "./price-summary/price-summary";
import { StayDetailsBar } from "./stay-details-bar/stay-details-bar";

/** Props for the component */
export interface CompleteBookingProps {
  selectedPackage: PackageInfo;
  stayDetails: StayDetails;
  hotelDetails: HotelListingResultDetails;
  cancellationPolicyForPackage: Loading<PackageCancellationPolicy | undefined>;
  paymentDetails: Loading<PaymentDetailsResp>;
  bookingDetails: BookingDetails;
  isLoading: boolean;
  showBookingFormErrors: boolean;
  setBookingDetails(bookingDetails: BookingDetails): void;

  /**
   * Represents an error obtained from submitting this booking form. If the value is loading then the form is currently
   * being submitted. If a value is present and it is not undefined then that is a form error that should be displayed
   * to the user.
   */
  formError?: Loading<string | undefined>;
}

/**
 * The Booking page.
 */
const CompleteBooking: FC = () => {
  const {
    completeBookingProps,
    createBookingQuote,
    termsAndConditions,
    priceType,
  } = assertNotUndefined(useContext(BookingContext));
  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );
  const {
    paymentDetails,
    bookingDetails,
    setBookingDetails,
    formError,
    hotelDetails,
    cancellationPolicyForPackage,
    selectedPackage,
    stayDetails,
  } = assertValueLoaded(completeBookingProps);

  const defaultCanLoadPaymentDetails =
    paymentDetails.kind === "loading" ||
    (paymentDetails.kind === "value" &&
      paymentDetails.value.kind === "details");
  const [purchaseDetailsCanBeLoaded, setPurchaseDetailsCanBeLoaded] =
    useState<boolean>(defaultCanLoadPaymentDetails);

  useEffect(() => {
    const canLoadPaymentDetails =
      paymentDetails.kind === "loading" ||
      (paymentDetails.kind === "value" &&
        paymentDetails.value.kind === "details");
    setPurchaseDetailsCanBeLoaded(canLoadPaymentDetails);
  }, [paymentDetails]);

  const assertPaymentDetailsAvailable = (
    paymentDetailsValue: PaymentDetailsResp
  ): PaymentDetails => {
    if (paymentDetailsValue.kind === "details") {
      return paymentDetailsValue.value;
    }

    throw new Error("payment details not available");
  };

  const mappedPaymentDetails = useMemo(
    () => mapLoading(paymentDetails, assertPaymentDetailsAvailable),
    [paymentDetails]
  );

  function SelectedPackage(): JSX.Element {
    const paymentDetailsData = mapLoading(
      paymentDetails,
      assertPaymentDetailsAvailable
    );
    if (paymentDetailsData.kind === "loading") {
      return (
        <RoomRateListing className={styles.selectedPackage} kind={"roomInfo"} />
      );
    } else if (paymentDetailsData.kind === "error") {
      return (
        <div className={styles.selectedPackage}>
          <LoadingError />
        </div>
      );
    } else if (paymentDetailsData.kind === "value") {
      return (
        <RoomRateListing
          className={styles.selectedPackage}
          kind={"roomInfo"}
          room={{
            roomPackage: selectedPackage,
            paymentDetails: paymentDetailsData.value,
          }}
        />
      );
    } else {
      return assertNever(paymentDetailsData);
    }
  }

  function LoadingIndicator(): JSX.Element {
    return <div>loading...</div>;
  }

  function LoadingError(): JSX.Element {
    return (
      <>
        <div>Error loading payment details</div>
        <Link to={ROUTE_PATH.Search} replace>
          Start new search
        </Link>
      </>
    );
  }

  function LoadingFormMessage(): JSX.Element | null {
    if (!formError) {
      return null;
    } else if (formError.kind === "value") {
      return <div>{formError.value}</div>;
    } else if (formError.kind === "error") {
      return <div>{formError.error}</div>;
    } else if (formError.kind === "loading") {
      return <LoadingIndicator />;
    } else {
      assertNever(formError);
    }
  }

  function LoadingCancellationPolicyListing(): JSX.Element {
    if (cancellationPolicyForPackage.kind === "error") {
      return <LoadingError />;
    } else if (cancellationPolicyForPackage.kind === "loading") {
      return <LoadingIndicator />;
    } else if (cancellationPolicyForPackage.kind === "value") {
      if (!cancellationPolicyForPackage.value) {
        return <div>Could not load cancellation policy for package</div>;
      } else {
        const bookAndHoldEnabled =
          mappedPaymentDetails.kind === "value" &&
          mappedPaymentDetails.value.canBookAndHold &&
          selectedPackage.cancellationPolicy.kind !== "noCancellation";
        return (
          <CancellationPolicyListing
            policy={assertNotUndefined(cancellationPolicyForPackage.value)}
            paymentMode={priceType}
            canBookAndHold={bookAndHoldEnabled}
          />
        );
      }
    } else {
      return assertNever(cancellationPolicyForPackage);
    }
  }

  const paymentDetailsElement: JSX.Element = useMemo(() => {
    if (paymentDetails.kind === "error") {
      return <LoadingError />;
    } else if (paymentDetails.kind === "loading") {
      return <LoadingIndicator />;
    } else if (paymentDetails.kind === "value") {
      if (paymentDetails.value.kind === "details") {
        return <ConfirmationInput />;
      } else if (
        paymentDetails.value.kind === "supplierError" ||
        paymentDetails.value.kind === "packageNotAvailable" ||
        paymentDetails.value.kind === "invalidPriceToken" ||
        paymentDetails.value.kind === "invalidHotelId"
      ) {
        if (paymentDetails.value.kind === "invalidPriceToken") {
          addNotification({
            text: OUTDATED_HOTELS_DATA_MSG,
            type: NotificationTypeEnum.Error,
          });
        }

        return <LoadingError />;
      } else {
        assertNever(paymentDetails.value);
      }
    } else {
      assertNever(paymentDetails);
    }
  }, [paymentDetails, selectedPackage]);

  const averageRate: PriceValue = useMemo(() => {
    const value =
      Number.parseFloat(selectedPackage.price.value) /
      getNightsFromRange(stayDetails.checkIn, stayDetails.checkOut);

    return {
      currency: selectedPackage.price.currency,
      value: Math.ceil(value).toFixed(2).toString(),
    };
  }, [stayDetails, selectedPackage]);

  const confirmationOptions: JSX.Element = useMemo(() => {
    return (
      <>
        {paymentDetailsElement}
        <div>
          <p className={styles.quoteText}>
            Would you like to create a quote instead?
          </p>
          <Button
            className={styles.fullWidth}
            onClick={createBookingQuote}
            emphasis="medium"
            size="large"
          >
            Create Quote
          </Button>
        </div>
      </>
    );
  }, [paymentDetailsElement]);

  const checkout: JSX.Element = useMemo(() => {
    return (
      <div className={styles.detailsContainer}>
        <div className={styles.responsive}>
          <div className={styles.leftColumn}>
            <HotelListCard
              details={hotelDetails}
              hideTotalPrice={true}
              averageRate={averageRate}
              nights={getNightsFromRange(
                stayDetails.checkIn,
                stayDetails.checkOut
              )}
              pax={stayDetails.rooms[0]}
            />
            <SelectedPackage />
            <BookingForm
              bookingDetails={bookingDetails}
              onChange={setBookingDetails}
            />
          </div>

          <div className={styles.rightColumn}>
            <PriceSummary
              packageName={selectedPackage.name}
              paymentDetails={mappedPaymentDetails}
            />
            <LoadingCancellationPolicyListing />
            {!formError && confirmationOptions}
            <LoadingFormMessage />
          </div>
        </div>

        <Conditions termsAndConditions={termsAndConditions} />
      </div>
    );
  }, [
    termsAndConditions,
    paymentDetails,
    bookingDetails,
    formError,
    selectedPackage.name,
    confirmationOptions,
    priceType,
    cancellationPolicyForPackage,
    mappedPaymentDetails,
  ]);

  return (
    <Page>
      <StayDetailsBar />
      <PageSection main>
        {purchaseDetailsCanBeLoaded ? checkout : <LoadingError />}
      </PageSection>
    </Page>
  );
};

export default CompleteBooking;
