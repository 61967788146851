import { LiveStatus } from "@adl-gen/hotel/booking";
import { validateRequiredEnvVar } from "@util/agentus-utis";

export const MAX_RATING_STARS = 5;

export const NOTIFICATION_TIMEOUT_MS = 10_000;

export const GoogleMapKey = validateRequiredEnvVar(
  process.env.GOOGLE_MAPS_API_KEY
);
export const GooglePlacesKey = validateRequiredEnvVar(
  process.env.GOOGLE_PLACES_API_KEY
);

export const UPDATED_HOTELS_DATA_MSG =
  "We've updated the search results so you can see only relevant data";
export const OUTDATED_HOTELS_DATA_MSG = "We are updating the rates for you";

export const HTML_REGEX = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;

export const COMMON_DATE_FORMAT = "D MMM YYYY";
// You can pass it to InputDate component as "formatDate" prop,
// because react-datepicker uses date-fns under the hood, so it needs different formats
export const COMMON_DATE_FNS_FORMAT = "d MMM y";
export const COMMON_DATETIME_FORMAT = "D MMM YYYY HH:mm";

export enum PaymentType {
  CreditCardPayment = "CreditCardPayment",
  BankTransfer = "BankTransfer",
  BookAndHold = "BookAndHold",
}

export const LIVE_STATUS_MAP: Record<
  LiveStatus,
  { key: string; label: string }
> = {
  [LiveStatus.confirmed]: {
    key: "confirmed",
    label: "Confirmed",
  },
  [LiveStatus.quote]: {
    key: "quote",
    label: "Quote",
  },
  [LiveStatus.draft]: {
    key: "draft",
    label: "Draft",
  },
  [LiveStatus.pendingConfirmation]: {
    key: "pending",
    label: "Pending Confirmation",
  },
  [LiveStatus.cancelled]: {
    key: "cancelled",
    label: "Cancelled",
  },
  [LiveStatus.unavailable]: {
    key: "unavailable",
    label: "Unavailable",
  },
  [LiveStatus.travelled]: {
    key: "travelled",
    label: "Travelled",
  },
};

export enum ROUTE_PATH {
  Root = "/",
  Login = "/login",
  Logout = "/logout",
  Admin = "/admin",
  Dashboard = "/dashboard",
  Search = "/search",
  CompleteBooking = "/book",
  Bookings = "/bookings",
  BookingsAdvanced = "/bookings/advanced",
  Itinerary = "/itinerary",
  Settings = "/settings",
  PasswordRecovery = "/password-recovery",
  Hotel = "/hotel",
}

export enum GooglePlaceType {
  State = "administrative_area_level_1",
  City = "locality",
}

export const ABORT_ERROR_MESSAGE = "The user aborted a request.";
