import React, { FC, useContext, useEffect, useState } from "react";
import Linkify from "react-linkify";

import { ItineraryCommentData } from "@adl-gen/hotel/api";
import { LoggedInContext } from "@app/app";
import { Icons } from "@common/icon/svg";
import { assertNotUndefined } from "@hx/util/types";
import { formatLocalDate } from "@util/agentus-utis";
import { Button } from "@widgets/button/button";
import CommentInfoModal from "@widgets/itinerary/comments/comment-info-modal";
import NewComment from "@widgets/itinerary/comments/new-comment";

import styles from "./styles.css";

export const COMMENTS_TABLE_DATE_FORMAT = "MM/DD/YYYY, HH:mm";

interface Props {
  bookingIds: string[];
}

const Comments: FC<Props> = ({ bookingIds }) => {
  const { itineraryController, identityController } = useContext(
    LoggedInContext
  );
  const { userProfile } = assertNotUndefined(identityController);
  const { comments, addComment, fetchComments } = assertNotUndefined(
    itineraryController
  );
  const [isNewCommentOpen, setIsNewCommentOpen] = useState(false);
  const [
    selectedComment,
    setSelectedComment,
  ] = useState<ItineraryCommentData | null>(null);

  useEffect(() => {
    fetchComments();
  }, []);

  function toggleAddModal() {
    setIsNewCommentOpen((prevState) => !prevState);
  }

  function closeInfoModal() {
    setSelectedComment(null);
  }

  function addNewComment(
    text: string,
    bookingNumbers: string[]
  ): Promise<void> {
    return addComment({
      created: new Date().getTime(),
      owner: userProfile!.appUser.value,
      text,
      bookingNumbers,
    });
  }

  return (
    <div className={styles.comments}>
      <div className={styles.commentsHeader}>
        <div className={styles.commentsTitle}>Comments</div>
        <Button className={styles.addButton} onClick={toggleAddModal}>
          {Icons.plusCircleFilled}
          Add Comment
        </Button>
      </div>
      <NewComment
        open={isNewCommentOpen}
        bookings={bookingIds}
        onClose={toggleAddModal}
        addComment={addNewComment}
      />
      <table className={styles.commentsTable}>
        <thead>
          <tr>
            <th>Date & Time</th>
            <th>User Email</th>
            <th>Bookings</th>
            <th className={styles.textColumn}>Text</th>
          </tr>
        </thead>

        {comments.kind === "value" && !!comments.value.items.length && (
          <tbody>
            {comments.value.items.map((comment) => (
              <tr
                key={comment.commentId}
                onClick={() => setSelectedComment(comment)}
              >
                <td>
                  {formatLocalDate(
                    new Date(comment.created),
                    COMMENTS_TABLE_DATE_FORMAT
                  )}
                </td>
                <td>{comment.owner.email}</td>
                <td>{comment.bookingNumbers.join(", ")}</td>
                <td>
                  <Linkify>{comment.text}</Linkify>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      {comments.kind === "value" && comments.value.items.length === 0 && (
        <div className={styles.emptyData}>No added comments</div>
      )}

      {selectedComment && (
        <CommentInfoModal comment={selectedComment} onClose={closeInfoModal} />
      )}
    </div>
  );
};

export default Comments;
