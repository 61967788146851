import React, { FC } from "react";

import { HotelRoom } from "@adl-gen/hotel/api";
import { Price, PriceProps } from "@widgets/price/price";
import { Skeleton } from "@widgets/skeleton/skeleton";

import styles from "./styles.css";

interface Props {
  nights: number;
  pax: HotelRoom;
  totalPricing: PriceProps;
}

const TotalHotelPrice: FC<Props> = ({ totalPricing, nights, pax }) => {
  const price = Math.ceil(Number(totalPricing.value));

  return (
    <div className={styles.totalPriceSection}>
      <Skeleton
        className={styles.nightsAndPaxSkeleton}
        trigger={totalPricing.value !== undefined}
      >
        <span className={styles.nightsAndPax}>
          {nights} {nights === 1 ? "night" : "nights"}, {pax.numAdults}{" "}
          {pax.numAdults === 1 ? "adult" : "adults"}
          {pax.children.length
            ? `, ${pax.children.length} ${
                pax.children.length === 1 ? "child" : "children"
              }`
            : null}
        </span>
      </Skeleton>

      <Price
        value={price ? `${price}` : undefined}
        currency={totalPricing.currency}
        size="small"
        fractionDigits={0}
      />
      <div className={styles.totalPriceLabel}>
        <Skeleton
          className={styles.totalSkeleton}
          trigger={totalPricing.value !== undefined}
        >
          Total price
        </Skeleton>
      </div>
    </div>
  );
};

export default TotalHotelPrice;
