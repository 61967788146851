export const ITINERARY_LOGS_MOCK = [
  {
    id: "1",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "2",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "3",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "4",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "5",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "6",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "7",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "8",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "9",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "10",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
  {
    id: "11",
    date: new Date(),
    user: "admin@agentus.com",
    itinerary: "12345",
    text: "Confirmed unpaid -> supplier unpaid",
  },
];
