import React, { FC } from "react";

import styles from "../hotel-details.css";

interface PropTypes {
  blockHeading: string;
  bodyTextBlocks?: string[];
}

const ContentGroup: FC<PropTypes> = ({
  blockHeading,
  bodyTextBlocks,
  children,
}) => (
  <div className={styles.contentGroup}>
    <div className={styles.blockHeading}>{blockHeading}</div>
    {bodyTextBlocks?.map((textBlock, index) => (
      <span key={index} className={styles.blockBodyText}>
        {textBlock}
      </span>
    ))}
    {children}
  </div>
);

export default ContentGroup;
