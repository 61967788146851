import React, { FC, useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { AppUserType } from "@adl-gen/hotel/db";
import { LoggedInContext } from "@app/app";
import { ROUTE_PATH } from "@constants/common";
import { assertNotUndefined } from "@hx/util/types";

/**
 * Component that restricts rendering of children based on login status
 * and permissions
 */

interface Props {
  allowedRoles?: AppUserType[];
}

export const Protected: FC<Props> = ({ children, allowedRoles }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { isLoggedIn, userProfile, logout } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  params.set("referrer", location.pathname);

  const loggedIn = isLoggedIn();

  function changeLocation() {
    location.pathname = ROUTE_PATH.Login;
    logout();
  }

  if (!loggedIn) {
    location.search = params.toString();
    changeLocation();

    return <Redirect to={location} />;
  } else if (
    allowedRoles &&
    // tslint:disable-next-line:no-non-null-assertion
    !allowedRoles.includes(userProfile!.appUser.value.userType)
  ) {
    location.search = "";
    changeLocation();

    return <Redirect to={location} />;
  }

  return <>{children}</>;
};
