import React, { FC } from "react";

import { ClientPaymentStatus } from "@src/adl-gen/hotel/booking";

import StatusDropdown, {
  Props as StatusDropdownProps,
} from "../status-dropdown";

const title = "Payment Status";

//not showing some statuses for now, per specs
const LabeledClientPaymentStatus = {
  [ClientPaymentStatus.unpaid]: "Unpaid",
  // [ClientPaymentStatus.paymentSubmitted]: "Payment Submitted",
  // [ClientPaymentStatus.paidViaCredit]: "Paid Via Credit",
  // [ClientPaymentStatus.underPaid]: "Under Paid",
  [ClientPaymentStatus.paid]: "Paid",
  // [ClientPaymentStatus.settled]: "Settled",
  [ClientPaymentStatus.refundDue]: "Refund Due",
  [ClientPaymentStatus.refunded]: "Refunded",
  [ClientPaymentStatus.commissionDue]: "Commission Due",
  [ClientPaymentStatus.cancelled]: "Cancelled",
};

const STATUS_OPTIONS = Object.keys(LabeledClientPaymentStatus)
  // We hide the Draft live status from non backoffice users
  .map((key) => ({
    key: Number(key),
    label: LabeledClientPaymentStatus[key],
  }));

type Props = Pick<
  StatusDropdownProps<ClientPaymentStatus>,
  "onSelectOption" | "value"
>;

const ClientPaymentStatusDropdown: FC<Props> = ({ onSelectOption, value }) => {
  const options = STATUS_OPTIONS;

  return (
    <StatusDropdown
      title={title}
      placeholder={title}
      renderOptions={options}
      onSelectOption={onSelectOption}
      value={value}
    />
  );
};

export default ClientPaymentStatusDropdown;
