import clsx from "clsx";
import React, { FC, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { PoiNearHotel } from "@adl-gen/ids/externalapi";
import { HotelSearchContext } from "@app/app";
import { Icons } from "@common/icon/svg";
import layout from "@common/layout-lib.css";
import { ROUTE_PATH } from "@constants/common";
import { ITEMS_PER_PAGE } from "@constants/pagination";
import {
  HOTEL_PARAM,
  PRICE_TOKEN_PARAM,
  SHOW_ON_MAP_PARAM,
} from "@controllers/booking/constant";
import {
  COMMISSION_PARAM,
  PAGE_PARAM,
} from "@controllers/hotel-search/constant";
import { HotelListingResultWithId } from "@controllers/hotel-search/hotel-search-controller";
import { BOUNDS_PARAM } from "@controllers/location-search/location-search-controller";
import { assertNotNull, assertNotUndefined } from "@hx/util/types";
import { HotelImage } from "@models/hotel";
import { isHotelPageUrlParams } from "@pages/hotel-details/util";
import { Button } from "@widgets/button/button";
import { SpinnerOverlay } from "@widgets/loader/loader";
import { Page } from "@widgets/page/page";
import { PageSection } from "@widgets/page/page-section";
import { SearchBar } from "@widgets/search-bar/search-bar";
import StarRating from "@widgets/star-rating";

import AboutThisArea from "./about-this-area";
import AboutThisProperty from "./about-this-property";
import Amenities from "./amenities";
import CoverImage from "./cover-image";
import HotelDetailsPackages from "./hotel-details-packages";
import styles from "./hotel-details.css";
import HotelModal from "./map-modal";
import PriceDetails from "./price-details";

const NEARBY_LANDMARKS_TO_DISPLAY_IN_TOP_SECTION = 6;

export const HotelDetails: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const [hotelImages, setHotelImages] = useState<HotelImage[]>([]);

  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const {
    setCurrentPriceToken,
    setLocationFromParams,
    setSelectedHotel,
    nearbyPoisToHotel,
    hotelListingResultDetails,
    packages,
    selectedHotel,
    onSelectPackage,
    getStayDetailsFromParams,
    setStayDetails,
    updateExistingSearch,
    searchHotelsFromCache,
    queryHotelImages,
    setCommission,
  } = hotelSearchController;

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    init();
  }, []);

  async function init() {
    const params = new URLSearchParams(location.search);
    if (isHotelPageUrlParams(params)) {
      const commission = params.get(COMMISSION_PARAM);
      setCommission(assertNotNull(commission));
      const pt = params.get(PRICE_TOKEN_PARAM);
      setCurrentPriceToken(assertNotNull(pt));

      const sd = getStayDetailsFromParams(params);
      setLocationFromParams(params);

      const hId = params.get(HOTEL_PARAM);

      setLocationFromParams(params);

      setStayDetails(sd);

      const images = await queryHotelImages(assertNotNull(hId), 100);
      setHotelImages(images.galleryImages);

      const bounds = params.get(BOUNDS_PARAM);
      const page = params.get(PAGE_PARAM);

      if (bounds !== null && page !== null) {
        const boundsValue = JSON.parse(bounds);

        await searchHotelsFromCache(
          { kind: "bounds", value: [boundsValue] },
          {
            count: ITEMS_PER_PAGE,
            offset: (+page - 1) * ITEMS_PER_PAGE,
            updateStore: true,
          }
        );
      } else {
        await updateExistingSearch();
      }

      setSelectedHotel(assertNotNull(hId));

      if (params.get(SHOW_ON_MAP_PARAM)) {
        setShowModal(true);
      }
    } else {
      location.pathname = ROUTE_PATH.Dashboard;
      location.search = "";

      history.push(location);
    }
  }

  function openModal() {
    setShowModal(true);
  }
  function closeModal() {
    setShowModal(false);
  }

  function selectHotel(id: string) {
    setSelectedHotel(id, { inNewTab: true });

    setShowModal(false);
  }

  const hotelId = selectedHotel;
  let hotel: HotelListingResultWithId | undefined;
  if (hotelListingResultDetails?.kind === "value") {
    hotel = hotelListingResultDetails.value.find(
      (value: HotelListingResultWithId) => value.id === hotelId
    );
  } else {
    return <SpinnerOverlay />;
  }

  const amenities = hotel?.details.hotelAmenities.sort() || [];

  function scrollToRates() {
    const ratesSection = document.getElementById("room-rate-listing");

    if (!ratesSection) {
      return;
    }

    ratesSection.scrollIntoView({ behavior: "smooth" });
  }

  function printHotelDetails() {
    window.print();
  }

  if (!hotel) {
    return <SpinnerOverlay />;
  }

  let nearbyPois: PoiNearHotel[] = [];
  if (nearbyPoisToHotel) {
    if (nearbyPoisToHotel.kind === "loading") {
      return null;
    } else if (nearbyPoisToHotel.kind === "error") {
      return <div>Error: {nearbyPoisToHotel.error}</div>;
    } else {
      nearbyPois = nearbyPoisToHotel.value.slice(
        0,
        NEARBY_LANDMARKS_TO_DISPLAY_IN_TOP_SECTION
      );
    }
  }

  return (
    <Page>
      <PageSection className={styles.searchBarContainer}>
        <SearchBar />
      </PageSection>
      <PageSection main>
        <div className={styles.hotelDetails} id="hotel-details">
          <div className={styles.topRow}>
            <div className={styles.hotelTitleSection}>
              <span className={styles.hotelName}>
                {hotel.details.hotelName}
              </span>
              <span className={styles.hotelAddress}>
                {hotel.details.hotelAddress}
              </span>
              <StarRating starRating={hotel.details.hotelStarRating} />
            </div>
            <div className={styles.hotelPriceSection}>
              <PriceDetails hotelDetails={hotel.details} />
              {hotel.details.priceDetails.kind === "value" &&
                hotel.details.priceDetails.value?.kind === "available" && (
                  <span className={styles.lowestAverageNightlyRate}>
                    Lowest average nightly rate
                  </span>
                )}
              <Button onClick={scrollToRates}>View Rates</Button>
            </div>
          </div>
          <div className={styles.middleImageRow}>
            <CoverImage
              coverImageUrl={hotel.details.hotelCoverImageURL}
              images={hotelImages.map(({ url }) => url)}
            />
            <div className={styles.rightImageColumn}>
              <div className={styles.mapView} onClick={openModal}>
                <div className={styles.mapViewInner}>Map view</div>
              </div>

              <div className={styles.nearbyLandmarks}>
                {nearbyPois.map((poi) => (
                  <div
                    className={clsx(
                      layout.horizontal,
                      styles.nearbyLandmarkRow
                    )}
                    key={poi.poi.id}
                  >
                    <span className={styles.poiDistance}>
                      {poi.distanceFromHotel}m
                    </span>
                    <span className={styles.poiName}>
                      from {poi.poi.value.name}
                    </span>
                  </div>
                ))}
              </div>

              <Button
                className={styles.printPdfButton}
                classes={{ container: styles.printPdfButtonInner }}
                emphasis="medium"
                onClick={printHotelDetails}
              >
                <span>Print or download the hotel info</span>
                {Icons.printDocument}
              </Button>
            </div>
          </div>
          {/*
           * TODO(jess): This was copied directly from hotel search page.
           * Move this to a common util file when there is time.
           */}
          <HotelDetailsPackages
            packages={packages}
            onSelectPackage={onSelectPackage}
          />

          {amenities.length ? (
            <div className={styles.contentSection}>
              <div className={styles.contentTitle}>Property amenities</div>
              <Amenities items={amenities} />
            </div>
          ) : null}

          <AboutThisArea nearbyPoisToHotel={nearbyPoisToHotel} />
          <AboutThisProperty hotel={hotel} />
          {/* Displaying Policies as a separate section is not available yet */}
          {/*<Policies />*/}
        </div>

        {hotel && (
          <HotelModal
            hotel={hotel}
            open={showModal}
            closeModal={closeModal}
            nearbyHotels={hotelListingResultDetails.value.filter(
              ({ id }) => id !== hotelId
            )}
            onSelectHotel={selectHotel}
          />
        )}
      </PageSection>
    </Page>
  );
};
