import React from "react";

import { Breadcrumb, BreadcrumbProps } from "@widgets/breadcrumb/breadcrumb";
import { Page, PageProps } from "@widgets/page/page";
import { PageSection } from "@widgets/page/page-section";

import styles from "./general-page.css";

/** Props interface */

export interface GeneralPageProps extends PageProps, BreadcrumbProps {}

export const GeneralPage = (props: GeneralPageProps): JSX.Element => {
  return (
    <Page>
      <PageSection className={styles.breadcrumb}>
        <Breadcrumb items={props.items} sibling={props.sibling} />
        <hr className={styles.hr} />
      </PageSection>
      <PageSection main>{props.children}</PageSection>
    </Page>
  );
};
