import React, {
  ChangeEvent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * Props for the ShadowInput component
 */
interface ShadowInputProps {
  name: string | undefined;
  value: string | number | undefined;
  inputRef: RefObject<HTMLInputElement>;
  inputHandler(event: ChangeEvent<HTMLInputElement>): void;
}

/**
 * ShadowInput component used to track component 'value' and dispatch events for
 * complex inputs.
 *
 * @author James Millar
 */
export const ShadowInput = ({
  name,
  value,
  inputRef,
  inputHandler,
}: ShadowInputProps) => {
  return (
    <input
      type="hidden"
      ref={inputRef}
      name={name}
      value={value}
      onInput={inputHandler}
    />
  );
};

// Types for value that can be stored in the shadow input
export type ValueTypes = string | number | undefined;

// Types for acceptable values that can be received
export type AcceptedTypes = ValueTypes | object | [];

/**
 * Shadow Input Hook that sets up an input field to act as the value store
 * for complex input components. It sets up and tracks changes to the shadow
 * input and fires change events when the value changes. This enabled the
 * building of complex inputs that can be used as controlled componets.
 *
 * @param name - The name of the complex input. NB this will be reflected in the `events.currentTarget.name` property.
 * @param propValue - The value of the complex input. Typically this will be passed in from a prop.
 * @param onChange - An onChange handler that has been passed to the complex input.
 *
 * @returns The setter to update the shadow value and the props for the ShadowInput component
 *
 * @author James Millar
 */
export function useShadowInput(
  name: string | undefined,
  propValue: AcceptedTypes,
  onChange: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined
): [(value: AcceptedTypes) => void, ShadowInputProps] {
  // Ref used to access the shadow input used for value tracking and event dispatch
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValueState] = useState<ValueTypes>(harmoniseType(propValue));

  // Effect to dispatch input event when the value changes
  useEffect(() => {
    // Dispatch an input event on the shadow input
    inputRef.current?.dispatchEvent(new Event("input", { bubbles: true }));
  }, [value]);

  // Handle the change event from the shadow input. NB that react conflates
  // change events with input events by binding onChange to onInput:
  // https://stackoverflow.com/questions/38256332/in-react-whats-the-difference-between-onchange-and-oninput
  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    // If we received an onChange handler
    if (onChange) {
      // Throw the event
      onChange(event);
    }
  };

  // Create the return props and ensure type safe
  const shadowInputProps: ShadowInputProps = {
    name,
    value,
    inputRef,
    inputHandler,
  };

  const setValue = (_value: AcceptedTypes) => {
    setValueState(harmoniseType(_value));
  };

  // return the setter, and the ShadowInputProps
  return [setValue, shadowInputProps];
}

// Utility function to convert arrays and objects to strings otherwise pass
// through strings, numbers and undefined
const harmoniseType = (value: AcceptedTypes): ValueTypes => {
  return Array.isArray(value) || typeof value === "object"
    ? JSON.stringify(value)
    : value;
};
