import classnames from "classnames";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import { InputText, InputTextProps } from "../input-text/input-text";
import { SpinnerIcon } from "../spinnerIcon/spinnerIcon";
import { InputLookupOptionProps } from "./input-lookup-option";
import styles from "./input-lookup.css";

/**
 * Properties for InputLookup component. Extends the InputTextProps and
 * overides the children property. This type includes the intrinsic
 * properties for an HTML Input.
 */
export interface InputLookupProps extends Omit<InputTextProps, "children"> {
  /** Optional additional classes to apply to internal elements */
  classes?: InputTextProps["classes"] & {
    inputLookup?: string;
    selectList?: string;
  };
  isLoading: boolean;
  children?: JSX.Element | JSX.Element[];
}

/**
 * Text Lookup Component intended to provide additional lookup options for an
 * `input type="text"` element. Management of the options (InputLookupOption)
 * are handled by the caller.
 *
 * @author James Millar
 */
export const InputLookup = React.forwardRef<HTMLInputElement, InputLookupProps>(
  (
    {
      className,
      classes = {},
      children,
      onChange,
      isLoading,
      ...props
    }: InputLookupProps,
    ref: React.RefObject<HTMLInputElement>
  ): JSX.Element => {
    // State used to track the ßinput value
    const [inputValue, setInputValue] = useState(props.value || "");

    // State used to track the open/close state of the select list
    const [isOpen, setIsOpen] = useState(false);

    // Ref to handle native setting of input value to trigger change event
    const inputRef = ref || useRef<HTMLInputElement>(null);

    // Ref to handle the click outside
    const wrapperRef = useRef<HTMLDivElement>(null);

    // Close the dropdown if the user clicks outside of the component
    useEffect(() => {
      const clickOutsideHandler = (event: MouseEvent) => {
        // If the event occured on an element outside of this component
        if (
          wrapperRef.current &&
          event.target instanceof HTMLElement &&
          !wrapperRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      // Add the listener
      document.addEventListener("mousedown", clickOutsideHandler);
      return () => {
        // Remove the listener
        document.removeEventListener("mousedown", clickOutsideHandler);
      };
    }, [wrapperRef]);

    useEffect(() => setInputValue(props.value || ""), [props.value]);

    // Determine if there are results to be displayed in the select list
    const isResults = Array.isArray(children)
      ? children.length > 0
      : !!children;

    // Handle change to the value of the input
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setInputValue(value);

      setIsOpen(value !== "");

      if (onChange && value !== "") {
        onChange(event);
      }
    };

    // Handle the selection of an item from the dropdown list. This uses a
    // method to set the input value natively so that the change event
    // is correctly triggered.
    const clickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
      setIsOpen(false);
      setInputValue(event.currentTarget.value);
    };

    // Handle recipt of focus on the component. If the input contains
    // a value and the select list contains results then the list is shown.
    const focusHandler = () => {
      setIsOpen(!!(inputValue && inputValue !== "" && isResults));
    };

    // Create an array of cloned children who have an onSelect handler
    const cloneChildren = (
      (Array.isArray(children) && children) || [children]
    ).map((current) => {
      if (current?.props.value !== undefined) {
        return React.cloneElement(
          current as ReactElement<InputLookupOptionProps>,
          {
            onClickInternal: clickHandler,
          }
        );
      } else {
        return current;
      }
    });

    // Aggregation of class names for root element
    const inputLookupClasses = classnames(
      styles.inputLookup,
      classes.inputLookup,
      className,
      isOpen && styles.open
    );

    // Merge inputText classes
    classes.inputText =
      (classes.inputText && `${styles.inputText} ${classes.inputText}`) ||
      styles.inputText;

    return (
      <div ref={wrapperRef} className={inputLookupClasses}>
        <InputText
          ref={inputRef}
          classes={classes}
          {...props}
          onChange={changeHandler}
          onFocus={focusHandler}
          value={inputValue}
        />
        {children && (
          <div className={classnames(styles.selectList, classes.selectList)}>
            {isLoading ? (
              <SpinnerIcon size="small" />
            ) : cloneChildren?.length ? (
              cloneChildren
            ) : (
              "No results"
            )}
          </div>
        )}
      </div>
    );
  }
);
