import classnames from "classnames";
import React, { FC } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ROUTE_PATH } from "@constants/common";

import { IconSizeable } from "../../icon/icon-sizeable";
import { Icons } from "../../icon/icons";
import { BreadCrumbsData, USER_PATH_PARAM } from "./constants";
import styles from "./styles.css";
import { BreadCrumbsDataType, BreadCrumbType } from "./types";

const HeaderBreadCrumbs: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const userPath = new URLSearchParams(location.search).get(USER_PATH_PARAM);

  function getActiveLinks(): BreadCrumbsDataType[] {
    let current;
    switch (true) {
      case location.pathname === ROUTE_PATH.Search && location.search === "":
        current = BreadCrumbType.Search;
        break;
      case location.pathname === ROUTE_PATH.Search && location.search !== "":
        current = BreadCrumbType.Results;
        break;
      case location.pathname === ROUTE_PATH.Hotel:
        current = BreadCrumbType.Details;
        break;
      default:
        current = BreadCrumbType.Search;
    }

    return BreadCrumbsData.slice(
      0,
      BreadCrumbsData.findIndex((b) => b.type === current) + 1
    );
  }

  const currentBreadcrumbs: BreadCrumbsDataType[] = userPath
    ? getBreadCrumbsFromUrlParam()
    : getActiveLinks();

  function getBreadCrumbsFromUrlParam() {
    const parsed: BreadCrumbType[] = JSON.parse(userPath as string);

    return parsed.map(
      (breadCrumb) =>
        BreadCrumbsData.find(
          (v) => v.type === breadCrumb
        ) as BreadCrumbsDataType
    );
  }

  function clickLink(type: BreadCrumbType) {
    removeUserPathFromLocation();

    switch (type) {
      case BreadCrumbType.Search:
        window.open(ROUTE_PATH.Search, "_self");
        break;
      case BreadCrumbType.Results:
        location.pathname = ROUTE_PATH.Search;
        history.push(location);
        break;
      case BreadCrumbType.Details:
        location.pathname = ROUTE_PATH.Hotel;
        history.push(location);
        break;
      default:
    }
  }

  function removeUserPathFromLocation() {
    const currentParams = new URLSearchParams(location.search);

    currentParams.delete(USER_PATH_PARAM);

    location.search = currentParams.toString();
  }

  return (
    <ol className={styles.breadcrumbs}>
      {currentBreadcrumbs.map(({ type, title }, index) => {
        const isActive = index === currentBreadcrumbs.length - 1;
        return (
          <li key={type}>
            <button
              className={classnames(
                styles.breadcrumbButton,
                isActive && styles.current
              )}
              onClick={isActive ? undefined : () => clickLink(type)}
            >
              {title}
            </button>
            {!isActive && <RightArrow />}
          </li>
        );
      })}
    </ol>
  );
};

const RightArrow = (): JSX.Element => (
  <IconSizeable icon={Icons.arrowRight} size={16} />
);

export default HeaderBreadCrumbs;
