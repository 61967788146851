/** Sleeps for a while */
export function sleep(millis: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, millis);
  });
}

export interface DebounceCallback<T> {
  value: T;
  signal?: AbortSignal;
}

// Wrap a callback fn, so that, as long as the wrapper continues to be
// invoked, it will not be triggered. The callback will be called
// after the wrapper stops being called for delayMs milliseconds.
export function debounce<T>(
  delayMs: number,
  fn: (v: DebounceCallback<T>) => void
) {
  let callScheduled: number | null = null;
  let abortController: AbortController | null = null;

  function delayedCall(newValue: T) {
    if (callScheduled !== null) {
      window.clearTimeout(callScheduled);
      if (abortController) {
        abortController.abort();
      }
    }
    abortController = new AbortController();
    const signal = abortController?.signal;
    callScheduled = window.setTimeout(
      () => fn({ value: newValue, signal }),
      delayMs
    );
  }

  delayedCall.cancel = function () {
    if (callScheduled !== null) {
      window.clearTimeout(callScheduled);
      if (abortController) {
        abortController.abort();
      }
      callScheduled = null;
      abortController = null;
    }
  };

  return delayedCall;
}
