/** HTTP communications using built in browser fetch */

import { HttpFetch, HttpRequest, HttpResponse } from "./http";

const DEFAULT_API_TIMEOUT_MS = 60000;

export class BrowserHttp implements HttpFetch {
  private readonly globalTimeout: number;

  constructor() {
    this.globalTimeout = this.safeParseInt(
      process.env.GLOBAL_API_TIMEOUT_MS,
      DEFAULT_API_TIMEOUT_MS
    );
    // tslint:disable-next-line:no-console
    console.log(`Global API Timeout: ${this.globalTimeout}`);
  }

  safeParseInt(input: string | undefined, defaultValue: number): number {
    const parsedValue = parseInt(input || "", 10);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  async fetch(request: HttpRequest): Promise<HttpResponse> {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();

      const timer = setTimeout(() => {
        controller.abort('Request timeout, the api is not responding');
      }, this.globalTimeout);

      const promise = fetch(request.url, {
        method: request.method,
        headers: request.headers,
        body: request.body,
        signal: request.signal
          ? this.combineSignals([request.signal, controller.signal])
          : controller.signal,
      });

      promise
        .then((resp) => {
          clearTimeout(timer);
          resolve({
            status: resp.status,
            statusText: resp.statusText,
            ok: resp.ok,
            json: () => resp.json(),
            text: () => resp.text(),
          });
        })
        .catch((reason) => {
          clearTimeout(timer);
          reject(reason);
        });
    });
  }

  private combineSignals(signals: AbortSignal[]): AbortSignal {
    const controller = new AbortController();

    for (const signal of signals) {
      if (signal.aborted) {
        return signal;
      }

      signal.addEventListener("abort", () => controller.abort(signal.reason), {
        signal: controller.signal,
        once: true,
      });
    }

    return controller.signal;
  }
}
