import { sendErrorToRollbar } from "@util/error-handling";

/** Stores tokens */
export interface TokenManager {
  getToken(): string;
  setToken(token: string): void;
}

/** Token manager backed by a browser storage implementation */
export class StorageTokenManager implements TokenManager {
  constructor(readonly tokenKey: string, readonly storage: Storage) {}

  getToken(): string {
    try {
      return this.storage.getItem(this.tokenKey) || "";
    } catch (e) {
      sendErrorToRollbar(e, {
        additionalInfo: "Failed to get value from the local storage",
      });
      // tslint:disable-next-line:no-console
      console.error("Failed to get value from the local storage: ", e.message);

      return "";
    }
  }

  setToken(token: string): void {
    try {
      this.storage.setItem(this.tokenKey, token);
    } catch (e) {
      sendErrorToRollbar(e, {
        additionalInfo: "Failed to set value to the local storage",
      });
      // tslint:disable-next-line:no-console
      console.error("Failed to set value to the local storage: ", e.message);
    }
  }
}

/** token manager that just stores the token in an instance variable */
export class LocalTokenManager implements TokenManager {
  private token: string = "";

  getToken(): string {
    return this.token;
  }

  setToken(token: string): void {
    this.token = token;
  }
}
