import clsx from "clsx";
import React, { useCallback, useContext, useMemo, useState } from "react";

import { HotelSearchContext, LoggedInContext } from "@app/app";
import { PlusFilledIcon } from "@assets/svg-components";
import layout from "@common/layout-lib.css";
import { assertNotUndefined } from "@hx/util/types";
import { Button } from "@widgets/button/button";
import Tooltip from "@widgets/tooltip";

import styles from "./styles.css";

interface Props {
  onClickDownload(): Promise<void>;
  actionsDisabled?: boolean;
}

const DOWNLOAD_LABEL = "Download Itinerary";

export default ({ onClickDownload, actionsDisabled }: Props) => {
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const { currentItinerary } = assertNotUndefined(
    useContext(LoggedInContext).itineraryController
  );
  const { startNewSearchForItinerary } = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const itineraryNumber: string = useMemo(() => {
    if (
      currentItinerary !== undefined &&
      currentItinerary.kind === "value" &&
      currentItinerary.value.kind === "itinerary"
    ) {
      return currentItinerary.value.value.itinerary.value.itineraryNumber;
    }

    return "";
  }, [currentItinerary]);

  async function loadingWrapper(
    callback: () => Promise<void>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    setIsLoading(true);
    await callback();
    setIsLoading(false);
  }

  const onDownload = () =>
    loadingWrapper(onClickDownload, setIsDownloadLoading);

  const RenderButton = useCallback(() => {
    return (
      <Button
        classes={{ button: styles.documentsButton, container: styles.documentsButtonInner }}
        onClick={onDownload}
        emphasis="medium"
        isLoading={isDownloadLoading}
        disabled={actionsDisabled}
      >
        {DOWNLOAD_LABEL}
      </Button>
    );
  }, [onDownload, isDownloadLoading, actionsDisabled]);

  return (
    <div className={styles.itineraryBar}>
      <div className={clsx(layout.horizontal, styles.leftSection)}>
        <div className={styles.block}>
          <span className={styles.label}>Itinerary</span>

          <span className={styles.itineraryId}>{itineraryNumber}</span>
        </div>

        <div className={styles.block}>
          <span className={styles.label}>Add</span>

          <Button
            classes={{ button: styles.accommodationButton }}
            emphasis="medium"
            onClick={() => startNewSearchForItinerary(itineraryNumber)}
          >
            <PlusFilledIcon />
            Accommodation
          </Button>
        </div>
      </div>

      <div className={styles.block}>
        <span className={styles.label}>Download</span>

        {actionsDisabled ? (
          <Tooltip
            text={
              "Vouchers can only be created for confirmed and paid bookings."
            }
          >
            <RenderButton />
          </Tooltip>
        ) : (
          <>
            <RenderButton />
          </>
        )}
      </div>
    </div>
  );
};
