import clsx from "clsx";
import React, { FC } from "react";

import layout from "@common/layout-lib.css";
import { BackofficePaymentLabel } from "@widgets/itinerary/booking/types";

import paymentBadgeStyles from "../../payment-badge.styles.css";
import styles from "./styles.css";

interface Props extends BackofficePaymentLabel {
  type: string;
}

const BackofficePaymentBadge: FC<Props> = ({ label, type, color, icon }) => {
  return (
    <div
      className={clsx(
        styles.backofficePaymentBadge,
        paymentBadgeStyles.paymentBadge,
        paymentBadgeStyles[color],
        layout.horizontal,
        layout.justified
      )}
    >
      <div className={paymentBadgeStyles.pictureContainer}>{icon}</div>
      <div className={clsx(styles.content, layout.vertical)}>
        <span className={paymentBadgeStyles.text}>{type}</span>
        <div className={paymentBadgeStyles.title}>{label}</div>
      </div>
    </div>
  );
};

export default BackofficePaymentBadge;
