import classnames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

import { UserProfile } from "@adl-gen/hotel/api";
import { AppUserType } from "@adl-gen/hotel/db";
import { ROUTE_PATH } from "@constants/common";
import { checkUserAccessLevel } from "@util/agentus-utis";

import { Icons } from "../icon/icons";
import styles from "./nav-primary.css";

export interface NavPrimaryProps {
  userProfile?: UserProfile;
  // Optional additional class to apply to the wrapper element
  className?: string;
  // Optional additional classes to apply to internal elements
  classes?: { navPrimary?: string };
}

export const NavPrimary = (props: NavPrimaryProps) => {
  const classes = props.classes || {};

  return (
    <nav
      className={classnames(
        styles.navPrimary,
        classes.navPrimary,
        props.className
      )}
      style={
        checkUserAccessLevel(props.userProfile, AppUserType.backOffice)
          ? {}
          : { flexGrow: 1 }
      }
    >
      <NavLink to={ROUTE_PATH.Dashboard} activeClassName={styles.current}>
        {Icons.dashboard}
        <span>Dashboard</span>
      </NavLink>
      <NavLink to={ROUTE_PATH.Search} activeClassName={styles.current}>
        {Icons.plusCircle}
        <span>New Booking</span>
      </NavLink>
      <NavLink to={ROUTE_PATH.Bookings} activeClassName={styles.current}>
        {Icons.listUiAlt}
        <span>Bookings</span>
      </NavLink>
      {/* <NavLink to={"/reports"} activeClassName={styles.current}>
        {Icons.fileBookmarkAlt}
        <span>Reporting</span>
      </NavLink> */}
    </nav>
  );
};
