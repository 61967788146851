import React, { FC, useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import { AppUserType } from "@adl-gen/hotel/db";
import { LoggedInContext } from "@app/app";
import { EyeIcon, HideIcon } from "@common/icon/icons";
import { ROUTE_PATH } from "@constants/common";
import { checkUserAccessLevel } from "@util/agentus-utis";

import CustomDropdown from "../../custom-dropdown";
import { Icons } from "../../icon/icons";
import styles from "./styles.css";

const ProfileMenu: FC = () => {
  const { identityController } = useContext(LoggedInContext);
  const [isOpen, setIsOpen] = useState(false);

  const clickHandler = () => setIsOpen((open) => !open);
  const isUserBackoffice = checkUserAccessLevel(
    identityController?.userProfile,
    AppUserType.backOffice
  );

  const DropdownInner = (
    <div className={styles.navLinkList}>
      <div
        className={styles.navLink}
        onClick={identityController?.toggleSensitiveDataVisibility}
      >
        {identityController?.sensitiveDataHidden ? (
          <HideIcon className={styles.iconComponent} />
        ) : (
          <EyeIcon className={styles.iconComponent} />
        )}
        <span>Client view</span>
      </div>

      <NavLink
        to={
          isUserBackoffice
            ? "/settings/user-profile"
            : "/settings/agent-profile"
        }
        className={styles.navLink}
      >
        {Icons.cog}
        <span>Settings</span>
      </NavLink>

      <NavLink to={ROUTE_PATH.Logout} className={styles.navLink}>
        {Icons.exit}
        <span>Logout</span>
      </NavLink>
    </div>
  );

  return (
    <CustomDropdown
      className={styles.dropdown}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      inner={DropdownInner}
    >
      <div className={styles.profileMenu} onClick={clickHandler}>
        <span className={styles.userName}>
          Hello, <b>{identityController?.userProfile?.fullname}</b>
        </span>

        <div className={styles.profileImage}>
          {identityController?.userProfile?.fullname[0]}
        </div>

        <div className={styles.dropdownIcon}>{Icons.dropdownDown}</div>
      </div>
    </CustomDropdown>
  );
};

export default ProfileMenu;
