import classnames from "classnames";
import React from "react";

import { PriceValue } from "@adl-gen/hotel/common";
import currenciesDigits from "@constants/currencies-digits.json";

import { Skeleton } from "../skeleton/skeleton";
import styles from "./price.css";

export interface PriceProps extends Partial<PriceValue> {
  className?: string;
  size?: "small" | "medium" | "large";
  sign?: string;
  fractionDigits?: number;
}

/**
 * Component to display a currency amount. The component provides
 * three size options for the amount size. Accepts undefined values and displays
 * placeholder Skeletons.
 *
 * @author: James Millar
 */

export const Price = ({
  className,
  size = "large",
  value,
  currency,
  sign,
  fractionDigits,
}: PriceProps) => {
  function renderPriceValue(val: number) {
    if (val === 0) {
      return val;
    }

    const digits =
      fractionDigits ??
      currenciesDigits.find(({ currency: curr }) => curr === currency)
        ?.fractionDigits;

    return digits
      ? val.toFixed(digits)
      : val < 0
      ? Math.floor(val)
      : Math.ceil(val);
  }

  return (
    <div className={classnames(styles.price, styles[size], className)}>
      <span className={styles.amount}>
        {sign && <span className={styles.currencySign}>{sign}</span>}
        <Skeleton className={styles.amountSkeleton}>
          {value !== undefined && renderPriceValue(+value)}
        </Skeleton>
      </span>
      <span className={styles.currency}>
        <Skeleton className={styles.currencySkeleton}>{currency}</Skeleton>
      </span>
    </div>
  );
};
