import classNames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import { HotelSearchContext } from "@app/app";
import { BaseProps } from "@common/base";
import { COMMON_DATE_FORMAT } from "@constants/common";
import { LOCALDATE_FORMAT } from "@controllers/hotel-search/hotel-search-controller";
import { getNameContext } from "@controllers/location-search/location-search-controller";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { assertValueLoaded, daysStaying } from "@util/agentus-utis";

import styles from "./stay-details-bar.css";

interface StayDetailsBarProps extends BaseProps {}

export const StayDetailsBar = (props: StayDetailsBarProps) => {
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const [occupants, setOccupants] = useState<{
    adults: number;
    children: number;
  }>({ adults: 0, children: 0 });

  useEffect(() => {
    let adults = 0;
    let children = 0;
    for (const room of hotelSearchController.stayDetails.rooms) {
      adults += room.numAdults;
      children += room.children.length;
    }

    setOccupants({ adults, children });
  }, [hotelSearchController.stayDetails]);

  const selectedHotelId = assertNotUndefined(
    hotelSearchController.selectedHotel
  );
  const selectedHotel = assertNotUndefined(
    assertValueLoaded(
      assertNotUndefined(hotelSearchController.hotelListingResultDetails)
    ).find((hotel) => hotel.id === selectedHotelId)
  );

  return (
    <div className={styles.stayDetailsBarWrapper}>
      <div className={classNames(props.classes, styles.stayDetailsBar)}>
        <DetailValue
          label={"Destination"}
          value={getNameContext(
            assertNotUndefined(hotelSearchController.selectedLocation)
          )}
          width={"large"}
        />
        <DetailValue
          label={"Property Name"}
          value={selectedHotel.details.hotelName}
          width={"large"}
        />
        <DetailValue
          label={"Check-in"}
          value={moment(
            hotelSearchController.stayDetails.checkIn,
            LOCALDATE_FORMAT
          ).format(COMMON_DATE_FORMAT)}
          width={"large"}
        />
        <DetailValue
          label={"Check-out"}
          value={moment(
            hotelSearchController.stayDetails.checkOut,
            LOCALDATE_FORMAT
          ).format(COMMON_DATE_FORMAT)}
          width={"large"}
        />
        <DetailValue
          label={"Nights"}
          value={daysStaying(hotelSearchController.stayDetails)}
          width={"small"}
        />
        <DetailValue
          label={"Rooms"}
          value={hotelSearchController.stayDetails.rooms.length}
          width={"small"}
        />
        <DetailValue
          label={"Adults"}
          value={occupants.adults}
          width={"small"}
        />
        <DetailValue
          label={"Children"}
          value={occupants.children}
          width={"small"}
        />
      </div>
    </div>
  );
};

interface DetailValueProps {
  label: string;
  value: string | number;
  width: "large" | "small";
}

const styleName = (width: DetailValueProps["width"]) => {
  if (width === "large") {
    return styles.large;
  } else if (width === "small") {
    return styles.small;
  } else {
    assertNever(width);
  }
};

const DetailValue = ({ label, value, width }: DetailValueProps) => {
  return (
    <div className={classNames(styles.detail, styleName(width))}>
      <label>{label}</label>
      <div className={styles.detailValue}>{value}</div>
    </div>
  );
};
