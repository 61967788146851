import React, { createContext, useEffect, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { UiConfig } from "@adl-gen/hotel/uiconfig";
import { Protected } from "@common/protected";
import { ROUTE_PATH } from "@constants/common";
import {
  AgencyController,
  useAgencyController,
} from "@controllers/agency-controller";
import {
  BookingSearchController,
  useBookingSearchController,
} from "@controllers/booking-search/booking-search-controller";
import { BookingController } from "@controllers/booking/booking-controller";
import {
  HotelSearchController,
  useHotelSearchController,
} from "@controllers/hotel-search/hotel-search-controller";
import {
  IdentityController,
  useIdentityController,
} from "@controllers/identity/identity-controller";
import {
  ItineraryController,
  useItineraryController,
} from "@controllers/itinerary/itinerary-controller";
import { useLocationSearchController } from "@controllers/location-search/location-search-controller";
import {
  NotificationController,
  useNotificationController,
} from "@controllers/notification-controller";
import {
  UserController,
  useUserController,
} from "@controllers/user/user-controller";
import ErrorFallback from "@layouts/error-fallback";
import { StripeLayout } from "@layouts/stripe-layout";
import BookingSearch from "@pages/booking-search/booking-search";
import CompleteBookingPage from "@pages/complete-booking-page/complete-booking-page";
import { Dashboard } from "@pages/dashboard/dashboard";
import { HotelDetails } from "@pages/hotel-details/hotel-details";
import { HotelSearchPage } from "@pages/hotel-search/hotel-search-page";
import InvitePage from "@pages/invite-page";
import { LoginPage } from "@pages/login-page/login-page";
import PasswordRecovery from "@pages/password-recovery";
import { SETTINGS_LINK_PERMISSION } from "@pages/settings/nav-settings/constant";
import { SettingsNavLink } from "@pages/settings/nav-settings/types";
import { Settings } from "@pages/settings/settings";
import { initRollbar, sendErrorToRollbar } from "@util/error-handling";
import { AgentusLoader } from "@widgets/agentus-loader/agentus-loader";
import { Itinerary } from "@widgets/itinerary/itinerary";
import NotificationBar from "@widgets/notification/notification-bar";

import { initHttp } from "../index";
import { AppAdmin } from "./app-admin";

interface AppProps {
  /** UI configuration */
  uiconfig: UiConfig;
}

export interface LoggedInContextProps {
  identityController?: IdentityController;
  itineraryController?: ItineraryController;
  agencyController?: AgencyController;
  userController?: UserController;
}

export interface GeneralContextProps {
  notificationController?: NotificationController;
}

export const LoggedInContext = createContext<LoggedInContextProps>({});

export const GeneralContext = createContext<GeneralContextProps>({});

export const HotelSearchContext = createContext<
  HotelSearchController | undefined
>(undefined);

export const BookingContext = createContext<BookingController | undefined>(
  undefined
);

export const BookingSearchContext = createContext<
  BookingSearchController | undefined
>(undefined);

export const App = (props: AppProps) => {
  const notificationController = useNotificationController();

  const history = useHistory();
  const location = useLocation();

  const [service, tokenManager] = useMemo(() => {
    return initHttp();
  }, []);

  const identityController = useIdentityController(
    service,
    tokenManager,
    notificationController
  );

  const agencyController = useAgencyController(
    service,
    notificationController,
    identityController.userProfile
  );
  const locationSearchController = useLocationSearchController(
    service,
    notificationController
  );

  const currentUser = identityController.userProfile?.appUser.value;
  const currentAgency =
    agencyController.agencySettingsState.currentAgencyDetails?.value;
  const userType = currentUser?.userType;

  const defaultSearchCommission = useMemo(() => {
    return currentUser?.commissionPercentage
      ? currentUser.commissionPercentage
      : currentAgency?.commissionPercentage || null;
  }, [currentUser, currentAgency]);

  const hotelSearchController = useHotelSearchController(
    service,
    notificationController,
    locationSearchController,
    defaultSearchCommission
  );

  const bookingSearchController = useBookingSearchController(
    service,
    notificationController
  );
  const itineraryController = useItineraryController({
    currentUser: identityController.userProfile?.appUser.id,
    service,
    notificationController,
  });

  const userController = useUserController({
    service,
    currentUser: identityController.userProfile?.appUser.value,
    notificationController,
  });

  useEffect(() => {
    initRollbar(props.uiconfig);
  }, []);

  const settingsRoute = useMemo(() => {
    if (
      userType !== undefined &&
      SETTINGS_LINK_PERMISSION[SettingsNavLink.UserProfile]?.includes(userType)
    ) {
      return SettingsNavLink.UserProfile;
    } else {
      return SettingsNavLink.AgentProfile;
    }
  }, [userType]);

  return (
    <GeneralContext.Provider
      value={{
        notificationController,
      }}
    >
      <ErrorBoundary fallback={<ErrorFallback />} onError={sendErrorToRollbar}>
        <Switch>
          <Route path="/invite/:inviteHash">
            <InvitePage userController={userController} />
          </Route>
          <Route path={ROUTE_PATH.Login}>
            <LoginPage identityState={identityController} />
          </Route>
          <Route
            path={ROUTE_PATH.Logout}
            render={() => {
              /**
               * In a timeout to prevent the following warning:
               * "Cannot update a component (`App`) while rendering a different component (`Context.Consumer`)"
               */
              setTimeout(() => {
                identityController.logout();
                agencyController.logout();
                hotelSearchController.logout();
                bookingSearchController.logout();
                itineraryController.logout();
                userController.logout();
                notificationController.logout();
                locationSearchController.logout();

                /**
                 * Technically, we could still use only the <Redirect>,
                 * since all data should have been removed by the
                 * logout methods above. However, a full page reload
                 * is useful to ensure there isn't any in-memory data
                 * available after a logout. The "logout" calls above
                 * still make sense despite a full reload as they may
                 * remove persistent data, eg. local or session storage,
                 * cookies, etc.
                 */
                window.location.replace(ROUTE_PATH.Login);
              }, 100);

              return <Redirect to={ROUTE_PATH.Login} />;
            }}
          />
          <Route path={ROUTE_PATH.PasswordRecovery}>
            <PasswordRecovery userController={userController} />
          </Route>
          {identityController.userProfileIsLoading ? (
            <AgentusLoader />
          ) : (
            <LoggedInContext.Provider
              value={{
                identityController,
                itineraryController,
                agencyController,
                userController,
              }}
            >
              <Route path={ROUTE_PATH.Admin}>
                <Protected>
                  <AppAdmin
                    reactRouterLocation={location}
                    reactRouterHistory={history}
                    service={service}
                    identityStore={identityController}
                  />
                </Protected>
              </Route>
              <Route path={ROUTE_PATH.Dashboard}>
                <Protected>
                  <BookingSearchContext.Provider
                    value={bookingSearchController}
                  >
                    <Dashboard />
                  </BookingSearchContext.Provider>
                </Protected>
              </Route>
              <Route path={ROUTE_PATH.Search}>
                <Protected>
                  <HotelSearchContext.Provider value={hotelSearchController}>
                    <HotelSearchPage />
                  </HotelSearchContext.Provider>
                </Protected>
              </Route>
              <Route path={ROUTE_PATH.Hotel}>
                <Protected>
                  <HotelSearchContext.Provider value={hotelSearchController}>
                    <HotelDetails />
                  </HotelSearchContext.Provider>
                </Protected>
              </Route>
              <Route exact path={ROUTE_PATH.CompleteBooking}>
                <Protected>
                  <StripeLayout service={service}>
                    <HotelSearchContext.Provider value={hotelSearchController}>
                      <CompleteBookingPage service={service} />
                    </HotelSearchContext.Provider>
                  </StripeLayout>
                </Protected>
              </Route>
              <Route exact path={ROUTE_PATH.Bookings}>
                <Protected>
                  <BookingSearchContext.Provider
                    value={bookingSearchController}
                  >
                    <BookingSearch
                      controller={bookingSearchController}
                      searchType="basic"
                    />
                  </BookingSearchContext.Provider>
                </Protected>
              </Route>
              <Route path={ROUTE_PATH.BookingsAdvanced}>
                <Protected>
                  <BookingSearchContext.Provider
                    value={bookingSearchController}
                  >
                    <BookingSearch
                      controller={bookingSearchController}
                      searchType="advanced"
                    />
                  </BookingSearchContext.Provider>
                </Protected>
              </Route>
              <Route path={`${ROUTE_PATH.Itinerary}/:id(IT[0-9]{9})`}>
                <Protected>
                  <HotelSearchContext.Provider value={hotelSearchController}>
                    <StripeLayout service={service}>
                      <Itinerary />
                    </StripeLayout>
                  </HotelSearchContext.Provider>
                </Protected>
              </Route>
              <Route exact path={ROUTE_PATH.Settings}>
                <Protected>
                  <Redirect to={settingsRoute} />
                </Protected>
              </Route>
              <Route path={ROUTE_PATH.Settings}>
                <Protected>
                  <Settings agencyController={agencyController} />
                </Protected>
              </Route>
              <Route exact path={ROUTE_PATH.Root}>
                <Protected>
                  <Redirect to={ROUTE_PATH.Dashboard} />
                </Protected>
              </Route>
            </LoggedInContext.Provider>
          )}
        </Switch>
      </ErrorBoundary>

      <NotificationBar />

      {/*used for creating an offscreen google maps screenshots*/}
      <div
        id="offscreen-map-container"
        style={{
          minHeight: "100px",
          position: "fixed",
          left: "calc(-100vw - 100px)",
          top: "calc(-100vh - 100px)",
        }}
      ></div>
    </GeneralContext.Provider>
  );
};
