import React, { FC, useCallback, useState } from "react";

import { WithDbId } from "@adl-gen/common/db";
import { Agency } from "@adl-gen/hotel/db";
import useOutsideClick from "@common/hooks/outside-click";
import { Icons } from "@common/icon/svg";

import CreateEditAgencyModal from "../agency-modal";
import styles from "./styles.css";

interface Props {
  agency: WithDbId<Agency>;
}

const AgencyDetailsButton: FC<Props> = ({ agency }) => {
  const { ref, isOpen, toggleIsOpen } = useOutsideClick<HTMLButtonElement>();
  const [modalOpened, setModalOpened] = useState(false);

  const toggleEditModal = useCallback(() => {
    setModalOpened((prevValue) => !prevValue);
  }, [modalOpened]);

  return (
    <>
      <button ref={ref} className={styles.detailsButton} onClick={toggleIsOpen}>
        {Icons.ellipsis}

        {isOpen && (
          <ul className={styles.list}>
            <li onClick={toggleEditModal}>Edit Agency</li>
          </ul>
        )}
      </button>

      <CreateEditAgencyModal
        isOpen={modalOpened}
        onClose={toggleEditModal}
        agency={agency}
      />
    </>
  );
};

export default AgencyDetailsButton;
