import { useEffect, useState } from "react";

import { Paginated, Unit } from "../../adl-gen/common";
import { DbResult, QueryReq } from "../../adl-gen/common/adminui/api";
import { DbKey, WithDbId } from "../../adl-gen/common/db";
import { makeTableQuery, TableView } from "../../adl-gen/common/tabular";
import { scopedNamesEqual } from "../../adl-gen/runtime/utils";
import * as adlast from "../../adl-gen/sys/adlast";
import { AdminService } from "../service";
import {DbKeyCache, dbKeyScopedName, mapDbValue, Metadata, TableMetadata, TSRow } from "../utils";


type LoadedRows = Paginated<WithDbId<TSRow>>;
interface LastQuery {view: TableView; query: QueryReq;}

export interface TablePageState {
  loadedRows: LoadedRows | null;
  canPageBack: boolean;
  canPageForward: boolean;
  rowsLoading: boolean;
  tableView: TableView | null;
  pendingDbError: string | null;
  getDbKeyTable(scopedName: adlast.ScopedName): string;
  getDbKeyLabel(scopedName: adlast.ScopedName, id: string): string;
  pageBack(): void;
  pageForward(): void;
  reload(): void;
  create(tsrow: TSRow): void;
  update(tsrow: WithDbId<TSRow>): void;
  deletev(id: DbKey<TSRow>): void;
  setTableView(view: TableView): void;
  clearDbError(): void;
};

const pageSize: number = 20;

export function createTablePageState(service: AdminService, metadata: Metadata, tmetadata:TableMetadata) : TablePageState {
  const [loadedRows, setLoadedRows] = useState<LoadedRows|null>(null);
  const [rowsLoading, setRowsLoading] = useState<boolean>(false);
  const [lastQuery, setLastQuery] = useState<LastQuery|null>(null);
  const [pendingDbError, setPendingDbError] = useState<string|null>(null);
  const [dbKeyCache] = useState<DbKeyCache>(() => new DbKeyCache(service));

  useEffect(() => {
    const req: QueryReq = {
      table: tmetadata.table.name,
      columns: [],
      query: makeTableQuery({
        offset: 0,
        count: pageSize
      })
    };
    void loadRows(req);
  }, [tmetadata.table.name]);


  const canPageForward =
      !rowsLoading &&
      loadedRows !== null &&
      loadedRows.current_offset + loadedRows.items.length < loadedRows.total_size;

  const canPageBack = !rowsLoading && loadedRows !== null && loadedRows.current_offset > 0;

  function getDbKeyTable(sn: adlast.ScopedName): string {
    for(const tname of metadata.tableNames) {
      const table = metadata.tableMap[tname];
      if (table) {
        const tsn =  {moduleName:table.declModuleName, name:table.declName};
        if (table && scopedNamesEqual(sn,tsn)) {
          return tname;
        }
      }
    }
    throw new Error("No db table found for " + sn.name);
  };

  async function loadRows(req: QueryReq): Promise<void> {
    setRowsLoading(true);
    const dbrows = await service.adminQuery(req);
    const tsrows = dbrows.items.map(dbrow => mapDbValue(tmetadata.tsRowFromDbRow, dbrow));
    await cacheDbKeyLabels(dbKeyCache, tmetadata, tsrows);
    setLastQuery({
      query: req,
      view: {
        columns: [],
        filter: req.query.filter,
        sorting: req.query.sorting,
      }
    });
    setLoadedRows({
      items: tsrows,
      current_offset: dbrows.current_offset,
      total_size: dbrows.total_size
    });
    setRowsLoading(false);
  };

  async function reloadRows(): Promise<void> {
    if (lastQuery !== null) {
      await loadRows(withUpdatedView(lastQuery.query, lastQuery.view));
    }
  }

  async function pageBack(): Promise<void> {
    if (canPageBack) {
      setLastQuery( (lq) => lq !== null ? withUpdatedOffset(lq, -pageSize): null);
      await reloadRows();
    }

  };

  async function pageForward(): Promise<void> {
    if (canPageForward) {
      setLastQuery( (lq) => lq !== null ? withUpdatedOffset(lq, pageSize): null);
    }
    await reloadRows();
  };


  async function create(tsrow: TSRow): Promise<void> {
    const dbresult = await service.adminCreate({
      table: tmetadata.table.name,
      values: tmetadata.dbRowFromTsRow(tsrow)
    });
    await updateAfterDbChange(dbresult);
  };

  async function update(tsrow: WithDbId<TSRow>): Promise<void> {
    const dbresult = await service.adminUpdate({
      table: tmetadata.table.name,
      values: mapDbValue(tmetadata.dbRowFromTsRow, tsrow)
    });
    await updateAfterDbChange(dbresult);
  };

  async function deletev(id: DbKey<TSRow>): Promise<void> {
    const dbresult = await service.adminDelete({
      table: tmetadata.table.name,
      id
    });
    await updateAfterDbChange(dbresult);
  };

  async function setTableView(view: TableView): Promise<void> {
    if (lastQuery !== null) {
      await loadRows(withUpdatedView(lastQuery.query, view));
    }
    return;
  };

  async function updateAfterDbChange(dbresult: DbResult<Unit>): Promise<void> {
    if (dbresult.kind === "ok") {
      await reloadRows();
    } else {
      setPendingDbError(dbresult.value);
    }
  }

  function clearDbError() {
    setPendingDbError(null);
  }

  return {
      tableView: lastQuery ? lastQuery.view: null,
      loadedRows,
      rowsLoading,
      canPageBack,
      canPageForward,
      pendingDbError,
      getDbKeyTable,
      getDbKeyLabel: (sn, id) => dbKeyCache.getLabel(sn, id), 
      pageBack,
      pageForward,
      reload: reloadRows,
      create,
      update,
      deletev,
      setTableView,
      clearDbError,
  };
}

function withUpdatedOffset(lq: LastQuery, delta: number): LastQuery {
  let offset = lq.query.query.offset + delta;
  if (offset < 0) {
    offset = 0;
  }
  return {
      query: {
        ...lq.query,
        query: {
          ...lq.query.query,
          offset,
        }
      },
      view: lq.view,
  };
}

function withUpdatedView(queryReq: QueryReq, view: TableView): QueryReq {
  return {
    ...queryReq,
    query : {
      ...queryReq.query,
      filter: view.filter,
      sorting: view.sorting,
    }
  }
}

async function cacheDbKeyLabels(dbKeyCache: DbKeyCache, tmetadata: TableMetadata, tsrows: WithDbId<TSRow>[] ): Promise<void> {

  // First find the keys we need
  for (const tsrow of tsrows) {
    for(const tcol of tmetadata.table.columns) {
      const dbKeyType = dbKeyScopedName(tcol.typeExpr, tmetadata.resolver);
      if (dbKeyType) {
        dbKeyCache.requestLabel(dbKeyType, tsrow.value[tcol.name] as string);
      }
    }
  }

  // Fetch them in a batch
  await dbKeyCache.fetch();
}
