import { GuardedPromise } from "@hx/util/guarded_promise";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import type { MutableRefObject } from "react";
import { useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import type { Paginated } from "@adl-gen/common";
import type { DbKey, WithDbId } from "@adl-gen/common/db";
import type {
  AgentBookingData,
  BookingRefundabilityResp,
  BookingSearchQuery,
  BookingSearchQueryResp,
  BookingSearchQueryResp_AgentBookingData,
  CancelBookingResp,
  GetItineraryResp,
  ItineraryCommentData,
  ItineraryDetails,
  PayForBookingResp,
} from "@adl-gen/hotel/api";
import type { PaymentMode } from "@adl-gen/hotel/booking";
import { ClientPaymentStatus, LiveStatus } from "@adl-gen/hotel/booking";
import type { Booking, Itinerary, TermsAndConditions } from "@adl-gen/hotel/db";
import type { AppUser } from "@adl-gen/ids/db";
import {
  CHECK_DRAFT_STATUS_INTERVAL,
  CHECK_DRAFT_STATUS_LIMIT,
} from "@constants/booking";
import { PaymentType } from "@constants/common";
import {
  ITINERARY_NUMBER_PARAM,
  PAYMENT_ERROR_FALLBACK,
  PAYMENT_ERROR_TEXT,
  QUOTE_REFRESH_ERROR_FALLBACK,
  QUOTE_REFRESH_RESPONSE_WARNING,
} from "@controllers/itinerary/constant";
import { isBookingPayable } from "@controllers/itinerary/utils";
import type { NotificationController } from "@controllers/notification-controller";
import useStoreCleanUp from "@hooks/useStoreCleanUp";
import { NotificationTypeEnum } from "@models/common";
import type { Loading } from "@models/loading";
import type { Service } from "@src/service/service";
import type { AgentSelectedBookingInfo } from "@widgets/itinerary/booking-selected/types";
import { extractBookingPriceData } from "@widgets/itinerary/payment-totals/helpers";
import type {
  BookingSearchResultState,
  PaymentTotal,
} from "@widgets/itinerary/types";
import type { StripeCallback } from "@widgets/payment-form/payment-form";

export const COMMENTS_QUERY_COUNT = 30;

export type ItineraryService = Pick<
  Service,
  | "queryBookings"
  | "createItinerary"
  | "getItinerary"
  | "cancelBooking"
  | "queryTermsAndConditions"
  | "queryItineraryComments"
  | "createItineraryComment"
  | "queryRefundability"
  | "refreshQuote"
  | "payForBooking"
>;

export interface ItineraryStoreProps {
  service: ItineraryService;
  currentUser?: DbKey<AppUser>;
  notificationController: NotificationController;
}

/**
 * Tracks amendments, e.g. cancellation, that we are waiting on from the backend
 * NOTE(Barry): Using Loading<null> because I don't actually care what the loaded value is (the bookings all get
 * refreshed after a successful amendment), I just want to track whether the amendment is loading or has returned an
 * error
 */
export type InFlightAmendments = Map<DbKey<Booking>, Loading<null>>;

export interface ItineraryController extends BookingSearchResultState {
  /**
   * Returns the most recently fetched itinerary. Will be undefined if the user is not currently fetching an itinerary
   */
  currentItinerary?: Loading<GetItineraryResp>;

  setCurrentItinerary(value: Loading<GetItineraryResp>): void;

  /**
   * Creates an itinerary and sets the value of currentItinerary, returns the created itinerary in
   * case callers need it immediately
   */
  createItinerary(): Promise<WithDbId<Itinerary> | undefined>;

  /**
   * Loads the itinerary with the given number. If the itinerary is loaded successfully will kick off loading the
   * bookings associated with that itinerary
   */
  loadItineraryNumber(itineraryNumber?: string): Promise<unknown>;

  /**
   * Kick off loading the itinerary if required
   */
  checkIfItineraryCanBeLoaded(): Promise<void>;

  /**
   * Amendments that are in-flight
   */
  inFlightAmendments: InFlightAmendments;

  /**
   * Cancels the booking with the given ID
   */
  cancelBooking(
    id: DbKey<Booking>,
    handleLocally?: boolean
  ): Promise<CancelBookingResp | undefined>;

  /**
   * The type of payment that the user chose when creating the booking
   */
  paymentType: MutableRefObject<PaymentType | null>;
  selectPaymentType(type: PaymentType): void;

  generateBookingKey(bookingId: string): string;

  selectedBookings: string[];

  toggleBookingSelection(bookingId: string): void;

  /** Getting all bookings by filters */
  fetchBookings(
    filters?: Partial<BookingSearchQuery>
  ): Promise<BookingSearchQueryResp | undefined>;

  /** Booking list loading state */
  isLoading: boolean;

  termsAndConditions: TermsAndConditions | null;
  getTermsAndConditions(): void;

  comments: Loading<Paginated<ItineraryCommentData>>;
  fetchComments(): Promise<void>;
  addComment(comment: Omit<ItineraryCommentData, "commentId">): Promise<void>;

  paymentTotals: PaymentTotal[];
  totalSelectedNet: number;
  totalSelectedGross: number;
  totalSelectedCommissions: number;

  checkIfBookingsRefundable(
    ids: string[]
  ): Promise<BookingRefundabilityResp | null>;

  refreshSelectedQuotes(): Promise<void>;

  payForQuote(
    bookingId: string,
    paymentMode: PaymentMode,
    stripeCallback: StripeCallback
  ): Promise<PayForBookingResp | undefined>;

  payForBookAndHold(
    bookingId: string,
    chargeMode: PaymentMode,
    stripeCallback: StripeCallback
  ): Promise<PayForBookingResp | undefined>;

  quoteToPay: AgentSelectedBookingInfo | null;
  setQuoteToPay(quote: AgentSelectedBookingInfo | null): void;

  /** Сlearing values after logout */
  logout(): void;
}

export const useItineraryController = (
  props: ItineraryStoreProps
): ItineraryController => {
  const { addNotification } = props.notificationController;

  const location = useLocation();
  const selectedItineraryNumber = useRef<string | undefined>(undefined);
  const [currentItinerary, setCurrentItinerary] = useState<
    Loading<GetItineraryResp> | undefined
  >(undefined);
  const [inFlightAmendments, setInFlightAmendments] =
    useState<InFlightAmendments>(new Map());
  const [itineraryBookings, setItineraryBookings] = useState<
    Loading<BookingSearchQueryResp>
  >({
    kind: "error",
    error: "No itinerary selected",
  });
  const [termsAndConditions, setTermsAndConditions] =
    useState<TermsAndConditions | null>(null);
  const paymentType = useRef<PaymentType | null>(null);

  const [selectedBookings, setSelectedBookings] = useState<string[]>([]);

  const [quoteToPay, setQuoteToPay] = useState<AgentSelectedBookingInfo | null>(
    null
  );

  const [comments, setComments] = useState<
    Loading<Paginated<ItineraryCommentData>>
  >({
    kind: "loading",
  });

  useStoreCleanUp(() => {
    setSelectedBookings([]);
    setItineraryBookings({ kind: "loading" });
    setQuoteToPay(null);
  });

  async function fetchComments() {
    try {
      const result = await props.service.queryItineraryComments({
        itineraryNumber: assertNotUndefined(selectedItineraryNumber.current),
        offset: 0,
        count: COMMENTS_QUERY_COUNT,
      });

      setComments({
        kind: "value",
        value: result,
      });
    } catch (e) {
      addNotification({
        text: "Comments loading error",
        type: NotificationTypeEnum.Error,
      });
    }
  }

  async function addComment(comment: Omit<ItineraryCommentData, "commentId">) {
    try {
      const response = await props.service.createItineraryComment({
        ...comment,
        itineraryNumber: selectedItineraryNumber.current!,
      });

      if (comments.kind === "value") {
        setComments({
          kind: "value",
          value: {
            items: [response, ...comments.value.items],
            current_offset: comments.value.current_offset,
            total_size: comments.value.total_size,
          },
        });
      }
    } catch (e) {
      addNotification({
        text: "Adding comment error",
        type: NotificationTypeEnum.Error,
      });
    }
  }

  function selectPaymentType(type: PaymentType) {
    paymentType.current = type;
  }

  function toggleBookingSelection(bookingId: string) {
    setSelectedBookings((prevState) =>
      prevState.includes(bookingId)
        ? prevState.filter((id) => id !== bookingId)
        : [...prevState, bookingId]
    );
  }

  const createItinerary = async (): Promise<
    WithDbId<Itinerary> | undefined
  > => {
    try {
      const resp = await props.service.createItinerary({
        agencyUserId: assertNotUndefined(props.currentUser),
        description: null,
      });

      if (resp.kind === "success") {
        const emptyItinerary: ItineraryDetails = {
          itinerary: resp.value,
          balanceDue: { value: "0.00", currency: "AUD" },
          grossPriceWithTax: { value: "0.00", currency: "AUD" },
          netPriceWithTax: { value: "0.00", currency: "AUD" },
          commission: { value: "0.00", currency: "AUD" },
          supplierPrice: null,
          buyPrice: null,
        };
        setCurrentItinerary({
          kind: "value",
          value: { kind: "itinerary", value: emptyItinerary },
        });
        return resp.value;
      } else if (resp.kind === "error") {
        setCurrentItinerary({
          kind: "error",
          error: "Could not save itinerary",
        });
        return undefined;
      } else {
        return assertNever(resp);
      }
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Unexpected error occurred: unable to create itinerary",
      });
    }
  };

  const loadSelectedItinerary = async (
    filters?: Partial<BookingSearchQuery>
  ): Promise<void> => {
    const itineraryNumber = selectedItineraryNumber.current;
    if (itineraryNumber === undefined) {
      setCurrentItinerary(undefined);
      return;
    }

    try {
      setCurrentItinerary({ kind: "loading" });
      const resp = await props.service.getItinerary({
        itineraryNumber,
      });
      setCurrentItinerary({ kind: "value", value: resp });
      if (resp.kind === "itinerary") {
        await fetchBookings({ filters: filters || {}, itineraryNumber });
      }
    } catch (e) {
      setCurrentItinerary({ kind: "error", error: "Could not load itinerary" });
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  async function fetchBookings(options?: {
    filters?: Partial<BookingSearchQuery>;
    itineraryNumber?: string;
    ignoreLoader?: boolean;
  }): Promise<BookingSearchQueryResp | undefined> {
    const itineraryNumberValue =
      options?.itineraryNumber || selectedItineraryNumber.current;

    if (!itineraryNumberValue) {
      return;
    }

    try {
      if (!options?.ignoreLoader) {
        setIsLoading(true);
      }

      const newBookings = await props.service.queryBookings({
        itineraryNumber: itineraryNumberValue,
        bookingNumber: null,
        agentRef: null,
        isCancellationFailed: null,
        count: 100,
        countryCode: null,
        createdFrom: null,
        createdTo: null,
        destinationName: null,
        firstName: null,
        lastName: null,
        liveStatuses: [],
        offset: 0,
        serviceFrom: null,
        isPaymentPending: null,
        serviceTo: null,
        userName: null,
        isCancellationPolicyActive: null,
        isInstantPurchase: null,
        clientPaymentStatuses: [],
        supplierPaymentStatuses: [],
        paymentDueDate: null,
        ...options?.filters,
      });

      if (!options?.ignoreLoader) {
        setIsLoading(false);
      }
      setItineraryBookings({ kind: "value", value: newBookings });

      return newBookings;
    } catch (e) {
      if (!options?.ignoreLoader) {
        setIsLoading(false);
      }
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "Loading bookings error",
      });
    }
  }

  const guardedLoadItinerary = new GuardedPromise(loadSelectedItinerary);

  const loadItineraryNumber = (itineraryNumber?: string) => {
    if (itineraryNumber) {
      selectedItineraryNumber.current = itineraryNumber;
    }

    return guardedLoadItinerary.requestAction();
  };

  const checkIfItineraryCanBeLoaded = async () => {
    const incomingParams = new URLSearchParams(location.search);
    const itineraryNumber = incomingParams.get(ITINERARY_NUMBER_PARAM);
    if (!itineraryNumber) {
      selectedItineraryNumber.current = undefined;
    }
    await loadItineraryNumber(itineraryNumber || undefined);
  };

  const cancelBooking = async (id: DbKey<Booking>, handleLocally?: boolean) => {
    if (handleLocally) {
      return props.service.cancelBooking(id);
    }

    setInFlightAmendments((currentVal) => {
      const updated = new Map(currentVal);
      updated.set(id, { kind: "loading" });
      return updated;
    });

    const setError = (error: string) => {
      setInFlightAmendments((currentVal) => {
        const updated = new Map(currentVal);
        updated.set(id, { kind: "error", error });
        return updated;
      });
    };

    setIsLoading(true);

    try {
      if (itineraryBookings.kind !== "value") {
        return;
      }
      const targetBooking = (
        itineraryBookings.value as BookingSearchQueryResp_AgentBookingData
      ).value.items.find(({ bookingData }) => bookingData.bookingId === id)!;

      const {
        bookingData: { liveStatus },
      } = targetBooking;

      const resp = await props.service.cancelBooking(id);
      if (resp.kind === "success") {
        setInFlightAmendments((currentVal) => {
          const updated = new Map(currentVal);
          updated.delete(id);
          return updated;
        });
        // Reload the itinerary to get the latest info
        await loadSelectedItinerary();

        if (liveStatus === LiveStatus.quote) {
          addNotification({
            type: NotificationTypeEnum.Success,
            text: "We have successfully cancelled a quote for you",
          });
        } else {
          addNotification({
            type: NotificationTypeEnum.Success,
            text: "We have successfully cancelled a booking for you",
          });
        }
      } else if (resp.kind === "invalidBookingId") {
        setError("Invalid booking ID");
        addNotification({
          type: NotificationTypeEnum.Error,
          text: "An error occurred, please try again or contact Agentus through: reservations@Agentus.com",
        });
      } else if (resp.kind === "error") {
        setError(resp.value);
        addNotification({
          type: NotificationTypeEnum.Error,
          text: "An error occurred, please try again or contact Agentus through: reservations@Agentus.com",
        });
      } else {
        assertNever(resp);
      }
    } catch (e) {
      addNotification({
        type: NotificationTypeEnum.Error,
        text: "An error occurred, please try again or contact Agentus through: reservations@Agentus.com",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const generateBookingKey = (bookingId: string) => {
    // a separate service to be able to change strategy of getting booking key
    // (e.g. calling a backend endpoint)
    return `B${bookingId}`;
  };

  async function getTermsAndConditions() {
    setTermsAndConditions(await props.service.queryTermsAndConditions());
  }

  async function checkIfBookingsRefundable(bookingIds: string[]) {
    try {
      return await props.service.queryRefundability({ bookingIds });
    } catch (e) {
      addNotification({
        text: "Unable to get cancellation policy information, please try again later",
        type: NotificationTypeEnum.Error,
      });

      return null;
    }
  }

  const paymentTotals: PaymentTotal[] = useMemo(() => {
    if (itineraryBookings.kind === "value") {
      return [...itineraryBookings.value.value.items].reduce(
        (acc: PaymentTotal[], item) => {
          if (selectedBookings.includes(item.bookingData.bookingId)) {
            acc.push(extractBookingPriceData(item));
          }
          return acc;
        },
        []
      );
    }

    return [];
  }, [selectedBookings]);

  const totalSelectedNet: number = useMemo(
    () =>
      paymentTotals.reduce((acc, { net, liveStatus, clientPaymentStatus }) => {
        return isBookingPayable(liveStatus, clientPaymentStatus)
          ? acc + net
          : acc;
      }, 0),
    [paymentTotals]
  );

  const totalSelectedGross: number = useMemo(
    () =>
      paymentTotals.reduce(
        (acc, { gross, liveStatus, clientPaymentStatus }) => {
          return isBookingPayable(liveStatus, clientPaymentStatus)
            ? acc + gross
            : acc;
        },
        0
      ),
    [paymentTotals]
  );

  const totalSelectedCommissions = useMemo(
    () => paymentTotals.reduce((acc, { commission }) => acc + commission, 0),
    [paymentTotals]
  );

  async function refreshSelectedQuotes() {
    if (itineraryBookings.kind !== "value") {
      return;
    }

    const bookingIds = (
      itineraryBookings.value.value.items as AgentBookingData[]
    )
      .filter(({ bookingData }) => bookingData.liveStatus === LiveStatus.quote)
      .map(({ bookingData }) => bookingData.bookingId)
      .filter((bookingId) => selectedBookings.includes(bookingId));

    if (!bookingIds.length) {
      addNotification({
        text: "Please select at least one quote to refresh",
        type: NotificationTypeEnum.Information,
      });

      return;
    }

    try {
      await Promise.all(bookingIds.map((id) => props.service.refreshQuote(id)));
      await loadItineraryNumber();

      addNotification({
        text: "Selected quotes have been refreshed",
        type: NotificationTypeEnum.Success,
      });

      setSelectedBookings([]);
    } catch (e) {
      addNotification({
        text: "Unable to refresh quotes, please try again later",
        type: NotificationTypeEnum.Error,
      });
    }
  }

  // TODO: enhance to support multiple quotes payment
  async function payForQuote(
    bookingId: string,
    paymentMode: PaymentMode,
    stripeCallback
  ): Promise<PayForBookingResp | undefined> {
    setIsLoading(true);

    const refreshResponse = await refreshQuote(bookingId);

    if (refreshResponse) {
      const packageDetails = refreshResponse.value;

      try {
        const response = await props.service.payForBooking({
          packageDetails,
          bookingId,
          creditCardToken: null,
          paymentOptions: { paymentMode, partial: false },
        });

        if (response.kind !== "success") {
          setIsLoading(false);

          if (response.kind === "packageNotAvailable") {
            // Return an error to show unavailability modal
            return response;
          } else {
            addNotification({
              text: PAYMENT_ERROR_TEXT[response.kind] || PAYMENT_ERROR_FALLBACK,
              type: NotificationTypeEnum.Error,
            });

            return;
          }
        }

        await stripeCallback(response.value.transactionToken);

        let iterations = 0;

        const interval = setInterval(async () => {
          iterations++;

          if (iterations > CHECK_DRAFT_STATUS_LIMIT) {
            setSelectedBookings([]);
            setQuoteToPay(null);
            setIsLoading(false);

            addNotification({
              text: "The payment has been processed, confirmation may take some time, please refresh the page",
              type: NotificationTypeEnum.Success,
            });
            clearInterval(interval);
          }

          await fetchBookings({ ignoreLoader: true });

          if (itineraryBookings.kind === "value") {
            const targetBooking = (
              itineraryBookings.value.value.items as AgentBookingData[]
            ).find(({ bookingData }) => bookingData.bookingId === bookingId);

            if (
              targetBooking &&
              targetBooking.bookingData.liveStatus !== LiveStatus.quote
            ) {
              setSelectedBookings([]);
              setQuoteToPay(null);
              setIsLoading(false);

              addNotification({
                text: "The payment has been proceeded",
                type: NotificationTypeEnum.Success,
              });
              clearInterval(interval);
            }
          }
        }, CHECK_DRAFT_STATUS_INTERVAL);
      } catch (e) {
        setIsLoading(false);

        // Stripe provides human-readable error messages, so we display them as is
        const isErrorFromStripe = !!e.code;

        addNotification({
          text: isErrorFromStripe ? e.message : PAYMENT_ERROR_FALLBACK,
          type: NotificationTypeEnum.Error,
        });
      }
    } else {
      setIsLoading(false);

      return { kind: "packageNotAvailable" };
    }
  }

  async function refreshQuote(bookingId: string) {
    try {
      const response = await props.service.refreshQuote(bookingId);

      if (response.kind === "packageDetails") {
        return response;
      } else {
        addNotification({
          text:
            QUOTE_REFRESH_RESPONSE_WARNING[response.kind] ||
            QUOTE_REFRESH_ERROR_FALLBACK,
          type: NotificationTypeEnum.Warning,
        });
      }
    } catch (e) {
      addNotification({
        text: "Unable to refresh a quote, please try again later",
        type: NotificationTypeEnum.Error,
      });
    }
  }

  const logout = () => {
    setCurrentItinerary(undefined);
    setInFlightAmendments(new Map());
    setTermsAndConditions(null);
    setComments({ kind: "loading" });
    setIsLoading(false);

    selectedItineraryNumber.current = undefined;
    paymentType.current = PaymentType.CreditCardPayment;
  };

  async function payForBookAndHold(
    bookingId: string,
    paymentMode: PaymentMode,
    stripeCallback
  ): Promise<PayForBookingResp | undefined> {
    setIsLoading(true);

    try {
      const response = await props.service.payForBooking({
        packageDetails: null,
        bookingId,
        creditCardToken: null,
        paymentOptions: { paymentMode, partial: false },
      });

      if (response.kind !== "success") {
        setIsLoading(false);

        if (response.kind === "packageNotAvailable") {
          // Return an error to show unavailability modal
          return response;
        } else {
          addNotification({
            text: PAYMENT_ERROR_TEXT[response.kind] || PAYMENT_ERROR_FALLBACK,
            type: NotificationTypeEnum.Error,
          });

          return;
        }
      }

      await stripeCallback(response.value.transactionToken);

      let iterations = 0;

      const interval = setInterval(async () => {
        iterations++;

        if (iterations > CHECK_DRAFT_STATUS_LIMIT) {
          setSelectedBookings([]);
          setQuoteToPay(null);
          setIsLoading(false);

          addNotification({
            text: "Payment successful, confirmation may take some time, please refresh the page",
            type: NotificationTypeEnum.Success,
          });
          clearInterval(interval);
        }

        const updatedBookings = await fetchBookings({ ignoreLoader: true });

        if (updatedBookings) {
          const targetBooking = (
            updatedBookings.value.items as AgentBookingData[]
          ).find(({ bookingData }) => bookingData.bookingId === bookingId);

          if (
            targetBooking &&
            targetBooking.bookingData.clientPaymentStatus !==
              ClientPaymentStatus.unpaid
          ) {
            setSelectedBookings([]);
            setQuoteToPay(null);
            setIsLoading(false);

            addNotification({
              text: "Payment successful",
              type: NotificationTypeEnum.Success,
            });
            clearInterval(interval);
          }
        }
      }, CHECK_DRAFT_STATUS_INTERVAL);
    } catch (e) {
      setIsLoading(false);

      // Stripe provides human-readable error messages, so we display them as is
      const isErrorFromStripe = !!e.code;

      addNotification({
        text: isErrorFromStripe ? e.message : PAYMENT_ERROR_FALLBACK,
        type: NotificationTypeEnum.Error,
      });
    }
  }

  return {
    searchResult: itineraryBookings,
    loadItineraryNumber,
    currentItinerary,
    setCurrentItinerary,
    createItinerary,
    checkIfItineraryCanBeLoaded,
    inFlightAmendments,
    cancelBooking,
    paymentType,
    selectPaymentType,
    generateBookingKey,
    toggleBookingSelection,
    selectedBookings,
    fetchBookings,
    isLoading,
    termsAndConditions,
    getTermsAndConditions,
    comments,
    fetchComments,
    addComment,
    paymentTotals,
    totalSelectedNet,
    totalSelectedGross,
    totalSelectedCommissions,
    checkIfBookingsRefundable,
    refreshSelectedQuotes,
    payForQuote,
    quoteToPay,
    setQuoteToPay,
    logout,
    payForBookAndHold,
  };
};
