import React, { useMemo } from "react";

import { WarningOutlinedIcon } from "@assets/svg-components";
import MultiSearch from "@widgets/multi-search";
import Tooltip from "@widgets/tooltip";

import styles from "./styles.css";

const DashboardQuickSearch = () => {
  return (
    <div className={styles.dashboardQuickSearch}>
      <div className={styles.title}>
        <h3>Booking search</h3>
        <Tooltip text={<Hint />} classes={{ tooltip: styles.hint }}>
          <WarningOutlinedIcon />
        </Tooltip>
      </div>

      <MultiSearch />
    </div>
  );
};

export default DashboardQuickSearch;

const Hint = () => {
  const currentYear = useMemo(() => {
    return new Date().getFullYear();
  }, []);

  return (
    <pre>
      <span>Keyword options:</span>
      <ol>
        <li>Itinerary number (starts with IT)</li>
        <li>Booking number (starts with BO)</li>
        <li>Check-in date (i.e. 31/01/{currentYear})</li>
        <li>Guest last name</li>
      </ol>
    </pre>
  );
};
