import { SimpleCancellationPolicy } from "@adl-gen/hotel/api";
import { Board } from "@adl-gen/hotel/booking";
import { PriceValue } from "@adl-gen/hotel/common";

import { RoomRate } from "./room-rate-item";

// Finds the cheapest rate for a room
export const findDefaultRateForRoom = (rates: RoomRate[]): RoomRate => {
  const prices = rates.map(({ roomPackage }) =>
    parseFloat(roomPackage.price.value)
  );

  const minPrice = Math.min(...prices);

  return rates.find(
    ({ roomPackage }) =>
      roomPackage.price.value === minPrice.toFixed(2).toString()
  ) as RoomRate;
};

// Finds list of unique cancellation options from the rates list
export const extractCancellationOptionsFromRates = (
  rates: RoomRate[],
  defaultRate: RoomRate
) => {
  let noCancellationFound = false;
  let freeCancellationFound = false;
  let freeCancellationUntilFound = false;

  let result: {
    policy: SimpleCancellationPolicy;
    additionalPrice: PriceValue | null;
  }[] = [];

  rates.forEach((rate) => {
    const currency = rate.roomPackage.price.currency;
    const additionalPrice: PriceValue | null =
      result.length === 0
        ? { value: "0", currency }
        : getRatesPriceDifference(defaultRate, rate);
    const optionValue = {
      policy: rate.roomPackage.cancellationPolicy,
      additionalPrice,
    };
    switch (rate.roomPackage.cancellationPolicy.kind) {
      case "noCancellation": {
        if (!noCancellationFound) {
          result.push(optionValue);
          noCancellationFound = true;
        }
        break;
      }
      case "freeCancellation": {
        if (!freeCancellationFound) {
          result.push(optionValue);
          freeCancellationFound = true;
        }
        break;
      }
      case "freeCancellationUntil": {
        if (!freeCancellationUntilFound) {
          result.push(optionValue);
          freeCancellationUntilFound = true;
        }
        break;
      }
      default:
        return;
    }
  });

  // If there is only one cancellation option, we don't need to display price difference
  if (result.length === 1) {
    result = result.map((option) => ({ ...option, additionalPrice: null }));
  }

  return result.sort((v1, v2) => v2.policy.kind.localeCompare(v1.policy.kind));
};

// Finds list of unique board options from the rates list
export const extractBoardOptionsFromRates = (rates: RoomRate[]): Board[] => {
  const uniqueOptionsSet = new Set(
    rates.map(({ roomPackage }) => roomPackage.board)
  );

  return Array.from(uniqueOptionsSet).sort((v1, v2) => v1 - v2);
};

export const CANCELLATION_DATE_DISPLAY_FORMAT: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
};

export const CANCELLATION_DATE_LOCALE: string = "en-AU";

export function renderPriceDiffString(price: PriceValue | null) {
  if (price === null) {
    return "";
  }

  return `+ ${price.value} ${price.currency}`;
}

export function getRatesPriceDifference(
  rateA: RoomRate,
  rateB: RoomRate
): { value: string; currency: string } {
  const priceA = parseFloat(rateA.roomPackage.price.value);
  const priceB = parseFloat(rateB.roomPackage.price.value);
  return {
    value: (Math.ceil(priceB) - Math.ceil(priceA)).toString(),
    currency: rateA.roomPackage.price.currency,
  };
}
