import clsx from "clsx";
import React, { FC, memo, useEffect, useState } from "react";

import { CloseUpIcon } from "@common/icon/icons";
import { NOTIFICATION_TIMEOUT_MS } from "@constants/common";
import { NotificationType } from "@models/common";

import { NotificationIcons } from "./constants";
import styles from "./styles.css";

interface PropTypes {
  notification: NotificationType;
  onClose(id: string): void;
}

const Notification: FC<PropTypes> = ({ notification, onClose }) => {
  const { text, type, id } = notification;
  const [isVisible, setIsVisible] = useState(true);

  const timer = setTimeout(() => setIsVisible(false), NOTIFICATION_TIMEOUT_MS);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  function closeNotification() {
    clearTimeout(timer);
    onClose(id);
  }

  return (
    <div
      style={{ opacity: isVisible ? 1 : 0 }}
      className={clsx(styles.notification, styles[type])}
      onTransitionEnd={() => onClose(id)}
    >
      <div className={styles.notificationIcon}>{NotificationIcons[type]}</div>
      <div className={styles.notificationText}>{text}</div>

      <button className={styles.closeBtn} onClick={closeNotification}>
        <CloseUpIcon />
      </button>
    </div>
  );
};

export default memo(Notification);
