import clsx from "clsx";
import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import { BigDecimal } from "@adl-gen/common";
import { HotelSearchSortOrder } from "@adl-gen/hotel/api";
import { GeneralContext, HotelSearchContext, LoggedInContext } from "@app/app";
import { EyeIcon, HideIcon } from "@common/icon/icons";
import { MAX_AGENT_COMMISSION } from "@constants/booking";
import { HotelViewEnum } from "@controllers/customHooks/useHotelView";
import { FILTER_PARAM } from "@controllers/hotel-search/constant";
import { PackageListingController } from "@controllers/hotel-search/hotel-search-controller";
import { PackageParams } from "@controllers/hotel-search/types";
import { assertNever, assertNotUndefined } from "@hx/util/types";
import { NotificationTypeEnum } from "@models/common";
import { Loading, mapLoading } from "@models/loading";
import { FilterPanel } from "@pages/hotel-search/filter-panel/filter-panel";
import { HotelListingType } from "@pages/hotel-search/types";
import { InputSelect, InputSelectOption } from "@widgets/input-select";
import { InputText } from "@widgets/input-text/input-text";
import { BreadCrumbType } from "@widgets/page/header-breadcrumbs/types";
import { Page } from "@widgets/page/page";
import { PageSection } from "@widgets/page/page-section";
import { RoomRateListing } from "@widgets/room-rate-listing/room-rate-listing";
import { SearchBar } from "@widgets/search-bar/search-bar";
import { Skeleton } from "@widgets/skeleton/skeleton";

import HotelResultsLayout from "./hotel-results";
import styles from "./hotel-search-page.css";
import MapListViewButton from "./sort-bar/map-list-view-button";

const ENTER_KEYCODE = "Enter";

export const HotelSearchPage = () => {
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );
  const itineraryController = assertNotUndefined(
    useContext(LoggedInContext).itineraryController
  );
  const location = useLocation();

  const [filtersCollapsed, setFiltersCollapsed] = useState(false);

  useEffect(() => {
    // In case user is redirected to this page via hitting "Search" button
    // we should skip initializing new search
    if (hotelSearchController.hotelListingResultDetails?.kind === "value") {
      return;
    }
    // If the URL provides enough detail to start a hotel search on first load, then do it
    hotelSearchController.checkIfSearchCanBeStarted().then(() => {
      const priceToken = hotelSearchController.getCurrentPriceToken();
      if (window.location.search.includes(FILTER_PARAM) && priceToken) {
        hotelSearchController.updateExistingSearch();
      }
    });
    itineraryController.checkIfItineraryCanBeLoaded();
  }, []);

  useEffect(() => {
    if (location.search.length === 0) {
      // We must have gone back to /search
      hotelSearchController.checkIfSearchCanBeStarted();
    }
  }, [location]);

  const hotels: Loading<HotelListingType> | undefined =
    hotelSearchController.hotelListingResultDetails &&
    mapLoading(
      hotelSearchController.hotelListingResultDetails,
      (details): HotelListingType => ({ details })
    );

  const hotelsLoaded = hotels && hotels.kind === "value";

  return (
    <Page>
      <PageSection className={styles.searchBarContainer}>
        <SearchBar />
      </PageSection>
      <PageSection
        main
        className={clsx(
          styles.searchResults,
          filtersCollapsed && styles.filtersCollapsed,
          hotelSearchController.hotelView === HotelViewEnum.MapView &&
            styles.filtersCollapsible
        )}
        classes={styles}
      >
        {hotelsLoaded ? (
          <>
            <FilterPanel
              collapsed={filtersCollapsed}
              onChangeCollapsed={setFiltersCollapsed}
            />

            <div className={clsx(styles.topBar)}>
              <SortOptions
                value={hotelSearchController.sortOrder}
                onChange={hotelSearchController.changeSortOrder}
              />
              <CommissionInput />
              <MapListViewButton
                currentView={hotelSearchController.hotelView}
                changeView={hotelSearchController.changeView}
              />
            </div>
          </>
        ) : null}

        {hotels ? <HotelResultsLayout hotelsData={hotels} /> : null}
      </PageSection>
    </Page>
  );
};

const CommissionInput = () => {
  const hotelSearchController = assertNotUndefined(
    useContext(HotelSearchContext)
  );

  const { sensitiveDataHidden } = assertNotUndefined(
    useContext(LoggedInContext).identityController
  );

  const { addNotification } = assertNotUndefined(
    useContext(GeneralContext).notificationController
  );

  const [isCommissionHidden, setIsCommissionHidden] = useState(true);

  const showCommission = useCallback(() => setIsCommissionHidden(false), []);
  const hideCommission = useCallback(() => setIsCommissionHidden(true), []);

  const [commission, setCommission] = useState<BigDecimal | undefined>(
    hotelSearchController.commission
  );

  useEffect(() => {
    if (commission !== hotelSearchController.commission) {
      setCommission(hotelSearchController.commission);
    }
  }, [hotelSearchController.commission]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCommission(event.currentTarget.value);
  };

  function onBlurCommission(e: FocusEvent<HTMLInputElement>) {
    const value = e.target.value;
    if (value) {
      const number = +value;

      let finalValue = number;
      let notificationText = "";

      if (!Number.isInteger(number)) {
        const integerNumber = Math.ceil(number);
        if (integerNumber <= MAX_AGENT_COMMISSION) {
          finalValue = integerNumber;
          notificationText = `Commission must be a whole number`;
        }
      }

      if (number < 0) {
        finalValue = +hotelSearchController.commission;
        notificationText = `Commission must be a positive number`;
      }

      if (number > MAX_AGENT_COMMISSION) {
        finalValue = MAX_AGENT_COMMISSION;
        notificationText = `Maximum commission is ${MAX_AGENT_COMMISSION}%`;
      }

      hotelSearchController.setCommission(finalValue.toString());
      // Setting local state to avoid unexpected behavior in edge cases
      setCommission(finalValue.toString());

      if (notificationText) {
        addNotification({
          type: NotificationTypeEnum.Information,
          text: notificationText,
        });
      }
    } else {
      // Restore the previous value if user submits the empty input
      setCommission(hotelSearchController.commission);
    }
  }

  function checkEnterClick(e: KeyboardEvent<HTMLInputElement>) {
    if (
      e.key === ENTER_KEYCODE &&
      hotelSearchController.commission !== commission
    ) {
      e.currentTarget.blur();
    }
  }

  return (
    <Skeleton className={styles.commission}>
      {isCommissionHidden || sensitiveDataHidden ? (
        <div
          className={styles.commissionText}
          title="Change YouCom"
          onClick={showCommission}
        >
          YouCom
          <EyeIcon className={styles.commissionIcon} />
        </div>
      ) : (
        hotelSearchController.commission && (
          <div className={styles.commissionInput}>
            <InputText
              label
              type="number"
              className={styles.commission}
              placeholder="YouCom"
              value={commission}
              onChange={handleChange}
              onBlur={onBlurCommission}
              onKeyUp={checkEnterClick}
              min={0}
            />
            <div
              className={styles.commissionIcon}
              onClick={hideCommission}
              title="Hide YouCom"
            >
              <HideIcon />
            </div>
          </div>
        )
      )}
    </Skeleton>
  );
};

const SortOptions = ({ value, onChange }) => {
  const OPTIONS = [
    {
      key: HotelSearchSortOrder.popularity,
      value: "Recommended",
      label: "Recommended",
    },
    {
      key: HotelSearchSortOrder.distance,
      value: "Distance",
      label: "Distance",
    },
  ];

  const selectOption = useCallback(
    (option: string) => {
      const selectedOption =
        OPTIONS.find((item) => item.value === option) || OPTIONS[0];
      onChange(selectedOption.key);
    },
    [onChange]
  );

  const defaultValue = useMemo(() => {
    const findValue = OPTIONS.find(({ key }) => key === value);
    return findValue?.value || OPTIONS[0].value;
  }, [value]);

  return (
    <InputSelect
      classes={{ inputText: styles.inputTextReadOnly }}
      open={false}
      readOnly
      placeholder="Sort by"
      value={defaultValue}
      onChange={selectOption}
    >
      {OPTIONS.map((option) => (
        <InputSelectOption key={option.key} value={option.value}>
          {option.label}
        </InputSelectOption>
      ))}
    </InputSelect>
  );
};

interface AvailableHotelPackagesProps extends PackageListingController {
  collapsed: boolean;
}

export const AvailableHotelPackages: FC<AvailableHotelPackagesProps> = ({
  packages,
  onSelectPackage,
  collapsed,
}) => {
  const packageList = packages.kind === "value" ? packages.value : undefined;

  function onSelect(packageParams: PackageParams) {
    onSelectPackage({ ...packageParams, selectedFrom: BreadCrumbType.Results });
  }

  if (packages.kind === "value" || packages.kind === "loading") {
    return (
      <RoomRateListing
        kind={"listingInfo"}
        roomPackageList={packageList}
        onSelectPackage={onSelect}
        collapsed={collapsed}
        fullWidth={false}
      />
    );
  } else if (packages.kind === "error") {
    return <div>props.error</div>;
  } else {
    assertNever(packages);
  }
};
