import { Paginated, Unit } from "@adl-gen/common";
import { MetaAdlDecl } from "@adl-gen/common/adminui/db";
import { DbKey, WithDbId } from "@adl-gen/common/db";
import { HttpGet, HttpGet2, HttpPost } from "@adl-gen/common/http";
import { TableQuery } from "@adl-gen/common/tabular";
import {
  BookingSearchShortcutCountResp,
  BookingSearchShortcutQuery,
  ConfirmInviteAgentUserReq,
  ConfirmInviteAgentUserResp,
  CreateAgencyResp,
  ItineraryCommentCreateReq,
  ItineraryCommentData,
  ItineraryCommentsQuery,
  PaymentClientConfigResp,
  RefreshBookingResp,
  UserInviteReq,
  UserInviteResp,
  ValidateInviteAgentUserReq,
  ValidateInviteAgentUserResp,
} from "@adl-gen/hotel/api";
import { Agency, Booking, TermsAndConditions } from "@adl-gen/hotel/db";
import { Hotel } from "@adl-gen/ids/db";
import {
  HotelImageReq,
  HotelImageResp,
  LocationInfoResp,
  LocationSearchReq,
  LocationSearchResp,
  LocationType,
  LocationType_Poi,
  LocationType_Region,
  PoiNearHotel,
} from "@adl-gen/ids/externalapi";
import { ATypeExpr, DeclResolver } from "@adl-gen/runtime/adl";
import {
  createJsonBinding,
  getAnnotation,
  JsonBinding,
} from "@adl-gen/runtime/json";
import { HttpFetch, HttpOptions, HttpRequest } from "@hx/hx/service/http";

import * as AR from "../adl-gen/common/adminui/api";
import * as R from "../adl-gen/hotel/api";
import * as DB from "../adl-gen/hotel/db";
import * as adlast from "../adl-gen/sys/adlast";
import { HttpServiceError } from "./http-service-error";
import { Service } from "./service";
import { TokenManager } from "./token-manager";

/**
 * Combines a async function to make a get request
 * along with the metadata required to plug that request
 * into an admin ui
 */
interface GetFn<O> {
  description(): string;
  rtype: HttpGet<O>;
  call(options?: HttpOptions): Promise<O>;
}

interface Get2Fn<T, O> {
  description(): string;
  rtype: HttpGet2<T, O>;
  call(req: T, options?: HttpOptions): Promise<O>;
}

/**
 * Combines a async function to make a post request
 * along with the metadata required to plug that request
 * into an admin ui
 */
interface PostFn<I, O> {
  description(): string;
  rtype: HttpPost<I, O>;
  call(req: I, options?: HttpOptions): Promise<O>;
}

interface RequestADL<T> extends HttpOptions {
  method: "get" | "post";
  path: string;
  jsonArgs: {} | null;
  respJB: JsonBinding<T>;
  /** Publicly consumable action of the request for error alerting purposes */
  actionName: string;
}

interface PostADL<I, O> extends HttpOptions {
  path: string;
  post: BiBinding<I, O>;
  req: I;
  actionName: string;
  raw?: boolean;
}

interface GetADL<T> extends HttpOptions {
  path: string;
  respJB: JsonBinding<T>;
  actionName: string;
}

interface Get2ADL<P, O> extends HttpOptions {
  initPath: string;
  reqJB: JsonBinding<P>;
  respJB: JsonBinding<O>;
  req: P;
  actionName: string;
}

/**
 * The agentus backend service.
 */
export class HttpService implements Service {
  postLogin: PostFn<R.LoginReq, R.LoginResp>;
  getWhoAmI: GetFn<R.UserProfile>;
  postCreateUser: PostFn<R.UserReq, R.CreateUserResp>;
  postUpdateUser: PostFn<WithDbId<R.UserReq>, Unit>;
  postDeleteUser: PostFn<DbKey<DB.AppUser>, Unit>;
  postUpdateUserPassword: PostFn<
    R.UpdateUserPasswordReq,
    R.UpdateUserPasswordResp
  >;
  postQueryUsers: PostFn<TableQuery, Paginated<WithDbId<R.AppUserDetails>>>;
  postCreateAgentUser: PostFn<R.CreateAgentUserReq, R.CreateAgentUserResp>;
  postCreateAgency: PostFn<Agency, CreateAgencyResp>;
  postUpdateAgency: PostFn<WithDbId<DB.Agency>, R.UpdateAgencyResp>;
  postGetAgency: PostFn<DbKey<DB.Agency>, DB.Agency>;
  postQueryAgencies: PostFn<TableQuery, Paginated<WithDbId<Agency>>>;
  postConfirmInviteAgentUser: PostFn<
    ConfirmInviteAgentUserReq,
    ConfirmInviteAgentUserResp
  >;
  postValidateInviteAgentUser: PostFn<
    ValidateInviteAgentUserReq,
    ValidateInviteAgentUserResp
  >;
  postInviteUser: PostFn<UserInviteReq, UserInviteResp>;

  postQueryLeadingPrices: PostFn<R.LeadingPriceQuery, R.LeadingPriceQueryResp>;
  postQueryHotels: PostFn<R.AgentHotelSearchQuery, R.AgentHotelSearchResp>;
  postQueryNearbyPoisToHotel: PostFn<DbKey<Hotel>, PoiNearHotel[]>;
  postSearchLocations: PostFn<LocationSearchReq, LocationSearchResp>;
  postQueryLocationInfo: PostFn<LocationType, LocationInfoResp>;
  postQueryAvailablePackages: PostFn<
    R.AvailablePackagesQuery,
    R.AvailablePackagesResp
  >;

  postQueryBookings: PostFn<R.BookingSearchQuery, R.BookingSearchQueryResp>;

  postQueryRefundability: PostFn<
    R.BookingRefundabilityReq,
    R.BookingRefundabilityResp
  >;

  // Admin services
  postAdminQueryTables: PostFn<TableQuery, Paginated<AR.Table>>;
  postAdminQueryDecls: PostFn<TableQuery, Paginated<MetaAdlDecl>>;
  postAdminCreate: PostFn<AR.CreateReq, AR.DbResult<DbKey<AR.DbRow>>>;
  postAdminQuery: PostFn<AR.QueryReq, Paginated<WithDbId<AR.DbRow>>>;
  postAdminUpdate: PostFn<AR.UpdateReq, AR.DbResult<Unit>>;
  postAdminDelete: PostFn<AR.DeleteReq, AR.DbResult<Unit>>;
  postAdminDbKeyLabels: PostFn<AR.DbKeyLabelReq[], AR.DbKeyLabelResp[]>;
  postQueryCancellationPolicies: PostFn<
    R.CancellationPoliciesQuery,
    R.CancellationPoliciesResp
  >;
  postQueryPaymentDetails: PostFn<R.PaymentDetailsReq, R.PaymentDetailsResp>;
  getPaymentClientConfig: GetFn<PaymentClientConfigResp>;
  postConfirmBooking: PostFn<R.ConfirmBookingReq, R.ConfirmBookingResp>;
  postCancelBooking: PostFn<DbKey<DB.Booking>, R.CancelBookingResp>;
  postCreateItinerary: PostFn<R.CreateItineraryReq, R.CreateItineraryResp>;
  postCreateQuote: PostFn<R.CreateBookingReq, R.CreateBookingResp>;
  postCreateDraft: PostFn<R.CreateBookingReq, R.CreateBookingResp>;
  postGetItineraryReq: PostFn<R.GetItineraryReq, R.GetItineraryResp>;
  postPayForBookingReq: PostFn<R.PayForBookingReq, R.PayForBookingResp>;
  postQueryTermsAndConditions: PostFn<
    DbKey<TermsAndConditions>,
    TermsAndConditions
  >;
  getQueryReports: Get2Fn<R.GetReportReq, R.GetReportResp>;
  postQueryHotelImages: PostFn<HotelImageReq, HotelImageResp>;

  postQueryItineraryComments: PostFn<
    ItineraryCommentsQuery,
    Paginated<ItineraryCommentData>
  >;

  postCreateItineraryComment: PostFn<
    ItineraryCommentCreateReq,
    ItineraryCommentData
  >;

  postCountBookingShortcuts: PostFn<
    BookingSearchShortcutQuery,
    BookingSearchShortcutCountResp
  >;

  postRefreshQuote: PostFn<DbKey<Booking>, RefreshBookingResp>;

  constructor(
    /** Fetcher over HTTP */
    private readonly http: HttpFetch,
    /** Base URL of the API endpoints */
    private readonly baseUrl: string,
    /** Resolver for ADL types */
    private readonly resolver: DeclResolver,
    /** Token manager storing the authentication tokens */
    private readonly tokenManager: TokenManager,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    private readonly handleError: (error: HttpServiceError) => void
  ) {
    const api = annotatedApi(
      this.resolver,
      R.snApiRequests,
      R.makeApiRequests({})
    );
    this.postLogin = this.mkPostFn(api.login);
    this.getWhoAmI = this.mkGetFn(api.whoAmI);
    this.postCreateUser = this.mkPostFn(api.createUser);
    this.postUpdateUser = this.mkPostFn(api.updateUser);
    this.postDeleteUser = this.mkPostFn(api.deleteUser);
    this.postUpdateUserPassword = this.mkPostFn(api.updateUserPassword);
    this.postQueryUsers = this.mkPostFn(api.queryUsers);
    this.postCreateAgentUser = this.mkPostFn(api.createAgentUser);
    this.postCreateAgency = this.mkPostFn(api.createAgency);
    this.postUpdateAgency = this.mkPostFn(api.updateAgency);
    this.postGetAgency = this.mkPostFn(api.getAgency);
    this.postQueryAgencies = this.mkPostFn(api.queryAgencies);
    this.postConfirmInviteAgentUser = this.mkPostFn(api.confirmInviteAgentUser);
    this.postValidateInviteAgentUser = this.mkPostFn(
      api.validateInviteAgentUser
    );
    this.postInviteUser = this.mkPostFn(api.inviteUser);

    this.postQueryLeadingPrices = this.mkPostFn(api.queryLeadingPrices);
    this.postQueryHotels = this.mkPostFn(api.queryHotels);
    this.postQueryNearbyPoisToHotel = this.mkPostFn(api.queryNearbyPoisToHotel);
    this.postSearchLocations = this.mkPostFn(api.searchLocations);
    this.postQueryLocationInfo = this.mkPostFn(api.queryLocationInfo);
    this.postQueryAvailablePackages = this.mkPostFn(api.queryAvailablePackages);
    this.postQueryBookings = this.mkPostFn(api.queryBookings);
    this.postQueryRefundability = this.mkPostFn(api.queryBookingRefundability);
    this.postQueryCancellationPolicies = this.mkPostFn(
      api.queryCancellationPolicies
    );
    this.postQueryPaymentDetails = this.mkPostFn(api.queryPaymentDetails);
    this.postConfirmBooking = this.mkPostFn(api.confirmBooking);
    this.postCancelBooking = this.mkPostFn(api.cancelBooking);
    this.postCreateItinerary = this.mkPostFn(api.createItinerary);
    this.postCreateQuote = this.mkPostFn(api.createBookingQuote);
    this.postCreateDraft = this.mkPostFn(api.createDraftBooking);
    this.postGetItineraryReq = this.mkPostFn(api.getItineraryByNumber);
    this.postPayForBookingReq = this.mkPostFn(api.payForBooking);
    this.getPaymentClientConfig = this.mkGetFn(api.queryPaymentClientConfig);
    this.postQueryTermsAndConditions = this.mkPostFn(
      api.queryTermsAndConditions,
      true
    );
    this.getQueryReports = this.mkGet2Fn(api.queryReports);
    this.postQueryHotelImages = this.mkPostFn(api.queryHotelImages);

    const adminApi = annotatedApi(
      this.resolver,
      AR.snAdminApiRequests,
      AR.makeAdminApiRequests({})
    );
    this.postAdminQueryTables = this.mkPostFn(adminApi.queryTables);
    this.postAdminQueryDecls = this.mkPostFn(adminApi.queryDecls);
    this.postAdminCreate = this.mkPostFn(adminApi.create);
    this.postAdminQuery = this.mkPostFn(adminApi.query);
    this.postAdminUpdate = this.mkPostFn(adminApi.update);
    this.postAdminDelete = this.mkPostFn(adminApi.delete);
    this.postAdminDbKeyLabels = this.mkPostFn(adminApi.dbKeyLabels);

    this.postQueryItineraryComments = this.mkPostFn(api.itineraryCommentsQuery);
    this.postCreateItineraryComment = this.mkPostFn(api.itineraryCommentCreate);

    this.postCountBookingShortcuts = this.mkPostFn(api.countBookingShortcuts);

    this.postRefreshQuote = this.mkPostFn(api.refreshBookingQuote);
  }

  async login(req: R.LoginReq, options?: HttpOptions): Promise<R.LoginResp> {
    return this.postLogin.call(req, options);
  }

  async whoami(options?: HttpOptions): Promise<R.UserProfile> {
    return this.getWhoAmI.call(options);
  }

  async createUser(
    req: R.UserReq,
    options?: HttpOptions
  ): Promise<R.CreateUserResp> {
    return this.postCreateUser.call(req, options);
  }

  async updateUser(
    req: WithDbId<R.UserReq>,
    options?: HttpOptions
  ): Promise<void> {
    await this.postUpdateUser.call(req, options);
  }

  async deleteUser(
    req: DbKey<R.UserReq>,
    options?: HttpOptions
  ): Promise<void> {
    await this.postDeleteUser.call(req, options);
  }

  async updateUserPassword(
    req: R.UpdateUserPasswordReq,
    options?: HttpOptions
  ): Promise<R.UpdateUserPasswordResp> {
    return this.postUpdateUserPassword.call(req, options);
  }

  async queryUsers(
    req: TableQuery,
    options?: HttpOptions
  ): Promise<Paginated<WithDbId<R.AppUserDetails>>> {
    return this.postQueryUsers.call(req, options);
  }

  async confirmInviteAgentUser(
    req: ConfirmInviteAgentUserReq,
    options?: HttpOptions
  ): Promise<ConfirmInviteAgentUserResp> {
    return this.postConfirmInviteAgentUser.call(req, options);
  }

  async validateInviteAgentUser(
    req: ValidateInviteAgentUserReq,
    options?: HttpOptions
  ): Promise<ValidateInviteAgentUserResp> {
    return this.postValidateInviteAgentUser.call(req, options);
  }

  async inviteUser(
    req: UserInviteReq,
    options?: HttpOptions
  ): Promise<UserInviteResp> {
    return this.postInviteUser.call(req, options);
  }

  async queryHotels(
    req: R.AgentHotelSearchQuery,
    options?: HttpOptions
  ): Promise<R.AgentHotelSearchResp> {
    return this.postQueryHotels.call(req, options);
  }

  async queryNearbyPoisToHotel(
    hotelId: DbKey<Hotel>,
    options?: HttpOptions
  ): Promise<PoiNearHotel[]> {
    return this.postQueryNearbyPoisToHotel.call(hotelId, options);
  }

  async queryLeadingPrices(
    req: R.LeadingPriceQuery,
    options?: HttpOptions
  ): Promise<R.LeadingPriceQueryResp> {
    return this.postQueryLeadingPrices.call(req, options);
  }

  async queryLocationInfo(
    req: LocationType_Region | LocationType_Poi,
    options?: HttpOptions
  ): Promise<LocationInfoResp> {
    return this.postQueryLocationInfo.call(req, options);
  }

  async searchLocations(
    req: LocationSearchReq,
    options?: HttpOptions
  ): Promise<LocationSearchResp> {
    return this.postSearchLocations.call(req, options);
  }

  async queryBookings(
    req: R.BookingSearchQuery,
    options?: HttpOptions
  ): Promise<R.BookingSearchQueryResp> {
    return this.postQueryBookings.call(req, options);
  }

  async queryRefundability(
    req: R.BookingRefundabilityReq,
    options?: HttpOptions
  ): Promise<R.BookingRefundabilityResp> {
    return this.postQueryRefundability.call(req, options);
  }

  async queryTermsAndConditions(id?: string, options?: HttpOptions) {
    return this.postQueryTermsAndConditions.call(id || "", options);
  }

  async queryReports(
    req: R.GetReportReq,
    options?: HttpOptions
  ): Promise<R.GetReportResp> {
    return this.getQueryReports.call(req, options);
  }

  async queryHotelImages(
    req: HotelImageReq,
    options?: HttpOptions
  ): Promise<HotelImageResp> {
    return this.postQueryHotelImages.call(req, options);
  }

  async queryItineraryComments(
    req: ItineraryCommentsQuery,
    options?: HttpOptions
  ): Promise<Paginated<ItineraryCommentData>> {
    return this.postQueryItineraryComments.call(req, options);
  }

  async createItineraryComment(
    req: ItineraryCommentCreateReq,
    options?: HttpOptions
  ): Promise<ItineraryCommentData> {
    return this.postCreateItineraryComment.call(req, options);
  }

  async createAgentUser(
    req: R.CreateAgentUserReq,
    options?: HttpOptions
  ): Promise<R.CreateAgentUserResp> {
    return this.postCreateAgentUser.call(req, options);
  }

  async createAgency(
    req: Agency,
    options?: HttpOptions
  ): Promise<CreateAgencyResp> {
    return this.postCreateAgency.call(req, options);
  }

  async updateAgency(
    req: WithDbId<DB.Agency>,
    options?: HttpOptions
  ): Promise<R.UpdateAgencyResp> {
    return this.postUpdateAgency.call(req, options);
  }

  async getAgency(
    req: DbKey<DB.Agency>,
    options?: HttpOptions
  ): Promise<DB.Agency> {
    return this.postGetAgency.call(req, options);
  }

  async queryAgencies(
    req: TableQuery,
    options?: HttpOptions
  ): Promise<Paginated<WithDbId<Agency>>> {
    return this.postQueryAgencies.call(req, options);
  }

  async adminQueryTables(
    req: TableQuery,
    options?: HttpOptions
  ): Promise<Paginated<AR.Table>> {
    return this.postAdminQueryTables.call(req, options);
  }

  async adminQueryDecls(
    req: TableQuery,
    options?: HttpOptions
  ): Promise<Paginated<MetaAdlDecl>> {
    return this.postAdminQueryDecls.call(req, options);
  }

  async adminCreate(
    req: AR.CreateReq,
    options?: HttpOptions
  ): Promise<AR.DbResult<DbKey<AR.DbRow>>> {
    return this.postAdminCreate.call(req, options);
  }

  async adminQuery(
    req: AR.QueryReq,
    options?: HttpOptions
  ): Promise<Paginated<WithDbId<AR.DbRow>>> {
    return this.postAdminQuery.call(req, options);
  }

  async adminUpdate(
    req: AR.UpdateReq,
    options?: HttpOptions
  ): Promise<AR.DbResult<Unit>> {
    return this.postAdminUpdate.call(req, options);
  }

  async adminDelete(
    req: AR.DeleteReq,
    options?: HttpOptions
  ): Promise<AR.DbResult<Unit>> {
    return this.postAdminDelete.call(req, options);
  }

  async adminDbKeyLabels(
    req: AR.DbKeyLabelReq[],
    options?: HttpOptions
  ): Promise<AR.DbKeyLabelResp[]> {
    return this.postAdminDbKeyLabels.call(req, options);
  }

  async queryAvailablePackages(
    req: R.AvailablePackagesQuery,
    options?: HttpOptions
  ): Promise<R.AvailablePackagesResp> {
    return this.postQueryAvailablePackages.call(req, options);
  }

  cancelBooking(
    req: DbKey<DB.Booking>,
    options?: HttpOptions
  ): Promise<R.CancelBookingResp> {
    return this.postCancelBooking.call(req, options);
  }

  confirmBooking(
    req: R.ConfirmBookingReq,
    options?: HttpOptions
  ): Promise<R.ConfirmBookingResp> {
    return this.postConfirmBooking.call(req, options);
  }

  queryCancellationPolicies(
    req: R.CancellationPoliciesQuery,
    options?: HttpOptions
  ): Promise<R.CancellationPoliciesResp> {
    return this.postQueryCancellationPolicies.call(req, options);
  }

  queryPaymentDetails(
    req: R.PaymentDetailsReq,
    options?: HttpOptions
  ): Promise<R.PaymentDetailsResp> {
    return this.postQueryPaymentDetails.call(req, options);
  }

  queryPaymentClientConfig(
    options?: HttpOptions
  ): Promise<PaymentClientConfigResp> {
    return this.getPaymentClientConfig.call(options);
  }

  createItinerary(
    req: R.CreateItineraryReq,
    options?: HttpOptions
  ): Promise<R.CreateItineraryResp> {
    return this.postCreateItinerary.call(req, options);
  }

  createBookingQuote(
    req: R.CreateBookingReq,
    options?: HttpOptions
  ): Promise<R.CreateBookingResp> {
    return this.postCreateQuote.call(req, options);
  }

  createDraftBooking(
    req: R.CreateBookingReq,
    options?: HttpOptions
  ): Promise<R.CreateBookingResp> {
    return this.postCreateDraft.call(req, options);
  }

  getItinerary(
    req: R.GetItineraryReq,
    options?: HttpOptions
  ): Promise<R.GetItineraryResp> {
    return this.postGetItineraryReq.call(req, options);
  }

  payForBooking(
    req: R.PayForBookingReq,
    options?: HttpOptions
  ): Promise<R.PayForBookingResp> {
    return this.postPayForBookingReq.call(req, options);
  }

  countBookingShortcuts(
    req: BookingSearchShortcutQuery,
    options?: HttpOptions
  ): Promise<R.BookingSearchShortcutCountResp> {
    return this.postCountBookingShortcuts.call(req, options);
  }

  refreshQuote(
    req: DbKey<Booking>,
    options?: HttpOptions
  ): Promise<RefreshBookingResp> {
    return this.postRefreshQuote.call(req, options);
  }

  private mkGetFn<O>(req: AnnotatedReq<HttpGet<O>>): GetFn<O> {
    const jb = createJsonBinding(this.resolver, req.rtype.respType);
    return {
      description: () => req.description,
      rtype: req.rtype,
      call: (options?: HttpOptions) => {
        return this.getAdl({
          path: req.rtype.path,
          respJB: jb,
          actionName: req.actionName,
          ...options,
        });
      },
    };
  }

  private mkGet2Fn<P, O>(req: AnnotatedReq<HttpGet2<P, O>>): Get2Fn<P, O> {
    const reqJB: JsonBinding<P> = createJsonBinding(
      this.resolver,
      req.rtype.paramsType
    );
    const resJB: JsonBinding<O> = createJsonBinding(
      this.resolver,
      req.rtype.respType
    );

    return {
      description: () => req.description,
      rtype: req.rtype,
      call: (ival: P, options?: HttpOptions) => {
        return this.get2Adl<P, O>({
          initPath: req.rtype.path,
          reqJB,
          respJB: resJB,
          req: ival,
          actionName: req.actionName,
          ...options,
        });
      },
    };
  }

  private mkPostFn<I, O>(
    req: AnnotatedReq<HttpPost<I, O>>,
    raw?: boolean
  ): PostFn<I, O> {
    const bb = createBiBinding<I, O>(this.resolver, req.rtype);
    return {
      description: () => req.description,
      rtype: req.rtype,
      call: (ival: I, options?: HttpOptions) => {
        return this.postAdl({
          path: req.rtype.path,
          post: bb,
          req: ival,
          actionName: req.actionName,
          raw,
          ...options,
        });
      },
    };
  }

  private async getAdl<O>({
    path,
    respJB,
    actionName,
    ...options
  }: GetADL<O>): Promise<O> {
    return this.requestAdl({
      ...options,
      method: "get",
      path,
      jsonArgs: null,
      respJB,
      actionName,
    });
  }

  private async get2Adl<P, O>({
    initPath,
    req,
    reqJB,
    respJB,
    actionName,
  }: Get2ADL<P, O>): Promise<O> {
    const jsonArgs = reqJB.toJson(req);
    const path = initPath + resolveQueryParams(jsonArgs as {});

    return this.requestAdl({
      method: "get",
      path,
      jsonArgs: null,
      respJB,
      actionName,
    });
  }

  private async postAdl<I, O>({
    post,
    req,
    raw,
    ...rest
  }: PostADL<I, O>): Promise<O> {
    const jsonArgs = raw ? req : post.reqJB.toJson(req);
    return this.requestAdl({
      ...rest,
      method: "post",
      jsonArgs: jsonArgs as {},
      respJB: post.respJB,
    });
  }

  private async requestAdl<O>({
    method,
    path,
    jsonArgs,
    respJB,
    /** Publicly consumable action of the request for error alerting purposes */
    actionName,
    signal,
  }: RequestADL<O>): Promise<O> {
    // Construct request
    const authToken = this.tokenManager.getToken();
    const headers: { [key: string]: string } = {};
    headers["Content-Type"] = "application/json";

    if (authToken) {
      headers["X-Auth-Token"] = authToken;
    }

    const httpReq: HttpRequest = {
      url: this.baseUrl + path,
      headers,
      method,
      body: jsonArgs !== null ? JSON.stringify(jsonArgs) : undefined,
      signal,
    };

    // Make request
    const resp = await this.http.fetch(httpReq);

    // Check for errors
    if (!resp.ok) {
      const bodyText = await resp.text();
      let publicMessageFragment = "";
      try {
        const bodyJson = JSON.parse(bodyText);
        if (bodyJson.publicMessage) {
          publicMessageFragment = bodyJson.publicMessage;
        }
      } catch (e) {
        // Not JSON
      }

      const error = new HttpServiceError(
        publicMessageFragment ||
        `Encountered server error attempting to call ${actionName}`,
        `${httpReq.method} request to ${httpReq.url} failed: ${resp.statusText} (${resp.status}): ${bodyText}`,
        resp.status
      );
      this.handleError(error);
      throw error;
    }

    // Parse response
    try {
      const respJson = await resp.json();
      return respJB.fromJson(respJson);
    } catch (e) {
      const error = new HttpServiceError(
        "Encountered parse error attempting to call " + actionName,
        e.getMessage(),
        resp.status
      );
      this.handleError(error);
      throw error;
    }
  }
}

interface BiTypeExpr<I, O> {
  reqType: ATypeExpr<I>;
  respType: ATypeExpr<O>;
}

interface BiBinding<I, O> {
  reqJB: JsonBinding<I>;
  respJB: JsonBinding<O>;
}

function createBiBinding<I, O>(
  resolver: DeclResolver,
  rtype: BiTypeExpr<I, O>
): BiBinding<I, O> {
  return {
    reqJB: createJsonBinding(resolver, rtype.reqType),
    respJB: createJsonBinding(resolver, rtype.respType),
  };
}

function resolveQueryParams<P extends {}>(params: P): string {
  if (typeof params === "object") {
    return Object.entries(params).reduce((acc, [key, value], index) => {
      const strValue = Array.isArray(value) ? value.join(",") : value;

      return `${acc}${index > 0 ? "&" : ""}${key}=${strValue}`;
    }, "?");
  }
  return "";
}

const texprDocString: ATypeExpr<string> = {
  value: {
    typeRef: {
      kind: "reference",
      value: { moduleName: "sys.annotations", name: "Doc" },
    },
    parameters: [],
  },
};

interface AnnotatedReq<RT> {
  actionName: string;
  description: string;
  rtype: RT;
}

type AnnotatedApi<A> = {
  [RT in keyof A]: AnnotatedReq<A[RT]>;
};

/**
 * Merge the information available as API annotations into the API value
 */
function annotatedApi<API extends {}>(
  resolver: DeclResolver,
  apisn: adlast.ScopedName,
  api: API
): AnnotatedApi<API> {
  const apiDecl = resolver(apisn);
  if (apiDecl.decl.type_.kind !== "struct_") {
    throw new Error("BUG: api is not a struct");
  }
  const apiStruct = apiDecl.decl.type_.value;

  //tslint:disable:no-object-literal-type-assertion
  const result = {} as AnnotatedApi<API>;

  for (const k of Object.keys(api)) {
    const rtype = api[k];
    const apiField = apiStruct.fields.find((f) => f.name === k);
    if (!apiField) {
      throw new Error(`BUG: api  missing field ${k}`);
    }
    const description =
      getAnnotation(
        createJsonBinding(resolver, texprDocString),
        apiField.annotations
      ) || "";
    result[k] = {
      actionName: apiField.name,
      description,
      rtype,
    };
  }
  return result;
}
