import clsx from "clsx";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import { LoggedInContext } from "@app/app";
import layout from "@common/layout-lib.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { assertNotUndefined } from "@hx/util/types";
import { Button } from "@widgets/button/button";
import Input from "@widgets/input";

import { ChangePasswordDefaultValues, ChangePasswordSchema } from "./constants";
import styles from "./styles.css";
import { ChangePasswordFormValues } from "./types";

const PasswordSettings = () => {
  const { updateUserPassword } = assertNotUndefined(
    useContext(LoggedInContext).userController
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues: ChangePasswordDefaultValues,
  });

  function savePassword({ password, newPassword }: ChangePasswordFormValues) {
    updateUserPassword({
      newPassword,
      oldPassword: password,
    });
    reset();
  }

  return (
    <form
      className={clsx(layout.vertical, styles.passwordSettings)}
      onSubmit={handleSubmit(savePassword)}
    >
      <Input
        {...register("password")}
        label="Current Password"
        type="password"
        error={errors?.password?.message}
      />
      <Input
        {...register("newPassword")}
        label="New Password"
        type="password"
        error={errors?.newPassword?.message}
      />
      <Input
        {...register("confirmPassword")}
        label="Confirm Password"
        type="password"
        error={errors?.confirmPassword?.message}
      />

      <span className={styles.hint}>
        Password must contain at least 8 symbols, including one uppercase, one
        number and one special sign {"(!@#$%^&*()_+|~-=`{}[]:\";'<>?,./)"}
      </span>

      <Button className={styles.saveButton} type="submit">
        Save Password
      </Button>
    </form>
  );
};

export default PasswordSettings;
