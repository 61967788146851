import { PaymentType } from "@constants/common";

export const PAYMENT_INFO_TEXT =
  " In order to avoid a possible dispute it is important that the credit\n" +
  "        card holder is notified of the charges. Please note that the charge will\n" +
  "        appear on the credit card as Agentus. Any chargeback will render your\n" +
  "        travel agency responsible for payment.";

export const CONDITIONS_AGREEMENT_TEXT =
  "I understand and accept the Booking Terms and Conditions";

export const BOOK_AND_HOLD_ACKNOWLEDGEMENT_TEXT =
  "I understand that this is a book and hold booking";

export const BANK_TRANSFER_INFO =
  "Full payment is required no later than the specified payment due date mentioned above. This booking is only confirmed when payment is received, and the status is updated in your account";

export enum PaymentFormMode {
  PayNow = "Pay Now",
  BookAndHold = "Book and Hold",
}

export const PAYMENT_LIST: Array<{
  key: PaymentType;
  title: string;
  supportedBy: PaymentFormMode[];
}> = [
  {
    key: PaymentType.CreditCardPayment,
    title: "Credit Card Payment",
    supportedBy: [PaymentFormMode.PayNow],
  },
  {
    key: PaymentType.BankTransfer,
    title: "Bank Transfer",
    supportedBy: [],
  },
  {
    key: PaymentType.BookAndHold,
    title: "Book and Hold",
    supportedBy: [PaymentFormMode.BookAndHold],
  },
];
